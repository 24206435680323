import { BASE_XY_API_PATH } from '@/config/constant';
import wujiFetch from '@utils/wujiFetch';
export const GROUP_API_PATH = `${BASE_XY_API_PATH}/group`;
export async function fetchGroupKVCfg(groupId, keys) {
    const data = await wujiFetch(`${GROUP_API_PATH}/${groupId}/kvCfg?includes=${keys}`);
    if (Array.isArray(keys))
        return data;
    return data[keys];
}
export async function updateGroupKVCfg(groupId, a, b) {
    const kv = {};
    if (typeof a === 'string')
        kv[a] = b;
    if (typeof a === 'object')
        Object.assign(kv, a);
    const updatePromise = Object.keys(kv).map(key => wujiFetch(`${GROUP_API_PATH}/${groupId}/kvCfg/${key}`, {
        method: 'POST',
        body: JSON.stringify({
            value: kv[key],
        }),
    }));
    await Promise.all(updatePromise);
}
export async function fetchGroupSystemInfo(groupId) {
    return await wujiFetch(`${BASE_XY_API_PATH}/sso/systemInfo/${groupId}`);
}
