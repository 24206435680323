export const CATEGORY = {
  UI: 'UI交互',
  LOGIC: '逻辑',
  DATASOURCE: '数据源',
  EXPORT: '导入导出',
  NAVIGATOR: '页面导航',
  DRAFT: '草稿',
  NOTIFICATION: '外部通知',
  WORKFLOW: '审批流',
  FLOW: '流程',
  AI: 'AI行为',
};
