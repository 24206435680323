<template>
  <div
    ref="container"
  />
</template>

<script>
import monacoProvider from '@utils/monacoProvider';

export default {
  name: 'MonacoEditor',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    initOptions: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: String,
      default: '',
    },
    language: {
      type: String,
      default: 'javascript',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  events: [
    'init', // (editor)
    'change', // (string)
  ],

  mounted() {
    this.lastValue = String(this.value ?? '');
    monacoProvider((monaco) => {
      this.monaco = monaco;
      this.editor = monaco.editor.create(this.$refs.container, {
        value: this.lastValue,
        language: this.language || 'javascript',
        foldingStrategy: 'indentation',
        fontSize: 16,
        fontFamily: 'Consolas, monospace',
        cursorBlinking: 'solid',
        theme: 'vs-dark',
        autoIndent: true,
        automaticLayout: true,
        tabSize: 2,
        minimap: { enabled: false },
        lineNumbers: 0,
        readOnly: this.readonly || false,
        fixedOverflowWidgets: true,
        ...this.initOptions,
      });
      this.editor.onDidChangeModelContent(() => {
        const value = this.editor.getValue();
        if (this.lastValue === value) return;

        this.lastValue = value;
        this.$emit('change', value);
      });
      this.$emit('init', this.editor);
      this.$watch(() => String(this.value ?? ''), (value) => {
        if (this.lastValue === value) return;
        this.lastValue = value;
        this.editor.setValue(value);
      });
    });
  },
};
</script>

<style>

</style>
