export const makeBox = (loading = true) => ({ loading, value: null, error: null, promise: null });

/** @type {<T>(fn: ()=>Promise<T>)=>({loading: boolean, value: T, error: any, promise: Promise<T>})} */
export const warpPromise = (fn) => {
  const box = makeBox();

  box.promise = Promise.resolve()
    .then(fn)
    .then((x) => {
      box.loading = false;
      box.value = x;
      return x;
    })
    .catch((err) => {
      box.loading = false;
      box.error = err;
      throw err;
    });

  return box;
};

export const mapBoxValue = boxName => ({
  get() {
    return this[boxName].value;
  },
  set(v) {
    this[boxName].value = v;
  },
});
