import dtBus from '@components/uicorePlugin/DTBus';
import DesignerReport from '@reporter/DesignerReport';
import { LogPanelForDevTool } from '@tencent/wuji-source';
import Vue from 'vue';
export function patchDevtools(uc, renderer) {
    const newList = [...Vue.prototype.$ucs || [], uc];
    Vue.prototype.$ucs = newList;
    {
        const cb = () => {
            Vue.prototype.$ucs = Vue.prototype.$ucs.filter((existed) => existed !== uc);
            dtBus.$emit('dt-renderer-created', Vue.prototype.$ucs);
        };
        uc.root.$once('hook:beforeDestroy', cb);
        renderer.$once('hook:beforeDestroy', () => {
            uc.root.$off('hook:beforeDestroy', cb);
        });
    }
    dtBus.$emit('dt-renderer-created', newList);
    if (uc?.hooks?.onOpenDevTool) {
        /** devtool 打开后的钩子, 在这里注册devtool的额外面板 */
        const cb = (devTool) => {
            devTool.setExtensionPanel('ds_log', {
                name: '数据源日志',
                component: LogPanelForDevTool,
            });
        };
        uc.hooks.onOpenDevTool.tap('dt-entry', cb);
        renderer.$once('hook:beforeDestroy', () => {
            uc.hooks.onOpenDevTool.taps = uc.hooks.onOpenDevTool.taps.filter((tap) => tap.fn !== cb);
        });
    }
    if (uc?.hooks?.onOpenDevToolDone) {
        const cb = (devTool) => {
            devTool.bus.reporter = new DesignerReport(devTool.bus.windowRef?.document);
            // 设置工具侧上报, 注意这里不报pv了
            const { projectId, pageId } = renderer.wContext.config || {};
            const { name: projectName, parentId } = renderer.wContext.projectConfig || {};
            devTool.bus.reporter
                .pgOut()
                .setIds({
                projectId: projectId || '_wuji',
                pageId: pageId || '_wuji',
                projectName,
                parentId,
            })
                .bindClick();
        };
        /** devtool 打开后的钩子, 这里做一些埋点上报 */
        uc.hooks.onOpenDevToolDone.tap('dt-entry', cb);
        renderer.$once('hook:beforeDestroy', () => {
            uc.hooks.onOpenDevToolDone.taps = uc.hooks.onOpenDevToolDone.taps.filter(tap => tap.fn !== cb);
        });
    }
    if (uc.$route.query?.dt_open === '1' && Vue.prototype.$ucs.length === 1) {
        uc.root?.openDevtool({ recording: true });
    }
}
