import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import { BASE_API_PATH } from '@config/constant';
import wujiFetch from '@utils/wujiFetch';
export default class GlobalDraftStore {
    async getDraft(draftId, projectId) {
        return await wujiFetch(`${BASE_API_PATH}/xy/page/draft/${projectId}/${draftId}`);
    }
    async loadDraft(params) {
        const { projectId, pageId, materialId, branch, range } = params;
        try {
            const queryObj = {};
            queryObj.pageid = pageId || '';
            queryObj.materialid = materialId || '';
            queryObj.branch = branch || '';
            if (range)
                queryObj.range = range.join(',');
            // 加载远程的草稿
            const url = `${BASE_API_PATH}/xy/page/draft/${projectId}?${new URLSearchParams(queryObj).toString()}`;
            const drafts = await wujiFetch(url, {
                timeout: 20000,
            });
            return drafts;
        }
        catch (error) {
            console.error(`获取远程草稿失败: ${error}`);
        }
    }
    async saveDraft(pageConfig, branch = '') {
        const url = `${BASE_API_PATH}/xy/page/draft/${pageConfig.projectId}`;
        const data = cloneDeep({ ...pageConfig, branch });
        // 这两个字段是page表的，草稿虽然直接复用了page表的字段，
        // 但是id和_id是独立的，所以在保存之前需要先清理掉
        const savedDraft = omit(data, ['id', '_id']);
        await wujiFetch(url, {
            method: 'POST',
            body: JSON.stringify(savedDraft),
            timeout: 3000,
        });
    }
}
