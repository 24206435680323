import { utilsForUser } from '@tencent/wuji-utils';
import scfLoader from '@loaders/scf/loader';
import { getUserInfo } from '@utils/userInfo';
import { getScfHostExtends } from '@/loaders/scf/utils';
import { getAppContext } from '@utils/context';
export const BUILD_IN_FIELDS = ['w', 'wLayout', 'ctx', '$utils'];
/* 获取全局默认 Mixin */
export function getDefaultMixin({ project, env = 'dev', w, mode = 'runtime' }) {
    const projectId = project.id;
    return {
        // @ts-ignore
        provide() {
            return {
                // @ts-ignore
                wLayout: this.wLayout,
            };
        },
        data() {
            const user = {
                ...getUserInfo(),
                roles: [],
                isAdmin: false,
            };
            return {
                wLayout: {
                    user,
                    header: null,
                    sidebar: null,
                    content: null,
                },
                w,
                ctx: getAppContext({ mode, project, env }),
            };
        },
        computed: {
            /* 工具类 */
            $utils() {
                return utilsForUser;
            },
        },
        methods: {
            /* 调用云函数，内部指向云函数loader->自动抹平各环境请求云函数差异 */
            callFunc(funcName, axiosOptions) {
                return scfLoader.callFunc({
                    funcName,
                    projectId,
                    ...getScfHostExtends(),
                }, axiosOptions);
            },
        },
    };
}
