import qs from 'query-string';
import { BASE_XY_API_PATH } from '@config/constant';
import wujiFetch from './wujiFetch';
import { isInBeaconAndHasSpace, getSpaceIdFromQuery } from './beacon';
export const USER_GROUP_API = `${BASE_XY_API_PATH}/user/group`;
export const listUserGroup = async (query = {}) => {
    const queryData = { ...query };
    if (isInBeaconAndHasSpace()) {
        Object.assign(queryData, {
            spaceId: await getSpaceIdFromQuery(),
        });
    }
    return await wujiFetch(`${USER_GROUP_API}?${qs.stringify(queryData)}`);
};
