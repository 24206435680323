import { getHmrPort, getQueryValue } from '@/utils/comps-loader';

export const getScfHostExtends = () => {
  const isDebug = Number(getQueryValue('debugfunc')) === 1;
  const exts = isDebug
    ? {
      debuging: true,
      host: getScfDebugHost(),
    }
    : {};

  return exts;
};

export const getScfDebugHost = () => {
  const debugHost = getQueryValue('debugfunchost') || '';
  return debugHost || `127.0.0.1:${getHmrPort()}`;
};
