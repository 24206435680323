<template>
  <div>
    <draggable
      v-model="config.options"
      ghost-class="drag-item"
    >
      <a-row
        v-for="(env, index) in config.options"
        :key="index"
      >
        <a-col :span="4">
          <a-form-model-item
            label="环境ID"
          >
            <a-auto-complete
              v-model="env.value"
              :data-source="Object.keys(presetEnvs)
                .filter(env => !config.options.map(({ value })=> value).includes(env))"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="4">
          <a-form-model-item
            label="环境名称"
          >
            <a-auto-complete
              v-model="env.label"
              :data-source="(presetEnvs[env.value] ? [presetEnvs[env.value]] : [])"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="2">
          <a-button
            class="opt-btn"
            @click="addEnv"
          >
            添加
          </a-button>
          <a-button
            class="opt-btn"
            @click="removeEnv(index)"
          >
            删除
          </a-button>
        </a-col>
      </a-row>
    </draggable>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';

const getDefaultEnvConfig = envConfig => ({
  queryKey: 'env',
  type: 'static',
  options: (envConfig && envConfig.options) || [],
});

export default {
  name: 'EnvForm',
  components: {
    Draggable,
  },
  props: {
    config: {
      type: Object,
      default: () => getDefaultEnvConfig(),
    },
  },
  data() {
    return {
      presetEnvs: {
        production: '生产环境',
        test: '测试环境',
        development: '开发环境',
        prerelease: '预发布环境',
      },
    };
  },
  computed: {
    envsLength() {
      return this.config.options ? this.config.options.length : 0;
    },
  },
  created() {
    Object.entries(getDefaultEnvConfig(this.config)).forEach(([key, value]) => {
      this.$set(this.config, key, value);
    });

    this.addFormIfEmpty();
  },
  methods: {
    addFormIfEmpty() {
      if (this.envsLength === 0) {
        this.addEnv();
      }
    },
    addEnv() {
      this.config.options.push({ label: '', value: '' });
    },
    removeEnv(index) {
      this.config.options.splice(index, 1);
    },
    // called by `nav-widget` component
    validate() {
      const values = [];
      if (!this.config?.options?.length) {
        throw new Error('环境选项不能为空');
      }
      this.config.options.forEach((o) => {
        if (values.includes(o.value)) {
          throw new Error(`环境配置有重复的选项 '${o.value}'`);
        }
        values.push(o.value);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.drag-item {
  border-bottom: 1px solid #7F0020;
  cursor: move;
}
.opt-btn {
  margin-top: 5px;
}
</style>
