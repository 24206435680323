import cloneDeep from 'lodash/cloneDeep';
import { WUJI_COMPONENTS } from '../../config/constant.js';
import fieldAdapter from './fieldAdapter';
import { getAvailableComponent, getCompInfoFromCompKey } from '@utils/comps-loader';

// 这个是把逍遥组件桥接到 data-schema 用的
//
// 另外抠了一部分 xy-manage 的 client/src/utils/panel.js 的 wujiFormatData 代码

/**
 * 将来自无极1.0的字段配置，转换为对应的逍遥组件（注意，返回结果不能直接用于UICore，需要处理下）
 *
 * @param {*} field
 * @returns {null | { type: string, props: any, }}
 */
export default function findXyComponentForField(field, { readonly = false } = {}) {
  const { type, props } = WUJI_COMPONENTS[field.type] || {};

  if (!type) return null;

  const { registry, keyword } = getCompInfoFromCompKey(type);


  // 将来自无极1.0的字段组件配置转为为2.0的组件配置
  const evolveProps = fieldAdapter.evolve(field);

  const ans = cloneDeep({
    type: getAvailableComponent(registry, keyword),
    props: {
      ...evolveProps,
      ...props,
      fieldCName: field.name,
      readonly: !!readonly || !!evolveProps.readonly,
      style: {
        display: 'block',
        verticalAlign: 'bottom',
      },
    },
  });

  return ans;
}
