// 按照容器组件和展示组件分类，会根据某种模型/规则生成一种rule path , 数组
// 这个rule path 是从一开始就定好的，后续的field到layout的对应渲染都是一边走这个path 一边走field来渲染
// 获取自组件的方法收敛到childByRule方法
// getXXX方法都会直接返回layout
// XXX如果是容器组件，会继续调用childByRule来获取children
// 如果XXX到了叶节点，则结束
import { getAvailableComponent } from '@/utils/comps-loader';
import { cloneDeep } from 'lodash';
const imgRule = ['logo', 'icon', 'avatar', 'img', 'imag', 'image', 'photo', 'cover'];
const titleRule = ['title', 'name'];
const subTitleRule = ['subtitle', 'summary', 'creator', 'rtx', 'modifier', 'data', 'time', 'id'];
const contentRule = ['desc', 'text', 'info', 'content', 'description'];
const footerRule = ['footer', 'tip', 'addition'];
const ruleOrder = [
    { img: imgRule },
    { title: titleRule },
    { subTitle: subTitleRule },
    { content: contentRule },
    { footer: footerRule },
];
function getReadOnlyLayout(field) {
    return {
        type: getAvailableComponent('public', 'w-readonly'),
        props: {
            ...field.ui?.settings || {},
            ...field.ui?.props || {},
            value: '只读字段，请绑定数据',
        },
        bindings: !!field.id ? {
            'v-model': `data.item.${field.id}`,
        } : null,
    };
}
function getFormItem(field) {
    return {
        type: 'w-form-item',
        props: {
            label: field.title || field.id,
            description: field.description,
        },
        children: [getReadOnlyLayout(field)],
    };
}
export function calcSlotByRule(fieldsSource) {
    const fields = cloneDeep(fieldsSource);
    const textFields = fields.filter(field => field.type === 'string');
    let autoIndex = 0;
    const res = {};
    ruleOrder.forEach((curRule) => {
        const [k, v] = Object.entries(curRule)[0];
        const idx = fields.findIndex((field) => v.some(rule => field.id.includes(rule)));
        if (idx !== -1) {
            res[k] = fields[idx];
            fields.splice(idx, 1);
        }
        else {
            if (k === 'img')
                return;
            res[k] = textFields[autoIndex];
            autoIndex = (autoIndex + 1) % textFields.length;
        }
    });
    return res;
}
function getComplexLayout({ img, title, subTitle, content }) {
    const firstP = {
        type: 'w-paragraph',
        children: [
            {
                type: getAvailableComponent('public', 'w-avatar'),
                style: {
                    width: '48px',
                    height: '48px',
                    lineHeight: '48px',
                },
                props: {
                    url: 'https://picsum.photos/200',
                },
                bindings: !!img ? { 图片地址: `data.item.${img.id}` } : null,
            },
            {
                type: getAvailableComponent('public', 'w-block'),
                style: {
                    flexGrow: '1',
                    marginLeft: '16px',
                    marginRight: '16px',
                    height: 'auto',
                    width: 'auto',
                },
                props: {
                    justifyContent: 'center',
                },
                children: [
                    {
                        type: 'w-paragraph',
                        props: {
                            align: 'left',
                        },
                        children: [
                            {
                                type: getAvailableComponent('public', 'w-title'),
                                style: {
                                    minHight: '24px',
                                    fontFamily: 'PingFangSC-Medium',
                                    fontSize: '16px',
                                    color: 'rgba(0,0,0,0.85)',
                                    textAlign: 'left',
                                    lineHeight: '24px',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    wordBreak: 'break-all',
                                    '-webkit-box-orient': 'vertical',
                                    '-webkit-line-clamp': null,
                                    'line-clamp': null,
                                    'word-break': 'break-all',
                                },
                                props: {
                                    isClamp: true,
                                    value: '标题 The Title',
                                },
                                bindings: !!title ? { 'v-model': `data.item.${title.id}` } : null,
                            },
                        ],
                    },
                    {
                        type: 'w-paragraph',
                        props: {
                            align: 'left',
                        },
                        children: [
                            {
                                type: getAvailableComponent('public', 'w-title'),
                                style: {
                                    minHight: '20px',
                                    fontFamily: 'PingFangSC-Medium',
                                    fontSize: '12px',
                                    color: 'rgba(0,0,0,0.45)',
                                    textAlign: 'left',
                                    lineHeight: '20px',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    wordBreak: 'break-all',
                                },
                                props: {
                                    isClamp: true,
                                    value: '副标题 如果不需要可以删除',
                                },
                                bindings: !!subTitle ? {
                                    'v-model': `data.item.${subTitle.id}`,
                                } : null,
                            },
                        ],
                    },
                ],
            },
            {
                type: getAvailableComponent('public', 'w-icon-show'),
                style: {
                    width: '16px',
                },
                props: {
                    value: '',
                    icon: 'login',
                },
            },
        ],
    };
    const secondP = {
        type: 'w-paragraph',
        props: {
            align: 'left',
        },
        children: [
            {
                type: getAvailableComponent('public', 'w-title'),
                style: {
                    fontFamily: 'PingFangSC-Medium',
                    fontSize: '12px',
                    color: 'rgba(0,0,0,0.65)',
                    textAlign: 'left',
                    lineHeight: '22px',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 'NaN',
                    lineClamp: 'NaN',
                    overflow: 'hidden',
                    wordBreak: 'break-all',
                    height: '44px',
                    '-webkit-box-orient': 'vertical',
                    '-webkit-line-clamp': null,
                    'line-clamp': null,
                    'word-break': 'break-all',
                },
                props: {
                    isClamp: true,
                    value: '这里是一些介绍文字。。请注意修改，或者绑定到数据项的已有字段上',
                },
                bindings: !!content ? { 'v-model': `data.item.${content.id}` } : null,
            },
        ],
        style: {
            minHight: '20px',
            fontFamily: 'PingFangSC-Medium',
            fontSize: '12px',
            color: 'rgba(0,0,0,0.45)',
            textAlign: 'left',
            lineHeight: '20px',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 'NaN',
            lineClamp: 'NaN',
            overflow: 'hidden',
            wordBreak: 'break-all',
        },
    };
    return [firstP, secondP];
}
function getComplexFooter(footer) {
    return [{
            type: 'w-paragraph',
            children: [
                {
                    type: getAvailableComponent('public', 'w-readonly'),
                    style: {
                        lineHeight: '22px',
                        fontSize: '12px',
                        color: 'rgba(0,0,0,0.4)',
                        wordWrap: 'break-word',
                    },
                    props: {
                        value: '附加文案，不需要可以删除',
                    },
                    bindings: !!footer ? { 'v-model': `data.item.${footer.id}` } : null,
                },
                {
                    type: 'w-spacer',
                    style: {
                        flexGrow: '1',
                        height: '8px',
                        alignSelf: 'center',
                        opacity: 0.3,
                    },
                },
                {
                    type: getAvailableComponent('public', 'w-button'),
                    style: {
                        fontSize: '14px',
                    },
                    props: {
                        type: 'default',
                        btnText: '一个按钮',
                    },
                },
            ],
            props: {
                align: 'right',
            },
        }];
}
// param 为预留字段，如果为复杂卡片的时候可能有用
export default function (items, { type = 'readonly', param = 3 }) {
    let children = [];
    let footerSlot = [];
    try {
        const { fields = [] } = items;
        if (type === 'readonly') {
            const length = Math.min(param, fields.length);
            children = fields.slice(0, length).map(field => getFormItem(field));
            footerSlot = [];
        }
        else if (type === 'complex') {
            const localFields = JSON.parse(JSON.stringify(fields));
            const { footer, ...childrenItem } = calcSlotByRule(localFields);
            children = getComplexLayout(childrenItem);
            footerSlot = getComplexFooter(footer);
        }
    }
    catch { }
    return {
        item: {
            layout: {
                type: 'w-container',
                children: [
                    {
                        type: getAvailableComponent('public', 'w-card'),
                        children,
                        props: {
                            widthBorder: true,
                            title: false,
                            withFooter: true,
                            isClickable: false,
                        },
                        slots: {
                            footer: footerSlot,
                        },
                    },
                ],
            },
        },
    };
}
