<template>
  <div
    :class="['nav-link-item', {'nav-link-item-active': active}]"
    :wj-eid="link.name"
    @click="click()"
  >
    <public-w-icon-show-new
      v-if="link.args.icon"
      class="nav-link-item-icon"
      :value="link.args.icon"
    />
    <span class="title">{{ link.name }}</span>
  </div>
</template>

<script>
import { toPageByMenu } from '@utils/path';
export default {
  name: 'NavLinkItem',
  inject: {
    w: {
      from: 'w',
      default: {},
    },
  },
  props: {
    link: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  watch: {
    $route: {
      handler(to) {
        if (/^\//.test(this.link.args.url)) {
          if (to.path === this.link.args.url) {
            this.active = true;
          } else {
            this.active = false;
          }
        } else {
          this.active = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    click() {
      if (this.disabled) return false;
      toPageByMenu(this.link, {
        $router: this.$router,
        $route: this.$route,
        stringInteropContext: {
          ...(this.w?.mainRenderer ?? {}),
          $app: this.$app,
          w: this.w,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-link-item {
  font-size: var(--xy-custom-nav-font-size);
  color: var(--xy-custom-nav-color);
  &:hover {
    color: var(--xy-custom-nav-hover-color);
    cursor: pointer;
  }
  &#{&}-active {
    color: var(--xy-custom-nav-active-color);
  }
  .nav-link-item-icon {
    width: var(--xy-custom-nav-link-icon-size);
    height: var(--xy-custom-nav-link-icon-size);
  }
}
</style>
