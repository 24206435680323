<template>
  <div :class="['personal-info-panel',mode]">
    <slot name="default" />
    <template v-if="isEditor">
      <p class="personal-info-panel-title">
        个人信息
      </p>
      <div
        v-loading="loading"
        class="personal-info-setting"
      >
        <div v-if="generatedKey">
          <p class="info-title">
            <a-icon
              type="key"
            />
            密钥
          </p>
          <div class="key">
            <p class="key-content">
              {{ secretKey }}
            </p>
            <a-icon
              type="copy"
              style="cursor: pointer;"
              @click="paste(secretKey)"
            />
          </div>
        </div>
        <a
          v-else
          style="cursor: pointer;"
          @click="generateKey"
        >
          <a-icon
            type="key"
          />
          获取密钥
        </a>
      </div>
      <div
        v-if="isSuper"
        class="personal-info-setting"
      >
        <div
          class="personal-info-setting-content"
          @click="triggerMockNormal"
        >
          <a-icon
            type="user"
          />
          {{ isMockNormal? '恢复超管或运维身份' : '体验非超管或运维身份' }}
        </div>
      </div>
    </template>

    <slot name="logout" />
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import request from '../../utils/wujiFetch';
import { BASE_API_PATH } from '@/config/constant';
import { writeClipboard } from '../../utils/clipboard';
import { isRealSuper, getIsMockNormal, triggerMockNormal } from '../../utils/userInfo.js';

export default {
  props: {
    pageMode: {
      type: String,
      default: 'editor',
    },
    mode: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {
      // 用户是否已经生成了key
      generatedKey: false,
      secretKey: '',
      loading: false,

      // 是否是超管
      isSuper: isRealSuper(),
      // 是否模仿普通用户中..
      isMockNormal: getIsMockNormal(),
    };
  },
  computed: {
    isEditor() {
      return this.pageMode === 'editor';
    },
  },
  async mounted() {
    await this.fetchKey();
    // micro-app 内嵌场景
    // eslint-disable-next-line no-underscore-dangle
    if (window.__MICRO_APP_ENVIRONMENT__) {
      this.setBeaconAvatarDrop(this.secretKey ? 'secret' : 'getSecret');
      window.microApp?.getData().globalAPI.navigation.onClickDDownSetting((e) => {
        if (e.id === 'secret') this.paste(this.secretKey);
        else if (e.id === 'getSecret') this.generateKey();
      });
    }
  },
  methods: {
    async fetchKey() {
      if (!this.isEditor) return;

      this.loading = true;
      try {
        const { secretKey } = await request(`${BASE_API_PATH}/privatekey`) || {};
        if (secretKey) {
          this.secretKey = secretKey;
          this.generatedKey = true;
        }
      } catch (err) {
        message.error(`获取密钥失败：${err}`);
      } finally {
        this.loading = false;
      }
    },
    setBeaconAvatarDrop(name) {
      // eslint-disable-next-line no-underscore-dangle
      if (window.__MICRO_APP_ENVIRONMENT__) {
        window.microApp && window.microApp.getData().globalAPI.navigation.setTopNavigation({
          avatarDropDown: {
          // 是否允许打开头像下拉面板
            enable: true,
            // 启用默认设置，默认 true
            enableDefaultSettings: true,
            // 功能区域配置
            customSettings: [{
              id: name,
              icon: name === 'secret' ? 'browse' : 'browse-off',
              title: name === 'secret' ? '复制秘钥' : '获取秘钥',
            }],
          },
        });
      }
    },
    async generateKey() {
      this.loading = true;
      try {
        const { secretKey } = await request(`${BASE_API_PATH}/privatekey`, {
          method: 'POST',
        }) || {};
        this.secretKey = secretKey;
        this.generatedKey = true;
        this.setBeaconAvatarDrop('secret');
        this.paste(this.secretKey);
      } catch (err) {
        message.error(`获取密钥失败：${err}`);
      } finally {
        this.loading = false;
      }
    },

    paste(content) {
      writeClipboard(content).then(
        () => message.success('复制成功'),
        err => message.error(`复制失败！${err}`),
      );
    },

    triggerMockNormal() {
      triggerMockNormal();
      window.location.reload();
    },
  },
};
</script>

<style lang='scss'>
.personal-info-panel{
  width: 200px;
  background: white;
  padding: 0px 0px 0px 0px ;

  .personal-info-panel-title {
    font-size: 16px;
    font-weight: 700;
    margin: 0px 12px 6px 12px;
    padding-top: 12px;
  }

  .personal-info-panel-content{
    margin-top: 10px;
  }

  .info-title{
    font-size: 14px;
    font-weight: normal;
    color: #5c5c5c;
    margin-bottom: 5px;
  }

  .key{
    display: flex;
    justify-content: space-between;
    line-height: 15px;
    &-content {
      margin-bottom: 0;
      color: #5c5c5c;
    }
  }

  .personal-info-setting {
    padding: 8px 12px;
    font-weight: normal;
    color: #5c5c5c;
    &:hover {
      cursor: pointer;
      background-color: rgba(black, 0.05);
    }
  }
}

.personal-info-panel.light {
  .nav-link-item-logout a {
    color: var(--wuji-text-color);
  }
  .pending-icon:hover,
  .nav-link-item:hover,
  .nav-link-item:hover a {
    color: var(--wuji-text-color);
  }
  .pending-icon:active,
  .nav-link-item:active,
  .nav-link-item:active a {
    background: rgba(0, 0, 0, 0.04);
  }
}

// 头像下拉框内容
.personal-info-panel.dark {
  color: var(--wuji-text-color-dark);
  background:var(--wuji-primary-bg-color-dark);

  .pending-icon,
  .nav-link-item,
  .nav-link-item a {
    color: var(--wuji-text-color-dark);
  }

  .pending-icon:hover,
  .nav-link-item:hover,
  .nav-link-item:hover a {
    color: var(--wuji-text-color-dark);
    background: rgba(255, 255, 255, 0.08);
  }
  .pending-icon:active,
  .nav-link-item:active,
  .nav-link-item:active a {
    background: rgba(255, 255, 255, 0.04);
  }

  .nav-link-item-logout {
    border-color:rgba(255, 255, 255, 0.04);
  }
}
</style>
