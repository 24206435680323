import { BASE_API_PATH, getDesignEnvPath, getRuntimeEnvPath, generatedPageIdPrefix } from '@/config/constant';
import wujiFetch, { memoizedFetchV2 } from '@/utils/wujiFetch';
import useIds from '../ids/useIds';
export var RelatedFlowType;
(function (RelatedFlowType) {
    RelatedFlowType["RELATED"] = "related";
    RelatedFlowType["PARTICIPATED"] = "participated";
    RelatedFlowType["CREATED"] = "created";
    RelatedFlowType["PENDING"] = "pending";
})(RelatedFlowType || (RelatedFlowType = {}));
export default function useJobService(ids = useIds()) {
    // const flowDesignUrl = `${getDesignEnvPath()}/manage/flow/@`;
    const { projectId } = ids;
    const flowRuntimeUrl = `${getRuntimeEnvPath(ids.envId)}/manage/flow`;
    const commonFlowPath = `${BASE_API_PATH}/xy/common/flow`;
    const designJobPath = `${getDesignEnvPath(ids.envId)}/manage/flow/job`;
    const createJob = async (flowId, params = {}, extraOptions) => {
        const res = await wujiFetch(`${BASE_API_PATH}/xy/common/env/${ids.envId}/flow`, {
            method: 'POST',
            query: {
                projectId: ids.projectId,
            },
            body: JSON.stringify({
                ...extraOptions,
                flowId,
                params,
            }),
        });
        return res;
    };
    const listForDesign = async (params) => {
        const list = await wujiFetch(commonFlowPath, {
            method: 'GET',
            query: {
                projectId: ids.projectId,
                sort: 'ctime',
                order: 'desc',
                ...params,
            },
        });
        return list;
    };
    const countForDesign = async (params) => {
        const res = await wujiFetch(commonFlowPath, {
            method: 'GET',
            query: {
                ...params,
                count: 1,
                projectId: ids.projectId,
            },
        });
        return res.total;
    };
    const getForRuntime = async (jobId, { pageId = '' } = {}) => {
        if (!jobId)
            throw new Error('Need jobID');
        // 目前生成的页面不需要额外数据，所以去掉 pageId 以合并请求
        if (pageId && pageId.startsWith(generatedPageIdPrefix))
            pageId = ''; // eslint-disable-line
        const res = await memoizedFetchV2(`${flowRuntimeUrl}/job/${encodeURIComponent(jobId)}`, {
            method: 'GET',
            query: {
                projectId: ids.projectId,
                pageid: pageId,
            },
        });
        transformJob(res);
        return res;
    };
    const getForDesign = async (jobId) => {
        if (!jobId)
            throw new Error('Need jobID');
        const res = await wujiFetch(`${designJobPath}/${encodeURIComponent(jobId)}`, {
            method: 'GET',
            query: {
                projectId: ids.projectId,
            },
        });
        transformJob(res);
        return res;
    };
    const getJobLog = async (jobId, seq = '') => {
        const data = await wujiFetch(`${designJobPath}/${encodeURIComponent(jobId)}/log`, {
            method: 'GET',
            query: {
                projectId: ids.projectId,
                seq,
            },
        });
        return data;
    };
    /**
     * 暂停或继续
     * @param jobId
     * @param status
     * @returns
     */
    const changeJobStatus = async (jobId, status) => {
        const data = await wujiFetch(`${designJobPath}/${jobId}`, {
            method: 'PATCH',
            query: {
                projectId: ids.projectId,
            },
            body: JSON.stringify({ status }),
        });
        return data;
    };
    const relatedListForRuntime = async (type, opt) => {
        const data = await wujiFetch(`${flowRuntimeUrl}/entity/list/${type}`, {
            method: 'GET',
            query: {
                projectId,
                sort: 'ctime',
                order: 'desc',
                filter: opt.filter,
                ...(opt.query || {}),
                page: opt.page || 1,
                size: opt.size || 10,
            },
        });
        return data;
    };
    const countRelatedListForRuntime = async (type, filter = '', query = {}) => {
        const data = await wujiFetch(`${flowRuntimeUrl}/entity/list/${type}`, {
            method: 'GET',
            query: {
                projectId,
                filter,
                ...query,
                count: 1,
                size: 'total',
            },
        });
        return data.total;
    };
    const countAllForRuntime = async (filter = '', query = {}) => {
        const data = await wujiFetch(`${flowRuntimeUrl}/entity`, {
            method: 'GET',
            query: {
                projectId,
                size: 'total',
                filter,
                ...query,
                count: 1,
            },
        });
        return data.total;
    };
    const allForRuntime = async (opt) => {
        const data = await wujiFetch(`${flowRuntimeUrl}/entity`, {
            method: 'GET',
            query: {
                projectId,
                sort: 'ctime',
                order: 'desc',
                filter: opt.filter,
                ...(opt.query || {}),
                page: opt.page || 1,
                size: opt.size || 10,
            },
        });
        return data;
    };
    return {
        createJob,
        listForDesign,
        countForDesign,
        getForRuntime,
        getForDesign,
        getJobLog,
        changeJobStatus,
        relatedListForRuntime,
        countRelatedListForRuntime,
        countAllForRuntime,
        allForRuntime,
    };
}
function transformJob(job) {
    if (!job || Array.isArray(job.detailBlocks)) {
        return;
    }
    try {
        const arr = JSON.parse(job.detailBlocks);
        // eslint-disable-next-line no-param-reassign
        job.detailBlocks = arr;
    }
    catch (error) {
        // eslint-disable-next-line no-param-reassign
        job.detailBlocks = [];
    }
}
