import { message } from 'ant-design-vue';
import PluginService from '@/services/plugin';
import pluginLoader, { setLocallyEnabledPluginConfig } from '@/loaders/plugin/loader';


export default {
  state: {
    enabledPlugins: [],
    locallyEnabledPlugins: [],
  },
  actions: {
    // 获取页面启用的插件
    async fetchEnablePlugins({ commit }, { projectId, pageId, filter = '' }) {
      try {
        const { plugins, locallyEnabledPlugins } = await pluginLoader.getInstalledPlugins(projectId, pageId, filter);
        console.log('setting', plugins);
        commit('setter', { enabledPlugins: plugins, locallyEnabledPlugins });
      } catch (err) {
        console.error(err);
        message.error('获取插件列表失败', err.message);
      }
    },
    async installPlugin({ }, { projectId, pageId, pluginId, pluginConfig }) {
      try {
        await PluginService.installPlugin({ projectId, pageId, pluginId, data: pluginConfig });
      } catch (err) {
        throw err;
      }
    },
    async updatePluginConfig({ state }, { projectId, pageId, pluginId, pluginConfig }) {
      try {
        // 如果是本地启动的插件，将config写入localStorage中，不需要发起请求
        if (state.locallyEnabledPlugins.includes(pluginId)) {
          setLocallyEnabledPluginConfig(projectId, pageId, pluginId, JSON.stringify(pluginConfig));
          return;
        }
        await PluginService.updatePluginConfig({ projectId, pageId, pluginId, pluginConfig });
      } catch (err) {
        throw err;
      }
    },
    async uninstallPlugin({ state }, { projectId, pageId, pluginId }) {
      try {
        // 如果是本地启动的插件，不允许卸载
        if (state.locallyEnabledPlugins.includes(pluginId)) {
          console.error('本地debug的插件不支持卸载');
          return;
        }
        await PluginService.uninstallPlugin({ projectId, pageId, pluginId });
      } catch (err) {
        throw err;
      }
    },
  },
  mutations: {
    setter(state, payload) {
      Object.assign(state, payload);
    },
  },
};
