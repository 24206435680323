// 后台储存的组id是带有 xy_hub_前缀的，我们在展示的时候希望隐藏掉这个前缀
export const getGroupId = (id) => {
  const regex = /xy_hub_(\w+)$/;
  if (regex.test(id)) {
    const [, idWithoutPrefix] = id.match(regex);
    return idWithoutPrefix;
  }
  return '';
};


export const getComponentStatus = (component) => {
  const { testRepoId, repoId, grayScaleRepoId } = component;
  const test = +testRepoId;
  const grayScale = +grayScaleRepoId;
  const formal = +repoId;
  if (test > formal && test > grayScale) {
    return 'testing';
  }
  if (grayScale > formal) {
    return 'grayScaling';
  }
  return 'running';
};
