import { message } from 'ant-design-vue';
import { BASE_API_PATH } from '@/config/constant';
import { getUserId } from '@/utils/userInfo';
import wujiFetch from '@/utils/wujiFetch';
import { ref } from '@vue/composition-api';
const ACTION = {
    ADD_PROJECT_USER: 'addXyProjectUser',
};
export var ProjectRole;
(function (ProjectRole) {
    ProjectRole["admin"] = "admin";
    ProjectRole["participant"] = "participant";
})(ProjectRole || (ProjectRole = {}));
const PAGE_SIZE = 10;
export default function useApproval() {
    const loading = ref(false);
    const approvals = ref({
        data: [],
        page: 1,
        total: 0,
        size: PAGE_SIZE,
    });
    const todoCount = ref(0);
    const getTodoCount = async () => {
        const data = await wujiFetch(`${BASE_API_PATH}/xy/approval/process?size=total&filter=${encodeURIComponent('status="pending"|status="failed"')}`);
        if (data) {
            todoCount.value = data.filter((i) => i.approvers?.split(',').includes(getUserId())).length;
        }
    };
    const getApprovals = async (page, filter, from = 'my') => {
        try {
            loading.value = true;
            const url = `${BASE_API_PATH}/xy/approval/process?size=total&sort=ctime&order=desc&filter=${filter}`;
            const data = await wujiFetch(url);
            const result = data.filter((i) => (from === 'my'
                ? i.creator === getUserId()
                : i.approvers?.split(',').includes(getUserId())));
            approvals.value = {
                data: result.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE),
                total: result.length,
                page,
                size: PAGE_SIZE,
            };
        }
        catch (err) {
            message.error('获取审批列表失败');
        }
        finally {
            loading.value = false;
        }
    };
    // 获取单个审批流信息
    const getApprovalDetail = async (processId) => {
        try {
            const data = await wujiFetch(`${BASE_API_PATH}/xy/approval/process/${processId}?link=1`);
            return data;
        }
        catch (err) {
            message.error('获取审批流失败');
        }
    };
    // 处理审批流程
    const handleProcess = async (processId, status, desc) => {
        await wujiFetch(`${BASE_API_PATH}/xy/approval/process/${processId}`, {
            method: 'POST',
            body: JSON.stringify({
                status,
                desc,
            }),
        });
    };
    // 验证审批的合法性
    const validataApproval = async (action, body) => {
        try {
            await wujiFetch(`${BASE_API_PATH}/xy/approval/action/${action}/validate`, {
                method: 'POST',
                body: JSON.stringify(body),
            });
        }
        catch (err) {
            console.error('发起的审批有误', err);
            throw new Error('发起的审批有误');
        }
    };
    // 发起审批
    const startApprovalProcess = async (action, data, desc, approvers) => {
        const requestData = {
            ...data,
            rtx: getUserId(),
        };
        await validataApproval(action, requestData);
        await wujiFetch(`${BASE_API_PATH}/xy/approval/process`, {
            method: 'POST',
            body: JSON.stringify({
                desc,
                action,
                data: requestData,
                approvers,
            }),
        });
    };
    // 申请应用权限的开发权限
    const requestProjectPermission = async (data, desc) => {
        const result = await startApprovalProcess(ACTION.ADD_PROJECT_USER, data, desc);
        return result;
    };
    return {
        approvals,
        requestProjectPermission,
        getApprovals,
        getApprovalDetail,
        handleProcess,
        loading,
        todoCount,
        getTodoCount,
    };
}
