import Vue from 'vue';
import { isPlainObject } from 'lodash';
import './vue-config-common';

// 运行时: 自定义 Vue.config 配置
const { custom_vue_config: customVueConfig } = window;
if (isPlainObject(customVueConfig)) {
  Object.keys(customVueConfig).forEach((key) => {
    Vue.config[key] = customVueConfig[key];
  });
}
