import Vue from 'vue';
import { message, Modal } from 'ant-design-vue';
import { pick, sortBy, orderBy } from 'lodash';
import { utilsForUser } from '@tencent/wuji-utils';
// @ts-ignore
import { requestCgi, WujiTips } from '@tencent/wuji-source';
import { useRouter } from '@/router/useRouter';
import { useStore as getStore } from '@store/index';
import { wEmitter, EventName } from '@utils/global/event-emitter';
import { getAppParams, toPage } from '@utils/path';
import { getUserInfo, getUserDeptAndOrgInfo, getLogoutURL, isGuest, customUserInfo } from '@utils/userInfo';
import { checkPermission } from '@pages/modules/permission/utils';
import { PAGE_PERMISSIONS } from '@pages/modules/permission/constants';
import { workflowEntityApi, organizationApi, robotApi, permissionApi, flowApi, staffApi } from '@/services';
import SwitchAccountMask from '@pages/modules/sso/SwitchAccountMask.vue';
import { requestCollectionCgi, cacheRequestCollectionCgi } from '@utils/common';
import screenInfo, { editorScreenInfo } from '@utils/screen';
import polyfillFetch from '@utils/polyfillFetch';
import { pageScrollTo, getPageContainer } from '@utils/browser';
import { getCurrentRequire } from '@utils/lessCode';
import scfLoader from '@loaders/scf/loader';
import { getScfHostExtends } from '@/loaders/scf/utils';
import { OPEN_RUNTIME_DEPLOY, SSO_DEPLOY } from '@/config/constant';
import { buildAtoms } from './apiAtoms';
import { openGlobalModal } from '@/components/modal/openGlobalModal';
import { getModalZIndex } from '@/components/modal';
import RuntimeReport from '@/reporter/RuntimeReport';
const userViewMap = new Map();
const userInfoMap = new Map();
const entityList = Vue.observable([]);
export const setRelatedEntityList = (val) => {
    const arr = val || [];
    entityList.splice(0, entityList.length, ...arr);
};
// 获取当前 VueRouter 实例
const getRouter = () => {
    const { router } = useRouter();
    return router.value;
};
// 获取当前 Route 实例
const getRoute = () => {
    const { route } = useRouter();
    return route.value;
};
// 等待指定 Render 的数据源就绪
const waitForRenderDataSourceReady = async (renderer) => {
    const sources = Object.values(renderer?.data ?? {});
    return await Promise.all(sources.map((data) => data?.promiseForReady));
};
// 编辑时模拟路由参数
export const mockPathParams = Vue.observable({});
export const setDesignerMockPathParams = (params) => {
    Object.keys(mockPathParams).forEach((key) => {
        Vue.delete(mockPathParams, key);
    });
    Object.keys(params).forEach((key) => {
        Vue.set(mockPathParams, key, params[key]);
    });
};
export const getDesignerMockPathParams = () => mockPathParams;
const wujiTips = new WujiTips();
export class CommonContext {
    mode = ''; // 运行时模式
    env = getAppParams('env') || 'dev'; // 运行环境
    // 用户信息
    user = {
        rtx: '',
        userId: '',
        name: '',
        chnName: '',
        avatar: '',
        deptName: '',
        deptIdString: '',
        deptNameString: '',
        workDeptId: '',
        workDeptIdString: '',
        workOrgIdString: '',
        positionName: '',
        orgBgId: '',
        orgDepartmentId: '',
        orgCenterId: '',
        orgGroupId: '',
        deptId: '',
        rawDeptIds: '',
        roles: [],
        isAdmin: false,
        isLogin: false,
        editorRole: '',
        logout: this.doSSOUserLogout.bind(this),
        switchAccount: this.doSSOUserSwitchAccount.bind(this),
    };
    // 常用工具方法
    utils = utilsForUser;
    // antd.confirm
    confirm = Modal.confirm;
    constructor({ mode = 'runtime' } = {}) {
        this.mode = mode;
        this.initContext();
    }
    get message() {
        return {
            ...message,
            // 错误提醒与应用消息提醒样式一致
            error: wujiTips.error.bind(wujiTips),
        };
    }
    // 应用
    // 应用ID
    get projectId() {
        return this.projectInfo?.id;
    }
    // 应用信息
    get projectInfo() {
        const store = getStore();
        if (this.mode === 'runtime') {
            return store.state.runtime.project;
        }
        return store.state.project.projectInfo;
    }
    // 页面
    // 获取页面列表
    get pageList() {
        const store = getStore();
        if (this.mode === 'runtime') {
            return store?.state?.runtime?.basicPages ?? [];
        }
        return store.state.page.pageList ?? [];
    }
    // 行为事件
    getAtoms(renderer = this.mainRenderer) {
        return buildAtoms({ renderer });
    }
    get atoms() {
        return this.getAtoms(this.mainRenderer);
    }
    // 公共参数
    // 根据 key 获取公共参数的值
    getCommonQueryValue(key) {
        return this.query?.[key];
    }
    // 根据 key 获取公共参数的选项
    getCommonQueryOptions(key) {
        const ctx = this.mainRenderer?.ctx;
        return ctx.topSwitchResult?.[key];
    }
    // 根据 key 重新请求公共参数的选项
    async fetchCommonQueryOptions(key) {
        const ctx = this.mainRenderer?.ctx;
        return ctx.topSwitch?.[key]?.();
    }
    // 路由
    get host() {
        return location.host;
    }
    get path() {
        return getRoute()?.path ?? '';
    }
    get query() {
        return (getRoute()?.query ?? {});
    }
    get params() {
        if (this.mode === 'runtime') {
            return (getRoute()?.params ?? {});
        }
        return mockPathParams;
    }
    // 跳转到应用内的指定页面(保留当前页面: push)
    navigateTo({ id, path, query, params }) {
        const $router = getRouter();
        const $route = getRoute();
        const pages = this.pageList;
        toPage({ pageId: id, pagePath: path, query, params, newWindow: false }, { method: 'push', pages, $router, $route });
    }
    // 跳转到应用内的指定页面(关闭当前页面: replace)
    redirectTo({ id, path, query, params }) {
        const $router = getRouter();
        const $route = getRoute();
        const pages = this.pageList;
        toPage({ pageId: id, pagePath: path, query, params, newWindow: false }, { method: 'replace', pages, $router, $route });
    }
    // 新窗口打开指定页面
    openPage({ id, path, query, params }) {
        const $router = getRouter();
        const $route = getRoute();
        const pages = this.pageList;
        toPage({ pageId: id, pagePath: path, query, params, newWindow: true }, { pages, $router, $route });
    }
    // 获取解析后的路径
    getResolveHref({ id, path, query, params }) {
        const $router = getRouter();
        const $route = getRoute();
        const pages = this.pageList;
        const href = toPage({ pageId: id, pagePath: path, query, params, newWindow: false }, { method: 'resolve', pages, $router, $route });
        return href;
    }
    // 导航
    get nav() {
        // const store = getStore();
        // const instance = store.state.layout.nav ?? null;
        return {};
    }
    // 导航的渲染器实例(headerRenderer = 兼容字段)
    get navRenderer() {
        const store = getStore();
        return store.state.layout.navRenderer ?? null;
    }
    get headerRenderer() {
        return this.navRenderer;
    }
    // 菜单
    get sidebar() {
        const store = getStore();
        const instance = store.state.layout.sidebar ?? null;
        return {
            // 获取当前的菜单组
            getCurrentGroup: () => instance?.currentGroupId,
            // 设置当前的菜单组
            setCurrentGroup: (name) => instance?.setCurrentGroup(name),
            // 设置当前选中的菜单项
            setSelectedMenu: (names) => instance?.setCurrent(names.join('.')),
            // 设置当前菜单收起展开态
            setCollapsed: (collapsed) => instance?.setCollapsed(collapsed),
            // 切换当前菜单收起展开态
            toggleCollapsed: () => instance?.toggleCollapsed(),
            // 获取当前菜单数据
            getSidebar: () => store.getters?.['layout/sidebar'],
            // 获取平台菜单数据
            getSystemSidebar: () => store.getters?.['layout.systemSidebar'],
            // 设置菜单数据
            setSidebar: (sidebar) => store.commit('layout/setCustomSidebar', sidebar),
            // 获取当前菜单名
            getCurrentSidebarNames: () => store.state.navigation.breadcrumb,
        };
    }
    // 菜单的的渲染器实例
    get sidebarRenderer() {
        const store = getStore();
        return store.state.layout.sidebarRenderer ?? null;
    }
    // 页头的渲染器实例
    get pageHeaderRenderer() {
        const store = getStore();
        return store.state.layout.pageHeaderRenderer ?? null;
    }
    // 页脚的渲染器实例(footerRenderer = 兼容字段)
    get footerRenderer() {
        return this.pageFooterRenderer;
    }
    get pageFooterRenderer() {
        const store = getStore();
        return store.state.layout.pageFooterRenderer ?? null;
    }
    // 全局 LessCode 实例
    get $app() {
        return Vue.prototype.$app;
    }
    // 页面
    // 当前主页面的渲染器实例
    get mainRenderer() {
        const store = getStore();
        return store.state.layout.mainRenderer ?? null;
    }
    // 根据组件 ID 搜索组件实例 - 根据主页面在前原则排序
    getCompInstancesById(compId) {
        if (!compId)
            return [];
        const store = getStore();
        const { ucRenderers } = store.state.layout;
        if (!ucRenderers?.length)
            return [];
        const compInstances = [];
        ucRenderers.filter((r) => r?.uc && !r._isDestroyed).forEach((renderer) => {
            const comps = renderer.uc.getVueInstanceByCompId(compId);
            // 按照组件在文档流的位置进行排序
            comps.sort((a, b) => {
                const elA = a.$el;
                const elB = b.$el;
                if (elA === elB)
                    return 0;
                const position = elA.compareDocumentPosition(elB);
                if (position & Node.DOCUMENT_POSITION_FOLLOWING || position === 0)
                    return -1;
                return 1;
            });
            if (comps?.length)
                compInstances.push(...comps);
        });
        if (this.mode === 'runtime') {
            // 运行时判断组件是否在主页面片进行排序
            const mainPageletSelector = '.xy-runtime-main-pagelet';
            return sortBy(compInstances, comp => !comp.$el.closest(mainPageletSelector));
        }
        return compInstances;
    }
    // 兼容字段
    getCompInstanceById(compId) {
        return this.getCompInstancesById(compId);
    }
    // 获取指定组件所属的渲染器实例
    getRenderByCompInstance(comp) {
        if (!comp?.$parent)
            return null;
        let renderer = null;
        let parent = comp.$parent;
        while (parent && !renderer) {
            // 当 $parent 为 uc-renderer 时返回
            if (parent?.$vnode?.tag?.endsWith('UicoreCRenderer') && 'uc' in parent) {
                renderer = parent;
            }
            parent = parent.$parent;
        }
        return renderer;
    }
    // 页面容器 DOM 元素
    get pageContainerElement() {
        return getPageContainer();
    }
    // 当前主页面的页面信息
    get mainPageInfo() {
        const store = getStore();
        return store.state.runtime.currentPage ?? null;
    }
    /**
     * 权限判断
     *
     * @param {string} permission
     * @return {boolean}
     */
    hasPermission(permission, spePageId) {
        if (!permission)
            return false;
        const pageId = spePageId || this.mainPageInfo?.pageId;
        // 判断页面权限时校验页面 id
        if (PAGE_PERMISSIONS.includes(permission) && !pageId)
            return false;
        return checkPermission(this.user, permission, pageId);
    }
    get permission() {
        return {
            fetchRoleList: async () => {
                const roleList = await permissionApi.fetchRoles(this.projectId, this.env);
                return roleList;
            },
            fetchRoleUserList: async (bizId = '', roleId = '', { id = '', type = '' } = {}) => {
                const roleUserList = await permissionApi.fetchRoleUsers(this.projectId, this.env, bizId, roleId, { id, type });
                return roleUserList;
            },
            fetchRolePermissionList: async (roleId) => {
                const resp = await permissionApi.fetchRolePermission(this.projectId, this.env, roleId);
                if (Array.isArray(resp))
                    return resp;
                if (resp && Array.isArray(resp.permissions))
                    return resp.permissions;
                return [];
            },
            fetchRoleView: async (roleId) => {
                const roleView = await permissionApi.fetchRoleView(this.projectId, this.env, roleId);
                return roleView;
            },
            fetchPermissionList: async () => {
                const permissionList = await permissionApi.fetchPermissions(this.projectId, this.env);
                return permissionList;
            },
            fetchBuiltinPermissionList: async () => {
                const builtinPermissionList = await permissionApi.fetchBuiltinPermissionList(this.projectId, this.env);
                return builtinPermissionList;
            },
            fetchApiPermissionList: async () => {
                const apiPermissionList = await permissionApi.listApiBinding(this.projectId, this.env);
                return apiPermissionList;
            },
            createRole: async (data) => {
                const role = await permissionApi.createRole(this.projectId, this.env, data);
                return role;
            },
            updateRole: async (data) => {
                const ok = await permissionApi.updateRole(this.projectId, this.env, data);
                return ok;
            },
            deleteRole: async (name) => {
                const ok = await permissionApi.deleteRole(this.projectId, this.env, name);
                return ok;
            },
            createPermission: async (data) => {
                const permission = await permissionApi.createPermission(this.projectId, this.env, data);
                return permission;
            },
            updatePermission: async (data) => {
                const ok = await permissionApi.updatePermission(this.projectId, this.env, data);
                return ok;
            },
            deletePermission: async (name) => {
                const ok = await permissionApi.deletePermission(this.projectId, this.env, name);
                return ok;
            },
            createRoleUser: async (data) => {
                const roleUser = await permissionApi.createRoleUser(this.projectId, this.env, data);
                return roleUser;
            },
            deleteRoleUser: async (id) => {
                const ok = await permissionApi.deleteRoleUser(this.projectId, this.env, id);
                return ok;
            },
            createRolePermission: async (data) => {
                const rolePermission = await permissionApi.createRolePermission(this.projectId, this.env, data);
                return rolePermission;
            },
            deleteRolePermission: async (id) => {
                const ok = await permissionApi.deleteRolePermission(this.projectId, this.env, { id });
                return ok;
            },
            batchDeleteRolePermission: async (ids) => {
                const ok = await permissionApi.deleteRolePermission(this.projectId, this.env, { id: 'batch', ids });
                return ok;
            },
            createApiPermission: async (data) => {
                const model = await permissionApi.createApiBinding(this.projectId, this.env, data);
                return model;
            },
            deleteApiPermission: async (id) => {
                const ok = await permissionApi.unbind(this.projectId, this.env, id);
                return ok;
            },
        };
    }
    // 流程
    get workflow() {
        return {
            fetchConfig: async (id) => {
                const config = await workflowEntityApi.fetchWorkflow(this.projectId, this.env, id);
                return config;
            },
            fetchConfigList: async () => {
                const configList = await workflowEntityApi.fetchWorkflows(this.projectId, this.env);
                return configList;
            },
            // 相关流程单
            get relatedEntityList() {
                return entityList;
            },
            // 流程单是否进行中
            isEntityProcessing: (entity) => entity.flowStatus === 'processing',
            // 当前用户能否审批
            canApproveEntity: (entity) => {
                const { userId } = this.user;
                if (!entity || !userId)
                    return false;
                return entity.flowStatus === 'processing' && entity?.handlers?.split(',').includes(userId);
            },
            // 重新指派审批人
            assignEntityHandlers: async (entityId, handlers) => {
                const result = await workflowEntityApi.assignHandlers(this.projectId, this.env, entityId, handlers);
                return result;
            },
            // 获取流程单列表
            fetchEntityList: async (type, filter = '', { page = 1, size = 10, count = 0 } = {}) => {
                const opt = { filter, page, size, count };
                const result = await workflowEntityApi.fetchEntities(this.projectId, this.env, type, opt);
                return result;
            },
            // 获取流程单详情
            fetchWorkflowEntity: async (entityId) => {
                const result = await workflowEntityApi.fetchWorkflowEntity(this.projectId, this.env, entityId);
                return result;
            },
            // 获取流程审批记录
            fetchEntityRecords: async (entityId) => {
                const result = await workflowEntityApi.fetchEntityRecords(this.projectId, this.env, entityId);
                return result;
            },
            // 发起流程
            start: async (workflowId, data) => {
                const result = await workflowEntityApi.start(this.projectId, this.env, workflowId, data);
                return result;
            },
            // 审批流程
            transition: async (entityId, data) => {
                const result = await workflowEntityApi.transition(this.projectId, this.env, entityId, data);
                return result;
            },
            // 重新发起流程
            restart: async (entityId, payload) => {
                const result = await workflowEntityApi.restart(this.projectId, this.env, entityId, payload);
                return result;
            },
            // 撤销流程
            abort: async (entityId) => {
                const result = await workflowEntityApi.abort(this.projectId, this.env, entityId);
                return result;
            },
        };
    }
    get robot() {
        return {
            postMessage: async (robotId, message) => {
                const result = await robotApi.postRobotMessage(this.projectId, this.env, robotId, message);
                return result;
            },
        };
    }
    // 组织架构
    get org() {
        return {
            fetchStaffs: async (rtxList) => {
                const result = await staffApi.fetchStaffInfo(rtxList);
                return result;
            },
            // 获取组织节点信息
            fetchDeptInfo: async (idString) => {
                const result = await organizationApi.fetchDeptInfo(idString);
                return result.map(x => pick(x, ['id', 'name', 'deptNameString'])).map(x => ({ ...x, id: String(x.id) }));
            },
        };
    }
    get flow() {
        return {
            fetchJobList: async (title = '', creator = '') => {
                const result = await flowApi.fetchJobList(this.projectId, title, creator);
                return result;
            },
            fetchJobListByType: async (title = '', type) => {
                const result = await flowApi.fetchJobListByType(this.projectId, title, type);
                return result;
            },
            fetchJob: async (jobId, pageId = '') => {
                const result = await flowApi.fetchJob(this.projectId, pageId, jobId);
                return result;
            },
            approval: async (jobId, nodeId, operation, comment, form) => {
                const result = await flowApi.approval(this.projectId, nodeId, jobId, operation, comment, form);
                return result;
            },
            startFlow: async (flowId, form) => {
                const result = await flowApi.startFlow(this.projectId, flowId, form);
                return result;
            },
            fetchFlow: async (flowId) => {
                const result = await flowApi.fetchFlow(this.projectId, flowId);
                return result;
            },
            fetchApprovalNodeInfo: async (jobId, nodeId) => {
                const result = await flowApi.fetchApprovalNodeInfo(jobId, nodeId);
                return result;
            },
            fetchLogNodeInfo: async (jobId, nodeId, tickId) => {
                const result = await flowApi.fetchLogNodeInfo(jobId, nodeId, tickId);
                return result;
            },
        };
    }
    // 公告
    get announcement() {
        return {
            getList: async (filter) => {
                const store = getStore();
                await store.dispatch('fetchAnncmntRuntimeList', {
                    projectId: this.projectId,
                    filter,
                });
                const { anncmntList } = store.state.announcement;
                return anncmntList;
            },
            refresh: async () => {
                const store = getStore();
                const promises = [
                    store.dispatch('fetchAnncmntRuntimeList', {
                        projectId: this.projectId,
                    }),
                    store.dispatch('fetchAnncmnLoopList', {
                        projectId: this.projectId,
                    }),
                ];
                await Promise.all(promises);
            },
        };
    }
    // 水印
    get watermark() {
        return {
            show: () => {
                const pagelet = this.mainRenderer?.$parent?.$parent;
                pagelet?.renderPageletWaterMark?.({ force: true });
            },
        };
    }
    // 请求
    // Ajax 请求
    async request(...rest) {
        return polyfillFetch(...rest);
    }
    async doSSOUserLogout() {
        const logoutURL = getLogoutURL(location.origin);
        window.location.href = logoutURL;
    }
    async doSSOUserSwitchAccount(shallRedirect = true) {
        if (!SSO_DEPLOY)
            throw new Error('这个部署版不支持切换账号，请联系平台运维人员');
        if (!OPEN_RUNTIME_DEPLOY)
            throw new Error('切换账号的功能仅限产物端（自定义域名）下可用');
        const container = document.createElement('div');
        document.body.appendChild(container);
        let resolve;
        const app = new Vue({
            el: container,
            render: h => h(SwitchAccountMask, { on: { response: (x) => resolve(x) } }),
        });
        try {
            const switched = await new Promise((r) => {
                resolve = r;
            });
            if (switched) {
                if (shallRedirect)
                    location.reload();
            }
            return switched;
        }
        finally {
            app.$el.remove();
            app.$destroy();
            container.remove();
        }
    }
    /**
     * 数据源请求
     * @deprecated 这个接口不推荐使用，请使用requestCgi
     */
    async requestCollectionCgi(collectionId, cgiId, ...args) {
        const result = await requestCollectionCgi(this.projectId, this.env, collectionId, cgiId, ...args);
        return result;
    }
    /**
     * 数据源请求（带缓存）
     * @deprecated 这个接口不推荐使用，请使用requestCgi
     */
    async cacheRequestCollectionCgi(collectionId, cgiId, ...args) {
        const result = await cacheRequestCollectionCgi(this.projectId, this.env, collectionId, cgiId, ...args);
        return result;
    }
    async requestCgi(collectionId, cgi, ...args) {
        const result = await requestCgi(collectionId, cgi, ...args);
        return result;
    }
    // 等待页面片数据源就绪
    async waitForDataSourceReady() {
        return waitForRenderDataSourceReady(this.mainRenderer);
    }
    // 云函数
    async callFunc(funcName, axiosOptions) {
        return scfLoader.callFunc({
            funcName,
            projectId: this.projectId,
            ...getScfHostExtends(),
        }, axiosOptions);
    }
    // 滚动
    // 将页面滚动到目标位置
    scrollTo(option = { top: 0 }) {
        pageScrollTo(option);
    }
    // 是否为小程序环境
    get isMiniProgram() {
        return screenInfo.isMiniprogram;
    }
    // 是否为窄屏
    get isNarrowScreen() {
        // 编辑模式下非预览状态
        return this.mode === 'editor' && editorScreenInfo.editor ? editorScreenInfo.isNarrowScreen : screenInfo.isNarrowScreen;
    }
    // 响应式参数
    get screen() {
        return this.mode === 'editor' && editorScreenInfo.editor ? editorScreenInfo.screen : screenInfo.screen;
    }
    // 模块
    require(path) {
        const r = getCurrentRequire();
        return r(path);
    }
    // 获取弹窗下一个 zIndex 的值
    getModalZIndex() {
        return getModalZIndex();
    }
    // 打开全局弹窗
    async showGlobalModal(id, data, opt = { keepAlive: false, suffix: '' }) {
        const { suffix } = opt;
        const store = getStore();
        const { globalModals } = store.state.layout;
        const modalId = suffix ? `${id}_${suffix}` : id;
        const globalModal = globalModals[modalId];
        if (!globalModal) {
            return await openGlobalModal({
                pageId: id,
                globalModalSetting: opt,
                mode: this.mode,
                projectId: this.projectId,
                data,
            });
        }
        ;
        const { instance = null } = globalModal;
        if (instance?.$refs?.[`wSysGlobalModal_${modalId}`]) {
            instance.$refs[`wSysGlobalModal_${modalId}`].visible = true;
            return await new Promise((resolve) => {
                instance.resolveHide = resolve;
            });
        }
    }
    // 关闭全局弹窗
    hideGlobalModal(id, data, opt = { destroy: false }) {
        const { destroy } = opt;
        const store = getStore();
        const { globalModals } = store.state.layout;
        const globalModal = globalModals[id];
        if (!globalModal)
            throw new Error(`全局弹窗${id}不存在`);
        const { instance, keepAlive } = globalModal;
        if (instance && (!keepAlive || destroy)) {
            // 实例存在，并且不保留实例或者设置销毁
            instance?.resolveGlobalModal(data);
            store.commit('layout/removeGlobalModalById', { id });
            return;
        }
        if (instance && keepAlive && instance?.$refs?.[`wSysGlobalModal_${id}`]) {
            // 实例存在，并且需要关闭后保留实例
            instance.$refs[`wSysGlobalModal_${id}`].visible = false;
            if (instance?.resolveHide) {
                instance.resolveHide(data);
                instance.resolveHide = null;
            }
            else {
                // 首次关闭
                instance?.resolveGlobalModal(data);
            }
        }
    }
    get emitter() {
        return {
            EventName,
            once: (eventName, fn) => wEmitter.once(eventName, fn),
            on: (eventName, fn) => wEmitter.on(eventName, fn),
            off: (eventName, fn) => wEmitter.off(eventName, fn),
            emit: (eventName, ...args) => wEmitter.emit(eventName, ...args),
        };
    }
    // 上报
    report(data) {
        if (!data)
            return;
        const project = this.projectInfo;
        const page = this.mainPageInfo;
        const { env } = this;
        if (!project || !page)
            return;
        const reporter = new RuntimeReport({
            projectInfo: {
                projectId: project.id,
                projectName: project.name,
                parentId: project.parentId,
            },
            pageId: page.pageId,
            envId: env,
            reportDimensions: [],
            agentReport: project.advanceConfig?.agentReport ?? false,
        });
        reporter.report(data);
    }
    async initContext() {
        const { projectId, env } = this;
        if (projectId && env) {
            this.initUser({ projectId, env });
            this.initStaff();
        }
    }
    async initUser({ projectId, env }) {
        // 用户信息
        const user = getUserInfo();
        Object.assign(this.user, user, { isLogin: !isGuest() });
        Object.assign(this.user, user, { custom: customUserInfo() });
        // 用户权限信息
        const key = `${projectId}-${env}`;
        const userView = window.xy_runtime_userview
            ?? userViewMap.get(key)
            ?? await permissionApi.fetchUserView(projectId, env);
        if (userView) {
            userViewMap.set(key, userView);
            Object.assign(this.user, {
                roles: userView.roles ?? [],
                isAdmin: userView.isAdmin ?? false,
                editorRole: userView.editorRole ?? '',
            });
        }
    }
    async initStaff() {
        const { rtx } = getUserInfo();
        const staffInfo = userInfoMap.get(rtx) ?? await getUserDeptAndOrgInfo(rtx);
        if (staffInfo) {
            userInfoMap.set(rtx, staffInfo);
            Object.assign(this.user, staffInfo);
        }
    }
}
export class PageletContext extends CommonContext {
    pageInfo = null; // 当前页面的页面信息
    renderer = null; // 当前页面的渲染器实例
    pageletContainer = null;
    constructor({ mode, pageletContainer }) {
        super({ mode });
        this.pageletContainer = pageletContainer;
    }
    // 行为事件
    getAtoms(renderer = this.renderer) {
        return buildAtoms({ renderer });
    }
    get atoms() {
        return this.getAtoms(this.renderer);
    }
    // 打开弹窗
    async showModal(id, data) {
        return this.pageletContainer?.wContext?.showModal(id, data);
    }
    // 关闭弹窗
    hideModal(id, data) {
        return this.pageletContainer?.wContext?.hideModal(id, data);
    }
    // 根据组件 ID 搜索组件实例 - 根据与当前 renderer 的距离排序
    getCompInstancesById(compId) {
        const compInstances = super.getCompInstancesById(compId);
        return orderBy(compInstances, instance => getUcDistanceByComp(instance.$el, this.renderer.$el));
    }
    // 兼容字段
    getCompInstanceById(compId) {
        return this.getCompInstancesById(compId);
    }
    // 等待页面片数据源就绪
    async waitForDataSourceReady() {
        return waitForRenderDataSourceReady(this.renderer);
    }
    // 水印
    get watermark() {
        return {
            show: () => {
                const isModal = !!this.renderer?.$el?.__vue__?.modalInstance; // 判断是否为弹窗内调用
                const pagelet = isModal ? this.renderer?.$el?.__vue__ : this.mainRenderer?.$parent?.$parent;
                pagelet?.renderPageletWaterMark?.({ force: true });
            },
        };
    }
    // 上报
    report(data) {
        if (!data)
            return;
        this.pageletContainer?.report(data);
    }
}
// 获取指定组件与指定uicore的距离
const getUcDistanceByComp = (compInstanceEl, rendererEl) => {
    const closestUc = compInstanceEl?.closest('.uc-renderer');
    if (!closestUc?.parentNode)
        return Infinity;
    if (closestUc === rendererEl)
        return 1;
    return 1 + getUcDistanceByComp(closestUc.parentNode, rendererEl);
};
