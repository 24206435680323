<template>
  <div class="home">
    <div class="home-wrapper">
      <a-icon
        class="home-icon"
        :style="{ fontSize: '16px' }"
        theme="filled"
        type="home"
        @click="backToHome"
      />
    </div>
  </div>
</template>

<script>
import wx from '@tencent/jweixin';

export default {
  name: 'BackToHomePage',
  methods: {
    // 返回首页
    backToHome() {
      // 首页点击返回, 直接关闭网页
      wx.miniProgram.redirectTo({
        url: '/pages/index/index',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-top: 16px;
  padding-left: 24px;
  &-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-icon {
    width: 16px;
    height: 16px;
  }
}
</style>
