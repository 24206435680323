/*
 * @Author: dannylo
 * @Date: 2022-03-09 19:33:19
 * @LastEditors: dannylo
 * @LastEditTime: 2022-03-09 19:39:54
 * @Description: 修改或增加meta
 */

// 禁止缩放
const content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';

const addMeta = (name, content) => {
  // 手动添加mate标签
  const meta = document.createElement('meta');
  meta.content = content;
  meta.name = name;
  document.getElementsByTagName('head')[0].appendChild(meta);
};

export const setMeta = (name) => {
  const meta = document.querySelector(`meta[name=${name}]`);
  if (meta) {
    meta.setAttribute('content', content);
    return;
  }
  addMeta(name, content);
};
