import wujiFetch from '@utils/wujiFetch';
const getRollbackHeaders = (id) => ({ rollback: id });
const list = async (params, query) => await wujiFetch(`/api/xy/oplog?${query}&${new URLSearchParams(params)}`);
const rollback = async (params) => {
    const { projectId, key, time } = params;
    const url = `/api/xy/design/oplog/rollback?projectId=${projectId}${time ? `&time=${time}` : ''}`;
    return await wujiFetch(url, {
        method: 'get',
        headers: getRollbackHeaders(key),
    });
};
export default {
    list,
    rollback,
};
