import { getAppParams, getBaseAppPath } from '@utils/path';

export default {
  state: {
    env: getAppParams('env') || 'dev', // 运行时环境
    branch: getAppParams('branch') || 'master', // 运行时分支
    projectId: getAppParams('projectId'), // 运行时projectId
    envList: [], // 应用环境变量
  },
  getters: {
    getAppPath(state) {
      return getBaseAppPath({ env: state.env, projectId: state.projectId });
    },
  },
  actions: {
  },
  mutations: {
    updateApp(state, payload) {
      Object.assign(state, payload);
    },
  },
};
