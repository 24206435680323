import { defineComponent, ref } from '@vue/composition-api';
import CodeConflictEditor from '../TextMerge/CodeConflictEditor';
import styles from './merge.module.scss';
export default defineComponent({
    name: 'ConflictsModal',
    props: {
        options: {
            type: Object,
            required: true,
        },
    },
    setup(_props) {
        const visible = ref(true);
        const conflictDialog = ref();
        const isFullScreen = ref(false);
        const toggleFullScreen = () => {
            isFullScreen.value = !isFullScreen.value;
        };
        return {
            visible,
            toggleFullScreen,
            conflictDialog,
            isFullScreen,
        };
    },
    methods: {
        handleResolved(data) {
            this.$emit('message', {
                success: true,
                data,
            });
            this.visible = false;
        },
        handleCancel() {
            this.$emit('message', {
                success: false,
                message: 'cancel',
            });
            this.visible = false;
        },
    },
    render() {
        return (<a-modal ref="conflictDialog" width="900px" visible={this.visible} title="请解决冲突" onCancel={this.handleCancel} footer={null} wrapClassName={this.isFullScreen ? styles.full_screen_modal : styles.normal_modal}>
        <CodeConflictEditor result={this.options.result.result.join('\n')} localCode={this.options.localData} onChange={this.handleResolved} onCancel={this.handleCancel} isFullScreen={this.isFullScreen} onToggleFullScreen={this.toggleFullScreen} enableSkipMerge={false} enableToggleFullScreen={true} type={this.options.type}/>
      </a-modal>);
    },
});
