<template>
  <a-modal
    :width="500"
    :visible="true"
    :centered="true"
    :closable="false"
    :footer="null"
    @cancel="$emit('close')"
    @ok="$emit('close')"
  >
    <div
      class="qr-code"
      @click="handlerQRCode"
    >
      <div v-if="fetched">
        <img
          v-if="miniCode"
          class="icon"
          :src="`data:image/png;base64,${miniCode}`"
        >
        <div v-else>
          {{ errorMsg }}
        </div>
      </div>
      <div
        v-else
        class="loading"
      >
        <span class="loading-tips">生成中...</span>
        <a-spin size="large" />
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { getServiceId } from '@/utils/serviceInfo';

export default {
  name: 'MiniprogramQRCode',
  data() {
    return {
      projectConfig: window.RUNTIME_CONFIGS,
      pageData: window.xy_runtime_pages,
      fetched: false,
      miniCode: '',
      errorMsg: '',
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions(['fetchMiniprogramQRCode']),
    async getData() {
      try {
        const params = {
          serviceId: getServiceId(),
          projectId: this.projectConfig.projectId,
          path: this.$route.fullPath,
          env: this.projectConfig.env,
        };
        const res = await this.fetchMiniprogramQRCode(params);
        const { done, img } = res;
        if (done) {
          this.miniCode = img;
        } else {
          this.handlerError();
        }
      } catch (error) {
        this.handlerError();
      } finally {
        this.fetched = true;
      }
    },
    handlerError() {
      // 拉不出来 跳首屏
      this.errorMsg = '服务器开小差，请重新生成二维码';
      this.errorStatus = true;
    },
    handlerQRCode() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.qr-code {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  z-index: 100;
  .icon {
    width: 375px;
    height: 375px;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .loading-tips {
    padding: 12px 0;
  }
}
</style>
