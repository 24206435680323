<template>
  <div class="error_message_container">
    <span class="error_text">
      加载失败，请保存页面再刷新
    </span>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.error_message_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .error_text{
    color:var(--wuji-primary-color);
  }
}
</style>
