import Vue from 'vue';
import Vuex from 'vuex';
import loadingPlugin from './plugins/loadingPlugin';

import project from './project/project';
import page from './page/index';
import app from './app';
import env from './env';
import version from './version';
import ui from './ui';
import runtime from './runtime';
import plugin from './plugin';
import template from './template';
import draft from './drafts';
import projectModifyRecord from './projectModifyRecord';
import componentHub from './componenthub';
import componentProjectLock from './component-project-lock';
import projectGroup from './projectGroup/index';
import miniprogram from './miniprogram/miniprogram';
import compTemplate from './compTemplate';
import branch from './branch';
import subProject from './subProject/subProject';
import globalSearch from './globalSearch';
import navigation from './navigation';
import layout from './layout';
import announcement from './announcement';
import datatable from './datatable';
import material from './material';
import dataSource from './dataSource';
import { permissionApi } from '@/services';
import banner from './banner';
import offline from './offline';
import hostConfig from './host-config';
import skin from './skin';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    project,
    page,
    ui,
    app,
    env,
    version,
    runtime,
    // 只保留 permissions state
    rbac: {
      namespaced: true,
      state: {
        permissions: [],
      },
      actions: {
        async fetchPermissions({ commit }, { projectId, env }) {
          const permissions = await permissionApi.fetchPermissions(projectId, env);
          commit('setPermissions', { permissions });
        },
      },
      mutations: {
        setPermissions(state, { permissions }) {
          Object.assign(state, { permissions });
        },
      },
    },
    plugin,
    template,
    draft,
    projectModifyRecord,
    componentHub,
    projectGroup,
    miniprogram,
    compTemplate,
    branch,
    material,
    subProject,
    globalSearch,
    componentProjectLock,
    navigation,
    layout,
    announcement,
    datatable,
    dataSource,
    banner,
    offline,
    hostConfig,
    skin,
  },
  plugins: [loadingPlugin],
});

export default store;

export const useStore = () => store;
