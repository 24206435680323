/*
 * @Author: dannylo
 * @Date: 2022-05-24 17:13:58
 * @LastEditors: dannylo
 * @LastEditTime: 2022-06-02 09:43:55
 * @Description: 子应用相关页面请求
 */
import logger from '@utils/logger';
import wujiFetch from '@/utils/wujiFetch';
import getXyManagePathPrefix from '@utils/getXyManagePathPrefix';
import { message } from 'ant-design-vue';

// 是否需要更新
const fetchProjecUpdated = async (projectId, parentId) => {
  const projecUpdated = await wujiFetch(`${getXyManagePathPrefix()}/project/sub/checkupdate/${projectId}?id=${parentId}`);
  return projecUpdated;
};

// 请求更新接口
const fetchProjecUpdate = async (payload, projectId) => {
  const projecUpdated = await wujiFetch(`${getXyManagePathPrefix()}/project/sub/update/${projectId}`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return projecUpdated;
};

export default {
  state: {
    isUpdated: false,
    updateList: [],
    updateResult: [],
    parentVersionId: 0,
  },
  getters: {
  },
  actions: {
    // 获取应用是否需要更新
    async fetchProjecIsUpdated({ commit }, { projectId, parentId }) {
      try {
        const projectUpdated = await fetchProjecUpdated(projectId, parentId);
        commit('updateSubProject', {
          ...projectUpdated,
        });
        return projectUpdated;
      } catch (e) {
        logger.error('拉取应用是否更新数据失败：', e);
        message.error(`拉取应用是否更新数据失败：${e.message}`);
      }
    },
    // 更新应用
    async fetchProjectUpdate({ commit }, { payload, projectId }) {
      try {
        const projectUpdated = await fetchProjecUpdate(payload, projectId);
        commit('updateSubProject', {
          ...projectUpdated,
        });
        // 自动发布版本
        return projectUpdated;
      } catch (e) {
        logger.error('更新应用数据失败：', e);
        message.error(`更新应用数据失败：${e.message}`);
      }
    },
  },
  mutations: {
    updateSubProject(state, payload) {
      Object.assign(state, payload);
    },
  },
};
