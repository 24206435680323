import Vue from 'vue';
import { useStore as getStore } from '@store/index';
import { useRouter } from '@/router/useRouter';
import pageLoader from '@loaders/page/loader';
import logger from '@/utils/logger';
import { checkPermission } from '@/pages/modules/permission/utils';
import { PageletContext } from '@utils/global/api';
import { getPageRequiredComps, getCompsList, getWujiCompInstance, registerAsyncComponents, COMPONENTS_WITH_UNKNOWN_DEPS, } from '@/utils/comps-loader';
import { getHubListQuery } from '@/utils/componentHub';
import componentWrapByUIcore from '@/utils/componentWrapByUIcore';
import XyModal from '@components/modal';
import XyPage from '@components/page';
import XyNav from '@components/builtin/xy-nav';
import XySidebar from '@components/builtin/xy-sidebar';
import XyHeader from '@components/builtin/xy-header';
import XyFooter from '@components/builtin/xy-footer';
import XyPageletContainer from '@components/pagelet';
import XyPageletRenderer from '@components/pagelet-renderer';
import { getDIRComp } from '@components/designerInRuntime';
import componentReport from '@/utils/reporter/component';
export async function openGlobalModal(opts) {
    const { pageId, globalModalSetting = { keepAlive: false, suffix: '' }, mode, projectId, data, config, } = opts;
    const { keepAlive, suffix } = globalModalSetting;
    const modalId = suffix ? `${pageId}_${suffix}` : pageId;
    const store = getStore();
    store.commit('layout/initGlobalModalById', { id: modalId, opt: { keepAlive } });
    const { router } = useRouter();
    // 预览时通过config传入
    const pageDetail = config ?? await pageLoader.getPageDetail({
        projectId,
        pageId,
    });
    const { allPagelets, pageletModals, childrenData } = getPagelets(pageDetail);
    const el = document.createElement('div');
    return new Promise((resolveGlobalModal) => {
        const instance = new Vue({
            store,
            router: router.value,
            provide() {
                return {
                    wContext: this.wContext,
                };
            },
            data() {
                return {
                    w: new PageletContext({ mode, pageletContainer: this }),
                    wContext: {
                        id: modalId,
                        type: 'globalModal',
                        env: 'dev',
                        config: pageDetail,
                        source: pageDetail.pageData,
                        projectConfig: null,
                        props: {},
                        data,
                        dataManager: null,
                        components: [],
                        childrenData,
                        pageList: null,
                        modalList: [],
                        container: this,
                        lessCode: null,
                        parent: null,
                        parentWContext: null,
                        isMiniprogram: false,
                        isNarrowScreen: false,
                        // 显示弹窗
                        showModal: async (id, data) => {
                            logger.info('[modal][globalModal].show.params', id, data);
                            if (childrenData[id]) {
                                childrenData[id].visible = true;
                                childrenData[id].data = data;
                                const closeRes = await new Promise((resolve) => {
                                    // 主页面监听所有弹窗的关闭事件
                                    this.$once(`dialogClose:${id}`, resolve);
                                });
                                logger.info('[modal][globalModal].show.res', closeRes);
                                return closeRes;
                            }
                            throw new Error(`找不到[${id}]对应的弹窗`);
                        },
                        // 隐藏弹窗
                        hideModal: async (id, ...args) => {
                            logger.info('[modal][globalModal].hide', id, ...args);
                            if (childrenData[id]) {
                                childrenData[id].visible = false;
                            }
                            await new Promise((rs) => {
                                // 延迟 300ms 后触发一次，兼容页面跳转后弹窗组件没有触发动画和 afterClose 事件导致事件流没有执行完毕
                                setTimeout(() => this.handleModalAfterClose(id), 300);
                                this.$once(`afterDialogClose:${id}`, () => {
                                    // 向 lessCode 触发弹窗关闭事件
                                    this.$emit(`dialogClose:${id}`, ...args);
                                    rs();
                                });
                            });
                        },
                        // 刷新 ui-core
                        reloadUcRenderer: (id = 'default') => {
                            this.reloadUcRenderer(id);
                        },
                    },
                    currentPagelet: null,
                    resolveHide: null,
                    resolveGlobalModal,
                    loading: true,
                };
            },
            computed: {
                currentBranch() {
                    return store.state.branch.currentBranch;
                },
                branchId() {
                    return window.RUNTIME_CONFIGS?.branch || this.currentBranch?.branch || '';
                },
            },
            methods: {
                handleModalAfterClose(id) {
                    // 确保弹窗关闭后再触发
                    setTimeout(() => this.$emit(`afterDialogClose:${id}`), 0);
                },
                async handleGlobalModalClose(hideData) {
                    await this.w.hideGlobalModal(modalId, hideData);
                },
                reloadUcRenderer(id) {
                    if (id === '*') {
                        // eslint-disable-next-line no-param-reassign
                        Object.entries(this.wContext.childrenData).forEach(([key, item]) => {
                            item.visible = false;
                            this.reloadPageletModal(key);
                        });
                        this.reloadGlobalModal();
                    }
                    else if (id === 'default') {
                        this.reloadGlobalModal();
                    }
                    else {
                        this.reloadPageletModal(id);
                    }
                },
                // 刷新全局弹窗
                reloadGlobalModal() {
                    const wSysGlobalModal = this.$refs[`wSysGlobalModal_${modalId}`];
                    if (wSysGlobalModal) {
                        wSysGlobalModal.reloadUcRenderer();
                    }
                },
                // 刷新指定页面片
                reloadPageletModal(id) {
                    const wSysModal = this.$refs[`wSysModal_${id}`];
                    if (wSysModal) {
                        wSysModal.reloadUcRenderer();
                    }
                },
                async initComps() {
                    // 初始化组件
                    if (this.wContext.components?.length === 0) {
                        performance.mark('COMPONENT_INIT_BEGIN');
                        // 收集页面所需要的组件
                        const { categories, keys } = getPageRequiredComps(this.wContext.config?.pageConfig);
                        // 非主页面的组件，标记为懒加载
                        const { keys: nonPrimaryPageKeys } = getPageRequiredComps(this.wContext.config?.pageConfig.slice(1));
                        // 取非主页面组件和总体组件的差集，得到可以懒加载的组件
                        const lazyLoadComponents = nonPrimaryPageKeys
                            .filter(key => !keys.includes(key));
                        let lazyLoadRestOfAllComponents = false;
                        // 特殊逻辑 如果有编辑器在运行时 就获取全部组件转化为懒加载组件
                        if (keys.some((x) => COMPONENTS_WITH_UNKNOWN_DEPS.has(x))) {
                            lazyLoadRestOfAllComponents = true;
                        }
                        const groups = this.w.projectInfo?.componentHubGroupsList?.split(',') || [];
                        const compsList = await getCompsList({
                            compsKey: keys,
                            register: true,
                            dependencies: {
                                categories,
                            },
                            componentHubQueryObject: {
                                projectId,
                                projectEnv: this.env,
                                hubList: getHubListQuery(groups),
                                componentRules: this.$route?.query?.componentRules,
                                branch: this.branchId,
                            },
                            forceRefresh: true,
                            lazyLoadComponents,
                            lazyLoadRestOfAllComponents,
                        });
                        // 异步注册没有收集到的组件
                        registerAsyncComponents(Object.keys(compsList ?? {}));
                        const finalComponents = Object.keys(compsList)
                            .map(key => componentWrapByUIcore(key, compsList[key]))
                            .filter(item => !!item);
                        const designerInRuntime = getDIRComp(null);
                        finalComponents.push(designerInRuntime);
                        const builtin = [
                            XyModal, XyPage, XyNav, XySidebar, XyHeader, XyFooter, XyPageletContainer, XyPageletRenderer,
                        ];
                        finalComponents.push(...builtin);
                        this.wContext.components = Object.seal(finalComponents);
                        performance.mark('COMPONENT_INIT_END');
                        componentReport();
                    }
                },
            },
            async created() {
                this.wContext.env = this.w?.env;
                this.wContext.projectConfig = this.w?.projectInfo;
                this.wContext.pageList = this.w?.pageList;
                this.wContext.isMiniprogram = this.w?.isMiniprogram;
                this.wContext.isNarrowScreen = this.w?.isNarrowScreen;
                this.wContext.hasPermission = (permission) => checkPermission(this.w.user, permission, pageId);
                await this.initComps();
                this.loading = false;
            },
            beforeDestroy() {
                const wujiComp = getWujiCompInstance();
                wujiComp.removeListener('hmrCompChange', this.hmrCompChange);
            },
            render() {
                return this.loading ? <div></div> : (<div>
            <w-sys-modal id="default" globalModalId={modalId} ref={`wSysGlobalModal_${modalId}`} mode={mode} visible={true} pagelets={allPagelets} keepAlive={keepAlive} params={data} on={{
                        'after-close': () => this.handleGlobalModalClose(),
                    }}/>
            {pageletModals.map(m => (<w-sys-modal id={m.id} key={`${this.$route.path}_${m.id}`} ref={`wSysModal_${m.id}`} mode={mode} pagelets={allPagelets} visible={childrenData[m.id].visible} params={childrenData[m.id].data} on={{
                            'after-close': () => this.handleModalAfterClose(m.id),
                        }}/>))}
          </div>);
            },
        });
        store.commit('layout/setGlobalModalInstanceById', { id: modalId, instance });
        document.body.appendChild(el);
        instance.$mount();
    });
}
function getPagelets(pageDetail) {
    const allPagelets = Array.isArray(pageDetail.pageConfig) ? pageDetail.pageConfig : [];
    const pageletModals = [];
    const childrenData = {};
    allPagelets.forEach((item) => {
        if ((item.xyType === 'modal' || item.layout.type === 'xy-modal-container') && item.id !== 'default') {
            // [上下文]
            childrenData[item.id] = { visible: false, data: {} };
            // 弹窗
            pageletModals.push(item);
        }
    });
    return {
        allPagelets,
        pageletModals,
        childrenData,
    };
}
