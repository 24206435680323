import fetch from '@utils/fetch';
import { message } from 'ant-design-vue';
import { loadScript, getDataOrThrow } from '@utils/common';
import wujiComp from '@tencent/xy-compsloader';
import logger from '@utils/logger';
import { getLocalBaseApiUrl, getLocalBaseUrl } from '@/config/cli-settings';
const pageDebugPromise = {};
const pages = {};

const throwError = (id, prefix) => {
  throw new Error(`${prefix} load page debug file "${id}" loading failed!`);
};

const loadModule = async ({ src, type }) => {
  if (!pageDebugPromise[type]) {
    pageDebugPromise[type] = loadScript(src);
  }

  await pageDebugPromise[type];

  const layoutInfo = await wujiComp.getLayout([type]);
  if (!layoutInfo) {
    throwError(type, 'load module');
  }
  pages[type] = typeof layoutInfo === 'function' ? layoutInfo() : layoutInfo;

  return pages[type].comp;
};

class LayoutLoader {
  constructor() {
    this.debug = false;
    this.port = 9998;
  }

  isDebug() {
    return !!this.debug;
  }

  initHmr(port) {
    this.debug = true;
    this.port = port;
  }

  // debug环境
  async loadDebugLayout() {
    const layoutMap = {};
    const layouts = await fetch(`${getLocalBaseApiUrl(this.port)}/layout`, {
      method: 'GET',
      credentials: 'omit',
    }, 5000, false).then(getDataOrThrow);
    layouts.forEach((layout) => {
      if (layout?.type) {
        const { type } = layout;
        layoutMap[type] = {
          type,
          src: `${getLocalBaseUrl(this.port)}/${type}/index.js?t=${+new Date}`,
        };
      }
    });

    return layoutMap;
  }

  async getLayout({ type }) {
    let targetLayout;
    try {
      let layouts;
      if (this.debug) {
        // 加载本地 HMR 的组件
        layouts = await this.loadDebugLayout();
      } else {
        // 加载远程组件
        layouts = window.xy_runtime_layout ?? null;
      }
      if (layouts?.[type]?.src) {
        targetLayout = await this.load({ src: layouts[type].src, type });
      }
    } catch (err) {
      logger.error('[layout-loader].getLayout', err);
    } finally {
      return targetLayout;
    }
  }

  async load({ src, type }) {
    let layout;

    try {
      layout = await loadModule({ src, type });
    } catch (err) {
      message.error(err.message);
    }

    return layout;
  }
}

export default new LayoutLoader();
