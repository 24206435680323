export const ELE_ID_RUNTIME_CONTENT = 'xy-runtime-content'; // 运行时内容容器的id
export const ELE_ID_PREVIEW_CONTENT = 'xy-preview-content-container'; // 编辑时预览内容容器的id
// 将页面滚动到目标位置
export const pageScrollTo = (option = { top: 0, behavior: 'auto' }) => {
    const elDocument = document.documentElement;
    const elContent = document.getElementById(ELE_ID_RUNTIME_CONTENT);
    if (elContent) {
        // 运行时
        elDocument?.scrollTo(option);
        elContent?.scrollTo(option);
    }
    else {
        // 编辑时预览
        const elPreview = document.getElementById(ELE_ID_PREVIEW_CONTENT);
        elPreview?.scrollTo(option);
    }
};
// 获取内容区域的可视高度
export const getContentClientHeight = () => {
    const elDocument = document.documentElement;
    const elContent = document.getElementById(ELE_ID_RUNTIME_CONTENT);
    // 运行时
    if (elContent)
        return elDocument?.clientHeight;
    // 编辑时预览
    const elPreview = document.getElementById(ELE_ID_PREVIEW_CONTENT);
    return elPreview?.clientHeight;
};
// 获取页面容器 DOM 元素
export const getPageContainer = () => {
    const elPreview = document.getElementById(ELE_ID_PREVIEW_CONTENT);
    // 编辑时
    if (elPreview)
        return elPreview;
    // 运行时
    const layoutInstance = window.xy_runtime_default_layout_instance;
    const isNavFixTop = !!layoutInstance?.isNavFixTop;
    if (isNavFixTop) {
        return document.getElementById(ELE_ID_RUNTIME_CONTENT);
    }
    return document.body;
};
// 将页面滚动到页面顶部
export const pageScrollToTop = ({ behavior = 'auto' } = {}) => {
    pageScrollTo({ top: 0, behavior });
};
// 将页面滚动到页面底部
export const pageScrollToBottom = ({ behavior = 'auto' } = {}) => {
    const elDocument = document.documentElement;
    const elContent = document.getElementById(ELE_ID_RUNTIME_CONTENT);
    const elPreview = document.getElementById(ELE_ID_PREVIEW_CONTENT);
    // 计算最大的滚动高度
    const top = Math.max(...[
        elDocument?.scrollHeight,
        elContent?.scrollHeight ?? 0,
        elPreview?.scrollHeight ?? 0,
    ]);
    pageScrollTo({ top, behavior });
};
// 是否组合键触发的点击事件
const isCombineKey = (e, target) => {
    const isSelf = !target || target === '_self';
    const { altKey, ctrlKey, shiftKey, metaKey } = e;
    return altKey || ctrlKey || shiftKey || metaKey || !isSelf;
};
// 是否 # 开头的路径
const isSharpHref = (href) => href?.startsWith('#');
// 是否单 / 开头的路径
const isSingleSlashHref = (href) => href && !href.startsWith('//') && href.startsWith('/');
// 代理a标签跳转同应用的页面路径
export function proxyATagToWujiPage(router) {
    const { base } = router?.options ?? {};
    const pages = window.xy_runtime_pages;
    if (typeof base === 'string' && Array.isArray(pages)) {
        document.addEventListener('click', (e) => {
            // FireFox 和 Safari 没有 path 字段, 可以通过 composedPath 方法来获取
            const elPath = e.path ?? e.composedPath?.() ?? [];
            const aTag = elPath.find((item) => item?.nodeName?.toLocaleLowerCase() === 'a');
            if (!aTag)
                return;
            const href = aTag.getAttribute('href');
            const parseHref = aTag.href;
            const { protocol, host } = location;
            const projectPrefix = `${protocol}//${host}${base}`;
            // # 开头判断为锚点, 不做处理
            if (isSharpHref(href))
                return;
            if (isSingleSlashHref(href)) {
                // / 开头的路径
                const target = aTag.getAttribute('target');
                let shortHref = `${href.replace(`${base}/`, '')}`;
                if (!shortHref.startsWith('/'))
                    shortHref = `/${shortHref}`;
                // 只有路径匹配时才判断跳转
                if (pages.some(p => shortHref.startsWith(p.path))) {
                    const fullHref = `${base}${shortHref}`;
                    if (isCombineKey(e, target)) {
                        aTag.setAttribute('href', fullHref);
                        setTimeout(() => aTag.setAttribute('href', href), 0);
                    }
                    else {
                        e.preventDefault();
                        router.push({ path: shortHref });
                    }
                }
            }
            else if (parseHref?.startsWith(`${projectPrefix}/`)) {
                // 解析全路径判断是否为同应用的页面
                const target = aTag.getAttribute('target');
                let shortHref = parseHref.replace(projectPrefix, '');
                if (!shortHref.startsWith('/'))
                    shortHref = `/${shortHref}`;
                // 只有路径匹配时才判断跳转
                if (pages.some(p => shortHref.startsWith(p.path))) {
                    if (!isCombineKey(e, target)) {
                        e.preventDefault();
                        router.push({ path: shortHref });
                    }
                }
            }
        });
    }
}
// 是否 Mac 系统
export const IS_MAC_SYSTEM = /macintosh|mac os x/i.test(navigator.userAgent);
// 系统自定义滚动条样式
const WUJI_SCROLLBAR = `
::-webkit-scrollbar {
  width: 10px;
  background-color: #ffffff;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar:horizontal {
  height: 6px;
}
`;
export function useWujiScrollbar() {
    // 非 Mac 系统使用自定义样式
    if (!IS_MAC_SYSTEM) {
        const style = document.createElement('style');
        style.innerHTML = WUJI_SCROLLBAR;
        document.getElementsByTagName('head')[0].appendChild(style);
    }
}
