import WujiSourceController from './wuji';
import CollectionSourceController from './collection';

import { getPatchedDataSchema } from '@tencent/data-schema-core';
import logger from '@utils/logger';

export const SourceControllerCtor = {
  wuji: WujiSourceController,
  collection: CollectionSourceController,
};

export function createSourceController(sourceConfig, xiaoyaoPlugin, renderer) {
  const Ctor = SourceControllerCtor[sourceConfig.type];
  if (!Ctor) return null;

  const controller = new Ctor({
    propsData: {
      config: sourceConfig,
      renderer,
      pluginInstance: xiaoyaoPlugin,
    },
    parent: xiaoyaoPlugin,
  });

  const handleLoadSchema = () => {
    // eslint-disable-next-line no-param-reassign
    xiaoyaoPlugin.loadingSchema -= 1;

    // 揉一个新的 dataSchema 进去

    const { ...originalDataSchema } = controller.dataSchema;

    const rootDataSchema = { ...xiaoyaoPlugin.dataSchema };
    const placeholderIndex = rootDataSchema.fields.findIndex(x => x.id === sourceConfig.id);

    let ucTag = null;

    // wuji-source 特色
    const state = controller.S?.state;
    if (state) ucTag = state.ucTag || Object.getPrototypeOf(state).constructor.stateModel;  // 比如 “form”

    rootDataSchema.fields = [...rootDataSchema.fields];
    rootDataSchema.fields[placeholderIndex] = {
      ...getPatchedDataSchema(originalDataSchema),
      id: sourceConfig.id,
      title: sourceConfig.id + (sourceConfig.title ? ` (${sourceConfig.title})` : ''),
      'xy:sourceId': sourceConfig.id,
      'uc:tag': ucTag,
      'uc:allowCopyPath': false,
    };

    // eslint-disable-next-line no-param-reassign
    xiaoyaoPlugin.dataSchema = Object.freeze(rootDataSchema);
    logger.info('[UICoreXiaoyaoPlugin] DataSchema Updated!', rootDataSchema);
  };

  controller.$on('load-schema', handleLoadSchema);
  const destroyController = () => {
    controller.$destroy();
  };
  xiaoyaoPlugin.$once('hook:beforeDestroy', destroyController);
  controller.$once('hook:beforeDestroy', () => {
    xiaoyaoPlugin.$off('hook:beforeDestroy', destroyController);
  });

  return controller;
}
