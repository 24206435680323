/**
 * 专门给lesscode内使用的fetch，不要写入业务逻辑！
 */
import fetch from 'isomorphic-fetch';
import { addPathPrefix } from '@utils/path';
import getXyHeaders from './getXyHeaders';
import { isInBeacon, getAbsolutePath } from './beacon';

function parseJSON(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  }

  const error = new Error(response.statusText);
  error.code = -1;
  throw error;
}

export default function (url, data = {}) {
  let headers = data.headers || {};
  headers = { ...headers, ...getXyHeaders() };

  // eslint-disable-next-line
  if (window.__POWERED_BY_WUJIE__) {
    return fetch(addPathPrefix(url), { ...data, headers, credentials: 'include' })
      .then(parseJSON);
  }

  if (isInBeacon()) {
    return fetch(getAbsolutePath(addPathPrefix(url), true), {
      ...data,
      headers,
      credentials: 'include',
    }).then(parseJSON);
  }

  return fetch(addPathPrefix(url), data)
    .then(parseJSON);
}
