import keyBy from 'lodash/keyBy';
import queryString from 'query-string';
import logger from '@/utils/logger';

/**
 * 获取元素上的attr属性
 */
export const getAttrs = ($el) => {
  const attrs = keyBy([...$el.attributes]
    .map(({ name, value }) => ({ name, value })), 'name');
  return attrs;
};

/**
 * 递归收集属性项
 */
export const getElementParams = (element, attr, rootAttr) => {
  let $ele = element;

  const params = {};
  const getParams = (str) => {
    Object.entries(queryString.parse(str)).forEach(([key, value]) => {
      if (!params[key]) {
        params[key] = value;
      }
    });
  };

  while ($ele && !$ele.hasAttribute?.(rootAttr)) {
    if ($ele?.hasAttribute?.(attr)) {
      getParams($ele.getAttribute(attr));
    }

    $ele = $ele.parentNode;
  }

  return {
    $root: $ele,
    params,
  };
};

/**
  * 绑定 曝光/结束曝光事件
  * 结束曝光分两种情况
  * 一种是单页跳转，不会触发document.visibilitychange，需要手动上报
  * 另一种是直接关掉浏览器，或者新开窗口，默认由组件内部进行上报
  */
export const addPgEventListener = ({ pgIn, pgOut }) => {
  const handler = () => {
    try {
      if (document.visibilityState === 'hidden') {
        pgOut();
      } else {
        pgIn();
      }
    } catch (err) {
      logger.error(err);
    }
  };
  document.addEventListener('visibilitychange', handler);
  return handler;
};

/**
 * 注销 曝光/结束曝光 事件
 */
export const removePgEventListener = (handler) => {
  document.removeEventListener('visibilitychange', handler);
};

/**
 * 忽略上报的情况
 */
export const reportIgnored = (() => {
  // 如果是本地开发，不要上报
  const loc = window.location;
  const { hostname } = loc;
  const isLocalDev = hostname === '127.0.0.1' || hostname === 'localhost';

  // 如果是无极的E2E测试，不要上报
  const qs = queryString.parse(loc.search);
  return qs.wuji_from === 'E2ETEST' || isLocalDev;
})();
