import { onBeforeMount, onBeforeUnmount } from '@vue/composition-api';
import { getDocLink } from '@utils/links';
import { getUserId } from '@utils/userInfo';
export default function useXiaoman(open = false, appid = '') {
    if (open && appid) {
        let script;
        onBeforeMount(() => {
            const src = getDocLink().XIAOMAN_JS;
            window.xmMeterSettings = {
                app_id: appid,
                rtx: getUserId(),
            };
            script = document.createElement('script');
            script.src = src;
            script.async = true;
            document.getElementsByTagName('head')[0].appendChild(script);
        });
        onBeforeUnmount(() => {
            script?.remove();
        });
    }
    return {};
}
;
