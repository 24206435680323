<template>
  <component
    :is="renderInfo.type"
    v-if="renderInfo"
    v-model="editValue"
    class="uicoreMountedOmniInput"
    v-bind="renderInfo.props"
    style="margin-bottom: 0"
  />
</template>

<script>
import findXyComponentForField from '../findXyComponentForField.js';

export default {
  name: 'WujiOmniInputMounter',
  props: {
    path: { type: String, required: true },
    value: { required: true },
    schema: { type: Object, default: null },
    readonly: { type: Boolean, default: false },
  },
  computed: {
    renderInfo() {
      const { schema } = this;
      if (!schema) return null;

      return findXyComponentForField(schema.ui.settings, {
        readonly: this.readonly,
      });
    },
    editValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      },
    },
  },
};
</script>

<style lang="scss">
.uicoreMountedOmniInput {
  padding-bottom: 0 !important;  // 佛了
  > .ant-row-flex > .wuji-component-label {
    display: none;

    + .ant-col-18 {
      width: 100%;
      float: none;
      flex-grow: 1;
    }
  }
}
</style>
