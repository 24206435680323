import Vue from 'vue';
import { fetchProjectLock, fetchComponentInfo } from '@utils/componentHub';
/**
 * 组件管理相关的信息
 */
export default {
    state: {
        componentInfo: null,
        projectLock: {}, // 应用锁信息
    },
    actions: {
        async fetchComponentInfo({ commit }, { projectId, hubList, extraQuery = {} }) {
            const componentInfo = await fetchComponentInfo(projectId, hubList, extraQuery);
            commit('setComponentHub', { componentInfo });
        },
        async fetchProjectLock({ commit }, { projectId, projectEnv }) {
            const projectLock = await fetchProjectLock(projectId, projectEnv);
            commit('setProjectLock', { projectLock, env: projectEnv });
        },
    },
    mutations: {
        setComponentHub(state, { componentInfo }) {
            state.componentInfo = componentInfo;
        },
        setProjectLock(state, { projectLock, env }) {
            Vue.set(state.projectLock, env, projectLock);
        },
    },
};
