import { createDecorator } from 'vue-class-component';
import { get, isEmpty, last } from 'lodash';
/** 从一条链上 保持先后顺序找到符合回调数组的节点 */
function searchNodesInDfsPath(source, cbArray) {
    const stack = [];
    // 两个指针跑两个数组匹配出合适的节点链路
    const searchInternal = (sourceIndex, cbIndex) => {
        if (cbIndex >= cbArray.length)
            return true;
        for (let index = sourceIndex; index < source.length; index++) {
            if (cbArray[cbIndex]?.(source[index])) {
                stack.push(source[index]);
                const res = searchInternal(index + 1, cbIndex + 1);
                if (res)
                    return true;
                stack.pop();
            }
        }
        return false;
    };
    const res = searchInternal(0, 0);
    if (res) {
        return stack;
    }
    return [];
}
/** 从vnode树root上跑出每一条到叶子节点的路径 */
function* dfsVnodeIter(root) {
    // 递归栈 深拷贝后即可用作dfs路径
    const stack = [root];
    // 纪录Vue节点被遍历到了第几个child
    const childIndexMap = new WeakMap();
    childIndexMap.set(root, 0);
    while (stack.length) {
        const node = stack.pop();
        const childIndex = childIndexMap.get(node);
        // dfs子节点
        if (childIndex < node.$children.length) {
            // 下一个儿子待遍历
            stack.push(node);
            childIndexMap.set(node, childIndex + 1);
            // 迭代当前子节点
            const child = node.$children[childIndex];
            stack.push(child);
            childIndexMap.set(child, 0);
        }
        else if (node.$children.length === 0) {
            // 当前节点遍历结束 且是叶子节点出栈 压回叶子抛出结果
            stack.push(node);
            yield stack;
            // 退到父级节点
            stack.pop();
        }
    }
}
/** 给一个filter_callback数组 找到位于vnode root到叶子节点路径上符合条件的链路 */
export function* vnodeTreeSearch(root, searchArray, options = {}) {
    for (const nodeStack of dfsVnodeIter(root)) {
        const res = searchNodesInDfsPath(nodeStack, searchArray);
        if (!isEmpty(res)) {
            {
                // 这个位置找到了 应该 跳过递归栈中的当前找到最末的节点的子树遍历
                while (!isEmpty(nodeStack) && last(nodeStack) !== last(res)) {
                    nodeStack.pop();
                }
                // 此时 栈顶 是 最深的结果 dfsVnodeIter里的yield结束之后会pop出去
            }
            if (options.returnLast) {
                yield [last(res)];
            }
            else {
                yield res;
            }
        }
    }
}
export function searchVNode(...args) {
    return Array.from(vnodeTreeSearch(...args, { returnLast: true })).flat()[0];
}
/**
 * decorator of a watch from props to data
 * @param  path the path or the expression to observe
 * @param  watchOptions
 */
export function WatchProps(path, watchOptions = {}) {
    return createDecorator((componentOptions, dataKey) => {
        const watch = get(componentOptions, 'watch', Object.create(null));
        componentOptions.watch = watch;
        // 保存之前的watcher
        if (typeof watch[path] === 'object' && !Array.isArray(watch[path])) {
            watch[path] = [watch[path]];
        }
        else if (typeof watch[path] === 'undefined') {
            watch[path] = [];
        }
        watch[path].push({
            handler(newVal) {
                this[dataKey] = newVal;
            },
            ...watchOptions,
        });
    });
}
/** 每帧去查看组件的位置 当组件停止运动后 resolve */
export function waitUntilScrollFinish(element, debounceFrame = 3) {
    let lastPos;
    let same = 0;
    const equalPos = () => {
        const pos = element.getBoundingClientRect();
        if (pos.top === lastPos?.top && pos.left === lastPos?.left) {
            same += 1;
        }
        lastPos = pos;
        if (same >= debounceFrame) {
            return true;
        }
    };
    return new Promise((r) => {
        const testPos = () => {
            if (equalPos()) {
                r(1);
                return;
            }
            requestAnimationFrame(testPos);
        };
        testPos();
    });
}
export function highlightString(vm, str, keyWord) {
    return vm.$createElement('span', { domProps: {
            innerHTML: str.replace(new RegExp(keyWord, 'g'), s => `<strong style="background: yellow;color: black;font-weight: normal;">${s}</strong>`),
        } });
}
