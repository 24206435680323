import SyncHook from './simple-hooks';
export const WUJI_DEPS_KEY = '_wuji_deps';
window[WUJI_DEPS_KEY] = {};
class DependencyLoader {
    registeredModules = new Set();
    hooks = {
        load: new SyncHook(),
        register: new SyncHook(),
    };
    constructor() {
        if (window[WUJI_DEPS_KEY])
            return;
        window[WUJI_DEPS_KEY] = {};
        window.wuji_dependency_loader = this;
    }
    async load(dependencies) {
        if (dependencies.length === 0)
            return [];
        dependencies.forEach((dep) => {
            this.register(dep);
        });
        const modules = await new Promise((resolve, reject) => {
            window.require(dependencies, (...modules) => {
                resolve(modules);
            }, (error) => {
                reject(error);
            });
        });
        dependencies.forEach((dependency, index) => {
            window[WUJI_DEPS_KEY][dependency] = modules[index];
        });
        this.hooks.load.call(dependencies);
        return modules;
    }
    register(module) {
        if (this.registeredModules.has(module)) {
            this.hooks.register.call(module);
            return;
        }
        window.require.config({
            paths: {
                [module]: `https://cdn.jsdelivr.net/npm/${module}?noext`,
            },
        });
        this.registeredModules.add(module);
        this.hooks.register.call(module);
    }
}
;
const dependencyLoader = new DependencyLoader();
window.wuji_dependency_loader = dependencyLoader;
export default dependencyLoader;
