import { addPathPrefix } from '@/utils/path';

export function getFlowJobPageURL({
  projectId = window.w.projectId,
  flowId,
  jobId = '',
  envId = window.w.env,  // TODO: support branch
  hashData = '',
}) {
  const url = new URL(location.href);
  url.pathname = addPathPrefix(`/xy/app/${envId}/${encodeURIComponent(projectId)}/wuji/workflow/flow/${encodeURIComponent(flowId)}`);
  url.search = '';
  url.searchParams.set('projectId', projectId);
  if (jobId) url.searchParams.set('jobId', jobId);
  if (hashData)url.hash = hashData;
  return url.toString();
}
