import { forEach } from 'lodash';
import { walkLayout } from '@tencent/ui-core/lib/utils';
import { uiCoreBuiltinComponents } from '@components/uicorePlugin/UICoreBuiltinComponents';
import { WUJI_COMPONENTS } from '@config/constant';
import { compsLoaderLogger as logger } from './logger';
/**
 * 无法收集依赖的组件
 *
 * 这些组件“可能”会依赖于一个特殊的组件列表，所以当页面上出现这些组件时，我们需要同时加载那个特殊的组件列表
 */
const COMPONENTS_WITH_SPECIAL_DEPS = new Set([
    'public-w-form',
    'public-w-smart-list',
    'public-w-new-list',
    'w-form-auto-editor',
    'public-w-auto-form',
    'public-w-uicore-designer',
]);
/**
 * COMPONENTS_WITH_SPECIAL_DEPS所依赖的special deps
 */
export const SPECIAL_DEPS = [
    ...Object.values(WUJI_COMPONENTS).map(component => component.type),
    // 为了遗留的应用，手动添加一下不满足上述规则的组件
    'public-w-input',
    'public-w-readonly',
    'public-w-button',
    'public-w-radio',
];
/** 可能会从用户数据里掏出来各种奇奇怪怪的组件去渲染 所以要全量懒加载*/
export const COMPONENTS_WITH_UNKNOWN_DEPS = new Set([
    'public-w-uicore-designer',
    'xy:autoFLowNodePagelet',
    'public-w-auto-form',
    'w-form-auto-editor',
]);
/**
 * 获取页面里所定义的组件
 *
 * @param {Array} 页面片对象数组
 * @return {Object} {keys, categories}组件key列表以及其依赖的分类列表
 */
export const getPageRequiredComps = (pagelets = []) => {
    const requiredComps = new Set();
    let loadSpecialDeps = false;
    pagelets.forEach((pagelet) => {
        const comps = getPageletRequiredComps(pagelet);
        [...comps].forEach((c) => {
            if (COMPONENTS_WITH_SPECIAL_DEPS.has(c))
                loadSpecialDeps = true;
            requiredComps.add(c);
        });
    });
    const categories = [];
    // TODO: 兼容现有无法收集必要组件的旧组件逻辑: 全量加载
    logger({
        functionName: 'getPageRequiredComps',
        content: `getPageRequiredComps.fixBefore ${JSON.stringify(Array.from(requiredComps), null, 2)}`,
    });
    if (loadSpecialDeps) {
        SPECIAL_DEPS.forEach((component) => {
            requiredComps.add(component);
        });
    }
    // 兼容同时出现在自定义组件和UiCore内置的同名组件的加载
    Object.keys(uiCoreBuiltinComponents).forEach((key) => {
        if (requiredComps?.has(key)) {
            requiredComps.add(`public-${key}`);
        }
    });
    logger({
        functionName: 'getPageRequiredComps',
        content: `getPageRequiredComps.fixAfter ${JSON.stringify(Array.from(requiredComps), null, 2)}`,
    });
    return { keys: Array.from(requiredComps), categories };
};
/**
 * 获取单个页面片里所定义的组件
 *
 * @param {Object} 页面片对象
 * @return {Array} 组件key列表
 */
export const getPageletRequiredComps = (pagelet) => {
    const comps = new Set();
    const collectTypes = (layout) => {
        if (!layout)
            return;
        walkLayout(layout, (step) => {
            comps.add(step.layout.type);
            forEach(step.layout.scopedSlots, subPagelet => collectTypes(subPagelet.layout));
        });
    };
    collectTypes(pagelet?.layout);
    return [...comps];
};
