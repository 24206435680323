import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import PermissionCheckbox from './PermissionCheckbox';
import styles from './permission.module.scss';
import { useActiveTabPerm } from './utils';
export default defineComponent({
    name: 'RelatedPermission',
    props: {
        projectId: {
            type: String,
            default: '',
            required: true,
        },
        env: {
            type: String,
            default: 'dev',
        },
        role: {
            type: String,
            default: '',
            required: true,
        },
        // 代表资源，如页面或流程
        rowResources: {
            type: Array,
            required: true,
        },
        // 代表权限
        colPerms: {
            type: Array,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        // 仅页面或流程相关权限
        rolePerms: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        isRelated: {
            type: Function,
            default: () => () => true,
        },
        isInherited: {
            type: Object,
            default: () => { },
        },
        pagePermissionList: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const maxRow = 80;
        const checkAll = ref(false);
        const checkAllLoading = ref(false);
        const singleColAllCheckMap = {};
        const singleRowAllCheckMap = {};
        const keyword = ref('');
        const readonlyOrTooManyRows = ref(props.readonly);
        const { activeRow } = useActiveTabPerm();
        const rolePermsMap = ref({});
        const filterRows = computed(() => {
            const q = keyword.value.toLowerCase();
            return props.rowResources.filter(r => r.name.toLowerCase().includes(q));
        });
        watch(() => [props.rolePerms, filterRows.value], () => {
            rolePermsMap.value = {};
            props.rolePerms.forEach((x) => {
                rolePermsMap.value[`${x.pageId}/${x.permission}`] = true;
            });
            let isAllChecked = true;
            filterRows.value.forEach((r) => {
                singleRowAllCheckMap[r.id] = props.colPerms.every((c) => {
                    if (!props.isRelated(c.id, r.id))
                        return true;
                    const key = `${r.id}/${c.id}`;
                    const has = rolePermsMap.value[key];
                    if (!has)
                        isAllChecked = false;
                    return has;
                });
            });
            props.colPerms.forEach((c) => {
                singleColAllCheckMap[c.id] = filterRows.value.every((r) => {
                    if (!props.isRelated(c.id, r.id))
                        return true;
                    const key = `${r.id}/${c.id}`;
                    const has = rolePermsMap.value[key];
                    if (!has)
                        isAllChecked = false;
                    return has;
                });
            });
            checkAll.value = isAllChecked;
            checkAllLoading.value = false;
        }, {
            immediate: true,
            deep: true,
        });
        return {
            maxRow,
            checkAll,
            checkAllLoading,
            singleColAllCheckMap,
            singleRowAllCheckMap,
            keyword,
            filterRows,
            readonlyOrTooManyRows,
            rolePermsMap,
            activeRow,
        };
    },
    render() {
        const renderPagination = () => {
            if (this.filterRows.length <= this.maxRow)
                return false;
            return {
                page: 1,
                size: 'small',
                defaultPageSize: 20,
                hideOnSinglePage: true,
            };
        };
        const checkChange = (e) => {
            const changePerms = Array.isArray(e.data) ? e.data : [e.data];
            const format = (changePerm) => {
                const data = changePerm;
                const row = this.rowResources.find(x => x.id === changePerm.pageId);
                const col = this.colPerms.find(x => x.id === changePerm.permission);
                data.permName = `${row?.name || changePerm.pageId}-${col?.name || changePerm.permission}`;
            };
            changePerms.forEach(x => format(x));
            this.$emit('checkChange', e);
        };
        const renderCheck = (permission, pageId) => {
            const isRelatedPage = this.isRelated(permission, pageId);
            return isRelatedPage
                ? <PermissionCheckbox projectId={this.projectId} env={this.env} role={this.role} rolePerms={this.rolePerms} permission={permission} pageId={pageId} readonly={this.readonlyOrTooManyRows} onCheckChange={checkChange}/>
                : <a-tooltip title="不相关"><span style="margin-left:4px">-</span></a-tooltip>;
        };
        const columns = [
            {
                title: this.title,
                dataIndex: 'name',
                key: 'name',
                width: 300,
            },
            ...(this.readonlyOrTooManyRows
                ? []
                : [
                    {
                        slots: { title: 'checkAll' },
                        width: 100,
                        customRender: (record) => (<a-checkbox checked={this.singleRowAllCheckMap[record.id]} onChange={(e) => {
                                const { checked } = e.target;
                                const changes = [];
                                let type = 'checked';
                                if (checked) {
                                    this.colPerms.forEach((x) => {
                                        const key = `${record.id}/${x.id}`;
                                        if (this.isRelated(x.id, record.id) && !this.rolePermsMap[key]) {
                                            changes.push({ pageId: record.id, permission: x.id });
                                        }
                                    });
                                }
                                else {
                                    changes.push(...this.rolePerms
                                        .filter(rp => rp.pageId === record.id)
                                        .map(x => ({ permission: x.permission, pageId: x.pageId })));
                                    type = 'unchecked';
                                }
                                this.singleRowAllCheckMap[record.id] = checked;
                                const event = { type, data: changes };
                                checkChange(event);
                            }}/>),
                    },
                ]),
            ...(this.colPerms.map(x => ({
                slots: { title: x.id },
                width: 120,
                customRender: (record) => renderCheck(x.id, record.id),
            }))),
        ];
        const searchPart = <div class={styles.generalWrapper}>
      <div class={styles.searchTag}>
        <a-input-search v-model={this.keyword} placeholder={`请输入${this.title}进行搜索`} style="width:240px"/>
      </div>
    </div>;
        const mainContent = <div>
          <a-table columns={columns} dataSource={this.filterRows} rowKey="id" rowClassName={(r) => (r.id === this.activeRow ? styles.activeRow : '')} pagination={renderPagination()} scroll={{ x: 700, y: 400 }}>
            <span slot="checkAll">
              <a-checkbox checked={this.checkAll} disabled={this.checkAllLoading} onChange={(e) => {
                const { checked } = e.target;
                const changes = [];
                let type = 'checked';
                this.checkAllLoading = true;
                if (checked) {
                    this.filterRows.forEach((r) => {
                        this.colPerms.forEach((c) => {
                            const key = `${r.id}/${c.id}`;
                            if (this.isRelated(c.id, r.id) && !this.rolePermsMap[key]) {
                                changes.push({ pageId: r.id, permission: c.id });
                            }
                        });
                    });
                }
                else {
                    type = 'unchecked';
                    this.filterRows.forEach((r) => {
                        this.colPerms.forEach((c) => {
                            const key = `${r.id}/${c.id}`;
                            if (this.rolePermsMap[key]) {
                                changes.push({ pageId: r.id, permission: c.id });
                            }
                        });
                    });
                }
                if (!changes.length)
                    this.checkAllLoading = false;
                this.checkAll = checked;
                checkChange({ type, data: changes });
            }}>
                全部
              </a-checkbox>
            </span>
            {
            // 列 slot
            this.colPerms.map(x => <span key={x.id} slot={x.id}>
                {(this.readonlyOrTooManyRows || this.isInherited?.[x.name]) ? (<span style="margin-right:4px">{x.name}</span>) : (<a-checkbox checked={this.singleColAllCheckMap[x.id]} onChange={(e) => {
                        const { checked } = e.target;
                        const changes = [];
                        let type = 'checked';
                        if (checked) {
                            this.filterRows.forEach((r) => {
                                const key = `${r.id}/${x.id}`;
                                if (this.isRelated(x.id, r.id) && !this.rolePermsMap[key]) {
                                    changes.push({ pageId: r.id, permission: x.id });
                                }
                            });
                        }
                        else {
                            type = 'unchecked';
                            const ids = this.filterRows.map(x => x.id);
                            const rolePerms = this.rolePerms.filter(x => x.pageId && ids.includes(x.pageId));
                            changes.push(...rolePerms.filter(rp => rp.permission === x.id)
                                .map(rp => ({ pageId: rp.pageId, permission: rp.permission })));
                        }
                        this.singleColAllCheckMap[x.id] = checked;
                        checkChange({ type, data: changes });
                    }}>
                    {x.name}
                  </a-checkbox>)}
                {x.tooltip ? <a-tooltip title={x.tooltip}>
                    <a-icon type="info-circle"/>
                  </a-tooltip> : null}
              </span>)}
          </a-table>
        </div>;
        return <div>
      {searchPart}
      {mainContent}
    </div>;
    },
});
