import { defineComponent, onMounted, ref, toRefs, watch } from '@vue/composition-api';
import styles from './merge.module.scss';
import { message } from 'ant-design-vue';
import useEditConflict from '@composables/useEditConflict';
import monacoProvider from '@utils/monacoProvider';
export default defineComponent({
    name: 'CodeConflictEditor',
    props: {
        result: {
            type: String,
            required: true,
        },
        localCode: {
            type: Object,
            required: false,
        },
        isFullScreen: {
            type: Boolean,
        },
        autoParse: {
            type: Boolean,
            default: true,
        },
        enableToggleFullScreen: {
            type: Boolean,
            default: false,
        },
        enableSkipMerge: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'json',
        },
        enableSkipResolved: {
            type: Boolean,
            default: false,
        },
        currentLabel: {
            type: String,
            default: '',
        },
        incomingLabel: {
            type: String,
            default: '',
        },
    },
    emits: ['change', 'cancel', 'toggleFullScreen', 'changeValue', 'blur'],
    setup(props, { emit }) {
        const { isFullScreen, autoParse, result, localCode, type } = toRefs(props);
        const editorRef = ref();
        const editor = ref(); // 编辑器实例
        const isJson = type.value === 'json';
        const { updateConflicts, findMatch, conflictsPosition, } = useEditConflict({
            currentLabel: props.currentLabel,
            incomingLabel: props.incomingLabel,
            editor,
            classname: {
                conflict_current_highlight: styles.conflict_current_highlight,
                conflict_incoming_highlight: styles.conflict_incoming_highlight,
            },
            onAccept: () => {
                // 点击按钮也算在文本编辑器失焦
                updateConflicts(false);
                emit('blur', editor.value.getValue());
            },
        });
        const handleConflictResolved = () => {
            // 校验是否为合法json
            const jsonStr = editor.value.getValue();
            try {
                const data = isJson && autoParse.value ? JSON.parse(jsonStr) : jsonStr;
                emit('change', data);
                emit('resolve', data);
            }
            catch (err) {
                message.error('JSON校验不通过');
            }
        };
        const useAllLocalChanges = () => {
            if (localCode.value) {
                emit('change', isJson && autoParse.value
                    ? JSON.parse(localCode.value)
                    : localCode.value);
            }
        };
        watch(isFullScreen, () => {
            // 切换全屏模式的时候，需要手动更新下monaco的layout才会重新计算宽高
            editor.value.layout();
        });
        onMounted(() => {
            monacoProvider((monaco) => {
                const model = monaco.editor.createModel(result.value, props.type);
                editor.value = monaco.editor.create(editorRef.value, {
                    model,
                    theme: 'vs',
                });
                updateConflicts(true);
                editor.value.onDidChangeModelContent(() => {
                    updateConflicts(false);
                    emit('changeValue', editor.value.getValue());
                });
                editor.value.onDidBlurEditorText(() => {
                    updateConflicts(false);
                    emit('blur', editor.value.getValue());
                });
            });
        });
        const getValue = () => editor.value.getValue();
        return {
            editorRef,
            findMatch,
            conflictsPosition,
            useAllLocalChanges,
            handleConflictResolved,
            getValue,
        };
    },
    render() {
        return (<div class={styles.code_merge_container}>
        <div class={styles.navbar}>
          <div>请手动解决以下冲突：</div>
          <div>
            <span class={styles.conflict_message}>{this.conflictsPosition.length}个冲突待处理</span>
            {this.conflictsPosition.length >= 1 && (<a-button type="link" onClick={() => this.findMatch('Previous')}>
                上一个
                <a-icon type="up"/>
              </a-button>)}
            {this.conflictsPosition.length >= 1 && (<a-button type="link" onClick={() => this.findMatch('Next')}>
                下一个
                <a-icon type="down"/>
              </a-button>)}
            {!this.enableSkipResolved && <a-button type="primary" disabled={this.conflictsPosition.length > 0} onClick={this.handleConflictResolved}>
              标记冲突已解决
            </a-button>}
            {this.enableSkipMerge && (<a-button onClick={this.useAllLocalChanges} style="margin-left: 16px;">
                不合并,直接保存本地
              </a-button>)}
            {this.enableToggleFullScreen && (<a-button style="margin-left: 16px;" icon={this.isFullScreen ? 'fullscreen-exit' : 'fullscreen'} onClick={() => this.$emit('toggleFullScreen')}></a-button>)}
          </div>
        </div>
        <div class={styles.conflict_content}>
          <div ref="editorRef" style="height: 100%"></div>
        </div>
      </div>);
    },
});
