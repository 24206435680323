<template>
  <a-layout-header
    v-if="!isMiniprogram || sidebarInstance || isShowNavIcon"
    class="header"
    :class="[isDarkMode ? 'dark': 'light']"
    wj-mod="导航"
  >
    <div class="vertical-center">
      <div class="vertical-center">
        <template v-if="!isMiniprogram">
          <a-icon
            v-show="routeStack.length === 0"
            type="home"
            class="icon-button"
            @click="goHome"
          />
          <a-icon
            v-show="routeStack.length > 0"
            type="left"
            class="icon-button"
            @click="goBack"
          />
        </template>
        <a-icon
          v-if="sidebarInstance"
          type="bars"
          class="icon-button"
          @click="toggleSidebar"
        />
        <!-- 自定义左侧组件 -->
        <NavComps
          ref="dynamic-comps"
          :project="project"
          :position="'left'"
        />
      </div>
    </div>
    <div class="vertical-center">
      <!-- 自定义右侧组件 -->
      <NavComps
        ref="dynamic-comps"
        :project="project"
        :position="'right'"
      />
      <a-icon
        v-if="isShowNavIcon"
        :type="drawerVisible ? 'close' : 'more'"
        class="icon-button"
        @click="drawerVisible = !drawerVisible"
      />
    </div>
    <a-drawer
      placement="top"
      :closable="false"
      :visible="drawerVisible"
      height="100%"
      get-container="#xy-runtime-content"
      :wrap-style="{ position: 'absolute'}"
      :wrap-class-name="`mini-program-nav-drawer ${isDarkMode ? 'dark': 'light'}`"
      @close="drawerVisible = false"
    >
      <!-- 环境选择 -->
      <template v-if="visible('env')">
        <div class="env-select-wrapper">
          <span style="margin-right: 8px;">环境</span>
          <EnvSelect
            :show-list="envShowList"
            :redirect="envRedirect"
          />
        </div>
        <a-divider style="margin: 12px 0px;" />
      </template>
      <NavWidget
        :project-id="project.id"
        :widgets="widgets"
        :is-mobile-or-narrow-screen="true"
      />
    </a-drawer>
  </a-layout-header>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api';
import { useRouter } from '@/router/useRouter';
import EnvSelect from '@components/nav/NavEnvSelect';
import NavWidget from '@components/nav-widget/NavWidget';
import NavComps from '@components/nav/NavComps';
import useLayout from '@composables/useLayout';
import { isMiniprogram } from '@utils/screen';

export default {
  name: 'MiniProgramNav',
  components: { EnvSelect, NavWidget, NavComps },
  setup() {
    const { router } = useRouter();
    const { project } = useLayout();

    // 简易的路由堆栈
    const routeStack = ref([]);
    onMounted(() => {
      router.value.afterEach((to, from) => {
        const topRoute = routeStack.value[routeStack.value.length - 1];
        if (from?.fullPath && from.fullPath !== to?.fullPath) {
          if (to.fullPath === topRoute) {
            routeStack.value.pop();
          } else {
            routeStack.value.push(from.fullPath);
          }
        }
      });
    });

    return {
      project,
      routeStack,
    };
  },
  data() {
    return {
      drawerVisible: false,
      isMiniprogram: isMiniprogram(),
    };
  },
  computed: {
    sidebarInstance() {
      return this.$store.state.layout.sidebar;
    },
    uiInfo() {
      return this.project.uiInfo || {};
    },
    isDarkMode() {
      return this.uiInfo?.navStyle?.nav === 1;
    },
    isShowNavIcon() {
      return this.visible('env') || this.hasWidgets;
    },
    // 是否显示业务
    widgets() {
      let widgets = [];
      if (this.uiInfo.navlink) {
        const { navlink } = this.uiInfo;
        widgets = (navlink.widgets ?? []).filter(w => !!w.type && w.config?.queryKey && w.isShow);
      }
      return widgets;
    },
    hasWidgets() {
      return Boolean(this.widgets.length);
    },
    // 外显的环境
    envShowList() {
      const envConfig = this.project?.uiInfo?.navlink?.defaultNav.find(item => item.id === 'env');
      return envConfig?.showList ?? ['*'];
    },
    envRedirect() {
      const envConfig = this.project?.uiInfo?.navlink?.defaultNav.find(item => item.id === 'env');
      return envConfig?.redirect ?? 'same';
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    goHome() {
      this.$router.replace({ path: '/' });
    },
    toggleSidebar() {
      this.sidebarInstance?.toggleCollapsed();
    },
    // 是否呈现菜单
    visible(id) {
      const { navlink } = this.project.uiInfo || {};
      if (navlink && navlink.defaultNav) {
        return navlink.defaultNav.find(item => item.id === id)?.isShow;
      }
      return id !== 'env';
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  z-index: 1001;
  height: var(--xy-header-height);
  line-height: var(--xy-header-height);
  padding: 0px 4px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 8px 0 rgba(0, 21, 41, 0.1);
  background: var(--xy-custom-nav-bg-color);
  color: var(--xy-custom-nav-color);
  .ant-menu {
    background: transparent;
  }
}

.env-select-wrapper {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.vertical-center{
  display: flex;
  align-items: center
}

.icon-button{
  font-size: 20px;
  padding: 16px 12px;
}

::v-deep.mini-program-nav-drawer {
  .ant-drawer-body {
    padding: 12px;
  }
  .ant-drawer-content {
    background: var(--xy-custom-nav-bg-color);
    color: var(--xy-custom-nav-color);
  }
}
</style>
