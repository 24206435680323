export const PAGE_TYPE = {
    // 在 page.pageType 字段
    CONTENT: 'content',
    PAGELET: 'pagelet',
    NAV: 'nav',
    SIDEBAR: 'sidebar',
    HEADER: 'header',
    FOOTER: 'footer',
    DATA_TABLE: 'datatable',
    MODAL: 'modal',
    // 在 page.type 字段
    CODE: 'code',
};
export const PAGE_TYPE_OPTIONS = [
    {
        value: 'content',
        label: '页面',
    },
    {
        value: 'pagelet',
        label: '页面片',
    },
    {
        value: 'nav',
        label: '导航',
    },
    {
        value: 'sidebar',
        label: '菜单',
    },
    {
        value: 'header',
        label: '页头',
    },
    {
        value: 'footer',
        label: '页脚',
    },
    {
        value: 'modal',
        label: '弹窗',
    },
];
export const LAYOUT_TYPE_OPTIONS = [
    {
        value: 'header-sidebar-content',
        label: '导航-菜单-内容',
    },
    {
        value: 'header-content',
        label: '导航-内容',
    },
    {
        value: 'sidebar-content',
        label: '菜单-内容',
    },
    {
        value: 'content',
        label: '内容',
    },
];
export const SCROLL_MODE_OPTIONS = [
    {
        value: 'system',
        label: '跟随应用',
    },
    {
        value: 'fix-top',
        label: '固定',
    },
    {
        value: 'not-fix-top',
        label: '不固定',
    },
];
export const WATERMARK_OPTIONS = [
    {
        value: 'system',
        label: '跟随应用',
    },
    {
        value: 'on',
        label: '开启',
    },
    {
        value: 'off',
        label: '关闭',
    },
];
/**
 * Checks if a given page is a content page.
 * @param page - The page object to check.
 * @returns True if the page is a content page, false otherwise.
 */
export const isContentPage = (page) => {
    if (!page)
        return false;
    const content = page.pageType === PAGE_TYPE.CONTENT; // 内容类型页面
    const dataTable = page.pageType === PAGE_TYPE.DATA_TABLE; // 数据表页面
    const code = page.type === PAGE_TYPE.CODE && !page.pageType; // 本地开发页面
    return content || dataTable || code;
};
