import { loadAmdModule } from '@utils/common';
import { scriptsConfig as config } from '@utils/scriptsConfig';

export const loadUtilScript = async (key) => {
  if (!config[key]) throw new Error(`${key} not found.`);

  const { key: wKey, js } = config[key];
  if (window[wKey]) return window[wKey];

  const instance = await loadAmdModule(js, key);
  window[wKey] = instance;
  return instance;
};

export const loadEcharts = () => loadUtilScript('echarts');

export const loadXLSX = async () => {
  await loadUtilScript('xlsx');
  // 这个amd模块返回的是一个函数，真正的实例在window.XLS上
  return window.XLS;
};

export const loadJstt = async () => {
  await loadUtilScript('jstt');
  return window.jstt;
};

