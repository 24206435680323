import { isEmpty, memoize } from 'lodash';
import { getCollection } from '@tencent/wuji-source';
import dayjs from 'dayjs';
import wujiFetch from '@utils/wujiFetch';
import { computeCurrentPageId } from '@utils/path';
import { useRouter } from '@/router/useRouter';
import { BUILTIN_PAGE_ID, generatedPageIdPrefix } from '@/config/constant';
const stringify = require('json-stable-stringify');

/**
 * 将驼峰语法换成带-的小写（vue props绑定使用）
 * @param {String} str 要转换的字符串
 */
export function camelChange(str) {
  if (!str || typeof str !== 'string') {
    return str;
  }
  const srtArray = str.split('_');
  srtArray[0] = srtArray[0].replace(/([A-Z])/g, '-$1').toLowerCase();
  if (srtArray[0].charAt(0) === '-') {
    srtArray[0] = srtArray[0].substring(1);
  }
  return srtArray.join('_');
}

/**
 * 中划线转成大驼峰
 * @param {String} str
 */
export function toCamel(string) {
  let str = string;
  if (/^[a-z]-/.test(str)) {
    str = str.charAt(0).toUpperCase() + str.substring(1);
  }
  return str.replace(/-([a-zA_Z])/g, ($0, $1) => $1.toUpperCase());
}

export function getDataOrThrow(resp) {
  if (!resp) throw new Error('无响应');
  if (resp.code !== 200) {
    const error = new Error(resp.error || '响应内容有误');
    error.code = resp.code;
    throw error;
  }
  return resp.data;
}

export async function loadScript(scriptUrl, isAsync) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = () => {
      resolve(script);
    };
    script.onerror = (err) => {
      reject(err);
    };
    script.src = scriptUrl;
    if (typeof isAsync !== 'undefined') {
      script.async = isAsync;
    }
    document.body.appendChild(script);
  });
}

export async function loadCss(cssUrl) {
  return new Promise((resolve, reject) => {
    const link = document.createElement('link');

    link.onload = () => {
      resolve(link);
    };
    link.onerror = (err) => {
      reject(err);
    };
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = cssUrl;
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(link);
  });
}

export async function loadAmdModule(scriptUrl, amdKey, callback) {
  if (!amdKey) throw new Error('loadAmdModule 函数调用缺少必要的 amdKey 参数');
  const amdRequire = window.require;
  return new Promise((resolve) => {
    amdRequire.config({
      paths: {
        [amdKey]: scriptUrl.slice(0, scriptUrl.lastIndexOf('.')),
      },
    });
    amdRequire([amdKey], (instance) => {
      if (typeof callback === 'function') {
        callback(instance);
      }
      resolve(instance);
    });
  });
}

export const getTopSwitchNamespace = projectId => `${projectId}/nav-widget/top-switch`;
export const getTopSwitchStorage = (projectId, key = '') => {
  const namespace = getTopSwitchNamespace(projectId);
  const local = localStorage.getItem(namespace);
  const result = local ? JSON.parse(local) : {};
  return key ? result[key] : result;
};

/**
 * 公共参数下拉组件是否隐藏
 *
 * @param {*} config
 * @returns {boolean}
 */
export const isSwitchHidden = (config) => {
  if (config?.invisible) return true;
  return false;
};

/**
 * 公共参数是否启用
 *
 * @param {*} config
 * @returns {boolean}
 */
export const isSwitchEnable = (config) => {
  if (isEmpty(config)) return false;

  const { invisiblePagePaths = [], invisibleEnvs = [] } = config;

  // 按页面禁用
  const { route } = useRouter();
  const currentPath = route.value?.path;
  if (invisiblePagePaths.includes(currentPath)) return false;

  // 按环境禁用
  const env = window.RUNTIME_CONFIGS?.env ?? 'dev';
  if (invisibleEnvs.includes(env)) return false;

  return true;
};

export const requestCollectionCgi = async (projectId, envId, collectionId, cgiId, ...args) => {
  const router = window?.globalContext?.router;
  let pageId = '';
  if (router) {
    pageId = computeCurrentPageId(router);
  }
  const collection = await getCollection({
    projectId,
    envId,
    pageId: pageId || 'any-page',
    collectionId,
    useCache: true,
  });
  const resp = await collection[cgiId](...args);
  return resp;
};

const map = new Map();
export const cacheRequestCollectionCgi = async (projectId, envId, collectionId, cgiId, ...args) => {
  const key = [projectId, envId, collectionId, cgiId].join('/');
  let promise = null;
  if (map.has(key)) {
    promise = map.get(key);
  } else {
    promise = requestCollectionCgi(projectId, envId, collectionId, cgiId, ...args);
    map.set(key, promise);
  }
  const result = await promise;
  return result;
};

export function formatDate(time, format = 'YYYY/MM/DD HH:mm:ss') {
  if (!time) {
    return '';
  }
  return dayjs(time).format(format);
}

export function formatToDate(time) {
  return dayjs().to(dayjs(time));
}

export function getUUID() {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + (Math.random() * 16)) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
  });
  return `web${uuid}`;
}

const defaultWUjiFetchConfig = { method: 'GET', timeout: 5000 };

/** 使用这个会合并相同 URL 的 GET 请求 */
export const memoizedFetchGET = memoize((url) => {
  setTimeout(() => {
    memoizedFetchGET.cache.delete(url);
  }, 10000);

  return wujiFetch(url, defaultWUjiFetchConfig);
});

// 原生的json.stringfiy会改变key的顺序，这里的stringify不会改变key的顺序
export const toJSONString = (value, replacer, space) => stringify(value, {
  replacer,
  space,
});

export const formatObjectToJSON = (value) => {
  if (typeof value === 'object') {
    return toJSONString(value, null, '\t');
  }
  return value;
};

export const formatObjectToJsStr = (value) => {
  if (!value) {
    return '';
  }
  const data = `() => (${formatObjectToJSON(value)})`;
  return data;
};

export const isBuiltinPageTemplate = pageId => pageId === BUILTIN_PAGE_ID || pageId?.startsWith(generatedPageIdPrefix);

export const tryParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};
