import { ref } from '@vue/composition-api';
import projectLoader from '@loaders/project/loader';
import { useStore } from '@/store';
const cache = new Map();
export default (projectId) => {
    const projectInfo = ref(null);
    const store = useStore();
    const fetchProjectInfo = async (env = 'dev') => {
        const key = `${projectId}/${env}`;
        let promise = null;
        if (cache.has(key)) {
            promise = cache.get(key);
        }
        else {
            promise = projectLoader.fetchBasicProjectInfo(projectId, env);
            cache.set(key, promise);
        }
        const resp = await promise;
        setTimeout(() => {
            cache.delete(key);
        }, 10e3);
        projectInfo.value = resp;
    };
    const updateProjectInfo = async (data) => {
        const resp = await store.dispatch('mergeAndUpdateProjectInfo', { ...data, id: projectId });
        return resp.result;
    };
    return {
        projectInfo,
        fetchProjectInfo,
        updateProjectInfo,
    };
};
