import { CATEGORY } from './const';
import { Modal } from 'ant-design-vue';
import { isEmpty as isObjectEmpty } from 'lodash';
import wujiFetch from '@/utils/wujiFetch';

const draftStorage = {
  get(key) {
    return JSON.parse(localStorage.getItem(key)
      || localStorage.getItem(`${key}}`)  // 兼容逻辑
      || '{}');
  },
  add(key, current) {
    const original = JSON.parse(localStorage.getItem(key) || '{}');
    localStorage.setItem(key, JSON.stringify({
      ...original,
      ...current,
    }));
  },
  remove(key) {
    localStorage.removeItem(key);
    localStorage.removeItem(`${key}}`); // 兼容逻辑
  },
};

const getDraftId = wContext => `${wContext.env}:${wContext.config.id}:${wContext.id}`;

export const DEFAULT_DRAFT_URL = '/api/xy/page/design/draft';

const checkDraftChange = (oData, nData) => {
  if (JSON.stringify(oData) !== JSON.stringify(nData)) {
    return true;
  }
  return false;
};

// 保存远程草稿
const saveDraftDB = async (projectId, key, content) => await wujiFetch(`${DEFAULT_DRAFT_URL}/${projectId}`, {
  method: 'POST',
  body: JSON.stringify({
    key, tenantId: 'wuji',
    content,
  }),
});

// 获取远程草稿
const getDBDraft = async (projectId, key) => {
  const data = await wujiFetch(`${DEFAULT_DRAFT_URL}/${projectId}?key=${key}`);
  if (data.length > 0) {
    const [draft] = data;
    return draft.content;
  }
  return {};
};

// 删除远程草稿
const deleteDBDraft = async (projectId, key) => await wujiFetch(`${DEFAULT_DRAFT_URL}/delete/${projectId}`, {
  method: 'POST',
  body: JSON.stringify({ key }),
});

// 保存草稿
export const saveDraftAtom = {
  id: 'xy:source:saveDraft',
  name: '保存草稿',
  category: CATEGORY.DRAFT,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'showConfirm',
        type: 'boolean',
        title: '是否提示用户',
        dafault: false,
      },
      {
        id: 'namespace',
        type: 'string',
        title: '指定命名空间',
        desc: '在需要区分业务下使用，不指定时草稿默认在当前页面或者弹窗使用',
        dafault: '',
      },
    ],
  },
  async execute(ctx, params) {
    const { renderer } = ctx;
    // TODO: isChange为当前上下文是否有编辑过, 需要等ui-core升级才有
    // const { isChanged } = renderer.wContext;
    const { showConfirm, namespace } = params;
    const saveDraft = () => {
      const form = renderer.ucRootForm;
      const data = {};
      form.forEachEditors((editor) => {
        if (editor.value) {
          data[editor.$vnode.key] = editor.value;
        }
        if (editor.array) {
          data[editor.$vnode.key] = editor.array;
        }
      });
      const key = `${getDraftId(renderer.wContext)}${namespace ? `:${namespace}` : ''}`;
      const isChanged = checkDraftChange(draftStorage.get(key), data);
      draftStorage.add(key, data);
      if (isChanged) {
        // 保存至远程DB
        saveDraftDB(renderer.wContext.projectId, key, data);
      }
    };
    if (showConfirm) {
      Modal.confirm({
        content: '是否保存当前的草稿？',
        okText: '保存',
        cancelText: '取消',
        onOk: saveDraft,
      });
    } else {
      saveDraft();
    }
  },
};

// 恢复草稿
export const restoreDraftAtom = {
  id: 'xy:source:restoreDraft',
  name: '恢复草稿',
  category: CATEGORY.DRAFT,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'showConfirm',
        type: 'boolean',
        title: '是否提示用户',
        dafault: true,
      },
      {
        id: 'namespace',
        type: 'string',
        title: '指定命名空间',
        desc: '在需要区分业务下使用，不指定时草稿默认在当前页面或者弹窗使用',
        dafault: '',
      },
      {
        id: 'remainDraftAfterCancel',
        type: 'boolean',
        title: '用户取消恢复草稿后是否保留草稿',
        default: false,
      },
    ],
  },
  async execute(ctx, params) {
    const { renderer } = ctx;
    const { showConfirm, namespace, remainDraftAfterCancel } = params;
    const key = `${getDraftId(renderer.wContext)}${namespace ? `:${namespace}` : ''}`;
    // 如果本地草稿消失了，应该拿出远程草稿
    const data = !isObjectEmpty(draftStorage.get(key)) ? draftStorage.get(key)
      : (await getDBDraft(renderer.wContext.projectId, key));
    const isEmpty = Object.keys(data).length === 0;

    const restoreDraft = () => {
      const form = renderer.ucRootForm;
      form.forEachEditors((editor) => {
        if (data[editor.$vnode.key]) {
          editor.$emit('change', data[editor.$vnode.key]);
        }
      });
      draftStorage.remove(key);
      deleteDBDraft(renderer.wContext.projectId, key);
    };
    if (!isEmpty && showConfirm) {
      Modal.confirm({
        content: '是否恢复上次编辑的内容？',
        okText: '恢复',
        cancelText: '取消',
        onOk: restoreDraft,
        onCancel: () => {
          if (!remainDraftAfterCancel) {
            draftStorage.remove(key);
            deleteDBDraft(renderer.wContext.projectId, key);
          }
        },
      });
    } else {
      !isEmpty && restoreDraft();
    }
  },
};

// 删除草稿
export const removeDraftAtom = {
  id: 'xy:source:removeDraft',
  name: '删除草稿',
  category: CATEGORY.DRAFT,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'namespace',
        type: 'string',
        title: '指定命名空间',
        desc: '如果保存草稿指定了命名空间，需要输入对应的命名空间',
        dafault: '',
      },
    ],
  },
  async execute(ctx, params) {
    const { namespace } = params;
    const key = `${getDraftId(ctx.renderer.wContext)}${namespace ? `:${namespace}` : ''}`;
    deleteDBDraft(ctx.renderer.wContext.projectId, key);
    return draftStorage.remove(key);
  },
};

export default [
  saveDraftAtom,
  restoreDraftAtom,
  removeDraftAtom,
];
