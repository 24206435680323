import { ADVANCE_SETTING } from '@/config/constant';
import { Modal } from 'ant-design-vue';
import cookies from 'js-cookie';
import qs from 'query-string';
const { jsCacheDisabled = false, jsCacheDisabledStamp } = ADVANCE_SETTING;
const cookieInput = (obj) => JSON.stringify(obj);
const cookieOutput = (str) => {
    try {
        return JSON.parse(decodeURIComponent(str));
    }
    catch (err) {
        return {};
    }
};
/**
 * 防止cookie存储膨胀，对存储进行淘汰
 * 仅能存储global的相关JS 1条，且最多10个页面JS。淘汰规则：失败次数越小，淘汰越快
 */
const disuseUrls = (urlsCount, url) => {
    const ret = urlsCount;
    const URL_TYPES_MAX = {
        'wuji-xy-globals': 1,
        'wuji-xy-page': 10,
    };
    const urlTypes = Object.keys(URL_TYPES_MAX);
    const findType = (urlKey) => urlTypes.find(s => urlKey.includes(s));
    const countMap = {};
    const currentType = findType(url);
    if (!currentType)
        return ret;
    Object.entries(urlsCount)
        .map(([urlKey, count]) => ({ urlKey, count }))
        .sort((a, b) => (b.count - a.count))
        .forEach(({ urlKey }) => {
        const tempType = findType(urlKey);
        if (url === urlKey || tempType !== currentType)
            return;
        countMap[currentType] = countMap[currentType] || 0;
        countMap[currentType] = countMap[currentType] + 1;
        if (countMap[currentType] >= URL_TYPES_MAX[currentType]) {
            delete ret[urlKey];
        }
    });
    return ret;
};
const COOKIE_SCRIPT_ERROR_NAME = 'wj_script_error';
const refreshJsCount = (url) => {
    let urlsCount = getCounter();
    urlsCount = disuseUrls(urlsCount, url);
    // 递增计数器
    urlsCount[url] = urlsCount[url] || 0;
    urlsCount[url] = urlsCount[url] + 1;
    cookies.set(COOKIE_SCRIPT_ERROR_NAME, cookieInput(urlsCount));
};
let reloadConfirming = false;
export const loadScriptError = (url) => {
    refreshJsCount(url);
    if (reloadConfirming)
        return;
    reloadConfirming = true;
    Modal.warning({
        closable: true,
        centered: true,
        title: '加载失败',
        content: '检测到页面资源加载失败，请刷新页面重试',
        okText: '刷新页面',
        cancelText: '取消',
        onOk() {
            location.reload();
            reloadConfirming = false;
        },
        onCancel() {
            reloadConfirming = false;
        },
    });
};
const getCounter = () => {
    try {
        return cookieOutput(cookies.get(COOKIE_SCRIPT_ERROR_NAME));
    }
    catch (err) {
        console.error(`获取cookie失败：${err.message}`);
        return {};
    }
};
export const getRefreshUrl = (url, ignore = {}) => {
    const urlsCount = getCounter();
    const queries = {};
    if (urlsCount[url]) {
        queries.try = `${urlsCount[url]}`;
    }
    if (!ignore.jsCacheDisabled && jsCacheDisabled) {
        queries.t = jsCacheDisabledStamp || `${+new Date}`;
    }
    const qString = qs.stringify(queries);
    const spliter = qString ? '?' : '';
    return `${url}${spliter}${qString}`;
};
