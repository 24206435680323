export function getLanguageFromFilename(filename, defaultValue = 'javascript') {
    if (!filename || typeof filename !== 'string')
        return defaultValue;
    if (filename.endsWith('.ts'))
        return 'typescript';
    if (filename.endsWith('.tsx'))
        return 'typescriptreact';
    if (filename.endsWith('.js'))
        return 'javascript';
    if (filename.endsWith('.jsx'))
        return 'jsx';
    if (filename.endsWith('.json'))
        return 'json';
    if (filename.endsWith('.css'))
        return 'css';
    if (filename.endsWith('.html'))
        return 'html';
    return defaultValue;
}
