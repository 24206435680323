import wujiFetch from '@utils/wujiFetch';
import { designPath } from '@config/constant';

// 路由
const baseUrl = `${designPath}/offline`;
const baseConfigUrl = `${designPath}/offline-config`;

const fetchOfflineList = async (projectId, { count, page, size, include, filter = '' }) => {
  const params = [
    `projectid=${projectId}`,
    size && `size=${size}`,
    count && `count=${count}`,
    page && `page=${page}`,
    include && `include=${include}`,
    filter && `filter=${encodeURIComponent(filter)}`,
  ].filter(Boolean).join('&');

  const url = `${baseUrl}?${params}`;

  const response = await wujiFetch(url, {
    method: 'GET',
    timeout: 3000,
  });

  return response;
};

const createOffline = async ({ projectId, data = {}, headers = {} }) => await wujiFetch(`${baseUrl}?projectid=${projectId}`, {
  method: 'POST',
  body: JSON.stringify({
    ...data,
    projectId,
  }),
  headers,
});

const deleteOffline = async ({ projectId, id, headers = {} }) => await wujiFetch(`${baseUrl}/${id}?projectid=${projectId}`, {
  method: 'DELETE',
  headers,
});

const fetchConfigList = async projectId => await wujiFetch(`${baseConfigUrl}?projectid=${projectId}`);


const createConfig = async ({ projectId, data = {}, headers = {} }) => await wujiFetch(`${baseConfigUrl}?projectid=${projectId}`, {
  method: 'POST',
  body: JSON.stringify({
    ...data,
    projectId,
  }),
  headers,
});

const updateConfig = async ({ projectId, id, data = {}, headers = {} }) => await wujiFetch(`${baseConfigUrl}/${id}?projectid=${projectId}`, {
  method: 'PATCH',
  body: JSON.stringify({
    ...data,
    projectId,
  }),
  headers,
});

const deleteConfig = async ({ projectId, id, headers = {} }) => await wujiFetch(`${baseConfigUrl}/${id}?projectid=${projectId}`, {
  method: 'DELETE',
  headers,
});

const debugConfig = async ({ projectId, data = {}, headers = {} }) => await wujiFetch(`${baseConfigUrl}/debug?projectid=${projectId}`, {
  method: 'POST',
  timeout: 5000,
  body: JSON.stringify({
    ...data,
    projectId,
  }),
  headers,
});

export default {
  fetchOfflineList,
  createOffline,
  deleteOffline,
  fetchConfigList,
  createConfig,
  updateConfig,
  deleteConfig,
  debugConfig,
};
