export const DEFAULT_LESSCODE = `export default {
  data() {
    return {
      // param: '',      // 自定义变量
    };
  },
  mounted() {

  },
  methods: {

  },
};
`;
export const DEFAULT_PROJECT_LESSCODE = `export default {
  data() {
    return {
      // globalParam: '',
    };
  },
  created() {

  },
  mounted() {

  },
  methods: {

  },
};
`;
export const generateDefaultModuleCode = (name) => `// 在其他地方可以使用 require('global/${name.replace(/\.\w+$/, '')}') 访问当前模块

export const msg = 'hello world';

export function sayHello() {
  console.log(msg);
}
`;
export default {
    DEFAULT_LESSCODE,
    DEFAULT_PROJECT_LESSCODE,
};
