// 这些是 ui-core 预制的组件，但是 wuji 组件商店里也许有更好的替换品，所以这里就全都要隐藏掉
export const UCBC_ALWAYS_HIDE = 2;
export const UCBC_EDITOR = 4;

export const uiCoreBuiltinComponents = {
  'w-button': 1 | UCBC_ALWAYS_HIDE,
  'w-container': 1,
  'w-card': 1,
  'w-image': 1,
  'w-progress': 1,
  'w-accordion': 1,
  arrayTable: 1 | UCBC_ALWAYS_HIDE | UCBC_EDITOR,
  textInput: 1 | UCBC_ALWAYS_HIDE | UCBC_EDITOR,
  numberInput: 1 | UCBC_ALWAYS_HIDE | UCBC_EDITOR,
  checkbox: 1 | UCBC_ALWAYS_HIDE | UCBC_EDITOR,
  select: 1 | UCBC_ALWAYS_HIDE | UCBC_EDITOR,
  'w-radio': 1 | UCBC_ALWAYS_HIDE | UCBC_EDITOR,
  colorPicker: 1 | UCBC_ALWAYS_HIDE | UCBC_EDITOR,
  markdown: UCBC_ALWAYS_HIDE,
  'w-tabs': UCBC_ALWAYS_HIDE,
  'w-tab-pane': UCBC_ALWAYS_HIDE,
  jsonView: 1 | UCBC_ALWAYS_HIDE | UCBC_EDITOR,
  fileUploader: 1 | UCBC_ALWAYS_HIDE | UCBC_EDITOR,
  'w-text': 1,
  'w-tag': 1,
};


export default {
  uiCoreBuiltinComponents,
  UCBC_ALWAYS_HIDE,
  UCBC_EDITOR,
};
