const ftext = (() => {
  const input = document.createElement('textarea');
  input.setAttribute('style', 'position:fixed;left:0;top:0;opacity:0.01;width:5px;height:10px;');
  document.body.appendChild(input);
  return input;
})();

const noticeDiv = (() => {
  const noticeDiv = document.createElement('div');
  const isMac = /^Mac/.test(navigator.platform);
  noticeDiv.innerHTML = `<p>请键盘按下 ${isMac ? 'Command + V' : 'Ctrl + V'} 以粘贴内容</p><p><button>取消粘贴</button></p>`;
  noticeDiv.setAttribute('style', 'box-shadow:0 0 0 50vmax rgba(0,0,0,0.5); padding: 20px; line-height: 10vh; background: #FFF; position: absolute; left: 30%; top: 30%; width: 40%; text-align: center; z-index: 10000; font-size: 16px; box-sizing: border-box;');
  return noticeDiv;
})();

export async function writeClipboard(text) {
  ftext.value = text;
  ftext.select();
  document.execCommand('copy');
}

export function readClipboard() {
  return new Promise(async (resolve, reject) => {
    try {
      const text = await navigator.clipboard.readText();
      resolve(text);
    } catch (_err) {
      ftext.value = '';
      ftext.focus();
      document.execCommand('paste');
      if (ftext.value) {
        resolve(ftext.value);
        return;
      }
      let resolved = false;
      let dispose = () => {
        document.body.removeChild(noticeDiv);
        ftext.onkeyup = null;
        ftext.onblur = null;
        dispose = () => { };
        if (!resolved) reject(new Error('操作取消'));
      };
      document.body.appendChild(noticeDiv);
      ftext.focus();
      ftext.onblur = () => {
        dispose();
      };
      ftext.onkeyup = () => {
        if (!ftext.value) return;
        resolve(ftext.value);
        resolved = true;
        dispose();
      };
    }
  });
}
