var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, ModelSync, Prop } from 'vue-property-decorator';
import { message } from 'ant-design-vue';
import './mockSwitch.scss';
import { MockCaseLocalStorageImpl } from '@utils/mockCaseManager';
import MonacoEditor from '@components/monacoEditor/index.vue';
/** mock数据编辑弹窗 */
let MockJsonEditorModal = class MockJsonEditorModal extends Vue {
    visible;
    mockData;
    /** mock数据的标题 */
    mockTitle = '';
    /** monaco-editor v-model */
    sourceText = '';
    /** monaco配置 */
    codeEditorOptions = {
        language: 'json',
        minimap: { enabled: true },
        lineNumbers: true,
        folding: true,
    };
    created() {
        this.$watch(() => this.mockData, () => {
            this.sourceText = JSON.stringify(this.mockData, null, 2);
            // 等待monacoEditor生成editor实例
            setTimeout(() => this.autoFold(), 0);
        }, { immediate: true });
    }
    /** 确定按钮 保存mock数据 */
    async handleConfirm() {
        const manager = new MockCaseLocalStorageImpl();
        const mockData = JSON.parse(this.sourceText);
        mockData.mockCaseName = this.mockTitle;
        manager.setWorkPage(mockData);
        await manager.create(mockData);
        this.visible = false;
        message.success('[mock]数据保存成功');
    }
    handleCancel() {
        this.visible = false;
    }
    /** monaco加载完成触发一次自动折叠 */
    init() {
        setTimeout(() => this.autoFold(), 100);
    }
    /** 对mock数据JSON进行折叠 折叠instance和schema以及深度超过7的元素 */
    async autoFold() {
        const editor = this.$refs.monacoEditor?.editor;
        if (!editor)
            return;
        const foldingModel = await editor.getContribution('editor.contrib.folding')?.getFoldingModel();
        if (!foldingModel)
            return;
        // 折叠方法来自vscode/src/vs/editor/contrib/folding/foldingModel
        const regionsInside = foldingModel.getRegionsInside(null, (r, level) => !r.isCollapsed && (level === 4 || level > 7));
        foldingModel.toggleCollapseState(regionsInside);
    }
    render() {
        return (<a-modal width="80vw" visible={this.visible} centered mask-closable={false} title="mock数据编辑" onOk={this.handleConfirm} onCancel={this.handleCancel}>
        <a-input v-model={this.mockTitle} class="title-input" placeholder="Mock标题"/>
        <monaco-editor ref="monacoEditor" v-model={this.sourceText} class="codeEditor" init-options={this.codeEditorOptions} onInit={this.init}/>
      </a-modal>);
    }
};
__decorate([
    ModelSync('myVisible', 'change', { type: Boolean })
], MockJsonEditorModal.prototype, "visible", void 0);
__decorate([
    Prop(Object)
], MockJsonEditorModal.prototype, "mockData", void 0);
MockJsonEditorModal = __decorate([
    Component({
        name: 'MockJsonEditorModal',
        components: { MonacoEditor },
    })
], MockJsonEditorModal);
export default MockJsonEditorModal;
