import Aegis from '@tencent/aegis-web-sdk-v2';
import newFunction from './new-function';
const GALILEO_URL = 'https://galileotelemetry.tencent.com/collect';
const DEFAULT_PLUGIN = {
    fid: true,
    ie: true,
    spa: true,
    pagePerformance: true,
    report: true,
    assetSpeed: true,
    apiSpeed: true,
    reportRequest: true,
    session: true,
    api: {
        apiDetail: true,
        // data 可能是字符串，也可能是对象,注意兼容
        retCodeHandler(data) {
            try {
                const body = typeof data === 'string' ? JSON.parse(data) : data;
                if (Array.isArray(body)) {
                    return { isErr: false, code: '0' };
                }
                const code = String(body.code ?? body.ret ?? body.retcode ?? body.errcode);
                if (['200', '0', 'undefined'].includes(code))
                    return { isErr: false, code: '0' };
                return { isErr: true, code };
            }
            catch (e) {
                return { isErr: false, code: e.message };
            }
        },
        // injectTraceHeader: 'traceparent', // FIXME: 跨域
    },
};
function getProjectAegisConfig(project) {
    const deployConfig = window.WUJI_SWITCH?.aegis_config ?? {}; // 部署级配置
    let { open = false } = deployConfig;
    const { id } = deployConfig;
    const projectOpen = project?.advanceConfig?.aegis; // 项目级配置
    if (open && typeof projectOpen === 'boolean') {
        open = projectOpen;
    }
    return { open, id };
}
export function injectAegis({ rtx, project, projectId, mode }) {
    const { open, id } = getProjectAegisConfig(project);
    const extField = {
        ext1: projectId,
        ext2: mode,
        ext3: '',
        ext4: '', // 当前部署serviceId,会被运行时覆盖
    };
    const defaultConfig = {
        uid: rtx,
        extField,
        delay: 1000 * 10,
        hostUrl: { url: GALILEO_URL },
        plugin: DEFAULT_PLUGIN,
    };
    if (!window.AegisV2 && open && id) {
        window.AegisV2 = new Aegis({
            id,
            ...defaultConfig,
        });
    }
    const { aegisConfig } = project.advanceConfig;
    if (!window.CustomAegisV2 && aegisConfig?.enable) {
        let config = {};
        if (aegisConfig.useAdvanceConfig && aegisConfig.aegisConfigHook) {
            config = newFunction(['w'], aegisConfig.aegisConfigHook)(window.w);
        }
        else if (aegisConfig.aegisId) {
            config = {
                id: aegisConfig.aegisId,
                ...defaultConfig,
            };
        }
        else {
            return;
        }
        window.CustomAegisV2 = new Aegis(config);
    }
}
/** 编辑时关掉了乱七八糟的上报 */
export function injectEditorAegis({ rtx, project, projectId, mode }) {
    const { open, id } = getProjectAegisConfig(project);
    const extField = {
        ext1: projectId,
        ext2: mode,
        ext3: '',
        ext4: '', // 当前部署serviceId,会被运行时覆盖
    };
    const PLUGIN = {
        pv: false,
        aid: false,
        error: false,
        device: false,
        close: false,
        pagePerformance: false,
        webVitals: false,
    };
    const defaultConfig = {
        uid: rtx,
        extField,
        delay: 5e3,
        hostUrl: { url: GALILEO_URL },
        plugin: PLUGIN,
    };
    if (open && id) {
        const aegis = new Aegis({
            id,
            ...defaultConfig,
        });
        Object.defineProperties(window, {
            AegisV2: {
                value: aegis,
            },
        });
        return aegis;
    }
}
