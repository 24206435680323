import { defineComponent } from '@vue/composition-api';
import dayjs from 'dayjs';
export default defineComponent({
    name: 'expireAt',
    props: {
        expireAt: {
            type: String,
            default: '',
        },
    },
    render() {
        if (!this.expireAt) {
            return <div style="text-wrap: nowrap;line-height:24px"><br /></div>;
        }
        const expireTime = <span>{dayjs(this.expireAt).format('YYYY-MM-DD HH:mm')}</span>;
        const expireDay = Math.ceil((+dayjs(this.expireAt) - Date.now()) / 86400e3);
        const warnExpire = <span style='color:red'>{expireDay}天内到期</span>;
        const warnExpired = <span style='color:red'>已过期{-expireDay}天</span>;
        return (<div style="text-wrap: nowrap;line-height:24px">
      {expireTime}&nbsp;&nbsp;{expireDay < 30 && expireDay >= 0 && warnExpire}{expireDay < 0 && warnExpired}
    </div>);
    },
});
