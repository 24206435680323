const delegate = (agent, type, selector, fn) => {
  const filiter = (agent, selector, target) => {
    const nodes = agent.querySelectorAll(selector);
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i] === target) {
        return true;
      }
    }
  };

  agent.addEventListener(type, (e) => {
    let { target } = e;
    const ctarget = e.currentTarget;
    let bubble = true;

    while (bubble && target && target !== ctarget) {
      if (filiter(agent, selector, target)) {
        bubble = fn.call(target, target);
        return bubble;
      }
      target = target?.parentNode;
    }
  }, false);
};

export default delegate;
