/**
 * $route.query上都是字符串 从字符串 按照props的schema进行映射 修复
 *
 * */
// @ts-ignore 缺少types
import yaml from 'js-yaml';
function tryYaml(query) {
    try {
        return { value: yaml.load(query) };
    }
    catch (e) {
        return { default: true };
    }
}
export function fromQueryString(query, schema) {
    if (query === undefined) {
        return { default: true };
    }
    switch (schema.type) {
        case 'string':
            return { value: query };
        case 'boolean':
            if (['1', 'yes', 'true', 'y'].map(o => o.toUpperCase()).includes(query.toUpperCase())) {
                return { value: true };
            }
            if (['0', 'no', 'false', 'n'].map(o => o.toUpperCase()).includes(query.toUpperCase())) {
                return { value: false };
            }
            return { default: true };
        case 'number':
            return isNaN(parseFloat(query)) ? { default: true } : { value: parseFloat(query) };
        case 'integer':
            return isNaN(parseInt(query, 10)) ? { default: true } : { value: parseInt(query, 10) };
        case 'object': {
            const res = tryYaml(query);
            if (res.value instanceof Object)
                return res;
            return { default: true };
        }
        case 'array': {
            const res = tryYaml(query);
            if (res.value instanceof Array)
                return res;
            return { default: true };
        }
        default:
            return { value: query };
    }
}
export function toQueryString(query, schema) {
    switch (schema.type) {
        case 'string':
            return query;
        case 'boolean':
            return String(query);
        case 'number':
            return String(query);
        case 'integer':
            return String(query);
        case 'object':
            return JSON.stringify(query);
        case 'array':
            return JSON.stringify(query);
        default:
            if (typeof query === 'string') {
                return query;
            }
            try {
                return JSON.stringify(query);
            }
            catch (e) {
                return '';
            }
    }
}
