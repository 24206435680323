import { getReportInfo } from '@/utils/serviceInfo';
import RuntimeBaseReport from './RuntimeBaseReport';

/**
 * 包含业务自定义ATTAID上报逻辑
 */
const systemConfig = {
  attaId: '03c00058181',
  attaToken: '5384471396',
};

const { id: attaId, token: attaToken } = getReportInfo()?.atta || {};

class RuntimeReport {
  constructor(ctorParams) {
    this.custom = attaId && attaToken;

    this.systemReporter = new RuntimeBaseReport({
      ...ctorParams,
      attaId: systemConfig.attaId,
      attaToken: systemConfig.attaToken,
      onlyPv: !!this.custom,
    });

    if (this.custom) {
      this.customReporter = new RuntimeBaseReport({
        ...ctorParams,
        attaId,
        attaToken,
        onlyPv: false,
      });
    }

    this.normalReporter = this.custom ? this.customReporter : this.systemReporter;
  }

  pv() {
    this.systemReporter.pv();
    this.customReporter?.pv();

    return this;
  }

  pgOut() {
    this.systemReporter.pgOut();
    this.customReporter?.pgOut();

    return this;
  }

  destroy() {
    this.systemReporter.destroy();
    this.customReporter?.destroy();

    return this;
  }

  report(data) {
    this.normalReporter.report(data);
  }
}

export default RuntimeReport;
