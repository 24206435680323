/**
 *
 * 页面组件
 *
 * 将页面片包装成在线组件
 *
 */
import XyPageContainer from './xy-pagelet-component';

export default {
  component: XyPageContainer,
  slots: [],
  display: 'block',
  visible: true,
  order: 1,
  propsSchema: {
    type: 'object',
    fields: [],
  },
  // 预设值
};
