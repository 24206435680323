import { message } from 'ant-design-vue';

import { writeClipboard } from '@utils/clipboard';
import { eventEmitterAtomId } from '@components/pagelet-component/flowAtom/event-emitter';
import { isPageletComponent, isPageletComponentRenderer } from '@components/pagelet-component/utils';

import { toString } from '@/utils/toString';
import { XyLessCodeMethodSelectorMeta } from '../paramsFormComponents';
import { CATEGORY } from './const';
import pageletAtoms from './pageletAtoms';
import diff3Atoms from './diff3Atoms';

// 复制文本
export const copyTextAtom = {
  id: 'uicore:copyText',
  name: '复制文本',
  category: CATEGORY.LOGIC,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'copyValue',
        type: 'string',
        title: '复制内容',
        default: '请设置内容',
        ui: {
          settings: {
            multipleLine: true,
          },
        },
      },
      {
        id: 'isRemind',
        title: '是否提示复制成功',
        type: 'boolean',
        default: true,
      },
    ],
  },
  execute: async (ctx, params) => {
    const { isRemind = true } = params;
    await writeClipboard(toString(params.copyValue));
    if (isRemind) {
      message.success('复制成功');
    }
  },
};
// 调用 LessCode 方法
export const invokeLessCodeAtom = {
  id: 'xy:invokeLessCode',
  name: '调用 LessCode 方法',
  category: CATEGORY.LOGIC,
  desc: '支持 async 的被调方法。会等待完成后，才执行后续步骤。',
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'methodName',
        type: 'string',
        title: '方法名',
        ui: { type: 'XyLessCodeMethodSelector' },
      },
      {
        id: 'abortWhenReturnFalse',
        type: 'boolean',
        default: true,
        title: '若方法返回false 就终止流程',
      },
      {
        id: 'args',
        type: 'array',
        'uc:allowInterop': true,
        title: '参数',
        description: '如果需要使用变量、表达式，请使用双花括号',
        items: {
          type: 'string',
          ui: { type: 'exprInput', props: { type: 'interopable-string' } },
        },
      },
    ],
  },
  returnValue: '被调用函数的返回结果',
  paramsFormComponents: [
    XyLessCodeMethodSelectorMeta,
  ],
  async execute(ctx, params) {
    const { methodName, abortWhenReturnFalse, args } = params;
    // 目前逍遥只能在最外层编写 LessCode，所以限制一下先
    let { renderer } = ctx;
    // 但是要注意在线组件的场景下，组件的renderer就是最外层的renderer
    while (renderer.ucParentRenderer && !isPageletComponentRenderer(renderer.$el)) {
      renderer = renderer.ucParentRenderer;
    }
    if (typeof renderer[methodName] !== 'function') {
      throw new Error(`未找到 ${methodName} 方法`);
    }
    const ans = await renderer[methodName](...args || []);
    if (ans === false && abortWhenReturnFalse) ctx.abort();
    return ans;
  },
};

export const debuggerAtom = {
  id: 'xy:debugger',
  name: 'debugger ',
  desc: '将在 Console 输出当前步骤的一些调试信息，需配合浏览器 DevTools 查看',
  category: CATEGORY.LOGIC,
  async execute(ctx) {
    // eslint-disable-next-line no-new-func
    new Function('ctx', 'console.log(\'Flow Ctx\', ctx); debugger')(ctx);
  },
};


// 仅在在线组件的编辑器中可见
const PageletComponentEventEmitterAtom = {
  id: eventEmitterAtomId,
  name: '在线组件发送事件',
  desc: '将事件从这个在线组件中抛出',
  category: CATEGORY.LOGIC,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'eventKey',
        type: 'string',
        title: '事件Key',
      }, {
        id: 'eventName',
        type: 'string',
        title: '事件名称',
      },
      {
        id: 'eventPayload',
        type: 'string',
        title: '事件参数',
        ui: {
          type: 'exprInput',
          props: {
            type: 'javascript',
            multipleLine: true,
            placeholder:
              '此处的返回值会作为事件参数传递给使用这个在线组件的页面',
          },
        },
      },
      {
        id: 'eventDesc',
        type: 'string',
        title: '参数描述',
      },
    ],
  },
  async execute(ctx, params) {
    const payload = ctx.tryEval(params.eventPayload);
    let currentVnode = ctx.renderer;
    // 找到最近的在线组件容器
    while (!isPageletComponent(currentVnode.$el) && currentVnode.$parent) {
      currentVnode = currentVnode.$parent;
    }
    currentVnode.$emit(params.eventKey, payload);
  },
  returnValue: null,
  visible: false,
};


export default [
  invokeLessCodeAtom,
  ...pageletAtoms,
  copyTextAtom,
  ...diff3Atoms,
  debuggerAtom,
  PageletComponentEventEmitterAtom,
];
