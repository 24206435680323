import { useRouter } from '@/router/useRouter';
import { getAbsolutePath } from './beacon';
/**
 * 当要进行页面跳转的时候，使用这个函数来获取跳转的地址
 * 会处理prefix和/xy的前缀
 * @param targetURL 路径，不需要带/xy
 * @param query 跳转的query
 * @returns
 */
const getJumpURL = (targetURL, query) => {
    const { router } = useRouter();
    return getAbsolutePath(router.value?.resolve({
        path: targetURL,
        query,
    }).href);
};
export default getJumpURL;
