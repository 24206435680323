var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject, Vue } from 'vue-property-decorator';
import { message } from 'ant-design-vue';
import './mockSwitch.scss';
import MockJsonEditorModal from '@components/mockSwitch/mockJsonEditorModal';
/** 悬浮于页面底部的mock收集按钮 */
let MockSwitch = class MockSwitch extends Vue {
    ctx;
    /** mock按钮在底部的左侧距离 */
    switchLeftPx = 50;
    /** 在一次鼠标按下-抬起的过程中是否按钮发生了拖动 */
    moved = false;
    /** JSON编辑器弹窗的可见性 */
    modalVisible = false;
    /** 用于向modal传递mock数据 */
    mockData = {};
    /** 仅当dev环境启用mock数据收集 */
    get enableCollect() {
        try {
            // 判断当前的renderer是否支持mock操作
            const uiCoreCollectAble = this.getUicoreCRenderer()?.collectCRendererRuntimeData instanceof Function;
            // 仅当dev环境允许收集mock数据
            return uiCoreCollectAble && this.ctx.env === 'dev';
        }
        catch (e) {
            return false;
        }
    }
    /** 鼠标按下后判断拖动按钮/打开弹窗 */
    mousedown() {
        const pxBefore = this.switchLeftPx;
        document.onmousemove = (ev) => {
            this.switchLeftPx += ev.movementX;
            this.moved = Math.abs(this.switchLeftPx - pxBefore) > 2;
        };
        document.onmouseup = () => {
            document.onmousemove = null;
            document.onmouseup = null;
            this.$nextTick(() => (this.moved = false));
            this.$nextTick(() => {
                const { right, left } = this.$refs?.container?.getBoundingClientRect();
                if (right > window.innerWidth) {
                    this.switchLeftPx -= right - window.innerWidth;
                }
                else if (left < 0) {
                    this.switchLeftPx += 0 - left;
                }
            });
        };
    }
    /** 鼠标抬起时如果在按钮上并且没移动就打开弹窗 */
    mouseup() {
        if (!this.moved)
            this.collectMock();
    }
    /** 从vdom里拿到CRenderer 收集mock的能力由uicore提供 */
    getUicoreCRenderer() {
        // mockSwitch和UcRenderer同级
        const componentWSysPagelet = this.$parent.$children;
        // 从数组里这么扣子组件可能在结构变化时失效?
        const ucRenderer = componentWSysPagelet.filter(com => com?.$vnode?.tag?.includes('UcRenderer'))[0];
        return ucRenderer.$refs?.renderer;
    }
    /** 打开弹窗并传递收集到的mock数据 */
    collectMock() {
        try {
            const outputObject = this.getUicoreCRenderer().collectCRendererRuntimeData();
            if (!outputObject)
                throw Error('failed get mock');
            // 启动编辑器对mock数据进行修改
            this.modalVisible = true;
            // 从prop传递mock数据
            this.$set(this, 'mockData', outputObject);
        }
        catch (error) {
            message.error('[mock]获取页面数据失败');
            console.warn(error);
            return false;
        }
    }
    render() {
        return (this.enableCollect && (<div>
          <div ref="container" class="mockSwitch-container" style={{ left: `${this.switchLeftPx}px` }}>
            <a-button onMousedown={this.mousedown} onMouseup={this.mouseup}>
              mock
            </a-button>
          </div>
          <mockJsonEditorModal ref="mockJsonEditorModal" mockData={this.mockData} v-model={this.modalVisible}/>
        </div>));
    }
};
__decorate([
    Inject('ctx')
], MockSwitch.prototype, "ctx", void 0);
MockSwitch = __decorate([
    Component({
        name: 'MockSwitch',
        components: { MockJsonEditorModal },
    })
], MockSwitch);
export default MockSwitch;
