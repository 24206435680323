/**
 * 提供给页面开发的utilsloader标准方法，内部方法请勿变更
 */
import { getUserId, getUserAvatarURL } from '@utils/userInfo';

const userInfo = {
  /**
   * 获取用户信息
   * @param {'id'|'avatar'} key
   */
  get(key) {
    const keyLUT = {
      id: getUserId,
      avatar: getUserAvatarURL,
    };

    if (!(keyLUT[key])) {
      throw new Error(`key "${key}" not found`);
    }

    return keyLUT[key]();
  },
};

export default userInfo;
