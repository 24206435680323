import wujiFetch from '@utils/wujiFetch';
import { BASE_API_PATH } from '@config/constant';
import { fetchPublishSetting, fetchRuntimePermissionSetting } from '@composables/useProjectSetting';
import { ref, onBeforeMount, getCurrentInstance } from '@vue/composition-api';
export const ONE_DAY_MS = 86400e3;
const rtxCache = {};
export async function fetchRtxInfo(rtx) {
    const missCache = rtx.filter(r => !rtxCache[r]);
    if (missCache.length > 0) {
        const request = await wujiFetch(`${BASE_API_PATH}/dept-rtx/staffs?rtx=${missCache.join(',')}&extraFields=typeName`);
        request.forEach((item) => {
            if (!item)
                return;
            rtxCache[item.id] = item;
        });
    }
    return rtx.map(r => rtxCache[r]).filter(Boolean);
}
export function usePermissionSettings() {
    const permissionSettings = ref({});
    const { props } = getCurrentInstance();
    const isRuntimeMode = !!window.RUNTIME_CONFIGS;
    onBeforeMount(async () => {
        const res = isRuntimeMode
            ? await fetchRuntimePermissionSetting(props.projectId)
            : await fetchPublishSetting(props.projectId);
        permissionSettings.value = res?.permissionSetting || {};
    });
    return { permissionSettings };
}
