import FormPlaceholder from './FormPlaceholder.vue';

/** @type {UICore.ComponentMeta} */
const FormPlaceholderMeta = {
  id: 'xyclient-w-form-placeholder',
  name: '表单',
  category: '表单',
  component: FormPlaceholder,
  icon: 'https://vfiles.gtimg.cn/wuji_dashboard/xy/rainbow/icon-form_1636364104837.png',
};

export default FormPlaceholderMeta;
