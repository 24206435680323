import wujiComp from '@tencent/xy-compsloader';
import componentBase from '@tencent/wuji-component-base';

export * from './pageconfig-parser';
export * from './loader';
export * from './register';
export * from './requestor';
export * from './hmr';
export * from './replace-options';

export const initCompsLoader = (extendComps = {}) => {
  wujiComp.config(
    {
      baseComp: componentBase,
    },
    extendComps,
  );
  const staticFileHost = getStaticFileHost();
  if (staticFileHost) {
    wujiComp.setStaticFileHost('public', '', staticFileHost);
  }
};

// 非 default 组件都已经在后端隐藏了，这里只需要处理 default 组件
// default 组件指的是编辑器内置的组件
export const getHiddenComponents = () => {
  const hiddenComponent = window.GLOBAL_INFO.componentHubSettings.hiddenComponents?.default || [];
  if (Array.isArray(hiddenComponent)) {
    return {
      hideInServer: hiddenComponent,
    };
  }
  return hiddenComponent;
};

export const getStaticFileHost = () => window.GLOBAL_INFO.componentHubSettings?.staticFileHost;

export const registerModuleLoader = (type, loader) => {
  wujiComp.registerModuleLoader(type, loader);
};

export const setDefaultLibrary = (library) => {
  wujiComp.setDefaultLibrary(library);
};

export const getWujiCompInstance = () => wujiComp;
