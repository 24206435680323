<template>
  <a-spin
    :size="size"
    :tip="tip"
  >
    <a-icon
      slot="indicator"
      type="loading"
      style="font-size: 32px; margin-bottom: 12px;"
      spin
    />
  </a-spin>
</template>

<script>
export default {
  name: 'WSysSpin',
  props: {
    size: {
      type: String,
      default: 'large',
    },
    tip: {
      type: String,
      default: '',
    },
  },
};
</script>
