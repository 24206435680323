import wujiFetch from '@utils/wujiFetch';

const commonApi = '/api/xy/design/email/setting';

const fetchEmailList = async (projectId) => {
  const query = new URLSearchParams({ projectid: projectId });
  const result = await wujiFetch(`${commonApi}?${query}`);
  return result;
};

const fetchEmailDetail = async (projectId, id) => {
  const query = new URLSearchParams({ projectid: projectId });
  const result = wujiFetch(`${commonApi}/${id}?${query}`);
  return result;
};

const createEmailSetting = async (projectId, data) => {
  const query = new URLSearchParams({ projectid: projectId });
  const result = await wujiFetch(`${commonApi}?${query}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return result;
};

const updateEmailSetting = async (projectId, id, data) => {
  const query = new URLSearchParams({ projectid: projectId });
  const result = await wujiFetch(`${commonApi}/${id}?${query}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return result;
};

const deleteEmailSetting = async (projectId, id) => {
  const query = new URLSearchParams({ projectid: projectId });
  const result = await wujiFetch(`${commonApi}/${id}?${query}`, {
    method: 'DELETE',
  });
  return result;
};

export default {
  fetchEmailList,
  fetchEmailDetail,
  createEmailSetting,
  updateEmailSetting,
  deleteEmailSetting,
};
