import { defineComponent, reactive, watch, computed } from '@vue/composition-api';
import wujiFetch from '@utils/wujiFetch';
import { debounce } from 'lodash';
import { useStore } from '@/store';
import { BASE_API_PATH } from '@config/constant';
import { getUserInfo } from '@/utils/userInfo';
export const UserAutoCompleteInput = defineComponent({
    name: 'UserAutoCompleteInput',
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        userExistTag: {
            type: String,
            default: false,
        },
    },
    setup(props, { emit }) {
        const inputStatus = reactive({
            complete: [],
            userInfoList: [],
        });
        const store = useStore();
        const headers = {
            'xy-group-id': store.state.projectGroup.groupInfo.id,
        };
        const userInfo = getUserInfo();
        if (userInfo.corpId) {
            headers['xy-beacon-corp-id'] = userInfo.corpId;
        }
        const fetchSearch = async () => {
            const q = props.value;
            const res = await wujiFetch(`${BASE_API_PATH}/dept-rtx/search-staffs?q=${encodeURIComponent(q)}&size=20`, {
                headers,
            });
            inputStatus.userInfoList = res;
            inputStatus.complete = res.map(item => item.id);
            return res;
        };
        const searchDebounce = debounce(fetchSearch, 200);
        watch(() => props.value, searchDebounce, { immediate: true });
        const bindUser = computed(() => inputStatus.userInfoList.find(item => item.id === props.value));
        watch(() => bindUser.value, (bindUser) => {
            emit('bindUpdated', bindUser);
        });
        const handleChange = (value) => {
            emit('change', value);
        };
        return {
            inputStatus,
            handleChange,
            bindUser,
        };
    },
    render() {
        const rendererTag = () => {
            if (this.value === '') {
                return undefined;
            }
            if (this.bindUser) {
                return (<a-tag color="purple">
            将绑定到已存在用户 {this.bindUser.chnName}({this.bindUser.id})
          </a-tag>);
            }
            return (<a-tag color="green">
          将新建用户，后续可在 <i>组织架构</i> 里完善信息
        </a-tag>);
        };
        return (<div>
        <a-auto-complete placeholder={this.$attrs.placeholder} size={this.$attrs.size} value={this.value} dataSource={this.inputStatus.complete} onChange={this.handleChange} onBlur={() => this.$emit('blur')}>
        </a-auto-complete>
        {this.userExistTag && <div>
          {rendererTag()}
        </div>}
      </div>);
    },
});
