const registry = {};
/**
 *
 * @param {string} type 类型
 * @param {Object} config 配置
 * @param {string} config.name 类型名
 * @param {string} config.configComponent 配置组件
 * @param {Object} config.viewComponent 展示组件
 */
const register = (type, config) => {
  if (!config) return registry[type];
  registry[type] = config;
  return config;
};

const getTypes = () => {
  const types = [];
  Object.keys(registry).forEach((type) => {
    types.push({
      value: type,
      name: registry[type].name,
    });
  });

  return types;
};

const getComponents = (cType) => {
  const components = {};
  Object.keys(registry).forEach((type) => {
    const r = registry[type];
    components[r[cType].name] = r[cType];
  });
  return components;
};

const getConfigComponents = () => getComponents('configComponent');
const getViewComponents = () => getComponents('viewComponent');

export default {
  register,
  getTypes,
  getConfigComponents,
  getViewComponents,
};
