/**
 * 本模块用于请求小程序码功能
 */

import { BASE_API_PATH } from '@/config/constant';
import { objectToQueryString } from './utils-query';
import wujiFetch from '@/utils/wujiFetch';

const prefix = `${BASE_API_PATH}/xy/report/miniprogram`;

/**
 * @param {string} type - 见  server 的 src/Server/Routers/xy/reportRouter.js，例如 wxQRCode
 * @param {Record<string, any>} payload
 */
export function request(type, payload = {}) {
  return wujiFetch(`${prefix}/${type}?${objectToQueryString(payload)}`, {
    method: 'GET',
  });
}
