import { useStore as getStore } from '@store/index';
import { getUserInfo, getUserDeptAndOrgInfo } from '@utils/userInfo';
import { requestCollectionCgi, cacheRequestCollectionCgi } from '@utils/common';
import { getExportableToCtxSdk, permissionApi } from '@/services';
import { toPageByMenu, toPage } from '@utils/path';
import { checkPermission } from '../pages/modules/permission/utils';
import { PAGE_PERMISSIONS } from '../pages/modules/permission/constants';
import { useRouter } from '@/router/useRouter';
// 获取当前 VueRouter 实例
const getRouter = () => {
    const { router } = useRouter();
    return router.value;
};
// 获取当前 Route 实例
const getRoute = () => {
    const { route } = useRouter();
    return route.value;
};
// 无极内置 SDK Api
function initBuildInSdk({ project, env }) {
    const projectId = project?.id;
    const mToPage = ({ method = 'push', pageId, pagePath, query, newWindow }) => {
        const $router = getRouter();
        const $route = getRoute();
        const store = getStore();
        const pages = store?.state?.runtime?.basicPages ?? [];
        toPage({ pageId, pagePath, query, newWindow }, { method, pages, $router, $route });
    };
    const mToPageByMenu = ({ method, menu }) => {
        const $router = getRouter();
        const $route = getRoute();
        toPageByMenu(menu, { method, $router, $route, stringInteropContext: {} });
    };
    return {
        requestCollectionCgi: requestCollectionCgi.bind(undefined, projectId, env),
        cacheRequestCollectionCgi: cacheRequestCollectionCgi.bind(undefined, projectId, env),
        projectApi: {
            getProjectConfig: () => project,
            getSidebarConfig: () => project?.uiInfo?.sidebar ?? [],
        },
        pageApi: {
            getPageList: () => {
                const store = getStore();
                return store?.state?.runtime?.basicPages ?? [];
            },
            getCurrentPage: () => {
                const store = getStore();
                return store.state.runtime.currentPage ?? null;
            },
        },
        routerApi: {
            replacePage: ({ pageId, pagePath, menu, query }) => {
                if (menu) {
                    mToPageByMenu({ method: 'replace', menu });
                }
                else {
                    mToPage({ method: 'replace', pageId, pagePath, query });
                }
            },
            pushPage: ({ pageId, pagePath, menu, query }) => {
                if (menu) {
                    mToPageByMenu({ method: 'push', menu });
                }
                else {
                    mToPage({ method: 'push', pageId, pagePath, query });
                }
            },
            openPage: ({ pageId, pagePath, query }) => {
                mToPage({ pageId, pagePath, query, newWindow: true });
            },
        },
        ...getExportableToCtxSdk(),
    };
}
const context = {
    mode: 'runtime',
    user: {
        rtx: '',
        name: '',
        avatar: '',
        icon: '',
        chnName: '',
        deptName: '',
        deptNameString: '',
        workDeptId: '',
        workDeptIdString: '',
        workOrgIdString: '',
        roles: [],
        isAdmin: false,
    },
    host: location.host,
    project: null,
    projectId: '',
    env: '',
    sdk: null,
    // TopSwitch 相关
    topSwitch: {},
    refreshTopSwitch: null,
    topSwitchResult: {},
    // workflow 相关
    workflowEntity: null,
    workflowApprovalData: null,
    get currentPage() {
        const store = getStore();
        return store.state.runtime.currentPage;
    },
    get path() {
        return getRoute()?.path ?? '';
    },
    get query() {
        return getRoute()?.query ?? {};
    },
    get params() {
        return getRoute()?.params ?? {};
    },
    // 判断当前角色当前页面的权限
    hasPermission(permission, pageId) {
        if (!permission)
            return false;
        // 判断页面权限时校验页面 id
        if (PAGE_PERMISSIONS.includes(permission) && !context?.currentPage)
            return false;
        return checkPermission(context.user, permission, pageId || context?.currentPage?.pageId);
    },
};
// 获取全局上下文实例
export const getAppContext = ({ mode, project, env }) => {
    const projectId = project?.id;
    // 相同 project 直接返回 context 对象
    if (context.projectId === projectId)
        return context;
    if (project)
        context.project = project;
    if (mode)
        context.mode = mode;
    if (projectId)
        context.projectId = projectId;
    if (env)
        context.env = env;
    if (projectId && env) {
        initUser({ projectId, env });
        initStaff();
    }
    ;
    if (project && env)
        context.sdk = initBuildInSdk({ project, env });
    return context;
};
// 初始化用户信息
const initUser = async ({ projectId, env }) => {
    const user = getUserInfo();
    const userView = window.xy_runtime_userview ?? await permissionApi.fetchUserView(projectId, env);
    if (userView) {
        Object.assign(user, {
            roles: userView.roles ?? [],
            isAdmin: userView.isAdmin ?? false,
        });
    }
    Object.assign(context.user, user);
};
// 初始化员工信息
const initStaff = async () => {
    const { rtx } = getUserInfo();
    const staffInfo = await getUserDeptAndOrgInfo(rtx);
    if (staffInfo) {
        Object.assign(context.user, staffInfo);
    }
};
