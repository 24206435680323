/**
 * 由于tapable的hook没有取消注册钩子的能力，所以这里自己实现一个简单的hook
 */
class SimpleHook {
    uid = 0;
    hooks;
    constructor() {
        this.hooks = [];
    }
    tap(name, fn) {
        const { uid } = this;
        this.uid = this.uid + 1;
        this.hooks.push({
            name,
            fn,
            uid: this.uid,
        });
        return uid;
    }
    call(arg) {
        this.hooks.forEach((hook) => {
            try {
                hook.fn(arg);
            }
            catch (e) {
                console.error(`[simple-hook][${hook.name}]执行失败：`, e);
            }
        });
    }
    untap(uid) {
        this.hooks = this.hooks.filter(hook => hook.uid !== uid);
    }
}
export default SimpleHook;
