import logger from '@/utils/logger';

export default function openSimpleModal(context, Comp) {
  let resolve;

  const promise = new Promise(rs => (resolve = rs));

  const instance = new Vue({
    methods: {
      onCancel() {
        resolve({
          code: 1,
          message: 'cancel',
          data: null,
        });
        this.$destroy();
      },
      onSuccess(data) {
        resolve({
          code: 0,
          message: 'confirm',
          data,
        });
        this.$destroy();
      },
    },
    render(h) {
      return h(Comp, {
        props: {
          is: Comp,
          context,
          onCancel: this.onCancel,
          onSuccess: this.onSuccess,
        },
      });
    },
  });

  const el = document.createElement('div');
  document.body.appendChild(el);
  instance.$mount(el);

  return promise;
}

export const getProps = () => ({
  context: {
    type: Object,
    default: () => ({}),
  },
  onCancel: {
    type: Function,
    default: () => {
      logger.info('default onCancel');
    },
  },
  onSuccess: {
    type: Function,
    default: () => {
      logger.info('default onSuccess');
    },
  },
});
