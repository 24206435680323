import { defineComponent, reactive, onMounted, watch, ref, computed } from '@vue/composition-api';
import { Modal, message } from 'ant-design-vue';
import wujiFetch from '@utils/wujiFetch';
import { runtimePath } from '@config/constant';
import styles from './index.module.scss';
import { map, toPairs } from 'lodash';
import { usePromise } from '@utils/promise';
import { UserAutoCompleteInput } from '@pages/projectGroup/SsoUserApprove/UserAutoCompleteInput';
import { fetchGroupKVCfg, updateGroupKVCfg } from '@/store/projectGroup/utils';
const STATUS_CONFIG = {
    approved: {
        color: 'green',
        text: '已通过',
    },
    pending: {
        color: 'cyan',
        text: '审核中',
    },
    rejected: {
        color: 'orange',
        text: '已拒绝',
    },
    blocked: {
        color: 'red',
        text: '黑名单',
    },
};
const NEW_USER_REG_MODE_CONFIG = {
    open: {
        label: '开放注册，无需审批',
        desc: '新用户注册后免审批，可直接登录',
    },
    approved: {
        label: '允许注册，需要审批',
        desc: '新用户提交注册申请后，需要管理员审批通过后才能登录',
    },
    closed: {
        label: '关闭注册',
        desc: '新用户不能提交注册申请',
    },
};
const LongTextPre = defineComponent({
    name: 'LongTextPre',
    props: { content: String },
    setup(props) {
        return () => <a-tooltip>
      <div slot="title">
        <pre class={styles.longTextPrePopup}>{props.content}</pre>
      </div>
      <pre class={styles.longTextPre}>{props.content?.replace(/^-+\s*/, '')}</pre>
    </a-tooltip>;
    },
});
const getApproveAction = (groupId) => {
    const approveRequest = async (id, payload) => {
        const res = await wujiFetch(`${runtimePath}/group/${groupId}/registerRequests/${id}`, {
            method: 'POST',
            body: JSON.stringify(payload),
        });
        if (res.success) {
            message.success(res.message);
            return res;
        }
        message.error(res.message);
        return Promise.reject(res.message);
    };
    const showApproveConfirm = async (text, record) => {
        const formData = reactive({
            userId: record.requestedUserId,
            name: record.requestedName || '',
            approveComment: '',
        });
        const rules = {
            userId: [{ required: true, trigger: 'change' }],
        };
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const ModalContentComponent = defineComponent({
            name: 'ModalContentComponent',
            setup() {
                const bindUser = ref();
                return () => (<div style="padding-right: 30px;">
          <a-form-model props={{ model: formData }} rules={rules}>
            <a-form-model-item label="用户id" prop="userId">
              <UserAutoCompleteInput userExistTag={true} model={{ value: formData.userId, callback: (val) => (formData.userId = val) }} onBindUpdated={(u) => {
                        bindUser.value = u;
                    }}/>
            </a-form-model-item>

            {bindUser.value
                        ? (<a-form-model-item label="用户名">
                    <a-input disabled value={bindUser.value.chnName}/>
                  </a-form-model-item>)
                        : (<a-form-model-item label="用户名">
                    <a-input model={{ value: formData.name, callback: (val) => (formData.name = val) }}/>
                  </a-form-model-item>)}

            <a-form-model-item label="审核备注">
              <a-input model={{ value: formData.approveComment, callback: (val) => (formData.approveComment = val) }}/>
            </a-form-model-item>
          </a-form-model>
        </div>);
            },
        });
        const { resolve, promise } = usePromise();
        // async里拿不到this.$set了 所以jsx插件的vModel会坏掉只能手写on
        Modal.confirm({
            title: '通过审核',
            content: <ModalContentComponent />,
            okText: '通过',
            async onOk() {
                return approveRequest(record.id, {
                    ...formData,
                    approveStatus: 'approved',
                }).then(resolve);
            },
        });
        return promise;
    };
    const showRejectConfirm = (text, record) => {
        const formData = reactive({
            approveComment: '',
            block: false,
        });
        const { resolve, promise } = usePromise();
        Modal.confirm({
            title: '拒绝审核',
            content: () => (<div>
          <a-form-model>
            <a-form-model-item>
              <a-checkbox model={{ value: formData.block, callback: (val) => (formData.block = val) }}>
                拉入黑名单
              </a-checkbox>
            </a-form-model-item>
            <a-form-model-item label="拒绝原因">
              <a-input model={{ value: formData.approveComment, callback: (val) => (formData.approveComment = val) }}/>
            </a-form-model-item>
          </a-form-model>
        </div>),
            okText: '拒绝',
            async onOk() {
                return approveRequest(record.id, {
                    approveComment: formData.approveComment,
                    approveStatus: formData.block ? 'blocked' : 'rejected',
                }).then(resolve);
            },
        });
        return promise;
    };
    const unBlocked = async (text, record) => {
        await approveRequest(record.id, {
            approveStatus: 'pending',
        });
    };
    return {
        showApproveConfirm,
        showRejectConfirm,
        unBlocked,
    };
};
const prepareTableProps = () => {
    const tableColumns = reactive([
        {
            dataIndex: 'avatar',
            key: 'avatar',
            scopedSlots: { customRender: 'renderAvatar' },
        },
        {
            dataIndex: 'requestedUserId',
            key: 'requestedUserId',
            title: '用户名',
        },
        {
            dataIndex: 'requestedName',
            key: 'requestedName',
            title: '用户中文名',
        },
        {
            dataIndex: 'authPortId',
            key: 'authPortId',
            title: '登录方式',
            scopedSlots: { customRender: 'renderAuthPortId' },
        },
        {
            dataIndex: 'requestedComment',
            key: 'requestedComment',
            title: '申请备注',
            scopedSlots: { customRender: 'renderLongTextPre' },
        },
        {
            dataIndex: 'approveComment',
            key: 'approveComment',
            title: '审核情况',
            scopedSlots: { customRender: 'renderLongTextPre' },
        },
        {
            dataIndex: 'approveStatus',
            key: 'approveStatus',
            title: '审核状态',
            scopedSlots: { customRender: 'renderStatus' },
        },
        {
            key: 'action',
            title: '操作',
            scopedSlots: { customRender: 'renderAction' },
        },
    ]);
    const tableProps = reactive({
        columns: tableColumns,
        dataSource: [],
    });
    return {
        tableProps,
    };
};
const prepareFilter = (groupId, tableProps, authPortIdDict) => {
    const filter = reactive({
        authPortId: '',
        requestedUserId: '',
        approveStatus: '',
    });
    const pageProps = reactive({
        page: 1,
        size: 20,
        total: 100,
    });
    const makeQueryFilter = () => {
        const query = map(filter, (item, key) => {
            if (item) {
                // 可能出现慢查? 不过人员表按理来说应该不会很大
                return `${key}%%${item}`;
            }
            return undefined;
        })
            .filter(Boolean)
            .join('&');
        return encodeURIComponent(query);
    };
    const getTotal = async () => {
        const url = `${runtimePath}/group/${groupId}/registerRequests?count=1&filter=${makeQueryFilter()}`;
        const res = await wujiFetch(url);
        pageProps.total = res.total || 20;
    };
    const updateRegisterRequests = async () => {
        const totalRequest = getTotal();
        const url = `${runtimePath}/group/${groupId}/registerRequests?page=${pageProps.page}&size=${pageProps.size}&filter=${makeQueryFilter()}`;
        const res = await wujiFetch(url);
        await totalRequest;
        tableProps.dataSource = res;
    };
    watch(() => filter.approveStatus, () => {
        updateRegisterRequests();
    });
    watch(() => [pageProps.page, pageProps.size], updateRegisterRequests);
    const renderFilterForm = function () {
        return (<a-form-model layout="inline">
        <a-form-model-item label="用户名">
          <a-input v-model={filter.requestedUserId}></a-input>
        </a-form-model-item>
        <a-form-model-item label="登录渠道">
          <a-select allowClear={true} v-model={filter.authPortId} style="min-width:100px;">
            {map(toPairs(authPortIdDict), ([key, text]) => (<a-select-option value={key}>{text}</a-select-option>))}
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="审核状态">
          <a-select allowClear={true} v-model={filter.approveStatus} style="min-width:100px;">
            {map(STATUS_CONFIG, (item, key) => (<a-select-option value={key}>{item.text}</a-select-option>))}
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button onClick={updateRegisterRequests}>搜索</a-button>
        </a-form-model-item>
      </a-form-model>);
    };
    const renderPagination = function () {
        const handleShowSizeChange = (current, size) => {
            pageProps.size = size;
        };
        return (<div class={styles.pagination}>
        <a-pagination defaultPageSize={pageProps.size} pageSizeOptions={['20', '50', '100']} v-model={pageProps.page} total={pageProps.total} onShowSizeChange={handleShowSizeChange} show-size-changer show-quick-jumper/>
      </div>);
    };
    return { updateRegisterRequests, renderFilterForm, renderPagination, filter };
};
export default defineComponent({
    name: 'SsoUserApprove',
    components: {},
    props: {
        groupId: { type: String, default: '' },
    },
    setup(props, { root }) {
        const groupId = props.groupId || root.$route.params.groupId;
        const authPortIdDict = reactive({});
        onMounted(async () => {
            const url = `${runtimePath}/group/${groupId}/authPorts`;
            const res = await wujiFetch(url);
            res.forEach((it) => {
                authPortIdDict[it.id] = it.name;
            });
        });
        // 新用户审批方式
        const kvConfigs = reactive({
            newUserRegMode: 'open',
            userIdLimitation: { forcePrefix: '', forceSuffix: '' },
            customRegForm: { enable: false, url: '' },
        });
        onMounted(async () => {
            Object.assign(kvConfigs, await fetchGroupKVCfg(groupId, Object.keys(kvConfigs)));
        });
        const changeNewUserRegMode = async (value) => {
            kvConfigs.newUserRegMode = value;
            await updateGroupKVCfg(groupId, 'newUserRegMode', value);
        };
        const renderNewUserRegMode = () => (<a-select value={kvConfigs.newUserRegMode ?? 'approved'} onChange={changeNewUserRegMode} style="width:200px">
        {map(NEW_USER_REG_MODE_CONFIG, (item, key) => (<a-select-option value={key}>
            {item.label}
            <a-tooltip title={item.desc} slot="suffixIcon">
              <a-icon type="question-circle" style="margin-left:5px"/>
            </a-tooltip>
          </a-select-option>))}
      </a-select>);
        const tempIdLimitation = reactive({ forcePrefix: '', forceSuffix: '' });
        const pInputStyle = {
            background: '#222',
            color: '#fff',
            border: '1px solid #666',
            borderRadius: '2px',
            padding: '2px 4px',
            fontSize: 'inherit',
            outline: '0',
            margin: '4px 0',
        };
        const submitTempIdLimitation = async (e) => {
            e.preventDefault();
            const { forcePrefix, forceSuffix } = tempIdLimitation;
            try {
                const re = /^[\w-@.]*$/;
                if (!re.test(forcePrefix))
                    throw new Error('前缀只能包含字母数字下划线@-.');
                if (!re.test(forceSuffix))
                    throw new Error('后缀只能包含字母数字下划线@-.');
                await updateGroupKVCfg(groupId, 'userIdLimitation', { forcePrefix, forceSuffix });
                message.success('修改成功（注意：仅对新的注册生效，存量申请不受影响）');
                kvConfigs.userIdLimitation.forcePrefix = forcePrefix;
                kvConfigs.userIdLimitation.forceSuffix = forceSuffix;
            }
            catch (error) {
                console.error(error);
                message.error(`修改失败，${error.message}`);
            }
        };
        const renderUserIdLimitation = computed(() => {
            if (kvConfigs.newUserRegMode === 'closed')
                return null;
            const { forcePrefix, forceSuffix } = kvConfigs.userIdLimitation;
            return <a-tooltip trigger="click">
        <a-button icon="setting" onClick={() => Object.assign(tempIdLimitation, kvConfigs.userIdLimitation)}>
          {!(forcePrefix || forceSuffix) ? '未限制用户ID' : `用户ID：${forcePrefix}***${forceSuffix}`}
        </a-button>

        <form slot="title" onSubmit={e => void submitTempIdLimitation(e)}>
          <div>前缀：<input spellcheck={false} style={pInputStyle} placeholder='(无)' value={tempIdLimitation.forcePrefix} onInput={e => void (tempIdLimitation.forcePrefix = e.target.value)}/></div>
          <div>后缀：<input spellcheck={false} style={pInputStyle} placeholder='(无)' value={tempIdLimitation.forceSuffix} onInput={e => void (tempIdLimitation.forceSuffix = e.target.value)}/></div>
          <div style="margin-top: 2px; text-align: center"><a-button size="small" onClick={submitTempIdLimitation}>确定</a-button></div>
        </form>
      </a-tooltip>;
        });
        const tempCustomRegForm = reactive({ enable: false, url: '' });
        const submitCustomRegForm = async (e) => {
            e.preventDefault();
            if (tempCustomRegForm.url) {
                try {
                    new URL(tempCustomRegForm.url);
                }
                catch (e) {
                    message.error(`无效的 URL，${e.message}`);
                    return;
                }
            }
            try {
                await updateGroupKVCfg(groupId, 'customRegForm', tempCustomRegForm);
                kvConfigs.customRegForm.enable = tempCustomRegForm.enable;
                kvConfigs.customRegForm.url = tempCustomRegForm.url;
                message.success('修改成功');
            }
            catch (e) {
                message.error(`修改失败，${e.message}`);
            }
        };
        const renderCustomRegForm = computed(() => {
            if (kvConfigs.newUserRegMode === 'closed')
                return null;
            return <a-tooltip trigger="click">
        <a-button icon="setting" onClick={() => Object.assign(tempCustomRegForm, kvConfigs.customRegForm)}>
          {'自定义新用户表单'}
        </a-button>

        <form slot="title" onSubmit={e => void submitCustomRegForm(e)}>
          <a-checkbox checked={tempCustomRegForm.enable} onChange={e => (tempCustomRegForm.enable = e.target.checked)}>启用</a-checkbox>
          <div>自定义表单链接：<input style={pInputStyle} value={tempCustomRegForm.url} onInput={e => void (tempCustomRegForm.url = e.target.value)}/></div>
          <div style="margin-top: 2px; text-align: center"><a-button size="small" onClick={submitCustomRegForm}>确定</a-button></div>
        </form>
      </a-tooltip>;
        });
        const { tableProps } = prepareTableProps();
        const { updateRegisterRequests, renderFilterForm, renderPagination, filter, } = prepareFilter(groupId, tableProps, authPortIdDict);
        filter.approveStatus = String(root.$route.query.approveStatus ?? 'pending');
        watch(() => filter.approveStatus, (approveStatus) => {
            const { query } = root.$route;
            root.$router.replace({ query: { ...query, approveStatus } });
        });
        onMounted(updateRegisterRequests);
        /** 解耦?  */
        const { showRejectConfirm, showApproveConfirm, unBlocked } = getApproveAction(groupId);
        const renderAction = (...args) => {
            const onApprove = async () => {
                await showApproveConfirm(...args);
                await updateRegisterRequests();
            };
            const onReject = async () => {
                await showRejectConfirm(...args);
                await updateRegisterRequests();
            };
            const { approveStatus } = args[1];
            const onUnBlocked = async () => {
                await unBlocked(...args);
                await updateRegisterRequests();
            };
            if (approveStatus === 'blocked') {
                return (<span class={styles.actionGroup}>
            <a-popconfirm title="确定解除黑名单?" okText="是" cancelText="否" onConfirm={onUnBlocked}>
              <a-button type="primary" size="small">
                解除黑名单
              </a-button>
            </a-popconfirm>
          </span>);
            }
            if (approveStatus === 'approved') {
                return (<div style="width:32px"/>);
            }
            return (<span class={styles.actionGroup}>
          <a-button type="primary" size="small" onClick={onApprove}>
            通过
          </a-button>
          <a-button size="small" onClick={onReject}>
            拒绝
          </a-button>
        </span>);
        };
        const renderAvatar = (text) => <img height={48} width={48} src={text}/>;
        const renderStatus = (text) => {
            const config = STATUS_CONFIG[text];
            return <a-tag props={config}>{config.text}</a-tag>;
        };
        const renderAuthPortId = (text) => <span>{String(authPortIdDict[text] || text)}</span>;
        const renderLongTextPre = (text) => <LongTextPre content={text}/>;
        return {
            updateRegisterRequests,
            tableProps,
            renderAvatar,
            renderAuthPortId,
            renderStatus,
            renderAction,
            renderFilterForm,
            renderPagination,
            renderLongTextPre,
            renderNewUserRegMode,
            renderUserIdLimitation,
            renderCustomRegForm,
        };
    },
    render() {
        const { renderAction, renderAvatar, renderAuthPortId, renderStatus, renderFilterForm, renderPagination, renderLongTextPre, renderNewUserRegMode, renderUserIdLimitation, renderCustomRegForm, } = this;
        return (<a-layout class={styles.layout}>
        <h2>新用户审批</h2>
        <div class={styles.regMode}>
          新用户注册方式
          {renderNewUserRegMode()}
          {renderUserIdLimitation}
          {renderCustomRegForm}
        </div>
        <div class={styles.approveCard}>
          <div>
            {renderFilterForm()}
            <a-button icon="reload" onClick={this.updateRegisterRequests}>
              刷新列表
            </a-button>
          </div>
          <div style="width:100%">
            <a-table class={styles.table} rowKey="id" columns={this.tableProps.columns} data-source={this.tableProps.dataSource} pagination={false} scopedSlots={{ renderAction, renderAuthPortId, renderAvatar, renderStatus, renderLongTextPre }}></a-table>
          </div>
          {renderPagination()}
        </div>
      </a-layout>);
    },
});
