import wujiFetch from '@/utils/wujiFetch';
import { message } from 'ant-design-vue';
import { PROJECT_ADVANCE_CODE_PATH, getPageletLessCodePath, getModuleCodePath } from '@components/CodeEditor/utils/builder';
import { keyBy } from 'lodash';
import getXyManagePathPrefix from '@/utils/getXyManagePathPrefix';

export default {
  state: {
    modifyRecords: {},
  },
  actions: {
    // 根据ID获取单条记录
    async getModifyRecordsById({ rootState }, { projectId, id }) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const res = await wujiFetch(`${getXyManagePathPrefix()}/modify-record/${projectID}/${id}`, {
          method: 'GET',
        });
        return res;
      } catch (err) {
        message.error(`获取id为${id}的修改记录失败: ${err}`);
      }
    },

    // 根据类型获取记录列表
    async getModifyRecordsByType({ commit, rootState }, { projectId, type }) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const records = await wujiFetch(`${getXyManagePathPrefix()}/modify-record/${projectID}?type=${type}`, {
          method: 'GET',
        });
        commit('setModifyRecordsByType', { type, records });
      } catch (err) {
        message.error(`获取${type}类型修改记录列表失败: ${err}`);
      }
    },
    // 保存修改记录
    async saveModifyRecord({ rootState }, { projectId, type, data, remoteData = '' }) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        // 为了兼容拉取和合并分支没有修改记录的情况，先保存远程记录再保存本地记录，server会判断是否重复
        await wujiFetch(
          `${getXyManagePathPrefix()}/modify-record/${projectID}`,
          {
            method: 'POST',
            body: JSON.stringify({ type, data: remoteData }),
          },
        );
        return await wujiFetch(
          `${getXyManagePathPrefix()}/modify-record/${projectID}`,
          {
            method: 'POST',
            body: JSON.stringify({ type, data }),
          },
        );
      } catch (err) {
        message.error(`保存${type}类型修改记录失败: ${err.message}`);
        return false;
      }
    },
    // 保存项目的修改记录
    async saveProjectRecord({ dispatch }, { projectId, local, remote }) {
      if (!projectId) return;
      const localAdvanceConfig = local?.advanceConfig;
      const remoteAdvanceConfig = remote?.advanceConfig ?? {};
      // 项目模块
      if (localAdvanceConfig) {
        const data = Object.keys(PROJECT_ADVANCE_CODE_PATH).filter((key) => {
          const localValue = localAdvanceConfig[key] ?? '';
          const remoteValue = remoteAdvanceConfig[key] ?? '';
          return localValue !== remoteValue;
        })
          ?.map(key => ({
            type: PROJECT_ADVANCE_CODE_PATH[key],
            data: JSON.stringify(localAdvanceConfig[key] ?? ''),
            remoteData: JSON.stringify(remoteAdvanceConfig[key] ?? ''),
          }));
        await dispatch('batchSaveModifyRecord', {
          data,
          projectId,
        });
      }

      // 项目 LessCode
      const localModules = local?.lessCodeModules ?? {};
      const remoteModules = remote?.lessCodeModules ?? {};
      const data = Object.keys(localModules).filter((key) => {
        const localModule = localModules[key];
        const remoteModule = remoteModules[key];
        return localModule?.code !== remoteModule?.code;
      })
        ?.map(key => ({
          type: getModuleCodePath(localModules[key].filename),
          data: JSON.stringify(localModules[key].code),
          remoteData: JSON.stringify(remoteModules[key].code),
        }));
      await dispatch('batchSaveModifyRecord', {
        data,
        projectId,
      });
    },
    // 保存页面的修改
    async savePageRecord({ dispatch }, { projectId, local, remote }) {
      const localPageConfig = local?.pageConfig;
      const remotePageConfig = remote?.pageConfig;
      if (!Array.isArray(localPageConfig) || !Array.isArray(remotePageConfig) || !projectId) return;
      const localKeys = localPageConfig.map(item => item.id);
      const localMap = keyBy(localPageConfig, 'id');
      const remoteMap = keyBy(remotePageConfig, 'id');
      const data = localKeys.filter((key) => {
        const localLessCode = localMap[key]?.lessCode ?? '';
        const remotePagelet = remoteMap[key];
        const remoteLessCode = remotePagelet?.lessCode ?? '';
        return (remotePagelet && localLessCode !== remoteLessCode) || !remotePagelet;
      })
        ?.map(key => ({
          type: getPageletLessCodePath(local.pageId, localMap[key].id),
          data: JSON.stringify(localMap[key]?.lessCode ?? ''),
          remoteData: JSON.stringify(remoteMap[key]?.lessCode ?? ''),
        }));
      await dispatch('batchSaveModifyRecord', {
        data,
        projectId,
      });
    },
    async batchSaveModifyRecord({ dispatch }, { data, projectId }) {
      // 如果未创建修改历史表，会先创建，因此先单独发起一个
      const firstData = data?.[0];
      const modifyData = data?.splice(1);
      if (firstData) {
        await dispatch('saveModifyRecord', {
          projectId,
          type: firstData.type,
          data: firstData.data,
          remoteData: firstData.remoteData,
        });
      }
      modifyData?.forEach((item) => {
        dispatch('saveModifyRecord', {
          projectId,
          type: item.type,
          data: item.data,
          remoteData: item.remoteData,
        });
      });
    },
  },
  mutations: {
    setModifyRecordsByType(state, { type, records }) {
      state.modifyRecords[type] = records;
    },
  },
};
