import { computed, ref, onBeforeUnmount, watch } from '@vue/composition-api';
import { throttle } from 'lodash';
import loadMiniProgramSidebarStyle from '@miniprogram/utils/add-css';
import { startMouseMove } from '@tencent/ui-core/lib/utils';
import { useRouter } from '@/router/useRouter';
export default function useSidebar({ isMobileOrNarrowScreen, width, mode = ref('runtime') }) {
    const { route } = useRouter();
    const isShowCollapseTrigger = ref(false); // 是否展示折叠按钮
    // 菜单栏的展开/收起状态
    const collapsed = ref(false); // 是否展开菜单栏
    /**
     * [Open-Api]设置当前菜单收起展开态
     *
     * @param value {boolean} true 表示打开, false 表示收起
     */
    const setCollapsed = (value) => {
        if (typeof value === 'boolean') {
            isShowCollapseTrigger.value = true;
            collapsed.value = value;
        }
    };
    /**
     * [Open-Api]切换当前菜单收起展开态
     */
    function toggleCollapsed() {
        setCollapsed(!collapsed.value);
    }
    /** 窄屏/手机/小程序隐藏菜单 */
    const setMobileCollapsed = (value = true) => {
        if (isMobileOrNarrowScreen.value) {
            setCollapsed(value);
            loadMiniProgramSidebarStyle();
        }
    };
    const extraWidth = ref(0); // 侧边栏移动
    // 菜单的原始宽度
    const sidebarWidthCache = ref(0);
    const getSidebarWidth = (useCache = true) => {
        if (useCache && sidebarWidthCache.value)
            return sidebarWidthCache.value;
        const elSidebar = document.getElementById('xy-runtime-sidebar');
        sidebarWidthCache.value = elSidebar?.clientWidth ?? 0;
        return sidebarWidthCache.value;
    };
    // 菜单的计算宽度
    const computedWidth = computed(() => {
        let originWidth = width.value;
        if (typeof originWidth === 'number')
            originWidth = `${originWidth}px`;
        if (extraWidth.value === 0)
            return originWidth;
        return `calc(${originWidth} + ${extraWidth.value}px)`;
    });
    const maskLeft = computed(() => ({ left: `${collapsed.value ? 0 : computedWidth.value}` }));
    // 菜单的宽度变化时, 重新计算菜单的原始宽度
    watch(width, () => {
        // 延迟是为了消除宽度变化动画导致获取宽度不准确的影响
        setTimeout(() => getSidebarWidth(false), 2 * 1000);
    });
    /** 拖动改变宽度 */
    function startResize(event) {
        const o = extraWidth.value;
        startMouseMove({
            initialEvent: event,
            onMove: ({ deltaX }) => {
                const sidebarWidth = getSidebarWidth();
                extraWidth.value = Math.min(Math.max(0, o + deltaX), sidebarWidth * 0.5);
            },
        }).then();
    }
    // 运行时逻辑
    if (mode.value === 'runtime') {
        // 处理鼠标移动 -> 判断是否显示展开/收起的按钮
        const handleMouseMove = throttle((event) => {
            const { clientX } = event;
            const sidebarWidth = getSidebarWidth();
            const triggerX = collapsed.value ? 30 : sidebarWidth + extraWidth.value + 20;
            isShowCollapseTrigger.value = clientX < triggerX;
        }, 100);
        window.addEventListener('mousemove', handleMouseMove, { passive: true });
        onBeforeUnmount(() => window.removeEventListener('mousemove', handleMouseMove));
        // 监听 isMobileOrNarrowScreen 值
        watch(isMobileOrNarrowScreen, (val) => {
            val ? setMobileCollapsed(true) : setCollapsed(false);
        }, { immediate: true });
        // 监听路径变化, 隐藏移动端菜单
        watch(() => route?.value?.fullPath, () => {
            setMobileCollapsed(true);
        });
    }
    return {
        collapsed,
        setCollapsed,
        toggleCollapsed,
        setMobileCollapsed,
        computedWidth,
        isShowCollapseTrigger,
        maskLeft,
        extraWidth,
        startResize,
    };
}
