import { getCurrentUser } from '@/utils/rtx';

const INPUT_BLOCK = ['approval'];


const isApprovalBlockInput = (block) => {
  const lastTimeline = block.timeline[block.timeline.length - 1];
  if (lastTimeline.approvalStatus !== 'waiting') {
    return false;
  }
  return lastTimeline.approvers.split(',').includes(getCurrentUser());
};

const isInputBlock = (block) => {
  if (!INPUT_BLOCK.includes(block.type)) {
    return false;
  }
  switch (block.type) {
    case 'approval':
      return isApprovalBlockInput(block);
    default:
      return false;
  }
};

export default isInputBlock;
