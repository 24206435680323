import { defineComponent } from '@vue/composition-api';
import useApproval from '@/pages/notification/useApproval';
export default defineComponent({
    name: 'NotificationEntry',
    methods: {
        gotoNotification() {
            this.$router.push('/notifications/approvals');
        },
    },
    setup() {
        const { getTodoCount, todoCount } = useApproval();
        return { getTodoCount, todoCount };
    },
    created() {
        this.getTodoCount();
    },
    render() {
        return (<div onClick={this.gotoNotification} wj-eid="审批页面">
        {this.todoCount > 0 ? (<a-tooltip title={`你有${this.todoCount}条待办审批`}>
            <a-badge dot>
              <a-icon type="bell"/>
            </a-badge>
          </a-tooltip>) : (<a-icon type="bell" style="font-size: 16px"/>)}
      </div>);
    },
});
