import wujiFetch from '@utils/wujiFetch';
import { designPath } from '@config/constant';
const baseUrl = `${designPath}/plugin`;

const installPlugin = async ({ projectId, pageId, pluginId, data = {}, headers = {} }) => await wujiFetch(
  `${baseUrl}/${pluginId}?projectid=${projectId}&pageid=${pageId}`,
  {
    method: 'POST',
    body: JSON.stringify(data),
    headers,
  },
);

const updatePluginConfig = async ({ projectId, pageId, pluginId, pluginConfig = {}, headers = {} }) => await wujiFetch(
  `${baseUrl}/patch/${pluginId}?projectid=${projectId}&pageid=${pageId}`,
  {
    method: 'POST',
    headers,
    body: JSON.stringify(pluginConfig),
  },
);

const uninstallPlugin = async ({ projectId, pageId, pluginId, headers = {} }) => await wujiFetch(
  `${baseUrl}/delete/${pluginId}?projectid=${projectId}&pageid=${pageId}`,
  {
    method: 'POST',
    headers,
  },
);

export default {
  installPlugin,
  updatePluginConfig,
  uninstallPlugin,
};
