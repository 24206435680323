import { BASE_API_PATH } from '@config/constant';
import getXyManagePathPrefix from '@utils/getXyManagePathPrefix';

import wujiFetch from '@utils/wujiFetch';

const getApiPrefix = () => `${getXyManagePathPrefix()}/workflow`;

const fetchSchema = async (projectId, appId, schemaId) => {
  const query = new URLSearchParams({
    projectid: projectId,
    appid: appId,
  });

  const result = wujiFetch(`${BASE_API_PATH}/xy/access/schema/${schemaId}?${query.toString()}`);
  return result;
};

const fetchSchemaWorkflow = async (projectId, appId, schemaId) => {
  const query = new URLSearchParams({
    projectid: projectId,
    appid: appId,
    schemaid: schemaId,
  });
  const result = wujiFetch(`${BASE_API_PATH}/xy/access/workflow?${String(query)}`);
  return result;
};

const fetchDataSources = async (projectId) => {
  const apiPrefix = getApiPrefix();
  const result = wujiFetch(`${apiPrefix}/project/${projectId}/schemas`);
  return result;
};

const fetchCollections = async (projectId) => {
  const query = new URLSearchParams({
    projectid: projectId,
    size: 'total',
  });
  const result = wujiFetch(`${getXyManagePathPrefix()}/datasource/collection?${String(query)}`);
  return result;
};

const fetchEnvList = async (projectId) => {
  const result = wujiFetch(`${BASE_API_PATH}/xy/env?projectid=${projectId}`);
  return result;
};

const fetchWorkflow = async (projectId, env, id) => {
  const apiPrefix = getApiPrefix();
  const result = wujiFetch(`${apiPrefix}/${id}?projectid=${projectId}`);
  return result;
};

const fetchWorkflows = async (projectId) => {
  const apiPrefix = getApiPrefix();
  const query = new URLSearchParams({
    projectid: projectId,
    size: 'total',
    sort: '_mtime',
    order: 'desc',
  });
  const result = wujiFetch(`${apiPrefix}?${query}`);
  return result;
};

const createWorkflow = async (projectId, env, data) => {
  const apiPrefix = getApiPrefix();
  const result = wujiFetch(`${apiPrefix}?projectid=${projectId}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return result;
};

const updateWorkflow = async (projectId, env, id, data) => {
  const apiPrefix = getApiPrefix();
  const result = wujiFetch(`${apiPrefix}/${id}?projectid=${projectId}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return result;
};

const deleteWorkflow = async (projectId, env, id) => {
  const apiPrefix = getApiPrefix();
  const result = wujiFetch(`${apiPrefix}/${id}?projectid=${projectId}`, {
    method: 'DELETE',
  });
  return result;
};

const transition = async (projectId, env, entityId, data) => {
  const apiPrefix = getApiPrefix(env);
  const result = wujiFetch(`${apiPrefix}/transition/${entityId}?projectid=${projectId}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return result;
};


export default {
  fetchSchema,
  fetchDataSources,
  fetchCollections,
  fetchSchemaWorkflow,
  fetchEnvList,
  fetchWorkflow,
  fetchWorkflows,
  createWorkflow,
  updateWorkflow,
  deleteWorkflow,
  transition,
};
