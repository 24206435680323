<template>
  <a-menu
    :selected-keys="selectedKeys"
    style="width: 100%;overflow-x: hidden;"
    mode="inline"
  >
    <a-menu-item
      v-for="group of showSidebarGroup"
      :key="group.groupId"
      @click="handleGroupClick(group)"
    >
      {{ group.groupName }}
    </a-menu-item>
  </a-menu>
</template>

<script>
import { message } from 'ant-design-vue';
import { toPageByMenu } from '@utils/path';
import { getGroupFirstLinkMenu } from '@components/sidebar/utils';

export default {
  name: 'MiniprogramMenusGroup',
  inject: {
    w: {
      from: 'w',
      default: {},
    },
  },
  props: {
    showSidebarGroup: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    selectedKeys() {
      const keys = this?.w?.sidebar?.getCurrentGroup();
      return keys ? [keys] : [];
    },
  },
  methods: {
    handleGroupClick(group) {
      const defaultMenu = getGroupFirstLinkMenu(group);
      if (defaultMenu) {
        toPageByMenu(defaultMenu, {
          $router: this.$router,
          $route: this.$route,
          stringInteropContext: {
            ...(this.w?.mainRenderer ?? {}),
            $app: this.$app,
            w: this.w,
          },
        });
      } else {
        message.warning(`该导航组[${group.groupName}]没有配置页面菜单`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
