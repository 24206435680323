<template>
  <!-- 注意: wuji-runtime-header-${currentEnv} 为系统暴露给业务使用的 class -->
  <a-layout-header
    class="header"
    :class="[`${navTheme}-header`, `wuji-runtime-header-${currentEnv} ${navTheme}`]"
    wj-mod="导航"
    :style="customNavStyle"
  >
    <div class="nav-left">
      <!-- 应用快捷切换面板 -->
      <wuji-navigator
        v-if="isShowWujiNavigator"
        :disabled="disabled ? 'true' : 'false'"
        :theme="navTheme"
        class="wuji-navigator"
      />
      <!-- 图标 -->
      <a
        v-if="visible('projectLogo') || visible('name')"
        class="nav-logo"
        @click="onClickLogo"
      >
        <img
          v-if="visible('projectLogo')"
          class="img"
          :src="projectLogo"
        >
        <span
          v-if="visible('name')"
          class="title"
        >{{ project.name }}</span>
      </a>
      <!-- 环境切换 -->
      <NavEnvSelect
        v-if="visible('env')"
        :mode="mode"
        :theme="navTheme"
        :show-list="envShowList"
        :redirect="envRedirect"
        :disabled="disabled"
      />
      <!-- 业务切换 -->
      <div
        v-if="hasWidgets"
        class="flex-container"
      >
        <NavWidget
          :project-id="project.id"
          :widgets="widgets"
          :theme="navTheme"
        />
      </div>
      <NavMenuGroup
        :disabled="disabled"
        :height="56"
      />
      <HmrPageList
        v-if="isHmrDebug"
        :mode="mode"
        :project-id="project.id"
      />
      <!-- 自定义左侧组件 -->
      <NavComps
        ref="dynamic-comps"
        :project="project"
        :disabled="disabled"
        :position="'left'"
        :mode="mode"
      />
    </div>
    <template v-if="mode === 'runtime' && !disabled && defaultAva.anncmnt">
      <!-- 公告走马灯 -->
      <anncmntLoop
        :project-id="projectId"
        :is-dark="isNavDark"
        :custom-style="carouselStyle"
        @click="handleAnncmntLoopClick"
      />
      <!-- 公告弹窗 -->
      <AnncmntModal />
      <!-- 公告抽屉 -->
      <AnncmntDrawer
        ref="anncmntDrawer"
        :visible="anncmntDrawerVisible"
        :project-id="projectId"
        @close="showAnncmntDrawer"
      />
    </template>
    <div class="nav-user-quick">
      <!-- 自定义右侧组件 -->
      <NavComps
        ref="dynamic-comps"
        :project="project"
        :disabled="disabled"
        :position="'right'"
        :mode="mode"
      />
      <!-- 平台切换 -->
      <NavLink
        :navlink="uiInfo.navlink"
        :disabled="disabled"
        :mode="navTheme"
      />
      <Notification
        v-if="mode !== 'runtime' && mode!== 'preview'"
      />
      <!-- 账户信息 -->
      <nav-user
        v-if="visible('user')"
        ref="user"
        :task-number="defaultAva.pending?taskNumber:0"
        :page-mode="mode"
        :mode="navTheme"
      >
        <template #default="{ close }">
          <div
            style="padding-top: 4px;"
            @click="close()"
          >
            <div
              v-for="(menu, index) in avatars"
              :key="index"
            >
              <NavLinkItem
                v-if="menu.type === 'link'"
                :link="menu"
                :disabled="disabled"
              />
              <template v-else-if="menu.type === 'menu'">
                <div
                  v-for="(item, i) in menu.args.menus"
                  :key="i"
                >
                  <NavLinkItem
                    v-if="item.type === 'link'"
                    :link="item"
                    :disabled="disabled"
                  />
                </div>
              </template>
            </div>
            <!-- eslint-disable-next-line vue/no-lone-template -->
            <template>
              <!-- 待办 -->
              <div
                v-if="defaultAva.pending"
                :class="{'nav-link-item-active': $route.name === 'workflow.entity-list'}"
              >
                <pending
                  :disabled="disabled"
                  :task-number="taskNumber"
                />
              </div>
              <!-- 公告 -->
              <div
                v-if="defaultAva.anncmnt"
                :class="'nav-link-item'"
                @click="showAnncmntDrawer"
              >
                <a-icon type="calendar" />
                <span>公告</span>
              </div>
              <div
                v-if="isShowGoToPageConfig"
                :class="'nav-link-item'"
                @click="handleGoToPageConfig"
              >
                <a-icon type="edit" />
                <span>跳转至编辑页</span>
              </div>
              <!-- 弹出小程序码窗口 -->
              <div
                v-if="wxQRCode && defaultAva.wxQRCode"
                class="nav-link-item"
                @click="()=> showQRCode = !showQRCode"
              >
                <a-icon type="qrcode" />
                <span>用小程序访问</span>
              </div>
              <div
                v-if="hasPermission"
                :class="['nav-link-item', {'nav-link-item-active': $route.name === 'rbac.role-list'}]"
                @click="goToPermissionSetting"
              >
                <a-icon type="file-protect" />
                <span>应用权限</span>
              </div>
              <div
                v-if="isShowPermissionButton"
                class="nav-link-item"
                @click="openUserPermissionModal"
              >
                <a-icon type="user" />
                <span>我的权限</span>
              </div>
              <div
                v-if="isGroupAdmin && !isMockAsGeneralUser"
                class="nav-submenu-wrapper"
              >
                <div class="ant-popover-inner nav-submenu-content">
                  <div
                    class="nav-link-item"
                    @click="goSSORoute('sso.userApprove')"
                  >
                    <a-icon type="key-o" />
                    <span>新用户注册审批</span>
                  </div>
                </div>

                <div
                  class="nav-link-item"
                  @click="goSSORoute('sso')"
                >
                  <a-icon type="key-o" />
                  <span>应用组管理</span>
                </div>
              </div>

              <div
                v-if="!OPEN_RUNTIME_DEPLOY && isAdmin && !isMockLogin"
                class="nav-link-item"
                @click="mockUser"
              >
                <a-icon type="user" />
                <span>{{ isMockAsGeneralUser ? '恢复管理员角色' : '体验非管理员角色' }}</span>
              </div>
            </template>
          </div>
        </template>
        <template #logout>
          <div
            class="nav-link-item nav-link-item-logout"
            @click="handleLogout"
          >
            <a
              class="nav-logout"
              wj-eid="退出登录"
            >
              <Icon icon="logout" />
              <span>{{ isMockLogin ?'退出模拟登录':'退出登录' }}</span>
            </a>
          </div>
        </template>
      </nav-user>
    </div>
    <!-- QRCode -->
    <QRCode
      v-if="showQRCode"
      @close="()=> showQRCode = !showQRCode"
    />
  </a-layout-header>
</template>

<script>
import { mapState } from 'vuex';
import HmrPageList from './HmrPageList.vue';
import Pending from '@components/nav/Pending.vue';
import NavLink from '@components/nav/NavLink';
import NavLinkItem from '@components/nav/NavLinkItem';
import NavUser from '@components/nav/NavUser';
import NavWidget from '@components/nav-widget/NavWidget.vue';
import NavEnvSelect from '@components/nav/NavEnvSelect';
import NavComps from '@components/nav/NavComps';
import NavMenuGroup from '@components/nav/NavMenuGroup';
import Icon from '@components/icon/Icon.vue';
import Notification from '@components/notification/Notification.tsx';
import QRCode from '@miniprogram/qr-code/index.vue';
import { OPEN_WUJI_NAVIGATOR, clientUrl, getProjectDefaultLogo, OPEN_RUNTIME_DEPLOY } from '@config/constant';
import openUserPermissionModal from './userPermissionModal';

import { getLogoutURL, getUserId, mockUserSwitch, isMockAsGeneralUser } from '@utils/userInfo';
import {
  getAppParams,
  goToPageConfig,
  isBaseBranchAppPath,
} from '@utils/path';
import { permissionApi as services, workflowEntityApi } from '@/services';
import pageLoader from '@loaders/page/loader';
import { getDefaultAva } from '@loaders/project/utils';
import useLayout from '@composables/useLayout';
import AnncmntDrawer from '@components/announcement/AnncmntDrawer';
import AnncmntModal from '@components/announcement/AnncmntModal';
import anncmntLoop from './anncmntLoop';
import useMockLoginRuntimeApi from '@composables/mockLogin/useMockLoginRuntimeApi';


export default {
  name: 'Nav',
  components: {
    NavLink,
    NavWidget,
    HmrPageList,
    NavUser,
    NavEnvSelect,
    Pending,
    NavLinkItem,
    NavComps,
    NavMenuGroup,
    Icon,
    Notification,
    QRCode,
    AnncmntDrawer,
    AnncmntModal,
    anncmntLoop,
  },
  inject: {
    ctx: {
      from: 'ctx',
      default: null,
    },
  },
  props: {
    mode: {
      type: String,
      default: 'runtime',
    },
    // 是否禁用点击
    disabled: {
      type: Boolean,
      default: false,
    },
    customNavStyle: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const {
      project,
      navTheme,
    } = useLayout();

    const { mockLogout } = useMockLoginRuntimeApi();

    return {
      project,
      navTheme,
      mockLogout,
    };
  },
  data() {
    return {
      isWujiNavigatorLoad: false,
      OPEN_RUNTIME_DEPLOY,
      clientUrl,
      groupId: '',
      isGroupAdmin: false,
      isHmrDebug: false,
      hasPermission: false,
      taskNumber: 0,
      isMockAsGeneralUser: isMockAsGeneralUser(),
      showQRCode: false,
      wxQRCode: window.GLOBAL_INFO.wxQRCode,
      dataAdminName: window.GLOBAL_INFO.brandInfo?.dataAdminName || '无极 1.0',
      anncmntDrawerVisible: false,
    };
  },
  computed: {
    ...mapState({
      runtimeMode: state => state.runtime.mode,
      currentEnv: state => state.app.env,
      currentPage: state => state.runtime.currentPage,
      branch: state => state.app.branch,
    }),
    uiInfo() {
      return this.project.uiInfo || {};
    },
    isShowGoToPageConfig() {
      if (OPEN_RUNTIME_DEPLOY) return false;  // open部署产物端，始终不显示这个
      if (!this.defaultAva.pageConfig) return false;

      const isConfig = this.runtimeMode !== 'runtime';
      return isConfig || (this.currentPage && !!this.userEditorRole);
    },
    avatars() {
      return (this.uiInfo?.navlink?.avatar || []).filter(item => !!item.isShow);
    },
    userEditorRole() {
      const { editorRole = '' } = window?.w?.user || {};
      return editorRole;
    },
    defaultAva() {
      const defAvaArray = this.uiInfo?.navlink?.defaultAva || getDefaultAva();
      const defAvaObject = {};
      defAvaArray.forEach(ava => defAvaObject[ava.id] = ava.isShow);
      return defAvaObject;
    },
    widgets() {
      let widgets = [];
      if (this.uiInfo.navlink) {
        const { navlink } = this.uiInfo;
        widgets = (navlink.widgets ?? []).filter(w => !!w.type && w.config?.queryKey);
      }
      return widgets;
    },
    hasWidgets() {
      return Boolean(this.widgets.length);
    },
    projectId() {
      return this.project.id;
    },
    dynamicComps() {
      return this.$refs?.['dynamic-comps']?.$refs?.comps ?? [];
    },
    env() {
      const params = getAppParams() || {};
      return params?.env ?? 'dev';
    },
    user() {
      return getUserId();
    },
    isAdmin() {
      return this.ctx?.user?.isAdmin;
    },
    homePageUrl() {
      const { origin } = location;
      const { href = '/' } = this.$router.resolve({ path: '/' }) ?? {};
      return origin + href;
    },
    logoutURL() {
      return getLogoutURL(this.homePageUrl);
    },
    externalPermissionLink() {
      const { advanceConfig } = this.project;
      const permissionLink = advanceConfig?.permissionLink;
      return permissionLink;
    },
    projectLogo() {
      return this.project?.logo || getProjectDefaultLogo(this.projectId);
    },
    // 外显的环境
    envShowList() {
      const envConfig = this.project?.uiInfo?.navlink?.defaultNav.find(item => item.id === 'env');
      return envConfig?.showList ?? ['*'];
    },
    envRedirect() {
      const envConfig = this.project?.uiInfo?.navlink?.defaultNav.find(item => item.id === 'env');
      return envConfig?.redirect ?? 'same';
    },
    isShowWujiNavigator() {
      return OPEN_WUJI_NAVIGATOR && this.wujiNavigator.isShow && this.isWujiNavigatorLoad;
    },
    wujiNavigator() {
      return this.project?.uiInfo?.navlink?.wujiNavigator ?? { isShow: true };
    },
    carouselStyle() {
      return this.project.advanceConfig?.anncmntConfig?.carouselStyle;
    },
    isNavDark() {
      return this.uiInfo?.navStyle?.nav;
    },
    isMockLogin() {
      return !!window.GLOBAL_INFO.mockedBy;
    },
    isShowPermissionButton() {
      return this.project?.uiInfo?.navlink?.showPermissionButton;
    },
  },
  async created() {
    this.isHmrDebug = pageLoader.isDebug();
    if (!!window.RUNTIME_CONFIGS && this.defaultAva.pending) {
      this.getPendingTaskNumber();
    }
    this.$store.commit('layout/setNav', this);
  },
  async created() {
    if (OPEN_WUJI_NAVIGATOR) {
      await import('@tencent/wuji-component-navigator');
      this.isWujiNavigatorLoad = true;
    }
  },
  async mounted() {
    if (this.mode === 'runtime' && !this.disabled) {
      this.hasPermission = await this.checkPermission();
    }

    const { projectId, env } = this;
    const { groupId, isAdmin: isGroupAdmin } = await this.$store.dispatch('runtime/getGroupInfo', { projectId, env });
    this.isGroupAdmin = isGroupAdmin;
    this.groupId = groupId;
  },
  beforeDestroy() {
    this.$store.commit('layout/removeNav', this);
  },
  methods: {
    onClickLogo() {
      if (this.disabled || this.mode !== 'runtime') return;
      this.$router.push({ path: '/' });
    },
    visible(id) {
      const { navlink } = this.project.uiInfo || {};
      if (navlink && navlink.defaultNav) {
        return navlink.defaultNav.find(item => item.id === id)?.isShow;
      }
      return id !== 'env';
    },
    handleGoToPageConfig() {
      if (this.disabled || !this.currentPage) return;
      const query = { pageid: this.currentPage.pageId, projectid: this.currentPage.projectId };
      // 不能用branch === master判断，因为开多分支后，即使是master也需要带branch=master的query参数
      if (isBaseBranchAppPath()) query.branch = this.branch;
      goToPageConfig(query);
    },
    goToPermissionSetting() {
      if (this.externalPermissionLink) {
        window.open(this.externalPermissionLink);
        return;
      }

      if (this.disabled) return;
      if (this.$route.name === 'rbac.role-list') return;
      const router = this.$router.resolve({ name: 'rbac.role-list' });
      window.open(router.href, '_blank');
    },
    goSSORoute(name) {
      const router = this.$router.resolve({ name });
      window.open(router.href, '_blank');
    },
    mockUser() {
      if (this.isAdmin) mockUserSwitch();
      window.location.reload();
    },
    async checkPermission() {
      if (this.externalPermissionLink) {
        return true;
      }

      let has = false;
      try {
        has = await services.checkPermission(this.projectId, this.env, 'read.permission');
      } catch {
        // ignore
      }
      return has;
    },
    setupRefreshTask() {
      // every 30s to refresh
      setTimeout(() => this.getPendingTaskNumber(), 30000);
    },
    async getPendingTaskNumber() {
      if (!this.env) {
        return;
      }
      const resp = await workflowEntityApi.fetchEntities(this.projectId, this.env, 'pending', { count: 1 });
      this.taskNumber = resp?.total || 0;
      this.setupRefreshTask();
    },
    async handleLogout() {
      if (this.isMockLogin) {
        await this.mockLogout();
        location.reload();
      } else {
        window.location.href = this.disabled ? 'javascript:void(0);' : this.logoutURL;
      }
    },
    showAnncmntDrawer() {
      this.anncmntDrawerVisible = !this.anncmntDrawerVisible;
      if (this.anncmntDrawerVisible) this.$refs?.anncmntDrawer?.drawerScrollToTop();
    },
    handleAnncmntLoopClick(id) {
      this.anncmntDrawerVisible = true;
      this.$refs?.anncmntDrawer?.highLightAnncmntById(id);
    },
    openUserPermissionModal() {
      openUserPermissionModal(this.projectId, this.env, this.groupId);
    },
  },
};
</script>
<style lang="scss" >
:root {
  --xy-header-height: 56px;
}

/*
  导航下拉框的统一配置 包括环境选择下拉框、 公共查询参数、 右侧快捷入口下拉框
*/
@import './popover-styles.scss';
</style>

<style lang="scss" scoped>
.header {
  z-index: 2;
  padding: var(--xy-custom-nav-padding);
  height: var(--xy-header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--xy-custom-nav-box-shadow);
  background-color: var(--xy-custom-nav-bg-color);
  font-size: var(--xy-custom-nav-font-size);
  color: var(--xy-custom-nav-color);
  .ant-menu {
    background: transparent;
  }

  .wuji-navigator {
    margin: var(--xy-custom-nav-wuji-navigator-margin);
  }
}

.nav-submenu-wrapper {
  position: relative;
}

.nav-submenu-content {
  position: absolute;
  right: 100%;
  top: 0;
  background: #fff;
  width: 180px;
  margin-right: 4px;
  border-radius: 0;
  transition: all 0.1s;
  overflow: hidden;
  max-height: 10px;
  opacity: 0;

  .nav-submenu-wrapper:hover & {
    overflow: hidden;
    max-height: 240px;
    opacity: 1;
  }
}

.nav-left{
  display: flex;
  align-items: center;
}

.nav-logo {
  padding: var(--xy-custom-nav-logo-container-padding);
  display: flex;
  align-items: center;
  gap: var(--xy-custom-nav-logo-container-gap);
  .img {
    max-height: var(--xy-custom-nav-logo-max-height);
    transform: translateZ(0);
  }
  .title {
    font-size:var( --xy-custom-nav-project-name-font-size);
    color: var(--xy-custom-nav-project-name-color);
  }
}

.flex-container {
  display: flex;
  align-items: center;
}

.nav-user-quick {
  display: flex;
  align-items: center;
  height: 32px;

  .nav-logout {
    font-size: 12px;
    color: #161616;
  }
}
.nav-link-item {
  padding: 10px 12px;
  margin: 0;
  cursor: pointer;
  &:hover {
    background: var(--wuji-primary-bg-color);
  }
  &-logout {
    border-top: 1px solid var(--wuji-primary-bg-color);
  }
}
</style>
