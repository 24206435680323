import wujiFetch from '@utils/wujiFetch';
import { BASE_API_PATH } from '@/config/constant';
import { parse } from 'query-string';
import { getUserInfo } from '@/utils/userInfo';

// 本地缓存结果
const staffRequestCache = new Map();

/**
 * 逐个的获取用户信息，如果获取失败则为 null / undefined。 这个方法总是会返回数组，即使出错也是
 *
 * @param {string | string[]} rtx 多个rtx用逗号隔开
 * @returns {Promise<any[]>}
 */
export const fetchStaffs = async (rtx) => {
  const key = encodeURIComponent(String(rtx));
  if (staffRequestCache.has(key)) return await staffRequestCache.get(key);

  const headers = {};

  if ((window?.GLOBAL_INFO?.mode === 'design')) {
    const query = parse(window.location.search.slice(1)); // get(window, 'globalContext.router.app.$route.query', null);

    let val;
    val = query.projectid || query.projectId; if (val) headers['xy-project-id'] = val;
    val = query.pageid || query.pageId; if (val) headers['xy-page-id'] = val;
  }

  const userInfo = getUserInfo();
  if (userInfo.corpId) {
    headers['xy-beacon-corp-id'] = userInfo.corpId;
  }

  const request = wujiFetch(`${BASE_API_PATH}/dept-rtx/staffs?rtx=${key}`, { headers }).catch(() => []);
  staffRequestCache.set(key, request);

  const res = await request;
  return res;
};

export default {
  fetchStaffInfo: fetchStaffs,
};
