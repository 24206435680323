<template>
  <div class="wf-wrapper">
    <aside class="aside">
      <!-- <sidebar /> -->
    </aside>
    <main class="main">
      <router-view />
    </main>
  </div>
</template>

<script>
// import Sidebar from './Sidebar';

export default {
  components: {
    // Sidebar,
  },
};
</script>

<style lang="scss" scoped>
.wf-wrapper {
  display: flex;
}

.aside {
  height: calc(100vh - var(--xy-header-height));
}

.main {
  width: 100%;
}
</style>
