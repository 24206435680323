import { defineComponent, ref, inject, onMounted } from '@vue/composition-api';
import { usePageletEvent, EVENTS } from '@composables/usePageletEvent';
import { DEFAULT_LESSCODE } from '@components/CodeEditor/utils/codes';
import styles from './index.module.scss';
const XySidebarContainer = defineComponent({
    name: 'XySidebarContainer',
    setup(props, { emit }) {
        usePageletEvent({ emit });
        const w = inject('w', null);
        const refContainer = ref(null);
        const getDefaultSlot = () => {
            if (w?.mode === 'runtime')
                return null;
            return (<div class={styles.placeholder}>
        <span>请放置组件</span>
      </div>);
        };
        // 运行时宽度设置为 100%, 适配菜单的宽度拖拽功能
        if (w?.mode === 'runtime') {
            onMounted(() => {
                refContainer.value.style.width = '100%';
            });
        }
        return {
            refContainer,
            getDefaultSlot,
        };
    },
    render() {
        return (<div class={['xy-sidebar-container', styles.container]} ref="refContainer">
        {this.$slots.default || this.getDefaultSlot()}
      </div>);
    },
});
const componentId = 'xy-sidebar-container';
export const getDefaultSidebarConfig = (id) => ([
    {
        id,
        name: '菜单',
        layout: {
            type: componentId,
            props: {},
            children: [],
            style: {
                width: '209px',
                height: '100%',
                'background-color': 'rgba(255, 255, 255, 1)',
            },
        },
        xyType: 'pagelet',
        lessCode: DEFAULT_LESSCODE,
    },
]);
export default {
    component: XySidebarContainer,
    componentForDesign: XySidebarContainer,
    category: '组合',
    id: componentId,
    slots: [
        { id: 'default', selector: '.xy-sidebar-container' },
    ],
    events: EVENTS,
    display: 'block',
    visible: false,
    name: '菜单栏',
    propsSchema: {
        type: 'object',
        fields: [],
    },
    readme: '',
};
