import wujiFetch from '@utils/wujiFetch';
export class ProjectDnsService {
    async load(projectId) {
        const result = await wujiFetch(`/api/xy/datasource/project_dns/${projectId}`);
        return result;
    }
    async loadProjectEnvList(projectId) {
        const res = await wujiFetch(`/api/xy/env?projectid=${projectId}`);
        return res.map(item => ({
            env: item.env,
            name: item.name,
        }));
    }
    async loadCustomDnsPlugins(projectId) {
        const res = await wujiFetch(`/api/xy/datasource/dnstype?projectid=${projectId}`);
        return res;
    }
    async loadFrontEndDNS(projectId, envId) {
        const { hostEnvListFrontEnd = [] } = await this.load(projectId);
        let target = hostEnvListFrontEnd.find((item) => item.id === envId);
        if (!target?.host) {
            target = hostEnvListFrontEnd.find((item) => item.id === 'default');
        }
        return target ?? null;
    }
}
export default new ProjectDnsService();
