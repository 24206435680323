import Dayjs from 'dayjs';
import { localDraftStore, remoteDraftStore } from '@utils/drafts';

const validateLocalDraftId = (id) => {
  if (typeof id === 'string' && id.indexOf('local_') === 0) {
    return true;
  }
  // 旧的本地草稿id都是-1
  return id < 0;
};

// 二分法把这条local的数据插入
const insertLocalDraft = (drafts, localDraft) => {
  const { ctime } = localDraft;
  let upper = drafts.length - 1;
  let lower = 0;
  while (lower <= upper) {
    const mid = Math.floor((upper + lower) / 2);
    const midCtime = drafts[mid].ctime;
    const currentTime = typeof midCtime === 'number' ? midCtime : new Dayjs(midCtime).valueOf();
    if (currentTime < ctime) {
      upper = mid - 1;
    }
    if (currentTime > ctime) {
      lower = mid + 1;
    }
    if (currentTime === ctime) {
      upper = mid;
      lower = mid;
      break;
    }
  }
  drafts.splice(lower, 0, localDraft);
  return drafts;
};

export default {
  state: {
    draftInfos: [], // 草稿列表，只包括创建人，时间等信息，不包括pageConfig
    draftLoading: false,
  },
  actions: {
    // 获取单个草稿
    async getDraftConfig(_, { projectId, draftId }) {
      let draft;
      if (validateLocalDraftId(draftId)) {
        draft = await localDraftStore.getDraft(draftId);
      } else {
        draft = await remoteDraftStore.getDraft(draftId, projectId);
      }
      return draft;
    },
    // 加载草稿列表
    async fetchDrafts({ state, commit }, { projectId, pageId, materialId, refresh, opts, branch }) {
      // 如果不需要强制刷新，不要反复发起
      if (!refresh && state.draftInfos.length) {
        return;
      }

      try {
        commit('setDraftLoading', true);

        const loadDraftParams = {
          projectId,
          pageId,
          materialId,
          branch,
          range: opts?.range,
        };

        // 加载远程的草稿
        const remoteDrafts = await remoteDraftStore.loadDraft(loadDraftParams);
        // 加载本地的草稿
        const localDrafts = await localDraftStore.loadDraft(loadDraftParams);
        // 将本地的草稿插入到远程的草稿列表中
        localDrafts.forEach((localDraft) => {
          insertLocalDraft(remoteDrafts, localDraft);
        });
        commit('setDraftInfos', remoteDrafts);
        commit('setDraftLoading', false);
      } catch (error) {
        console.error(`获取修改记录失败${error}`);
      }
    },
  },
  mutations: {
    setDraftInfos(state, draftInfo) {
      state.draftInfos = draftInfo;
    },
    setDraftLoading(state, loading) {
      state.draftLoading = loading;
    },
  },
};
