import { loadScript } from '@utils/common';
import wujiComp from '@tencent/xy-compsloader';
import { fetchCompDBInfo } from '@utils/comps-loader';
import store from '@store/index';
import { loadScriptError, getRefreshUrl } from '@utils/loadScriptError';

const loaded = {
  pageDebugPromise: {},
};
const pages = {};

const throwError = (id, prefix) => {
  throw new Error(`${prefix} load page debug file "${id}" loading failed!`);
};

function loadAMDPage(id) {
  return new Promise((resolve) => {
    window.require([`w-page-${id}`], (page) => {
      resolve(page);
    });
  });
}

const loadModule = async ({ id, src }) => {
  const { pageDebugPromise } = loaded;
  if (!pageDebugPromise[id]) {
    pageDebugPromise[id] = loadScript(getRefreshUrl(src), false);
  }

  try {
    await pageDebugPromise[id];
  } catch (err) {
    loadScriptError(src);
    // 将错误抛出，让外层调用者可以捕获这个错误
    // 用于页面的加载失败上报
    throw Error('script loaded failed');
  }

  // 发起请求，加载组件列表
  fetchCompDBInfo({
    projectId: store.state?.app?.projectId,
    projectEnv: store.state?.app?.env,
  });
  // window[`w-page-${id}`]兼容旧版本，旧版本是一个function
  const compInfo = await wujiComp.getPageInfo([id]).catch(() => false) || await loadAMDPage(id);
  if (!compInfo) {
    throwError(id, 'load module');
  }
  pages[id] = typeof compInfo === 'function' ? compInfo() : compInfo;

  return pages[id].comp;
};

export default loadModule;
