import wujiFetch from '@utils/wujiFetch';
import { designPath } from '@config/constant';

// 路由
const bannerUrl = `${designPath}/banner`;
const recordUrl = `${bannerUrl}/record`;

const fetchBannerData = async pathname => await wujiFetch(`${bannerUrl}?pathname=${pathname}`);
const fetchBannerRecord = async id => await wujiFetch(`${recordUrl}/${id}?`);

export default {
  fetchBannerData,
  fetchBannerRecord,
};
