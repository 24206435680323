import WujiOmniInput from './WujiOmniInput';
// import fixedArrayTableModule from './fixedArrayTable';
import findXyComponentForField from '../findXyComponentForField';
import FormSpecialSourceSelector from './FormSpecialSourceSelector';
import FormPlaceholderMeta from './FormPlaceholder.meta';

/**
 * @type {import('../../../../../ui-core/types').ComponentMeta}
 */
const FormSpecialSourceSelectorMeta = {
  id: 'FormSpecialSourceSelector',
  visible: false,
  component: FormSpecialSourceSelector,
};

const excludeList = ['public-w-select-2v'];

/**
 * @type {import('../../../../../ui-core/types').ComponentMeta[]}
 */
const extraUICoreComponents = [
  FormPlaceholderMeta,
  {
    id: 'wujiOmniInput',
    visible: false,
    component: WujiOmniInput,
    bindings: [
      {
        id: '数据字段',
        main: true,
        required: true,
        prop: 'value',
        event: 'change',
        pathProp: 'path',
        schemaProp: 'schema',
      },
    ],
    props: [
      { id: 'readonly', type: 'boolean' },
    ],
    fitData({ dataSchema, dataStub, dataPath, uc }) {
      if (dataSchema.ui?.type !== 'wujiOmniInput' || !dataSchema.ui?.settings?.type) return null;

      const fieldInfo = dataSchema.ui.settings;
      const xy = findXyComponentForField(fieldInfo, {
        readonly: dataStub.readonly,
      });
      if (!xy) return;
      if (excludeList.includes(xy.type)) return;

      const component = uc.components[xy.type];
      if (!component) return ;   // 未注册的组件

      const bindingKey = component.meta.bindings[0]?.id;
      if (!bindingKey) return ;   // 未定义 bindings 的组件

      return {
        type: 'editor',
        name: component.meta.name,
        desc: '根据数据表结构，推荐用这个组件',
        icon: component.meta.icon,
        order: 9,
        layout: {
          type: xy.type,
          props: xy.props,
          bindings: {
            [bindingKey]: dataPath,
          },
        },
      };
    },
  },
  // fixedArrayTableModule,
  FormSpecialSourceSelectorMeta,
];

export default extraUICoreComponents;
