import { cloneDeep } from 'lodash';
import { useStorage } from '@vueuse/core';

const TAG_LIST_STORAGE_SWITCH_KEY = 'wuji:tag-list:switch';
const TAG_LIST_STORAGE_LIST_KEY = 'wuji:tag-list:list';

export const localTagListSwitch = useStorage(TAG_LIST_STORAGE_SWITCH_KEY, false);
export const localTagList = useStorage(TAG_LIST_STORAGE_LIST_KEY, []);

const setTagListStorageList = (list) => {
  if (localTagListSwitch.value) {
    localTagList.value = list;
  }
};

export const getTagListStorageList = () => {
  if (!localTagListSwitch.value) return [];
  return cloneDeep(localTagList.value);
};

export default {
  name: 'navigation',
  namespaced: true,
  state: {
    showMenu: false,
    breadcrumb: [],
    tagList: getTagListStorageList(),
  },
  getters: {
  },
  actions: {
    addTag({ commit, state }, tag) {
      const tagPathList = state.tagList.map(item => item.path);
      if (!tagPathList.includes(tag.path)) {
        commit('addTagM', tag);
      }
    },
    removeTag({ commit, state }, tag) {
      const findIndex = state.tagList.findIndex(item => item.path === tag.path);
      if (findIndex !== -1) {
        commit('removeTagM', findIndex);
      }
    },
    // 只保留index
    reserveTag({ commit }, index) {
      commit('reserveTagM', index);
    },
    // 清除所有标签
    cleanTag({ commit }) {
      commit('cleanTagM');
    },
    setBreadcrumb({ commit }, breadcrumb) {
      commit('setBreadcrumbM', breadcrumb);
    },
    setShowMenu({ commit }, showMenu) {
      commit('setShowMenuM', showMenu);
    },
  },
  mutations: {
    addTagM(state, tag) {
      state.tagList.push(tag);
      setTagListStorageList(state.tagList);
    },
    removeTagM(state, index) {
      state.tagList.splice(index, 1);
      setTagListStorageList(state.tagList);
    },
    reserveTagM(state, index) {
      state.tagList = [state.tagList[index]];
      setTagListStorageList(state.tagList);
    },
    cleanTagM(state) {
      state.tagList = [];
      setTagListStorageList(state.tagList);
    },
    setBreadcrumbM(state, breadcrumb) {
      state.breadcrumb = breadcrumb;
    },
    setShowMenuM(state, showMenu) {
      state.showMenu = showMenu;
    },
  },
};
