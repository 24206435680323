import wujiFetch from '@utils/wujiFetch';
import getXyManagePathPrefix from '@/utils/getXyManagePathPrefix';

const getApiPrefix = env => `${getXyManagePathPrefix({ envId: env, isRuntimeRoute: true })}/workflow`;

const fetchWorkflow = async (projectId, env, id) => {
  const apiPrefix = getApiPrefix(env);
  const result = wujiFetch(`${apiPrefix}/${id}?projectid=${projectId}`);
  return result;
};

const fetchWorkflows = async (projectId, env) => {
  const apiPrefix = getApiPrefix(env);
  const query = new URLSearchParams({
    projectid: projectId,
    size: 'total',
    sort: '_mtime',
    order: 'desc',
  });
  const result = wujiFetch(`${apiPrefix}?${query}`);
  return result;
};

const fetchWorkflowEntities = async (projectId, env, filter, { page = 1, size = 10, count = 0 }) => {
  console.warn('deprecated api, please use `fetchEntities` instead');
  const apiPrefix = getApiPrefix(env);
  const pagination = count ? { count } : { page, size };
  const query = new URLSearchParams({
    projectid: projectId,
    env,
    filter,
    sort: 'mtime',
    order: 'desc',
    ...pagination,
  });
  const result = wujiFetch(`${apiPrefix}/entity?${query}`);
  return result;
};

const fetchEntities = async (projectId, env, type, { filter = '', page = 1, size = 10, count = 0 } = {}) => {
  const apiPrefix = getApiPrefix(env);
  const pagination = count ? { count } : { page, size };
  if (size === 'total') delete pagination.page;
  const query = new URLSearchParams({
    projectid: projectId,
    filter,
    sort: 'mtime',
    order: 'desc',
    ...pagination,
  });
  const api = type === 'all' ? '/entity' : `/entity/list/${type}`;
  const result = wujiFetch(`${apiPrefix}${api}?${query}`);
  return result;
};

const fetchWorkflowEntity = async (projectId, env, id) => {
  const apiPrefix = getApiPrefix(env);
  const query = new URLSearchParams({
    projectid: projectId,
  });
  const result = wujiFetch(`${apiPrefix}/entity/${id}?${query}`);
  return result;
};

const fetchEntityRecords = async (projectId, env, id) => {
  const apiPrefix = getApiPrefix(env);
  const query = new URLSearchParams({
    projectid: projectId,
  });
  const result = wujiFetch(`${apiPrefix}/records/${id}?${query}`);
  return result;
};

const start = async (projectId, env, workflowId, data) => {
  const apiPrefix = getApiPrefix(env);
  const result = wujiFetch(`${apiPrefix}/start/${workflowId}?projectid=${projectId}`, {
    method: 'POST',
    body: JSON.stringify(data),
    timeout: 60000, // 可能存在较长时间的钩子执行
  });
  return result;
};

const assignHandlers = async (projectId, env, entityId, handlers) => {
  const apiPrefix = getApiPrefix(env);
  const url = `${apiPrefix}/entity/${entityId}/assign?projectid=${projectId}`;
  return wujiFetch(url, { method: 'PATCH', body: JSON.stringify({ handlers }), timeout: 3000 });
};

const restart = async (projectId, env, entityId, payload = {}) => {
  const apiPrefix = getApiPrefix(env);
  const result = wujiFetch(`${apiPrefix}/restart/${entityId}?projectid=${projectId}`, {
    method: 'POST',
    body: JSON.stringify({ payload }),
  });
  return result;
};

const retry = async (projectId, env, entityId, retryNodeId) => {
  const apiPrefix = getApiPrefix(env);
  const result = wujiFetch(`${apiPrefix}/retry/${entityId}?projectid=${projectId}`, {
    method: 'POST',
    body: JSON.stringify({ retryNodeId }),
  });
  return result;
};

const abort = async (projectId, env, entityId) => {
  const apiPrefix = getApiPrefix(env);
  const result = wujiFetch(`${apiPrefix}/abort/${entityId}?projectid=${projectId}`, {
    method: 'POST',
  });
  return result;
};

const transition = async (projectId, env, entityId, data) => {
  const apiPrefix = getApiPrefix(env);
  const result = wujiFetch(`${apiPrefix}/transition/${entityId}?projectid=${projectId}`, {
    method: 'POST',
    body: JSON.stringify(data),
    timeout: 60000,
  });
  return result;
};

export default {
  fetchWorkflow,
  fetchWorkflows,
  fetchWorkflowEntities,
  fetchEntities,
  fetchWorkflowEntity,
  fetchEntityRecords,
  start,
  transition,
  restart,
  retry,
  abort,
  assignHandlers,
};
