<template>
  <a-breadcrumb
    v-if="showMenu && breadcrumb.length"
    class="breadcrumb-contain"
    separator=">"
  >
    <a-breadcrumb-item
      v-for="item in breadcrumb"
      :key="item"
    >
      {{ item }}
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      breadcrumb: state => state.navigation.breadcrumb,
      showMenu: state => state.navigation.showMenu,
    }),
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb-contain {
  padding: 0 20px;
  margin: 15px 0;
}
</style>
