export const WUJIE_EVENT_NAME = {
    runtimePageLoaded: 'runtimePageLoaded',
    approvalSubmit: 'approvalSubmit',
    approvalError: 'approvalError', // 流程-审批页面片-提交审批事件,出错时触发
};
export const wujieEventEmit = (eventName, data) => {
    if (window.$wujie?.bus) {
        window.$wujie?.bus.$emit(eventName, data);
    }
};
