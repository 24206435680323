import { defineComponent } from '@vue/composition-api';
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
/**
 * 数据源的表单输入
 *
 * 用户给在线组件提供数据源时，需要输入一个数据源id，
 * 在线组件通过这个id在组件内部生成数据源实例，传给对应的renderer
 */
export default defineComponent({
    name: 'DataFormItem',
    mixins: [vueComponentMixin],
    computed: {
        projectId() {
            return this.$route.query?.projectid;
        },
    },
    methods: {
        async handleInputClick() {
            const { openCollectionSelectModal } = await import('@tencent/wuji-source');
            const res = await openCollectionSelectModal({
                projectId: this.projectId,
                selectTarget: 'id',
            });
            if (res.action === 'ok') {
                this.$emit('change', res.collection);
            }
        },
    },
    render() {
        return <div>
      <a-input value={this.editValue} onClick={this.handleInputClick}></a-input>
    </div>;
    },
});
