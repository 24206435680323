import { defineComponent } from '@vue/composition-api';
import modalFactory from '@/utils/modalFactory';
import styles from './styles.module.scss';
const modal = defineComponent({
    name: 'DebugInfoModal',
    props: {
        options: {
            type: Object,
            required: true,
        },
    },
    methods: {
        handleOk() {
            this.$emit('message', {
                success: true,
            });
        },
        handleCancel() {
            this.$emit('message', {
                success: false,
            });
        },
    },
    render() {
        return <a-modal visible={true} onOk={this.handleOk} onCancel={this.handleCancel} ok-text="不再提示" cancel-text="关闭">
      <div class={styles.container}>
        <p class={styles.title}>请在打开的页面中使用组件<span>{this.options.componentId}</span>，查看调试结果</p>
        <p class={styles.tip}>在本编辑器中，点击保存按钮（或者按下ctrl/cmd + s），会触发调试页面的更新</p>
        <p class={styles.warning}>请注意：不要刷新调试页面，不然会导致热更新失效。</p>
      </div>
    </a-modal>;
    },
});
const openComponentInfoModal = modalFactory(modal);
export default openComponentInfoModal;
