const customUUID = () => Date.now().toString(36);
/**
 * 异步的mockCase管理接口
 * 预计实现localstorage储存和数据库储存
 * */
class MockCaseManager {
    projectId;
    pageId;
}
/**
 * localStorage中的MockCase按照
 * key=wuji_mock_${projectId}_${pageId}
 * value=Array<MockCase>
 * 进行储存
 */
class MockCaseLocalStorageImpl extends MockCaseManager {
    localStorage;
    constructor() {
        super();
        this.localStorage = localStorage;
    }
    setWorkPage({ projectId, pageId }) {
        this.projectId = projectId;
        this.pageId = pageId;
    }
    list() {
        const pageMockCaseString = this.localStorage.getItem(this.generateLocalStoragePath()) || '[]';
        let pageMockCase = JSON.parse(pageMockCaseString);
        if (!Array.isArray(pageMockCase))
            pageMockCase = [];
        return pageMockCase;
    }
    get(uuid) {
        const pageMockCase = this.list();
        return pageMockCase.find((o) => o.uuid === uuid);
    }
    create(mockData) {
        const uuid = customUUID();
        return this.update(mockData, uuid);
    }
    async update(mockData, uuid) {
        const pageMockCase = this.list();
        const newMockCase = pageMockCase.filter((o) => o.uuid !== uuid);
        const newMockData = { ...mockData, uuid };
        newMockCase.push(newMockData);
        this.updateAll(newMockCase);
        return newMockData;
    }
    delete(uuid) {
        const pageMockCase = this.list();
        const newMockCase = pageMockCase.filter((o) => o.uuid !== uuid);
        this.updateAll(newMockCase);
        return Promise.resolve(true);
    }
    async hasMockData() {
        const pageMockCaseString = this.localStorage.getItem(this.generateLocalStoragePath());
        return !!pageMockCaseString;
    }
    generateLocalStoragePath() {
        return `wuji_mock_${this.projectId}_${this.pageId}`;
    }
    updateAll(pageMockCase) {
        this.localStorage.setItem(this.generateLocalStoragePath(), JSON.stringify(pageMockCase));
    }
}
export { MockCaseLocalStorageImpl, MockCaseManager };
