export class FlowNodeSpecialBase {
    nodeId;
    flow;
    node;
    isDesignMode;
    constructor(init) {
        this.flow = init.flow;
        this.nodeId = init.nodeId;
        this.isDesignMode = !!init.isDesignMode;
        this.node = this.flow.flowGraph.nodes[this.nodeId];
    }
}
;
