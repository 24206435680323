import { isPlainObject } from 'lodash';
import { setRelatedEntityList } from '@utils/global/api';
import {
  XyWorkflowSelectorMeta,
  XyFlowSelectorMeta,
  XyFlowApproveDataEditorMeta,
} from '../paramsFormComponents';
import workflow from './workflow';
import { workflowEntityApi } from '@/services';
import { CATEGORY } from './const';
import { message } from 'ant-design-vue';
import { ENABLE_FLOWENGINE } from '@config/constant';

const jsonParse = (jsonOrObj, name) => {
  if (!jsonOrObj) return null;
  if (isPlainObject(jsonOrObj)) return jsonOrObj;
  if (Array.isArray(jsonOrObj)) return { ...jsonOrObj };
  let result = null;
  if (typeof jsonOrObj === 'string') {
    try {
      result = JSON.parse(jsonOrObj);
    } catch (error) {
      const msg = `${name}应为有效的 json 字符串或普通对象`;
      throw new Error(msg);
    }
  }
  if (result && !isPlainObject(result)) {
    const msg = `${name}应是普通对象`;
    throw new Error(msg);
  }
  return result;
};

// 获取流程单
export const workflowInitEntityAtom = {
  id: 'xyEvent:workflow:init-entity',
  name: '获取审批单',
  desc: '后续可以通过 `w.workflow.relatedEntityList` 来使用；注意只获取`与当前用户相关`的',
  category: CATEGORY.WORKFLOW,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'type',
        title: '类型',
        type: 'string',
        default: 'byConfig',
        enum: [
          { value: 'byConfig', label: '根据审批流配置获取' },
          { value: 'byEntityId', label: '通过审批单ID获取' },
        ],
      },
      {
        id: 'entityId',
        type: 'string',
        title: '审批单ID',
        default: '{{ w.query.entityid }}',
        condition: 'params.type === "byEntityId"',
      },
      {
        id: 'workflowId',
        type: 'string',
        title: '审批流配置',
        ui: { type: 'XyWorkflowSelector' },
        condition: 'params.type === "byConfig"',
      },
      { id: 'onlyProcessing', type: 'boolean', title: '只获取进行中的审批单', default: true },
      {
        id: 'listType',
        enum: [
          { label: '与当前用户相关', value: 'related' },
          { label: '全部（需要当前用户有「查看所有审批单」的内置权限）', value: 'all' },
        ],
        title: '过滤类型',
        default: 'related',
      },
    ],
  },
  paramsFormComponents: [
    XyWorkflowSelectorMeta,
  ],
  async execute(ctx, params) {
    const { wContext: { config: { projectId }, env } } = ctx.renderer;
    const { type } = params;
    if (type === 'byEntityId') {
      if (!params.entityId) {
        throw new Error('审批单ID不能为空');
      }
      try {
        const resp = await workflowEntityApi.fetchWorkflowEntity(projectId, env, params.entityId);
        setRelatedEntityList(resp ? [resp] : []);
      } catch (error) {
        throw new Error('获取相关流程单失败');
      }
      return;
    }
    const { workflowId, onlyProcessing, listType } = params;
    if (!workflowId) {
      throw new Error('审批流配置ID不能为空');
    }
    const filter = [`flowId="${workflowId}"`];
    if (onlyProcessing) filter.push('flowStatus="processing"');
    try {
      const list = await workflowEntityApi.fetchEntities(projectId, env, listType || 'related', {
        filter: filter.join('&'),
        size: 'total',
      });
      setRelatedEntityList(list);
    } catch (error) {
      throw new Error('获取相关流程单失败');
    }
  },
};
// 发起流程
export const workflowStartV2Atom = {
  id: 'xy:source:workflowStartV2',
  name: '发起审批',
  category: CATEGORY.WORKFLOW,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'type',
        type: 'string',
        title: '流程类型',
        default: 'workflow',
        enum: [
          { label: '普通审批流', value: 'workflow' },
          ENABLE_FLOWENGINE && { label: '高级流程', value: 'flow' },
        ].filter(Boolean),
        condition: 'false',
      },
      {
        id: 'workflowId',
        type: 'string',
        title: '审批流配置',
        condition: 'params.type==="workflow"',
        ui: { type: 'XyWorkflowSelector' },
      },
      {
        id: 'flowId',
        type: 'string',
        title: '流程ID',
        condition: 'params.type==="flow"',
        ui: { type: 'XyFlowSelector' },
      },
      {
        id: 'approveData',
        type: 'string',
        title: '审批数据',
        description: 'json 串: `{"name":"wuji"}`, 或者 js 对象: `{{ flowState.resp }}`, 也可以混用 `{"name":"{{w.user.name}}"}`',
      },
      {
        id: 'extraData',
        type: 'string',
        title: '额外数据',
        description: 'json 串: `{"name":"wuji"}`, 或者 js 对象: `{{ flowState.resp }}`, 也可以混用 `{"name":"{{w.user.name}}"}`',
      },
      {
        id: 'showSuccessTooltip',
        type: 'boolean',
        title: '发起审批成功提示',
        default: true,
      },
      {
        id: 'autoOpenDetail',
        type: 'boolean',
        title: '自动打开审批单详情页',
        default: false,
      },
    ],
  },
  returnValue: '审批单数据对象',
  paramsFormComponents: [
    XyWorkflowSelectorMeta,
    XyFlowSelectorMeta,
    XyFlowApproveDataEditorMeta,
  ],
  async execute(ctx, params) {
    const { workflowId, approveData, extraData, showSuccessTooltip, autoOpenDetail } = params;
    const data = jsonParse(approveData, '审批数据');
    if (!data) throw new Error('审批数据不能为空');
    const extra = jsonParse(extraData, '额外数据');
    // eslint-disable-next-line no-underscore-dangle
    if (extra) data._wuji_extra_data = extra;
    const { wContext: { config: { projectId }, env } } = ctx.renderer;
    const envId = window.RUNTIME_CONFIGS?.env ?? env;

    try {
      const result = await workflow.startWithId(projectId, envId, workflowId, data);

      // eslint-disable-next-line no-underscore-dangle
      const url = `${window.globalContext.router.options.base}/wuji/workflow/entity-detail?id=${result.objectId}&entityid=${result._id}`;
      result.url = url;

      if (showSuccessTooltip) message.success('成功发起流程');
      if (autoOpenDetail) window.open(url);
      return result;
    } catch (error) {
      const { index, flow } = ctx;
      const step = flow?.steps?.[index];
      const hasErrMsgTpl = step?.errMsgTpl;
      let err = error.message;
      if (error.message.includes('entity existed')) {
        err = '流程单已存在，不能重新发起';
      } else if (error.message.includes('审批人不能为空')) {
        err = '审批人为空，发起流程失败';
      }
      if (!hasErrMsgTpl) message.error(err);
      throw new Error(err);
    }
  },
};
// 重新发起流程
export const workflowRestartAtom = {
  id: 'xy:source:workflowRestart',
  name: '重新发起审批流',
  category: CATEGORY.WORKFLOW,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'entityId',
        type: 'string',
        title: '审批单ID',
      },
      {
        id: 'payload',
        type: 'string',
        title: '审批数据',
        description: 'json 串: `{"name":"wuji"}`, 或者 js 对象: `{{ flowState.resp }}`, 也可以混用 `{"name":"{{w.user.name}}"}`',
      },
    ],
  },
  returnValue: '审批单数据',
  paramsFormComponents: [
  ],
  async execute(ctx, params) {
    const { entityId, payload } = params;
    if (!entityId) {
      const msg = '审批单ID不能为空';
      throw new Error(msg);
    }
    if (typeof payload !== 'object') {
      const msg = '审批数据的类型只能是对象';
      throw new Error(msg);
    }
    const { wContext: { config: { projectId }, env } } = ctx.renderer;
    const result = await workflowEntityApi.restart(projectId, env, entityId, payload || {});
    message.success('重新发起流程成功');
    return result;
  },
};
// 流程审批操作
export const approvalV3Atom = {
  id: 'xy:source:approvalV3',
  name: '审批操作',
  category: CATEGORY.WORKFLOW,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'entityId',
        type: 'string',
        title: '审批单ID',
      },
      {
        id: 'operation',
        title: '审批动作',
        type: 'string',
        enum: [
          { label: '同意', value: 'pass' },
          { label: '驳回', value: 'reject' },
          { label: '撤销', value: 'abort' },
        ],
      },
      {
        id: 'status',
        type: 'string',
        title: '当前审批状态',
        condition: 'params.operation !== "abort"',
      },
      {
        id: 'payload',
        type: 'string',
        title: '数据',
        condition: 'params.operation !== "abort"',
        description: 'json 串: `{"name":"wuji"}`, 或者 js 对象: `{{ flowState.resp }}`, 也可以混用 `{"name":"{{w.user.name}}"}`',
      },
      {
        id: 'extraData',
        type: 'string',
        title: '额外数据',
        condition: 'params.operation !== "abort"',
        description: 'json 串: `{"name":"wuji"}`, 或者 js 对象: `{{ flowState.resp }}`, 也可以混用 `{"name":"{{w.user.name}}"}`',
      },
      {
        id: 'comment',
        type: 'string',
        title: '审批意见',
        condition: 'params.operation !== "abort"',
      },
    ],
  },
  returnValue: '审批单数据',
  paramsFormComponents: [
  ],
  async execute(ctx, params) {
    const { entityId, operation, comment, status, payload, extraData } = params;
    if (!entityId) {
      throw new Error('审批单ID不能为空');
    }
    const { wContext: { config: { projectId }, env, workflowEntity = {} } } = ctx.renderer;
    const envId = window.RUNTIME_CONFIGS?.env ?? env;
    const extra = jsonParse(extraData, '额外数据');
    if (['pass', 'reject'].includes(operation)) {
      const currentStatus = status || workflowEntity?.status;
      if (!currentStatus) {
        throw new Error('当前审批状态不能为空');
      }
      try {
        const result = await workflow.transitionWithId(
          projectId,
          envId,
          entityId,
          { operation, currentStatus, comment, payload, extra },
        );
        message.success('审批成功');
        return result;
      } catch (error) {
        throw new Error('审批失败');
      }
    }
    try {
      const result = await workflowEntityApi.abort(projectId, env, entityId);
      message.success('撤销成功');
      return result;
    } catch (error) {
      throw error;
    }
  },
};
// 重新指派审批人
export const workflowReassignHandlersAtom = {
  id: 'xy:source:workflowReassignHandlers',
  name: '重新指派审批人',
  category: CATEGORY.WORKFLOW,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'entityId',
        type: 'string',
        title: '审批单ID',
      },
      {
        id: 'handlers',
        type: 'string',
        title: '审批人 `user1,user2`',
      },
    ],
  },
  async execute(ctx, params) {
    const { entityId, handlers } = params;
    if (!entityId) {
      throw new Error('审批单ID不能为空');
    }
    if (!handlers) {
      throw new Error('审批人不能为空');
    }
    const { wContext: { config: { projectId }, env } } = ctx.renderer;
    try {
      const result = await workflowEntityApi.assignHandlers(projectId, env, entityId, handlers);
      message.success('指派成功');
      return result;
    } catch (error) {
      throw new Error(`指派失败${error.message}`);
    }
  },
};
// 流程审批操作
export const fetchWorkflowRecords = {
  id: 'xy:source:workflowRecords',
  name: '获取审批记录',
  category: CATEGORY.WORKFLOW,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'entityId',
        type: 'string',
        title: '审批单ID',
        default: '{{ w.query.entityid }}',
      },
      {
        id: 'targetId',
        type: 'string',
        title: '根据目标节点 id 过滤',
      },
    ],
  },
  returnValue: '审批记录列表',
  paramsFormComponents: [
  ],
  async execute(ctx, params) {
    const { entityId, targetId = '' } = params;
    const { wContext: { config: { projectId }, env } } = ctx.renderer;
    if (!entityId) throw new Error('审批单ID不能为空');
    const result = await workflowEntityApi.fetchEntityRecords(projectId, env, entityId);
    return result?.filter(rec => (targetId ? rec.to === targetId : true));
  },
};

export default [
  workflowInitEntityAtom,
  workflowStartV2Atom,
  workflowRestartAtom,
  approvalV3Atom,
  workflowReassignHandlersAtom,
  fetchWorkflowRecords,
].filter(Boolean);
