import { loadXLSX } from './loadUtilScripts';
/**
 * 字符串转字符流
 */
const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
};
/**
 * 下载文件
 */
export const downloadFile = (fileName, blob) => {
    const a = document.createElement('a');
    a.innerHTML = fileName;
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    document.body.appendChild(a);
    const evt = document.createEvent('MouseEvents');
    evt.initEvent('click', false, false);
    a.dispatchEvent(evt);
    document.body.removeChild(a);
};
export default async function exportExcel(fileName, list, fields) {
    const XLSX = await loadXLSX();
    const sheet = XLSX.utils.json_to_sheet(list, {
        ...(fields ? { header: fields } : {}),
    });
    const workbook = {
        SheetNames: ['Sheet1'],
        Sheets: {
            Sheet1: sheet,
        },
    };
    const wbOutput = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'binary',
    });
    const blobData = new Blob([s2ab(wbOutput)], {
        type: 'text/csv,charset=UTF-8',
    });
    downloadFile(`${fileName}.xlsx`, blobData);
}
