<template>
  <div style="flex:1">
    <!-- 数据源选择器 -->

    <div v-if="!renderer || !renderer.ucIsRoot">
      请通过组件库面板 - 数据源，寻找需要的字段
    </div>

    <template v-else>
      <section>
        <a-button @click="openSourceDialog">
          {{ sourceIds.length?'管理数据源':'添加数据源' }}
        </a-button>
      </section>

      <section v-if="sourceIds.length">
        <a-select
          style="width: 100%"
          value="从数据源导入字段..."
          @change="importFieldsFrom"
        >
          <a-select-option
            v-for="id in sourceIds"
            :key="id"
            :value="id"
          >
            {{ id }}
          </a-select-option>
        </a-select>
      </section>
    </template>
  </div>
</template>

<script>
import { vueDeepAssign, walkLayout } from '@tencent/ui-core/lib/utils';

export default {
  inject: {
    designer: { from: 'uicore:designer' },
  },
  computed: {
    renderer() {
      return this.designer.renderer;
    },
    sourceIds() {
      return Object.keys(this.renderer?.sources || {});
    },
  },
  methods: {
    async openSourceDialog() {
      this.renderer.openPageletSourcesEditor();
    },
    async importFieldsFrom(sourceId) {
      const { designer } = this;
      if (designer.activeComp?.children?.length) {
        const confirmed = await designer.uc.$confirm('当前你的表单里已经有些东西了，切换数据源会丢失原有内容。是否继续？');
        if (!confirmed) return;
      }

      const layout = designer.uc.fitData(sourceId).find(x => x.type === 'editor')?.layout;
      if (!layout) {
        designer.uc.$alert('无法导入此数据源，请通过左侧“数据源”自行拖拽制作表单');
        return;
      }

      await designer.replaceCompWith(designer.compPath, layout);
    },

    // 批量转化当前表单的子元素为只读 or 非只读
    // 现在新版本，可以将整个表单 readonly 设置为 true 并让子元素继承，所以这个似乎没啥用了
    // 留作纪念 + 参考代码
    async batchConvertReadonly(newReadonly) {
      const { designer } = this;
      const { uc } = designer;

      await designer.modifyActiveComp((comp) => {
        walkLayout(comp, (step) => {
          const compMeta = uc.components[step.layout.type].meta;
          if (!compMeta?.propsSchema?.fields?.some(x => x?.id === 'readonly' && x.type === 'boolean')) return;

          vueDeepAssign(step.layout, { props: { readonly: !!newReadonly } });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
section + section {
  margin-top: 12px;
}

.label {
  color: #333;
  font-size: 14px;
  line-height: 28px;
}
</style>
