import Atta from './Atta';
import { getElementParams, addPgEventListener, removePgEventListener } from './utils';
import delegate from '@utils/delegate';

const attaConfig = {
  id: '0e900059243',
  token: '9615749763',
};

const reportFrom = {
  'wj-eid': {
    eid: 'wj-eid',
    mod: 'wj-mod',
    submods: 'wj-submods',
    params: 'wj-params',
  },
  'data-ucd-report-eid': {
    eid: 'data-ucd-report-eid',
    mod: 'data-ucd-report-mod',
    submods: 'data-ucd-report-submods',
    params: 'data-ucd-report-params',
  },
};
const reportFromKeys = Object.keys(reportFrom);

/**
 * 获取 mod, submod1, submod2, submod3 等的值
 * @param {HTMLElement} element 元素
 * @param {string} eidKey 属于哪种类型的元素ID上报（可能是无极平台，或者是uicore的）
 */
const getNodeMods = function (element, eidKey) {
  const config = reportFrom[eidKey];
  const { $root, params } = getElementParams(element, config.submods, config.mod);

  return {
    ...params,
    // 主模块
    wmod: $root?.getAttribute?.(config.mod) || '',
  };
};

class DesignerReport {
  constructor(overrideDocument) {
    this.reporter = new Atta({
      attaId: attaConfig.id,
      token: attaConfig.token,
    });

    this.clickBinded = false;
    this.pgEventBinded = false;
    this.pvHandler = () => { };
    this.document = overrideDocument || document;
  }

  setIds(info) {
    if (!info) return this;

    const { projectId, pageId, projectName, parentId } = info;
    this.setProject({ projectId, projectName, parentId });
    this.setPage(pageId);
    return this;
  }

  setProject({ projectId, projectName, parentId }) {
    this.reporter.setProject({ projectId, projectName, parentId });
    return this;
  }

  setPage(pageId) {
    this.reporter.setPage(pageId);
    return this;
  }

  pv() {
    this.reporter.pv();

    if (!this.pgEventBinded) {
      this.pvHandler = addPgEventListener({
        pgIn: () => this.pv(),
        pgOut: () => this.pgOut(),
      });
      this.pgEventBinded = true;
    }

    return this;
  }

  pgOut() {
    this.reporter.pgOut();
    return this;
  }

  destroy() {
    removePgEventListener(this.pvHandler);
    return this;
  }

  bindClick() {
    if (this.clickBinded) return this;

    const { body } = this.document;
    reportFromKeys.forEach((fromKey) => {
      delegate(body, 'click', `[${fromKey}]`, (target) => {
        const mods = getNodeMods(target, fromKey);

        const config = reportFrom[fromKey];
        const { params } = getElementParams(target, config.params, config.mod);

        this.reporter.report('click', {
          eid: target.getAttribute(fromKey),
          ...mods,
          ...params,
        });
      });
    });

    this.clickBinded = true;

    return this;
  }

  report(action, data) {
    this.reporter.report(action, data);
  }
}

export default DesignerReport;
