/* eslint-disable */


let mixin = {};

// eslint-disable-next-line no-undef
const detect = typeof FinalizationRegistry !== 'undefined' && typeof WeakMap !== 'undefined';

if (detect) {
  const unmountedComponentWeakMap = new WeakMap();
  const unmountedComponentSet = new Set();

  // eslint-disable-next-line no-undef
  const registry = new FinalizationRegistry((value) => {
    unmountedComponentSet.delete(value.weakRef);
  });

  const watch = (instance) => {
    if (!unmountedComponentWeakMap.has(instance)) {
      unmountedComponentWeakMap.set(instance, instance._$memoryDetectKey);

      // eslint-disable-next-line no-undef
      const weakRef = new WeakRef(instance);
      unmountedComponentSet.add(weakRef);
      registry.register(instance, { weakRef, key: instance._$memoryDetectKey });
    }
  };

  const unWatch = (instance) => {
    for (const weakRef of unmountedComponentSet.keys()) {
      const ref = weakRef.deref();
      if (ref === instance) {
        unmountedComponentWeakMap.delete(instance);
        unmountedComponentSet.delete(weakRef);
        registry.unregister(instance);
        break;
      }
    }
  };

  const memoryLeakDump = () => {
    const locationMap = {};
    const allLogStrList = [['组件名', '泄漏次数', '文件名', '执行路径']];
    for (const weakRef of unmountedComponentSet.keys()) {
      const ref = weakRef.deref();
      if (!ref) continue;
      const value = unmountedComponentWeakMap.get(ref);
      try {
        const valueObj = JSON.parse(value);
        if (!locationMap[valueObj.location]) {
          locationMap[valueObj.location] = {
            obj: valueObj,
            count: 1,
          };
        } else {
          locationMap[valueObj.location].count += 1;
        }
      } catch (e) {

      }
    }
    Object.keys(locationMap)
      .forEach((key) => {
        allLogStrList.push([
          locationMap[key].obj.name,
          locationMap[key].count,
          locationMap[key].obj.file,
          locationMap[key].obj.location,
        ]);
      });
    return allLogStrList;
  };

  const getLeakComponentsCount = () => unmountedComponentSet.size;

  window.memoryLeakDump = memoryLeakDump;
  window.getLeakComponentsCount = getLeakComponentsCount;

  const getVmDetectKey = (vm) => {
    const name = vm.$options.name || vm.$options._componentTag || '(empty-name)';
    let location = name;
    let parent = vm;
    while ((parent = parent.$parent)) {
      location = `${parent.$options.name || vm.$options._componentTag || 'root'}-${location}`;
    }
    return JSON.stringify({
      name,
      location,
      file: vm.$options.__file,
      time: new Date().toLocaleString(),
    });
  };

  mixin = {

    data() {
      return {
        _$memoryDetectKey: undefined,
      };
    },
    created() {
      if (!this._$memoryDetectKey) {
        this._$memoryDetectKey = getVmDetectKey(this);
      }
    },
    mounted() {
      unWatch(this);
    },
    beforeDestroy() {
      watch(this);
    },

  };
}

export default mixin;
