export default {
  state: {
    showModalList: [],
    highlightKey: {
      path: '',
      key: '',
    },
  },
  mutations: {
    hideModal(state, { id }) {
      state.showModalList = state.showModalList.filter(item => item !== id);
    },
    showModal(state, { id }) {
      state.showModalList.push(id);
    },
    setHighlightKey(state, { path, key }) {
      state.highlightKey.path = path;
      state.highlightKey.key = key;
    },
    clearHighlightKey(state) {
      state.highlightKey.path = '';
      state.highlightKey.key = '';
      window.sessionStorage.removeItem('store_highlightKey');
    },
  },
};
