<template>
  <component
    :is="renderId"
    v-if="isReady"
  />
</template>

<script>
import { toRefs, onBeforeMount, ref } from '@vue/composition-api';
import { getCompsList } from '@/utils/comps-loader';

export default {
  name: 'CustomHeaderComp',
  props: {
    renderId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { renderId } = toRefs(props);
    const isReady = ref(false);

    onBeforeMount(async () => {
      if (!renderId.value) return;
      await getCompsList({
        compsKey: [renderId.value],
        register: true,
      });
      isReady.value = true;
    });

    return {
      isReady,
    };
  },
};
</script>
