<template>
  <div>
    <a-card
      title="权限申请"
      :bordered="false"
    >
      <apply-form
        ref="applyForm"
        :project-id="projectId"
        :env="env"
        :data="form"
      />
      <a-form-model>
        <a-form-model-item :wrapper-col="{ offset: 8 }">
          <a-button
            type="primary"
            :disabled="applying"
            :icon="applying ? 'loading' : ''"
            @click="apply"
          >
            申请
          </a-button>
          <a-button
            style="margin-left:12px"
            @click="back"
          >
            返回
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-card>
  </div>
</template>

<script>
import { getUserId } from '@utils/userInfo';
import ApplyForm from './ApplyForm.vue';
import { getAppParams } from '@utils/path';

export default {
  components: {
    ApplyForm,
  },
  data() {
    return {
      form: {
        user: getUserId(),
        env: '',
        pageId: '',
        bizId: '',
        permission: '',
        api: '',
        method: '',
        role: '',
        description: '',
      },
      roles: [],
      applying: false,
    };
  },
  computed: {
    urlParams() {
      return getAppParams() || {};
    },
    projectId() {
      return this.urlParams.projectId;
    },
    env() {
      return this.urlParams.env || 'dev';
    },
  },
  watch: {
    '$route.query.role': {
      handler(role) {
        if (role && role !== this.form.role) {
          this.form.role = role;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const { xy_runtime_project: projectInfo } = window;
      this.form.env = this.env;
      const { query } = this.$route;
      this.form.pageId = query.pageId || '';
      this.form.flowId = query.flowId || '';
      this.form.permission = query.permission || '';
      this.form.bizId = query[projectInfo.bizIsolation] || '';
      this.form.api = query.api || '';
      this.form.method = query.method || '';
    },
    async apply() {
      this.applying = true;
      const entity = await this.$refs.applyForm.apply();
      const result = Array.isArray(entity) ? entity : [entity];
      this.applying = false;
      if (result.length === 1) {
        const { _id: id, objectId } = result[0];
        this.$router.push({
          path: '/wuji/workflow/entity-detail',
          query: { id: objectId, entityid: id },
        });
      } else if (result.length > 1) {
        this.$router.push({
          path: '/wuji/workflow/entity-list',
          query: { menu: 'created' },
        });
      }
    },
    back() {
      this.$emit('back');
    },
  },

};
</script>

<style lang="scss" scoped>

</style>
