export default {
  type: 'object',
  fields: [
    {
      id: 'attr',
      title: '属性',
      type: 'string',
      'uc:allowExpr': false,
      'uc:allowReset': false,
      group: '属性',
      ui: { type: 'PageletAttr' },
    },
    { id: 'className', type: 'string' },
  ],
};
