<template>
  <div
    class="collapse-trigger"
    :class="{
      'collapsed': collapsed,
    }"
    :style="{ visibility: isShowCollapseTrigger ? 'visible' : 'hidden' }"
  >
    <div
      class="collapse-trigger-btn"
      :class="{
        'collapsed': collapsed,
      }"
      @click="$emit('toggle-collapsed')"
    >
      <svg
        width="16"
        height="56"
        viewBox="0 0 16 56"
      >
        <path
          class="collapse-btn-bg"
          d="M0,0 L14.59655,4.56142187 C15.4315004,4.82234387 16,5.59561189 16,6.47038183
            L16,49.5296182 C16,50.4043881 15.4315004,51.1776561 14.59655,51.4385781 L0,56 L0,56 L0,0 Z"
          fill="#000"
          fill-rule="evenodd"
          fill-opacity=".1"
        />
      </svg>
      <a-icon
        v-if="collapsed"
        class="trigger-icon trigger-icon-right"
        type="caret-right"
      />
      <a-icon
        v-else
        class="trigger-icon trigger-icon-left"
        type="caret-left"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapseTrigger',
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    isShowCollapseTrigger: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
$collapse-trigger-width: 16px;
.collapse-trigger{
  position: absolute;
  width: $collapse-trigger-width;
  height: 100%;
  top: 0;
  right: -16px;
  transform: translateX(1px);

  .collapse-trigger-btn{
    width: $collapse-trigger-width;
    height: 56px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform:  translate(-50%, -50%);
    cursor: pointer;
    &:after{
      content:'';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
    &:hover{
      .collapse-btn-bg {
        fill: var(--wuji-primary-color);
        fill-opacity: 1;
      }
      .trigger-icon-left{
        color: #fff;
      }
    }
    &.collapsed{
      .collapse-btn-bg{
        fill: var(--wuji-primary-color);
        fill-opacity: 1;
      }
    }
    .trigger-icon{
      width:100%;
      height:100%;
      position: absolute;
      top: 21px;
    }
    .trigger-icon-right{
      color: #fff;
    }
  }
}
</style>
