import { defineComponent, computed, shallowRef, ref, onMounted } from '@vue/composition-api';
import { BUILTIN_PAGE_ID } from '@config/constant';
import { workflowEntityApi } from '@/services';
import { APPLY_WORKFLOW_ID } from './constants';
import { searchVNode } from '@/components/globalSearch/util';
import { vueClassNameFilter } from '@/components/globalSearch/panelReflect';
import { isEmpty } from 'lodash';
export default defineComponent({
    name: 'ApplyCustomForm',
    model: {
        prop: 'formData',
        event: 'change',
    },
    props: {
        formData: {
            type: Object,
            default: () => { },
        },
        flowConfig: {
            type: Object,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { projectId = '', env = '' } = window.RUNTIME_CONFIGS ?? {};
        const projectConfig = shallowRef(window.xy_runtime_project);
        const workflow = ref(null);
        const uiCore = ref(null);
        const pageConfig = computed(() => {
            if (!projectId || !env)
                return null;
            if (isEmpty(workflow.value?.schema?.fields))
                return null;
            const pagelet = {
                id: 'default',
                name: 'mainPage',
                ucVersion: 4,
                xyType: 'pagelet',
                lessCode: '',
                layout: {
                    type: 'w-container',
                    props: {
                        minHeight: '300px',
                        background: 'white',
                    },
                    children: [
                        {
                            type: 'w-form',
                            props: {
                                layout: 'horizontal',
                                disabled: props.readonly,
                            },
                            children: [
                                {
                                    type: 'public-w-auto-form',
                                    props: {
                                        schemaType: 'customSchema',
                                        dataSchema: workflow.value?.schema,
                                        fillLine: true,
                                        // cardInForm: true,
                                    },
                                    componentId: 'publicWAutoForm',
                                    bindings: {
                                        dataSource: 'state.data',
                                    },
                                },
                            ],
                        },
                    ],
                },
                stateSchema: {
                    type: 'object',
                    fields: [
                        {
                            type: 'object',
                            'uc:stateType': 'normal',
                            default: props.formData,
                            id: 'data',
                        },
                    ],
                },
            };
            return {
                projectId,
                pageId: BUILTIN_PAGE_ID,
                title: '权限申请',
                pageData: {},
                pageConfig: [pagelet],
            };
        });
        onMounted(async () => {
            if (!isEmpty(props.flowConfig))
                workflow.value = props.flowConfig;
            else
                workflow.value = await workflowEntityApi.fetchWorkflow(projectId, env, `${projectId}:${APPLY_WORKFLOW_ID}`);
        });
        return {
            pageConfig,
            projectConfig,
            uiCore,
        };
    },
    watch: {
        'uiCore.state.data': {
            handler(newVal) {
                this.$emit('change', newVal);
            },
            deep: true,
        },
    },
    mounted() {
        const getUiCore = () => {
            if (!this.$refs.page)
                return null;
            const node = searchVNode(this.$refs.page, [vueClassNameFilter('UicoreCRenderer')]);
            if (!node)
                return setTimeout(getUiCore, 1000);
            this.uiCore = node;
        };
        setTimeout(getUiCore, 1000);
    },
    render() {
        this.$emit('updated', !!this.pageConfig);
        return <div>
      {this.pageConfig
                ? <w-sys-page ref="page" config={this.pageConfig} project-config={this.projectConfig} style="background-color: transparent"/>
                : null}
    </div>;
    },
});
