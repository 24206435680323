import { defineComponent } from '@vue/composition-api';
import styles from './styles/mergeModal.module.scss';
import { startMouseMove } from '@tencent/ui-core/lib/utils/browser';
import { vueLinkLocalStorage } from '@tencent/ui-core/lib/utils/vue';
const MergeSidebar = defineComponent({
    name: 'MergeSidebar',
    props: {
        conflicts: {
            type: Array,
            default() {
                return [];
            },
        },
        mergedPatches: {
            type: Array,
            default() {
                return [];
            },
        },
        selectedKeys: {
            type: Object,
            default() {
                return {
                    conflicts: [],
                    mergedPatches: [],
                };
            },
        },
        selectDetailChange: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    emits: ['change', 'select'],
    data() {
        return {
            width: 200,
            current: '',
            isCollapsed: {
                conflicts: false,
                mergedPatches: false,
            },
        };
    },
    methods: {
        handleSelect(type, key, ev) {
            const { checked } = ev.target;
            let data = this.selectedKeys[type];
            if (checked) {
                data.push(key);
            }
            else {
                data = data.filter((item) => item !== key);
            }
            this.$emit('select', type, data);
        },
        handleResizerPointerdown(ev) {
            const ow = this?.$refs?.sidebar?.offsetWidth;
            ev.preventDefault();
            startMouseMove({
                initialEvent: ev,
                onMove: ({ deltaX }) => void (this.width = Math.max(150, ow + deltaX)),
            });
        },
        handleClick(item) {
            this.current = item.key;
            this.$emit('change', item);
        },
        handleSelectAll(type, checked) {
            if (checked) {
                this.selectedKeys[type] = this[type].map(item => item.key);
            }
            else {
                this.selectedKeys[type] = [];
            }
        },
        isSelectedAll(type) {
            return this.selectedKeys[type].length === this[type].length;
        },
        collapse(type) {
            this.isCollapsed[type] = !this.isCollapsed[type];
        },
    },
    created() {
        vueLinkLocalStorage(this, 'MergeModalSidebar', 'width');
    },
    render() {
        return (<div class={styles.editor_wrap_left} ref="sidebar" style={{ flexBasis: `${this.width}px` }}>
        <div style="margin-bottom: 12px">
          {this.conflicts.length > 0 ? <div class={styles.conflict_title}>
            <a-icon onClick={() => this.collapse('conflicts')} type="caret-down" class={[styles.conflict_title_icon, this.isCollapsed.conflicts ? styles.collapse : null]}/>
            <span>
            <a-checkbox checked={this.isSelectedAll('conflicts')} style='margin-right: 4px' onChange={ev => this.handleSelectAll('conflicts', ev.target.checked)}/>待解决</span>
          </div> : null}
          {!this.isCollapsed.conflicts ? this.conflicts.map(item => (<div onClick={() => this.handleClick(item)} class={[styles.conflict_item,
                    this.selectDetailChange
                        && this.selectDetailChange.key === item.key ? styles.selected : null]}>
              <a-checkbox checked={this.selectedKeys.conflicts.includes(item.key)} class={styles.checkbox} onChange={ev => this.handleSelect('conflicts', item.key, ev)}/>
              <a-tag color="blue">
                {item.schemaTitle}
              </a-tag>
              <span>{item.title}</span>
            </div>)) : null}
        </div>
        <div>
          {this.mergedPatches.length > 0 ? <div class={styles.conflict_title}>
            <a-icon onClick={() => this.collapse('mergedPatches')} type="caret-down" class={[styles.conflict_title_icon, this.isCollapsed.mergedPatches ? styles.collapse : null]}/>
            <span>
            <a-checkbox checked={this.isSelectedAll('mergedPatches')} style='margin-right: 4px' onChange={ev => this.handleSelectAll('mergedPatches', ev.target.checked)}/>
              已合并</span>
          </div> : null}
          {!this.isCollapsed.mergedPatches ? this.mergedPatches.map(item => (<div onClick={() => this.handleClick(item)} class={[styles.conflict_item, this.selectDetailChange
                        && this.selectDetailChange.key === item.key ? styles.selected : null]}>
              <a-checkbox checked={this.selectedKeys.mergedPatches.includes(item.key)} class={styles.checkbox} onChange={ev => this.handleSelect('mergedPatches', item.key, ev)}/>
              {item.title !== item.schemaTitle
                    && <a-tag color="blue">
                {item.schemaTitle}
              </a-tag>}
              <span> {item.title}</span>
            </div>)) : null}
        </div>
        <div class={styles.sidebarResizer} onMousedown={this.handleResizerPointerdown}></div>
      </div>);
    },
});
export default MergeSidebar;
