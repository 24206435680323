<template>
  <page-layout
    :wrap-styles="{ padding: '24px' }"
    :styles="{ padding: '0px' }"
  >
    <div
      v-if="loading"
      style="padding: 20px; text-align: center"
    >
      <p><a-spin /></p>
      <p>加载中...</p>
    </div>

    <div
      v-else-if="loadingError"
      style="padding: 20px; text-align: center"
    >
      <p style="color: red">
        {{ String(loadingError) }}
      </p>
    </div>

    <w-sys-page
      v-else
      :config="pageConfig"
      :project-config="projectConfig"
      style="background-color: transparent"
    />
  </page-layout>
</template>

<script>
import { getAppParams } from '@utils/path';
import { BUILTIN_PAGE_ID } from '@config/constant';
import {
  computed,
  defineComponent,
  ref,
  shallowRef,
  watch,
} from '@vue/composition-api';
import { useRouter } from '@/router/useRouter';
import useJobService from '@/composables/flow/useJobService';
import useFlowService from '@/composables/flow/useFlowService';

export default defineComponent({
  setup() {
    const router = useRouter();
    const appParams = getAppParams();

    const { projectId, env: envId } = appParams;
    const flowId = computed(() => router.route.value.params.flowId || ''); // computed!!!!
    const jobId = computed(() => router.route.value.params.jobId || ''); // computed!!!!
    const loading = ref(true);

    const detailLayout = ref('');

    const projectConfig = shallowRef(window.xy_runtime_project);
    const pageConfig = computed(() => {
      const pagelet = {
        id: 'default',
        name: 'mainPage',
        ucVersion: 4,
        xyType: 'pagelet',
        lessCode: '',
        layout: {
          type: 'w-container',
          props: {},
          children: [
            {
              type: 'xy-page-container',
              props: {
                pageId: detailLayout.value || `!generated:${JSON.stringify({
                  type: 'flowDetailPage',
                  flowId: flowId.value,
                })}`,
                projectId,
              },
              events: {
                flowPluginUpdateTitle: {
                  steps: [
                    {
                      type: 'uicore:runScript',
                      params: {
                        script: 'state.pageTitle = $event',
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
        stateSchema: {
          type: 'object',
          fields: [
            {
              type: 'string',
              'uc:stateType': 'normal',
              default: '',
              id: 'pageTitle',
            },
          ],
        },
      };

      return {
        projectId,
        pageId: BUILTIN_PAGE_ID,
        title: '{{ state.pageTitle || "流程实例详情" }}',
        pageData: {},
        pageConfig: [pagelet],
      };
    });

    const loadingError = shallowRef(null);
    const pageletChunks = ref([]);

    const jobService = useJobService({ projectId, envId });
    const flowService = useFlowService({ projectId, envId });

    async function reload() {
      loading.value = true;
      loadingError.value = null;
      pageletChunks.value = ['start'];

      // 获取 detailLayout
      {
        let newDetailLayout = '';

        if (jobId.value) {
          const job = await jobService.getForRuntime(jobId.value);
          newDetailLayout = job.detailLayout || '';
        } else {
          const flow = await flowService.getForRuntime(flowId.value);
          newDetailLayout = flow.detailLayout || '';
        }

        if (newDetailLayout !== detailLayout.value) {
          detailLayout.value = newDetailLayout;
        }
      }

      await Promise.all([
        // loadFlowInfo(),
      ]).catch((error) => {
        loadingError.value = error;
      });

      loading.value = false;
    }

    watch(() => `${flowId.value}/${jobId.value}`, reload, { immediate: true });

    return {
      projectId,
      envId,
      flowId,
      jobId,

      loading,
      loadingError,

      projectConfig,
      pageConfig,
    };
  },
});
</script>

<style lang="scss" scoped></style>
