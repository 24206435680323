import wujiFetch from '@utils/wujiFetch';
import { getRuntimeEnvPath } from '@config/constant';

const getApiPrefix = env => `${getRuntimeEnvPath(env)}/notice/robot`;
const postRobotMessage = async (projectId, envId, robotId, message) => {
  const url = `${getApiPrefix(envId)}/${robotId}?projectid=${projectId}`;
  const result = wujiFetch(url, {
    method: 'POST',
    body: JSON.stringify(message),
  });
  return result;
};

export default {
  postRobotMessage,
};
