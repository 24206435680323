<template>
  <div
    class="xy-inner-pagelet-selector"
  >
    <a-select
      v-model="editValue"
      class="xy-inner-pagelet-selector-select"
      show-search
      filter-option
      :options="pageletOptions"
    />
  </div>
</template>
<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';

const component = {
  inject: {
    wContext: {
      from: 'wContext',
      default: null,
    },
  },
  name: 'XyInnerPageletSelector',
  mixins: [vueComponentMixin],
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };
  },
  computed: {
    pageletOptions() {
      if (Array.isArray(this.wContext?.config?.pageConfig)) {
        return this.wContext.config.pageConfig.filter(pagelet => pagelet.xyType === 'pagelet' && pagelet.id !== 'default').map(pagelet => ({
          value: `${pagelet.id}`,
          label: `${pagelet.name}`,
        }));
      }
      return [];
    },
  },
};

export default component;

const settingsSchema = null;
export const meta = {
  settingsSchema,
  component,
  hideLabel: false,
};
</script>

<style lang="scss" scope>
.xy-inner-pagelet-selector {
  width: 100%;
  .xy-inner-pagelet-selector-select {
    width: 100%;
  }
}
</style>
