import { postEmail, notifyByWework } from './notification';
import { robotApi } from '@/services';
import { isEmpty } from 'lodash';
import { message } from 'ant-design-vue';
import {
  XyRtxSelectorMeta,
  XyRobotSelectorMeta,
  XyWeworkMessageMeta,
  XyWxworkSelectorMeta,
  XyEmailSelectorMeta,
} from '../paramsFormComponents';
import { CATEGORY } from './const';
import stringInterop from '@tencent/ui-core/lib/utils/stringInterop';
import { ENABLE_EMAIL_SETTING } from '@/config/constant';

// 邮件通知
export const emailNotificationAtom = {
  id: 'xy:source:emailNotification',
  name: '邮件通知',
  category: CATEGORY.NOTIFICATION,
  paramsSchema: {
    type: 'object',
    fields: [
      ...(ENABLE_EMAIL_SETTING ? [
        {
          id: 'emailId',
          type: 'string',
          title: '发送人',
          default: '',
          ui: { type: 'XyEmailSelector' },
        },
      ] : []),
      {
        id: 'to',
        type: 'string',
        title: '收件人',
        default: '',
        ui: { type: 'XyRtxSelector' },
      },
      {
        id: 'cc',
        type: 'string',
        title: '抄送',
        default: '',
        ui: { type: 'XyRtxSelector' },
      },
      {
        id: 'bcc',
        type: 'string',
        title: '密送',
        default: '',
        ui: { type: 'XyRtxSelector' },
      },
      {
        id: 'title',
        type: 'string',
        title: '邮件主题',
        default: '',
      },
      {
        id: 'content',
        type: 'string',
        title: '邮件内容',
        default: '',
      },
    ],
  },
  paramsFormComponents: [
    XyRtxSelectorMeta,
    XyEmailSelectorMeta,
  ],
  async execute(ctx, params) {
    const { to, cc = '', bcc = '', title, content, emailId = '' } = params;
    if (!to || !title || !content) {
      throw new Error('收件人,主题或内容为空,请检查');
    }

    await postEmail(to, title, content, { cc, bcc, emailId });
  },
};
// 企微机器人通知
export const robotNotificationAtom = {
  id: 'xy:source:robotNotification',
  name: '企微机器人通知',
  category: CATEGORY.NOTIFICATION,
  desc: '消息格式详情请[点击查看](https://developer.work.weixin.qq.com/document/path/95274)',
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'robotId',
        type: 'string',
        title: '机器人',
        ui: { type: 'XyRobotSelector' },
      },
      {
        id: 'data',
        type: 'string',
        title: '消息',
        description: '',
        default: JSON.stringify({ msgtype: 'text', text: { content: 'hello {{w.user.userId}}' } }, null, 2),
      },
      {
        id: 'successNotice',
        type: 'boolean',
        title: '成功通知',
        default: true,
      },
    ],
  },
  async execute(ctx, params) {
    const { wContext: { config: { projectId }, env } } = ctx.renderer;
    const { robotId, successNotice } = params;
    if (!robotId || !params.data) {
      const msg = '机器人通知配置错误';
      throw new Error(msg);
    }
    let data = null;
    try {
      if (typeof params.data === 'string') data = JSON.parse(params.data);
      else data = params.data;
    } catch (error) {
      const msg = '机器人消息应为有效的 JSON';
      throw new Error(msg);
    }
    try {
      await robotApi.postRobotMessage(projectId, env, robotId, data);
      successNotice && message.success('机器人通知成功');
    } catch (error) {
      const msg = '机器人通知失败';
      throw new Error(msg);
    }
  },
  paramsFormComponents: [
    XyRobotSelectorMeta,
  ],
};
// 企业微信通知
export const weworkNotificationAtom = {
  id: 'xy:source:weworkNotification',
  name: '企业微信通知',
  category: CATEGORY.NOTIFICATION,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'weworkMsgConfig',
        type: 'object',
        title: '通知设置',
        'uc:allowInterop': true,
        ui: { type: 'XyWeworkMessage' },
        fields: [
          {
            id: 'weworkApp',
            title: '通知应用（消息发送方）',
            type: 'string',
            default: '',
          },
          {
            id: 'receiverType',
            title: '消息接收人类型',
            type: 'string',
            default: 'dynamic',
          },
          {
            id: 'receivers',
            title: '消息接收人',
            type: 'string',
            default: '',
          },
          {
            id: 'msgType',
            title: '消息类型',
            type: 'string',
            default: 'text',
          },
          {
            id: 'content',
            title: '内容',
            type: 'string',
            default: '',
          },
          {
            id: 'title',
            title: '标题',
            type: 'string',
            default: '',
          },
          {
            id: 'description',
            title: '内容',
            type: 'string',
            default: '',
          },
          {
            id: 'url',
            title: '链接',
            type: 'string',
            default: '',
          },
          {
            id: 'btnTxt',
            title: '按钮文本',
            type: 'string',
            default: '详情',
          },
        ],
      },
    ],
  },
  paramsFormComponents: [
    XyWeworkMessageMeta,
  ],
  async execute(ctx, params) {
    const {
      weworkApp,
      receivers, msgType, content, title, description, url, btnTxt } = params.weworkMsgConfig || {};
    if (isEmpty(receivers)) {
      throw new Error('企业微信通知-消息接收人不能为空');
    }
    if (msgType === 'text') {
      if (!content) {
        throw new Error('企业微信通知-文本内容不能为空');
      }
    } else if (msgType === 'markdown') {
      if (!content) {
        throw new Error('企业微信通知-Markdown内容不能为空');
      }
    } else {
      if (!title || !description || !url || !btnTxt) {
        throw new Error('企业微信通知-文本卡片消息配置有误');
      }
    }

    const receiverList = typeof receivers === 'string' ? receivers.split(/[ ,;]/g).filter(Boolean) : receivers;
    const data = { receivers: receiverList, msgType };
    data.data = ['text', 'markdown'].includes(msgType) ? { content } : { title, description, url, btntxt: btnTxt };
    const { wContext: { config: { projectId }, env } } = ctx.renderer;

    const builtin = weworkApp === 'builtin';
    await notifyByWework(projectId, env, data, builtin);
    message.success('企业微信通知成功');
  },
};
// 唤起企业微信
export const messageAtom = {
  id: 'xyEvent:wxwork:message',
  name: '唤起企业微信',
  category: CATEGORY.NOTIFICATION,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'messageConfig',
        title: '唤起配置',
        type: 'object',
        ui: { type: 'XyWxworkSelector' },
        fields: [
          {
            id: 'type',
            title: '类型',
            type: 'string',
            default: 'username',
          },
          {
            id: 'username',
            title: '企业微信名',
            type: 'string',
            default: '',
          },
          {
            id: 'usernameExpr',
            title: '企业微信名(动态)',
            type: 'string',
            default: '',
          },
          {
            id: 'uin',
            title: '客服号(uin)',
            type: 'string',
            default: '',
          },
        ],
      },
    ],
  },
  paramsFormComponents: [
    XyWxworkSelectorMeta,
  ],
  async execute(ctx, params) {
    const { type, username, usernameExpr, uin } = (params?.messageConfig ?? {});
    let typeValue;
    if (type === 'username') {
      typeValue = username;
      if (!username) {
        throw new Error('企业微信名不能为空');
      }
    }
    if (type === 'usernameExpr') {
      typeValue = stringInterop(usernameExpr, ctx.renderer);
      if (!typeValue) {
        throw new Error('企业微信名不能为空');
      }
    }
    if (type === 'uin') {
      typeValue = stringInterop(uin, ctx.renderer);
      if (!typeValue) {
        throw new Error('企业微信客服号不能为空');
      }
    }
    if (typeValue) {
      typeValue = String(typeValue).replace(/\s*[，；、,]\s*/g, ';')
        .trim();
      const openType = type === 'usernameExpr' ? 'username' : type;
      window.open(`wxwork://message.com/?${openType}=${typeValue}`, '_blank');
    };
  },
};

export default [
  emailNotificationAtom,
  robotNotificationAtom,
  weworkNotificationAtom,
  messageAtom,
];
