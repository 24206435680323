import { omit } from 'lodash';
import { wEmitter, EventName } from '@/utils/global/event-emitter';
/** 运行时修改页面标题 */
export default function usePageTitle() {
    const handleChangePageTitle = ({ page, title }) => {
        // 当用户监听了页面标题修改事件则不执行默认的修改操作
        const listeners = wEmitter.listeners(EventName.PAGE_TITLE_CHANGE);
        if (listeners?.length > 0) {
            const omitPageKeys = Object.keys(page).filter((key) => key.endsWith('Config'));
            wEmitter.emit(EventName.PAGE_TITLE_CHANGE, { page: omit(page, omitPageKeys), title });
        }
        else {
            document.title = title;
        }
    };
    return {
        handleChangePageTitle,
    };
}
