import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import getChanges from './utils/getChanges';

/**
 * 这个是数据源实例的基类，你要实现的东西，参考 README.md
 */
const SourceControllerBase = Vue.extend({
  inject: {
    wContext: {
      from: 'wContext',
      default: null,
    },
  },
  props: {
    config: { type: Object, required: true }, // 见 ../types.d.ts
    renderer: { type: Object, required: true }, // LessCode 上下文
    pluginInstance: { type: Object, required: true }, // 其实好像没啥用
  },
  data() {
    return {
      lastLoadedData: null,
      isReady: false,
    };
  },
  computed: {
    id() {
      return this.config.id;
    },
    uc() {
      return this.renderer.uc;
    },
  },
  created() {
    this.$on('load', () => {
      this.lastLoadedData = this.getClonedData();
    });
    this.$on('reset', () => {
      this.lastLoadedData = null;
    });
    this.$once('ready', () => {
      this.isReady = true;
    });
  },
  methods: {
    /** 返回一个 Promise，在数据源进入了 ready 的时候才 resolve */
    waitForReady() {
      if (this.isReady) return Promise.resolve(this);
      return new Promise(resolve => this.$once('ready', () => {
        resolve(this);
      }));
    },
    getClonedData() {
      return cloneDeep(this.data);
    },
    getChanges() {
      return getChanges(this.lastLoadedData, this.getClonedData());
    },
  },
});

export default SourceControllerBase;
