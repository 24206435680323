import Vue from 'vue';
import { defineComponent, ref, toRefs, onMounted, computed } from '@vue/composition-api';
import { message } from 'ant-design-vue';
import styles from './ModuleName.module.scss';
const ModuleName = defineComponent({
    name: 'ModuleName',
    props: {
        moduleNames: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { emit }) {
        const { moduleNames } = toRefs(props);
        const input = ref('');
        const elInput = ref(null);
        const isConfirm = ref(false);
        const handleKeyUp = (event) => {
            if (event.key === 'Enter') {
                handleConfirm();
            }
        };
        const isEmpty = computed(() => !input.value);
        const isFileName = computed(() => /^[a-zA-Z][\w-]*\.(js|ts)$/.test(input.value));
        const isExist = computed(() => moduleNames.value.includes(input.value));
        const handleConfirm = () => {
            if (isEmpty.value) {
                message.warning('模块名不能为空');
            }
            else if (!isFileName.value) {
                message.warning('模块名由字母、数字、下划线、中划线组成，且只能以字母开头和 .js / .ts 结束');
            }
            else if (isExist.value) {
                message.warning('该模块名已存在');
            }
            else {
                isConfirm.value = true;
                emit('confirm', input.value);
            }
        };
        const handleBlur = () => {
            if (isFileName.value && !isExist.value) {
                handleConfirm();
            }
            else {
                handleCancel();
            }
        };
        const handleCancel = () => {
            if (isConfirm.value)
                return;
            emit('cancel');
        };
        onMounted(() => {
            elInput.value?.focus();
        });
        return {
            input,
            elInput,
            handleKeyUp,
            handleConfirm,
            handleBlur,
            handleCancel,
        };
    },
    render() {
        return <div class={styles.prompt} on={{ mousedown: e => e.stopPropagation() }}>
      <a-input v-model={this.input} ref="elInput" size="large" placeholder="输入以 .js 或者 .ts 结尾的模块名称, 按回车确认" style="width: 500px;" on={{ keyup: this.handleKeyUp, blur: this.handleBlur }}/>
    </div>;
    },
});
export default function promptForModuleName(moduleNames = []) {
    return new Promise((rs) => {
        const container = document.getElementsByClassName('ce-workbench')?.[0];
        if (container) {
            const el = document.createElement('div');
            const instance = new Vue({
                provide: {},
                methods: {
                    confirm(value) {
                        this.$destroy();
                        this.$el.remove();
                        rs(value);
                    },
                    cancel() {
                        this.$destroy();
                        this.$el.remove();
                        rs('');
                    },
                },
                render() {
                    return <ModuleName moduleNames={moduleNames} onConfirm={this.confirm} onCancel={this.cancel}/>;
                },
            });
            container.appendChild(el);
            instance.$mount(el);
        }
    });
}
