import {
  XyPageletPropsEditorMeta,
} from '../paramsFormComponents';
import { CATEGORY } from './const';
import logger from '@utils/logger';

export default [
  {
    id: 'pagelet:emitEvent',
    name: '触发页面片事件',
    desc: '外部可通过 `$event.XXX` 拿到这里给出的事件参数 XXX',
    category: CATEGORY.LOGIC,
    paramsSchema: {
      type: 'object',
      fields: [
        {
          id: 'eventName',
          title: '触发事件名',
          type: 'string',
          enum: [
            { label: '选择事件', value: '' },
            { label: '预置事件A', value: 'presetEventA' },
            { label: '预置事件B', value: 'presetEventB' },
            { label: '预置事件C', value: 'presetEventC' },
            { label: '预置事件D', value: 'presetEventD' },
            { label: '预置事件E', value: 'presetEventE' },
          ],
          default: '',
        },
        {
          id: 'data',
          type: 'array',
          title: '事件参数',
          'uc:allowInterop': true,
          ui: {
            type: 'XyPageletPropsEditor',
            props: {
              getPagelet: () => null,
            },
          },
          items: {
            type: 'object',
            fields: [
              { id: 'key', type: 'string' },
              { id: 'value', type: 'string' },
            ],
          },
        },
      ],
    },
    paramsFormComponents: [
      XyPageletPropsEditorMeta,
    ],
    execute: async (ctx, params) => {
      const { wContext } = ctx.renderer;  // xy 上下文
      const { eventName, data } = params;

      if (!eventName) {
        const msg = '触发页面片事件[pagelet:emitEvent]: 必须选择事件';
        logger.info(msg);
        throw new Error(msg);
      }

      const kv = {};
      if (Array.isArray(data)) {
        data.forEach((item) => {
          const { key, value } = item;
          kv[key] = value;
        });
      }

      wContext?.container?.$emit(eventName, kv);
    },
  },
];
