import { defineComponent, computed, toRefs } from '@vue/composition-api';
import { useRuntimePagelet } from '@composables/useRuntimePagelet';
export default defineComponent({
    name: 'CustomHeaderPagelet',
    props: {
        project: {
            type: Object,
            default: null,
        },
        renderId: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const { project, renderId } = toRefs(props);
        const projectId = computed(() => project.value.id);
        const { page, pageList } = useRuntimePagelet({
            projectId: projectId.value,
            pageId: renderId.value,
        });
        return {
            page,
            pageList,
        };
    },
    render() {
        return (this.project && this.pageList && this.page ? <w-sys-pagelet root={true} mode="runtime" type="header" project-config={this.project} config={this.page} page-list={this.pageList} main={false}/> : <template />);
    },
});
