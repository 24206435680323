<template>
  <div class="navigation">
    <a-menu
      v-model="selectedKeys"
      @click="menuClick"
    >
      <a-menu-item key="pending">
        待办审批
      </a-menu-item>
      <a-menu-item key="created">
        我的申请
      </a-menu-item>
      <a-menu-item key="participated">
        已办审批
      </a-menu-item>
      <a-menu-item key="related">
        相关审批
      </a-menu-item>
      <a-menu-item
        v-if="canReadAll"
        key="all"
      >
        全部审批
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
import { permissionApi } from '@/services';

export default {
  inject: {
    ctx: 'ctx',
  },
  data() {
    return {
      selectedKeys: [],
      canReadAll: false,
    };
  },
  computed: {
    current() {
      const { query } = this.$route;
      return query.menu;
    },
    projectId() {
      return window.w.projectId;
    },
    env() {
      return window.w.env;
    },
  },
  watch: {
    current: {
      handler(value) {
        if (value) {
          this.selectedKeys = [value];
        }
      },
      immediate: true,
    },
  },
  created() {
    this.checkReadAll();
  },
  methods: {
    async checkReadAll() {
      if (this.ctx.user.isAdmin) {
        this.canReadAll = true;
        return;
      }
      const resp = await permissionApi.checkPermission(this.projectId, this.env, 'read.all-workflow-entity');
      this.canReadAll = resp;
    },
    menuClick({ key }) {
      this.$router.push({
        name: 'workflow.entity-list',
        params: { projectId: this.projectId, env: this.env },
        query: { ...this.$route.query, menu: key },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  width: 260px;
  height: 100%;
  background-color: #fff;
  padding: 10px;

  ::v-deep  .ant-menu-vertical {
    border-right: none;
  }
}
</style>
