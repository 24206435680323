import { watch, defineComponent, computed } from '@vue/composition-api';
import { useExpire } from './index';
import { ONE_DAY_MS } from '@pages/modules/permission/expire/utils';
export const UserRoleExpireEdit = defineComponent({
    name: 'UserRoleExpireEdit',
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        userId: {
            type: String,
            default: null,
        },
        projectId: {
            type: String,
            default: null,
        },
        env: {
            type: String,
            default: null,
        },
        value: {
            type: Number,
            default: null,
        },
        expire: {
            type: Number,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { expireOptions, renderCreateExpire } = useExpire(computed(() => [props.userId]), { readonly: computed(() => props.readonly) });
        watch(() => expireOptions.expireAt, (val, oldVal) => {
            if (props.readonly)
                return;
            if (oldVal !== val) {
                emit('change', val);
                emit('expireChange', expireOptions.expire);
            }
        });
        watch(() => props.expire, (val) => {
            expireOptions.expire = val;
        }, { immediate: true });
        watch(() => props.value, (val) => {
            if (!val) {
                if (!props.expire)
                    expireOptions.expire = 0;
                return;
            }
            expireOptions.expire = Math.round((+val - Date.now()) / ONE_DAY_MS);
        }, { immediate: true });
        return {
            renderCreateExpire,
        };
    },
    render() {
        return (<div>
        {this.renderCreateExpire()}
      </div>);
    },
});
