import { getRuntimeEnvPath } from '@/config/constant';
import wujiFetch from '@/utils/wujiFetch';
export default function useMockLoginRuntimeApi() {
    const path = `${getRuntimeEnvPath(window.w.env)}/manage/project/${window.w.projectId}/mock`;
    async function mockLogout() {
        const res = await wujiFetch(`${path}/logout`, {
            method: 'PATCH',
        });
        console.log('mock logout', res);
        return res;
    }
    return {
        mockLogout,
    };
}
