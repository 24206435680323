import ChatSdk from '@tencent/wuji-chat-sdk';
import { XyFlowTypeSelectorMeta, XyGPTAlertMeta, AiModelSelectMeta } from '../paramsFormComponents';
import { CATEGORY } from './const';
import { isExternalDeploy } from '@/utils/external-deploy';
import { isPageletComponentRenderer } from '@components/pagelet-component/utils';

const chatSdk = new ChatSdk({
  dns: `${location.host}${window.GLOBAL_INFO?.pathPrefix || ''}`,
  proto: location.protocol.slice(0, -1),
  isRuntime: true,
});

// azure/openai 支持的语言模型
export const AZURE_MODELS = [
  '', 'gpt-3.5-turbo', 'gpt-3.5-turbo-16k', 'gpt-4', 'gpt-4-32k', 'gpt-4-1106-preview', 'gpt-4-0125-preview', 'gpt-4o',
];

export const HUNYUAN_MODELS = [
  'hunyuan', 'hunyuan-code', 'hunyuan-codecmp', 'hunyuan-role', 'hunyuan-13B', 'hunyuan-standard', 'hunyuan-lite-13b', 'hunyuan-lite-7b', 'hunyuan-standard-256K', 'hunyuan-lite', 'hunyuan-vision',
];

// 消息返回类型
const RETURN_TYPE = {
  sync: 'sync',
  flow: 'flow',
};

// LLM系统，对应system
const SYSTEM = {
  混元: 'hy',
  ChatGPT: 'azure',
};

// 流式响应类型，拼接字符串、调用LessCode方法
const FLOW_TYPE = {
  joinString: 'joinString',
  callMethod: 'callMethod',
};

// 生成sessionId
const getSessionId = () => `${Math.random().toString(16)
  .slice(2, 10)}`;

// 根据路径拼接字符串
const appendValueByPath = (obj, path, value) => {
  try {
    const props = path.split('.');
    let current = obj;
    for (let i = 0; i < props.length - 1; i++) {
      current = current[props[i]];
    }
    current[props[props.length - 1]] += value;
  } catch (error) {
    console.error('路径错误！', error);
  }
};

const getErrorMessage = (message = '') => `[行为]AI模型调用出错：${message}`;

// 发送消息
const sendData = (params, renderer) => new Promise(async (resolve) => {
  const {
    message,
    systemContent,
    type,
    flowSetting: { flowType, joinString, callMethod },
    system,
    config,
    model,
    hunyuanModel,
  } = params;
  let queryStr = window.w.projectId ? `projectId=${window.w.projectId}` : '';
  if (window.w.mainPageInfo?.pageId) {
    queryStr += `&pageId=${window.w.mainPageInfo.pageId}`;
  }
  const sessionId = getSessionId();
  let event;
  const modelConfig = config || {};
  if (system === 'hy') {
    modelConfig.model = hunyuanModel || model;
  } else if (model) {
    modelConfig.model = model;
  }

  if (Array.isArray(message)) {
    if (systemContent && message?.[0]?.role !== 'system') {
      message.unshift({
        role: 'system',
        content: systemContent,
      });
    }
    event = await chatSdk.sendAll({
      messages: message,
      sessionId,
      modelConfig,
    }, {
      system,
      queryStr,
    });
  } else {
    const params = {
      sessionId,
    };
    if (message) {
      params.message = message;
      systemContent && (params.systemContent = systemContent);
    } else if (systemContent) {
      // 兼容只有prompt，没有message的情况
      params.message = systemContent;
    }
    params.modelConfig = modelConfig;
    event = await chatSdk.send(params, { system, queryStr });
  }

  let content = '';
  if (type === RETURN_TYPE.flow) {
    event.on('data', (data) => {
      const responseMessage = data.delta === undefined ? '' : data.delta;
      if (flowType === FLOW_TYPE.joinString) {
        appendValueByPath(renderer, joinString, responseMessage);
        content += responseMessage;
      }
      if (flowType === FLOW_TYPE.callMethod) {
        let topRenderer = renderer;
        while (topRenderer.ucParentRenderer && !isPageletComponentRenderer(topRenderer.$el)) {
          topRenderer = topRenderer.ucParentRenderer;
        }
        (topRenderer[callMethod](responseMessage));
      }
    });
  }

  event.on('end', (data) => {
    resolve({
      content: data.value,
      sessionId,
    });
  });

  event.on('error', (error) => {
    resolve({
      content,
      sessionId,
      errorMessage: error.error,
    });
  });
});

export default [
  {
    id: 'xy:ai:sendMessage',
    name: 'AI模型调用',
    desc: '',
    category: CATEGORY.AI,
    paramsSchema: {
      type: 'object',
      fields: [
        {
          id: 'alert',
          title: '',
          type: 'string',
          default: '',
          ui: { type: 'XyGPTAlert' },
          'uc:allowExpr': false,
          'uc:allowReset': false,
        },
        {
          id: 'systemContent',
          title: '预置prompt',
          type: 'string',
          default: '',
          'uc:allowInterop': true,
          description: '当message为数组时，prompt会作为第一条信息插入message数组，但如果message数组第一条就是"system"，则忽略该prompt',
        },
        {
          id: 'message',
          title: 'message（文本或者数组）',
          type: 'string',
          default: '',
          description: `message支持文本、对话上下文数组。数组格式如下：
[{
  "role": "system",
  "content": "你是一个只会用中文回答问题的助理"
},
{
  "role": "user",
  "content": "Hi！"
},
{
  "role": "assistant",
  "content": "你好，有什么我可以帮助你的吗？"
}]`,
          'uc:allowInterop': true,
        },
        {
          id: 'showErrorNotification',
          title: '错误提示',
          type: 'boolean',
          description: '勾选后,AI请求出错会弹窗并阻塞后续流程运行',
          default: true,
        },
        {
          id: 'config',
          title: '模型参数',
          type: 'object',
          'uc:allowInterop': true,
          fields: [{
            id: 'temperature',
            title: '温度',
            type: 'number',
            default: 1,
            description: '整型, 从0-2取值,默认是1.值越大输出越不稳定.',
          }],
          description: '可用于传递给AI模型的额外参数, 例如 temperature, top_p, response_format 等. \n如需拓展请使用右上角小齿轮.',
        },
        {
          id: 'type',
          title: '返回消息形式',
          type: 'string',
          enum: [
            {
              value: RETURN_TYPE.sync,
              label: '同步返回（等待全部消息接收完成后返回）',
            },
            {
              value: RETURN_TYPE.flow,
              label: '流式返回（拼接字符串或调用LessCode方法）',
            },
          ],
          default: RETURN_TYPE.sync,
        },
        {
          id: 'flowSetting',
          type: 'string',
          ui: { type: 'XyFlowTypeSelector' },
          title: '',
          condition: `params.type === "${RETURN_TYPE.flow}"`,
          default: {
            flowType: FLOW_TYPE.joinString,
            joinString: '',
            callMethod: 'onData',
          },
        },
        {
          id: 'system',
          title: '语言模型',
          type: 'string',
          enum: Object.entries(SYSTEM).map(([label, value]) => ({
            value, label,
          })),
          condition: `${!isExternalDeploy()}`,
          default: 'azure',
        },
        {
          id: 'model',
          title: '模型',
          type: 'string',
          condition: `${!isExternalDeploy()}`,
          default: '',
          ui: {
            type: 'XyAiModelSelect',
          },
        },
        {
          id: 'hunyuanModel',
          title: '模型',
          type: 'string',
          condition: 'false',
          enum: HUNYUAN_MODELS.map(v => ({ label: v, value: v })),
          default: '',
        },
      ],
    },
    execute: async (ctx, params) => {
      const { systemContent, message } = params;

      if (!systemContent && !message) {
        const msg = getErrorMessage('预置prompt、message不能同时为空！');
        throw new Error(msg);
      }

      const result = await sendData(params, ctx.renderer);
      if (result.errorMessage) {
        if (params.showErrorNotification) {
          throw new Error(getErrorMessage(result.errorMessage));
        }
      }
      return result;
    },
    returnValue: {
      type: 'object',
      fields: [
        {
          id: 'sessionId',
          description: '会话ID',
          type: 'string',
        },
        {
          id: 'content',
          description: '接收到的消息',
          type: 'string',
        },
        {
          id: 'errorMessage',
          description: '错误信息',
          type: 'string',
        },
      ],
    },
    paramsFormComponents: [
      XyFlowTypeSelectorMeta,
      XyGPTAlertMeta,
      AiModelSelectMeta,
    ],
  },
];
