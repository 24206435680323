import openSimpleModal from '@components/simpleModal/openSimpleModal';
import localLoadErrorModal from '@/components/localLoadErrorModal/modal';
import { toggleCliHttps, ENABLE_LOCAL_HTTPS } from '@/config/cli-settings';
import { getAppQuery } from '@utils/query';
let showModalPromise;
const openLocalLoadErrorModal = async (context) => {
    const debugProtocal = getAppQuery('debugproto');
    if (!debugProtocal) {
        return toggleCliHttps(!ENABLE_LOCAL_HTTPS, { reload: true, withRetryQuery: true });
    }
    if (typeof showModalPromise === 'undefined') {
        showModalPromise = openSimpleModal(context, localLoadErrorModal);
    }
    return showModalPromise;
};
export default openLocalLoadErrorModal;
