import cloneDeep from 'lodash/cloneDeep';
import isPlainObject from 'lodash/isPlainObject';
import logger from '@utils/logger';

function toJSON(str, defaultReturn = {}) {
  try {
    if (typeof str === 'string') {
      return JSON.parse(str);
    }
    return str;
  } catch (err) {
    return defaultReturn;
  }
}

/**
 * @returns {{ value: string, label: string, groups?: string[] }[] | undefined}
 */
function computeOptions(args) {
  try {
    const c = args.replace(/^LIST:/, '');
    if (c[0] === '{') return toJSON(c).options;

    return c.split(',').map((opt) => {
      const kv = opt.split('|');
      return {
        value: kv[0],
        label: kv[1],
        groups: [],
      };
    });
  } catch (e) {
  }
}

const ERROR_TAG = '[field-adapter]';

const TYPE_EVOLVE_MAP = {
  // 4: evolvePic,
  5: evolveSelect,
  6: evolveSelect,
  7: evolveSelect,
  13: evolveSelect,
  9: evolveBoolean,
  10: evolveDate,
  11: evolveDate,
  12: evolveDate,
  20: evolveJsonText,
  21: evolveDecimals,
  15: evolveColor,
  // 22: evolveFile,
  // 25: evolveCodeBlock,
};

// function evolvePic(field, config) {
//   const { args } = field;
//   const argsObj = toJSON(args);
// }

function evolveSelect(field, config) {
  const evolveConfig = cloneDeep(config);

  // 下拉框: 转换自定义选项配置
  if (field.args.startsWith('LIST:')) evolveConfig.options = computeOptions(field.args);
  evolveConfig.fieldConf = field || {};

  return evolveConfig;
}


function evolveBoolean(field, config) {
  const evolveConfig = cloneDeep(config);

  const { args } = field;
  const argsObj = toJSON(args);

  // 布尔值: 样式
  if (typeof argsObj.useSwitch === 'boolean') {
    evolveConfig.useSwitch = argsObj.useSwitch;
  }

  // 布尔值: 名字
  evolveConfig.text = argsObj.text || '';

  return evolveConfig;
}

function evolveDate(field, config) {
  const evolveConfig = cloneDeep(config);

  Object.assign(evolveConfig, { isReturnTimeStamp: false, isReturnSecond: false });
  if (field.timeType === 'second') Object.assign(evolveConfig, { isReturnTimeStamp: true, isReturnSecond: true });
  if (field.timeType === 'ms') Object.assign(evolveConfig, { isReturnTimeStamp: true, isReturnSecond: false });

  return evolveConfig;
}

function evolveJsonText(_, config) {
  const evolveConfig = cloneDeep(config);
  evolveConfig.showTools = false;
  return evolveConfig;
}

function evolveDecimals(field, config) {
  const evolveConfig = cloneDeep(config);

  // 小数输入
  evolveConfig.step = '0.01';

  return evolveConfig;
}

function evolveColor(field, config) {
  const evolveConfig = cloneDeep(config);

  evolveConfig.type = 'hex';

  return evolveConfig;
}

// function evolveFile(field, config) {
//   const { args } = field;
//   const argsObj = toJSON(args);
// }

// function evolveCodeBlock(field, config) {
//   const { args } = field;
//   const argsObj = toJSON(args);
// }

// 无极1.0组件到2.0组件的配置转换层
function evolve(field) {
  if (!isPlainObject(field)) {
    return field;
  }

  const {
    args,
    type,
    id,
    name,
    desc,
    value,
    width,
    maxlength,
    pattern,
    readonly,
    required,
    unique,
    notAutoSpace,
    sort,
    triggers,
    score,
    defaultOperator,
    searchable,
  } = field;

  let config = {
    type,  // 字段类型
    id, // 英文名id
    fieldCName: name, // 中文名
    name,  // 中文名
    desc, // 说明
    defaultValue: value,  // 初始值
    // pattern, // 正则校验 -- 已经废弃，使用 patterns
    patterns: [], // 自定义校验
    required,  // 必填
    readonly,  // 只读
    unique,  // 唯一
    notAutoSpace,  // 保留首尾空格
    sort, // 排序
    args, // 扩展设置
    triggers, // 字段变更监听(触发器配置)
    score, // 排序权重
    defaultOperator,
    searchable,
  };

  // 宽度处理
  if (typeof width === 'number' && width > 0) {
    config.width = `${width}px`;
  }

  // 最大限制长度处理
  if (typeof maxlength === 'number' && maxlength > 0) {
    config.maxlength = maxlength;
  }

  // 正则表达式处理
  if (pattern && typeof pattern === 'string') {
    config.patterns.push({
      reg: pattern,
      errMsg: `不匹配字段正则表达式：${pattern}`,
      suspend: true,
      method: 'regex',
      triggers: [],
    });
  }

  if (TYPE_EVOLVE_MAP[field.type]) {
    try {
      config = TYPE_EVOLVE_MAP[field.type].call(this, field, config);
    } catch (err) {
      logger.error(ERROR_TAG, err);
    }
  }

  return config;
}

export default {
  evolve,
};
