<template>
  <div v-if="!dtHide && ucs.length">
    <div
      v-if="ucs.length > 1"
      ref="listwrapper"
      class="dt-entry-list"
      :class="isHovered ? 'hovered' : ''"
      :style="{
        bottom: `${dropDownBottom}px`
      }"
    >
      <div
        v-for="(uc, index) in ucs"
        :key="index"
        class="dt-entry-list-item"
        @click="handleUcClick(uc)"
        @mouseover="handleUcMouseover(uc)"
      >
        第{{ index+1 }} 个页面
      </div>
    </div>
    <a-button
      type="primary"
      size="large"
      class="dt-entry"
      :class="{ 'hovered': isHovered }"
      :style="{
        bottom: `${bottom}px`,
      }"
      @click="handleOpenDT"
      @mouseenter="isHovered = true"
      @mousedown="startDrag"
      @mouseleave="handleMouseLeave"
    >
      <a-icon
        type="monitor"
        :style="{transform:'scale(1.2)'}"
      />
      <span class="title">
        开发者工具
      </span>
    </a-button>
  </div>
</template>

<script>
import dtBus from '@/components/uicorePlugin/DTBus';
import { startMouseMove, tryEval } from '@tencent/ui-core/lib/utils';
import { highlightRect } from '@tencent/ui-core/lib/utils/browser';

export default {
  props: {
    config: { type: Object, default() {
      return {};
    } },
    env: { type: String, default: '' },
  },
  data() {
    return {
      isHovered: false,
      ucs: [],
      right: 0,
      bottom: 100,
      dragging: false,
    };
  },
  computed: {
    dtHide() {
      if (this.$route?.query?.dt_show === '1') return false;
      return this.$route?.query?.dt_hide === '1' || (!['dev', 'test'].includes(this.env));
    },
    dtConfig() {
      return tryEval(this.$route?.query?.dtConfig) || {};
    },
    dropDownBottom() {
      const height = this.$refs.listwrapper?.getBoundingClientRect()?.height;

      return this.bottom - (height || 20) / 2;
    },
    dragOffset() {
      return this.dragging ? this.dragOffsetY : 0;
    },
  },
  created() {
    const onDtRendererCreated = (data) => {
      // 预览模式下还保留着designer，这里给filter掉
      this.ucs = data;
      // if (data.some(uc => !uc.designer)) {
      //   this.ucs = data.filter(uc => !uc.designer);
      // } else this.ucs = data;
    };
    dtBus.$on('dt-renderer-created', onDtRendererCreated);
    this.$once('hook:beforeDestroy', () => {
      dtBus.$off('dt-renderer-created', onDtRendererCreated);
    });
  },
  methods: {
    handleUcMouseover(uc) {
      highlightRect(uc.renderer.$el.getBoundingClientRect());
    },
    startDrag(e) {
      const { bottom } = this;

      startMouseMove({
        initialEvent: e,
        onMove: ({ deltaY }) => {
          if (deltaY) this.dragging = true;
          this.bottom = bottom - deltaY;
        },
      });
    },
    handleMouseLeave() {
      if (this.ucs.length < 2) this.isHovered = false;
    },
    openDevTool(root) {
      root.openDevtool();
    },
    handleUcClick(uc) {
      this.isHovered = false;
      this.openDevTool(uc.root);
    },
    handleOpenDT() {
      if (this.dragging) {
        this.dragging = false;
        return;
      }
      if (this.ucs.length === 1) {
        this.openDevTool(this.ucs[0].root);
      } else {
        this.isHovered = !this.isHovered;
      }
    },
  },
};
</script>

<style scoped lang="scss">

.dt-entry {
  position: fixed;

  padding: 0px 0px 0px 10px !important;

  border-top-left-radius: 40px !important;
  border-bottom-left-radius: 40px !important;
  right: 0;
  backdrop-filter: blur(5px);

  z-index: 1000;
  overflow:hidden;
  cursor: pointer;
  transition: none !important;

  .title {
    max-width: 0px;
    transition: max-width 0.5s;
  }

  &.hovered{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: auto;
    .title {
      max-width: 200px;
    }
  }
  &-list{
    position: absolute;
    z-index: 1000;
    background-color: white;
    right: 120px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    padding: 5px 0;
    visibility: hidden;
    transition-delay: 2s;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    opacity: 0;

    &.hovered {
      visibility: visible;
      opacity: 1;
    }

    &-item {
    cursor: pointer;
    line-height: 24px;
    padding: 3px 15px;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}

</style>
