export function definePagePlugin(meta, loader) {
    const id = meta.pluginId;
    const fullMeta = {
        icon: 'https://formal-75535.gzc.vod.tencent-cloud.com/default-logo.png',
        creator: 'wuji',
        owner: 'wuji',
        ctime: new Date().toISOString(),
        group: '系统内置',
        propsSchema: { type: 'object', fields: [] },
        version: '0.0.1',
        src: async () => ({
            comp: await loader(),
            config: { id, ...fullMeta },
        }),
        ...meta,
    };
    const info = {
        ctime: fullMeta.ctime,
        group: fullMeta.group,
        pluginId: id,
        publisher: fullMeta.owner,
        versionId: `${id}@${fullMeta.version}`,
        detail: fullMeta,
    };
    return info;
}
