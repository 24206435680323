var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
export var ConfirmSaveType;
(function (ConfirmSaveType) {
    ConfirmSaveType[ConfirmSaveType["NOT_SAVE"] = 0] = "NOT_SAVE";
    ConfirmSaveType[ConfirmSaveType["SAVE"] = 1] = "SAVE";
    ConfirmSaveType[ConfirmSaveType["DO_NOTHING"] = 2] = "DO_NOTHING";
})(ConfirmSaveType || (ConfirmSaveType = {}));
let ConfirmModal = class ConfirmModal extends Vue {
    loading = false;
    render() {
        return (<a-modal keyboard={false} closable={true} visible={true} onCancel={this.handleCancel} title={null} footer={null} width={425} bodyStyle={{
                padding: '32px 32px 24px',
            }}>
        <div style={{ fontSize: '16px', color: '#161616', fontWeight: 500, display: 'flex' }}>
          <a-icon type="question-circle" style={{ fontSize: '22px', marginRight: '16px', color: '#efa000' }}/>
          应用配置修改未保存, 是否需要保存再跳转?
        </div>
        <div style={{ marginTop: '24px', display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
          <a-button loading={this.loading} onClick={this.handleNotSave}>
            不保存
          </a-button>
          <a-button type="primary" loading={this.loading} onClick={this.handleSave}>
            保存
          </a-button>
        </div>
      </a-modal>);
    }
    handleCancel() {
        this.loading = true;
        this.$emit('close', ConfirmSaveType.DO_NOTHING);
    }
    handleSave() {
        this.loading = true;
        this.$emit('close', ConfirmSaveType.SAVE);
    }
    handleNotSave() {
        this.loading = true;
        this.$emit('close', ConfirmSaveType.NOT_SAVE);
    }
};
ConfirmModal = __decorate([
    Component({
        name: 'ConfirmModal',
    })
], ConfirmModal);
export { ConfirmModal };
