import wujiFetch from '@/utils/wujiFetch';
import getXyManagePathPrefix from '@utils/getXyManagePathPrefix';

const fetchDataTables = async projectId => await wujiFetch(`${getXyManagePathPrefix('dev')}/datatable?projectid=${projectId}&join=page`);

const addDataTable = async ({ projectId, data }, headers = {}) => await wujiFetch(`${getXyManagePathPrefix('dev')}/datatable?projectid=${projectId}`, {
  method: 'POST',
  headers,
  body: JSON.stringify(data),
});

const updateDataTable = async ({ projectId, data }, headers = {}) => await wujiFetch(`${getXyManagePathPrefix('dev')}/datatable/${data.id}?projectid=${projectId}`, {
  method: 'PATCH',
  headers,
  body: JSON.stringify(data),
});

const deleteDataTable = async ({ projectId, id }, headers = {}) => await wujiFetch(`${getXyManagePathPrefix('dev')}/datatable/${id}?projectid=${projectId}`, {
  method: 'DELETE',
  headers,
});


export default {
  fetchDataTables,
  addDataTable,
  updateDataTable,
  deleteDataTable,
};
