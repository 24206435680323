import wujiFetch from '@/utils/wujiFetch';
import getXyManagePathPrefix from '@utils/getXyManagePathPrefix';

const fetchCollection = async ({ projectId, collectionId }) => await wujiFetch(`${getXyManagePathPrefix()}/datasource/collection/${collectionId}?projectid=${projectId}`);

const getAllCollection = async (projectId, filter = '') => {
  const res = await wujiFetch(`${getXyManagePathPrefix()}/datasource/basic/collection`, {
    method: 'get',
    query: {
      projectid: projectId,
      size: 'total',
      filter,
      sort: '_mtime',
      order: 'desc',
    },
  });
  return res;
};

export default {
  fetchCollection,
  getAllCollection,
};
