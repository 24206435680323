import wujiFetch from '@/utils/wujiFetch';
export default {
    state: {
        dataSourceConfigs: [],
        dataSourceDetailConfig: {},
    },
    actions: {
        async fetchDataSource({ commit }, { projectId }) {
            const query = new URLSearchParams({
                projectid: projectId,
                size: 'total',
            });
            const dataSourceConfigs = await wujiFetch(`/api/xy/datasource/collection?${query.toString()}`);
            commit('updateDataSource', dataSourceConfigs);
        },
        async fetchDataSourceDetailConfig({ commit, state }, { projectId, dataSourceId }) {
            const id = `${projectId}:${dataSourceId}`;
            if (state.dataSourceDetailConfig[id]) {
                return;
            }
            const dataSourceConfigs = await wujiFetch(`/api/xy/datasource/collection/${dataSourceId}?projectid=${projectId}`);
            commit('updateDataSourceDetailConfig', dataSourceConfigs);
        },
    },
    mutations: {
        updateDataSource(state, payload) {
            state.dataSourceConfigs = payload;
        },
        updateDataSourceDetailConfig(state, payload) {
            state.dataSourceDetailConfig[payload.id] = payload;
        },
    },
};
