import XyPageletRenderer from './xy-pagelet-renderer.vue';
import XyPageletSchema from './xy-pagelet-schema';
import { meta as XyInnerPageletSelectorMeta } from './xy-inner-pagelet-selector.vue';
import { XyPageletPropsEditorMeta } from '../uicorePlugin/paramsFormComponents';
import Vue from 'vue';
import logger from '@utils/logger';

export default {
  component: XyPageletRenderer,
  componentForDesign: XyPageletRenderer,
  category: '插入',
  id: 'xy-pagelet-renderer',
  events: [
    {
      id: 'presetEventA',
      name: '预置事件A',
      desc: '预置事件A触发时执行，可通过 `$event.XXX` 拿到事件参数 XXX',
    },
    {
      id: 'presetEventB',
      name: '预置事件B',
      desc: '预置事件B触发时执行，可通过 `$event.XXX` 拿到事件参数 XXX',
    },
    {
      id: 'presetEventC',
      name: '预置事件C',
      desc: '预置事件C触发时执行，可通过 `$event.XXX` 拿到事件参数 XXX',
    },
    {
      id: 'presetEventD',
      name: '预置事件D',
      desc: '预置事件D触发时执行，可通过 `$event.XXX` 拿到事件参数 XXX',
    },
    {
      id: 'presetEventE',
      name: '预置事件E',
      desc: '预置事件E触发时执行，可通过 `$event.XXX` 拿到事件参数 XXX',
    },
  ],
  icon: 'https://vfiles.gtimg.cn/wuji_dashboard/xy/rainbow/icon-page-let-renderer_1636364733590.png',
  slots: [],
  display: 'block',
  visible: false,
  name: '内嵌页面片',
  order: 1,
  propPanel: {
    XyInnerPageSelector: XyInnerPageletSelectorMeta,
    XyPageletPropsEditor: XyPageletPropsEditorMeta,
  },
  readme: '',
  propsSchema: XyPageletSchema,
  getActions({ modifyComp }) {
    return [
      {
        title: '升级组件',
        icon: 'fa fa-refresh',
        async execute() {
          await modifyComp((c) => {
            logger.info(c);
            Vue.set(c, 'type', 'xy-page-container');
            Vue.set(c.props, 'type', 'pagelet');
          });
        },
      },
    ];
  },
};
