import EventEmitter from 'events';
// 全局事件监听器(仅用于运行时系统级的事件)
export const wEmitter = new EventEmitter();
export const EventName = {
    ROUTER_BEFORE_EACH: 'router:beforeEach',
    ROUTER_AFTER_EACH: 'router:afterEach',
    PAGE_INIT: 'page:init',
    PAGE_CREATED: 'page:created',
    PAGE_BEFORE_DESTROY: 'page:beforeDestroy',
    PAGE_DESTROYED: 'page:destroyed',
    PAGE_DATASOURCE_READY: 'page:datasource:ready',
    PAGE_TITLE_CHANGE: 'page:title:change', // 页面标题修改
};
