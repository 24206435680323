import { jobReloadButton } from '@loaders/pageGenerator/generators/flowDetailPage';
export function reloadFitDataButton(uc) {
    uc.hooks.fitDataAfterAll.tap('flowPagePlugin', (oldList, req) => {
        if (req.dataPath === 'job.reload') {
            return [{
                    type: 'action',
                    icon: 'https://vfiles.gtimg.cn/vupload/20210427/938d481619528238025.png',
                    layout: jobReloadButton,
                    order: 1,
                    name: '[刷新流程]按钮',
                    desc: '',
                }];
        }
    });
}
