import { computeCurrentPageId, computeCurrentPath } from '@utils/path';
import { getBizEntry } from '@components/nav-widget/switch/utils';
import Cookies from 'js-cookie';
import { isOffline } from './offline';

/* 获取业务Header */
const getBizHeaders = (projectConfig) => {
  if (!projectConfig) return {};
  const entry = getBizEntry(projectConfig);
  if (!entry) return {};
  return { 'xy-biz-info': `${entry.key}=${entry.value || ''}` };
};

const getSdkHeaders = () => {
  const { __POWERED_BY_WUJIE__: poweredBySdk, $wujie } = window;
  return poweredBySdk ? {
    'xy-powered-by': $wujie.props?.version || 'wujie@1.0.0',
    'xy-auth-id': $wujie.props?.authId || '',
  } : {};
};

export const setXyCookies = ({ projectId } = {}) => {
  projectId && Cookies.set('xy-project-id', projectId);

  const { RUNTIME_CONFIGS } = window;
  if (RUNTIME_CONFIGS) {
    const { projectId, env } = RUNTIME_CONFIGS;
    const { pageId, path } = getPageInfoFromRouter();
    pageId && Cookies.set('xy-page-id', pageId);
    projectId && Cookies.set('xy-project-id', projectId);
    env && Cookies.set('xy-env', env);
    path && Cookies.set('xy-page-path', path);
  }
};

const setXyFlowCookie = (flowId) => {
  Cookies.set('xy-flow-id', flowId);
};

const getPageInfoFromRouter = () => {
  const router =  window?.globalContext?.router;
  let pageId = '';
  let path = '';
  if (router) {
    path = computeCurrentPath(router);
    pageId = computeCurrentPageId(router);
  }
  return {
    path, pageId,
  };
};

export default () => {
  const { RUNTIME_CONFIGS, xy_runtime_project: project } = window;
  if (!RUNTIME_CONFIGS || isOffline()) return {};

  const { projectId, env } = RUNTIME_CONFIGS;
  // todo(ice): 用页面路由鉴权后干掉
  const { pageId, path } = getPageInfoFromRouter();
  const flowPath = '/wuji/workflow/flow/';
  if (path.startsWith(flowPath)) {
    const flowId = path.replace(flowPath, '');
    setXyFlowCookie(flowId);
  } else {
    setXyFlowCookie('');
  }
  setXyCookies({ projectId });
  const mode = window.w?.mode || 'unknown';
  const headers = {
    'xy-project-id': projectId,
    'xy-page-id': pageId,
    'xy-page-path': path,
    'xy-env': env,
    'xy-mode': mode,
    ...getBizHeaders(project),
    ...getSdkHeaders(),
  };

  return headers;
};
