import { request } from '@miniprogram/utils/api';

export default {
  state: {
    qrCode: {},
  },
  actions: {
    async fetchMiniprogramQRCode({ commit, state }, payload) {
      const { path } = payload;
      // 判断内存有没有图片
      if (state.qrCode[path]) {
        return { done: true, img: state.qrCode[path] };
      }

      // 没有请求接口获取
      const wxQRCodeInfo = await request('wxQRCode', payload);
      const { done, img } = wxQRCodeInfo;
      if (done) {
        commit('updateQRCode', { path, img });
      }
      return wxQRCodeInfo;
    },
  },
  mutations: {
    updateQRCode(state, payload) {
      const { path, img } = payload;
      state.qrCode[path] = img;
    },
  },
};
