import { openDB } from 'idb';
const createObjectStore = async (db, definition) => {
    const store = db.createObjectStore(definition.name, definition.opts);
    definition.indexes?.forEach((indexDefinition) => {
        store.createIndex(indexDefinition.name, indexDefinition.key, indexDefinition.opts);
    });
};
export default class App {
    name;
    db = null;
    constructor(name) {
        this.name = name;
    }
    async init(schemaDefinition) {
        const db = await openDB(this.name, schemaDefinition.version, {
            upgrade: (db) => {
                schemaDefinition.stores.forEach((storeDefinition) => {
                    createObjectStore(db, storeDefinition);
                });
            },
        });
        this.db = db;
    }
    store(name) {
        if (!this.db)
            throw Error('Database not initialized');
        return new ObjectStore(name, this.db);
    }
}
export class ObjectStore {
    db = null;
    name;
    constructor(name, db) {
        this.db = db;
        this.name = name;
    }
    async get(key) {
        return await this.db.get(this.name, key);
    }
    async getAll() {
        return await this.db.getAll(this.name);
    }
    async getAllKeys() {
        return await this.db.getAllKeys(this.name);
    }
    async count(query) {
        return await this.db.count(this.name, query);
    }
    async put(key, value) {
        return await this.db.put(this.name, value, key);
    }
    async add(value) {
        return await this.db.add(this.name, value);
    }
    async delete(key) {
        return await this.db.delete(this.name, key);
    }
    async clear() {
        return await this.db.clear(this.name);
    }
    transaction(type) {
        return this.db.transaction(this.name, type);
    }
    // index相关的操作
    getFromIndex(indexName, key) {
        return this.db.getFromIndex(this.name, indexName, key);
    }
    getAllFromIndex(indexName, key, count = 1000) {
        return this.db.getAllFromIndex(this.name, indexName, key, count);
    }
    getKeyFromIndex(indexName, key) {
        return this.db.getKeyFromIndex(this.name, indexName, key);
    }
    countFromIndex(indexName, key) {
        return this.db.countFromIndex(this.name, indexName, key);
    }
}
