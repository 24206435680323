import Vue from 'vue';
import { message } from 'ant-design-vue';
import { cloneDeep } from 'lodash';
import { makeMockData } from '@tencent/ui-core/lib/utils/dataSchema/makeMockData';
import { DataSchemaContext } from '@tencent/data-schema/lib/core/DataSchemaContext';
import initWujiDirtyWorker from './utils/wujiDirtyWorker';
import qs from 'querystring';
import { makeBox, mapBoxValue, warpPromise } from './utils/promiseBox';
import SourceControllerBase from './SourceControllerBase';
import logger from '@utils/logger';
import { getAvailableComponent } from '@/utils/comps-loader';

const WujiSourceController = Vue.extend({
  name: 'WujiSourceController',
  extends: SourceControllerBase,
  data() {
    return {
      dataBox: makeBox(),
      dataSchemaBox: makeBox(),
      actions: [],
      pagination: { page: 1, size: 10, total: 0 },
      mock: false,
      // 走复制逻辑的标志，true为复制逻辑（带objectId参数获取详情，但逻辑为新建）
      forceCreateMode: false,
      dirtyOldData: null,
      dataClipper: {},
      internalObjectId: undefined,
    };
  },

  computed: {
    data: mapBoxValue('dataBox'),
    dataSchema: mapBoxValue('dataSchemaBox'),
    loading() {
      return !!(this.dataBox.loading || this.dataSchemaBox.loading);
    },

    workflow() {
      // deprecated
      return this.dataSchema?.['xy:workflow'];
    },

    objectId: {
      get() {
        return this.internalObjectId;
      },
      set(val) {
        this.internalObjectId = val;
        this.loadData();
      },
    },

    isCreate() {
      if (this.forceCreateMode) return true;
      return !this.isListMode && !this.objectId && this.objectId !== 0;
    },
    isListMode() {
      return !!this.config.isListMode;
    },
  },
  created() {
    this.pagination.size = this.config?.pageCon?.size || 10;
    this.mock = this.pluginInstance.isDesignMode;

    // -----------------------

    this.actions = [
      { id: 'reset', title: '重置表单' },
    ];

    if (this.isListMode) {
      this.actions.push({ id: 'upsert', title: '提交列表修改' });
    } else {
      this.actions.push(
        { id: 'upsert', title: '(自动)新建或者修改当前数据' },
        { id: 'createSingle', title: '新建数据' },
        { id: 'updateSingle', title: '修改当前数据' },
        { id: 'deleteSingle', title: '删除当前数据', isDangerous: true },
      );
    }

    // -----------------------

    const sourceId = this.config.objectIdSource;
    if (this.wContext?.data?.data?.[sourceId]) {
      this.internalObjectId = this.wContext.data.data[sourceId];
      this.forceCreateMode = this.wContext.data.forceCreateMode;
    } else if (this.wContext?.data?.[sourceId]) {
      this.internalObjectId = this.wContext.data[sourceId];
      this.forceCreateMode = this.wContext.data.forceCreateMode;
    } else {
      const query = qs.parse(location.search.substr(1));
      this.forceCreateMode = !!query.forceCreateMode;
      this.internalObjectId = query[sourceId] || '';
    }
    logger.info('wuji forceCreateMode', this.forceCreateMode);
    // 先初始化修剪器

    this.loadDataSchema()
      .then(() => {
        this.$emit('load-schema', this);
        this.$nextTick(async () => {
          try {
            await this.loadData();
          } catch (error) {
            console.error(`[UICoreXY] WUJI Source ${this.config.id} failed at first data loading`, error);
          } finally {
            this.$emit('ready', this);
          }
        });
      }, (error) => {
        message.error(`数据源 ${this.config.id}载入数据失败：${error}`);
        console.error(`数据源${this.config.id}载入数据失败：`, error);
      });
  },

  methods: {
    /**
     * @param {import('../../../../../ui-core/types').ComponentFitDataResult[]} list
     */
    fitDataAfterAll(list) {
      // 把原来的“表单项”组件去掉
      const toRemoveIndex = this.config.isListMode ? list.findIndex(x => x.layout.type === 'w-form-item') : -1;
      if (toRemoveIndex >= 0) list.splice(toRemoveIndex, 1);

      // 找到一个能用的 editor
      const editor = list.find(x => x.type === 'editor');   // 找到第一个 editor
      if (!editor) return list;

      // 然后就是一系列操作了
      const sourceId = this.config.id;

      let hideModalEvents = [];
      if (this.wContext.type === 'modal') {
        hideModalEvents = [
          {
            type: 'xy:hideModal',
            params: {},
          },
        ];
      }

      // 提交按钮
      const submitButton = {
        type: getAvailableComponent('public', 'w-button'),
        props: { type: 'primary', size: 'large', btnText: '提 交' },
        events: {
          click: {
            steps: [
              {
                type: 'uicore:validateForm',
                params: { target: '' },
              },
              {
                type: 'xy:source:submit',
                params: { sourceId, actionType: 'upsert', reloadData: !this.forceCreateMode },
              },
              {
                type: 'uicore:message',
                params: { type: 'success', text: this.forceCreateMode ? '复制成功' : '修改已提交' },
              },
              ...hideModalEvents,
            ],
            debounce: false,
          },
        },
      };

      // 删除按钮（非列表模式）
      const deleteButton = !this.isListMode && {
        type: getAvailableComponent('public', 'w-button'),
        props: { icon: 'delete', size: 'large', btnText: '删除' },
        events: {
          click: {
            steps: [
              {
                type: 'uicore:confirm',
                params: { text: '确定要删除吗？' },
              },
              {
                type: 'xy:source:submit',
                params: { sourceId, actionType: 'deleteSingle', reloadData: false },
              },
              {
                type: 'uicore:message',
                params: { type: 'success', text: '已删除' },
              },
              ...hideModalEvents,
            ],
            debounce: false,
          },
        },
      };

      // 如果是表单的话，把那两个按钮塞进去
      if (!this.config.isListMode && editor.layout.type === 'w-form') {
        const buttonLine = {
          type: 'w-paragraph',
          props: { align: 'center' },
          children: [
            submitButton,
            deleteButton,
          ].filter(Boolean),
        };
        editor.layout.children.push(buttonLine);
      }

      if (deleteButton) {
        list.unshift({
          type: 'action',
          icon: 'https://vfiles.gtimg.cn/vupload/20210427/938d481619528238025.png',
          layout: deleteButton,
          order: 100,
          name: '[删除]按钮',
          desc: '',
        });
      }

      if (submitButton) {
        list.unshift({
          type: 'action',
          icon: 'https://vfiles.gtimg.cn/vupload/20210427/938d481619528238025.png',
          layout: submitButton,
          order: 100,
          name: '[提交修改]按钮',
          desc: '',
        });
      }
    },

    async initDataClipper() {
      // 兼容旧数据
      if (!this.config.dataClipper) return;

      const  { generateSchema, resolver, schemaResolver } = await import('@tencent/wuji-data-clipper-core');

      const { fields: forwardFields } = this.config.dataClipper.forward;
      const { fields: backwardFields } = this.config.dataClipper.backward;

      const context = {
        wContext: this.wContext,
      };

      const setUpClipper = (direction, fields) => {
        const { data, schema } = this.dataClipper;
        if (!data) {
          this.dataClipper.data = {};
        }
        if (!schema) {
          this.dataClipper.schema = {};
        }
        const clipperSchema = generateSchema(fields);
        this.dataClipper.data[direction] = data => resolver(clipperSchema, data, context);
        this.dataClipper.schema[direction] = schema => schemaResolver(clipperSchema, schema);
      };

      if (forwardFields?.length > 0) {
        setUpClipper('forward', forwardFields);
      }
      if (backwardFields?.length > 0) {
        setUpClipper('backward', backwardFields);
      }
    },

    /**
     * 修剪
     * @param {('schema'|'data')} type
     * @param {any} data
     * @param {('forward'|'backward')} direction 方向，从dm获取是forward，提交给dm是backward
     * @param {('list'|'detail')} listOrDetail
     * @returns {any}
     */
    clipper(type, data, direction, listOrDetail) {
      let res = data;
      // 初始化的时候clipper里只会有data和schema, 里面是空的
      if (this.dataClipper && this.dataClipper?.[type]?.[direction]) {
        res = (listOrDetail === 'list') ? data.map(item => this.dataClipper[type][direction](item)) : this.dataClipper[type][direction](data);
      }
      return res;
    },

    /**
     * 向拉取到的数据，注入那些诸如 `$upsert()` 的方法
     *
     * @returns dist
     */
    injectActionMethods(dist) {
      if (!dist || typeof dist !== 'object') return dist;

      this.actions.map(x => x.id).forEach((actionId) => {
        Object.defineProperty(dist, `$${actionId}`, {
          configurable: true,
          enumerable: false,
          value: (...args) => this.dispatch(actionId, ...args),
        });
      });
      return dist;
    },

    loadDataSchema() {
      this.dataSchemaBox = warpPromise(async () => {
        await this.initDataClipper();

        const [x] = await Promise.all([
          this.wContext.dataManager.getDataSchema(this.config),
        ]);
        if (x.code !== 200) throw new Error(`${x.code}: ${x.message}`);

        let ds = x.data;

        ds = this.clipper('schema', ds, 'forward', 'detail');
        if (this.isListMode) {
          ds = { type: 'array', items: ds, table: x.data.table };
        }
        const wujiDirtyWorker = initWujiDirtyWorker(ds);
        this.wujiDirtyWorker = wujiDirtyWorker;
        const schema = wujiDirtyWorker.newDataSchema;

        if (!schema.methods) schema.methods = [];
        this.actions.forEach((action) => {
          schema.methods.push({ ...action, id: `$${action.id}` });
        });

        return schema;
      });
      return this.dataSchemaBox.promise;
    },

    getClonedData() {
      try {
        return JSON.parse(JSON.stringify(this.wujiDirtyWorker.toDirtyData((this.clipper('data', this.data, 'backward', 'list')))));
      } catch (err) {
        console.warn('[XiaoyaoCollectionDS] Cannot clone data for ', this, err);
        return null;
      }
    },

    async reset() {
      const { dataSchema, data: oldData } = this;
      this.dataBox = warpPromise(() => {
        const stub = new DataSchemaContext(dataSchema, { requiredByDefault: true });
        const defaultValue = stub.makeRootStubOf({}).getDefaultValue();
        return this.injectActionMethods(defaultValue);
      });
      this.dataBox.value = oldData;
      this.dataBox.promise.then(() => {
        this.$emit('reset', this);
      });
      return this.dataBox.promise;
    },

    loadData(overrideOptions) { // options方便外部调用传页码/过滤条件
      const { objectId, dataSchema, data: oldData } = this;

      // dataSchema 还没载入。。。。
      if (!dataSchema) return Promise.reject(new Error('dataSchema not ready'));

      // mock 模式
      if (this.mock) {
        this.dataBox = warpPromise(() => this.injectActionMethods(makeMockData(dataSchema)));
        this.dataBox.value = oldData;
        this.dataBox.promise.then(() => this.$emit('load', this));
        return this.dataBox.promise;
      }

      // 列表模式
      if (this.isListMode) {
        this.dataBox = warpPromise(async () => {
          const options = {
            ...this.config,
            ...overrideOptions,
          };

          // TODO: pageCon 的 filter 和 filterCon 会有冲突，可能要看看怎么拯救下。目前如果有 filterCon 就先删除 pageCon.filter
          if (options.filterCon?.length > 0 && typeof options.pageCon?.filter === 'string') {
            options.pageCon = { ...options.pageCon };
            console.warn(`数据源 ${this.id} 配置了 pageCon.filter，同时还有 filterCon。已放弃前者`);
            delete options.pageCon.filter;
          }

          const x = await this.wContext.dataManager.getList(options);
          if (x.code !== 200) throw new Error(`${x.code}: ${x.message}`);

          const rawData = x.data.dataList;
          this.pagination.total = x.data.dataCount;
          this.dirtyOldData = cloneDeep(rawData);
          const resolvedData = this.clipper('data', rawData, 'forward', 'list');
          const cleanedData = this.wujiDirtyWorker.cleanData(resolvedData);
          return this.injectActionMethods(cleanedData);
        });
        this.dataBox.value = oldData;
        this.dataBox.promise.then(() => this.$emit('load', this));
        return this.dataBox.promise;
      }

      // 如果没有 objectId，就使用默认值填充
      if (this.isCreate && !this.forceCreateMode) {
        return this.reset();
      }

      // 否则就是正常的载入工作了
      this.dataBox = warpPromise(async () => {
        const x = await this.wContext.dataManager.getDetail({
          ...this.config, ...overrideOptions,
          objectId,
        });
        if (x.code !== 200) throw new Error(`${x.code}: ${x.message}`);

        const rawData = x.data.dataDetail;
        this.dirtyOldData = cloneDeep(rawData);
        const resolvedData = this.clipper('data', rawData, 'forward', 'detail');
        const cleanedData = this.wujiDirtyWorker.cleanData(resolvedData);
        return this.injectActionMethods(cleanedData);
      });
      this.dataBox.value = oldData;
      this.dataBox.promise.then(() => this.$emit('load', this));
      return this.dataBox.promise;
    },

    /**
     * 用于“列表模式”的数据源，提交修改
     *
     * @param {Object} opt
     * @param {number[]} opt.indexes   可选，指定提交哪些序号（0，1，2…）的条目。如果不传，默认全部修改都会被提交
     *                                 【⚠️ 警告】如果发生删除，可能会有问题，这个我们再确认下
     */
    async updateList(opt = {}) {
      // 先准备 newList
      const rawData1 = this.data;   // 准备原始数据
      const rawData2 = this.clipper('data', rawData1, 'backward', 'list');    // 数据修剪器
      let newList = this.wujiDirtyWorker.toDirtyData(rawData2);     // 无极骚操作

      // 然后如果定提交哪些序号。。。。
      if (Array.isArray(opt.indexes)) {
        const newList2 = [...this.dirtyOldData];
        opt.indexes.forEach((i) => {
          newList2[i] = newList[i];
        });
        newList = newList2;
      }

      const ans = await this.wContext.dataManager.updateList({
        ...this.config,
      }, newList, this.dirtyOldData);

      return ans;
    },

    // DM有复制的特殊逻辑处理，可自动忽略数据中的id值，新建数据
    async createSingle(isCopy) {
      const newData = this.data;

      const resolvedData = this.clipper('data', newData, 'backward', 'detail');
      const data = this.wujiDirtyWorker.toDirtyData(resolvedData);
      const ans = await this.wContext.dataManager.updateDetail(
        {
          ...this.config,
        },
        isCopy ? 'copy' : 'create',
        data,
      );
      return ans;
    },

    async updateSingle() {
      const { objectId } = this;
      const newData = this.data;
      if (this.isCreate) throw new Error('当前不是编辑模式，请检查程序');

      const resolvedData = this.clipper('data', newData, 'backward', 'detail');

      const ans = await this.wContext.dataManager.updateDetail({
        ...this.config,
        objectId,
      }, 'edit', this.wujiDirtyWorker.toDirtyData(resolvedData), this.dirtyOldData);

      return ans;
    },

    async upsertSingle() {
      if (this.isCreate) return await this.createSingle(this.forceCreateMode).then((res) => {
        if (res && this.forceCreateMode) this.internalObjectId = res?.data?.id;
        return res;
      });
      return await this.updateSingle();
    },

    async deleteSingle() {
      return await this.delete(this.dirtyOldData);
    },

    // 很刺激的多合一方法
    async upsert() {
      if (this.isListMode) return await this.updateList();
      return await this.upsertSingle();
    },

    /**
     * 单条删除 & 批量删除
     * @param {Array | Object} data 要删除的数据
     */
    async delete(data) {
      if (data) {
        const clipperType = Array.isArray(data) ? 'list' : 'detail';
        const resolvedData = this.clipper('data', data, 'backward', clipperType);
        return await this.wContext.dataManager.updateDetail({
          ...this.config,
        }, 'delete', undefined, resolvedData);
      }
    },

    async dispatch(actionName) {
      const fn = this[actionName];
      if (typeof fn === 'function') return await fn.call(this);
    },
  },
});

export default WujiSourceController;
