

export const XyInnerPageSelectorMate = {
  settingsSchema: null,
  component: () => (
    {
      component: import(/* webpackChunkName: "xyInnerPageSelector" */'./xy-inner-page-selector.vue'),
    }
  ),
  hideLabel: false,
};


export const XyInnerPageletSelectorMate = {
  settingsSchema: null,
  component: () => (
    {
      component: import(/* webpackChunkName: "xyInnerPageletSelector" */'./xy-inner-pagelet-selector.vue'),
    }
  ),
  hideLabel: false,
};


export const XyInnerFlowNodePageletConfigMate = {
  settingsSchema: null,
  component: () => (
    {
      component: import(/* webpackChunkName: "xyInnerFlowNodePageletConfig" */'./xy-inner-flow-node-pagelet-config.vue'),
    }
  ),
  hideLabel: false,
};
