import { pick, cloneDeep, isPlainObject } from 'lodash';
import day from 'dayjs';
import { message } from 'ant-design-vue';

import { getDefaultProjectInfo, getDefaultProjectSettings, formatToSaveProjectInfo, formatToUiProjectInfo, haveCustomProjectSettings } from '@loaders/project/utils';
import projectLoader from '@loaders/project/loader';
import { getCompsList } from '@/utils/comps-loader';
import fetch from '@/utils/fetch';
import wujiFetch from '@/utils/wujiFetch';
import getXyManagePathPrefix from '@utils/getXyManagePathPrefix';
import { addPathPrefix } from '@/utils/path';
import { getUserId } from '@/utils/userInfo';
import { isRemoteProjectChange, mergeProject } from '@/utils/merge/merge-multi';
import { nodeDiff3Object } from '@/utils/merge/textMerge';
import { BASE_API_PATH, designPath } from '@/config/constant';

export const fetchProjectInfo = async (projectId, branchId, isRuntimeRoute = false, envId = 'dev') => {
  const data = await wujiFetch(`${getXyManagePathPrefix({ branchId, isRuntimeRoute, envId })}/project/${projectId}`);
  const projectInfo = formatToUiProjectInfo(data);
  if (!projectInfo.permissionApplyMode) projectInfo.permissionApplyMode = 'all-in-one';
  return projectInfo;
};

const updateProjectInfo = async (projectId, projectInfo) => {
  const apiUrl = `${getXyManagePathPrefix()}/project/${projectId}`;
  return await wujiFetch(apiUrl, {
    method: 'PATCH',
    body: JSON.stringify(formatToSaveProjectInfo(projectInfo)),
  });
};

const updateProjectAppsAndComponentHub = async (projectId, { authAppIds, componentHubGroupsList }) => {
  const apiUrl = `${getXyManagePathPrefix()}/project/${projectId}/apps/componenthub`;
  return await wujiFetch(apiUrl, {
    method: 'PATCH',
    body: JSON.stringify({ authAppIds, componentHubGroupsList }),
  });
};

const allowFields = ['advanceConfig', 'uiInfo', 'lessCodeModules'];
const updateProjectInfoByDev = async (projectId, projectInfo) => {
  const apiUrl = `${getXyManagePathPrefix()}/project/dev/${projectId}`;
  const project = formatToSaveProjectInfo(projectInfo);
  return await wujiFetch(apiUrl, {
    method: 'PATCH',
    body: JSON.stringify(pick(project, allowFields)),
  });
};

const updateEnableOffline = async (projectId) => {
  const url = `${designPath}/auth-offline?projectid=${projectId}`;
  return await wujiFetch(url, {
    method: 'GET',
  });
};

// 对于导入和同步管理台，数据量较大，需要增加超时时间
const timeout = 60000 * 5; // 5分钟

export default {
  state: {
    settings: getDefaultProjectSettings(),
    projectList: [],
    baseProjectInfo: {},
    projectInfo: getDefaultProjectInfo(),
    total: 0,
    enableOffline: false,
  },
  getters: {
  },
  actions: {
    async checkProjectExist({ }, { projectId }) {
      try {
        const url = `${getXyManagePathPrefix({ envId: 'dev' })}/project/${projectId}`;
        // set showMessage to false
        await fetch(addPathPrefix(url), undefined, undefined, false);
        return true;
      } catch (err) {
        if (err.code === 404) return false;
        if (err.code === 403) return true;
        message.error('检测应用ID失败');
        return true;
      }
    },
    // 获取编辑时的应用信息
    async fetchProjectInfo({ commit }, { projectId, branchId }) {
      try {
        const projectInfo = await fetchProjectInfo(projectId, branchId);
        commit('updateProject', {
          projectInfo,
        });
        setTimeout(() => {
          commit('updateProject', {
            baseProjectInfo: cloneDeep(projectInfo), // 作为基准版本用于后续的冲突合并
          });
        });
        return projectInfo;
      } catch (e) {
        console.error(e);
        message.error(`拉取应用数据失败：${e.message}`);
      }
    },
    async fetchProjectInfoById({ commit, dispatch }, { projectId }) {
    // 判断项目是否支持离线化
      try {
        const enableOffline = await updateEnableOffline(projectId);
        commit('updateProject', {
          enableOffline,
        });
        const settings = await dispatch('fetchProjectSettings', { projectId });
        const enabledBranch = settings?.versionSetting?.enabledBranch;

        const params = { projectId };

        if (enabledBranch) {
          const currentBranch = await dispatch('fetchCurrentBranch', { projectId });
          if (currentBranch?.branch) params.branchId = currentBranch.branch;
        } else {
          // 没有开启分支功能是重置分支数据
          commit('resetBranch');
        }

        await dispatch('fetchProjectInfo', params);
      } catch (e) {
        message.error(`切换项目获取数据失败：${e.message}`);
      }
    },
    // 创建应用
    async saveProjectInfo({ dispatch }, { projectInfo }) {
      const newProject = await projectLoader.saveProjectInfo(projectInfo, true);

      // 私有部署扩展的应用偏好设置
      if (newProject?.id && haveCustomProjectSettings()) {
        await dispatch('saveProjectSettings', {
          projectId: newProject.id,
          data: getDefaultProjectSettings(),
        });
      }

      return newProject;
    },
    // 更新应用信息
    async updateProjectInfo({ rootState, state, dispatch }, { muted, ...data }) {
      const branchId = rootState.branch.currentBranch?.branch;
      const { baseProjectInfo } = state;
      const remoteProjectInfo = await fetchProjectInfo(data.id, branchId);
      const { _mtime: mtime } = remoteProjectInfo;
      const { _mtime: currentMtime } = baseProjectInfo;
      const isOutdated = day(mtime).isAfter(currentMtime);
      if (isOutdated) {
        const mergeResult = await nodeDiff3Object(state.baseProjectInfo, data, remoteProjectInfo);
        if (!mergeResult.success) {
          return;
        }
        await updateProjectInfo(data.id, mergeResult.data);
      } else {
        await updateProjectInfo(data.id, data);
      }
      if (!muted) message.success('保存成功');
      await dispatch('fetchProjectInfo', { projectId: data.id });
    },
    // 校验合并 + 更新应用信息(仅更新开发者允许修改的字段)
    async mergeAndUpdateProjectInfoByDev({ dispatch }, project) {
      return await dispatch('mergeAndUpdateProject', { project, isUpdateByDev: true });
    },
    // 校验合并 + 更新应用信息
    async mergeAndUpdateProjectInfo({ dispatch }, project) {
      return await dispatch('mergeAndUpdateProject', { project });
    },
    // 更新应用数据库授权&组件库授权
    async updateProjectAuthRelated({ dispatch }, { projectId, authAppIds, componentHubGroupsList }) {
      await updateProjectAppsAndComponentHub(projectId, { authAppIds, componentHubGroupsList });
      await dispatch('fetchProjectInfo', { projectId });
    },
    /**
     * 更新应用信息 不含三路合并
     */
    async updateProject({ dispatch, state }, { projectId, projectInfo, isUpdateByDev }) {
      isUpdateByDev
        ? await updateProjectInfoByDev(projectId, projectInfo)
        : await updateProjectInfo(projectId, projectInfo);

      // todo
      // dispatch('saveProjectRecord', { projectId, local: data, remote: remoteProjectInfo });

      await dispatch('fetchProjectInfo', { projectId });

      return {
        result: state.projectInfo,
      };
    },

    async mergeAndUpdateProject({ rootState, state, dispatch }, { project: localProjectInfo, isUpdateByDev }) {
      try {
        const branchId = rootState.branch.currentBranch?.branch;
        const { baseProjectInfo } = state;
        const remoteProjectInfo = await fetchProjectInfo(localProjectInfo.id, branchId);

        let data = localProjectInfo;
        let merged = false;
        if (isRemoteProjectChange({ base: baseProjectInfo, remote: remoteProjectInfo })) {
          const result = await mergeProject({
            local: localProjectInfo,
            base: state.baseProjectInfo,
            remote: remoteProjectInfo,
          });
          if (result.success) {
            merged = true;
            data = result.data;
          } else {
            throw new Error('合并应用修改冲突失败, 取消保存');
          }
        }

        isUpdateByDev ? await updateProjectInfoByDev(data.id, data) : await updateProjectInfo(data.id, data);
        if (merged) {
          message.success('保存成功 (已合并本地落后的远程修改)');
        } else {
          message.success('保存成功');
        }

        dispatch('saveProjectRecord', { projectId: data.id, local: data, remote: remoteProjectInfo });

        await dispatch('fetchProjectInfo', { projectId: data.id });

        return {
          merged,
          result: state.projectInfo,
        };
      } catch (err) {
        message.error(err.message);
      }
    },
    async fetchProjectList({ commit }, { search, size, page } = {}) {
      try {
        let searchStr = '';
        if (typeof search === 'string') {
          searchStr = search;
        } else if (isPlainObject(search)) {
          searchStr = Object.entries(search)
            .map(([key, value]) => {
              if (!value) return '';
              return `${key}%%"${value}"`;
            })
            .filter(v => v)
            .join('&');
        }
        const query = {
          size: size || 'total',
          sort: 'ctime',
          order: 'desc',
          page,
        };
        const projects = await projectLoader.getProjectList({
          ...query,
          filter: searchStr,
        });
        commit('updateProject', {
          projectList: projects,
        });
        if (size && page === 1) {
          const { total } = await projectLoader.getProjectList({
            filter: searchStr,
            count: 1,
          });
          commit('updateProject', {
            total,
          });
        }
      } catch (err) {
        console.error('err', err);
      }
    },
    async fetchComponents() {
      await getCompsList({ compsKey: ['public-w-upload-file', 'public-w-dept-rtx-select', 'public-w-wujie'], register: true });
    },
    async fetchProjectSettings({ commit, rootState }, { projectId }) {
      try {
        const proId = projectId || rootState.project.projectInfo?.id;
        let data = await wujiFetch(`${BASE_API_PATH}/xy/setting?projectid=${proId}`);
        const defaultSetting = getDefaultProjectSettings();
        data = {
          ...(defaultSetting ?? {}),
          ...(data ?? {}),
          versionSetting: {
            ...(defaultSetting?.versionSetting ?? {}),
            ...(data?.versionSetting ?? {}),
          },
          permissionSetting: {
            ...(defaultSetting?.permissionSetting ?? {}),
            ...(data?.permissionSetting ?? {}),
          },
        };
        commit('updateProjectSetting', data);
        return data;
      } catch (err) {
        message.error(`获取应用设置失败:${err.message}`);
      }
    },
    async fetchRuntimePermissionSettings({ rootState }, { projectId }) {
      try {
        const proId = projectId || rootState.project.projectInfo?.id;
        let data = await wujiFetch(`${BASE_API_PATH}/xy/runtime/setting/permission?projectid=${proId}`);
        const defaultSetting = getDefaultProjectSettings();
        data = {
          permissionSetting: {
            ...(defaultSetting?.permissionSetting ?? {}),
            ...(data?.permissionSetting ?? {}),
          },
        };
        return data;
      } catch (err) {
        message.error(`获取权限设置失败:${err.message}`);
      }
    },
    async saveProjectSettings({ dispatch }, { projectId, data }) {
      await wujiFetch(`${BASE_API_PATH}/xy/setting?projectid=${projectId}`, {
        method: 'POST',
        body: JSON.stringify(data),
      });
      return await dispatch('fetchProjectSettings', { projectId });
    },
    async importProject({ }, data) {
      return await wujiFetch(`${getXyManagePathPrefix({ envId: 'dev' })}/project/import`, {
        method: 'POST',
        body: JSON.stringify(data),
        timeout,
      });
    },
    async copyProject({ }, { projectId, data, options }) {
      return await wujiFetch(`${getXyManagePathPrefix({ envId: 'dev' })}/project/copy/${projectId}`, {
        method: 'POST',
        body: JSON.stringify({
          data,
          options,
        }),
        timeout,
      });
    },
    async forkProject({ }, { projectId, data, options }) {
      return await wujiFetch(`${getXyManagePathPrefix({ envId: 'dev' })}/project/fork/${projectId}`, {
        method: 'POST',
        timeout,
        body: JSON.stringify({
          data,
          options,
        }),
      });
    },
    // 上传文件同步到当前 projectId, 或线上应用 onlineProjectId 同步到当前 projectId
    async syncProject({ }, { projectId, data, authMapping, onlineProjectId = '' }) {
      const bodyData = { authMapping, data };
      if (onlineProjectId) bodyData.onlineProjectId = onlineProjectId;
      return await wujiFetch(`${getXyManagePathPrefix({ envId: 'dev' })}/project/sync/${projectId}`, {
        method: 'POST',
        body: JSON.stringify(bodyData),
        timeout,
      });
    },
    async fetchProjectStorageInfo({ }, { projectId }) {
      return await wujiFetch(`${getXyManagePathPrefix({ envId: 'dev' })}/project/export/${projectId}?projectid=${projectId}`, {
        timeout,
      });
    },
    async diffProjectInfo({ }, { projectId, targetProjectId, data }) {
      return await wujiFetch(`${getXyManagePathPrefix({ envId: 'dev' })}/project/diff${targetProjectId ? `/${targetProjectId}` : ''}?projectid=${projectId}`, {
        timeout,
        method: data ? 'POST' : 'GET',
        body: data ? JSON.stringify({
          data,
        }) : null,
      });
    },
  },
  mutations: {
    updateProject(state, payload) {
      Object.assign(state, payload);
    },
    updateProjectSetting(state, settings) {
      state.settings = settings;
    },
    resetProjectSettings(state) {
      state.settings = getDefaultProjectSettings();
    },
    resetProjectInfo(state) {
      const project = {
        ...getDefaultProjectInfo(),
        admin: getUserId(),
      };
      state.projectInfo = project;
      state.baseProjectInfo = cloneDeep(project);
    },
  },
};
