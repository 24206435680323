import logger from '../logger';

export const compsLoaderLogger = (opts) => {
  logger.info(`%c [compsloader][${opts.functionName}]${opts.content}`, 'color: blue');
};

export const logInstalledComponent = (compsMap = {}) => {
  const res = {};
  Object.entries(compsMap).forEach(([key, value]) => {
    res[key] = {
      id: key,
      version: value.compDB.version,
      group: value.compDB.group || '',
      registry: value.compDB.registry,
    };
  });
  window.INSTALLED_COMPONENT = res;
};
