<template>
  <div />
</template>

<script>
export default {
  created() {
    // 会自动拉起智能网关登录，登录后会跳转回该页面，该页面控制关闭即可
    const data = {
      event: 'login',
    };

    if (window.opener && !window.opener.closed) {
      window.opener.postMessage(data);
    }

    window.close();
  },
};
</script>
