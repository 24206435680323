import { formatObjectToJSON } from '@utils/common';
import { openConflictModal } from '@components/merge/index';
import { merge } from 'node-diff3';
import { message } from 'ant-design-vue';
import { omit } from 'lodash';
export const OMIT_COMPARE_FIELDS = ['_ctime', 'creator', '_mtime', '_id', 'modifier', 'ctime', 'mtime'];
/**
 * 三路合并json对象(带冲突合并UI)
 */
export const nodeDiff3Object = async (baseObj, localStr, remoteStr) => {
    const base = JSON.stringify(omit(baseObj, OMIT_COMPARE_FIELDS), null, '\t');
    const local = JSON.stringify(omit(localStr, OMIT_COMPARE_FIELDS), null, '\t');
    const remote = JSON.stringify(omit(remoteStr, OMIT_COMPARE_FIELDS), null, '\t');
    return nodeDiff3Text(base, remote, local, { type: 'json' });
};
/**
 * 三路合并文本(带冲突合并UI)
 */
export const nodeDiff3Text = async (base, remote, local, options) => {
    const { showTip = true, type = 'json', remainEmptyLine, showMerged } = options || {};
    const format = (text) => {
        if (remainEmptyLine) {
            return text?.split('\n');
        }
        return text;
    };
    const result = merge(format(local), format(base), format(remote), {
        stringSeparator: /[\r\n]+/,
    });
    if (!result.conflict) {
        showTip && message.info('已自动合并本地落后的远程修改');
        const data = type === 'json' ? JSON.parse(result.result.join('\n')) : result.result.join('\n');
        if (showMerged) {
            return await openConflictModal({
                result,
                baseData: base,
                remoteData: remote,
                localData: local,
                type: options?.type,
            });
        }
        return {
            success: true,
            data,
        };
    }
    showTip && message.warning('当前版本和最新版本有冲突，请解决冲突后进行保存');
    result.result = result.result.map((item) => {
        if (item === '<<<<<<<') {
            return `<<<<<<< ${options?.currentLabel || '当前版本'}`;
        }
        if (item === '>>>>>>>') {
            return `>>>>>>> ${options?.remoteLabel || '最新版本'}`;
        }
        return item;
    });
    const conflictSolveResult = await openConflictModal({
        result,
        baseData: base,
        remoteData: remote,
        localData: local,
        type: options?.type,
    });
    if (!conflictSolveResult.success) {
        return conflictSolveResult;
    }
    return {
        success: true,
        data: conflictSolveResult.data,
    };
};
export const getDiff3MergeData = (o, a, b) => {
    const formatter = (data) => formatObjectToJSON(data);
    const base = formatter(o);
    const local = formatter(a);
    const remote = formatter(b);
    const mergeResult = merge(local, base, remote, {
        stringSeparator: /[\r\n]+/,
    });
    return {
        data: mergeResult.result.join('\n'),
        conflict: mergeResult.conflict,
    };
};
