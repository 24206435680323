<template>
  <div
    ref="xy-modal-container-design-wrap"
    class="xy-modal-container-design-wrap"
  >
    <component
      :is="componentType"
      :class="`xy-${renderType || 'modal'}-container-design`"
      :visible="true"
      :get-container="getContainer"
      :closable="withClosableIcon"
      style="will-change: opacity"
      v-bind="componentBind"
    >
      <template v-if="isShowTitle">
        <!-- 自定义标题 -->
        <div
          v-if="isTitleSlot"
          class="xy-modal-container-title"
        >
          <slot name="title">
            <div :class="`xy-modal-container-title-placeholder`">
              <span>[标题] 请放置组件</span>
            </div>
          </slot>
        </div>
        <!-- 默认标题 -->
        <div
          v-else
          class="xy-modal-container-title-default"
        >
          <div class="xy-modal-container-title-text">
            {{ title }}
          </div>
        </div>
      </template>
      <div :class="contentClass">
        <slot>
          <div class="xy-modal-container-content-placeholder">
            <span>[内容] 请放置组件</span>
          </div>
        </slot>
      </div>
      <div
        v-if="withFooter"
        class="xy-modal-container-footer"
      >
        <slot name="footer">
          <div :class="`xy-modal-container-footer-placeholder`">
            <span>[页脚] 请放置组件</span>
          </div>
        </slot>
      </div>
    </component>
  </div>
</template>
<script>
/* eslint-disable no-param-reassign */
import { computed, toRefs } from '@vue/composition-api';
import { useMobile } from '@/composables/useAdaptation';
import SchemaDefault from './xy-modal-schema-default.json';
import { getDefaultSlots, getDefaultTitleSlot } from './index';

export default {
  name: 'XyModalContainerDesign',
  inject: {
    designer: { from: 'uicore:designer' },
  },
  props: {
    title: {
      type: String,
      default: SchemaDefault.title,
    },
    withTitle: {
      type: Boolean,
      default: SchemaDefault.withTitle,
    },
    isTitleSlot: {
      type: Boolean,
      default: SchemaDefault.isTitleSlot,
    },
    type: {
      type: String,
      default: SchemaDefault.type,
    },
    width: {
      type: String,
      default: SchemaDefault.width,
    },
    height: {
      type: String,
      default: SchemaDefault.height,
    },
    drawerPlacement: {
      type: String,
      default: SchemaDefault.drawerPlacement,
    },
    withFooter: {
      type: Boolean,
      default: SchemaDefault.withFooter,
    },
    withDefaultFooter: {
      type: Boolean,
      default: SchemaDefault.withDefaultFooter,
    },
    withClosableIcon: {
      type: Boolean,
      default: SchemaDefault.withClosableIcon,
    },
  },
  setup(props) {
    const { type } = toRefs(props);
    const { isMobile } = useMobile();

    const renderType = computed(() => {
      if (type.value === 'adaptation') {
        return isMobile.value ? 'drawer' : 'modal';
      }
      return type.value;
    });

    return {
      renderType,
    };
  },
  computed: {
    // 是否显示标题
    isShowTitle() {
      return this.withTitle && (this.isTitleSlot || this.title);
    },
    // 组件类型
    componentType() {
      return this.renderType === 'modal' ? 'a-modal' : 'a-drawer';
    },
    // 组件绑定值
    componentBind() {
      const bind = {
        class: ['w-sys-modal'],
      };
      const { renderType, drawerPlacement } = this;
      let { width, height } = this;

      /* 兼容 width 和 height 为数字的旧数据 */
      if (typeof width !== 'string') width = `${width}`;
      if (typeof height !== 'string') height = `${height}`;

      /* 编辑时根据配置修改样式 */
      if (renderType === 'drawer' && ['top', 'bottom'].includes(drawerPlacement)) {
        if (height.endsWith('vw')) {
          bind.height = height.replace('vw', '%');
        } else {
          bind.height = height;
        }
      } else {
        if (width.endsWith('vw')) {
          bind.width = width.replace('vw', '%');
        } else {
          bind.width = width;
        }
      }

      if (renderType === 'drawer') {
        bind.placement = drawerPlacement;
        bind.bodyStyle = { padding: '0px' };
      } else {
        bind.footer = null;
      }

      return bind;
    },
    contentClass() {
      const { renderType, drawerPlacement, withFooter } = this;
      const contentClass = ['xy-modal-container-content'];

      if (renderType === 'drawer') {
        contentClass.push(`placement-${drawerPlacement}`);
        contentClass.push(withFooter ? 'with-footer' : 'without-footer');
      }

      return contentClass;
    },
  },
  watch: {
    /* 兼容旧弹窗没有 slots.title 的情况, 防止切换自定义标题时标题为空的问题 */
    isTitleSlot: {
      handler(isTitleSlot) {
        if (isTitleSlot) {
          try {
            const { designer } = this;
            designer.modifyComp('', (comp) => {
              if (comp?.slots && !comp.slots.title) {
                const layout = designer.getLayoutAtPath('');
                const propsTitle = layout?.props?.title;
                comp.slots.title = getDefaultTitleSlot(propsTitle);
              }
            });
          } catch (err) {
            console.error('[xy-modal-container-design]watch.isTitleSlot.error', err);
          }
        }
      },
    },
  },
  mounted() {
    try {
      // [兼容] withDefaultFooter 设置为 true 的情况, 替换默认的弹窗页脚
      const { designer } = this;
      const layout = designer.getLayoutAtPath('');
      if (layout?.props?.withDefaultFooter) {
        designer.modifyComp('', (comp) => {
          delete comp.props.withDefaultFooter;
          comp.slots = getDefaultSlots();
        });
      }
    } catch (err) {
      console.error('[xy-modal-container-design]mounted.error', err);
    }
  },
  methods: {
    getContainer() {
      return this.$refs['xy-modal-container-design-wrap'];
    },
  },
};
</script>

<style lang="scss">
.xy-modal-container-design-wrap {
  position: relative;
  background-color: rgba(0, 0, 0, 0.45);

  &[data-uc-design-mode] {
    min-height: 100%;
  }

  // 弹窗
  .xy-modal-container-design {
    .ant-modal-mask {
      position: absolute;
      z-index: 100;
    }
    .ant-modal-wrap {
      position: relative;
      z-index: 100;
    }

    .ant-modal {
      top: 0;
      padding: 12px 0;
      /* 防止设置超过视窗宽高 */
      max-width: 100%;
    }

    .ant-modal-body {
      padding: 0;
    }
  }

  // 抽屉
  .xy-drawer-container-design {
    position: absolute;
    .ant-drawer-content-wrapper {
      .ant-drawer-body {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .xy-modal-container-title,
        .xy-modal-container-title-default {
          flex-shrink: 0;
        }
        .xy-modal-container-content {
          flex: 1;
          overflow: auto;
        }
        .xy-modal-container-footer {
          flex-shrink: 0;
        }
      }
    }

    /* 防止设置超过视窗宽高 */
    &.ant-drawer-right,
    &.ant-drawer-left {
      .ant-drawer-content-wrapper {
        max-width: 100%;
      }
    }
    &.ant-drawer-bottom,
    &.ant-drawer-top {
      .ant-drawer-content-wrapper {
        max-height: 100%;
      }
    }
  }

  /* 弹窗相关变量 */
  --wuji-modal-header-padding: 16px 24px;
  --wuji-modal-header-border-bottom: 1px solid #e8e8e8;
  --wuji-modal-body-padding: 24px;
  --wuji-modal-footer-padding: 10px 16px;
  --wuji-modal-footer-border-top: 1px solid #e8e8e8;
  // 合并样式
  .xy-modal-container-design,
  .xy-drawer-container-design {
    // 自定义标题的样式
    .xy-modal-container-title {
      padding: var(--wuji-modal-header-padding);
      border-bottom: var(--wuji-modal-header-border-bottom);
      .xy-modal-container-title-placeholder {
        width: 100%;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999;
        border: 1px dashed;
      }
    }
    // 默认标题的样式
    .xy-modal-container-title-default {
      padding: var(--wuji-modal-header-padding);
      border-bottom: var(--wuji-modal-header-border-bottom);
      .xy-modal-container-title-text {
        margin: 0;
        color: #161616;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        word-wrap: break-word;
      }
    }
    .xy-modal-container-content {
      padding: var(--wuji-modal-body-padding);
      overflow: auto;
      .xy-modal-container-content-placeholder {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999;
        border: 1px dashed;
      }
    }

    .xy-modal-container-footer {
      padding: var(--wuji-modal-footer-padding);
      border-top: var(--wuji-modal-footer-border-top);
      .xy-modal-container-footer-placeholder {
        width: 100%;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999;
        border: 1px dashed;
      }
    }
  }
}
</style>
