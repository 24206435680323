export default {
  state: {
    // 全局搜索输入框缓存
    globalSearchInputTemp: '',
  },
  mutations: {
    setGlobalSearchInputTemp(state, inputTemp) {
      state.globalSearchInputTemp = inputTemp;
    },
  },
};
