<template>
  <div class="not-found">
    <div class="content">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg"
        alt=""
      >
      <div class="explain">
        <h1 class="title">
          404
        </h1>
        <div class="message">
          你访问的页面貌似不存在？
        </div>
        <a-button type="primary">
          <router-link to="/">
            返回首页
          </router-link>
        </a-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.not-found {
  position: relative;
  height: 100%;
  .content {
    overflow: hidden;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
    img {
      display: inline-block;
      vertical-align: top;
      font-size: 0px;
    }
    .explain {
      display: inline-block;
      padding-top: 60px;
      .title {
        color: #434e59;
        font-size: 72px;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 20px;
      }
      .message {
        line-height: 28px;
        margin-bottom: 16px;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}
</style>
