import { message } from 'ant-design-vue';
import { ref } from '@vue/composition-api';
import PageTagService from '@/services/pageTag';
export default function usePageTag(projectId) {
    const tagList = ref([]);
    const basicTagList = ref([]);
    const getTagList = async () => {
        try {
            tagList.value = await PageTagService.getTagList(projectId.value);
        }
        catch (err) {
            message.error(`获取页面标签列表失败:${err.message}`);
        }
    };
    const getBasicTagList = async () => {
        try {
            basicTagList.value = await PageTagService.getBasicTagList(projectId.value);
        }
        catch (err) {
            message.error(`获取页面标签列表失败:${err.message}`);
        }
    };
    const createTag = async ({ name }) => {
        try {
            await PageTagService.createTag(projectId.value, { name });
            getTagList();
        }
        catch (err) {
            message.error(`创建标签失败：${err.message}`);
        }
    };
    const deleteTag = async (id) => {
        try {
            await PageTagService.deleteTag(projectId.value, id);
            getTagList();
        }
        catch (err) {
            message.error(`删除标签失败:${err.message}`);
        }
    };
    const updateTag = async ({ id, name }) => {
        try {
            await PageTagService.updateTag(projectId.value, id, { name });
            getTagList();
        }
        catch (err) {
            message.error(`更新标签失败:${err.message}`);
        }
    };
    return {
        tagList,
        getTagList,
        basicTagList,
        getBasicTagList,
        createTag,
        deleteTag,
        updateTag,
    };
}
