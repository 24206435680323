/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/prefer-for-of */
/**
 * 复用应用宝的导出组件代码
 */
import { downloadFile } from './exportExcel';
import { cloneDeep, fromPairs, get, isObject } from 'lodash';
import { loadXLSX } from './loadUtilScripts';
import logger from './logger';
import { message } from 'ant-design-vue';
let XLSX;
// 单元格的默认样式
const DEFAULT_CELL_STYLE = {
    font: {
        name: 'MicrosoftYaHei',
        sz: 12,
    },
    alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true,
    },
};
// 单元格的默认列宽（wch单位的含义是一行中能够容纳的最大字符数）
const DEFAULT_CELL_COL_WIDTH = {
    wch: 12,
};
/**
 * 导出EXCEL的主体函数（sheetJS实现）
 * @param {Array[Object]} rawData 原始JSON格式数据
 * @param {Object} fileMetaData 导出excel文件的元信息（文件名，表名等）
 * @param {Function} callback 导出结束时执行的回调函数（这里的作用是解除按钮的loading效果）
 */
export default async function exportExcel(rawData, fileMetaData, callback) {
    await makeExcelFile(fileMetaData, (wb) => {
        // 在WorkBook中定义一个新的WorkSheet
        wb.SheetNames.push(fileMetaData.sheetName);
        const ws = getSheet(rawData, fileMetaData.fields ?? []);
        // 部署配置好的WorkSheet
        wb.Sheets[fileMetaData.sheetName] = ws;
    }, callback);
}
export async function exportExcels(sheets, fileMetaData, callback) {
    await makeExcelFile(fileMetaData, (wb) => {
        sheets.forEach((sheet) => {
            // 在WorkBook中定义一个新的WorkSheet
            wb.SheetNames.push(sheet.sheetName);
            const ws = getSheet(sheet.data, sheet.fields ?? []);
            // 部署配置好的WorkSheet
            wb.Sheets[sheet.sheetName] = ws;
        });
    }, callback);
}
async function makeExcelFile(fileMetaData, cb, done) {
    if (!XLSX) {
        XLSX = await loadXLSX();
    }
    try {
        const wb = XLSX.utils.book_new();
        // 定义WorkBook属性
        wb.Props = {
            Title: fileMetaData.fileName,
            Subject: 'Excel',
            Author: fileMetaData.author,
            Comments: 'This is a file generated by wuji',
            CreatedDate: new Date(),
        };
        cb(wb);
        // 生成可导出的Blob文件
        const fileBlob = generateFileBlob(wb, fileMetaData.format);
        // 导出EXCEL文件
        downloadFile(`${fileMetaData.fileName}.${fileMetaData.format}`, fileBlob);
        done?.();
    }
    catch (error) {
        message.error(`导出失败: ${get(error, 'message', '未知错误')}`);
        logger.error('Error in export excel[xlsx]: ', error);
    }
}
export function getFieldsArray(rawData, fields) {
    // 只保留需要导出的字段
    let newData = [];
    if (rawData.length > 0) {
        newData = rawData.map(item => fromPairs(fields.map(f => [f.colName, get(item, f.dataKey)])));
    }
    else {
        // 但是数据为空时, Mock 一条空的记录导出字段列
        newData = [fromPairs(fields.map(f => [f.colName, null]))];
    }
    return newData;
}
function getSheet(rawData, fields) {
    // 只保留需要导出的字段
    const newData = getFieldsArray(rawData, fields);
    // 从JSON格式数据中提取合并规则和aoa(array of array)格式数据
    const mergeRules = getMergeRules(newData);
    const columnArray = generateColumnArrayFromMergeRule(mergeRules);
    const columnMergeArray = generateMergeArrayFromMergeRules(mergeRules);
    const { contentArray, contentMergeArray } = getContentArray(newData, columnArray.length);
    // 最终用于sheetJS生成excel文件的aoa数组
    const excelFileArray = columnArray.concat(contentArray);
    const excelMergeArray = columnMergeArray.concat(contentMergeArray);
    // 向WorkSheet中填充aoa格式的数据
    let ws = XLSX.utils.aoa_to_sheet(excelFileArray);
    // 设置单元格合并规则
    ws['!merges'] = excelMergeArray;
    logger.info(excelMergeArray);
    // 设置单元格样式（包含横向合并单元格的文本居中对齐）
    ws = setCellStyle(ws, mergeRules, excelFileArray /* , fileMetaData.bordered */);
    // 设置单元格行高和列宽
    const colWidthArray = getColWidthArray(fields);
    ws['!cols'] = colWidthArray;
    return ws;
}
/**
 * 从原始JSON格式数据中提炼出单元格合并规则
 * 返回的单元格合并规则数据结构
 * {
 * -- depth: 列的合并深度
 * -- children: 所有子列的数组
 * }
 * @param {Array[Object]} data  原始JSON格式数据
 * @returns {Object} mergeRules 单元格合并规则
 */
function getMergeRules(data) {
    try {
        const mergeRules = {};
        if (!data) {
            return mergeRules;
        }
        if (data.length === 0) {
            logger.warn('Warning in w-excel[getMergeRules]: input data length = 0');
            return mergeRules;
        }
        const objKeysArray = Object.keys(data[0]);
        let maxDepth = 1;
        for (let i = 0; i < objKeysArray.length; ++i) {
            let currentDepth = 1;
            if (!Array.isArray(data[0][objKeysArray[i]])) {
                mergeRules[objKeysArray[i]] = {
                    depth: currentDepth,
                    children: [],
                };
                continue;
            }
            if (!isObject(data[0][objKeysArray[i]][0])) {
                mergeRules[objKeysArray[i]] = {
                    depth: currentDepth,
                    children: [],
                };
                continue;
            }
            // 后续对于层层嵌套的单元格是否需要使用递归完成下列逻辑
            currentDepth += 1;
            maxDepth = Math.max(currentDepth, maxDepth);
            const childObjKeysArray = Object.keys(data[0][objKeysArray[i]][0]);
            const childrenArray = [];
            for (let j = 0; j < childObjKeysArray.length; ++j) {
                childrenArray.push(childObjKeysArray[j]);
            }
            mergeRules[objKeysArray[i]] = {
                depth: currentDepth,
                children: childrenArray,
            };
        }
        // Here, $ is used to represent private attribute
        mergeRules.$maxDepth = maxDepth;
        return mergeRules;
    }
    catch (error) {
        logger.error('Error in w-excel[xlsx getMergeRule]: ', error);
    }
}
/**
 * 根据单元格合并规则生成列的数组
 * @param {Object} mergeRules  合并规则
 */
function generateColumnArrayFromMergeRule(mergeRules) {
    const columnArray = [];
    for (let depth = 0; depth < mergeRules.$maxDepth; ++depth) {
        columnArray.push([]);
    }
    const firstLayerKeyArray = Object.keys(mergeRules).filter(key => key !== '$maxDepth');
    for (let i = 0; i < firstLayerKeyArray.length; ++i) {
        const childrenColNum = mergeRules[firstLayerKeyArray[i]].children.length;
        columnArray[0].push(firstLayerKeyArray[i]);
        // 对于无children列的情况，需要添加纵向null占位
        if (childrenColNum === 0) {
            for (let j = 1; j < mergeRules.$maxDepth; ++j) {
                columnArray[j].push(null);
            }
        }
        // 对于包含两个及以上children的column，添加null占位
        if (childrenColNum > 0) {
            for (let j = 1; j < childrenColNum; ++j) {
                columnArray[0].push(null);
            }
            // 当前只考虑maxDepth为2的情况，更深层嵌套合并算法需要继续研究
            for (let j = 0; j < childrenColNum; ++j) {
                columnArray[1].push(mergeRules[firstLayerKeyArray[i]].children[j]);
            }
        }
    }
    return columnArray;
}
/**
 * 根据单元格合并规则生成可用于sheetJS的merge数组
 * @param {Object} mergeRules   合并规则
 */
function generateMergeArrayFromMergeRules(mergeRules) {
    const mergeArray = [];
    const firstLayerKeyArray = Object.keys(mergeRules).filter(key => key !== '$maxDepth');
    let currentCol = 0;
    for (let i = 0; i < firstLayerKeyArray.length; ++i, ++currentCol) {
        const childrenColNum = mergeRules[firstLayerKeyArray[i]].children.length;
        // 对于无children列的情况，需要添加纵向合并
        if (childrenColNum === 0) {
            mergeArray.push(formatMergeObj(0, currentCol, mergeRules.$maxDepth - 1, currentCol));
        }
        // 对于包含两个及以上children的column，添加横向合并
        if (childrenColNum > 1) {
            mergeArray.push(formatMergeObj(0, currentCol, 0, currentCol + childrenColNum - 1));
            currentCol += childrenColNum - 1;
        }
    }
    return mergeArray;
}
/**
 * 抽取出所有需要横向合并的单元格的集合，以便于后面设置这些单元格的文本居中对齐样式
 * @param {Array[Object]} mergeRules 单元格合并规则
 * @returns {Array[Object]} horizontalMergedCellArray 所有需要横向合并的单元格的集合
 */
function getHorizontalMergedCellArray(mergeRules) {
    const horizontalMergedCellArray = [];
    const firstLayerKeyArray = Object.keys(mergeRules).filter(key => key !== '$maxDepth');
    let currentCol = 0;
    for (let i = 0; i < firstLayerKeyArray.length; ++i, ++currentCol) {
        const childrenColNum = mergeRules[firstLayerKeyArray[i]].children.length;
        // 对于包含两个及以上children的column，添加横向合并
        if (childrenColNum > 1) {
            horizontalMergedCellArray.push({
                r: 0,
                c: currentCol,
            });
            currentCol += childrenColNum - 1;
        }
    }
    return horizontalMergedCellArray;
}
/**
 * 返回sheetJS可识别的merge数组的工厂
 * @param {Number} startRow   起始行
 * @param {Number} startCol   起始列
 * @param {Number} endRow     终止行
 * @param {Number} endCol     终止列
 * @returns {Object} mergeObj 可被sheetJS识别的合并对象
 */
function formatMergeObj(startRow, startCol, endRow, endCol) {
    return {
        s: {
            r: startRow,
            c: startCol,
        },
        e: {
            r: endRow,
            c: endCol,
        },
    };
}
/**
 * 将JSON类型的数据转化为aoa（嵌套数组）类型的数据
 * @param {Array[Object]} data 原始JSON类型的数据
 * @param {Number} contentStartRowNum 数据条目从第几行开始，即列所占的行数，仅用于生成mergeRule，contentArray不受影响
 * @returns {Object} { contentArray {Array} (数据aoa形式), contentMergeArray {Array[Object]} (数据合并规则)  }
 */
function getContentArray(data, contentStartRowNum) {
    let contentArray = [];
    let contentMergeArray = [];
    if (!data) {
        return {
            contentArray,
            contentMergeArray,
        };
    }
    // startRow变量表示该data record的起始行数
    let startRow = 0;
    for (let recordNum = 0; recordNum < data.length; ++recordNum) {
        let startCol = 0;
        const firstLayerKeyArray = Object.keys(data[recordNum]);
        // getMaxContentDepth
        const maxContentDepth = getMaxContentDepth(data[recordNum]);
        // push corresponding number of rows to the content array
        for (let depth = 0; depth < maxContentDepth; ++depth) {
            contentArray.push([]);
        }
        // getContent
        for (let objKeyIndex = 0; objKeyIndex < firstLayerKeyArray.length; ++objKeyIndex) {
            if (!Array.isArray(data[recordNum][firstLayerKeyArray[objKeyIndex]])) {
                const cloneOutput = handleNonArrayContent(contentArray, contentMergeArray, data[recordNum][firstLayerKeyArray[objKeyIndex]], contentStartRowNum, maxContentDepth, startRow, startCol);
                contentArray = cloneOutput.contentArray;
                contentMergeArray = cloneOutput.contentMergeArray;
                startCol += 1;
                continue;
            }
            const arrayContent = data[recordNum][firstLayerKeyArray[objKeyIndex]];
            let totalHorizontalItemNum = 0;
            for (let currentDepth = 0; currentDepth < maxContentDepth; ++currentDepth) {
                if (currentDepth < arrayContent.length) {
                    const subContentObj = arrayContent[currentDepth];
                    if (isObject(subContentObj)) {
                        totalHorizontalItemNum = Object.keys(subContentObj).length;
                        for (let subKeyIndex = 0; subKeyIndex < totalHorizontalItemNum; ++subKeyIndex) {
                            contentArray[startRow + currentDepth]
                                .push(subContentObj[Object.keys(subContentObj)[subKeyIndex]]);
                        }
                    }
                    else {
                        contentArray[startRow + currentDepth].push(arrayContent.join(','));
                    }
                    continue;
                }
                for (let currentHorizontalItemIndex = 0; currentHorizontalItemIndex < totalHorizontalItemNum; ++currentHorizontalItemIndex) {
                    contentArray[startRow + currentDepth].push(null);
                }
            }
            startCol += totalHorizontalItemNum;
        }
        startRow += maxContentDepth;
    }
    return {
        contentArray,
        contentMergeArray,
    };
}
/**
 * 获取数据最大深度
 * @param {Object} rawData 数据
 * @returns {Number} maxContentDepth 最大深度
 */
function getMaxContentDepth(rawData) {
    if (!rawData) {
        logger.error('Error in w-excel[xlsx getMaxContentDepth]: rawData is falsy');
    }
    let maxContentDepth = 1;
    Object.keys(rawData).forEach((key) => {
        const value = rawData[key];
        if (Array.isArray(value) && value.some(item => isObject(item))) {
            maxContentDepth = Math.max(maxContentDepth, value.length);
        }
    });
    return maxContentDepth;
}
/**
 * 为降低getContentArray函数的圈复杂度，将非array数据的情况单独提取出来成一个函数
 * @param {Array} contentArray
 * @param {Array} contentMergeArray
 * @param {String} dataToPush
 * @param {Number} contentStartRowNum
 * @param {Number} maxContentDepth
 * @param {Number} startRow
 * @param {Number} startCol
 */
function handleNonArrayContent(contentArray, contentMergeArray, dataToPush, contentStartRowNum, maxContentDepth, startRow, startCol) {
    contentArray[startRow].push(dataToPush);
    // 列中其他的单元格添加null占位
    for (let currentDepth = 1; currentDepth < maxContentDepth; ++currentDepth) {
        contentArray[startRow + currentDepth].push(null);
    }
    // 新增merge对象
    if (maxContentDepth > 1) {
        contentMergeArray.push(formatMergeObj(contentStartRowNum + startRow, startCol, contentStartRowNum + startRow + maxContentDepth - 1, startCol));
    }
    return {
        contentArray,
        contentMergeArray,
    };
}
/**
 * 给EXCEL文件单元格设置样式
 * @param {Object} ws WorkSheet对象
 * @param {Array[Object]} mergeRules 单元格合并规则（用于生成需要横向合并的单元格集合）
 * @param {Array[Array]} excelFileArray 结合列和数据内容的aoa数组
 * @returns {Object} cloneWS 克隆并设置好样式后的WorkSheet
 */
function setCellStyle(ws, mergeRules, excelFileArray) {
    try {
        const cloneWS = cloneDeep(ws);
        if (Object.keys(mergeRules).length === 0) {
            return cloneWS;
        }
        const totalRowNum = excelFileArray.length;
        const totalColNum = excelFileArray[0].length;
        for (let row = 0; row < totalRowNum; ++row) {
            for (let col = 0; col < totalColNum; ++col) {
                const cellRef = XLSX.utils.encode_cell({
                    r: row,
                    c: col,
                });
                if (cloneWS[cellRef]) {
                    cloneWS[cellRef].s = DEFAULT_CELL_STYLE;
                }
                // // 暂不支持边框设置
                // if (bordered) {
                //   cloneWS[cellRef].s.border = BORDERED_CELL_STYLE;
                // }
            }
        }
        // 获取所有需要横向合并的单元格的集合
        const horizontalMergedCellArray = getHorizontalMergedCellArray(mergeRules);
        for (let index = 0; index < horizontalMergedCellArray.length; ++index) {
            const cellRef = XLSX.utils.encode_cell(horizontalMergedCellArray[index]);
            if (cloneWS[cellRef]) {
                cloneWS[cellRef].s.alignment.horizontal = 'center';
            }
        }
        return cloneWS;
    }
    catch (error) {
        logger.error('Error in w-excel[xlsx setCellStyle] ', error);
    }
}
function getColWidthArray(fields) {
    return fields.map((item) => {
        const colWidth = Number(item.colWidth);
        return (isNaN(colWidth) || colWidth <= 0) ? DEFAULT_CELL_COL_WIDTH : { wch: colWidth };
    });
}
/**
 * 针对不同格式的导出要求，按照各自格式的方式生成Blob
 * @param {any} workbook
 * @param {String} format 导出的格式
 * @returns {Blob} fileBlob 导出文件的Blob
 */
function generateFileBlob(workbook, format) {
    const xlsxFormatArray = ['xlsx', 'xlsm'];
    if (xlsxFormatArray.includes(format)) {
        const wbout = XLSX.write(workbook, { bookType: format, type: 'binary' });
        return new Blob([strToArrayBuffer(wbout)], { type: 'application/octet-stream' });
    }
    // 暂不支持csv格式导出
    throw new Error(`Warning in w-excel[xlsx]: unsupported file format ${format}`);
}
/**
 * 字符串转Array Buffer
 * @param {String} str 待转为array buffer的字符串
 * @returns {ArrayBuffer}
 */
function strToArrayBuffer(str) {
    // convert str to arrayBuffer
    const buf = new ArrayBuffer(str.length);
    // create uint8array as viewer
    const view = new Uint8Array(buf);
    for (let i = 0; i < str.length; i++) {
        // convert to octet
        view[i] = str.charCodeAt(i) & 0xff;
    }
    return buf;
}
