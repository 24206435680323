var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Inject, Watch, Vue } from 'vue-property-decorator';
import { Renderer } from '@tencent/ui-core';
import { memoize, cloneDeep, isEmpty, merge, defaults } from 'lodash';
import { getDocLink } from '@utils/links';
import styles from './index.module.scss';
import { getUiCore } from '@utils/getUiCore';
const baseLayoutComponents = {
    block: { type: 'public-w-block', props: { dropInVersion: 2 } },
    scopedSlotRoot: { type: 'public-w-block', props: { dropInVersion: 2 } },
};
/** 在渲染时异步拉取UICore并注册*/
const getUICore = memoize(async () => {
    // eslint-disable-next-line function-paren-newline
    const UiCore = await getUiCore();
    Vue.use(UiCore);
    return UiCore;
});
const getComponent = (UiCore) => {
    let DesignerInRuntimeWrapper = class DesignerInRuntimeWrapper extends Vue {
        mode;
        ucDesigner = null;
        ucCompSource = null;
        uiCore = null;
        get designMode() {
            return this.mode === 'designMode';
        }
        // 没渲染时不会主动去获取designer
        async loadDesigner(mode) {
            if (mode) {
                this.ucDesigner = UiCore?.Designer || (await getUICore())?.Designer;
                this.ucCompSource = UiCore?.CompSource || (await getUICore())?.CompSource;
                this.uiCore = UiCore || (await getUICore());
            }
        }
    };
    __decorate([
        Prop({ type: String, default: 'designMode' })
    ], DesignerInRuntimeWrapper.prototype, "mode", void 0);
    __decorate([
        Watch('designMode', { immediate: true })
    ], DesignerInRuntimeWrapper.prototype, "loadDesigner", null);
    DesignerInRuntimeWrapper = __decorate([
        Component({ name: 'DesignerInRuntimeWrapper' })
    ], DesignerInRuntimeWrapper);
    let DesignerInRuntime = class DesignerInRuntime extends DesignerInRuntimeWrapper {
        wContext;
        renderer;
        outerDesigner;
        pagelet;
        dataMode;
        data;
        dataPath;
        defaultContainer;
        uiCompPanel;
        uiDataPanel;
        uiStylePanel;
        uiEventPanel;
        uiToolBar;
        /** 传入designer的作用域插槽 */
        designerScopedSlots = {};
        /** 万一传入的是数组呢 所以要包一层 */
        dataInternal = {};
        designerUiConfig = null;
        rendererTemp = [];
        setDataInternal(value) {
            this.dataInternal = { data: value };
        }
        created() {
            this.$watch(() => this.dataInternal.data, (newVal) => {
                if (newVal !== this.data) {
                    this.$emit('changeData', newVal);
                }
            });
            this.designerUiConfig = this.defaultUiConfig;
        }
        get defaultUiConfig() {
            return {
                propPanelFilter: (item) => {
                    const filters = [];
                    if (!this.uiEventPanel) {
                        filters.push(item.id !== 'event');
                    }
                    if (!this.uiStylePanel) {
                        filters.push(item.id !== 'style');
                    }
                    return filters.every(Boolean);
                },
                materialBoxFilter: (item) => {
                    const filters = [];
                    if (!this.uiCompPanel) {
                        filters.push(item.id !== 'common');
                    }
                    if (!this.uiDataPanel) {
                        filters.push(item.id !== 'data');
                    }
                    return filters.every(Boolean);
                },
                customMaterialBoxes: [],
                toolBarFilter: () => this.uiToolBar,
                domTreeView: false,
            };
        }
        get defaultPagelet() {
            return {
                id: 'default',
                name: '主页面',
                layout: {
                    type: this.defaultContainer || 'w-container',
                },
                xyType: 'pagelet',
                ucVersion: 4,
            };
        }
        get pagelets() {
            let { pagelet } = this;
            if (isEmpty(pagelet)) {
                return [cloneDeep(this.defaultPagelet)];
            }
            if (typeof pagelet === 'string') {
                pagelet = JSON.parse(pagelet);
            }
            const tempPagelet = cloneDeep(pagelet);
            if (!tempPagelet.id) {
                tempPagelet.id = 'default';
            }
            // 尝试兼容复制过来的
            if (!tempPagelet.layout) {
                tempPagelet.layout = tempPagelet.comp;
            }
            if (!tempPagelet.ucVersion) {
                tempPagelet.ucVersion = this.renderer.uc.pagelets[0]?.ucVersion || 4;
            }
            return [tempPagelet];
        }
        get dataSchema() {
            if (!this.dataPath)
                return undefined;
            const rendererStub = this.renderer?.getStubAtPath(this.dataPath);
            return {
                type: 'object',
                fields: [{ ...rendererStub.schema, id: 'data' }],
            };
        }
        onPageletsChange(newValue) {
            if (!this.designMode)
                return;
            this.$emit('changePagelet', newValue[0]);
        }
        get dataSources() {
            return [{
                    id: 'state',
                    schema: {
                        ...this.dataSchema,
                        title: '数据',
                    },
                    instance: this.dataInternal,
                }];
        }
        get allDataSources() {
            const dataSources = Object.values(this.renderer.ucDataSources);
            const ds = dataSources.map((item) => {
                const instance = (item.instance || this.renderer[item.id]);
                return instance && {
                    id: item.id,
                    schema: item.schema,
                    instance,
                };
            }).filter(Boolean);
            return ds;
        }
        get componentPlugin() {
            const compList = ['w-array'];
            return {
                install(uc) {
                    uc.hooks.componentMetaListAfterLoad.tap('uicoreComponent', (components) => {
                        components.forEach((comp, index, arr) => {
                            if (compList.includes(comp.id)) {
                                // eslint-disable-next-line no-param-reassign
                                arr[index] = { ...comp, visible: true, category: '布局' };
                            }
                        });
                        return components;
                    });
                },
            };
        }
        get ucProps() {
            let dataSources = [];
            if (this.dataMode === 'stateData') {
                dataSources = this.dataSources;
            }
            if (this.dataMode === 'allData') {
                dataSources = this.allDataSources;
            }
            const props = {
                dataSources,
                pagelets: this.pagelets,
                components: this.wContext.components,
                pageletId: 'default',
                plugins: [this.componentPlugin],
            };
            return props;
        }
        get ucEvents() {
            return {
                'hook:mounted': () => {
                    const renderer = this.$refs.renderer;
                    this.rendererTemp.push(renderer);
                    this.$store.commit('layout/collectUcRenderer', { mainRoot: false, renderer });
                },
                'hook:beforeDestroy': () => {
                    const renderer = this.rendererTemp.shift();
                    this.$store.commit('layout/changeUcRenderer', { mainRoot: false, renderer });
                },
                'update:pagelets': this.onPageletsChange,
            };
        }
        renderDesigner() {
            const Designer = this.ucDesigner;
            // 缺失designer 暂时不渲染
            if (!Designer)
                return;
            return (<Designer style="min-height:600px;min-width:600px" class="xy-uc-designer" hide-breadcrumb ref='renderer' props={this.ucProps} use-unsettled-editor={false} base-layout-components={baseLayoutComponents} on={this.ucEvents} designer-ui-config={this.designerUiConfig} scopedSlots={this.designerScopedSlots} file-upload-handler={() => void 0} state-plugin={false}/>);
        }
        renderRenderer() {
            return (<div>
        <Renderer ref="renderer" style="height:100%" class="xy-uc-designer" hide-breadcrumb props={this.ucProps} on={this.ucEvents} state-plugin={false}/>
      </div>);
        }
        render() {
            return this.designMode ? this.renderDesigner() : this.renderRenderer();
        }
    };
    __decorate([
        Inject('wContext')
    ], DesignerInRuntime.prototype, "wContext", void 0);
    __decorate([
        Inject('uicore:renderer')
    ], DesignerInRuntime.prototype, "renderer", void 0);
    __decorate([
        Inject('uicore:designer')
    ], DesignerInRuntime.prototype, "outerDesigner", void 0);
    __decorate([
        Prop({ type: Object, default: () => null })
    ], DesignerInRuntime.prototype, "pagelet", void 0);
    __decorate([
        Prop({ type: String, default: 'stateData' })
    ], DesignerInRuntime.prototype, "dataMode", void 0);
    __decorate([
        Prop({ type: Object, default: () => null })
    ], DesignerInRuntime.prototype, "data", void 0);
    __decorate([
        Prop({ type: String, default: '' })
    ], DesignerInRuntime.prototype, "dataPath", void 0);
    __decorate([
        Prop({ type: String, default: '' })
    ], DesignerInRuntime.prototype, "defaultContainer", void 0);
    __decorate([
        Prop({ type: Boolean, default: false })
    ], DesignerInRuntime.prototype, "uiCompPanel", void 0);
    __decorate([
        Prop({ type: Boolean, default: false })
    ], DesignerInRuntime.prototype, "uiDataPanel", void 0);
    __decorate([
        Prop({ type: Boolean, default: false })
    ], DesignerInRuntime.prototype, "uiStylePanel", void 0);
    __decorate([
        Prop({ type: Boolean, default: false })
    ], DesignerInRuntime.prototype, "uiEventPanel", void 0);
    __decorate([
        Prop({ type: Boolean, default: false })
    ], DesignerInRuntime.prototype, "uiToolBar", void 0);
    __decorate([
        Watch('data', { immediate: true })
    ], DesignerInRuntime.prototype, "setDataInternal", null);
    DesignerInRuntime = __decorate([
        Component({ name: 'DesignerInRuntime' })
    ], DesignerInRuntime);
    // TODO 已经和动态加载资源解耦了 可以抽到公共组件里 想办法从全局继承到DesignerInRuntime
    let PrintTemplate = class PrintTemplate extends DesignerInRuntime {
        customPanel;
        get completedCustomPanel() {
            let config = cloneDeep(this.customPanel);
            if (!Array.isArray(config) && typeof config === 'object') {
                config = [config];
            }
            if (Array.isArray(config)) {
            }
            else {
                console.error('[UICore组件]:无效的自定义物料面板配置');
                return [];
            }
            config.forEach((item) => {
                const id = item.title || item.id;
                defaults(item, { type: 'comp', id, title: id, content: [] });
                item.content.forEach((item) => {
                    // @ts-ignore 兼容从复制过来的组件生成物料
                    if (item.comp && !item.layout) {
                        // @ts-ignore
                        item.layout = item.comp;
                    }
                    defaults(item, { layout: { type: item.type } });
                    item.layout.type = item.layout.type || item.type || '';
                });
            });
            return config;
        }
        created() {
            const renderMap = {
                comp: this.getRenderCustomComp,
            };
            this.$watch('completedCustomPanel', (newVal) => {
                this.designerUiConfig = { ...this.defaultUiConfig, customMaterialBoxes: newVal };
                this.designerScopedSlots = this.completedCustomPanel
                    .map(item => ({ [item.id]: renderMap[item.type](item) }))
                    .reduce(merge);
            }, { immediate: true });
        }
        getRenderCustomComp(config) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            return function () {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const CompSource = self.ucCompSource;
                if (!CompSource)
                    return;
                // 把每个item渲染成类似组件卡片的东西
                // 并且保证能拖入
                const materialList = config.content.map(item => (<CompSource class={styles.compSource} value={item.layout} key={item.title} title={item.title}>{item.title}</CompSource>));
                return (<div class={styles.materialPanelBox}>
            <div class={styles.compSourceList}>{materialList}</div>
        </div>);
            };
        }
    };
    __decorate([
        Prop({ type: Array, default: () => [] })
    ], PrintTemplate.prototype, "customPanel", void 0);
    PrintTemplate = __decorate([
        Component({ name: 'PrintTemplate' })
    ], PrintTemplate);
    return PrintTemplate;
};
export const getDIRComp = (UiCore) => ({
    id: 'public-w-uicore-designer',
    name: 'UICore容器',
    component: getComponent(UiCore),
    componentId: 9999,
    display: 'block',
    framework: 'vue',
    registry: 'public',
    version: '0.0.1',
    visible: true,
    category: '插入',
    initialLayout: {
        props: {
            height: '700px',
            dataMode: 'allData',
        },
    },
    bindings: [
        {
            id: '页面内容',
            prop: 'pagelet',
            event: 'changePagelet',
            required: true,
        },
        {
            id: '数据',
            prop: 'data',
            pathProp: 'dataPath',
            event: 'changeData',
        },
    ],
    propsSchema: {
        type: 'object',
        fields: [
            {
                id: 'mode',
                type: 'string',
                default: 'designMode',
                title: '模式',
                enum: [
                    {
                        label: '设计模式',
                        value: 'designMode',
                    },
                    {
                        label: '渲染模式',
                        value: 'renderMode',
                    },
                ],
            },
            {
                id: 'pagelet',
                type: 'any',
                title: '页面内容',
            },
            {
                id: 'dataMode',
                type: 'string',
                title: '数据模式',
                description: '绑定的数据将挂载到内部的state.data',
                default: 'stateData',
                enum: [
                    {
                        label: '传入数据',
                        value: 'stateData',
                    },
                    {
                        label: '透传数据源',
                        value: 'allData',
                    },
                ],
                group: '数据',
            },
            {
                id: 'data',
                type: 'any',
                title: '数据',
                description: '绑定的数据将挂载到内部的state.data',
                group: '数据',
                condition: 'props.dataMode==="stateData"',
            },
            {
                id: 'defaultContainer',
                type: 'string',
                title: '默认容器类型',
                description: '布局的默认根容器',
            },
            {
                id: 'customPanel',
                type: 'any',
                title: '自定义物料面板',
                description: `[帮助文档](${getDocLink().DS_CUSTOM_MATERIAL})`,
                default: [],
            },
            {
                id: 'uiCompPanel',
                type: 'boolean',
                title: '组件面板',
                default: false,
                group: '编辑器UI修剪',
            },
            {
                id: 'uiDataPanel',
                type: 'boolean',
                title: '数据源面板',
                default: false,
                group: '编辑器UI修剪',
            },
            {
                id: 'uiStylePanel',
                type: 'boolean',
                title: '样式面板',
                default: false,
                group: '编辑器UI修剪',
            },
            {
                id: 'uiEventPanel',
                type: 'boolean',
                title: '行为面板',
                default: false,
                group: '编辑器UI修剪',
            },
            {
                id: 'uiToolBar',
                type: 'boolean',
                title: '工具栏',
                default: false,
                group: '编辑器UI修剪',
            },
        ],
    },
});
