/* eslint-disable no-underscore-dangle */
import Vue from 'vue';

const screenInfo = {
  isMobile: false,
  isMiniprogram: false,
  isNarrowScreen: false,
  screen: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
    xxxl: false,
  },
};

const breakpointsAntDesign = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

const matchMap = {
  xs: `(max-width: ${breakpointsAntDesign.xs}px)`,
  sm: `(min-width: ${breakpointsAntDesign.xs}px) and (max-width: ${breakpointsAntDesign.sm}px)`,
  md: `(min-width: ${breakpointsAntDesign.sm}px) and (max-width: ${breakpointsAntDesign.md}px)`,
  lg: `(min-width: ${breakpointsAntDesign.md}px) and (max-width: ${breakpointsAntDesign.lg}px)`,
  xl: `(min-width: ${breakpointsAntDesign.lg}px) and (max-width: ${breakpointsAntDesign.xl}px)`,
  xxl: `(min-width: ${breakpointsAntDesign.xl}px) and (max-width: ${breakpointsAntDesign.xxl}px)`,
  xxxl: `(min-width: ${breakpointsAntDesign.xxl}px)`,
};

Object.keys(screenInfo.screen).forEach((key) => {
  const mediaList = window.matchMedia(matchMap[key]);
  Vue.util.defineReactive(screenInfo.screen, key, mediaList.matches);
  mediaList.addListener(() => {
    screenInfo.screen[key] = mediaList.matches;
  });
});

// 提供一个响应式的编辑态对象
export const editorScreenInfo = {
  editor: true, // 当且仅当编辑时为true，预览时为false
  isNarrowScreen: false,
  screen: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
    xxxl: false,
  },
};

Vue.observable(editorScreenInfo);

export const updateEditorScreenSize = function (width) {
  let type = null;
  Object.keys(editorScreenInfo.screen).forEach(key => (editorScreenInfo.screen[key] = false));
  if (width > 1600) type = 'xxxl';
  else if (width <= 1600 && width > 1200) type = 'xxl';
  else if (width <= 1200 && width > 992) type = 'xl';
  else if (width <= 992 && width > 768) type = 'lg';
  else if (width <= 768 && width > 576) type = 'md';
  else if (width <= 576 && width > 480) type = 'sm';
  else if (width <= 480) type = 'xs';
  if (type === 'xs') {
    editorScreenInfo.isNarrowScreen = true;
  } else {
    editorScreenInfo.isNarrowScreen = false;
  }
  type && (editorScreenInfo.screen[type] = true);
};

const ua = window.navigator.userAgent;

// 判断是否移动端
export const isMobile = () => !!/Android|webOS|iPhone|iPod|BlackBerry/i.test(ua);

// 判断是否小程序
export const isMiniprogram = () => !!(window.__wxjs_environment === 'miniprogram')
  || ua.indexOf('miniprogram') > -1;

// 判断是否窄屏
export const isNarrowScreen = () => !!screenInfo.screen.xs;

// 判断是否是手机 / 窄屏 / 小程序
export const isMobileOrNarrowScreen = () => isMobile() || isNarrowScreen() || isMiniprogram();

let isMockMobile = false;
export const setMockMobile = (value) => {
  isMockMobile = value;
};

Object.defineProperty(screenInfo, 'isMobile', {
  configurable: false,
  enumerable: true,
  get: () => isMobile() || isMockMobile,
});

Object.defineProperty(screenInfo, 'isMiniprogram', {
  configurable: false,
  enumerable: true,
  get: () => isMiniprogram(),
});

Object.defineProperty(screenInfo, 'isNarrowScreen', {
  configurable: false,
  enumerable: true,
  get: () => isNarrowScreen() || isMockMobile,
});

Object.defineProperty(screenInfo, 'isMobileOrNarrowScreen', {
  configurable: false,
  enumerable: true,
  get: () => isMobileOrNarrowScreen(),
});

// 挂载判断是否窄屏变量
window.xyScreenInfo = screenInfo;

export default screenInfo;
