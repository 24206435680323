<template>
  <div>
    <a-form-model
      :model="form"
      v-bind="layout"
    >
      <a-form-model-item label="申请人">
        <span>{{ form.user }}</span>
      </a-form-model-item>
      <a-form-model-item label="环境">
        <span>{{ currentEnvName }}</span>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.bizId"
        label="业务"
      >
        <span>{{ currentBizName }}</span>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.pageId"
        label="页面"
      >
        <span>{{ currentPageInfo }}</span>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.flowId"
        label="流程"
      >
        <span>{{ form.flowId }}</span>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.api"
        label="云函数接口"
      >
        <span>{{ form.api }}</span>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.method"
        label="请求方法"
      >
        <span>{{ form.method }}</span>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.permission"
        label="权限项"
      >
        <span>{{ permissionMap[form.permission] || form.permission }}</span>
      </a-form-model-item>
      <a-form-model-item
        v-if="mode !== 'approval'"
        label="申请类型"
      >
        <a-radio-group v-model="form.type">
          <a-radio-button value="role">
            角色
          </a-radio-button>
          <a-radio-button
            value="page"
            :disabled="!!projectInfo.disabledPageApply || !!form.flowId"
          >
            页面
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.type === 'page'"
        label="申请页面权限"
      >
        <a-select
          v-model="selectedPages"
          mode="multiple"
          :filter-option="pageFilterOption"
          @change="selectedPagesChange"
        >
          <a-select-option
            v-for="page of pages"
            :key="page.id"
            :value="page.pageId"
          >
            {{ page.pageName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.type === 'role'"
        label="申请角色"
        required
      >
        <div>
          <a-select
            v-model="selectedRoles"
            :disabled="!editable"
            mode="multiple"
            :filter-option="filterOption"
            @search="onSearch"
            @change="selectedRolesChange"
          >
            <span slot="notFoundContent">
              <span v-if="searchRoleQ">搜索无结果</span>
              <span v-else>
                <span>无可申请角色</span>
                <span style="font-size:10px;color:#8d8d8d;font-style:italic">内置角色不可被申请</span>
              </span>
            </span>
            <a-select-opt-group
              v-if="needRolePermList()"
              label="以下角色有需要申请的权限"
            >
              <a-select-option
                v-if="recommendedRoles.length === 0"
                disabled
                value=""
              >
                无
                <span style="font-size:10px;color:#8d8d8d;font-style:italic"> 请联系管理员</span>
              </a-select-option>
              <a-select-option
                v-for="role of recommendedRoles"
                v-else
                :key="role.name"
              >
                {{ role.cname }}
              </a-select-option>
            </a-select-opt-group>
            <a-select-opt-group
              v-if="needRolePermList()"
              label="以下角色没有需要申请的权限"
            >
              <a-select-option
                v-for="role of otherRoles"
                :key="role.name"
                disabled
              >
                {{ role.cname }}
              </a-select-option>
            </a-select-opt-group>
            <a-select-option
              v-for="role of availableRoles"
              v-else
              :key="role.name"
            >
              {{ role.cname }}
            </a-select-option>
          </a-select>
          <div
            v-if="selectedRoles.length && !isMobileScreen"
            style="margin-bottom:-12px"
          >
            点击查看角色权限：
            <a-tag
              v-for="role of selectedRoles"
              :key="role"
              @click="() => {
                rolePermView.name = role;
                rolePermView.cname = roleMap[role].cname;
                rolePermView.visible = true;
              }"
            >
              {{ roleMap[role] && roleMap[role].cname }}
            </a-tag>
          </div>
        </div>
      </a-form-model-item>
      <user-role-expire-edit
        :expire="form.expire"
        :user-id="form.user"
        :project-id="projectId"
        :readonly="mode === 'approval'"
        :env="env"
        @expireChange="(expire) => form.expire = expire"
      />
      <a-form-model-item
        label="申请说明"
        required
      >
        <a-textarea
          v-if="mode !== 'approval'"
          v-model="form.description"
          placeholder="描述正在进行的操作，便于管理员分配恰当的角色"
        />
        <span v-else>{{ form.description || '无' }}</span>
      </a-form-model-item>
      <a-form-model-item
        v-show="hasExtraForm"
        label="额外表单"
      >
        <ApplyCustomForm
          ref="extraForm"
          v-model="extraData"
          :readonly="mode === 'approval'"
          :flow-config="flowConfig"
          @updated="hasForm => hasExtraForm = hasForm"
        />
      </a-form-model-item>
    </a-form-model>
    <a-modal
      :title="rolePermView.cname"
      :visible="rolePermView.visible"
      width="64%"
      :destroy-on-close="true"
      @ok="() => rolePermView.visible = false"
      @cancel="() => (rolePermView.visible = false)"
    >
      <manage-permission
        v-if="rolePermView.visible"
        :key="rolePermView.name"
        :project-id="projectId"
        :env="env"
        :role="rolePermView.name"
        :pages="pages"
        :tag-list="tagList"
        mode="view"
        max-height="60vh"
      />
    </a-modal>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import { mapState, mapActions } from 'vuex';
import { getUserId } from '@utils/userInfo';
import { BUILT_IN_ROLES } from '@pages/modules/permission/constants';
import { permissionApi } from '@/services';
import ManagePermission from '@pages/modules/permission/ManagePermission';
import { keyBy } from 'lodash';
import { UserRoleExpireEdit } from '@pages/modules/permission/expire/UserRoleExpireEdit';
import { isMobileOrNarrowScreen } from '@utils/screen';
import ApplyCustomForm from './ApplyCustomForm.tsx';

export default {
  components: {
    ManagePermission,
    UserRoleExpireEdit,
    ApplyCustomForm,
  },
  props: {
    projectId: {
      type: String,
      default: '',
    },
    env: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
    flowConfig: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        user: getUserId(),
        env: '',
        pageId: '',
        bizId: '',
        permission: '',
        api: '',
        method: '',
        type: 'role',
        page: '',
        role: '',
        description: '',
        expireAt: 0,
        flowId: '',
      },
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      },
      hasExtraForm: false,
      extraData: null,
      searchRoleQ: '',
      roles: [],
      roleMap: {},
      pageMap: {},
      rolePermList: [],
      rolePermView: {
        visible: false,
        name: '',
        cname: '',
      },
      pages: window.xy_runtime_pages.map(p => ({
        id: p.id,
        pageName: `${p.name}(${p.path})`,
        pageId: p.pageId,
        tags: p.pageTag?.split(',').filter(Boolean)
          .map(Number) ?? [],
      })),
      tagList: [],
      selectedRoles: [],
      selectedPages: [],
      permissionMap: {
        'read.project': '查看应用数据',
        'create.project': '新建应用数据',
        'update.project': '更新应用数据',
        'delete.project': '删除应用数据',
        'read.page': '查看数据',
        'create.page': '新建数据',
        'update.page': '更新数据',
        'delete.page': '删除数据',
        'read.permission': '查看权限配置',
        'update.permission': '更新权限配置',
        'start.flow': '发起流程',
        'upload.flow': '在流程中上传',
      },
    };
  },
  computed: {
    ...mapState({
      projectInfo: state => state.runtime.project,
      topSwitch: state => state.runtime.topSwitch,
      envList: state => state.env.envList,
    }),
    bizKey() {
      return this.projectInfo?.bizIsolation;
    },
    bizList() {
      const result = this.topSwitch[this.bizKey] || [];
      return result;
    },
    availableRoles() {
      let roles = this.roles.filter(r => !BUILT_IN_ROLES.includes(r.name));
      if (!this.form.bizId) {
        roles = roles.filter(r => r.type !== 'biz');
      }
      return roles;
    },
    recommendedRoles() {
      return this.availableRoles.filter(i => this.rolePermList.some(rp => rp.role === i.name));
    },
    otherRoles() {
      return this.availableRoles.filter(i => !this.rolePermList.some(rp => rp.role === i.name));
    },
    currentBizName() {
      const biz = this.bizList.find(i => i.value === this.form.bizId);
      return biz ? biz.label : this.form.bizId;
    },
    currentEnvName() {
      if (this.form.env === 'dev') return '开发环境';
      const env = this.envList.find(r => r.env === this.form.env);
      return env?.name ?? this.env;
    },
    currentPageInfo() {
      if (!this.form.pageId) return '';
      const page = this.pages.find(p => p.pageId === this.form.pageId);
      return page ? page.pageName : this.form.pageId;
    },
    isMobileScreen() {
      return isMobileOrNarrowScreen();
    },
  },
  watch: {
    data: {
      handler() {
        if (this.data) this.init();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.initRoles();
    this.initRolePermList();
    this.fetchEnvList();
  },
  methods: {
    ...mapActions(['fetchEnvList']),
    init() {
      this.form.user = this.data.user;
      this.form.env = this.data.env;
      this.form.pageId = this.data.pageId;
      this.form.flowId = this.data.flowId;
      this.form.permission = this.data.permission;
      this.form.bizId = this.data.bizId;
      this.form.api = this.data.api;
      this.form.method = this.data.method;
      this.form.role = this.data.role;
      this.form.description = this.data.description;
      this.form.projectName = window.xy_runtime_project.name || this.projectId;
      this.form.envName = this.currentEnvName || this.env;
      this.form.bizName = this.currentBizName || this.data.bizId;
      typeof this.data.expire === 'number' && (this.form.expire = this.data.expire);
      this.selectedRoles = this.data.role.split(',').filter(Boolean);
      this.pageMap = keyBy(this.pages, 'pageId');
      this.extraData = this.data.form;
    },
    async initRoles() {
      try {
        const resp = await permissionApi.fetchRoles(this.projectId, this.env);
        this.roles = resp;
        this.roleMap = keyBy(this.roles, 'name');
        if (this.selectedRoles.length) {
          setTimeout(() => {
            this.selectedRoles = this.selectedRoles.filter(i => this.availableRoles.some(r => r.name === i));
            if (!this.selectedRoles.length) message.warn('用户申请的角色不存在');
          }, 0);
        }
      } catch (error) {
        const msg = '获取角色列表失败';
        console.error(msg, error);
        message.error(msg);
      }
    },
    needRolePermList() {
      if (!this.editable) return false;
      if (!this.form.permission) return false;
      const { permission } = this.form;
      if (this.permissionMap[permission] && permission.includes('.page') && !this.form.pageId) return false;
      return true;
    },
    async initRolePermList() {
      if (!this.needRolePermList()) return;
      try {
        const resp = await permissionApi.fetchRolesByPermission(
          this.projectId,
          this.env,
          { permission: this.form.permission, pageId: this.form.pageId || this.form.flowId },
        );
        this.rolePermList = resp;
        if (this.selectedRoles.length) {
          setTimeout(() => {
            const result = this.selectedRoles.filter(i => this.recommendedRoles.some(r => r.name === i));
            if (!result.length) message.warn('用户申请的角色没有相关的权限');
          }, 300);
        }
      } catch (error) {
        message.error('获取权限-角色失败');
      }
    },
    async apply() {
      if (this.selectedRoles.length === 0 && this.selectedPages.length === 0) {
        message.error('申请角色或页面不能为空');
        return;
      }
      if (!this.form.description) {
        message.error('申请说明不能为空');
        return;
      }
      try {
        const customFormResult = this.hasExtraForm ? await this.$refs.extraForm.uiCore.ucRootForm.validate() : [];
        if (customFormResult.some(x => x.error)) return;
      } catch (error) {
        message.error(`校验额外表单失败, ${error.message}`);
        return;
      }
      const { projectId, env, roleMap, pageMap } = this;
      this.form.role = this.selectedRoles.join(',');
      this.form.page = this.selectedPages.join(',');
      const roleNames = this.selectedRoles.map(r => roleMap[r].cname).join(',');
      const pageNames = this.selectedPages.map(id => pageMap[id].pageName).join(',');
      const data = { ...this.form, roleNames, pageNames, form: this.extraData };
      delete data.user;
      try {
        const resp = await permissionApi.apply(projectId, env, data);
        return resp;
      } catch (error) {
        const msg = error.message.includes('empty handlers') ? '找不到审批人，请联系管理者' : error.message;
        message.error(msg);
      }
    },
    onSearch(q) {
      this.searchRoleQ = q;
    },
    selectedChange(selected, type) {
      if (selected.length > 5) {
        selected.pop();
        message.warn(`最多只能选择 5 个${type}`);
      }
    },
    selectedRolesChange() {
      this.selectedChange(this.selectedRoles, '角色');
    },
    selectedPagesChange() {
      this.selectedChange(this.selectedPages, '页面');
    },
    filterOption(inputValue, option) {
      if (!inputValue) return true;
      const q = inputValue.toLowerCase();
      if (option.key?.toLowerCase().includes(q)) return true;
      const role = this.availableRoles.find(i => i.name === option.key);
      return role?.cname.toLowerCase().includes(q);
    },
    pageFilterOption(inputValue, option) {
      if (!inputValue) return true;
      const q = inputValue.toLowerCase();
      const page = this.pages.find(i => i.id === option.key);
      return page?.pageId.toLowerCase().includes(q) || page?.pageName.toLowerCase().includes(q);
    },
  },

};
</script>

<style lang="scss" scoped>

</style>
