import fetch from '@utils/fetch';
import wujiFetch from '@/utils/wujiFetch';
import { getRuntimeEnvPath } from '@config/constant';
import { message } from 'ant-design-vue';

export const postEmail = async (to, title, content, { cc = '', bcc = '', emailId = '' }) => {
  const { projectId = '', env = 'dev' } = window.w || {};
  const api = emailId ? `${getRuntimeEnvPath(env)}/email` : '/api/xy/email';
  const data = {
    emailId,
    to,
    title,
    content,
    cc,
    bcc,
  };
  try {
    await wujiFetch(`${api}?projectid=${projectId}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  } catch (error) {
    message.error('邮件发送失败');
    throw error;
  }
};

export const notifyByWework = async (projectId, env, { receivers, msgType, data }, builtin = true) => {
  const url = getRuntimeEnvPath(env);
  try {
    await fetch(`${url}/notice/wework?projectid=${projectId}&builtin=${builtin}`, {
      method: 'POST',
      body: JSON.stringify({
        receivers,
        msgType,
        data,
      }),
    });
  } catch (error) {
    message.error('企业微信通知失败');
    throw error;
  }
};

export default {};
