<template>
  <a-modal
    v-if="anncmnt && !anncmnt.read && isIframe"
    title="公告"
    centered
    :visible="visible"
    :mask="false"
    :mask-closable="false"
    @cancel="read"
  >
    <h3 :style="{color: anncmnt.type==='warn'?'red':'black'}">
      <a-icon
        :type="anncmnt.type==='warn'?'warning':'info-circle'"
        :two-tone-color="anncmnt.type==='warn'?'red':'#1890ff'"
        theme="twoTone"
        style="margin-right:4px"
      />
      {{ anncmnt.title }}
    </h3>
    <public-w-cherry-markdown
      :value="anncmnt.content"
      readonly="true"
    />
    <templat slot="footer">
      <a-button
        type="primary"
        @click="read"
      >
        知道了
      </a-button>
    </templat>
  </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default ({
  name: 'AnncmntModal',
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    ...mapGetters(['getListOfStyle']),
    anncmnt() {
      const list = this.getListOfStyle('modal');
      list.sort((a, b) => (dayjs(a.startTime).isBefore(b.startTime) ? 1 : -1));
      return list[0];
    },
    isIframe() {
      return window.self === window.top;
    },
  },
  created() {
    this.fetchAnncmntRuntimeList({ projectId: this.projectId });
  },
  methods: {
    ...mapActions(['readAnncmntRuntime', 'fetchAnncmntRuntimeList']),
    read() {
      const { projectId, id, endTime } = this.anncmnt;
      // 修改已读态
      this.readAnncmntRuntime({ projectId, id, endTime });
      this.visible = false;
    },
  },
});
</script>


<style lang="scss" scoped>
  ::v-deep .cherry-previewer{
    padding: 10px;
  }
  ::v-deep .ant-modal-body{
    max-height: 500px;
    overflow: auto;
  }

</style>
