<template>
  <component
    :is="customLayout"
    v-if="isCustomLayout"
    class="custom-wuji-layout"
  />
  <DefaultLayout
    v-else
    v-bind="$props"
    v-on="$listeners"
  />
</template>

<script>
import { getUserId, getUserAvatarURL } from '@utils/userInfo';
import layoutLoader from '@loaders/layout/loader';
import DefaultLayout from './DefaultLayout.vue';
import Vue from 'vue';
import { mapState } from 'vuex';
import polyfillFetch from '@utils/polyfillFetch';
import * as dayjs from 'dayjs';
import { sendMessage } from '@miniprogram/utils/send-message';
import { getAppContext } from '@utils/context';
import useXiaoman from '@/composables/useXiaoman';
import { setXyCookies } from '@/utils/getXyHeaders';
import { getServiceId } from '@/utils/serviceInfo';

export default {
  name: 'RuntimeLayout',
  components: {
    DefaultLayout,
  },
  provide() {
    return {
      ctx: this.ctx,
    };
  },
  props: {
    appConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    mode: {
      type: String,
      default: 'runtime', // 'editor'(编辑时) | 'runtime'(运行时) | 'preview'(预览时)
    },
    env: {
      type: String,
      default: 'dev',
    },
  },
  setup(props) {
    // 添加小满
    const xiaomanOpen = window.WUJI_SWITCH?.xiaoman_runtime?.open;
    if (props.env === 'prod' && xiaomanOpen) {
      const xiaoman = props.appConfig?.advanceConfig?.xiaoman ?? {};
      useXiaoman(xiaoman.open, xiaoman.appid);
    }
  },
  data() {
    return {
      customLayout: null,
      isCustomLayout: false,
      ctx: getAppContext({ mode: 'runtime', project: this.appConfig, env: this.env }),
    };
  },
  computed: {
    ...mapState({
      project: state => state.runtime.project,
      basicPages: state => state.runtime.basicPages,
      currentPage: state => state.runtime.currentPage,
    }),
    projectId() {
      return this.appConfig.id;
    },
    widgets() {
      let widgets = [];
      if (this.appConfig?.uiInfo?.navlink) {
        const { navlink } = this.appConfig.uiInfo;
        widgets = (navlink.widgets ?? []).filter(w => !!w.type && w.config?.queryKey);
      }
      return widgets;
    },
  },
  watch: {
    $route: {
      handler(route) {
        const pageId = route?.meta?.pageId;
        // Aegis 上报
        if (pageId) {
          const serviceId = getServiceId();
          window.AegisV2?.setConfig({
            extField: {
              ext1: this.projectId || '',
              ext3: pageId,
              ext4: serviceId,
            },
          });
          window.CustomAegisV2?.setConfig({
            extField: {
              ext3: pageId,
              ext4: serviceId,
            },
          });
        }

        if (window.RUNTIME_CONFIGS && pageId) {
          window.RUNTIME_CONFIGS.pageId = pageId;
        }
        setXyCookies();
        // 触发小程序保存路径方法
        sendMessage(route.fullPath);
      },
      immediate: true,
      deep: true,
    },
  },
  async created() {
    let commonQuery = [];
    if (this.appConfig?.uiInfo?.navlink) {
      commonQuery = this.widgets.filter(w => ['switch', 'env'].includes(w.type)).map(w => w.config.queryKey);
    }

    // FIXME: [废弃字段] 兼容旧组件, 暂时保留
    Vue.prototype.$pageInfo = {
      mode: this.mode,
      userInfo: {
        rtx: getUserId(),
        icon: getUserAvatarURL(),
      },
    };

    Vue.prototype.$fetch = polyfillFetch;
    Vue.prototype.$dayjs = dayjs;

    Vue.prototype.$mode = 'runtime';
    Vue.prototype.$commonQuery = commonQuery;

    // 加载自定义布局组件
    const projectLayoutConfig = window.xy_runtime_layout ?? null;
    const type = 'layout';
    if (projectLayoutConfig?.[type] || layoutLoader.isDebug()) {
      this.loadCustomLayout(type);
    }
  },

  methods: {
    async loadCustomLayout(type) {
      this.isCustomLayout = true;
      const layout = await layoutLoader.getLayout({ type });
      this.customLayout = layout;
    },
  },
};
</script>
<style lang="scss">
.custom-wuji-layout {
  .w-sys-main-pagelet {
    min-height: 100%;
  }
}
</style>
