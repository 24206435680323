import { computed } from '@vue/composition-api';
import { useStore } from '@store/index';
export default function useLayout() {
    const store = useStore();
    return {
        // 渲染器实例
        mainRenderer: computed(() => store.state.layout.mainRenderer),
        project: computed(() => store.getters?.['layout/project']),
        roles: computed(() => store.state.layout.roles),
        biz: computed(() => store.state.layout.biz),
        isAdmin: computed(() => store.state.layout.isAdmin),
        navTheme: computed(() => store.getters?.['layout/navTheme']),
        sidebarGroups: computed(() => store.getters?.['layout/sidebarGroups']),
        sidebarTheme: computed(() => store.getters?.['layout/sidebarTheme']),
        visibleSidebarGroups: computed(() => store.getters?.['layout/visibleSidebarGroups']),
        customSidebar: computed(() => store.state.layout.customSidebar),
        // 顶部导航
        showSidebarGroups: computed(() => store.getters?.['layout/showSidebarGroups']),
        navShowSidebarGroups: computed(() => store.getters?.['layout/navShowSidebarGroups']),
        navSidebarGroupSelectedKeys: computed(() => store.getters?.['layout/navSidebarGroupSelectedKeys']),
    };
}
