let $esbuild;
export function loadESBuild() {
    if (!$esbuild) {
        $esbuild = (async () => {
            const wasmURL = await import(/* webpackChunkName: "esbuild-wasm" */ 'esbuild-wasm/esbuild.wasm').then(x => (x.default || x));
            const esbuild = await import(/* webpackChunkName: "esbuild-wasm" */ 'esbuild-wasm');
            await esbuild.initialize({ wasmURL });
            return esbuild;
        })();
    }
    return $esbuild;
}
export async function transpileWithESBuild(code, filename) {
    const { message } = await import('ant-design-vue');
    const loading = message.loading(`正在编译 ${filename}`, 0);
    try {
        const isTypeScript = /\.tsx?$|^typescript(react)?$/.test(filename);
        const esbuild = await loadESBuild();
        const out = await esbuild.transform(code, {
            format: 'cjs',
            loader: isTypeScript ? 'ts' : 'js',
            minifyWhitespace: true, // 暂时不 minify 语法和变量名字了，不方便看bug
            // jsxFactory: 'F___jsx_h$$',
            // jsxFragment: 'F___jsx_Fragment$$',
        });
        return {
            code: out.code,
        };
    }
    catch (e) {
        message.error(`编译 ${filename} 失败: ${e}`);
        console.error(`编译 ${filename} 失败:`, e);
        throw e;
    }
    finally {
        loading();
    }
}
