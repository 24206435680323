import cloneDeep from 'lodash/cloneDeep';
import { set } from 'lodash';
class UcComponentPlugins {
  constructor() {
    this.plugins = [];
  }

  // 注册插件
  use(plugin) {
    const pluginConfig = cloneDeep(plugin);
    this.plugins.push({
      id: pluginConfig.id,
      config: pluginConfig,
    });
    // 通过事件的方式触发插件的加载
    const event = new Event('componentPluginLoaded');
    window.dispatchEvent(event);
  }

  // 获取uicore的插件
  getUcPlugins() {
    return this.plugins.map(plugin => ({
      install(uc) {
        set(uc.componentPlugins, plugin.id, plugin.config);
      },
    }));
  }
}

const ucComponentPlugins =  new UcComponentPlugins();
export default ucComponentPlugins;
