<template>
  <div style="height:100%">
    <render-page
      v-if="pageId"
      :project-id="projectId"
      :page-id="pageId"
    />
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import { getAppParams } from '@utils/path';
import { workflowApi, workflowEntityApi } from '@/services';
import RenderPage from './RenderPage.vue';

export default {
  components: {
    RenderPage,
  },
  data() {
    return {
      pageId: '',
    };
  },
  computed: {
    urlParams() {
      return getAppParams() || {};
    },
    projectId() {
      return this.urlParams.projectId;
    },
    env() {
      return this.urlParams.env;
    },
    flowId() {
      const { query } = this.$route;
      return query.flowid;
    },
    objectId() {
      const { query } = this.$route;
      return query.id;
    },
    entityId() {
      const { query } = this.$route;
      return query.entityid;
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let { flowId } = this;
      if (!flowId && this.entityId) {
        const entity = await workflowEntityApi.fetchWorkflowEntity(this.projectId, this.env, this.entityId);
        // 延后发出事件
        // 子页面一般在 mounted 周期中监听该事件，确保事件在子页面挂载开始监听后发出
        setTimeout(() => {
          this.$root.$emit('workflow:entity', entity);
        }, 1000);
        flowId = entity.flowId;
      }
      const flowConf = await this.getWorkflow(this.projectId, this.env, flowId);
      if (!flowConf) return;
      const { pageId: ids } = flowConf;
      if (!ids) return;
      const [, pageId] = ids.split(':');
      this.pageId = pageId;
    },
    async getWorkflow(projectId, env, id) {
      try {
        const result = await workflowApi.fetchWorkflow(projectId, env, id);
        return result;
      } catch (err) {
        message.error('获取流程配置失败');
      }
    },
  },
};
</script>
