import fetch from 'isomorphic-fetch';
import wujiFetch from '@/utils/wujiFetch';
import { getServiceId } from '@/utils/serviceInfo';
import { getUserId } from '@/utils/userInfo';
import { reportIgnored } from '@reporter/utils';
import { STANDARD_EVENTS } from '@pages/report/constant';

export default class Atta {
  constructor({ attaId, token, getPublicDims, agentReport, pageInfo }) {
    this.attaId = attaId;
    this.token = token;
    this.getPublicDims = getPublicDims || function () {
      return {};
    };

    this.pageInfo = pageInfo || null;
    this.agentReport = agentReport ?? false;
    this.inTime = -1;
  }

  /**
   * @param {Object} projectInfo
   * @param {string} projectInfo.projectId
   * @param {string} projectInfo.projectName
   * @param {string} projectInfo.parentId
   */
  setProject(projectInfo) {
    this.projectInfo = projectInfo;

    if (this.projectInfo) {
      this.projectInfo.parentId = this.projectInfo.parentId ?? '';
    }
  }

  setPage(pageId) {
    this.pageId = pageId;
  }

  async report(action, queries) {
    if (NODE_ENV === 'development') return;
    if (reportIgnored || !this.projectInfo || !this.projectInfo.projectId || !this.pageId) return;

    const loc = window.location;

    const dims = {
      ...queries,
      ...this.getPublicDims(),
      ...this.projectInfo,

      // 统一上报公共维度
      attaid: this.attaId,
      token: this.token,
      pageId: this.pageId,
      service: getServiceId(),
      host: loc.host,
      url: loc.href,
      action,
      userId: getUserId(),
      ua: navigator.userAgent,
      _dc: Math.random(),
      actionTime: +new Date,
      screenResolution: `${screen.width}x${screen.height}`,
    };

    const url = window.ATTA_REPORT_URL;
    if (this.agentReport) {
      await wujiFetch(url, {
        method: 'POST',
        body: JSON.stringify(dims),
      });
    } else {
      await fetch(url, {
        method: 'POST',
        body: new URLSearchParams(dims),
        mode: 'no-cors',
      });
    }
  }

  /**
   * 页面曝光
   */
  pv(queries) {
    // 统一上报pv事件
    this.inTime = +new Date;
    if (this.pageInfo?.pageType === 'content') {
      this.report(STANDARD_EVENTS.pageView2, {
        ...queries,
        type: this.pageInfo.type,
        contentType: this.pageInfo.contentType });
    }
    this.report('pageview', queries);
  }

  /**
   * 页面结束曝光，上报页面停留时长
   */
  pgOut(queries) {
    const inTimeValid = this.inTime !== -1;
    if (!inTimeValid) return;

    this.report('pageout', {
      ...queries,
      stayTime: inTimeValid ? +new Date - this.inTime : 0,
    });
  }
}
