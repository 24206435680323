import { Vue } from 'vue-property-decorator';
import { toRect } from '@tencent/ui-core/lib/utils/styling';
import { highlightRect } from '@tencent/ui-core/lib/utils/browser';
import { get, last, set, invoke } from 'lodash';
import { vnodeTreeSearch, waitUntilScrollFinish } from '@components/globalSearch/util';
import { delay } from '@tencent/ui-core/lib/utils';
export function getVueClassName(node) {
    return get(node, 'constructor.extendOptions.name') || last(get(node, '$vnode.tag', '').split('-')) || '';
}
export function vueClassNameFilter(name) {
    return (node) => getVueClassName(node) === name;
}
function highlightVue([parentComp, childComp]) {
    if (!parentComp)
        return;
    const rect = toRect(childComp || parentComp);
    const rectW = toRect(parentComp);
    const rect2 = new DOMRect(rectW.x, rect.y, rectW.width, rect.height);
    highlightRect(rect2, 800);
}
/** 取父元素宽度+横坐标 + 子元素高度+纵坐标 组成方框框选group中的元素 并滚动进view */
export async function highlight([parentComp, childCompInput]) {
    if (!parentComp)
        return;
    const childComp = childCompInput || parentComp;
    const element = childComp.$el;
    if (!element)
        return;
    await delay(0);
    element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
    });
    await waitUntilScrollFinish(element);
    highlightVue([parentComp, childComp]);
}
/** 实现搜索反射右侧面板的配置项
 * 搜索结果以layout path呈现 通过加入vNode的筛选 将特定类型的path对应到特定类型的vNode上
 * 从而进行group展开/定位等操作
 * path以组件layout为根
 * 参考ui-core/types/layout.d.ts
 *@return{
 *   pathFilter 筛选生效的path
 *   vNodeFilter 从vNode上找到多个在一条树上dfs路径的节点 传入N个filter返回N个节点
 *   nodeResCallback vNodeFilter筛选后的节点传入这里,进行回调(高亮,滚动等等)
 *}
 * */
export const panelCallbacks = (path) => {
    const propsCallback = ([parent, children]) => {
        if (!parent)
            return;
        if (!get(parent, 'acutalExpanded'))
            parent?.toggle?.();
        highlight([parent, children]);
    };
    return [
        {
            // 属性:数据源绑定js值
            pathFilter: () => path.join('.').includes('props.value') || path.join('.').includes('props.defaultValue'),
            vNodeFilter: [node => getVueClassName(node) === 'WAccordion' && node.title === '数据'],
            nodeResCallback: propsCallback,
        },
        {
            // 事件:自定义上报
            pathFilter: () => path[0] === 'reports',
            vNodeFilter: [node => getVueClassName(node) === 'ReportCardList'],
            nodeResCallback: highlight,
        },
        {
            // 属性:数据源绑定
            pathFilter: () => path[0] === 'bindings',
            vNodeFilter: [
                node => getVueClassName(node) === 'WAccordion' && node.title === '数据',
                node => getVueClassName(node) === 'data-form-field' && node?.ui?.type === 'BindingEdit',
            ],
            nodeResCallback: propsCallback,
        },
        {
            // 事件:自定义事件 Simpleflow
            pathFilter: () => path[0] === 'events',
            vNodeFilter: [
                node => getVueClassName(node) === 'EventCard' && node.eventKey === path[1],
                node => getVueClassName(node) === 'SimpleFlowStepEditor' && node.index === path[3],
            ],
            nodeResCallback: ([parent, children]) => {
                if (!children)
                    return;
                set(children, 'showParams', true);
                highlight([parent, children]);
            },
        },
        {
            // 事件:自定义事件兜底
            pathFilter: () => path[0] === 'events',
            vNodeFilter: [node => getVueClassName(node) === 'EventCard' && node.eventKey === path[1]],
            nodeResCallback: highlight,
        },
        {
            // 属性:表格设置
            pathFilter: () => path[0] === 'props' && path[1] === 'cols',
            vNodeFilter: [
                node => getVueClassName(node) === 'WAccordion',
                node => getVueClassName(node) === 'ColEditor' && node.index === path[2],
            ],
            nodeResCallback: propsCallback,
        },
        {
            // 属性:可折叠group设置
            pathFilter: () => path[0] === 'props',
            vNodeFilter: [
                node => getVueClassName(node) === 'WAccordion',
                node => getVueClassName(node) === 'data-form-field' && path.includes(node.path),
            ],
            nodeResCallback: propsCallback,
        },
        {
            // 属性: 兜底
            pathFilter: () => path[0] === 'props',
            vNodeFilter: [
                node => getVueClassName(node) === 'data-form',
                node => getVueClassName(node) === 'data-form-field' && path.includes(node.path),
            ],
            nodeResCallback: propsCallback,
        },
        {
            // 样式: 兜底
            pathFilter: () => path[0] === 'style',
            vNodeFilter: [node => getVueClassName(node) === 'StylePanel'],
            nodeResCallback: highlight,
        },
    ];
};
/** map<layout中的component key -> designer的aside panel key> */
export const mapRootKey = (key) => {
    const m = {
        events: 'event',
        props: 'prop',
        bindings: 'prop',
        style: 'style',
        class: 'style',
        styleSheetName: 'style',
        reports: 'event',
        displayCondition: 'event',
        renderCondition: 'event',
    };
    return m[String(key)];
};
/** 根据pageletid切换页面片 */
export async function selectPageLet(xyPageConfig, pageletId) {
    if (xyPageConfig.designArea.activeKey !== pageletId) {
        xyPageConfig.handleSelectPagelet(pageletId || '');
        await delay(800);
    }
}
/** 从路径选中组件
 * xyPageConfig:inject('xyPageConfig)
 * attrpath对应面板上的key event prop style三个面板 可以用上面的maprootkey生成
 * */
export async function reflectComp(xyPageConfig, designer, pageletId, compPath, attrPath) {
    selectPageLet(xyPageConfig, pageletId);
    return new Promise((resolve) => {
        setTimeout(() => {
            if (designer) {
                designer.$refs.asideRight.isExpanded = true;
                if (attrPath) {
                    const rightPanel = designer.$refs.designerRightPanel;
                    rightPanel.activeKey = attrPath;
                }
                designer.compPath = compPath;
                highlight([designer.activeCompInstance]);
            }
            resolve(null);
        }, 100);
    });
}
export async function reflectSourcePath(xyPageConfig, designer, pageletId, sourcePath, attrPath) {
    selectPageLet(xyPageConfig, pageletId);
    await delay(100);
    if (!designer)
        return;
    await openSidePanel(designer, 'right');
    if (attrPath) {
        const rightPanel = designer.$refs.designerRightPanel;
        rightPanel.activeKey = attrPath;
    }
    // walkLayout出来的sourcePath不太标准
    const path = sourcePath.split('/');
    path[0] = 'layout';
    await designer.setSourcePath(path);
    highlight([designer.activeCompInstance]);
}
/** 右侧面板定位具体配置项 layoutPath 形如["props","justifyContent"] */
export async function reflectPanel(designer, layoutPath) {
    return new Promise((resolve, reject) => {
        const reflectFn = () => {
            const rightPanel = designer.$refs.designerRightPanel;
            if (!layoutPath) {
                highlight([rightPanel]);
                resolve(false);
                return;
            }
            const callbacks = panelCallbacks(layoutPath).filter(obj => obj.pathFilter());
            if (!callbacks.length) {
                resolve(false);
                return;
            }
            callbacks.find((callback) => {
                const nodeArrayGenerator = vnodeTreeSearch(rightPanel, callback.vNodeFilter);
                const resNode = nodeArrayGenerator.next()?.value;
                if (resNode) {
                    callback.nodeResCallback?.(resNode);
                    resolve({ callback, resNode });
                }
                return !!resNode;
            });
            resolve(false);
        };
        setTimeout(() => {
            try {
                reflectFn();
            }
            catch (e) {
                reject(e);
            }
        });
    });
}
/** 前面的nodeResCallback返回的结果 将作为下一次的root节点 */
async function execReflect(rootNode, callbackConfigs) {
    const configs = callbackConfigs.filter(config => config.pathFilter());
    for (const config of configs) {
        const nodeArrayGenerator = vnodeTreeSearch(rootNode, config.vNodeFilter);
        const resNode = nodeArrayGenerator.next()?.value;
        if (resNode) {
            const res = await config.nodeResCallback?.(resNode);
            if (res === 'abort') {
                return res;
            }
            if (typeof res === 'object') {
                // eslint-disable-next-line no-param-reassign
                rootNode = res;
            }
        }
    }
    return false;
}
/** 打开uicore的左右侧面板 */
export async function openSidePanel(designer, position, open = true) {
    await execReflect(designer, [
        {
            pathFilter: () => true,
            vNodeFilter: [
                node => getVueClassName(node) === 'Aside' && node.position === position,
            ],
            nodeResCallback: ([sidePanel]) => {
                set(sidePanel, 'isExpanded', open);
            },
        },
    ]);
}
export async function reflectDataSource(designer, sourceId, sourceObj, sourcePath, isOpenEditor) {
    await openSidePanel(designer, 'left');
    await Vue.nextTick();
    const materialBox = designer.$refs.materialBox;
    // 切到数据源面板
    materialBox.activeKey = 'data';
    await Vue.nextTick();
    const dataSourceFilters = {
        // 对应sourceId的数据源面板
        dataSource: node => getVueClassName(node) === 'DSTreeBase' && node.dataPath === `data.${sourceId}`,
        // 只包含标题区域的数据源节点
        dataSourceTitle: node => getVueClassName(node) === 'DSTreeNode' && node.dataPath === `data.${sourceId}`,
    };
    const cbDataSource = {
        pathFilter: () => true,
        vNodeFilter: [
            dataSourceFilters.dataSource,
            dataSourceFilters.dataSourceTitle,
        ],
        nodeResCallback: async ([dataSourceItem, dataSourceNode]) => {
            set(dataSourceItem, 'expanded', true);
            // 如果还有后续 先不做高亮
            if (sourcePath)
                return;
            dataSourceNode.$el.scrollIntoView({ block: 'center' });
            await waitUntilScrollFinish(dataSourceNode.$el);
            highlightVue([dataSourceItem]);
        },
    };
    // 展开数据源
    await execReflect(materialBox, [cbDataSource]);
    if (!sourcePath)
        return;
    // 处理sourcePath寻找对应的数据源方法
    if (get(sourcePath, [0]) !== 'methods')
        return;
    const methodIndex = parseInt(`${get(sourcePath, [1])}`, 10);
    if (isNaN(methodIndex))
        return;
    // 新数据源的methods跳转
    const methodId = get(sourceObj, ['methods', methodIndex, 'id']);
    // 需要跳转到 sourceId.$methods.[methodIndex] 的位置然后高亮
    // 找到方法按钮 并且展开数据源方法
    const cbMethodList = {
        pathFilter: () => true,
        vNodeFilter: [
            dataSourceFilters.dataSource,
            node => getVueClassName(node) === 'MethodList',
        ],
        nodeResCallback: async ([, methodList]) => {
            set(methodList, 'expanded', true);
            if (get(methodList, 'sourceConfig.folded')) {
                invoke(methodList, 'foldMethod');
            }
            await delay(0);
            return methodList;
        },
    };
    // 找到对应的数据源方法按钮
    const cbMethod = {
        pathFilter: () => true,
        vNodeFilter: [
            node => getVueClassName(node) === 'MethodItem' && get(node, ['schema', 'id']) === methodId,
        ],
        nodeResCallback: async ([methodItem]) => {
            methodItem.$el.scrollIntoView({ block: 'nearest' });
            await waitUntilScrollFinish(methodItem.$el);
            highlightVue([methodItem]);
            // 点开数据源方法编辑
            isOpenEditor && methodItem.onMethodClick?.();
        },
    };
    await execReflect(materialBox, [cbMethodList, cbMethod]);
}
