import { message } from 'ant-design-vue';
import { defineComponent, ref, toRefs, watch } from '@vue/composition-api';
import { permissionApi } from '@/services';
import usePageTags from '@/components/pageTag/usePageTags';
import ManagePermission from './ManagePermission';
import { injectPermState } from './PermState';
import { usePageList } from './utils';
export default defineComponent({
    name: 'ManagePermModal',
    props: {
        roleOrPerm: {
            type: Object,
            default: null,
        },
        projectId: {
            type: String,
            default: window.RUNTIME_CONFIGS?.projectId || '',
        },
        env: {
            type: String,
            default: 'dev',
        },
        showModal: {
            type: Boolean,
            default: false,
        },
        canUpdatePermission: {
            type: Boolean,
            default: false,
        },
        isPermSet: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const showPermModal = ref(false);
        const okBtnDisabled = ref(true);
        const roleView = ref({ rolePerms: [] });
        const permState = injectPermState();
        const { projectId } = toRefs(props);
        let { tagList, getTagList } = usePageTags(projectId);
        if (window.RUNTIME_CONFIGS) {
            const { basicTagList, getBasicTagList } = usePageTags(projectId);
            tagList = basicTagList;
            getTagList = getBasicTagList;
        }
        const { pageList: pages, fetchPageList: fetchPages } = usePageList(props.projectId, props.env);
        watch(() => props.showModal, () => {
            if (props.showModal) {
                showPermModal.value = true;
                fetchPages();
                getTagList();
            }
        });
        return {
            showPermModal,
            okBtnDisabled,
            permState,
            roleView,
            tagList,
            pages,
        };
    },
    render() {
        const hide = () => {
            this.showPermModal = false;
            this.$emit('hide');
        };
        const updateRolePermissions = async () => {
            this.okBtnDisabled = true;
            try {
                const permissions = this.roleView.rolePerms.map((rp) => {
                    const path = rp.pageId ? this.pages.find(i => i.pageId === rp.pageId)?.path ?? '' : '';
                    return { ...rp, path };
                });
                await permissionApi.updateRoleView(this.projectId, this.env, {
                    role: this.roleOrPerm.name,
                    permissions,
                });
                message.success('已保存');
                hide();
            }
            catch (error) {
                message.error('保存失败');
                this.okBtnDisabled = false;
            }
        };
        return <a-modal okButtonProps={{ props: { disabled: this.okBtnDisabled } }} visible={this.showPermModal} width="64%" destroyOnClose={true} onOk={() => updateRolePermissions()} onCancel={() => hide()}>
          <div slot="title" style="display:flex;">
            <span>{this.roleOrPerm.cname}</span>
            {this.isPermSet ? <a-tag color="orange" style="transform:scale(0.8)">权限集合</a-tag> : null}
          </div>
          {this.permState.panguMappingEnabled.value ? null : <template slot="footer">
              <a-button key="submit" onClick={() => hide()}>
                关闭
              </a-button>
            </template>}
          <ManagePermission key={this.roleOrPerm.name} projectId={this.projectId} env={this.env} role={this.roleOrPerm.name} pages={this.pages} tagList={this.tagList} mode={this.canUpdatePermission ? 'edit' : 'view'} maxHeight="60vh" isPermSet={this.isPermSet} onChange={(v) => ((this.roleView.rolePerms = v), (this.okBtnDisabled = false))} onRefresh={(v) => ((this.roleView.rolePerms = v), (this.okBtnDisabled = true))}/>
        </a-modal>;
    },
});
