import { get } from 'lodash';
import fetch from '@utils/fetch';
import stringInterop from '@tencent/ui-core/lib/utils/stringInterop';
import { getUserId } from '@utils/userInfo';
import { requestCollectionCgi, getTopSwitchStorage, isSwitchEnable } from '@utils/common';

const fetchFromDS = async (config, { projectId, env, ctx = {}, q = '' }) => {
  const { collectionId, cgiId } = config;
  const resp = await requestCollectionCgi(
    projectId, env, collectionId, cgiId,
    {
      query: { ...(ctx.query ?? {}), ...(config.searchKey ? { [config.searchKey]: q } : {}) },
      body: {},
    },
  );
  const result = resp?.body;
  if (!result) {
    console.error('topSwitch fetch from ds error, response with', resp);
  }
  return result;
};

const getUrl = (config, { projectId, env, ctx = {} }) => {
  const { url = '' } = config;
  if (!url) return '';

  const path = url.replace(/^https*:\/\//g, '');
  const { host, protocol } = location;
  let api = `${protocol}//${path}`;
  api = stringInterop(api, { env, projectId, host, rtx: getUserId(), ctx });
  return api;
};

const fetchOptions = async (config, { projectId, env, ctx = {}, q = '' }) => {
  const { listField, displayField, valueField } = config;
  const user = getUserId();
  const result = [];
  let resp;
  try {
    const api = getUrl(config, { projectId, env, ctx });
    if (api) {
      resp = await fetch(api, { headers: { staffname: user } });
    } else {
      resp = await fetchFromDS(config, { projectId, env, ctx, q });
    }
  } catch (err) {
    console.error('fetch switch widget options failed', err);
  }

  const options = get(resp, listField, []);
  options?.forEach?.((option) => {
    const label = get(option, displayField, '');
    // 值须为字符串才能匹配 url 上的 query string
    const value = String(get(option, valueField, ''));
    if (label || value) {
      result.push({ label, value });
    }
  });
  return result;
};

export const fetchSwitchOptions = async (config, { projectId, env, ctx = {}, q = '' }) => {
  let options = [];
  if (config.type === 'static') {
    ({ options } = config);
  } else {
    options = await fetchOptions(config, { projectId, env, ctx, q });
  }

  return options;
};

export const getBizConfig = (projectInfo) => {
  let config = null;
  const { bizIsolation, uiInfo } = projectInfo;
  if (bizIsolation) {
    const { widgets } = uiInfo.navlink;
    const widget = widgets.find(w => w.config.queryKey === bizIsolation && w.isShow);
    config = widget?.config;
  }

  return config;
};

/* 获取业务参数键值对 */
export const getBizEntry = (projectConfig) => {
  const { bizIsolation, id: projectId } = projectConfig || {};
  if (!bizIsolation) return null;

  const widgets = (projectConfig.uiInfo?.navlink?.widgets ?? []).filter(w => w.isShow);
  const config = widgets.find(w => w.config?.queryKey === bizIsolation)?.config;
  if (!isSwitchEnable(config)) return null;

  // 先从 query 上取值
  const searchParams = new URLSearchParams(location.search.substr(1));
  let bizId = searchParams.get(bizIsolation);

  // 再从 local storage 中取值
  if (!bizId && !config.disabledLocalStorage) {
    bizId = getTopSwitchStorage(projectId, bizIsolation);
  }

  // 再取默认值
  if (!bizId) {
    if (config.type === 'static') {
      // 静态: 取第一项
      bizId = config?.options?.[0]?.value;
    } else {
      // 动态: 取默认值
      bizId = config?.defaultValue;
    }
  }

  return {
    key: bizIsolation,
    value: bizId,
  };
};
