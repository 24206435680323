<template>
  <component
    :is="componentType"
    v-if="componentType"
    class="w-sys-modal"
    :visible="visible && isInit"
    :z-index="zIndex"
    v-bind="componentBind"
    @ok="handleOk"
    @close="handleCancel"
    @cancel="handleCancel"
  >
    <uc-renderer
      v-if="rendererType ==='ucRenderer'"
      :key="ucRendererKey"
      ref="ui-core"
      :data="uicorePlugin.getRootData()"
      :data-schema="uicorePlugin.getRootDataSchema()"
      :ui-lib="uicorePlugin.uiLib"
      :plugins="finalUcPlugins"
      :pagelets="pagelets"
      :pagelet-id="id"
      :components="wContext.components"
      :user-cache-key="wContext.uiCoreUserCacheKey"
      :flow-error-logger="$tips"
      :uc-static-renderer="true"
      :component-styles="wContext.projectConfig.uiInfo.componentStyles"
      @uc:report="$emit('uc:report', $event)"
    />
    <slot />
  </component>
</template>
<script>
import { mapState } from 'vuex';

import LessCodePlugin from '@tencent/ui-core/exts/LessCode';

import SchemaDefault from './xy-modal-schema-default.json';
import UICoreXiaoyaoPlugin from '../../components/uicorePlugin/UICoreXiaoyaoPlugin';
import { getModalZIndex } from './index';
import { INJECT_MODAL_INSTANCE } from './const';
import uicoreMixin from '@mixins/uicore-mixin';
import logger from '@utils/logger';
import { PageletContext } from '@utils/global/api';
import { generateCodeDeflate, getLessCodeProxyContext } from '@utils/lessCode';
import { useMobile } from '@/composables/useAdaptation';

const C_RENDERER_TAG = 'cRenderer';

export default {
  name: 'WSysModal',
  mixins: [uicoreMixin],
  provide() {
    return {
      w: this.w,
      ctx: this.wContext,
      wContext: this.wContext,
      [INJECT_MODAL_INSTANCE]: this,
    };
  },
  inject: {
    parentWContext: { from: 'wContext', default: null },
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'runtime',
    },
    pagelets: {
      type: Array,
      default() {
        return [];
      },
    },
    mergeParams: {
      type: Boolean,
      default: false,
    },
    params: {
      type: [Array, Object],
      default: () => [],
    },

    // 渲染方式，可能会是ucRenderer或者cRenderer
    rendererType: {
      type: String,
      default: 'ucRenderer',
    },

    // 全局弹窗参数
    globalModalId: {
      type: String,
      default: '',
    },
    keepAlive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isMobile } = useMobile();

    return {
      isMobile,
    };
  },
  data() {
    return {
      // ui-core
      uicorePlugin: new UICoreXiaoyaoPlugin({ parent: this }),
      lessCodePlugin: null,
      isInit: false,

      zIndex: getModalZIndex(),
      w: new PageletContext({ mode: this.mode, pageletContainer: this }),
      wContext: this.globalModalId
        ? this.parentWContext
        : {
          ...this.parentWContext,
          id: this.id,
          type: 'modal',
          env: null,
          config: this.parentWContext.config,
          source: this.parentWContext.source,
          projectConfig: this.parentWContext.projectConfig,
          props: {},
          data: {},
          dataManager: this.parentWContext.dataManager,
          components: this.parentWContext.components,
          childrenData: {},
          pageList: this.parentWContext.pageList,
          modalList: [],
          lessCode: null,
          parent: null,
          parentWContext: this.parentWContext,
          showModal: (id, data, opt) => {
            logger.info('[modal][Modal].show', id, this.id, opt);
            return this.handleShowModal(id, data, opt);
          },
          hideModal: (id, data) => {
            logger.info('[modal][Modal].hide', id, this.id, data);
            return this.handleHideModal(id, data);
          },
          // 刷新 ui-core 实例
          reloadUcRenderer: () => {
            this.parentWContext.reloadUcRenderer(this.id);
          },
          uiCoreUserCacheKey: this.parentWContext.uiCoreUserCacheKey,
        },

      componentType: '', // 弹窗展示类型
      componentBind: {}, // 弹窗配置

      ucRendererKey: Date.now().toString(36),
    };
  },
  computed: {
    ...mapState({
      env: state => state.app.env,
    }),
    eventTarget() {
      if (this.rendererType !== C_RENDERER_TAG) {
        return this.$refs?.['ui-core']?.getRefByPath('');
      }
      return this.$slots.default[0]?.componentInstance?.getRefByPath('');
    },
  },
  watch: {
    params: {
      handler(params) {
        this.wContext.data = params;
        // [废弃] mergeParams 兼容旧配置
        if (this.mergeParams) {
          if (this.wContext?.lessCode) {
            const renderer = this.wContext.lessCode;
            Object.keys(params).forEach((key) => {
              renderer.$set(renderer.state, key, params[key]);
            });
          }
        }
      },
      immediate: true,
      deep: true,
    },
    env: {
      handler(env) {
        this.wContext.env = env;
      },
      immediate: true,
    },
  },
  async created() {
    const pagelet = this.pagelets.find(p => p.id === this.id);
    const props = pagelet?.layout?.props ?? {};
    const type = props?.type ?? SchemaDefault.type;
    let keepAlive = props?.keepAlive ?? SchemaDefault.keepAlive;
    if (this.globalModalId) keepAlive = this.keepAlive;
    this.componentType = type === 'drawer' || (type === 'adaptation' && this.isMobile) ? 'a-drawer' : 'a-modal';
    this.setModalProps(props);

    this.lessCodePlugin = new LessCodePlugin({
      deflate: generateCodeDeflate(getLessCodeProxyContext({ w: this.w, $app: this.$app })),
    });

    this.$watch(
      () => this.visible && this.isInit,
      (ready) => {
        if (ready) {
          // [上下文] 赋值
          if (!this.globalModalId) {
            this.wContext.parent = this.parentWContext?.lessCode;
            this.wContext.parentWContext = this.parentWContext;
            this.wContext.components = this.parentWContext?.components;
          }

          this.w.pageInfo = this.parentWContext.config;
          this.$nextTick(() => {
            const renderer = this.rendererType === C_RENDERER_TAG
              ? this.$slots.default[0]?.componentInstance
              : this.$refs?.['ui-core']?.renderer;
            this.wContext.lessCode = renderer;
            this.w.renderer = renderer;

            this.eventTarget?.handleOpenEvent?.();

            this.w.renderer?.uc && this.$store.commit('layout/collectUcRenderer', { renderer: this.w.renderer });
          });
        }
      },
      { immediate: true },
    );

    /* 每次打开弹窗 z-index + 1, 保证弹窗层级按弹出顺序展示 */
    this.$watch('visible', (visible) => {
      if (visible) {
        this.zIndex = getModalZIndex();
        if (!keepAlive) this.reloadUcRenderer();
        // 由于渲染顺序的问题，通过CRenderer渲染的弹窗没办法在patchCRenderer阶段
        // 注入到w.renderer中，所以只能通过visible回调来触发
      } else {
        if (!keepAlive) {
          this.w.renderer?.uc && this.$store.commit('layout/changeUcRenderer', { renderer: this.w.renderer });
        }
      }
    });

    const { projectId, pageId } = this.parentWContext.config;
    await this.initPlugins(projectId, pageId);
    this.isInit = true;
  },
  destroyed() {
    this.w.renderer?.uc && this.$store.commit('layout/changeUcRenderer', { renderer: this.w.renderer });
  },
  methods: {
    /* 初始化弹窗的配置, xy-modal-container 里也会调用 */
    setModalProps(props = {}) {
      const newProps = { ...this.wContext.props, ...props };
      const {
        drawerPlacement = SchemaDefault.drawerPlacement,
        height = SchemaDefault.height,
        width = SchemaDefault.width,
        keyboard = SchemaDefault.keyboard,
        mask = SchemaDefault.mask,
        maskClosable = SchemaDefault.maskClosable,
        withClosableIcon = SchemaDefault.withClosableIcon,
      } = newProps;
      let { type = SchemaDefault.type } = newProps;
      let keepAlive = newProps?.keepAlive ?? SchemaDefault.keepAlive;
      if (this.globalModalId) keepAlive = this.keepAlive;

      // 移动端适配时调整 type 的值
      if (type === 'adaptation') {
        type = this.isMobile ? 'drawer' : 'modal';
      }

      // 弹窗配置
      const bind = {
        class: ['w-sys-modal'],
        bodyStyle: {},
        destroyOnClose: !keepAlive,
      };

      // 弹窗尺寸
      if (type === 'drawer' && ['top', 'bottom'].includes(drawerPlacement)) {
        bind.height = height;
      } else {
        bind.width = width;
      }

      if (type === 'drawer') {
        bind.placement = drawerPlacement;
        bind.afterVisibleChange = (visible) => {
          if (!visible) this.$emit('after-close');
        };
      } else {
        bind.centered = true;
        bind.footer = null;
        bind.afterClose = () => {
          this.$emit('after-close');
        };
      }

      // 是否因此蒙层
      bind.mask = mask;
      if (type === 'modal' && !mask) {
        bind.wrapClassName = 'w-sys-modal_none_pointer_events';
        bind.maskClosable = false;
        bind.keyboard = false;
      } else {
        bind.maskClosable = maskClosable;
        bind.keyboard = keyboard;
      }

      bind.closable = withClosableIcon;

      this.componentBind = bind;
      this.wContext.props = newProps;
    },
    async handleShowModal(id, data, opt) {
      return await this.parentWContext?.showModal?.(id || this.id, data, opt);
    },
    async handleHideModal(id, data) {
      // 事件流触发的关闭事件
      await this.eventTarget?.handleCloseEvent?.();
      return await this.parentWContext?.hideModal?.(id || this.id, data);
    },
    async handleHideGlobalModal() {
      // 事件流触发的关闭事件
      await this.eventTarget?.handleCloseEvent?.();
      this.$emit('after-close');
    },
    getLoggerInfo(functionName) {
      return `[${this.globalModalId ? 'Global Modal' : 'Modal'}] ${functionName} ${this.globalModalId ? this.globalModalId : this.id}`;
    },
    handleOk() {
      logger.info(this.getLoggerInfo('handleOk'));
      this.globalModalId ? this.handleHideGlobalModal() : this.handleHideModal(this.id, null);
    },
    handleCancel() {
      logger.info(this.getLoggerInfo('handleCancel'));
      this.globalModalId ? this.handleHideGlobalModal() : this.handleHideModal(this.id, null);
    },
    reloadUcRenderer() {
      this.ucRendererKey = Date.now().toString(36);
    },
  },
};
</script>

<style lang="scss">
.w-sys-modal {
  /* 弹窗 */
  .ant-modal {
    padding-bottom: 0px;
    /* 防止设置超过视窗宽高 */
    max-width: 98vw;
    .ant-modal-body {
      padding: 0;
    }
  }

  /* 抽屉 */
  .ant-drawer-body {
    height: 100%;
    padding: 0px;
  }
  /* 防止设置超过视窗宽高 */
  &.ant-drawer-right,
  &.ant-drawer-left {
    .ant-drawer-content-wrapper {
      max-width: 100vw;
    }
  }
  &.ant-drawer-bottom,
  &.ant-drawer-top {
    .ant-drawer-content-wrapper {
      max-height: 100vh;
    }
  }
}
.w-sys-modal_none_pointer_events {
  pointer-events: none;
}
</style>
