<template>
  <div class="xy-pageLoadingIndicator">
    <div
      class="xy-pageLoadingIndicator-fill"
      :class="`isStatus${status}`"
    >
      <div
        v-if="status !== 0"
        class="xy-pageLoadingIndicator-highlightAnimation"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLoadingIndicator',
  props: {
    loading: {
      type: [Boolean, Number],
      required: true },    // 可传入布尔值、数字等
  },
  data() {
    return {
      status: 0,   // 0 - 不展示  1 - 载入中  2 - 载入完成
    };
  },
  mounted() {
    if (this.loading) {
      setTimeout(() => {
        this.status = 1;
      }, 100);
    }

    this.$watch(() => !!this.loading, (loading) => {
      this.status = loading ? 1 : 2;
    });

    let lastTimer = 0;
    this.$watch(() => this.status, (status) => {
      if (lastTimer) {
        clearTimeout(lastTimer);
        lastTimer = 0;
      }

      // 载入完成的状态只能维持 300ms，随即进入“不展示”的状态
      if (status === 2) {
        lastTimer = setTimeout(() => {
          this.status = 0;
        }, 300);
      }
    });
  },
};
</script>

<style lang="scss">
.xy-pageLoadingIndicator {
  color: var(--wuji-primary-color);
  position: relative;  // 兼容
  position: sticky;
  top: 0;
}

$barSize: 2px;
.xy-pageLoadingIndicator-fill {
  position: absolute;
  left: 0;
  top: -$barSize / 2;
  height: $barSize;
  z-index: 1000;
  overflow: hidden;

  background-color: currentColor;
  box-shadow: 0 0 2px currentColor;

  transition: all 1s;
  opacity: 1;

  pointer-events: none;

  &.isStatus0 {
    transition-duration: 0s;
    width: 0;
  }

  &.isStatus1 {
    transition-duration: 2s;
    width: 85%;
  }

  &.isStatus2 {
    transition-duration: 0.2s;
    width: 100%;
    opacity: 0;
  }
}

.xy-pageLoadingIndicator-highlightAnimation {
  position: absolute;
  left: -30%;
  top: 0;
  width: 30%;
  height: 100%;
  background: linear-gradient(to right, transparent, #fff);
  opacity: 0.8;
  animation: xy-pageLoadingIndicator-highlightAnimation 2s ease-out infinite;
}

@keyframes xy-pageLoadingIndicator-highlightAnimation {
  0% {
    left: -30%;
  }
  100% {
    left: 130%;
  }
}
</style>
