import UserApprove from './entity/UserApprove';
import Index from './index';

const ssoRoute = {
  path: 'sso',
  name: 'sso',
  component: Index,
  meta: { layoutType: 'header-content' },
  children: [
    {
      path: '',
      redirect: 'entity',
    },
    {
      path: 'userApprove',
      name: 'sso.userApprove',
      component: UserApprove,
    },
  ],
};

export default ssoRoute;
