<template>
  <div style="height: 100%">
    <div class="diff_header">
      <div>{{ preTitle || '基础版本' }}</div>
      <slot name="rollback" />
      <div>{{ nextTitle || '本地版本' }}</div>
    </div>
    <div
      ref="diffContainer"
      style="height: calc(100% - 27px)"
      class="diff_container"
    />
  </div>
</template>

<script>
import monacoProvider from '@utils/monacoProvider';
import useEditConflict from '@composables/useEditConflict';
import { computed, ref } from '@vue/composition-api';

export default {
  name: 'DetailDiff',
  props: {
    preTitle: {
      type: String,
      default: '',
    },
    nextTitle: {
      type: String,
      default: '',
    },
    originalData: {
      type: String,
      require: true,
      default() {
        return '';
      },
    },
    modifiedData: {
      type: String,
      require: true,
      default() {
        return '';
      },
    },
    type: {
      type: String,
      default() {
        return 'json';
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const modifiedModel = ref();
    const diffEditor = ref();
    const editor = computed(() => diffEditor.value?.getModifiedEditor());
    const {
      updateConflicts,
    } = useEditConflict({ editor, currentLabel: '选择合入版本', incomingLabel: '选择当前版本' });

    return {
      diffEditor,
      modifiedModel,
      updateConflicts,
    };
  },
  data() {
    return {
      originalModel: null,
    };
  },
  watch: {
    originalData() {
      this.updateDiff();
    },

    modifiedData() {
      this.updateDiff();
    },
  },
  mounted() {
    monacoProvider((monaco) => {
      this.diffEditor =  monaco.editor.createDiffEditor(this.$refs.diffContainer, {
        enableSplitViewResizing: false,
        readOnly: this.readOnly,
        foldingStrategy: 'indentation',
        cursorBlinking: 'solid',
        automaticLayout: true,
        tabSize: 2,
        language: 'javascript',
        ...this.options,
      });
      this.updateDiff();
      // monaco有bug，滚动条不显示导致滚动条失效，js强制修改style
      setTimeout(() => {
        const scrollbars = document.getElementsByClassName('visible scrollbar vertical');
        for (let index = 0;index < scrollbars.length;index ++) {
          scrollbars[index].style.display = 'block';
          scrollbars[index].style.height = '18px';
        }
      }, 500);
      this.updateConflicts(true);
    });
  },
  beforeDestroy() {
    this.diffEditor && this.diffEditor.dispose();
  },
  methods: {
    updateDiff() {
      monacoProvider((monaco) => {
        this.originalModel = monaco.editor.createModel(this.originalData, this.type);
        this.modifiedModel = monaco.editor.createModel(this.modifiedData, this.type);
        this.diffEditor.setModel({
          original: this.originalModel,
          modified: this.modifiedModel,
        });
        const navi = monaco.editor.createDiffNavigator(this.diffEditor, {
          followsCaret: true, // resets the navigator state when the user selects something in the editor
          ignoreCharChanges: true, // jump from line to line
        });
        navi.next();
        this.addListeners();
        this.$emit('change', this.modifiedModel.getValue());
      });
    },
    // 监听编辑器的事件
    addListeners() {
      const modifiedModel = this.diffEditor.getModifiedEditor();

      // 文本被修改的事件监听
      modifiedModel.onDidChangeModelContent(() => {
        this.updateConflicts(false);
        this.$emit('change', modifiedModel.getValue());
      });
    },
  },

};
</script>

<style lang="scss" scoped>
.diff_header {
  background: #fff;
  display: flex;
  justify-content: space-around;
  & > div {
    text-align: center;
    line-height: 27px;
  }
}
</style>
