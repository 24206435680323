// @ts-ignore
import wujiComp from '@tencent/xy-compsloader';
import { compsLoaderLogger as logger } from './logger';
export const fetchCompDBInfo = (query, forceRefresh) => {
    const componentHubQuery = query?.projectId ? query : getComponentHubQuery();
    wujiComp.fetchCompDBInfo(componentHubQuery, forceRefresh);
    logger({
        functionName: 'fetchCompDBInfo',
        content: `初始化组件商店请求:${JSON.stringify(query, null, 2)}`,
    });
};
const getComponentHubQuery = () => {
    if (window?.RUNTIME_CONFIGS) {
        const { projectId, env } = window.RUNTIME_CONFIGS;
        return { projectId, projectEnv: env };
    }
    // 编辑时需要从页面路径中获取
    const query = new URLSearchParams(window.location.search);
    const projectId = query.get('projectId') || query.get('projectid');
    return {
        projectId,
    };
};
