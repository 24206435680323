import { CUSTOM_HOST_CONFIG, EXTERNAL_DEPLOY, HAS_OTHER_AUTH_SERVICE, IS_SHOW_XIAOMAN, IS_SHOW_AEGIS, IS_SHOW_AGENT_REPORT, OPEN_DESINGER_DEPLOY, OPEN_WUJI_NAVIGATOR, } from '@config/constant';
const projectInfo = '应用信息';
const version = '发布管理';
const auth = '授权';
const report = '日志 | 数据';
const permission = '权限管理';
const setting = '设置';
export const PAGES = {
    pageList: {
        englishName: ['pageList'],
        name: ['页面列表'],
        path: '/pagelist',
        keys: {
            addPage: '添加页面',
            labelManage: '标签管理',
        },
    },
    basic: {
        englishName: ['projectInfo', 'basic'],
        name: [projectInfo, '通用配置'],
        path: '/basic',
        keys: {
            id: '应用ID',
            name: '应用中文名',
            desc: '描述',
            projectType: '类型',
            productId: '运营产品',
            admin: '管理者',
            participant: '开发者',
            theme: '页面主题色',
            skin: '设计风格',
            navStyle: '默认布局风格',
            favicon: {
                title: '网页图标',
                desc: '网页图标(favicon)配置浏览器在标签页中显示的图标',
            },
            homePage: {
                title: '首页',
                desc: '应用首页和根路径(/)重定向的页面; 支持配置页面路径为 / 的页面作为应用默认首页',
            },
            page403: {
                title: '403 重定向',
                desc: '请求接口返回 403 时重定向的页面',
            },
            hideLoadingIndicator: {
                title: '隐藏页面进度条',
                desc: '是否隐藏页面顶部的加载进度条',
            },
            tipsStyle: {
                title: '消息提醒样式',
                desc: '定义页面运行时的错误消息如何展示，默认是“通知浮层”',
            },
            showWatermark: {
                title: '水印功能',
                desc: '这里开启的是全局水印功能(所有页面生效), 如果希望单个页面生效请到页面中单独设置',
            },
            xiaoman: {
                title: '小满反馈',
                desc: [
                    '这里开启的是正式环境的小满功能。',
                    '小满是一个满意度反馈的平台，其中小满ID是您的管理平台的唯一ID。',
                    '需要了解小满相关内容及生成小满ID可前往小满官网。',
                ],
            },
            aegis: {
                title: '前端监控',
                desc: [
                    '这里开启的是平台内置的运行时前端监控。',
                    '开启有助于平台更快速定位具体问题',
                ],
            },
            agentReport: {
                title: '代理上报',
                desc: [
                    '默认为关闭, 运行时使用前端直接上报',
                    '开启时, 运行时使用后台代理上报',
                ],
            },
            announcement: {
                title: '站内公告',
                desc: '这里开启的是编辑时站内公告配置，以及运行时站内公告展示',
            },
            customNav: {
                title: '应用导航',
                desc: '应用导航为运行时所有页面的导航配置, 优先级低于页面的单独配置',
            },
            customSidebar: {
                title: '应用菜单',
                desc: '应用菜单为运行时所有页面的菜单配置, 优先级低于页面的单独配置',
            },
            customHeader: {
                title: '应用页头',
                desc: '应用页头为运行时所有页面的页头配置, 优先级低于页面的单独配置',
            },
            customFooter: {
                title: '应用页脚',
                desc: '应用页脚为运行时所有页面的页脚配置, 优先级低于页面的单独配置',
            },
            visibility: '可见范围',
        },
    },
    advance: {
        englishName: ['projectInfo', 'advance'],
        name: [projectInfo, '扩展配置'],
        path: '/advance',
        keys: {
            customLayout: '自定义布局组件配置',
            routeGuard: '全局路由守卫',
            weworkApp: '企业微信应用配置',
            projectLessCode: '应用 LessCode',
            globalScriptSetting: '全局脚本配置',
            globalIcons: '全局图标配置',
            aegisConfig: '前端监控配置',
        },
    },
    navbar: {
        englishName: ['navbar'],
        name: ['顶部导航'],
        path: '/navbar',
        keys: {
            user: '用户信息',
            name: '应用名称',
            projectLogo: '应用Logo',
            env: '环境切换',
            help: '帮助文档',
            feedback: '我要反馈',
            wujiNavigator: '应用快捷切换面板',
            navFixTop: '固定头部导航',
            showBreadcrumb: {
                title: '显示页面层级关系',
                desc: '在导航下面使用面包屑来展示页面的层级关系，示例：<br/>'
                    + '一级目录 / 二级目录 / 页面xxx',
            },
            showTagsView: {
                title: '显示最近访问路由',
                desc: '在导航下面展示最近访问的页面，方便业务快速跳转',
            },
            showNavWaterMark: {
                title: '显示水印区分环境',
                desc: '开发环境和测试环境导航栏显示水印防止误操作',
            },
            widgets: {
                title: '公共查询参数',
                desc: '公共查询参数的配置以 key=value 的形式拼接在链接的查询参数中, 并且在顶部导航栏中显示切换参数的组件',
            },
            bizIsolation: '启用业务隔离',
            menuGroup: '菜单组',
            rightSelect: '右侧快捷入口',
            navbarComponent: '导航栏组件',
            defaultAvatar: '默认头像下拉菜单',
            customAvatar: '自定义头像下拉菜单',
            permission: '权限配置',
        },
    },
    sidebar: {
        englishName: ['sidebar'],
        name: ['左侧菜单'],
        path: '/menu',
        keys: {
            width: '菜单宽度',
            menuConfig: '菜单选项配置',
            withTitle: '显示应用标题',
            withMenuTitle: '显示菜单标题',
            openAllSubMenu: '默认展开子级菜单',
            accordion: {
                title: '手风琴模式',
                desc: '开启后左侧菜单只会展开当前选中的菜单项，其他菜单项将不会展开',
            },
            withSearch: '显示搜索框',
            menuSetting: '菜单配置',
        },
    },
    workflow: {
        englishName: ['workflow'],
        name: ['审批流'],
        path: '/workflow',
        keys: {},
    },
    flow: {
        englishName: ['flow'],
        name: ['工作流', '高级流程'],
        path: '/flow',
        keys: {},
    },
    flowJob: {
        englishName: ['job'],
        name: ['执行记录'],
        path: '/flow/job',
        keys: {},
    },
    flowConfig: {
        englishName: ['config'],
        name: ['全局配置'],
        path: '/flow/config',
        keys: {},
    },
    datasource: {
        englishName: ['datasource'],
        name: ['数据源'],
        path: '/collection/list',
        keys: {},
    },
    version: {
        englishName: ['version', 'versionRecord'],
        name: [version, '发布记录'],
        path: '/version',
        keys: {},
    },
    env: {
        englishName: ['version', 'env'],
        name: [version, '环境管理'],
        path: '/env',
        keys: {},
    },
    component: {
        englishName: ['component'],
        name: ['组件管理'],
        path: '/component',
        keys: {},
    },
    offline: {
        englishName: ['offline'],
        name: ['离线任务'],
        path: '/offline',
        keys: {},
    },
    base: {
        englishName: ['auth', 'base'],
        name: [auth, '应用相关授权'],
        path: '/auth/base',
        keys: {
            authApp: '授权数据库',
            authComponentHub: '组件商店授权',
        },
    },
    secretKey: {
        englishName: ['auth', 'secretKey'],
        name: [auth, '应用密钥'],
        path: '/auth/secretkey',
        keys: {},
    },
    cos: {
        englishName: ['auth', 'projectCOS'],
        name: [auth, '应用COS'],
        path: '/auth/cos',
        keys: {},
    },
    api: {
        englishName: ['auth', 'apiAuth'],
        name: [auth, '接入第三方鉴权'],
        path: '/auth/api',
        keys: {
            setAuthApi: '设置第三方鉴权接口',
            authCollectionId: {
                title: '后台鉴权数据源 ',
                desc: '先设置第三方鉴权接口类型的数据源',
            },
            permissionLink: '第三方权限系统链接',
        },
    },
    dslog: {
        englishName: ['report | dslog', 'dslog'],
        name: [report, '日志查询'],
        path: '/dslog',
        keys: {},
    },
    role: {
        englishName: ['permission', 'role'],
        name: [permission, '角色管理'],
        path: '/permission/role',
        keys: {},
    },
    user: {
        englishName: ['permission', 'user'],
        name: [permission, '用户管理'],
        path: '/permission/user',
        keys: {},
    },
    customPermission: {
        englishName: ['permission', 'customPermission'],
        name: [permission, '自定义权限'],
        path: '/permission/permission',
        keys: {},
    },
    apiPermission: {
        englishName: ['permission', 'apiPermission'],
        name: [permission, '接口管理'],
        path: '/permission/api',
        keys: {},
    },
    permissionAdvanceSetting: {
        englishName: ['permission', 'advanceSetting'],
        name: [permission, '高级配置'],
        path: '/permission/advance',
        keys: {},
    },
    announcement: {
        englishName: ['announcement'],
        name: ['站内公告', '公告管理'],
        path: '/announcement',
        keys: {},
    },
    branch: {
        englishName: ['branch'],
        name: ['分支管理'],
        path: '/settings/branch',
        keys: {
            enabledBranch: {
                title: '开启多分支',
                desc: '开启后默认分支为master分支，用户可以创建或者切换分支，分支之间的操作互相独立。',
            },
            protectBranch: {
                title: '分支保护',
                desc: '开启后用户将不允许直接在master进行修改，只能通过分支合并更新master',
            },
        },
    },
    publish: {
        englishName: ['setting', 'publish'],
        name: [setting, '发布设置'],
        path: '/settings/publish',
        keys: {
            deleteProject: '删除应用',
            needStaging: {
                title: '开启预发布环境',
                desc: '发布正式环境之前，默认先发布到预发布环境，验证通过后才继续发布到正式环境,流程如下：',
            },
            enabledProdVersionApproval: {
                title: '开启发版审批',
                desc: '版本需要通过审批获得发布到正式环境的资格后才能发布到正式环境，默认审批人为直接上级',
            },
            devToProd: '允许直接从开发环境发布版本到正式环境',
            pushHook: '开启发布或回滚推送流程',
        },
    },
    editor: {
        englishName: ['setting', 'editor'],
        name: [setting, '编辑器设置'],
        path: '/settings/editor',
        keys: {
            eslintEnabled: {
                title: '启用 ESLint 检查',
                desc: '配置后可以在 LessCode 编辑器内获得 ESLint 检查',
            },
            eslintConfig: {
                title: 'ESLint 配置',
            },
        },
    },
};
export const UPDATE_PAGE = {
    projectUpdate: {
        englishName: ['projectUpdate'],
        name: ['应用更新'],
        path: '/subProject/update',
        keys: {},
    },
};
export const BRANCH_PAGE = {
    branch: {
        englishName: ['branch'],
        name: ['分支管理'],
        path: '/branch',
        keys: {},
    },
};
export const REPORT_PAGE = {
    report: {
        englishName: ['report | dslog', 'report'],
        name: [report, '上报分析'],
        path: '/report',
        keys: {},
    },
};
export const HOST_PAGE = {
    host: {
        englishName: ['setting', 'shortPath'],
        name: [setting, '短路径配置'],
        path: '/settings/host',
        keys: {},
    },
};
export const AUTH_PAGE = {
    AuthSetting: {
        englishName: ['setting', 'AuthSetting'],
        name: [setting, '鉴权设置'],
        path: '/permission/setting',
        keys: {},
    },
};
export const ADVANCE_PAGE = {
    advanceSetting: {
        englishName: ['setting', 'advanceSetting'],
        name: [setting, '其他'],
        path: '/settings/advance',
        keys: {},
    },
};
export const EDITOR_PAGE = {
    advanceSetting: {
        englishName: ['setting', 'editor'],
        name: [setting, '编辑器设置'],
        path: '/settings/editor',
        keys: {},
    },
};
export const EMAIL_SETTING_PAGE = {
    advanceSetting: {
        englishName: ['setting', 'email'],
        name: [setting, '邮箱配置'],
        path: '/settings/email',
        keys: {},
    },
};
export const OPEN_PAGE = {
    openNetwork: {
        englishName: ['setting', 'openNetwork'],
        name: [setting, '外网设置'],
        path: '/settings/open',
        keys: {},
    },
};
// 是否为可以显示的搜索项（如：小满反馈为外网不显示的配置项）
async function isIncludeKey(key, store) {
    const { hasDeptInfoService, isSuper } = await import('@utils/userInfo');
    const basicKeys = PAGES.basic.keys;
    const visibility = store.state.project.projectInfo?.visibility;
    const customLayouts = window.xy_custom_layouts || [];
    if (key === basicKeys.orgName && !hasDeptInfoService())
        return false;
    if (key === basicKeys.visibility && !(isSuper() || visibility === '1'))
        return false;
    if (key === basicKeys.xiaoman.title && !IS_SHOW_XIAOMAN)
        return false;
    if (key === basicKeys.aegis.title && !IS_SHOW_AEGIS)
        return false;
    if (key === basicKeys.agentReport.title && !IS_SHOW_AGENT_REPORT)
        return false;
    if (key === PAGES.navbar.keys.wujiNavigator && !OPEN_WUJI_NAVIGATOR)
        return false;
    if (key === PAGES.advance.keys.customLayout && customLayouts.length <= 0)
        return false;
    return true;
}
export async function searchConfig(input) {
    const result = [];
    const { useStore } = await import('@/store');
    const store = useStore();
    const enabledBranch = store.state.project.settings?.versionSetting?.enabledBranch;
    const haveParent = !!store.state.project.baseProjectInfo?.parentId;
    const needToSearchPages = {
        ...PAGES,
        ...(haveParent ? UPDATE_PAGE : {}),
        ...(enabledBranch ? BRANCH_PAGE : {}),
        ...(!EXTERNAL_DEPLOY ? REPORT_PAGE : {}),
        ...(CUSTOM_HOST_CONFIG ? HOST_PAGE : {}),
        ...(HAS_OTHER_AUTH_SERVICE ? AUTH_PAGE : {}),
        ...ADVANCE_PAGE,
        ...(OPEN_DESINGER_DEPLOY ? OPEN_PAGE : {}),
    };
    const isMatched = (key) => {
        if (!key)
            return false;
        if (Array.isArray(key)) {
            return key.some(i => i.toLowerCase().includes(input.toLowerCase()));
        }
        return key.toLowerCase().includes(input.toLowerCase());
    };
    for (const page of Object.values(needToSearchPages)) {
        const englishName = page.englishName.join('/');
        const name = page.name.join('/');
        // 搜索一二级菜单
        if (isMatched(name)) {
            result.push({ path: page.path, englishName, chineseName: name, parentMenu: name });
        }
        // 搜索页面配置项
        for (const [key, value] of Object.entries(page.keys)) {
            const { title, desc } = typeof value === 'string' ? { title: value, desc: '' } : value;
            if (await isIncludeKey(title, store) && (isMatched(title) || isMatched(desc))) {
                result.push({ path: page.path, englishName: `${englishName}/${key}`, chineseName: title, parentMenu: `${name}/${title}` });
            }
        }
    }
    return result;
}
