<template>
  <a-form-model
    :model="editPagelet"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    style="width:100%"
  >
    <a-form-model-item ref="pageletAttrId">
      <span slot="label">
        {{ typeName }} id
        <a-tooltip>
          <template slot="title">
            修改{{ typeName }} id 需要手动更新 LessCode 和关联的组件配置
          </template>
          <a-icon
            style="opacity: 0.8; transform: translateY(1px)"
            type="question-circle"
          />
        </a-tooltip>
      </span>
      <a-input
        v-model="editPagelet.id"
        @blur="handleIdOrNameChange('id')"
      />
    </a-form-model-item>
    <a-form-model-item
      ref="pageletAttrName"
      :label="`${typeName}名称`"
      style="margin-top:16px"
    >
      <a-input
        v-model="editPagelet.name"
        @blur="handleIdOrNameChange('name')"
      />
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';
import { message } from 'ant-design-vue';
import { highlightRect } from '@tencent/ui-core/lib/utils/browser';

export default ({
  name: 'PageletAttr',
  mixins: [vueComponentMixin],
  inject: {
    designer: { from: 'uicore:designer' },
    xyPageConfig: { from: 'xyPageConfig' },
  },
  data() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      editPagelet: {
        id: this.designer?.pagelet?.id,
        name: this.designer?.pagelet?.name,
      },
    };
  },
  computed: {
    pagelet() {
      return this.designer?.pagelet;
    },
    typeName() {
      return this.pagelet?.xyType === 'modal' ? '弹窗' : '页面片';
    },
    pagelets() {
      return this.designer?.pagelets;
    },
  },
  watch: {
    pagelet: {
      handler(newPagelet) {
        this.editPagelet.id = newPagelet.id;
        this.editPagelet.name = newPagelet.name;
      },
      deep: true,
    },
  },
  methods: {
    handleIdOrNameChange(attr) {
      if (this.editPagelet[attr] === this.pagelet[attr]) return;
      const rect = attr === 'name'
        ? this.$refs.pageletAttrName.$el.getBoundingClientRect()
        : this.$refs.pageletAttrId.$el.getBoundingClientRect();
      if (!this.editPagelet[attr]) {
        highlightRect(rect, 500);
        message.error(`${this.typeName}${attr === 'name' ? '名称' : attr} 不能为空`);
        this.editPagelet[attr] = this.pagelet[attr];
      } else if (this.pagelets.some(item => item[attr] === this.editPagelet[attr])) {
        highlightRect(rect, 500);
        message.error(`${this.typeName}${attr === 'name' ? '名称' : attr} 已经存在`);
        this.editPagelet[attr] = this.pagelet[attr];
      } else if (attr === 'id' && !/^[a-zA-Z][\w-]*$/.test(this.editPagelet[attr])) {
        highlightRect(rect, 500);
        message.error('ID由字母、数字、下划线、中划线组成，且只能以字母开头');
        this.editPagelet.id = this.pagelet.id;
      } else this.refreshPagelets();
    },
    refreshPagelets() {
      this.pagelets.forEach((item) => {
        if (item.id === this.pagelet.id) {
          Object.assign(item, this.editPagelet);
        }
      });
      Object.assign(this.pagelet, this.editPagelet);
      // 修改后重新设置激活的页面片id
      this.xyPageConfig.handleSelectPagelet(this.editPagelet.id);
    },
  },
});
</script>

<style lang="scss" scoped>
::v-deep .ant-form-item {
  margin-bottom: 0px;
  .ant-form-item-label {
    text-align: left;
  }
}
</style>
