<template>
  <div>
    <span
      class="pending-icon"
      @click="goToEntityList"
    >
      <a-badge
        :count="taskNumber"
        :offset="[20,6]"
      >
        <Icon icon="bell" />
        <span>待办</span>
      </a-badge>
    </span>
  </div>
</template>

<script>
import { getAppParams } from '@utils/path';
import Icon from '@components/icon/Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    taskNumber: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    urlParams() {
      return getAppParams() || {};
    },
    projectId() {
      return this.urlParams.projectId;
    },
    env() {
      return this.urlParams.env;
    },
  },
  methods: {
    goToEntityList() {
      const branch = getAppParams('branch');
      const router = this.$router.resolve({ name: 'workflow.entity-list', query: { menu: 'pending', branch } });
      window.open(router.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.pending-icon {
  color: rgba(0, 0, 0, 0.9);
  padding: 10px 12px;
  line-height: 1.5;
  display: block;
  &:hover {
    background: var(--wuji-primary-bg-color);
    cursor: pointer;
  }
}
.router-link-active {
  color: var(--wuji-primary-color);
}
</style>
