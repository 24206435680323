<template>
  <a-layout :style="{...defaultWrapStyles, ...wrapStyles}">
    <a-layout-content :style="{...defaultStyles, ...styles}">
      <slot />
    </a-layout-content>
  </a-layout>
</template>
<script>
export default {
  props: {
    wrapStyles: {
      type: Object,
      default() {
        return {};
      },
    },
    styles: {
      type: Object,
      default() {
        return {};
      },
    },
    showActions: {
      type: Boolean,
      default() {
        return false;
      },
    },
    saveLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      defaultWrapStyles: {
        minHeight: 'calc(100vh - 96px)',
      },
      defaultStyles: {
        background: '#fff',
      },
    };
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleSave() {
      this.$emit('save');
    },
  },
};
</script>
<style lang="scss">
:root {
  --project-config-sidebar-width: 162px;
}

.page-layout-actions {
  height: 48px;
  background: #ffffff;
  box-shadow: 4px -4px 4px 0 rgba(0, 0, 0, 0.1);
  border-left: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  left: var(--project-config-sidebar-width);
  bottom: 0;
  padding: 16px;
  z-index: 2;
  button {
    margin: 0 8px;
  }
}
.page-layout-actions-modal {
  left: 0 !important;
}
.page-layout-actions-fixed {
  position: fixed;
  left: 0;
}
</style>
