import wujiFetch from '@utils/wujiFetch';
import { getDesignEnvPath } from '@config/constant';

const getPathPrefix = env => `${getDesignEnvPath(env)}/version`;

/**
  * 导出版本数据
  */
const exportProject = async ({ projectId, env, versionId, query = {} }) => {
  const params = new URLSearchParams({
    projectid: projectId,
    ...query,
  });
  return await wujiFetch(`${getPathPrefix(env)}/project/export/${versionId}?${params}`);
};

const checkUpdateApprovalStatus = async ({ projectId, env, versionId, query = {} }) => {
  const params = new URLSearchParams({
    projectid: projectId,
    ...query,
  });
  return await wujiFetch(
    `${getPathPrefix(env)}/project/approval/status?${params}`,
    { method: 'PATCH', body: JSON.stringify({ versionId }) },
  );
};

export default {
  exportProject,
  checkUpdateApprovalStatus,
};
