import { getServiceId } from '@/utils/serviceInfo';
import { keyBy } from 'lodash';
export const STANDARD_EVENTS = {
    pageview: 'pageview',
    pageView2: 'pageview2',
    pageout: 'pageout',
    click: 'click',
    customEvent: 'customEvent',
    onview: 'onview',
};
export const EVENTS = [{
        name: 'PV/UV',
        id: STANDARD_EVENTS.pageview,
    }, {
        name: '页面停留时长',
        id: STANDARD_EVENTS.pageout,
        isCustom: true, // 是否自定义指标
    }, {
        name: '元素点击',
        id: STANDARD_EVENTS.click,
    }, {
        name: '元素曝光',
        id: STANDARD_EVENTS.onview,
    }, {
        name: '元素行为',
        id: STANDARD_EVENTS.customEvent,
        desc: '除 “元素点击” 事件外的其他组件行为事件（比如切换选项卡，行为ID需填 “${组件ID}:${事件ID}”）',
    }];
export const STANDARD_INDEXES = {
    countAll: 'count_all',
    userCount: 'count_distinct_uin',
    userAvgCount: 'average_count_by_uin',
    stayAvg: 'avg',
    stayMax: 'max',
};
export const eventsById = keyBy(EVENTS, 'id');
const normalEvents = [
    STANDARD_EVENTS.pageview,
    STANDARD_EVENTS.click,
    STANDARD_EVENTS.customEvent,
    STANDARD_EVENTS.onview,
];
export const INDEXES = [{
        name: '总访问次数',
        id: STANDARD_INDEXES.countAll,
        events: normalEvents,
    }, {
        name: '总访问人数',
        id: STANDARD_INDEXES.userCount,
        events: normalEvents,
    }, {
        name: '人均次数',
        id: STANDARD_INDEXES.userAvgCount,
        events: normalEvents,
    }, {
        name: '停留时长[平均值:秒]',
        id: STANDARD_INDEXES.stayAvg,
        events: [STANDARD_EVENTS.pageout],
        customField: 'stayTime',
    }, {
        name: '停留时长[最大值:秒]',
        id: STANDARD_INDEXES.stayMax,
        events: [STANDARD_EVENTS.pageout],
        customField: 'stayTime',
    }];
export const indexesById = keyBy(INDEXES, 'id');
export const EXPANDABLE_DIMS = new Array(10).fill('')
    .map((item, index) => {
    const num = index + 1;
    return {
        id: `${item}${num}`,
        type: 'string',
        name: `扩展字段${num}`,
    };
});
/**
 * 公参
 */
export const DIMENSIONS = [
    {
        id: 'host',
        type: 'string',
        name: 'HOST',
    },
    {
        id: 'url',
        type: 'string',
        name: '上报url',
    },
    {
        id: 'ua',
        type: 'string',
        name: '浏览器版本',
    },
    {
        id: 'screenResolution',
        type: 'string',
        name: '屏幕分辨率',
    },
];
const compPath = {
    id: 'compPath',
    type: 'string',
    name: '组件路径',
};
/**
 * 各种类型的额外默认维度
 */
export const extDimensions = {
    [STANDARD_EVENTS.pageview]: [],
    [STANDARD_EVENTS.click]: [
        compPath,
    ],
    [STANDARD_EVENTS.customEvent]: [
        compPath,
    ],
    [STANDARD_EVENTS.onview]: [
        compPath,
    ],
    [STANDARD_EVENTS.pageout]: [],
};
export const NUMBER_EQ_CONDITION = '-eq';
export const STRING_EQ_CONDITION = '-is';
export const operations = {
    string: [
        {
            name: '开头匹配',
            id: '-slike',
        },
        {
            name: '结尾匹配',
            id: '-elike',
        },
        {
            name: '开头不匹配',
            id: '-snlike',
        },
        {
            name: '结尾不匹配',
            id: '-enlike',
        },
        {
            name: '匹配',
            id: '-like',
        },
        {
            name: '不匹配',
            id: '-nlike',
        },
        {
            name: '是',
            id: STRING_EQ_CONDITION,
        },
        {
            name: '不是',
            id: '-nis',
        },
    ],
    number: [
        {
            name: '等于',
            id: NUMBER_EQ_CONDITION,
        },
        {
            name: '不等于',
            id: '-neq',
        },
        {
            name: '大于',
            id: '-gt',
        },
        {
            name: '大于等于',
            id: '-ge',
        },
        {
            name: '小于',
            id: '-lt',
        },
        {
            name: '小于等于',
            id: '-le',
        },
        {
            name: '区间',
            id: '-btn',
            desc: '格式为：(x1,x2) 或 (x1,x2] 或 [x1,x2) 或 [x1,x2]，比如(0,100]表示大于0小于等于100',
        },
        {
            name: '无值',
            id: '-numnull',
            disabledInput: true,
        },
        {
            name: '有值',
            id: '-numnnll',
            disabledInput: true,
        },
        {
            name: '为空',
            id: '-numblank',
            disabledInput: true,
        },
        {
            name: '不为空',
            id: '-numnblank',
            disabledInput: true,
        },
    ],
};
export const orderFieldType = {
    ds: 'datetime',
    userId: 'dim',
    ftime: 'event_time_field',
    defaults: 'dim',
};
export const dimFieldType = {
    ds: {
        dimType: 'original_field',
        fieldComplexType: 'string',
        fieldType: 'string',
    },
    userId: {
        dimType: 'original_field',
        fieldComplexType: 'string',
        fieldType: 'string',
    },
    ftime: {
        dimType: 'original_field',
        fieldComplexType: 'yyyy-MM-dd HH:mm:ss',
        fieldType: 'datetime',
        dateGroup: 'day',
    },
    defaults: {
        dimType: 'original_field',
        fieldComplexType: 'string',
        fieldType: 'string',
    },
};
export const DATE_GROUP = [
    {
        id: 'min',
        name: '1分钟',
    }, {
        id: '10min',
        name: '10分钟',
    }, {
        id: 'hour',
        name: '小时',
    }, {
        id: 'day',
        name: '天',
    }, {
        id: 'week',
        name: '周',
    }, {
        id: 'month',
        name: '月',
    }, {
        id: 'year',
        name: '年',
    },
];
export const getDefaultDims = (projectId) => [{
        id: 'service',
        name: '服务ID',
        value: getServiceId(),
    }, {
        id: 'projectId',
        name: '应用ID',
        value: projectId,
    }];
