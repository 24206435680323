import userInfo from './user-info';
import scfLoader from '@loaders/scf/loader';
const axios = require('axios');;

const utils = {
  axios,
  userInfo,
  scfLoader,
};

const utilsLoader = {
  load(toolName) {
    if (!utils[toolName]) {
      throw new Error(`${toolName} is not define`);
    }
    return utils[toolName];
  },
  registry(toolName, tool) {
    utils[toolName] = tool;
  },
};

export default utilsLoader;
