import { message } from 'ant-design-vue';

export const isQuotaExceedError = (error) => {
  if (error.name === 'QuotaExceededError') {
    return true;
  }
};

export const promptMessage = (type, msg) => {
  message[type](msg);
};
