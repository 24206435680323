import { defineComponent, ref, toRefs, onBeforeMount } from '@vue/composition-api';
import CustomHeaderPagelet from './custom-header-pagelet';
import CustomHeaderComp from './custom-header-comp.vue';
import { useRuntimePage } from '@composables/useRuntimePagelet';
export default defineComponent({
    name: 'CustomHeader',
    components: {
        CustomHeaderPagelet,
        CustomHeaderComp,
    },
    props: {
        project: {
            type: Object,
            default: null,
        },
        renderId: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const { project, renderId } = toRefs(props);
        const { getPageList } = useRuntimePage(project.value.id);
        const isReady = ref(false);
        const isPage = ref(true);
        const pageList = ref([]);
        onBeforeMount(async () => {
            pageList.value = await getPageList();
            // 优先判断和渲染页面
            isPage.value = !!pageList.value.find(item => item.pageId === renderId.value);
            isReady.value = true;
        });
        return {
            isReady,
            isPage,
        };
    },
    render() {
        if (!this.isReady)
            return <template />;
        return (this.isPage
            ? <CustomHeaderPagelet project={this.project} renderId={this.renderId}/>
            : <CustomHeaderComp renderId={this.renderId}/>);
    },
});
