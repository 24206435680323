import { cloneDeep } from 'lodash';
import HostConfigService from '@/services/hostConfig';
export default {
    state: {
        hostConfigs: [],
    },
    actions: {
        // 获取短路径配置列表
        async getHostConfigList({ commit, state }, { projectId = '', isRuntime = false, cache = true } = {}) {
            if (!projectId)
                throw new Error('projectId is required');
            let hostConfigs = state.hostConfigs;
            if (hostConfigs.length === 0 || hostConfigs.some(config => config.projectId !== projectId) || !cache) {
                hostConfigs = await HostConfigService.getHostConfigList(projectId, isRuntime);
                commit('setHostConfigState', { hostConfigs });
            }
            return cloneDeep(hostConfigs);
        },
        async createHostConfig({ dispatch }, { projectId, data }) {
            const res = await HostConfigService.createHostConfig(projectId, data);
            if (res.code === 200) {
                dispatch('getHostConfigList', { projectId, cache: false });
            }
            return res;
        },
        // 更新短路径配置
        async updateHostConfig({ dispatch }, { projectId, id, data }) {
            const res = await HostConfigService.updateHostConfig(projectId, id, data);
            if (res.code === 200) {
                dispatch('getHostConfigList', { projectId, cache: false });
            }
            return res;
        },
        // 删除短路径配置
        async deleteHostConfig({ dispatch }, { projectId, id }) {
            const res = await HostConfigService.deleteHostConfig(projectId, id);
            dispatch('getHostConfigList', { projectId, cache: false });
            return res;
        },
    },
    mutations: {
        setHostConfigState(state, payload) {
            Object.assign(state, payload);
        },
    },
};
