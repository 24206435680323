<template>
  <a-menu
    id="xy-sidebar-menu"
    mode="inline"
    :inline-indent="22"
    :theme="sidebarTheme"
    :selected-keys="selectedKeys"
    :open-keys="openKeys"
    :inline-collapsed="false"
    @openChange="$emit('openChange', $event)"
  >
    <template v-for="(first) of menus">
      <!-- 有二级菜单 -->
      <a-sub-menu
        v-if="haveSubMenu(first)"
        :key="getMenuKey([first])"
        class="first-menu-item"
      >
        <template slot="title">
          <span>
            <public-w-icon-show-new
              v-if="showIcons[0] && isMenuWithIcon(first)"
              :value="getMenuIcon(first, `${first.name}`)"
            />
            <span class="ellipsis">{{ first.name }}</span>
          </span>
        </template>
        <!-- 二级菜单 -->
        <template v-for="second of getSubMenu(first)">
          <!-- 有三级菜单 -->
          <a-sub-menu
            v-if="haveSubMenu(second)"
            :key="getMenuKey([first, second])"
            class="second-menu-item"
          >
            <template slot="title">
              <span>
                <div
                  v-if="!isMenuWithIcon(first)"
                  class="icon-placeholder"
                />
                <public-w-icon-show-new
                  v-else-if="showIcons[1] && isMenuWithIcon(second)"
                  :value="getMenuIcon(second, `${first.name}.${second.name}`)"
                />
                <span class="ellipsis">{{ second.name }}</span>
              </span>
            </template>
            <!-- 三级菜单 -->
            <template
              v-for="third of getSubMenu(second)"
            >
              <!-- 有四级菜单 -->
              <a-sub-menu
                v-if="haveSubMenu(third)"
                :key="getMenuKey([first, second, third])"
                class="third-menu-item"
              >
                <template slot="title">
                  <span>
                    <div
                      v-if="!isMenuWithIcon(second)"
                      class="icon-placeholder"
                    />
                    <span class="ellipsis">{{ third.name }}</span>
                  </span>
                </template>
                <!-- 四级菜单 -->
                <a-menu-item
                  v-for="fourth of getSubMenu(third)"
                  :key="getMenuKey([first, second, third, fourth])"
                  class="forth-menu-item"
                  :disabled="isMenuDisabled(fourth)"
                  :title="third.name"
                  @click="$emit('click', fourth, getMenuKey([first, second, third, fourth]))"
                >
                  <div
                    v-if="!isMenuWithIcon(third)"
                    class="icon-placeholder"
                  />
                  <span class="ellipsis">{{ fourth.name }}</span>
                </a-menu-item>
              </a-sub-menu>
              <!-- 无四级菜单 -->
              <a-menu-item
                v-else
                :key="`${getMenuKey([first, second, third])}`"
                class="third-menu-item"
                :disabled="isMenuDisabled(third)"
                :title="third.name"
                @click="$emit('click', third, getMenuKey([first, second, third]))"
              >
                <div
                  v-if="!isMenuWithIcon(second)"
                  class="icon-placeholder"
                />
                <span class="ellipsis">{{ third.name }}</span>
              </a-menu-item>
            </template>
          </a-sub-menu>
          <!-- 无三级菜单 -->
          <a-menu-item
            v-else
            :key="`${getMenuKey([first, second])}`"
            class="second-menu-item"
            :disabled="isMenuDisabled(second)"
            :title="second.name"
            @click="$emit('click', second, getMenuKey([first, second]))"
          >
            <public-w-icon-show-new
              v-if="showIcons[1] && isMenuWithIcon(second)"
              :value="getMenuIcon(second, `${first.name}.${second.name}`)"
            />
            <div
              v-else-if="!isMenuWithIcon(first)"
              class="icon-placeholder"
            />
            <span class="ellipsis">{{ second.name }}</span>
          </a-menu-item>
        </template>
      </a-sub-menu>
      <!-- 无二级菜单 -->
      <a-menu-item
        v-else
        :key="`${getMenuKey([first])}`"
        class="first-menu-item"
        :disabled="isMenuDisabled(first)"
        :title="first.name"
        @click="$emit('click', first, getMenuKey([first]))"
      >
        <public-w-icon-show-new
          v-if="showIcons[0] && isMenuWithIcon(first)"
          :value="getMenuIcon(first)"
        />
        <span class="ellipsis">{{ first.name }}</span>
      </a-menu-item>
    </template>
  </a-menu>
</template>

<script>
import Vue from 'vue';
import { inject, toRefs } from '@vue/composition-api';

import useLayout from '@composables/useLayout';
import { useRouter } from '@/router/useRouter';
import { normalizeRouteConfig, isRegexPath } from '@utils/path';
import {
  getSubMenu,
  haveSubMenu,
  getMenuParams,
  getMenuPathParams,
  getMenuKey,
  getMenuUrl,
} from './utils';

export default {
  name: 'XySidebarMenu',
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
    selectedKeys: {
      type: Array,
      default: () => [],
    },
    openKeys: {
      type: Array,
      default: () => [],
    },
    showIcons: {
      type: Array,
      default: () => [true, false],
    },
  },
  setup(props) {
    const { openKeys } = toRefs(props);
    const { route } = useRouter();
    const w = inject('w', null);

    const {
      sidebarTheme,
    } = useLayout();

    function isMenuDisabled(menu) {
      try {
        const isPageMenu = menu?.args?.urlType === 'page';
        const originPath = getMenuUrl(menu);
        if (isPageMenu && isRegexPath(originPath)) {
          const { path } = normalizeRouteConfig({
            path: originPath,
            params: getMenuPathParams(menu),
            query: getMenuParams(menu),
            $route: route.value,
            stringInteropContext: {
              ...(w?.mainRenderer ?? {}),
              $app: Vue.prototype.$app,
              w,
            },
          });
          // 解析后依然为表达式的路径
          return isRegexPath(path);
        }
        return false;
      } catch (err) {
        return true;
      }
    }

    function isMenuWithIcon(menu) {
      return !!menu?.args?.icon;
    }

    function getMenuIcon(menu, key) {
      let icon = menu?.args?.icon || '';

      // 兜底图标的逻辑
      const isOpened = key && openKeys.value.includes(key);
      if (icon === 'folder' && isOpened) {
        icon = 'folder-open';
      }

      return icon;
    }

    return {
      sidebarTheme,

      getSubMenu,
      haveSubMenu,
      getMenuKey,

      isMenuDisabled,
      isMenuWithIcon,
      getMenuIcon,
    };
  },
};
</script>
