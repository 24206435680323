import { computed, inject } from '@vue/composition-api';
const MAGIC_PAGE_WIDTH = 414;
export const useMobile = () => {
    const designer = inject('uicore:designer', null);
    const screen = computed(() => window.xyScreenInfo?.screen ?? {
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false,
        xxl: false,
        xxxl: false,
    });
    // 是否为窄屏
    const isMobileOrNarrowScreen = computed(() => window.xyScreenInfo.isMobileOrNarrowScreen);
    // 是否页面设置为移动端
    const isMobilePage = computed(() => !!window.w?.mainPageInfo?.pageAttr?.isMobile);
    // 是否移动端预览
    const isMobileDesign = computed(() => designer?.pageWidth === MAGIC_PAGE_WIDTH);
    const isMobile = computed(() => isMobileOrNarrowScreen.value
        || isMobilePage.value
        || screen.value.xs
        || screen.value.sm
        || isMobileDesign.value);
    return {
        isMobile,
    };
};
