import { defineComponent, computed } from '@vue/composition-api';
import { message } from 'ant-design-vue';
import ModalFactory from '@/utils/modalFactory';
import styles from './styles/mergeModal.module.scss';
import { useStore } from '@store/index';
import MergeView from './MergeView';
import { applyPatches, findConflicts } from '@tencent/data-mapper';
import Sidebar from './Sidebar';
import { asyncNodeDiff3String, nodeDiff3String } from '@/utils/merge/merge-multi';
import { jsonToYaml, yamlToJson } from '@/utils/merge/jsonToYaml';
import { cloneDeep } from 'lodash';
import { useRouter } from '@/router/useRouter';
const NEED_DECODE_KEYS = ['bodyScript', 'headScript'];
const formatNodeDiffResult = (data) => {
    if (Array.isArray(data)) {
        const str = data.join('\n');
        return str;
    }
    return data;
};
export const MergeModal = defineComponent({
    name: 'MergeModal',
    props: {
        options: {
            type: Object,
            required: true,
        },
    },
    components: {
        MergeView,
    },
    emits: ['message'],
    setup() {
        const store = useStore();
        const currentBranch = computed(() => store.state.branch.currentBranch);
        return {
            currentBranch,
        };
    },
    data() {
        return {
            visible: true,
            calculating: false,
            conflicts: [],
            mergedPatches: [],
            commitMessage: '',
            selectedChangesKeys: {
                conflicts: [],
                mergedPatches: [],
            },
            selectDetailChange: null,
            isSubmitting: false,
        };
    },
    created() {
        this.initChanges();
    },
    methods: {
        async initChanges() {
            if (!this?.options?.changes)
                return;
            this.calculating = true;
            try {
                const conflicts = [];
                const ps = [];
                const mergedPatches = [];
                const findConflicts = async ({ schemaKey, objectId, value }) => {
                    const objectKey = `${schemaKey}.${objectId}`;
                    let { conflict } = value;
                    let content;
                    if (value.conflict) {
                        const result = await this.formatConflictItem(value);
                        conflict = result.conflict;
                        content = result.result;
                    }
                    else {
                        content = this.formatMergeItem(value);
                    }
                    const data = {
                        content,
                        key: objectKey,
                        schemaTitle: value.schemaTitle,
                        title: value.title,
                        conflict,
                        schemaKey: value.schemaKey,
                        id: objectId,
                    };
                    if (conflict) {
                        conflicts.push(data);
                    }
                    else {
                        if (data.content.before !== data.content.after) {
                            mergedPatches.push(data);
                        }
                    }
                };
                Object.entries(this.options.changes).forEach(([schemaKey, objectMap]) => {
                    Object.entries(objectMap).forEach(([objectId, value]) => {
                        ps.push(findConflicts({ schemaKey, objectId, value }));
                    });
                });
                await Promise.all(ps);
                this.conflicts = conflicts;
                this.mergedPatches = mergedPatches;
                if (this.conflicts.length === 0 && this.mergedPatches.length === 0) {
                    message.warning('没有需要合并的内容');
                    this.handleClose();
                }
                // 已合并的默认勾选
                if (this.mergedPatches.length > 0) {
                    this.selectedChangesKeys.mergedPatches = this.mergedPatches.map(item => item.key);
                }
                if (this.conflicts.length > 0) {
                    [this.selectDetailChange] = this.conflicts;
                }
                else if (this.mergedPatches.length > 0) {
                    [this.selectDetailChange] = this.mergedPatches;
                }
            }
            catch (err) {
                console.error(err);
                message.error('代码合并失败');
                this.visible = false;
            }
            finally {
                this.calculating = false;
            }
        },
        formatToYAML(data) {
            const obj = cloneDeep(data);
            // 这里针对项目信息的headScript和bodyScript进行decode是为了对比起来更直观
            NEED_DECODE_KEYS.forEach((key) => {
                if (obj?.advanceConfig?.[key]) {
                    obj.advanceConfig[key] = decodeURIComponent(obj.advanceConfig[key]);
                }
            });
            return jsonToYaml(obj);
        },
        formatToJSON(data) {
            const obj = yamlToJson(data);
            // 这里针对项目信息的headScript和bodyScript进行encode避免报错
            NEED_DECODE_KEYS.forEach((key) => {
                if (obj?.advanceConfig?.[key]) {
                    obj.advanceConfig[key] = encodeURIComponent(obj.advanceConfig[key]);
                }
            });
            return obj;
        },
        async formatConflictItem(data) {
            const { minePatches, theirsPatches } = data.patches || [];
            const { baseData } = data;
            const beforeObj = applyPatches(baseData, minePatches);
            const afterObj = applyPatches(baseData, theirsPatches);
            const base = this.formatToYAML(baseData);
            const local = this.formatToYAML(beforeObj);
            const remote = this.formatToYAML(afterObj);
            const useWorker = useRouter()?.route?.value?.query?.worker !== '0';
            const diffFunction = useWorker ? asyncNodeDiff3String : nodeDiff3String;
            const mergedResult = await diffFunction({
                base,
                local,
                remote,
                type: 'yaml',
                localLabel: this.options.currentBranch,
                remoteLabel: this.options.mergeBranch,
            }, { returnConflict: true });
            return {
                conflict: mergedResult.conflict,
                result: {
                    before: this.formatToYAML(beforeObj),
                    after: formatNodeDiffResult(mergedResult.result),
                },
            };
        },
        formatMergeItem(data) {
            const { minePatches, theirsPatches } = data.patches || [];
            const { mergedPatches } = findConflicts(minePatches, theirsPatches);
            const { baseData } = data;
            const beforeObj = applyPatches(baseData, minePatches);
            const afterObj = applyPatches(baseData, mergedPatches);
            return {
                before: this.formatToYAML(beforeObj),
                after: this.formatToYAML(afterObj),
            };
        },
        handleClose() {
            this.$nextTick(() => {
                this.visible = false;
                this.$emit('message', {
                    success: false,
                    message: 'cancel',
                });
            });
        },
        handleShowDetailChange(data) {
            this.selectDetailChange = data;
        },
        handleChangeMergeItem(data) {
            this.conflicts = [...this.conflicts.map((item) => {
                    if (item.key === data.key) {
                        return data;
                    }
                    return item;
                })];
            this.selectDetailChange = data;
        },
        handleSelect(type, value) {
            this.selectedChangesKeys[type] = value;
        },
        async handleOk() {
            try {
                this.isSubmitting = true;
                // 校验提交描述
                if (!this.commitMessage)
                    return message.warn('提交描述不允许为空');
                // 筛选勾选的提交变更
                const selectedConflictChanges = this.conflicts
                    .filter(item => this.selectedChangesKeys.conflicts.includes(item.key));
                const selectedMergedChanges = this.mergedPatches
                    .filter(item => this.selectedChangesKeys.mergedPatches.includes(item.key));
                const selectedData = [...selectedConflictChanges, ...selectedMergedChanges];
                if (selectedData.length === 0)
                    return message.warn('未选择任何变更');
                // 转化提交的数据
                const data = [];
                let formatPass = true;
                selectedData.forEach((item) => {
                    try {
                        const value = this.formatToJSON(item.content.after);
                        data.push({
                            id: item.id,
                            schemaInfo: {
                                id: item.schemaKey,
                            },
                            compareData: value,
                        });
                    }
                    catch (err) {
                        message.error(`[${item.title}] 数据合并失败, 请检查是否还有冲突未解决`);
                        formatPass = false;
                    }
                });
                if (!formatPass)
                    return;
                // 提交到服务端;
                const { onConfirm } = this.options;
                if (onConfirm) {
                    const res = await onConfirm(data, this.commitMessage);
                    this.$emit('message', {
                        success: true,
                        data: res,
                    });
                }
                else {
                    this.$emit('message', {
                        success: true,
                        data: {
                            data,
                            desc: this.commitMessage,
                        },
                    });
                }
            }
            finally {
                this.isSubmitting = false;
            }
        },
    },
    render() {
        return (<a-drawer onClose={this.handleClose} title={this.options.title || '提交变更'} placement="bottom" wj-mod="版本发布" visible={this.visible} height="100%" wrap-class-name={styles.commit_drawer_container}>
        <div class={styles.container}>
          <Sidebar conflicts={this.conflicts} selectedKeys={this.selectedChangesKeys} mergedPatches={this.mergedPatches} onChange={this.handleShowDetailChange} onSelect={this.handleSelect} selectDetailChange={this.selectDetailChange}/>
          <div class={styles.editor_wrap_right}>
            <div style="flex: 1">
              {this.selectDetailChange && (<MergeView style="height:100%" change={this.selectDetailChange} onChange={this.handleChangeMergeItem} currentBranch={this.options.currentBranch} mergeBranch={this.options.mergeBranch}/>)}
            </div>
            <div class={styles.commit_action}>
              <a-textarea style="flex: 1" placeholder="提交信息（必填）" vModel={this.commitMessage} rows="4"/>
              <div class={styles.commit_action_buttons}>
                <a-button loading={this.isSubmitting} onClick={this.handleClose} style="margin-right: 12px">
                 取消
                </a-button>
                <a-button loading={this.isSubmitting} onClick={this.handleOk} type="primary">
                  提交
                </a-button>
              </div>
            </div>
          </div>

          <a-modal title="代码合并" visible={this.calculating} closable={false} centered={true} onOk={() => { }} onCancel={() => { }} footer={null}>
            <div style={{ display: 'flex', 'justify-content': 'center', padding: '20px' }}>
              <a-spin tip="代码合并计算中, 请耐心等待"/>
            </div>
          </a-modal>
        </div>
      </a-drawer>);
    },
});
const open = ModalFactory(MergeModal);
export const openMergeModal = async (options) => {
    await open(options);
};
