// import pageLoader from '@loaders/page/loader';

export default {
  type: 'object',
  fields: [
    {
      id: 'pageletId',
      type: 'string',
      title: '页面片',
      ui: { type: 'XyInnerPageSelector' },
      default: '',
    },
    {
      id: 'params',
      type: 'array',
      title: '页面片参数 (页面片通过 props.xxx 取参)',
      ui: {
        type: 'XyPageletPropsEditor',
        props: {
          allowBind: true,
          async getPagelet(inputBox) {
            const { pageletId } = inputBox.container.stub.parent.value;
            const pagelets = inputBox?.wContext?.config?.pageConfig ?? [];
            return pagelets.find(x => x.id === pageletId);
          },
        },
      },
      items: {
        type: 'object',
        fields: [
          { id: 'key', type: 'string' },
          { id: 'value', type: 'string' },
          { id: 'bind', type: 'string' },
        ],
      },
    },
  ],
};
