import { defineComponent, toRefs, ref, computed } from '@vue/composition-api';
import CodeConflictEditor from '@/components/merge/TextMerge/CodeConflictEditor';
import CodeChecker from '@/components/merge/TextMerge/CodeChecker';
import styles from './merge.module.scss';
import { isPlainObject, cloneDeep } from 'lodash';
export default defineComponent({
    name: 'MultiConflictsModal',
    props: {
        options: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { options } = toRefs(props);
        const source = ref(cloneDeep(options.value.source));
        const title = ref(options.value.title);
        const conflictSource = computed(() => source.value.filter(item => item.conflict));
        const mergedSource = computed(() => source.value.filter(item => !item.conflict));
        const confirmDisabled = computed(() => conflictSource.value.length > 0);
        const visible = ref(true);
        const loading = ref(false);
        const currentNode = ref(conflictSource.value[0]);
        const handleConfirm = () => {
            emit('message', { success: true, data: source.value });
            visible.value = false;
        };
        const handleCancel = () => {
            emit('message', { success: false });
            visible.value = false;
        };
        const renderConflictList = () => (<div class={styles.editor_wrap_left}>
        <div style="margin-bottom: 12px">
          <div class={styles.conflict_title}>
            <a-icon type="caret-down" class={styles.conflict_title_icon}/>
            <span>待解决</span>
          </div>
          {conflictSource.value.map(item => (<div onClick={() => currentNode.value = item} class={[styles.conflict_item, currentNode.value === item ? styles.selected : null]}>
              <i class="fa fa-exclamation-triangle" aria-hidden="true" style="margin-right: 4px; color: #fa8c16;"/>
              <span>{item.title}</span>
            </div>))}
        </div>
        <div>
          <div class={styles.conflict_title}>
            <a-icon type="caret-down" class={styles.conflict_title_icon}/>
            <span>已合并</span>
          </div>
          {mergedSource.value.map(item => (<div onClick={() => currentNode.value = item} class={[styles.conflict_item, currentNode.value === item ? styles.selected : null]}>
              <i class="fa fa-check-square" aria-hidden="true" style="margin-right: 4px; color: #52c41a; font-size: 16px;"/>
              <span>{item.title}</span>
            </div>))}
        </div>
      </div>);
        const handleConflictResolved = (data) => {
            if (currentNode.value) {
                currentNode.value.conflict = false;
                currentNode.value.result = data;
            }
        };
        const handleConflictCancel = () => { };
        const renderCodeEditor = () => {
            let result = '';
            if (Array.isArray(currentNode.value?.result)) {
                result = currentNode.value?.result?.join('\n') ?? '';
            }
            else if (isPlainObject(currentNode.value?.result)) {
                result = JSON.stringify(currentNode.value?.result, null, 2);
            }
            else if (typeof currentNode.value?.result === 'string') {
                result = currentNode.value?.result;
            }
            const renderConflictEditor = () => {
                if (!currentNode.value)
                    return null;
                return (<CodeConflictEditor key={currentNode.value.title} result={result} localCode={currentNode.value.local} autoParse={false} onChange={handleConflictResolved} onCancel={handleConflictCancel} isFullScreen={false} enableSkipMerge={true} enableToggleFullScreen={false} type={currentNode.value.type}/>);
            };
            const renderCodeChecker = () => {
                if (!currentNode.value)
                    return null;
                return (<CodeChecker key={currentNode.value.title} code={result} type={currentNode.value.type}/>);
            };
            return (<div class={styles.editor_wrap_right}>
          {currentNode.value?.conflict ? renderConflictEditor() : renderCodeChecker()}
        </div>);
        };
        const renderModalFooter = () => (<template slot="footer">
        <a-button key="cancel" onClick={handleCancel}>
          取消合并
        </a-button>
        <a-button key="confirm" type="primary" disabled={confirmDisabled.value} loading={loading.value} onClick={handleConfirm}>
          提交合并
        </a-button>
      </template>);
        return {
            visible,
            source,
            title,
            handleConfirm,
            handleCancel,
            renderConflictList,
            renderCodeEditor,
            renderModalFooter,
        };
    },
    render() {
        return (<a-modal width="95vw" visible={this.visible} title={this.title || '解决冲突'} onCancel={this.handleCancel} centered closable={false} keyboard={false} zIndex={2000} wrapClassName={styles.modal}>
        <div class={styles.editor_wrap}>
          {this.renderConflictList()}
          {this.renderCodeEditor()}
        </div>
        {this.renderModalFooter()}
      </a-modal>);
    },
});
