import { defineComponent, toRefs } from '@vue/composition-api';
import './index.scss';
import { getProps } from '@components/simpleModal/openSimpleModal';
import { getDocLink } from '@/utils/links';
import { toggleCliHttps } from '@/config/cli-settings';
const chromeTips = [
    {
        type: 'console-error',
        args: {
            console: 'GET http://localhost:port/ net::ERR_CONNECTION_REFUSED',
        },
        solutions: [{
                text: 'CLI工具未启动调试命令，请检测后重试',
            }],
    },
    {
        type: 'console-error',
        args: {
            console: 'GET http://localhost:port/ net::ERR_EMPTY_RESPONSE',
        },
        solutions: [{
                text: '本地启动的是https服务，但是客户端请求的是http的接口。',
                btnText: '切换到https',
                btnAction: () => {
                    toggleCliHttps(true, { reload: true });
                },
            }],
    },
    {
        type: 'console-error',
        args: {
            console: 'GET https://localhost:port/ net::ERR_SSL_PROTOCOL_ERROR',
        },
        solutions: [{
                text: '本地启动的是http服务，但是客户端请求的是https的接口。',
                btnText: '切换到http',
                btnAction: () => {
                    toggleCliHttps(false, { reload: true });
                },
            }, {
                text: '本地开发者工具启动https调试模式',
                btnText: '开发工具如何开启https本地服务',
                btnAction: () => {
                    window.open(getDocLink().HTTPS_DEVTOOL);
                },
            }],
    },
    {
        type: 'console-error',
        args: {
            console: 'Failed to load resource:net::ERR_SSL_PROTOCOL_ERROR',
        },
        solutions: [{
                text: 'https页面禁止加载http资源，因此加载本地开发资源失败。',
                btnText: '页面使用http重新加载',
                btnAction: () => {
                    window.location.href = window.location.href.replace(/^https/, 'http');
                },
            }],
    },
    {
        type: 'console-error',
        args: {
            console: 'blocked by CORS policy: The request client is not a secure context and the resource is in more-private address space `local`',
        },
        solutions: [{
                image: 'https://vfiles.gtimg.cn/vupload/20211019/7e61b91634629215335.png',
                text: '浏览器禁止请求localhost，请打开 <span class="highlight-font">chrome://flags/#block-insecure-private-network-requests </span> 设置 Block insecure private network requests 为 disabled',
            }],
    },
];
const tips = navigator.userAgent.includes('Chrome') ? chromeTips : null;
export default defineComponent({
    name: 'localLoadErrorModal',
    props: {
        ...getProps(),
    },
    setup(props) {
        const { context } = toRefs(props);
        return {
            debugType: context.value?.debugType || '模块',
            tips,
        };
    },
    render() {
        return (<a-modal visible={true} ok-text="确定" class="local-load-error-modal" cancel-text="取消" centered title={`很遗憾，加载本地开发${this.debugType}失败`} width="800px" onOk={this.onSuccess} onCancel={this.onCancel}>
        {this.tips ? (<div class="content-box">
            请求本地开发接口失败，通常为浏览器设置限制导致，可以按照以下指示逐一排查：
            {this.tips.map((item, index) => (<div class="content-item">
                <div>
                  <a-icon class="content-item-icon" type="exclamation-circle"/>
                  场景{index + 1} - 控制台报错：
                  <div class="console-error">{item.args.console}</div>
                </div>
                {item.solutions.map((solution, index) => (<div class="contenet-item-solution">
                  <a-icon class="content-item-icon" type="alert"/>
                  解决方法{index + 1}：<span domPropsInnerHTML={solution.text}></span>
                  {solution.btnText ? (<a-button type="link" onClick={solution.btnAction}>
                      {solution.btnText}
                    </a-button>) : null}
                  {solution.image ? <img class="pic" src={solution.image}/> : null}
                </div>))}
               </div>))}
          </div>) : ('请求本地开发接口失败，请使用Chrome浏览器进行开发')}
      </a-modal>);
    },
});
