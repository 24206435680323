/* eslint-disable no-underscore-dangle */
import { FIELDS_LIST_API } from '../config/constant';
import wujiFetch from './wujiFetch'; // eslint-disable-line
import { flattenToFields } from '@tencent/wuji-utils';
import { fromWujiFieldInfo } from '@tencent/data-schema-core/lib/convertor/wuji';
import { message } from 'ant-design-vue';
import isPlainObject from 'lodash/isPlainObject';
import logger from './logger';

export async function httpGetWujiField(appId, schemaId, projectId) {
  const fieldsListApi = FIELDS_LIST_API
    .replace('{{appid}}', appId)
    .replace('{{schemaid}}', schemaId)
    .replace('{{projectid}}', projectId);
  const result = await wujiFetch(fieldsListApi, {
    method: 'GET',
  });
  return { data: result };
}
// 判断是否拥有主键ID
export function haveIndexId(fields) {
  if (fields.length <= 0) return {};
  const res = fields.filter(item => [100, 101, 102, 103].includes(item.type));
  return !!res[0];
}
// 获取主键ID，如果没有，则创建一个默认的_id
export function getIndexId(fields) {
  let res;
  if (fields.length <= 0) return {};
  res = fields.filter(item => [100, 101, 102, 103].includes(item.type));
  if (!res[0]) {
    // 虚拟一个字段
    res = {
      id: '_id',
      args: '',
      desc: '默认主键',
      maxlength: '',
      name: '默认主键',
      notAutoSpace: false,
      pattern: '',
      readonly: true,
      required: true,
      score: 9,
      sort: '',
      triggers: '',
      type: 102,
      unique: false,
      width: 0,
    };
  } else {
    [res] = res;
  }

  return res;
}
export function wujiField2dataSchema(wujiFieldsRes) {
  const wujiFields = wujiFieldsRes.data.fields;
  if (wujiFields.length <= 0) return {};
  const res = {
    type: 'object',
    fields: [],
  };
  wujiFields.forEach((item) => {
    res.fields.push(fromWujiFieldInfo(item));
  });
  return res;
}

async function getSchemaFields(selectAppId, selectSchemaId, projectId) {
  const result = await httpGetWujiField(selectAppId, selectSchemaId, projectId);
  let dataSchema;
  if (result.data.type === 'idl' && result.data.idlInfo) {
    // TODO: 这里应该还是有问题，只支持打平了一层的字段，如果是多维，这里应该还要递归
    const fields = flattenToFields(result.data.idlInfo.dataSchema.fields, '', true);
    result.data.fields = fields;
    ({ dataSchema } = result.data.idlInfo);
  }
  // 判断是否需要补齐一个_id的默认主键
  if (!haveIndexId(result.data.fields)) {
    result.data.fields.push(getIndexId(result.data.fields));
  }
  if (!dataSchema) {
    dataSchema = wujiField2dataSchema(result);
  }
  return {
    appId: selectAppId,
    appid: selectAppId,     // TODO 兼容旧代码的写法，后续要删除
    schemaId: selectSchemaId,
    tableCName: `${result.data.id}${(result.data.name && `(${result.data.name})`) || (result.data.desc ? `(${result.data.desc.replace(/(<([^>]+)>)/gi, '')})` : '')}`,
    fields: result.data.fields,
    // 增加dataSchema
    dataSchema,
  };
}

/**
 * 传入选中的无极数据源，做相关的处理
 * @param {*} selectSchemas
 * @param {*} dataSource
 * @param {*} wujiHost
 * @param {*} pageData
 */
export async function wujiDataSourceProcess(selectSchemas, dataSource, pageData, projectId) {
  const assignPageData = pageData;
  // selectSchemas为空时, 直接返回
  if (!Array.isArray(selectSchemas) || selectSchemas.length === 0) {
    assignPageData.selectSchemas = [];
    return;
  }
  const getRequest = [];
  selectSchemas.forEach((schemaPattern) => {
    // 处理字符模板 (`appId:appId:schemaId`), 加载数据源数据
    if (typeof schemaPattern === 'string') {
      const [, selectAppId, selectSchemaId] = schemaPattern.split(':');
      dataSource.forEach((data) => {
        if (data && data.schemas) {
          const [selectSchemaData] = data.schemas.filter((schema) => {
            const isMatch = schema.id === selectSchemaId && data.appId === selectAppId;
            return isMatch;
          });
          if (selectSchemaData) {
            getRequest.push(getSchemaFields(selectAppId, selectSchemaId, projectId));
          }
        }
      });
    }
  });

  // 当没有需要 schemaPattern 加载数据时, 不处理
  if (getRequest.length > 0) {
    const res = await Promise.all(getRequest);
    const map = {};
    res.forEach((item) => {
      map[`${item.appId}:${item.appId}:${item.schemaId}`] = item;
    });
    const newSchemas = [];
    selectSchemas.forEach((schemaPattern) => {
      if (typeof schemaPattern === 'string') {
        if (map[schemaPattern]) {
          newSchemas.push(map[schemaPattern]);
        }
      } else {
        newSchemas.push(schemaPattern);
      }
    });
    assignPageData.selectSchemas = newSchemas;
  }

  // selectSchemas 校验逻辑
  assignPageData.selectSchemas.forEach((schema) => {
    if (typeof schema === 'string') {
      message.error(`${schema} 数据源解析失败, 请检查授权数据库的配置`);
    }
  });
}

/**
 *自动根据link对象字段，选中从表数据源和补全数据源关系
 * @param {*} selectSchemas    无极选中的数据源
 * @param {*} dataSourceRelation    数据源关系列表
 * @param {*} dataSource     包含具体详细信息的全量数据源列表
 * @param {*} wujiHost   无极host域名
 */
export async function checkLinkField(selectSchemas, dataSourceRelation, dataSource, projectId) {
  for (let i = 0; i < selectSchemas.length; i++) {
    const selectSchema = selectSchemas[i];
    const [, selectAppId, selectSchemaId] = selectSchema.split(':');
    let selectSchemaData;
    for (let j = 0; j < dataSource.length; j++) {
      const data = dataSource[j];
      if (data.schemas) {
        [selectSchemaData] = data.schemas.filter((schema) => {
          const isMatch = schema.id === selectSchemaId && data.appId === selectAppId;
          return isMatch;
        });

        if (selectSchemaData) {
          const result = await httpGetWujiField(selectAppId, selectSchemaId, projectId);

          if (result.data.type === 'idl' && result.data.idlInfo) {
            // TODO: 这里应该还是有问题，只支持打平了一层的字段，如果是多维，这里应该还要递归
            const fields = flattenToFields(result.data.idlInfo.dataSchema.fields, '', true);
            result.data.fields = fields;
          }
          const wujiFields = result.data.fields;

          for (let k = 0; k < wujiFields.length; k++) {
            const item = wujiFields[k];
            // TODO: 这里处理无极1.0过来的link字段(type=7)理解为对应的数据源关系(反向索引关系)
            if (item.type === 7) {
              let linkConf;
              try {
                logger.info(`main: ${selectSchema}`, item);
                linkConf = JSON.parse(item.args);
              } catch (e) {
                logger.error(e);
              }
              const linkDataSourceName = `${linkConf.appid}:${linkConf.appid}:${linkConf.schemaid}`;
              const isHave = selectSchemas.filter(item => item === linkDataSourceName);
              // 如果不存在该数据源，则自动选中
              if (isHave.length <= 0) {
                selectSchemas.push(linkDataSourceName);
              }

              // 判断是否不存在该关系，自动补上

              const forkRes = await httpGetWujiField(linkConf.appid, linkConf.schemaid, projectId);
              if (forkRes.data.type === 'idl' && forkRes.data.idlInfo) {
                // TODO: 这里应该还是有问题，只支持打平了一层的字段，如果是多维，这里应该还要递归
                const fields = flattenToFields(forkRes.data.idlInfo.dataSchema.fields, '', true);
                forkRes.data.fields = fields;
              }
              const indexField = getIndexId(forkRes.data.fields); // 获取主键ID
              let rel = '1';
              // WUJI1.0 当这个字段为 undefined 时, 默认为多选
              if (linkConf.multiple === undefined) {
                rel = '2';
              } else {
                rel = ((!linkConf.multiple || linkConf.multiple_limit === 1) && '1') || '2';
              }
              const addRelation = {
                forkField: indexField.id,
                forkFieldCname: `${indexField.id}(${indexField.name || indexField.desc})`,
                forkTable: `${linkConf.appid}.${linkConf.schemaid}`,
                forkTableCname: `${linkConf.schemaid}(${forkRes.data.name || forkRes.data.desc})`,
                isReverse: true,
                mainField: item.id,
                mainFieldCname: `${item.id}(${item.name || item.desc})`,
                mainTable: `${selectAppId}.${selectSchemaId}`,
                mainTableCname: `${selectSchemaId}(${result.data.name || result.data.desc})`,
                rel,
                split: ',',
              };
              const isHave2 = dataSourceRelation.filter(item => (
                Boolean(item.isReverse) === true
                && item.rel === addRelation.rel
                && item.forkTable === addRelation.forkTable
                && item.forkField === addRelation.forkField
                && item.mainField === addRelation.mainField
                && item.mainTable === addRelation.mainTable
              ));
              if (isHave2.length <= 0) {
                logger.info('加了一次', addRelation);
                dataSourceRelation.push(addRelation);
              }
            }
          }
        }
      }
    }
  }
}

// 使Schema配置与数据源数据对齐
export function alignSchemaConfig(pageData) {
  const schemaConfig = []; // 新增的
  pageData.selectSchemas.forEach((schema) => {
    if (schema && isPlainObject(schema) && Array.isArray(schema.fields) && pageData.schemaConfig.every((item) => {
      const isMatch = `${schema.appId}.${schema.schemaId}` !== `${item.appId}.${item.schemaId}`;
      return isMatch;
    })) {
      // 取默认外显字段
      const { fields } = schema;
      let defaultField = '';
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        if (!defaultField && field.type === 1) defaultField = field.id;
        if (['title', 'text', 'subtitle', 'sceond_title', 'name'].includes(field.id) && field.type === 1) {
          defaultField = field.id;
          break;
        }
      }

      schemaConfig.push({
        appId: schema.appId,
        schemaId: schema.schemaId,
        labelKey: defaultField,
        valueKey: '',
        copyType: 1,
        deleteType: 1,
        deleteField: '',
        deleteValue: '',
        isKeep: true,
      });
    }
  });

  const selectSchemasArr = pageData.selectSchemas.map(item => `${item.appId}.${item.schemaId}`);
  // 过滤掉不在 selectSchemas 中的表 data.pageData.schemaConfig
  return [...pageData.schemaConfig, ...schemaConfig].filter(item => selectSchemasArr.includes(`${item.appId}.${item.schemaId}`));
}

export default {
  httpGetWujiField,
  getIndexId,
  haveIndexId,
  checkLinkField,
  wujiDataSourceProcess,
  wujiField2dataSchema,
  alignSchemaConfig,
};
