import { defineComponent } from '@vue/composition-api';
import styles from './styles/mergeModal.module.scss';
import CodeConflictEditor from '@components/merge/TextMerge/CodeConflictEditor';
export default defineComponent({
    name: 'ConflictView',
    props: {
        conflictItem: {
            type: Object,
            default: [],
        },
        key: {
            type: String,
            default: '',
        },
        currentBranchLabel: {
            type: String,
            default: '',
        },
        mergeBranchLabel: {
            type: String,
            default: '',
        },
    },
    emits: ['change'],
    data() {
        return {};
    },
    methods: {
        handleChangeConflict(data) {
            this.$emit('change', data);
        },
    },
    watch: {},
    render() {
        return (<div class={styles.conflict_view_container}>
          <div class={styles.conflict_content}>
           <CodeConflictEditor key={this.key} ref="conflictEditor" currentLabel={this.currentBranchLabel} incomingLabel={this.mergeBranchLabel} result={this.conflictItem.after} autoParse={false} enableSkipMerge={false} enableSkipResolved={true} onBlur={this.handleChangeConflict} type={'yaml'}/>
          </div>
        </div>);
    },
});
