import { BUILD_IN_FIELDS } from '@utils/runtime-mixin';
/* 获取变量类型 */
function getType(value) {
    let type = 'any';
    switch (typeof value) {
        case 'number':
            if (Number.isInteger(value)) {
                type = 'integer';
            }
            else {
                type = 'number';
            }
            break;
        case 'boolean':
            type = 'boolean';
            break;
        case 'string':
            type = 'string';
            break;
        case 'object':
            if (Array.isArray(value)) {
                type = 'array';
            }
            else if (!!value) {
                type = 'object';
            }
            break;
        default:
            type = 'any';
            break;
    }
    return type;
}
/* 根据 Vue 实例生成 DataSchema */
export const buildVueInstanceDataSchema = (instance) => {
    const { $options } = instance;
    const { data, computed = {}, methods = {} } = $options;
    /* 收集实例的 data 字段 */
    const dataAns = [];
    if (typeof data === 'function') {
        const dataRes = data.call(instance);
        Object.keys(dataRes)
            .filter(key => !BUILD_IN_FIELDS.includes(key))
            .forEach((key) => {
            dataAns.push({
                id: key,
                title: key,
                type: getType(dataRes[key]),
                readonly: true,
            });
        });
    }
    /* 收集实例的 computed 字段 */
    const computedAns = Object.keys(computed)
        .filter(key => !BUILD_IN_FIELDS.includes(key))
        .map((key) => {
        /* 获取当前的计算值 */
        let type = 'any';
        if (typeof computed[key] === 'function') {
            type = getType(computed[key].call(instance));
        }
        return {
            id: key,
            title: key,
            type,
            readonly: true,
        };
    });
    /* 收集实例的 data 字段 */
    const methodsAns = Object.keys(methods).map(key => ({
        id: key,
        title: '方法',
    }));
    return Object.freeze({
        fields: [...dataAns, ...computedAns],
        methods: [...methodsAns],
    });
};
/* 生成全局实例的 DataSchema */
export const buildAppDataSchema = (instance) => {
    const schema = buildVueInstanceDataSchema(instance) ?? {};
    return Object.freeze({
        id: '$app',
        title: '全局实例 / 全局 LessCode 实例',
        type: 'object',
        readonly: true,
        'uc:collapsed': true,
        'uc:bindable': false,
        ...schema,
        tsType: '$__UICoreContext.AppLessCode',
    });
};
