import { isNil, set } from 'lodash';
import { loadXLSX } from './loadUtilScripts';
let XLSX;
// 返回当前时区与该日期所在时区之间的时间差（以毫秒为单位）
function getTimezoneOffsetMS(date) {
    const time = date.getTime();
    const utcTime = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    return time - utcTime;
}
// 计算当前时区与1899年12月30日时区之间的时间差（以毫秒为单位）
const importBugHotfixDiff = (function () {
    const baseDate = new Date(1899, 11, 30, 0, 0, 0);
    const dnThreshAsIs = (new Date().getTimezoneOffset() - baseDate.getTimezoneOffset()) * 60000;
    const dnThreshToBe = getTimezoneOffsetMS(new Date()) - getTimezoneOffsetMS(baseDate);
    return dnThreshAsIs - dnThreshToBe;
}());
// 修复日期的精度损失
function fixPrecisionLoss(date) {
    return (new Date(date.getTime() - importBugHotfixDiff));
}
export default async function importExcel(data, option = {}) {
    if (!XLSX) {
        XLSX = await loadXLSX();
    }
    const xlsxResult = XLSX.read(data, {
        type: 'binary',
        cellDates: true,
        ...option,
    });
    return formatSheetToJSON(xlsxResult);
}
// 将读取的数据转成JSON
function formatSheetToJSON(data) {
    // 获取所有数据源
    const sheets = data.Sheets;
    // 获取每个 Sheet 的数据
    const sheetItems = Object.keys(sheets);
    const sheetMap = {};
    sheetItems.forEach((item) => {
        const rows = XLSX.utils.sheet_to_json(sheets[item], { header: 1 });
        // 格式化值为 null / empty 的单元格
        for (let i = 0; i < (rows).length; i++) {
            const row = rows[i];
            for (let j = 0; j < row.length; j++) {
                const cell = row[j];
                if (isNil(cell))
                    set(row, j, '');
                // 修复日期类型的精度误差
                if (cell instanceof Date) {
                    set(row, j, fixPrecisionLoss(cell));
                }
            }
        }
        sheetMap[item] = rows;
    });
    return sheetMap;
}
