<template>
  <div class="wf-wrapper">
    <aside
      v-if="showSidebar"
      class="aside"
    >
      <sidebar />
    </aside>
    <main class="main">
      <router-view />
    </main>
  </div>
</template>

<script>
import { isMobileOrNarrowScreen } from '@utils/screen';
import Sidebar from './Sidebar';

export default {
  components: {
    Sidebar,
  },
  computed: {
    showSidebar() {
      return !isMobileOrNarrowScreen();
    },
  },
};
</script>

<style lang="scss" scoped>
.wf-wrapper {
  display: flex;
}

.aside {
  height: calc(100vh - var(--xy-header-height));
}

.main {
  width: 100%;
  overflow: auto;
}
</style>
