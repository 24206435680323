import qs from 'query-string';
import { set } from 'lodash';
const StorageKey = 'CLI_SETTING.https';
window.CLI_SETTING = {
    https: localStorage.getItem(StorageKey) === 'true',
};
export const ENABLE_LOCAL_HTTPS = !!window?.CLI_SETTING?.https;
export const LOCAL_API_PROTOCOL = ENABLE_LOCAL_HTTPS ? 'https:' : 'http:';
export const toggleCliHttps = (value, { reload = false, withRetryQuery = false } = {}) => {
    set(window, StorageKey, value);
    localStorage.setItem(StorageKey, value ? 'true' : 'false');
    if (reload) {
        const query = qs.parse(window.location.search);
        if (withRetryQuery) {
            query.debugproto = value ? 'https' : 'http';
        }
        else {
            delete query.debugproto;
        }
        window.location.href = `${window.location.origin}${window.location.pathname}?${qs.stringify(query)}`;
    }
};
export const getLocalBaseUrl = (port) => `${LOCAL_API_PROTOCOL}//localhost:${port}`;
export const getLocalBaseApiUrl = (port) => `${LOCAL_API_PROTOCOL}//localhost:${port}/api`;
