import wujiFetch from '@/utils/wujiFetch';
import { message } from 'ant-design-vue';
/**
 * 物料管理相关的信息
 *
 * 目前只有pagelet component这一种物料
 */
export default {
    state: {
        material: {},
        materialList: [],
    },
    actions: {
        async fetchMaterialDetail({ rootState, commit }, { projectId, materialId } = {}) {
            try {
                const proId = rootState.project?.projectInfo?.id || projectId;
                const data = await wujiFetch(`/api/xy/design/material/${materialId}?projectid=${proId}`);
                commit('setMaterial', {
                    material: data,
                });
                return data;
            }
            catch (e) {
                console.error(e);
                message.error(`拉取物料数据失败：${e.message}`);
                throw e;
            }
        },
        async fetchMaterialList({ rootState, commit }, { projectId } = {}) {
            try {
                const proId = rootState.project?.projectInfo?.id || projectId;
                const data = await wujiFetch(`/api/xy/design/material?projectid=${proId}`);
                commit('setMaterialList', {
                    materialList: data,
                });
                return data;
            }
            catch (e) {
                console.error(e);
                message.error(`拉取物料数据失败：${e.message}`);
                throw e;
            }
        },
        async createMaterial({ rootState }, { material, }) {
            const proId = rootState.project?.projectInfo?.id || material.projectId;
            const data = await wujiFetch(`/api/xy/design/material?projectid=${proId}`, {
                method: 'POST',
                body: JSON.stringify(material),
            });
            return data;
        },
        async updateMaterial({ rootState, }, { material, materialId, }) {
            const proId = rootState.project?.projectInfo?.id || material.projectId;
            const data = await wujiFetch(`/api/xy/design/material/${materialId}?projectid=${proId}`, {
                method: 'POST',
                body: JSON.stringify(material),
            });
            return data;
        },
        async deleteMaterial({ rootState, dispatch, }, { material, materialId, }) {
            const proId = rootState.project?.projectInfo?.id || material.projectId;
            const data = await wujiFetch(`/api/xy/design/material/${materialId}?projectid=${proId}`, {
                method: 'DELETE',
                body: JSON.stringify(material),
            });
            dispatch('fetchMaterialList', { projectId: proId });
            return data;
        },
    },
    mutations: {
        setMaterial(state, { material }) {
            state.material = material;
        },
        setMaterialList(state, { materialList }) {
            state.materialList = materialList;
        },
    },
};
