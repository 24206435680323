import Vue from 'vue';
import XyModalContainer from './xy-modal-container';
import XyModalContainerDesign from './xy-modal-container-design';
import xyModalSchema from './xy-modal-schema';
import { getDocLink } from '@utils/links';
import PageletAttr from '@components/pagelet/pagelet-attr';
import { meta as XyModalSizeEditorMeta } from './xy-modal-size-editor.vue';
import { EVENTS } from '@composables/usePageletEvent';
import { getAvailableComponent } from '@/utils/comps-loader';

let modalZIndex = 800;

/* 获取弹窗的 z-index 值 */
export function getModalZIndex() {
  modalZIndex += 1;
  return modalZIndex;
}

// 默认的弹窗布局
export function getDefaultChildren() {
  return [];
};

// 默认的标题
export function getDefaultTitleSlot(defaultTitle = '弹窗') {
  return [
    {
      type: 'w-paragraph',
      props: {
        align: 'left',
      },
      children: [
        {
          type: getAvailableComponent('public', 'w-readonly'),
          style: {
            margin: '0',
            color: '#161616',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '22px',
            wordWrap: 'break-word',
            'word-wrap': 'break-word',
          },
          componentId: 'publicWReadonlyNew',
          props: {
            value: defaultTitle,
          },
        },
      ],
      componentId: 'wParagraph',
    },
  ];
}

// 默认的页脚
export function getDefaultFooterSlot() {
  return [
    {
      type: 'w-paragraph',
      props: {
        align: 'right',
      },
      children: [
        {
          type: getAvailableComponent('public', 'w-button'),
          style: {
            fontSize: '14px',
          },
          componentId: 'modalCancelButton',
          props: {
            type: 'default',
            text: '取消',
          },
          events: {
            click: {
              steps: [
                {
                  type: 'xy:hideModal',
                  params: {
                    data: [],
                  },
                  _key: 'cancel',
                },
              ],
            },
          },
        },
        {
          type: getAvailableComponent('public', 'w-button'),
          style: {
            fontSize: '14px',
          },
          componentId: 'modalConfirmButton',
          props: {
            text: '确定',
          },
          events: {
            click: {
              steps: [
                {
                  type: 'xy:hideModal',
                  params: {
                    data: [],
                  },
                  _key: 'confirm',
                },
              ],
            },
          },
        },
      ],
      componentId: 'modalFooter',
    },
  ];
}

// 默认的弹窗Slots(页脚等)
export function getDefaultSlots() {
  return {
    title: getDefaultTitleSlot(),
    footer: getDefaultFooterSlot(),
  };
};

export default {
  component: XyModalContainer,
  componentForDesign: XyModalContainerDesign,
  category: '系统组件',
  id: 'xy-modal-container',
  events: [
    {
      id: 'open',
      name: '打开',
      desc: '注意：不要在这个时候调用数据源的方法',
      asProp: 'handleOpenEvent',
      arguments: [],
    },
    {
      id: 'close',
      name: '关闭',
      asProp: 'handleCloseEvent',
      arguments: [],
    },
    ...EVENTS,
  ],
  readme: getDocLink().MODAL_DOC ?? '',
  slots: [
    { id: 'title', selector: '.xy-modal-container-title' },
    { id: 'default', selector: '.xy-modal-container-content' },
    { id: 'footer', selector: '.xy-modal-container-footer' },
  ],
  display: 'block',
  visible: false,
  name: '弹窗容器',
  propPanel: {
    XyModalSizeEditor: XyModalSizeEditorMeta,
    PageletAttr: {
      component: PageletAttr,
      hideLabel: true,
    },
  },
  propsSchema: xyModalSchema,
  getActions({ modifyComp }) {
    return [
      {
        title: '重置页脚',
        icon: 'fa fa-refresh',
        async execute() {
          await modifyComp((c) => {
            Vue.set(c, 'slots', getDefaultSlots());
          });
        },
      },
    ];
  },
};

