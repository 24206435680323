import store from '@store/index';
import getXyHeaders from '@utils/getXyHeaders';
import { getScfDebugHost } from './utils';
import fetch from '@utils/fetch';
import { getHmrPort } from '@utils/comps-loader';
import { getLocalBaseApiUrl } from '@config/cli-settings';
const axios = require('axios');
const { addPathPrefix } = require('@utils/path');

const getEnv = () => {
  const { app = {} } = store.state;
  return app.env || 'dev';
};

class ScfLoader {
  constructor() {
    const loc = window.location;
    this.host = loc.host;
  }

  /**
   * @param {Object} urlInfo 拼接云函数url的url信息
   * @param {Object} axiosOptions axios透传的属性
   */
  callFunc(urlInfo, axiosOptions) {
    const url = this.generateUrl(urlInfo);
    const headers = axiosOptions?.headers || {};
    return axios({
      url,
      ...{ withCredentials: true, ...axiosOptions },
      headers: { ...headers, ...getXyHeaders() },
    });
  }

  /**
  * 获取云函数的请求url策略
  * @param {Object} urlInfo 拼接云函数url的url信息
  * @param {String} urlInfo.host 云函数的host
  * @param {String} urlInfo.funcName 云函数的名字
  * @param {Boolean} urlInfo.debuging 是否是本地debug的云函数，默认false
  * @param {String} urlInfo.projectId 原函数使用的应用ID
 */
  generateUrl = (urlInfo) => {
    const { funcName, projectId, debuging = false } = urlInfo;

    // 相对路径
    let relativePath = `/api/xy/f/${getEnv()}/${projectId}/func/${funcName}`;
    relativePath = debuging ? relativePath : addPathPrefix(relativePath);

    const nextHost = debuging ? getScfDebugHost() : this.host;

    return `//${nextHost}${relativePath}`;
  }

  async loadDebugFunc() {
    const fetchResult = await fetch(`${getLocalBaseApiUrl(getHmrPort())}/xy/f/list`, {});
    return fetchResult.data;
  }
}

export default new ScfLoader();
