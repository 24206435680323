import { defineComponent, onMounted, ref, toRefs } from '@vue/composition-api';
import styles from './merge.module.scss';
import monacoProvider from '@utils/monacoProvider';
export default defineComponent({
    name: 'CodeChecker',
    props: {
        code: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    emits: ['change', 'cancel', 'toggleFullScreen', 'changeValue'],
    setup(props) {
        const { code, type } = toRefs(props);
        const editorRef = ref();
        const editor = ref(); // 编辑器实例
        onMounted(() => {
            monacoProvider((monaco) => {
                const model = monaco.editor.createModel(code.value, type.value);
                editor.value = monaco.editor.create(editorRef.value, {
                    model,
                    theme: 'vs',
                });
            });
        });
        return {
            editorRef,
        };
    },
    render() {
        return (<div class={styles.code_merge_container}>
        <div class={styles.navbar}>
          <div>已合并</div>
        </div>
        <div class={styles.conflict_content}>
          <div ref="editorRef" style="height: 100%"></div>
        </div>
      </div>);
    },
});
