import { message } from 'ant-design-vue';
import offlineService from '@/services/offline';
import {
  INITTOTAL,
  INITPAGE,
  OFFLINESTATE,
  MESSAGES,
} from '@/pages/project/projectConfig/offline/const';

const PAGE_SIZE = 10;
const SUCCESS = 'Success';
export default {
  state: {
    offlineList: [],
    semvers: [],
    total: INITTOTAL, // 总数
    page: INITPAGE, // 当前页
    showOfflineModal: false, // 页面选择框
  },
  getters: {
  },
  actions: {
    async fetchOfflineList({ commit, rootState }, { projectId, page = 1, filter = '' } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const { total } = await offlineService.fetchOfflineList(projectID, {
          filter,
          count: 1,
        });
        // 存储调整后的page,处理边界问题
        let adjustedPage = page;
        const totalPages = Math.ceil(total / PAGE_SIZE);
        if (adjustedPage > totalPages) {
          adjustedPage = totalPages;
        }
        const newList = await offlineService.fetchOfflineList(projectID, {
          page: adjustedPage,
          filter,
          size: PAGE_SIZE,
        });
        commit('updateOfflineState', { page: adjustedPage, total });
        commit('updateOfflineList', newList);
        return newList;
      } catch (err) {
        console.error(err);
        message.error('获取离线列表失败', err.message);
      }
    },
    // 获取历史版本号
    async fetchSemvers({ commit, rootState }, { projectId } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const result = await offlineService.fetchOfflineList(projectID, {
          size: 'total',
          include: 'semver',
        });
        const semvers = [...new Set(result.map(item => item.semver))];
        commit('updateSemvers', semvers);
      } catch (err) {
        console.error(err);
        message.error('获取历史版本号失败', err.message);
      }
    },
    async createOffline({ rootState }, { projectId, createData }) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const filter = `state = ${OFFLINESTATE.PENDING}`;
        // 获取进行中状态
        const pendingExist = await offlineService.fetchOfflineList(projectID, {
          size: 'total',
          filter,
        });
        if (pendingExist.length !== 0) {
          message.loading(MESSAGES.PENDING);
          return false;
        }
        await offlineService.createOffline({
          projectId: projectID,
          data: createData,
        });
        message.success('离线任务创建成功');
        return true;
      } catch (err) {
        message.error(`离线任务创建失败: ${err.message}`);
        return false;
      }
    },
    async deleteOffline({ dispatch, rootState }, { projectId, id } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        await offlineService.deleteOffline({ projectId: projectID, id });
        dispatch('fetchSemvers', { projectId: projectID });
        message.success('已删除离线记录');
      } catch (err) {
        message.error(`删除离线记录失败: ${err.message}`);
      }
    },
    // 自定义离线化配置
    async fetchConfigList({ rootState }, { projectId } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const result = await offlineService.fetchConfigList(projectID, {});
        return result;
      } catch (err) {
        console.error(err);
        message.error('获取离线化配置列表失败', err.message);
      }
    },
    async createConfig({ rootState }, { projectId, data } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const result = await offlineService.createConfig({
          projectId: projectID,
          data,
        });
        message.success('离线化配置创建成功');
        return result;
      } catch (err) {
        message.error(`离线化配置创建失败: ${err.message}`);
      }
    },
    async updateConfig({ rootState }, { projectId, id, data } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const result = await offlineService.updateConfig({
          projectId: projectID,
          id,
          data,
        });
        if (result) {
          message.success('离线化配置更新成功');
        } else {
          message.error('离线化配置更新失败');
        }
        return result;
      } catch (err) {
        message.error(`离线化配置更新失败: ${err.message}`);
      }
    },
    async deleteConfig({ rootState }, { projectId, id } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const result = await offlineService.deleteConfig({ projectId: projectID, id });
        if (result) {
          message.success(`已删除离线化配置: ${id}`);
        } else {
          message.error('删除离线化配置失败');
        }
        return result;
      } catch (err) {
        message.error(`删除离线化配置失败: ${err.message}`);
      }
    },
    async debugConfig({ rootState }, { projectId, data } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const debugResult = await offlineService.debugConfig({
          projectId: projectID,
          data,
        });
        if (debugResult.message === SUCCESS) {
          message.success('配置函数调试成功');
        } else {
          message.error('配置函数调试失败');
        }
        return debugResult;
      } catch (err) {
        message.error(`配置函数调试失败: ${err.message}`);
        return err;
      }
    },
  },
  mutations: {
    updateOfflineList(state, newList) {
      state.offlineList = newList;
    },
    updateOfflineState(state, payload) {
      state.page = payload.page;
      state.total = payload.total;
    },
    updateSemvers(state, semvers) {
      state.semvers = semvers;
    },
    updateOfflineModal(state, isShow) {
      state.showOfflineModal = isShow;
    },
  },
};
