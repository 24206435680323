import useIds from '@/composables/ids/useIds';
import { INJECT_UICORE_BUS } from '@tencent/ui-core/lib/utils/consts';
import { computed, inject, onMounted, proxyRefs, reactive, toRef, toRefs } from '@vue/composition-api';
export function toFullFlowPageState(config, r) {
    const { nodeId, flowId } = config;
    const { projectId } = useIds();
    const flowName = computed(() => r.flowInfoSWR.value?.name || flowId);
    const refresh = async () => {
        await Promise.all([
            rs.jobSWR.reload(),
            rs.flowInfoSWR.reload(),
        ]);
        return rs;
    };
    const emptyJobParams = reactive({});
    const rs = proxyRefs({
        projectId,
        pluginConfig: config,
        flowId,
        flowInfo: toRef(r.flowInfoSWR, 'value'),
        // flowInfoSWR := r
        flowName,
        flowGraph: computed(() => r.flowInfoSWR.value?.flowGraph),
        nodeId,
        node: computed(() => r.flowInfoSWR.value?.flowGraph.nodes[nodeId]),
        // jobId := r
        // jobSWR := r
        job: toRef(r.jobSWR, 'value'),
        jobName: computed(() => r.jobSWR.value?.title || flowName.value),
        jobParams: computed(() => r.jobSWR.value?.jobParams || emptyJobParams),
        refresh,
        ...toRefs(r),
    });
    onMounted(refresh);
    return (rs);
}
export function useFlowPageState(uc = inject(INJECT_UICORE_BUS, null)) {
    return useFlowPagePlugin(uc).state;
}
export function useFlowPagePlugin(uc = inject(INJECT_UICORE_BUS, null)) {
    return uc['xy:flowPagePlugin'];
}
