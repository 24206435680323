import { flatten } from 'lodash';
import { getModuleLogger } from '@utils/logger';
import { isRuntime } from '@/utils/globalInfo';

const logger = getModuleLogger('platformUICorePlugins');

const pluginModulesPromise = (async () => {
  const urls = window.GLOBAL_INFO.platformUICorePlugins;
  const mods = await Promise.all(urls.map(url => new Promise((resolve) => {
    window.require([url], (mod) => {
      resolve({ url, mod });
    }, (error) => {
      logger.error('failed to load plugin ', url, error);
      resolve(null);
    });
  })));

  return mods.filter(Boolean);
});

/**
 * @param {{ pageId, projectId }} infoRaw
 * @returns
 */
export async function getPlatformUICorePlugins(infoRaw) {
  const info = {
    ...infoRaw,
    envId: window.RUNTIME_CONFIGS?.env || 'dev',
    scene: isRuntime() ? 'runtime' : 'designer',
  };
  const mods = await pluginModulesPromise();
  return flatten(await Promise.all(mods.map(async ({ url, mod }) => {
    try {
      return await mod.getUICorePlugins(info);
    } catch (err) {
      logger.error('cannot initialize on', info, url, err);
      return null;
    }
  }))).filter(Boolean);
}
