import { message } from 'ant-design-vue';
import { openMergeModal } from '@/components/branch/merge/MergeModal';
import wujiFetch from '@/utils/wujiFetch';
import { memoizedFetchGET } from '@utils/common';
import { BASE_API_PATH } from '@config/constant';
import { useRouter } from '@/router/useRouter';
import { getAppQuery } from '@utils/query';

const designBaseUrl = `${BASE_API_PATH}/xy/design/branch`;
const getUserBranchUrl = projectId => `${BASE_API_PATH}/xy/user/branch?projectid=${projectId}`;

export const MASTER_BRANCH = 'master';
const DEFAULT_BRANCH = {
  branch: MASTER_BRANCH,
  name: MASTER_BRANCH,
  id: MASTER_BRANCH,
  isDefault: true,
};

// 分支相关接口超时时间
const branchTimeout = 60 * 1000;

export default {
  state: {
    currentBranch: null,
    uncommitChanges: {},
    mergeRequstInfo: null,
    commitList: [],
    branchList: [],
  },
  actions: {
    // 创建分支
    async createBranch({ rootState, dispatch }, data) {
      const name = data.name.trim();
      if (!name) throw new Error('分支名称不能为空');
      const result = await wujiFetch(`${designBaseUrl}?projectid=${rootState.project.projectInfo?.id}`, {
        method: 'POST',
        body: JSON.stringify({
          ...data,
          name,
          checkoutBranchId: 'master',
        }),
        timeout: branchTimeout,
      });
      if (result.error) {
        throw new Error(result.error);
      }
      // 创建成功，默认切换到新创建的分支
      dispatch('checkoutBranch', { branch: result.branch, name: result.name });
      return result;
    },
    // 获取当前应用的所有分支
    async fetchBranchList({ commit, rootState }, { projectId, includeArchived = 0 }) {
      try {
        const result = await wujiFetch(`${designBaseUrl}?projectid=${projectId || rootState.project.projectInfo?.id}&includeArchived=${includeArchived}`);
        commit('updateBranchList', result);
      } catch (err) {
        message.error('获取分支列表失败');
      }
    },

    async fetchBranchInfo({ rootState }, { branchId }) {
      try {
        const result = await wujiFetch(`${designBaseUrl}/${branchId}?projectid=${rootState.project.projectInfo?.id}`);
        return result;
      } catch (err) {
        message.error('获取分支信息失败');
      }
    },

    async deleteBranch({ commit, state, rootState }, { branchId }) {
      await wujiFetch(`${designBaseUrl}/${branchId}?projectid=${rootState.project.projectInfo?.id}`, {
        method: 'DELETE',
      });
      commit('updateBranchList', [...state.branchList.filter(item => item.branch !== branchId)]);
    },
    // 获取用户正在开发中的分支
    async fetchCurrentBranch({ commit }, { projectId } = {}) {
      try {
        // 优先取query的branch
        try {
          const branchQuery = getAppQuery('branch');
          if (branchQuery) {
            const branchInfo = await wujiFetch(`${designBaseUrl}/${branchQuery}?projectid=${projectId}`);
            if (branchInfo) {
              commit('updateCurrentBranch', branchInfo);
              return branchInfo;
            }
          }
        } catch (err) {
          message.warn('自动切换到开发分支');
        }
        // query的branch无效再取用户上次开发的分支
        const result = await memoizedFetchGET(getUserBranchUrl(projectId));
        const branch = result ? result.branch : DEFAULT_BRANCH;
        commit('updateCurrentBranch', branch);
        return branch;
      } catch (err) {
        console.error(err);
        message.error('获取用户当前开发分支失败');
      }
    },
    // 切换分支
    async checkoutBranch({ rootState }, { branch, name }) {
      try {
        const projectId = rootState.project.projectInfo?.id;
        const result = await wujiFetch(`${BASE_API_PATH}/xy/user/branch/checkout/${branch}?projectid=${projectId}`, {
          method: 'POST',
        });
        if (result) {
          const { router, route } = useRouter();
          const currentQuery = route.value.query;
          const { href = '' } = router.value.resolve({
            path: route.value.path,
            query: {
              ...currentQuery,
              projectid: projectId,
              branch,
            },
          });
          if (name) message.success(`即将切换至 ${name} 分支`);
          window.location.href = href;
        } else {
          message.error('切换分支失败');
        }
      } catch (err) {
        console.error(err);
        message.error('切换分支失败');
      }
    },
    // 获取分支未提交的变更
    async fetchBranchUncommitChanges({ commit, state, rootState }, { projectId, branchId } = {}) {
      try {
        const proId = projectId || rootState.project.projectInfo?.id;
        const branch = branchId || state.currentBranch.branch;
        const result = await wujiFetch(`${designBaseUrl}/${branch}/uncommitchanges?projectid=${proId}`, {
          timeout: branchTimeout,
        });
        if (!branchId || state.currentBranch.branch === branchId) {
          commit('updateUncommitChanges', result);
        }
        return result;
      } catch (err) {
        message.error('获取未提交变更失败');
      }
    },
    // 提交分支变更
    async commitBranchChanges({ rootState, dispatch }, { branchId, data }) {
      const result = await wujiFetch(`${designBaseUrl}/${branchId}/commit?projectid=${rootState.project.projectInfo?.id}`, {
        method: 'POST',
        body: JSON.stringify(data),
      });
      dispatch('fetchBranchUncommitChanges', { branchId });
      return result;
    },
    // 获取分支的提交记录
    async fetchBranchCommits({ rootState, commit }, { branchId, projectId, page = 1, size = 20 }) {
      try {
        const url = `${designBaseUrl}/${branchId}/commit?projectid=${projectId || rootState.project.projectInfo?.id}`;
        const getTotal = wujiFetch(`${url}&count=1`);
        const getList = wujiFetch(
          `${url}&page=${page}&size=${size}&sort=_ctime&order=desc`,
          { method: 'GET', timeout: branchTimeout },
        );
        const [result, { total }] = await Promise.all([getList, getTotal]);
        commit('updateCommitList', result);
        return {
          page,
          total,
          result,
        };
      } catch (err) {
        message.error('获取提交记录失败');
      }
    },
    // 获取分支的最新的提交
    async fetchBranchHeadCommit({ rootState }, { branchId, projectId }) {
      try {
        const result = await wujiFetch(`${designBaseUrl}/${branchId}/commit/head?projectid=${projectId || rootState.project.projectInfo?.id}`);
        return result;
      } catch (err) {
        message.error('获取分支最新版本失败');
      }
    },
    // 撤销分支上的某条变更
    async discardBranchChange({ rootState, dispatch }, { branchId, data }) {
      const projectId = rootState.project.projectInfo.id;
      const result = await wujiFetch(`${designBaseUrl}/${branchId}/discard?projectid=${projectId}`, {
        method: 'POST',
        body: JSON.stringify({
          data,
        }),
      });
      dispatch('fetchBranchUncommitChanges', { branchId });
      return result;
    },

    // 获取可拉取的最新commitId
    async getPullCommitId({ rootState, state }, { projectId, branchId, targetBranchId } = {}) {
      const proId = projectId || rootState.project.projectInfo?.id;
      const branch = branchId || state.currentBranch.branch;
      const result = await wujiFetch(`${designBaseUrl}/${branch}/pullcommit?projectid=${proId}&targetbranch=${targetBranchId}`, {
        timeout: branchTimeout,
      });
      return result;
    },
    // 获取两个分支的基准commit
    async getBaseCommit({ rootState }, { branchId, targetBranchId } = {}) {
      const result = await wujiFetch(`${designBaseUrl}/${branchId}/basecommit?projectid=${rootState.project.projectInfo?.id}&targetbranch=${targetBranchId}`);
      return result;
    },
    // 获取差异
    async fetchCommitDiff({ rootState }, { commitId1, commitId2, baseCommitId }) {
      const proId = rootState.project.projectInfo?.id;
      const commits = [commitId1, commitId2, baseCommitId].filter(item => !!item).join(',');
      const result = await wujiFetch(`${designBaseUrl}/commit/diff?projectid=${proId}&commits=${commits}`, {
        timeout: branchTimeout,
      });
      return result;
    },

    async mergeBranch({ rootState, state }, { branchId, mergeBranchId }) {
      const proId = rootState.project.projectInfo?.id;
      const res = await wujiFetch(`${designBaseUrl}/${branchId}/conflict/${mergeBranchId}?projectid=${proId}`, {
        method: 'GET',
        timeout: branchTimeout,
      });
      if (!res.diff) {
        message.warn(`没有可${mergeBranchId === 'master' ? '拉取' : '合并'}的提交`);
        return;
      }
      const currentBranch = state.branchList.find(item => item.branch === branchId);
      const mergeBranch = state.branchList.find(item => item.branch === mergeBranchId);

      await openMergeModal({
        changes: res.data,
        readonly: false,
        title: `合并分支${mergeBranch.name}到${currentBranch.name}`,
        currentBranch: currentBranch.name,
        mergeBranch: mergeBranch.name,
        onConfirm: async (data, desc) => {
          try {
            await wujiFetch(`${designBaseUrl}/${branchId}/merge/${mergeBranchId}?projectid=${proId}`, {
              method: 'POST',
              body: JSON.stringify({
                data,
                desc,
              }),
              timeout: branchTimeout,
            });
            message.success('提交成功');
            if (currentBranch !== 'master') {
              setTimeout(() => window.location.reload(), 500);
            }
          } catch (err) {
            message.error('提交失败');
            throw err;
          }
        },
      });
    },

    async fetchCommitChanges({ rootState }, { commitId, branchId }) {
      const projectId = rootState.project.projectInfo.id;
      try {
        return await wujiFetch(`${designBaseUrl}/${branchId}/commit/${commitId}?projectid=${projectId || rootState.project.projectInfo?.id}`);
      } catch (err) {
        message.error('获取提交记录失败');
      }
    },

    async resetCommit({ rootState }, { commitId, branchId, projectId }) {
      try {
        return await wujiFetch(`${designBaseUrl}/${branchId}/commit/reset/${commitId}?projectid=${projectId || rootState.project.projectInfo?.id}`, {
          method: 'POST',
        });
      } catch (err) {
        message.error('重置提交操作失败');
        throw err;
      }
    },
  },
  mutations: {
    resetBranch(state) {
      state.currentBranch = null;
    },
    updateBranchList(state, data) {
      state.branchList = data;
    },
    updateCommitList(state, data) {
      state.commitList = data;
    },
    updateCurrentBranch(state, data) {
      state.currentBranch = data;
    },
    updateUncommitChanges(state, data) {
      state.uncommitChanges = data;
    },
    updateMergeRequstInfo(state, data) {
      state.mergeRequstInfo = data;
    },
  },
};
