<template>
  <div
    class="spin-container"
  >
    <w-sys-spin
      class="loading-content"
      tip="组件加载中..."
    />
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.spin-container{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
