import widget from './registration';

import TopSwitch from './switch/TopSwitch';
import EnvForm from './switch/EnvForm';

widget.register('switch', {
  name: '快速切换',
  configComponent: null,
  viewComponent: TopSwitch,
});

widget.register('env', {
  name: '环境切换',
  configComponent: EnvForm,
  viewComponent: TopSwitch,
});

export default widget;
