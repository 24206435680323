/* eslint-disable no-param-reassign */

import { fromJSONSchema } from '@tencent/data-schema/lib/convertor/jsonSchema';
import { cloneDeep } from 'lodash';
import logger from '@utils/logger';

/**
 * @param {import('@tencent/data-schema/lib/types').DataSchema} dataSchema
 */
const initWujiDirtyWorker = (dataSchema) => {
  /** JSON文本 类型的字段有哪些，示例： [["foo$bar$config"], ["foo$baz$config"], ["foo$bar$arr", 0, "xxxxx"]] */
  const jsonTextFields = [];
  const newDataSchema = cloneDeep(dataSchema);

  logger.info('dirtyScan', newDataSchema);
  !(function scan(ds, keypath) {
    if (!ds) return;

    switch (ds.type) {
      case 'object':
        (ds.fields || []).forEach(sds => scan(sds, [...keypath, sds.id]));
        break;

      case 'array':
        scan(ds.items, [...keypath, 0]);
        break;

      case 'string':
        if (ds.ui && ds.ui.type === 'wujiOmniInput' && ds.ui.settings && ds.ui.settings.type === 20) {
          try {
            const args = JSON.parse(ds.ui.settings.args);
            const jsonSchema = JSON.parse(args.schema);
            const dataSchema = fromJSONSchema(jsonSchema);

            jsonTextFields.push(keypath);
            delete ds.ui;
            ds.xyFromJSONField = true;
            ds.type = dataSchema.type;

            if (dataSchema.fields) ds.fields = dataSchema.fields;
            if (dataSchema.items) ds.items = dataSchema.items;

            try {
              if (ds.default === '{}' || ds.default === '[]') throw new Error();
              ds.default = JSON.parse(ds.default);
            } catch (error) {
              delete ds.default;
            }
          } catch (er) {
          }
        }
        break;

      default:
        break;
    }
  }(newDataSchema, []));

  function process(data, processor) {
    const ans = cloneDeep(data);

    jsonTextFields.forEach((function processKeyPath(target, keypath) {
      const ubound = keypath.length - 1;
      for (let i = 0; i <= ubound; i++) {
        const k = keypath[i];
        const nextTarget = target[k];

        if (!nextTarget) {
          return;
        }

        if (i === ubound) {
          target[k] = processor(nextTarget);
          return;
        }

        if (Array.isArray(nextTarget)) {
          nextTarget.forEach((_, j) => {
            processKeyPath(nextTarget, [j, ...keypath.slice(i + 2)]);
          });
          return;
        }

        target = nextTarget;
      }
    }).bind(null, ans));

    return ans;
  }

  const cleanData = data => process(data, (value) => {
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  });

  const toDirtyData = data => process(data, (value) => {
    if (typeof value === 'object') return JSON.stringify(value);
    return value;
  });

  return {
    jsonTextFields,
    newDataSchema,
    cleanData,
    toDirtyData,
  };
};

export default initWujiDirtyWorker;
