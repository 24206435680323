import { getUiCore } from '@utils/getUiCore';


export const XySourceSelectorMeta = {
  id: 'XySourceSelector',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XySourceSelector'),
  }),
};

export const XySourceActionEditMeta = {
  id: 'XySourceActionEdit',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XySourceActionEdit'),
  }),
};

export const XyLessCodeMethodSelectorMeta = {
  id: 'XyLessCodeMethodSelector',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyLessCodeMethodSelector'),
  }),
};

export const XyPageSelectorMeta = {
  id: 'XyPageSelector',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyPageSelector'),
  }),
};

export const XyModalSelectorMeta = {
  id: 'XyModalSelector',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyModalSelector'),
  }),
};

export const XyPageletPropsEditorMeta = {
  id: 'XyPageletPropsEditor',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyPageletPropsEditor'),
  }),
};

export const XyHttpRequestConfigMeta = {
  id: 'XyHttpRequestConfig',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyHttpRequestConfig'),
  }),
};

export const XyWorkflowSelectorMeta = {
  id: 'XyWorkflowSelector',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyWorkflowSelector'),
  }),
};

export const XyFlowSelectorMeta = {
  id: 'XyFlowSelector',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyFlowSelector'),
  }),
};

export const XyFlowApproveDataEditorMeta = {
  id: 'XyFlowApproveDataEditor',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyFlowApproveDataEditor'),
  }),
};

export const XyFlowAtomDataEditorMeta = {
  id: 'XyFlowAtomDataEditor',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyFlowAtomDataEditor'),
  }),
};

export const XyRobotSelectorMeta = {
  id: 'XyRobotSelector',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyRobotSelector'),
  }),
};

export const XyRobotSelectorTdesignMeta = {
  id: 'XyRobotSelectorTdesign',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyRobotSelectorTdesign'),
  }),
};

export const XyRtxSelectorMeta = {
  id: 'XyRtxSelector',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyRtxSelector'),
  }),
};

export const XyEmailSelectorMeta = {
  id: 'XyEmailSelector',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyEmailSelector'),
  }),
};

export const XySourceCgiEditMeta = {
  id: 'XySourceCgiEdit',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XySourceCgiEdit'),
  }),
};

export const XySourceMethodEditMeta = {
  id: 'XySourceMethodEdit',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XySourceMethodEdit'),
  }),
};

export const XyWxworkSelectorMeta = {
  id: 'XyWxworkSelector',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyWxworkSelector'),
  }),
};

export const XyExcelFieldConfigMeta = {
  id: 'XyExcelFieldConfigEdit',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./ExcelFieldConfigEdit'),
  }),
};

export const XyWeworkMessageMeta = {
  id: 'XyWeworkMessage',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyWeworkMessage'),
  }),
};


export const XyCosSelectorMeta = {
  id: 'XyCosSelector',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyCosSelector'),
  }),
};

export const XyCosSelectorEnvMeta = {
  id: 'XyCosEnvSelector',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyCosEnvSelector'),
  }),
};

const excelEdit = () => ({
  component: import(/* webpackChunkName: "paramsform" */'./ExcelEdit'),
});
export const XyExcelEditMeta = {
  id: 'XyExcelEdit',
  visible: false,
  component: excelEdit,
};

export const XyFlowTypeSelectorMeta = {
  id: 'XyFlowTypeSelector',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./GPTCall/XyFlowTypeSelector'),
  }),
};

export const XyGPTAlertMeta = {
  id: 'XyGPTAlert',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./GPTCall/XyGPTAlert'),
  }),
};

export const XyGlobalModalSettingMeta = {
  id: 'XyGlobalModalSetting',
  visible: false,
  hideLabel: true,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyGlobalModalSetting'),
  }),
};

export const ExprInputMeta = {
  id: 'ExprInput',
  visible: false,
  component: () => ({
    component: async () => (await getUiCore()).ExprInput,
  }),
};

export const AiModelSelectMeta = {
  id: 'XyAiModelSelect',
  visible: false,
  component: () => ({
    component: import(/* webpackChunkName: "paramsform" */'./XyAiModelSelect'),
  }),
};
