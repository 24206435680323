<template>
  <a-layout-sider
    v-if="menus.length || !isRuntime || isMobileOrNarrowScreen"
    v-model="collapsed"
    :class="[
      `xy-sidebar xy-default-sidebar ${sidebarTheme}`,
      {
        'collapsed': collapsed,
        'runtime': isRuntime,
        'miniProgram': isMobileOrNarrowScreen,
      }
    ]"
    :width="computedWidth"
    :theme="sidebarTheme"
    :collapsible="isRuntime"
    :trigger="null"
    collapsed-width="0"
  >
    <!-- Sidebar Header头部菜单 -->
    <template v-if="isMobileOrNarrowScreen">
      <MiniprogramHeader
        :mode="mode"
        :class="{
          'header-hidden': collapsed
        }"
      />
      <div
        class="drawer-mask"
        :class="{'drawer-mask-opened' : !collapsed}"
        :style="maskLeft"
        @click="setCollapsed(true)"
      />
    </template>

    <!-- 应用标题 -->
    <template v-else>
      <template v-if="withTitle">
        <div
          id="xy-sidebar-title"
          :class="{ collapsed: collapsed }"
        >
          <img
            v-if="project.logo"
            :src="project.logo"
            class="xy-sidebar-logo"
          >
          <span
            v-show="!collapsed"
            class="xy-sidebar-text"
          >
            {{ project.name }}
          </span>
        </div>
      </template>

      <!-- 菜单标题 -->
      <template v-if="withMenuTitle">
        <div
          id="xy-sidebar-menu-title"
          :class="{collapsed:collapsed}"
        >
          <div
            v-if="sidebar.groupLogo"
            class="xy-sidebar-logo"
          >
            <public-w-icon-show-new
              :value="sidebar.groupLogo"
              class="logo-icon"
            />
          </div>
          <span
            v-show="!collapsed"
            class="xy-sidebar-text"
          >
            {{ sidebar.groupName || '' }}
          </span>
        </div>
      </template>

      <!-- 搜索框 -->
      <template v-if="withSearch">
        <div id="xy-sidebar-menu-search">
          <a-input
            v-model="searchInput"
            placeholder="搜索"
            allow-clear
            @change="handleSearch"
          >
            <a-icon
              slot="prefix"
              class="!opacity-80"
              type="search"
            />
          </a-input>
        </div>
      </template>
    </template>

    <XySidebarMenu
      :class="{
        'no-menu-title': !withMenuTitle,
        'hidden': collapsed
      }"
      :menus="searchInput ? searchMenus : menus"
      :selected-keys="selectedKeys"
      :open-keys="searchInput ? searchOpenKeys : openKeys"
      :show-icons="showIcons"
      @openChange="handleMenuOpenChange"
      @click="handleMenuClick"
    />

    <template v-if="!isMobileOrNarrowScreen">
      <!-- Sidebar 宽度调节 -->
      <div
        class="xy-sidebar-resizer"
        @mousedown="startResize"
        @touchstart="startResize"
      />
      <!-- Sidebar 开关 -->
      <CollapseTrigger
        v-if="isRuntime"
        :collapsed="collapsed"
        :is-show-collapse-trigger="isShowCollapseTrigger"
        @toggle-collapsed="toggleCollapsed"
      />
    </template>

    <!-- Sidebar 菜单底部无极标识 -->
    <template v-if="isIntranetWork">
      <div
        :class="[`sidebar-identify ${sidebarTheme}`]"
        @click="handleToWujiHome"
      >
        Powered by 无极
      </div>
    </template>
  </a-layout-sider>
</template>

<script>
import { watch, toRefs, ref, computed, getCurrentInstance } from '@vue/composition-api';
import { message } from 'ant-design-vue';
import { difference, isMatch, union, uniq, throttle } from 'lodash';
import qs from 'query-string';

import MiniprogramHeader from '@miniprogram/header/index';
import CollapseTrigger from '@components/sidebar/CollapseTrigger';
import useSidebar from '@composables/useSidebar';
import useLayout from '@composables/useLayout';
import logger from '@utils/logger';
import { runtimeInfo } from '@utils/fetch';
import { toPageByMenu } from '@utils/path';

import {
  getMatchedMenuKeys,
  getMenuUrl,
  getMatchedGroupId,
  getAllOpenKeys,
  getConfigOpenKeys,
  getGroupNameMap,
  filterSearchMenus,
} from './utils';
import XySidebarMenu from './menu';

export default {
  name: 'XySidebar',
  components: { MiniprogramHeader, CollapseTrigger, XySidebarMenu },
  inject: {
    wLayout: {
      from: 'wLayout',
      default: null,
    },
    w: {
      from: 'w',
      default: {},
    },
  },
  props: {
    // 模式
    mode: {
      type: String,
      default: 'runtime',
      validator(value) {
        return ['runtime', 'preview'].includes(value);
      },
    },
    previewGroupId: {
      type: String,
      default: '0',
    },
    isMobileOrNarrowScreen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const instance = getCurrentInstance();
    const { previewGroupId, isMobileOrNarrowScreen, mode } = toRefs(props);
    const lastOpenKey = ref([]); // 缓存上次打开的 openKeys
    const openKeys = ref([]); // 当前打开的 openKeys
    const selectedKeys = ref([]); // 当前展开的 SubMenu 菜单项 key 数组
    const sidebar = ref(null); // 当前选中的菜单项 key 数组

    watch(openKeys, (newValue) => {
      lastOpenKey.value = newValue || [];
    }), { immediate: true };

    const width = computed(() => sidebar?.value?.width ?? 209);

    const {
      project,
      roles,
      biz,
      isAdmin,
      sidebarGroups,
      visibleSidebarGroups,
      sidebarTheme,
      customSidebar,
    } = useLayout();

    const {
      collapsed,
      toggleCollapsed,
      setCollapsed,
      computedWidth,
      isShowCollapseTrigger,
      maskLeft,
      extraWidth,
      startResize,
      setMobileCollapsed,
    } = useSidebar({ isMobileOrNarrowScreen, width, mode });

    // 初始化预览时的 sidebar 参数
    const initPreviewSidebar = () => {
      sidebar.value = visibleSidebarGroups.value.find(group => group.groupId === previewGroupId.value);
      if (sidebar.value) {
        openKeys.value = getAllOpenKeys(sidebar.value.menus ?? []);
      }
    };

    // 菜单项搜索
    const searchInput = ref('');
    const searchMenus = ref([]);
    const searchOpenKeys = ref([]);
    const handleSearch = throttle(async () => {
      const menus = filterSearchMenus(instance?.proxy?.menus, searchInput.value || '');
      searchMenus.value = menus;
      searchOpenKeys.value = getAllOpenKeys(menus);
    }, 200);

    // 切换菜单组时清空搜索框内容
    watch(() => sidebar.value?.groupId, () => {
      searchInput.value = '';
    });

    return {
      lastOpenKey,
      openKeys,
      selectedKeys,
      sidebar,
      collapsed,
      toggleCollapsed,
      setCollapsed,
      computedWidth,
      isShowCollapseTrigger,
      maskLeft,
      extraWidth, // 暴露extraWidth用来进行单元测试
      startResize,
      setMobileCollapsed,
      project,
      roles,
      biz,
      isAdmin,
      sidebarGroups,
      visibleSidebarGroups,
      sidebarTheme,
      customSidebar,
      initPreviewSidebar,

      // 菜单搜索相关
      searchInput,
      handleSearch,
      searchMenus,
      searchOpenKeys,
    };
  },
  computed: {
    route() {
      let route = this.$route;
      try {
        // 无权限时根据重定向的 url 字段模拟路由选中菜单
        const { page403Path, page403Query: page403QueryStr } = runtimeInfo();
        const currentQuery = this.$route?.query || {};
        const page403Query = qs.parse(page403QueryStr)?.query || {};
        if (this.$route?.path === page403Path && this.$route?.query?.url && isMatch(currentQuery, page403Query)) {
          const url = new URL(this.$route?.query?.url);
          const base = this.$router?.options?.base;
          const path = url.href.replace(url.origin, '').replace(base, '');
          const mockRoute = this.$router?.resolve(path);
          if (mockRoute?.resolved) {
            route = mockRoute.route;
          }
        }
      } catch (err) {
        logger.error('菜单获取路由失败', err);
      } finally {
        return route;
      }
    },
    currentGroupId() {
      return this.sidebar.groupId;
    },
    currentGroupName() {
      return this.sidebar.groupName;
    },
    menus() {
      return this.sidebar?.menus ?? [];
    },
    groupNameMap() {
      return getGroupNameMap(this.visibleSidebarGroups);
    },
    withTitle() {
      return this.sidebar?.withTitle ?? false;
    },
    withMenuTitle() {
      return this.sidebar?.withMenuTitle ?? false;
    },
    // 是否默认开发所有子菜单项
    defaultOpenAllKeys() {
      return this.sidebar?.openAll ?? false;
    },
    // 是否手风琴展开模式
    accordion() {
      return this.sidebar?.accordion ?? false;
    },
    // 是否显示搜索框
    withSearch() {
      return this.sidebar?.withSearch ?? false;
    },
    showIcons() {
      return this?.sidebar?.showIcons ?? [true, false];
    },
    isRuntime() {
      return this.mode === 'runtime';
    },
    stringInteropContext() {
      return {
        ...(this.w?.mainRenderer ?? {}),
        $app: this.$app,
        w: this.w,
      };
    },
    isIntranetWork() {
      const { open, openDeployType, externalDeploy } = window?.GLOBAL_INFO || {};

      // 外部商业化环境
      if (externalDeploy) return false;

      // 当前在内网环境下
      if (!open) return true;

      // 在open版中，但是是在内网环境下
      if (open && openDeployType === 'designer') return true;

      // 外网环境统一不渲染
      return false;
    },
  },
  created() {
    this.$store.commit('layout/setSidebar', this);
    this.$store.commit('layout/setLayoutMode', this.mode);
    if (this.mode === 'preview') {
      this.$watch(() => ({
        project: this.project,
        roles: this.roles,
        biz: this.biz,
        isAdmin: this.isAdmin,
        previewGroupId: this.previewGroupId,
      }), () => {
        this.initPreviewSidebar();
      }, { immediate: true, deep: true });
    } else {
      this.initRuntimeSidebar();
      this.$watch(() => ({
        $route: this.route,
        roles: this.roles,
        biz: this.biz,
        isAdmin: this.isAdmin,
      }), () => {
        this.computeSidebar();
      }, { deep: true });

      // 自定义菜单改变时, 重新计算一次菜单项
      this.$watch(() => this.customSidebar, () => {
        this.computeSidebar();
      });

      if (this.wLayout) {
        this.wLayout.sidebar = this;
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('layout/removeSidebar', this);
  },
  methods: {
    // 初始化运行时 sidebar 参数
    initRuntimeSidebar() {
      this.computeSidebar();
      // 计算默认的打开菜单
      if (this.defaultOpenAllKeys) {
        this.openKeys = union(getAllOpenKeys(this.menus), this.openKeys);
      } else if (!this.accordion) {
        this.openKeys = union(getConfigOpenKeys(this.menus), this.openKeys);
      }
    },
    // 计算Sidebar的打开状态
    computeSidebar() {
      const groupId = getMatchedGroupId(this.visibleSidebarGroups, {
        $route: this.route,
        stringInteropContext: this.stringInteropContext,
      });
      this.sidebar = this.visibleSidebarGroups.find(group => `${group.groupId}` === `${groupId}`) || this.visibleSidebarGroups[0] || [];

      const menus = this.sidebar?.menus ?? [];
      const {
        openKeys,
        selectedKeys,
        activeMenu,
        parentMenus,
      } = getMatchedMenuKeys(menus, {
        $route: this.route,
        stringInteropContext: this.stringInteropContext,
      });

      // 合并保留当前打开的一级菜单
      if (this.accordion) {
        this.openKeys = union(openKeys, this.openKeys.filter(key => key.includes('.')));
      } else {
        this.openKeys = union(openKeys, this.openKeys);
      }
      this.selectedKeys = selectedKeys;
      if (activeMenu) {
        this.$store?.dispatch('navigation/addTag', { name: activeMenu.name, path: this.route.fullPath });
        this.$store?.dispatch('navigation/setBreadcrumb', [...parentMenus, activeMenu].map(menu => menu.name));
      } else {
        this.$store?.dispatch('navigation/setBreadcrumb', []);
      }
    },
    // 获取菜单URL
    getMenuUrl,
    // 菜单点击事件: 页面跳转
    handleMenuClick(menu, key) {
      // 重复点击不跳转页面, 但隐藏移动端菜单
      if (key && this.selectedKeys.some(item => item === key)) {
        this.setMobileCollapsed(true);
        if (getMenuUrl(menu) === this.route.path) return;
      };
      toPageByMenu(menu, {
        $router: this.$router,
        $route: this.route,
        stringInteropContext: this.stringInteropContext,
      });
    },
    // 菜单打开事件: 只展开当前父级菜单
    handleMenuOpenChange(openKeys) {
      let nextOpenKey = openKeys;
      if (this.accordion) {
        const newFirstOpenKey = openKeys.filter(key => !key.includes('.'));
        const oldFirstOpenKey = this.lastOpenKey.filter(key => !key.includes('.'));
        const diffFistOpenKey = difference(newFirstOpenKey, oldFirstOpenKey);
        if (diffFistOpenKey.length > 0) {
          nextOpenKey = openKeys.filter(key => key.includes('.')).concat(diffFistOpenKey);
        }
      }
      this.openKeys = nextOpenKey;
    },
    // Sidebar 实例供外部调用的方法
    /**
     * [Open-Api]添加菜单项
     *
     * @param menu 菜单Item，
     * @example
     *  {
     *    args: { target: 'self', url: '/path1' },
     *    name: '新路径',
     *    type: 'link'
     *  }
     * @param parentName 一级菜单的name
     * @param repeatBlock name重复时是否阻止添加
     * @return {boolean} true 表示执行成功, false 表示执行失败
     */
    append(menu, parentName, { repeatBlock = true } = {}) {
      // 一级导航直接 push
      if (!parentName) {
        if (repeatBlock) {
          const targetMenu = this.menus.find(m => m.name === menu.name);
          if (targetMenu) return false;
        }
        this.menus.push(menu);
        return true;
      }

      // 二级导航找到对应的一级导航再 push
      const parentMenu = this.menus.find(m => m.name === parentName);
      if (parentMenu) {
        if (!parentMenu.args) parentMenu.args = {};
        if (!Array.isArray(parentMenu.args.menus)) parentMenu.args.menus = [];
        if (repeatBlock) {
          const targetMenu = parentMenu?.args?.menus.find(m => m.name === menu.name);
          if (targetMenu) return false;
        }
        parentMenu?.args?.menus.push(menu);
        return true;
      }
      return false;
    },
    /**
     * [Open-Api]删除指定菜单
     *
     * @param position 菜单name
     * @return {boolean} true 表示执行成功, false 表示执行失败
     */
    remove(position) {
      const [menuName, submenuName] = position.split('.');
      if (submenuName) {
      // 移除二级菜单
        const parentMenu = this.menus.find(m => m.name === menuName);
        if (Array.isArray(parentMenu?.args?.menus)) {
          const delSubMenuIndex = parentMenu?.args?.menus.findIndex(m => m.name === submenuName);
          if (delSubMenuIndex > -1) {
            parentMenu?.args?.menus.splice(delSubMenuIndex, 1);
            return true;
          }
        }
      } else if (menuName) {
        // 移除一级菜单
        const delMenuIndex = this.menus.findIndex(m => m.name === menuName);
        if (delMenuIndex > -1) {
          this.menus.splice(delMenuIndex, 1);
          return true;
        }
      }
      return false;
    },
    /**
     * [Open-Api]跳转到指定菜单
     *
     * @param position 菜单name
     * @param groupName 菜单组name
     * @return {boolean} true 表示执行成功, false 表示执行失败
     */
    goto(position, groupName) {
      const res = this.groupNameMap?.[groupName || this.currentGroupName]?.[position];
      if (res?.menu) {
        this.handleMenuClick(res.menu);
      }
      return this;
    },
    /**
     * [Open-Api]设置当前菜单组
     *
     * @param position 菜单组 name / id
     * @example
     *   设置一级菜单: first
     * @return {Object} 当前组件实例
     */
    setCurrentGroup(position, { tips = true } = {}) {
      let currentSidebar = this.visibleSidebarGroups.find(group => `${group.groupName}` === `${position}`);
      if (!currentSidebar) {
        currentSidebar = this.visibleSidebarGroups.find(group => `${group.groupId}` === `${position}`);
      }
      if (currentSidebar) {
        this.sidebar = currentSidebar;
      } else if (tips) {
        message.warning(`Sidebar.setCurrentGroup: 找不到 ${position} 菜单组`);
      }
      return this;
    },
    /**
     * [Open-Api]设置当前高亮的菜单项
     *
     * @param position 菜单name
     * @example
     *   设置一级菜单: first
     *   设置二级菜单: first.second
     *   设置三级菜单: first.second.third
     */
    setCurrent(position) {
      this.$nextTick(() => {
        const res = this.groupNameMap?.[this.currentGroupName]?.[position];
        if (res) {
          const { openKeys = [], selectedKeys = [] } = res;
          this.openKeys = uniq([...this.openKeys, ...openKeys]);
          this.selectedKeys = selectedKeys;
        }
      });
    },
    handleToWujiHome() {
      window.open('https://xy.woa.com/xy/app/prod/portal/index');
    },
  },
};

</script>
<style lang="scss">
$menuItemHeight: var(--xy-custom-sidebar-menu-item-height);

.xy-sidebar {
  &.runtime {
    border-right: 1px solid #eee;
  }

  &.miniProgram {
    border-right: 0;
  }

  // from ui-core/src/comps/Aside.vue
  .xy-sidebar-resizer{
    position: absolute;
    top: 0;
    width: 4px;
    height: 100%;
    margin: 0 -2px;
    cursor: ew-resize;
    z-index: 1;
    right:0;
    &:hover {
      width: 6px;
      margin: 0 -3px;
      background: rgba(#fff, 0.06);
    }
  }

  .drawer-mask{
    position: fixed;
    top: var(--xy-header-height);
    width: 0;
    height: 0;
    transition: background 0.3s ,left 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86) 0s, width 0s 0.3s , height 0s 0.3s;
    z-index: 1;
    &-opened{
      background: rgba(0,0,0,0.3);
      width: 100%;
      height: 100%;
      transition: background 0.3s ,left 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86) 0s, width 0s 0s , height 0s 0s;
    }
  }

  .sidebar-identify {
    padding: 16px 0;
    cursor: pointer;
    text-align: center;
    color: rgba(0, 0, 0, 0.48);

    &.dark {
      color: rgba(255, 255, 255, 0.48);
    }
  }
}

#xy-runtime-sidebar.xy-default-sidebar.ant-layout-sider {
  background-color: var(--xy-custom-sidebar-bg-color);
}

.xy-default-sidebar {
  padding: var(--xy-custom-sidebar-padding);
  &.collapsed{
    margin-right: 0;
  }

  .ant-layout-sider-children {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-menu-inline{
    width: 100%;
  }

  // 隐藏antd默认的border
  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 0;
  }

  #xy-sidebar-title {
    height: var(--xy-custom-sidebar-title-height);
    display: flex;
    align-items: center;
    gap: var(--xy-custom-sidebar-title-gap);
    padding: var(--xy-custom-sidebar-title-padding);
    margin: var(--xy-custom-sidebar-title-margin);
    border-bottom: var(--xy-custom-sidebar-title-border-bottom);
    box-sizing: border-box;
    transition: padding 0.3s;
    &.collapsed{
      padding: 0;
    }

    .xy-sidebar-logo {
      width: var(--xy-custom-sidebar-title-logo-width);
      height: var(--xy-custom-sidebar-title-logo-height);
      overflow-x: hidden;
    }
    .xy-sidebar-text {
      font-size: var(--xy-custom-sidebar-title-font-size);
      color: var(--xy-custom-sidebar-title-color);
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  #xy-sidebar-menu-title {
    margin: var(--xy-custom-sidebar-menu-title-margin);
    height: $menuItemHeight;
    display: flex;
    align-items: center;
    gap: var(--xy-custom-sidebar-menu-title-gap);
    padding: var(--xy-custom-sidebar-menu-title-padding);
    box-sizing: border-box;
    transition: padding 0.3s;
    &.collapsed{
      padding: 0;
    }

    .xy-sidebar-logo {
      width: var(--xy-custom-sidebar-menu-title-logo-size);
      height: var(--xy-custom-sidebar-menu-title-logo-size);
      background-color: var(--xy-custom-sidebar-menu-title-logo-bg-color);
      border-radius: 100%;
      display: flex;
      align-items: center;
      overflow-x: hidden;
      justify-content: center;
      .logo-icon{
        font-size: var( --xy-custom-sidebar-menu-title-logo-font-size);
        color: var(--xy-custom-sidebar-menu-title-logo-color);
        width: max-content;
        height: max-content;
      }
    }
    .xy-sidebar-text {
      font-family: PingFangSC-Medium;
      font-size: var(--xy-custom-sidebar-menu-title-text-font-size);
      color: var(--xy-custom-sidebar-menu-title-text-color);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  #xy-sidebar-menu-search {
    padding: var(--xy-custom-sidebar-menu-search-padding);
  }

  #xy-sidebar-menu {

    &.ant-menu-root {
      flex: 1;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
    &.hidden {
      display: none;
    }

    .first-menu-item.ant-menu-item,
    .first-menu-item.ant-menu-submenu > .ant-menu-submenu-title {
      padding-left: var(--xy-custom-sidebar-menu-item-left-padding-first) !important;
      font-size: var(--xy-custom-sidebar-font-size-first);
    }

    .second-menu-item.ant-menu-item,
    .second-menu-item.ant-menu-submenu > .ant-menu-submenu-title {
      padding-left: var(--xy-custom-sidebar-menu-item-left-padding-second) !important;
    }

    .third-menu-item.ant-menu-item,
    .third-menu-item.ant-menu-submenu > .ant-menu-submenu-title {
      padding-left: var(--xy-custom-sidebar-menu-item-left-padding-third) !important;
    }

    .forth-menu-item.ant-menu-item {
      padding-left: var(--xy-custom-sidebar-menu-item-left-padding-forth) !important;
    }

    .ant-menu-item, .ant-menu-submenu {
      padding-bottom: 0;
      .icon-placeholder {
        margin-left: -10px;
      }
      i.anticon {
        flex-shrink: 0;
      }
      .ellipsis {
        display: inline-block;
        text-overflow: ellipsis; // 在多行文本的情况下，用...隐藏超出范围的文本
        word-break: break-all;
        overflow: hidden;
      }
    }

    .ant-menu-submenu-open {
      .ant-menu {
        margin-bottom: var(--xy-custom-sidebar-sub-menu-bottom-margin);
      }
      .ant-menu-submenu-title,.ant-menu-item {
        margin: var(--xy-custom-sidebar-menu-open-item-margin);
      }
    }

    .ant-menu-item, .ant-menu-submenu-title {
      height: $menuItemHeight;
      line-height: $menuItemHeight;
      box-sizing: border-box;
      margin: var(--xy-custom-sidebar-menu-item-margin);
      padding-bottom: 0;
    }

    .ant-menu-item {
      padding-right: var(--xy-custom-sidebar-menu-item-right-padding);
    }
    .ant-menu-submenu-title {
      padding-right: var(--xy-custom-sidebar-menu-item-right-padding-arrow);
    }

    // 菜单收起的情况
    .ant-menu-inline-collapsed {
      .ant-menu-item, .ant-menu-submenu-title {
        display: flex;
        justify-content: center;
        .anticon {
          margin-right: 0px;
          line-height: 16px;
        }
      }
      .ant-menu-submenu-selected {
        .anticon {
          color: var(--wuji-primary-color);
        }
      }
    }

    .ant-menu-item, .ant-menu-submenu-title {
      width: var(--xy-custom-sidebar-menu-item-width);
      .anticon {
        margin-right: 8px;
        font-size: var(--xy-custom-sidebar-font-size-icon);
        width: var(--xy-custom-sidebar-font-size-icon);
        height: var(--xy-custom-sidebar-font-size-icon);
      }
      & > span {
        display: flex;
        align-items: center;
      }
    }

    .ant-menu-item {
      display: flex;
      align-items: center;
      font-size: var(--xy-custom-sidebar-font-size-second);
    }

    // 去除选中态右侧的边框
    .ant-menu-item::after {
      opacity: 0;
    }
  }
}


.header-hidden {
  display: none;
}

</style>
