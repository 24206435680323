import wujiFetch from '@/utils/wujiFetch';
import { message } from 'ant-design-vue';
const prefix = '/api/xy/template/component';
function getUrl(path) {
    return `${prefix}${path}`;
}
export var CompTemplateStateMode;
(function (CompTemplateStateMode) {
    CompTemplateStateMode["create"] = "create";
    CompTemplateStateMode["replace"] = "replace";
    CompTemplateStateMode["list"] = "list";
})(CompTemplateStateMode || (CompTemplateStateMode = {}));
export default {
    state: {
        mode: 'list',
        selectComponent: null,
        templateList: [],
        editableTemplates: [],
        selectComponentInfo: null,
    },
    actions: {
        /**
         * 获取组件模版列表
         */
        async fetchComponentTemplateList({ commit }, { projectId }) {
            try {
                const res = await wujiFetch(getUrl(`/list?exclude=pageInfo&projectid=${projectId}`), {
                    method: 'get',
                });
                commit('setCompTemplateState', {
                    templateList: res,
                });
                return res;
            }
            catch (err) {
                message.error(`获取组件模版列表失败${err.message}`);
            }
        },
        /**
         * 获取组件模版列表
         */
        async getComponentTemplate({}, { id }) {
            try {
                const res = await wujiFetch(getUrl(`/get/${id}`), {
                    method: 'GET',
                });
                return res;
            }
            catch (err) {
                message.error('获取组件模版失败');
            }
        },
        /**
         * 更新模版
         */
        async updateComponentTemplate({ commit, dispatch }, { template }) {
            try {
                await wujiFetch(getUrl('/patch'), {
                    method: 'POST',
                    body: JSON.stringify(template),
                });
                message.success('模板更新成功');
                commit('setCompTemplateState', {
                    mode: 'list',
                });
                dispatch('fetchComponentTemplateList', { projectId: template.projectId });
                dispatch('getEditableCompTemplates');
            }
            catch (error) {
                console.error(error);
                message.error(error.message);
            }
        },
        /**
         * 创建模版
         */
        async createComponentTemplate({ dispatch }, template) {
            try {
                const res = await wujiFetch(getUrl('/create'), {
                    method: 'post',
                    body: JSON.stringify(template),
                });
                dispatch('fetchComponentTemplateList', { projectId: template.projectId });
                message.success('模板创建成功');
                return res;
            }
            catch (error) {
                message.error(error.message);
            }
        },
        /**
         * 获取当前用户有编辑全局的模板list
         */
        async getEditableCompTemplates({ commit, rootState }, { type = '', projectId } = {}) {
            try {
                const url = getUrl(`/editable?projectid=${projectId || rootState.project.projectInfo?.id}&${type ? `filter=${encodeURIComponent(`type="${type}"`)}` : ''}`);
                const res = await wujiFetch(url, {
                    method: 'GET',
                });
                commit('setCompTemplateState', {
                    editableTemplates: res,
                });
                return res;
            }
            catch (err) {
                message.error('获取可管理的组件模版列表失败');
            }
        },
        /**
         * 删除模版
         */
        async deleteCompTemplate({ dispatch, rootState }, { _id, projectId }) {
            try {
                await wujiFetch(getUrl('/patch'), {
                    method: 'POST',
                    body: JSON.stringify({
                        _id,
                        disabled: 1,
                    }),
                });
                message.success('模板删除成功');
                dispatch('fetchComponentTemplateList', { projectId: projectId || rootState.project.projectInfo?.id });
                dispatch('getEditableCompTemplates');
            }
            catch (error) {
                console.error(error);
                message.error('模版删除失败');
            }
        },
        /**
         * 模版排序
         */
        async sortCompTemplate({ dispatch, rootState }, { tplSort, projectId }) {
            try {
                const validProjectId = projectId || rootState.project.projectInfo?.id;
                await wujiFetch(getUrl(`/sort/create?projectid=${validProjectId}`), {
                    method: 'post',
                    body: JSON.stringify({ tplSort }),
                });
                dispatch('fetchComponentTemplateList', { projectId: validProjectId });
            }
            catch (err) {
                throw err;
            }
        },
    },
    mutations: {
        setCompTemplateState(state, payload) {
            Object.assign(state, payload);
        },
    },
};
