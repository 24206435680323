import getXyManagePathPrefix from '@utils/getXyManagePathPrefix';

import wujiFetch from '@utils/wujiFetch';

const getApiPrefix = () => `${getXyManagePathPrefix({ isRuntimeRoute: true })}`;

export const ApprovalOperation = [
  {
    value: 'approve',
    label: '同意',
  },
  {
    value: 'reject',
    label: '驳回',
  },
];

const fetchJob = async (projectId, pageId, jobId) => {
  if (!jobId) throw new Error('Need jobID');
  const query = {
    projectid: projectId,
    pageid: pageId,
  };

  const result = await wujiFetch(`${getApiPrefix()}/flow/job/${jobId}`, {
    query,
  });
  return result;
};

// 修改审批节点的状态
const approval = async (projectId, nodeId, jobId, operation, comment, form) => {
  const query = {
    projectid: projectId,
    jobId,
    nodeId,
  };

  const res = await wujiFetch(`${getApiPrefix()}/flow/job/approval`, {
    query,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      operation,
      comment,
      form,
    }),
  });
  return res;
};

const startFlow = async (projectId, flowId, params) => {
  const query = {
    projectid: projectId,
  };

  const res = await wujiFetch(`${getApiPrefix()}/flow/job`, {
    query,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      flowId,
      params,
    }),
  });
  return res;
};

const fetchFlow = async (projectId, flowId) => {
  const query = {
    projectid: projectId,
  };

  const result = await wujiFetch(`${getApiPrefix()}/flow/flow/${flowId}`, {
    query,
  });
  return result;
};

// 获取审批节点信息
const fetchApprovalNodeInfo = async (jobId, nodeId, tickId = '') => {
  const query = {
    nodeId,
    jobId,
    tickId,
  };
  if (!nodeId) {
    throw new Error('缺少nodeId');
  }
  const result = await wujiFetch(`${getApiPrefix()}/flow/job/node/info/approval`, {
    query,
  });
  return result;
};

// 获取日志输出节点
const fetchLogNodeInfo = async (jobId, nodeId, tickId = '') => {
  const query = {
    nodeId,
    jobId,
    tickId,
  };

  const result = await wujiFetch(`${getApiPrefix()}/flow/job/node/info/log`, {
    query,
  });
  return result;
};

const fetchJobList = async (projectId, title, creator) => {
  const query = {
    projectid: projectId,
    title,
    creator,
  };

  const result = await wujiFetch(`${getApiPrefix()}/flow/entity`, {
    query,
  });
  return result;
};

const fetchJobListByType = async (projectId, title, type) => {
  const query = {
    projectid: projectId,
    title,
  };

  const result = await wujiFetch(`${getApiPrefix()}/flow/entity/list/${type}`, {
    query,
  });
  return result;
};

const updateApprovers = async (projectId, jobId, nodeId, approvers) => {
  const query = {
    projectid: projectId,
    jobId,
    nodeId,
  };

  const result = await wujiFetch(`${getApiPrefix()}/flow/job/approver`, {
    query,
    method: 'PATCH',
    body: JSON.stringify({
      approvers,
    }),
  });
  return result;
};

export default {
  fetchJob,
  fetchFlow,
  approval,
  startFlow,
  fetchApprovalNodeInfo,
  fetchLogNodeInfo,
  fetchJobList,
  fetchJobListByType,
  updateApprovers,
};
