// 等待某一个instance.$refs可以被访问后，执行handler
export const waitUntilRefReady = (instance, refKey, handler, timeout = 1) => {
  if (instance.$refs?.[refKey]) {
    handler(instance.$refs[refKey]);
  } else {
    setTimeout(() => {
      waitUntilRefReady(instance, refKey, handler, Math.max(100, timeout * 2));
    }, timeout);
  }
};
