export const jobSchema = {
    type: 'object',
    id: 'job',
    title: '当前流程',
    fields: [
        { id: 'id', type: 'string', title: '流程单ID' },
        { id: 'title', type: 'string', title: '流程单标题' },
        { id: 'status', type: 'string', title: '状态' },
        { id: 'params', type: 'object', title: '发起时参数', fields: [] },
        { id: 'state', type: 'object', title: '全局变量', fields: [] },
        { id: 'nodes', type: 'object', title: '各节点数据', fields: [] },
        { id: 'message', type: 'string', title: '流程状态说明' },
        { id: 'creator', type: 'string', title: '发起人' },
        { id: 'currentApprovers', type: 'string', title: '当前审批人' },
        { id: 'relatedUsers', type: 'string', title: '关注人' },
        { id: 'flowId', type: 'string', title: '流程类型' },
        { id: 'ctime', type: 'string', title: '流程发起时间' },
        { id: 'envId', type: 'string', title: '环境' },
        { id: 'url', type: 'string', title: '流程单网址' },
        {
            id: 'detailBlocks', type: 'array', title: '流程页面的区块',
            items: {
                type: 'object',
                fields: [
                    { id: 'title', type: 'string', title: '区块标题' },
                    { id: 'ctime', type: 'number', title: '时间' },
                    { id: 'nodeId', type: 'string', title: '对应步骤节点ID' },
                    { id: 'type', type: 'string', title: '对应步骤节点类型' },
                    // { id: 'nodePagelet', type: 'string', title: '对应页面片ID' },
                ],
            },
        },
        {
            id: 'reload', type: 'methods', title: '刷新页面数据',
        },
        {
            id: 'isWaitingForNextBlock', type: 'boolean', title: '等待下一区域',
        },
    ],
};
