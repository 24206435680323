import { cloneDeep } from 'lodash';
/**
 * 返回一个collect函数 收集所有输入的参数 并定期调用triggerFn对收集到的参数进行处理
 * 该函数每次被调用后的参数将按照reduceFn被收集
 * 同时如果没有triggerFn被启动 则会启动一个单例的triggerFn 直到resetCollect被调用 认为此次收集已结束
 * 在triggerFn中 可以通过getPayload获取到最新的collection 通过resetCollect结束本次收集
 * */
export function collect(reduceFn, reduceDefault, triggerFn) {
    let reduceTemp = cloneDeep(reduceDefault);
    let pending = false;
    const resetCollect = () => {
        pending = false;
        reduceTemp = cloneDeep(reduceDefault);
    };
    const getPayload = () => reduceTemp;
    return function (payload) {
        reduceTemp = reduceFn(reduceTemp, payload);
        if (!pending) {
            pending = true;
            triggerFn(getPayload, resetCollect);
        }
        return { getPayload, resetCollect };
    };
}
