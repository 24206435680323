import logger from 'js-logger';
logger.useDefaults();
// 默认的logger只会打印logger.ERROR级别的log
export default logger;
/**
 * 获取一个模块专属的log，可以自定义打印的级别
 * 参考https://www.npmjs.com/package/js-logger#named-loggers
 * @param moduleName
 * @returns
 */
export const getModuleLogger = (moduleName) => logger.get(moduleName);
export const setupLogger = () => {
    setLoggerLevel();
    window.logger = logger;
    window.logAll = logAll;
    window.dismissAll = dismissAll;
};
// 默认只展示error级别的log，但是用户可以通过设置local storage来调整级别
const setLoggerLevel = () => {
    const level = getLocalStorageLevel();
    logger.setLevel(level);
};
// 从local storage中读取设置的key，返回对应的log level
const getLocalStorageLevel = () => {
    const level = localStorage.getItem('wuji-logger-level');
    if (!level)
        return logger.ERROR;
    switch (level) {
        case 'trace':
            return logger.TRACE;
        case 'info':
            return logger.INFO;
        case 'debug':
            return logger.DEBUG;
        case 'warn':
            return logger.WARN;
        case 'error':
        default:
            return logger.ERROR;
    }
};
// 在window上挂一个方法，调用后直接展示所有的log
const logAll = () => {
    localStorage.setItem('wuji-logger-level', 'trace');
};
// 在window上挂一个方法，调用后只保留error
const dismissAll = () => {
    localStorage.setItem('wuji-logger-level', 'error');
};
