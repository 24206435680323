/**
 * 编辑模式的路由
 */
import Vue from 'vue';
import { message } from 'ant-design-vue';
import qs from 'query-string';
import { cloneDeep, camelCase, upperFirst, isNil } from 'lodash';

import stringInterop from '@tencent/ui-core/lib/utils/stringInterop';

import RuntimeLayout from '@pages/app/runtimeLayout/Layout.vue';
import Page from '@pages/app/index.vue';
import DataTable from '@pages/runtime/dataTable';
import NotFound from '@pages/notFound/notFound.vue';
import Login from '@pages/login/index.vue';
import PermissionDenied from '@pages/PermissionDenied.vue';
import builtinRoute from './runtimeBuiltInRoute';
import store from '@store';
import { getBaseAppPath, isRegexPath, getWithoutNilQuery } from '@utils/path';
import { PAGE_TYPE } from '@/loaders/page/consts';
import { isOffline } from '@/utils/offline';

const getAppPageList = async ({ projectId, env }) => {
  let appInfo = {};
  let pageList = [];

  try {
    // 合并 project 和 page 数据请求
    // 不能直接用 window.xy_runtime_project, dispatch 里有对部分数据做格式化/兼容处理
    [appInfo, pageList] = await Promise.all([
      store.dispatch('runtime/getProject', { projectId, env }),
      store.dispatch('runtime/getBasicPageList', { projectId, env }),
    ]);
  } catch (e) {
    message.error(`获取应用和页面数据失败: ${e.message}`);
  }

  return { appInfo, pageList };
};

const buildRouteMeta = ({ project, page, pages }) => {
  const pageAttr = page.pageAttr ?? {};
  const { customNav, customSidebar } = pageAttr;

  const renderNavId = (isNil(customNav) || customNav === 'system') ? project?.advanceConfig?.customNav : customNav;
  if (renderNavId && renderNavId !== 'default') {
    const target = pages.find(p => p.pageId === renderNavId);
    pageAttr.height = target?.pageAttr?.height;
  }

  const renderSidebarId = (isNil(customSidebar) || customSidebar === 'system') ? project?.advanceConfig?.customSidebar : customSidebar;
  if (renderSidebarId && renderSidebarId !== 'default') {
    const target = pages.find(p => p.pageId === renderSidebarId);
    pageAttr.width = target?.pageAttr?.width;
  }

  return {
    pageId: page.pageId,
    pageName: page.name,
    pageAttr,
    isKeepAlive: pageAttr?.keepAlive,
    layoutType: page.layoutType,
    pageType: page.pageType || 'page',
  };
};

// 根据页面 ID 生成运行时的组件名
function getComponentName(prefix, pageId) {
  return `${prefix}${upperFirst(camelCase(pageId))}`.replace(/_/g, '-');
}

export default async ({ projectId, env, branch }) => {
  store.commit('runtime/setMode', 'runtime');
  const routerArray = cloneDeep([builtinRoute.route]);

  const { appInfo, pageList } = await getAppPageList({ projectId, env });
  Vue.prototype.$project = { appInfo, pageList };

  const pageNormalRoute = []; // 普通路径
  const pageRegexRoute = []; // 正则表达式路径

  pageList.forEach((page) => {
    const isDatatable = page.pageType === PAGE_TYPE.DATA_TABLE;
    const component = isDatatable
      ? Vue.extend({ ...DataTable, name: getComponentName('DataTable', page.pageId) })
      : Vue.extend({ ...Page, name: getComponentName('RuntimePage', page.pageId) });

    pushRoute(page.path, page.pageId, page, component);
    if (isOffline()) {
      pushRoute(`${page.path}.html`, `${page.pageId}_offline`, page, component);
    }
  });

  function pushRoute(path, name, page, component) {
    const route = {
      path,
      name,
      component,
      props: { projectId, pageId: page.pageId },
      meta: buildRouteMeta({ project: appInfo, page, pages: pageList }),
    };
    if (isRegexPath(page.path)) {
      pageRegexRoute.push(route);
    } else {
      pageNormalRoute.push(route);
    }
  }

  // [vue-router] 同一个路径可以匹配多个路由时，匹配的优先级就按照路由的定义顺序：路由定义得越早，优先级就越高。
  // 这里需要把正则表达式的路径放在后面定义, 让普通路径的页面优先命中
  routerArray.push(...pageNormalRoute, ...pageRegexRoute);

  // 流程引擎中的默认页面片是以 !generated: 开头的
  routerArray.push({
    path: '/!generated:*/',
    name: 'generate',
    component: Page,
    props(route) {
      const [pageId] = route.path.match(/!generated:.+/);
      return { projectId, pageId: decodeURIComponent(pageId) };
    },
  });

  // 根路径
  const rootPath = '/';
  if (routerArray.every(route => route.path !== rootPath)) {
    const rootRoute = { path: '/' };
    if (appInfo?.homePage?.path) {
      rootRoute.redirect = () => {
        // Query 参数支持插值表达式
        const query = qs.parse(stringInterop(appInfo.homePage.query || '', {
          ...window?.w?.mainRenderer ?? {},
          $app: Vue.prototype.$app,
          w: window?.w,
        }));
        return {
          path: appInfo.homePage.path,
          query: getWithoutNilQuery(query),
        };
      };
    }
    routerArray.unshift(rootRoute);
  }

  // 403
  const PATH_403 = '/403';
  if (routerArray.every(route => route.path !== PATH_403)) {
    routerArray.push({
      path: PATH_403,
      component: PermissionDenied,
    });
  }

  // 404
  const PATH_404 = '/404';
  if (routerArray.every(route => route.path !== PATH_404)) {
    routerArray.push({
      path: PATH_404,
      component: NotFound,
    });
  }

  routerArray.push({
    path: '/login',
    component: Login,
  });

  // 兼容小程序智能网关跳转过来的 path
  routerArray.push({
    path: '/an:*',
    redirect: to => `/${to.path.split('/').pop()}`,
  });

  // 通配符
  const PATH_WILDCARD = '*';
  if (routerArray.every(route => route.path !== PATH_WILDCARD)) {
    routerArray.push({
      path: PATH_WILDCARD,
      redirect: '/404',
    });
  }

  return {
    mode: 'history',
    base: getBaseAppPath({ env, projectId, branch }),
    routes: [
      {
        path: '/',
        component: RuntimeLayout,
        props: {
          appConfig: appInfo,
          env,
          mode: 'runtime',
        },
        children: routerArray,
      },
    ],
  };
};

