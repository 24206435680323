const STYLE_ID = 'flow_custom_style';

const getStyleElement = () => {
  let styleSheet;
  const existSheet = document.getElementById(STYLE_ID);

  if (!existSheet) {
    styleSheet = document.createElement('style');
    styleSheet.id = STYLE_ID;
    document.head.appendChild(styleSheet);
  } else {
    styleSheet = existSheet;
  }
  return styleSheet;
};

const appendCustomStyle = () => {
  const styleSheet = getStyleElement();
  styleSheet.innerText = `
  @keyframes floatUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }`.replace(/\n/g, '');
};

export default appendCustomStyle;
