import pageLoader from '@loaders/page/loader';

export default {
  type: 'object',
  fields: [
    {
      id: 'type',
      type: 'string',
      title: '类型',
      enum: [
        { label: '页面(片)', value: 'page' },
        { label: '当前页面内的页面片', value: 'pagelet' },
        { label: '流程节点', value: 'flowNodePagelet' },
      ],
      default: 'page',
    },
    {
      id: 'pageId',
      type: 'string',
      title: '页面(片)',
      ui: { type: 'XyInnerPageSelector' },
      default: '',
      condition: 'props.type === "page"',
    },
    {
      id: 'pageletId',
      type: 'string',
      title: '当前页面内的页面片',
      ui: { type: 'XyInnerPageletSelector' },
      default: '',
      condition: 'props.type === "pagelet"',
    },
    {
      id: 'flowNodePageletConfig',
      type: 'any',
      title: '流程和节点',
      ui: { type: 'XyInnerFlowNodePageletConfig' },
      default: {
        flowId: '',
        nodeId: '',
      },
      condition: 'props.type === "flowNodePagelet"',
    },
    {
      id: 'params',
      type: 'array',
      title: '参数 (页面/页面片内通过 props.xxx 取参)',
      ui: {
        type: 'XyPageletPropsEditor',
        props: {
          allowBind: true,
          async getPagelet(inputBox) {
            const { pageId, pageletId, type } = inputBox.container.stub.parent.value;
            if (type === 'page' && pageId) {
              const { projectConfig: { id: projectId } } = inputBox.wContext;  // wContext.projectId 可能是空的
              const ans = await pageLoader.getPageDetail({ projectId, pageId });
              const pageConfig = ans?.pageConfig;
              const pagelets = Array.isArray(pageConfig) ? pageConfig : [];
              return pagelets?.find(x => x.id === 'default');
            }
            if (type === 'pagelet' && pageletId) {
              const pageConfig = inputBox?.wContext?.config?.pageConfig;
              const pagelets = Array.isArray(pageConfig) ? pageConfig : [];
              return pagelets?.find(x => x.id === pageletId);
            }
          },
        },
      },
      items: {
        type: 'object',
        fields: [
          { id: 'key', type: 'string' },
          { id: 'value', type: 'string' },
          { id: 'bind', type: 'string' },
        ],
      },
    },
    {
      id: 'loadingMode',
      type: 'string',
      title: '加载模式',
      description: '默认模式显示加载文案和进度条',
      enum: [
        { label: '默认', value: 'default' },
        { label: '占位', value: 'skeleton' },
        { label: '静默', value: 'silence' },
      ],
      default: 'default',
    },
  ],
};
