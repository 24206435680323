import { once } from 'lodash';

/**
 * 动态加载css脚本
 * @param {string} cssText css样式
 */
const loadStyleString = (cssText) => {
  const style = document.createElement('style');
  style.type = 'text/css';
  try {
    // firefox、safari、chrome和Opera
    style.appendChild(document.createTextNode(cssText));
  } catch (ex) {
    // IE早期的浏览器 ,需要使用style元素的stylesheet属性的cssText属性
    style.styleSheet.cssText = cssText;
  }
  document.getElementsByTagName('head')[0].appendChild(style);
};
/** https://github.com/ant-design/ant-design/issues/11584 */
// 兼容动画，不要太突然弹出
const hiddenCss = `
.ant-menu-submenu-popup { display: none!important; }
.ant-menu-inline-collapsed-tooltip { display: none!important; }
.ant-layout-sider { transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86)!important; }
`;
const loadMiniProgramSidebarStyleOnce = once(() => loadStyleString(hiddenCss));
export default loadMiniProgramSidebarStyleOnce;
