<template>
  <a-modal
    :visible="visible"
    width="1200px"
    title="扩展插件列表"
    wj-mod="扩展插件列表浮层"
    :footer="null"
    class="pluginList"
    centered
    @cancel="handleClose"
  >
    <a-spin :spinning="loading">
      <div
        v-if="pluginList.length !== 0"
        class="wrapper"
      >
        <div class="container">
          <div
            v-for="plugin in sortedPluginList"
            :key="plugin.pluginId"
          >
            <div
              class="pluginItem"
              :class="{ 'installed': isInstall(plugin.pluginId)}"
            >
              <div class="pluginTitle">
                <div>
                  <div class="pluginTitleWrap">
                    <span class="pluginTitleText">{{ plugin.detail.name }}</span>
                    <a-tooltip
                      v-for="tag in getPluginTags(plugin)"
                      :key="tag.text"
                    >
                      <template slot="title">
                        {{ tag.description }}
                      </template>
                      <a-tag :color="tag.color">
                        {{ tag.text }}
                      </a-tag>
                    </a-tooltip>
                  </div>
                  <a-button
                    type="link"
                    class="pluginAuthor"
                    @click="contactAuthor(plugin.publisher)"
                  >
                    By {{ plugin.publisher }}&nbsp;&nbsp;[{{ plugin.detail.version }}]
                  </a-button>
                </div>
                <div class="pluginTitleIconWrap">
                  <img
                    class="pluginTitleIcon"
                    :src="plugin.detail.icon"
                  >
                </div>
              </div>
              <div class="pluginBody">
                <!-- eslint-disable vue/no-v-html -->
                <div
                  class="pluginDescription"
                  v-html="plugin.detail.description"
                />
                <!-- eslint-enable -->
                <div class="pluginBodyBtns">
                  <a-button
                    type="link"
                    size="small"
                    :class="{ disabled: !isInstall(plugin.pluginId) }"
                    wj-eid="配置"
                    @click="
                      isInstall(plugin.pluginId) ? showDrawer(plugin) : () => {}
                    "
                  >
                    <a-icon
                      key="edit"
                      type="setting"
                    />
                    配置
                  </a-button>
                  <a-button
                    size="small"
                    :wj-eid="isInstall(plugin.pluginId) ? '禁用' : '启用'"
                    @click="
                      isInstall(plugin.pluginId)
                        ? handleUnInstall(plugin.pluginId)
                        : showDrawer(plugin)
                    "
                  >
                    <a-icon
                      key="api-disconnect"
                      :type="isInstall(plugin.pluginId) ? 'disconnect' : 'api'"
                    />
                    {{ isInstall(plugin.pluginId) ? "禁用" : "启用" }}
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a-drawer
          v-if="selectPlugin"
          :title="selectPlugin.detail.name"
          placement="right"
          :closable="false"
          width="600"
          :visible="drawerVisible"
          :get-container="false"
          :wrap-style="{ position: 'absolute' }"
          @close="onClose"
        >
          <data-form
            :key="selectPlugin.pluginId"
            v-model="selectPluginValue"
            class="uc-dataForm uc-dataForm-vertical"
            :context-options="contextOptions"
            :schema="selectPlugin.detail.propsSchema"
          />
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="onClose"
            >
              取消
            </a-button>
            <a-popconfirm
              placement="bottom"
              ok-text="全应用"
              cancel-text="当前页"
              @confirm="onSave('global')"
              @cancel="onSave('local')"
            >
              <template slot="title">
                <p>请问是要全应用启用还是只在当前页启用？</p>
              </template>
              <a-button
                type="primary"
                :loading="updating"
              >
                保存
              </a-button>
            </a-popconfirm>
          </div>
        </a-drawer>
      </div>
      <div
        v-else
        class="wrapper"
      >
        <h3 class="emptyList">
          暂时还没有扩展插件~
        </h3>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { DataForm } from '@tencent/data-schema/lib/vue-ui/DataForm';
import { useActions } from 'vuex-composition-helpers';
import { useStore } from '@store/index';
import { getDefaultValue } from '@tencent/data-schema-core';
import pluginLoader from '@/loaders/plugin/loader';
import UICoreXiaoyaoPlugin from '../../components/uicorePlugin/UICoreXiaoyaoPlugin';
import { computed, toRefs } from '@vue/composition-api';
import { toHubListWithoutRegistryPrefix } from '@/utils/componentHub';

export const GLOBAL_PAGE_ID = '*';


export default {
  name: 'PluginList',
  components: {
    DataForm,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  emits: ['message'],
  setup(props) {
    const store = useStore();
    const { projectId, pageId } = toRefs(props.options);
    const {
      fetchEnablePlugins,
      installPlugin,
      updatePluginConfig,
      uninstallPlugin,
      fetchProjectInfoById,
    } = useActions(store, [
      'fetchEnablePlugins',
      'installPlugin',
      'updatePluginConfig',
      'uninstallPlugin',
      'fetchProjectInfoById',
    ]);
    return {
      installedPlugins: computed(() => store.state.plugin.enabledPlugins),
      projectInfo: computed(() => store.state.project.projectInfo),
      updating: computed(() => {
        const { installPlugin, updatePluginConfig, uninstallPlugin } = store.state.loading;
        return installPlugin || updatePluginConfig || uninstallPlugin;
      }),
      projectId,
      pageId,
      fetchEnablePlugins,
      installPlugin,
      updatePluginConfig,
      uninstallPlugin,
      fetchProjectInfoById,
    };
  },
  data() {
    return {
      visible: true,
      loading: false,
      selectPluginValue: {},
      selectPlugin: null,
      drawerVisible: false,
      pluginList: [],
      groups: [],
      uicorePlugin: new UICoreXiaoyaoPlugin({ parent: this }),
    };
  },
  computed: {
    contextOptions() {
      return {
        requiredByDefault: true,
        immutable: false,
        setter: this.$set,
        components: [],
      };
    },
    // 插件列表排序
    sortedPluginList() {
      const installedIds = this.installedPlugins.map(item => item.pluginId);
      return [...this.pluginList].filter(item => item.detail).sort((a, b) => {
        const aIndex = installedIds.findIndex(id => id === a.pluginId);
        const bIndex = installedIds.findIndex(id => id === b.pluginId);
        return aIndex > bIndex ? -1 : 1;
      });
    },
  },
  async created() {
    try {
      this.loading = true;
      await this.loadGroupList();
      const [pluginList] = await Promise.all([
        pluginLoader.loadPlugins(toHubListWithoutRegistryPrefix(this.groups)),
        this.fetchEnablePlugins({
          projectId: this.projectId,
          pageId: this.pageId,
        }),
      ]);
      this.pluginList = pluginList;
    } catch (err) {
      console.error(err);
      message.error('获取插件失败');
    } finally {
      this.loading = false;
    }
  },
  methods: {
    handleClose() {
      this.$emit('message', {
        success: false,
        message: 'cancel',
      });
      this.visible = false;
    },
    showDrawer(plugin) {
      this.selectPlugin = plugin;
      const pluginData = this.isInstall(plugin.pluginId);
      let pluginValue = {};
      if (pluginData) {
        pluginValue = JSON.parse(pluginData.pluginConfig);
      }
      this.selectPluginValue = Object.assign(
        getDefaultValue(plugin.detail.propsSchema),
        pluginValue,
      );
      this.drawerVisible = true;
    },
    onClose() {
      this.drawerVisible = false;
    },
    isInstall(pluginId) {
      return this.installedPlugins.find(item => item.pluginId === pluginId);
    },
    async loadGroupList() {
      try {
        if (!this.projectInfo.id) {
          await this.fetchProjectInfoById({
            projectId: this.projectId,
          });
        }
        const groups = this.projectInfo.componentHubGroupsList;
        this.groups = groups.split(',');
      } catch (error) {
        message.error(`获取组列表失败，${error}`);
      }
    },
    handleUnInstall(pluginId) {
      const plugin = this.installedPlugins.find(item => item.pluginId === pluginId);
      const { pageId } = plugin;
      try {
        this.uninstallPlugin({
          projectId: this.projectId,
          pageId,
          pluginId,
        });
        this.fetchEnablePlugins({
          projectId: this.projectId,
          pageId: this.pageId,
        });
        this.visible = false;
        message.success('禁用插件成功');
        this.$emit('message', {
          success: true,
        });
      } catch (err) {
        console.error(err);
        message.error('禁用插件失败', err.message);
      }
    },
    async onSave(type) {
      const pageId = type === 'global' ? GLOBAL_PAGE_ID : this.pageId;
      try {
        if (!this.isInstall(this.selectPlugin.pluginId)) {
          await this.installPlugin({
            projectId: this.projectId,
            pageId,
            pluginId: this.selectPlugin.pluginId,
            pluginConfig: this.selectPluginValue,
          });
        } else {
          await this.updatePluginConfig({
            projectId: this.projectId,
            pageId,
            pluginId: this.selectPlugin.pluginId,
            pluginConfig: this.selectPluginValue,
          });
        }
        message.success('保存插件配置成功');
        this.fetchEnablePlugins({
          projectId: this.projectId,
          pageId: this.pageId,
        });
        this.onClose();
        this.$emit('message', {
          success: true,
        });
      } catch (err) {
        console.error(err);
        message.error('保存插件配置失败', err.message);
      }
    },
    getPluginTags(plugin) {
      const tags = [];
      const installed = this.installedPlugins.find(installedPlugin => installedPlugin.pluginId === plugin.pluginId);
      if (installed) {
        const { pageId } = installed;
        if (pageId === GLOBAL_PAGE_ID) {
          tags.push({
            color: 'blue',
            text: '全局启用',
            description: '这个插件是全应用启用的',
          });
        } else {
          tags.push({
            color: 'green',
            text: '页面启用',
            description: '这个插件正在运行中',
          });
        }
        if (installed.enabledRemotely === false) {
          tags.push({
            color: 'red',
            text: '本地启动',
            description: '这个插件是本地启用的，关闭debug模式后需要再次启用',
          });
        }
      }
      if (plugin.isHMR) {
        tags.push({
          color: 'red',
          text: '本地调试',
          description: '这个插件使用的是本地HMR开发的插件',
        });
      }
      return tags;
    },
    contactAuthor(publisher) {
      window.open(`wxwork://message.com/?username=${publisher}`, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.pluginList {
  ::v-deep .ant-modal {
    padding: 0;
    .wrapper {
      position: relative;
      overflow: hidden;
      min-height: 433px;
    }
    .container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 12px;
      grid-column-gap: 12px;
      padding: 24px;

      .pluginItem {
        border-radius: 4px;
        border-color: #d0d7de;
        border-style: dotted;
        border-width: 1px;
        padding: 24px 24px 18px 24px;
        &.installed {
          border-style: solid;
        }

        .pluginTitle {
          display: flex;
          justify-content: space-between;
          .pluginTitleWrap {
            display: flex;
            align-items: center;
          }
          .pluginTitleText {
            font-size: 16px;
            font-weight: 600;
            max-width: 200px;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 8px;
            color: #24292f;
          }
          .pluginTitleIconWrap {
            height: 32px;
            width: 32px;
            border-radius: 16px;
            box-shadow: 0 3px 6px rgba(140,149,159,0.15);
            overflow: hidden;
            .pluginTitleIcon {
              width: 100%;
              height: 100%;
            }
          }

          .pluginAuthor{
            padding: 0;
            margin: 0;
            color: rgb(87, 96, 106);
            font-size: 12px;
            height: 18px;
            &:hover {
              border-bottom: 1px solid rgb(87, 96, 106, 0.8);
            }
          }

          .ant-tag {
            margin-right: 4px
          }
        }

        .pluginBody {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .pluginDescription {
            overflow: hidden;
            font-size: 12px;
            height: 72px;
            box-sizing: border-box;
            margin-top: 4px;
            margin-bottom: 8px;
            color: #57606a;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }

          .pluginBodyBtns {
            display: flex;
            justify-content: space-between;
            .ant-btn-link {
              padding: 0;
              color: #5c5c5c;
            }
            .disabled {
              color: rgba(#5c5c5c, 0.6)
            }
          }
        }
      }
    }
    .disabled {
      cursor: not-allowed;
    }
    .emptyList {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .ant-modal-body {
      padding: 0px;
      // max-height: 481px;
      max-height: calc(100vh - 120px);
      overflow: auto;
    }
  }
}
</style>
