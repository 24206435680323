/**
 * 运行时内置路由
 */
import ModuleEntry from '../pages/modules/index';
import workflowRoute from '../pages/modules/workflow/route';
import permissionRoutes from '../pages/modules/permission/route';
import ssoRoute from '../pages/modules/sso/route';
import flow from '../pages/flow/route/runtime';

const BUILT_IN_ROUTE_PREFIX = '/wuji';

const route = {
  path: BUILT_IN_ROUTE_PREFIX,
  component: ModuleEntry,
  children: [
    workflowRoute,
    ...permissionRoutes,
    ssoRoute,
    ...flow,
  ],
};

export default {
  BUILT_IN_ROUTE_PREFIX,
  route,
};
