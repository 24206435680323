import { ref, computed, onBeforeMount } from '@vue/composition-api';
import { useStore } from '@store/index';
export const useRuntimePagelet = ({ projectId, pageId }) => {
    const store = useStore();
    const page = ref(null);
    const pageList = ref(null);
    const env = computed(() => store.state.app.env);
    const branch = computed(() => store.state.app.branch);
    const initPage = async () => {
        try {
            const res = await store.dispatch('runtime/getPageDetail', { projectId, pageId, env: env.value, branch: branch.value });
            if (res)
                page.value = res;
        }
        catch (error) { }
    };
    const initPageList = async () => {
        try {
            pageList.value = await store.dispatch('runtime/getBasicPageList', { projectId, env: env.value, branch: branch.value });
        }
        catch (error) { }
    };
    onBeforeMount(() => {
        initPage();
        initPageList();
    });
    return {
        page,
        pageList,
    };
};
export const useRuntimePage = (projectId) => {
    const store = useStore();
    const env = computed(() => store.state.app.env);
    const branch = computed(() => store.state.app.branch);
    const getPageList = async () => await store.dispatch('runtime/getBasicPageList', { projectId, env: env.value, branch: branch.value }).catch(() => []);
    return {
        getPageList,
    };
};
