// 需要在xy的执行最初直出启动组件的下载 防止占用xy本身js的网速
import { scriptsConfig } from '@utils/scriptsConfig';
import { reactScripts } from '@utils/preload/getReactScripts';

const preLoad = () => {
  if (!window || !document || !document.body) return;
  //  无界下不做预加载 不然会重复请求
  if (window.$wujie) return;
  const supportPreload = typeof window?.preloadComp === 'function';
  if (!supportPreload) return;
  const hasCompCache = window?.xy_runtime_page_config_cache && window?.xy_runtime_component_hub;
  const hasPluginCache = window?.xy_runtime_page_config_cache;

  let haveReact = false;
  let haveVue3 = false;
  const preLoadKeyMap = {};

  if (hasCompCache) {
    // 会有多个pageId 按说是主页面和头部之类的都可以在首屏用到的
    const keys = Object.values(window.xy_runtime_page_config_cache || {})
      .map(i => (i?.pageData?.compPreLoad?.keys || []))
      .filter(Boolean)
      .flat();
    if (keys.length > 0) {
      const comps = window.xy_runtime_component_hub?.data.map(i => i.components).flat()
        .filter(i => i?.info?.src);
      const compsLut = comps.reduce((obj, i) => {
        // eslint-disable-next-line no-param-reassign
        obj[i.id] = i;
        return obj;
      }, {});
      const addDep = (id) => {
        if (preLoadKeyMap[id]) return;
        const comp = compsLut[id];
        if (comp && comp.info) {
          if (comp.info.framework === 'react') {
            haveReact = true;
          }
          if (comp.info.framework === 'vue3') {
            haveVue3 = true;
          }
          if (comp.info.src) {
            preLoadKeyMap[id] = comp.info.src;
          }
          let dep = comp.info.dependencies;
          if (dep) {
            dep = JSON.parse(dep);
            dep.forEach(addDep);
          }
        }
      };
      keys.forEach((i) => {
        addDep(i);
      });
    }
  }


  hasPluginCache && Object.values(window.xy_runtime_page_plugins_cache).forEach((i) => {
    i.forEach((plugin) => {
      if (plugin.pageId === '*') {
        // 全局插件  TODO:当前页面的插件的判断逻辑之后补上
        if (plugin.detail.src) {
          preLoadKeyMap[plugin.id] = {
            src: plugin.detail.src,
            noCacheHeader: true,
          };
        }
      }
    });
  });

  const chunks = Array.isArray(window.xy_async_chunk) && window.xy_async_chunk;
  if (haveReact && chunks) {
    const reactChunks = ['reactadaptor_asyncchunk_noexecute'];
    const reactUrls = chunks.filter(i => reactChunks.some(j => i.includes(j)));
    const scripts = reactScripts.map(k => scriptsConfig[k].js);
    [...reactUrls, ...scripts].forEach((url) => {
      preLoadKeyMap[url] = {
        src: url,
        noCacheHeader: true,
      };
    });
  }
  if (haveVue3 && chunks) {
    const vue3Chunks = ['vuenextadaptor_asyncchunk_noexecute'];
    const vue3Urls = chunks.filter(i => vue3Chunks.some(j => i.includes(j)));
    const scripts = ['vue3'].map(k => scriptsConfig[k].js);
    [...vue3Urls, ...scripts].forEach((url) => {
      preLoadKeyMap[url] = {
        src: url,
        noCacheHeader: true,
      };
    });
  }
  window.preloadComp?.(Object.values(preLoadKeyMap));
};
try {
  preLoad();
} catch (e) {
  if (typeof window === 'object') {
    // eslint-disable-next-line no-underscore-dangle
    window.__wuji_preload_error = e;
  }
}
