import modalFactory from '@/utils/modalFactory';
import { defineComponent, ref, computed } from '@vue/composition-api';
import ExpireAt from '@/pages/modules/permission/expireAt';
import ManagePermModal from '@/pages/modules/permission/ManagePermModal';
import { providePermState } from '@/pages/modules/permission/PermState';
import { useRouter } from '@/router/useRouter';
import { permissionApi } from '@/services';
import { keyBy } from 'lodash';
const columns = [
    {
        dataIndex: 'role',
        key: 'role',
        slots: {
            title: 'roleTitle',
        },
        scopedSlots: {
            customRender: 'role',
        },
    },
    {
        title: '过期时间',
        dataIndex: 'expireAt',
        key: 'expireAt',
        scopedSlots: { customRender: 'expireTime' },
    },
    {
        title: '操作',
        scopedSlots: {
            customRender: 'action',
        },
    },
];
const userPermissionModal = defineComponent({
    name: 'userPermissionModal',
    components: {
        ExpireAt,
    },
    setup(_props, { emit }) {
        const showPermModal = ref(false);
        const permModalRole = ref({});
        const { router } = useRouter();
        providePermState();
        const roles = ref([]);
        const userRoles = ref([]);
        const fetchRoles = async () => {
            const { projectId, env } = window.RUNTIME_CONFIGS;
            roles.value = await permissionApi.fetchRoles(projectId, env);
        };
        const fetchUserView = async () => {
            const { projectId, env } = window.RUNTIME_CONFIGS;
            const resp = await permissionApi.fetchUserView(projectId, env);
            userRoles.value = resp.roles;
        };
        const openPermModal = (role) => {
            permModalRole.value = { ...role };
            showPermModal.value = true;
        };
        const jumpToPermissionApply = (role) => {
            emit('message', {});
            router.value.push({
                path: '/403',
                query: {
                    applying: 'true',
                    role,
                },
            });
        };
        const isSystemRole = (role) => {
            if (!role.startsWith('project')) {
                return false;
            }
            const [, roleId] = role.split('.');
            if (roleId && ['admin', 'guest'].includes(roleId)) {
                return true;
            }
        };
        const tableData = computed(() => {
            const rawData = userRoles.value;
            const roleMap = keyBy(roles.value, 'name');
            return rawData.map((role) => ({
                ...role,
                name: roleMap[role.role]?.name || role.role,
                cname: roleMap[role.role]?.cname ?? role.role,
                _id: role._id || role.role,
            }));
        });
        return {
            permModalRole,
            showPermModal,
            openPermModal,
            jumpToPermissionApply,
            isSystemRole,
            roles,
            fetchRoles,
            fetchUserView,
            tableData,
        };
    },
    created() {
        this.fetchRoles();
        this.fetchUserView();
    },
    render() {
        return <a-modal visible={true} title="我的权限" footer={null} onCancel={() => {
                this.$emit('message', {});
            }} width='50vw'>
      <a-table columns={columns} dataSource={this.tableData} pagination={false} rowKey="_id" scopedSlots={{
                role: (role, record) => <div>
              <a href="javascript:;" onClick={() => this.openPermModal(record)}>{record.cname || role} </a>
              {record.userType === 'org' ? <a-tag>继承自组织</a-tag> : null}
            </div>,
                expireTime: (expireAt) => <div>
              {expireAt ? <ExpireAt expireAt={expireAt}></ExpireAt> : '永久'}
            </div>,
                action: (_ref, record) => <div>
              <a-button disabled={this.isSystemRole(record.role)} style='padding-left: 0' type="link" onClick={() => {
                        this.jumpToPermissionApply(record.role);
                    }}>申请续期</a-button>
            </div>,
            }}>
        <div slot='roleTitle'>
          <span>角色 <a-popover>
            <div slot='content'>
              点击角色名称查看详细权限
            </div>
            <a-icon type="question-circle"/>
            </a-popover>
          </span>
        </div>
      </a-table>
      <ManagePermModal showModal={this.showPermModal} roleOrPerm={this.permModalRole} onHide={() => {
                this.showPermModal = false;
            }}></ManagePermModal>
    </a-modal>;
    },
});
export default modalFactory(userPermissionModal);
