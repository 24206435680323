import { getDocLink } from '@utils/links';

/* eslint-disable */
__webpack_public_path__ = `${window.GLOBAL_INFO.staticFileCdn || window.GLOBAL_INFO.pathPrefix}/xystatic/`;

window.MagicalWorker = class MagicalWorker extends Worker {
  constructor(url, ...args) {
    if (/^https?:/.test(url)) {
      url = URL.createObjectURL(new Blob(["importScripts(" + JSON.stringify(url) + ")"]));
    }
    super(url, ...args);
  }
};

// micro-app 内嵌场景
if (window.__MICRO_APP_ENVIRONMENT__) {
  const { globalAPI } = window.microApp.getData();
  const {hasBeaconSpace} = window.GLOBAL_INFO;

  const guides = [
    {
      id: "component-hub",
      // 引导图表，可以使用基座提供的图标或者 CDN URL
      icon: "server",
      // tooltip 内容
      tooltipContent: "组件管理",
      // 点击事件回调
    },
    {
      id: "layout",
      // 引导图表，可以使用基座提供的图标或者 CDN URL
      icon: "layers-line",
      // tooltip 内容
      tooltipContent: "模板管理",
      // 点击事件回调
    },
    {
      id: "global",
      // 引导图表，可以使用基座提供的图标或者 CDN URL
      icon: "usergroup",
      // tooltip 内容
      tooltipContent: "公共账号",
      // 点击事件回调
    },
    {
      id: "group",
      // 引导图表，可以使用基座提供的图标或者 CDN URL
      icon: "app2-line",
      // tooltip 内容
      tooltipContent: "应用组",
    },
    {
      id: "shop",
      // 引导图表，可以使用基座提供的图标或者 CDN URL
      icon: "company",
      // tooltip 内容
      tooltipContent: "组件商店",
    },
    {
      id: 'authority',
      icon: 'authority-filled',
      tooltipContent: '权限中心'
    }
  ];

  const { HOME } = getDocLink();

  if (HOME) {
    guides.push({
      id: "docs",
      // 引导图表，可以使用基座提供的图标或者 CDN URL
      icon: "doubt-filled",
      // tooltip 内容
      tooltipContent: "使用文档",
      // 点击事件回调
      onClick: () => window.open(HOME, "_blank"),
    })
  }

  globalAPI.navigation.setTopNavigation({
    enableSearch: false,
    enablePrimaryNavs: true,
    primaryNavs: [
      {
        id: "wuji-srouce",
        activeRule: /wuji-source\//,
        // 导航名称
        title: "数据配置",
        // 点击事件回调
        onClick: () => {
          globalAPI.router.subAppReplace("wuji-source", { path: "/p/" });
        },
      },
      {
        id: "wuji",
        activeRule: /\/wuji\/xy/,
        // 导航名称
        title: "应用管理",
        // 点击事件回调
        onClick: () => {},
      },
    ],
    guides,
  });
  if (!hasBeaconSpace) {
    globalAPI.navigation.disableSpaceSwitch();
  }
}
