<template>
  <span
    v-if="icon"
    class="icon"
  >
    <img
      v-if="/^https*:\/\//.test(icon)"
      :src="icon"
      class="icon-img"
    >
    <a-icon
      v-else
      :type="icon"
    />
  </span>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  height: 14px;
  max-width: 20px;
  max-height: 12px;
  margin-right: 4px;
  .icon-img {
    max-width: 20px;
  }
}
</style>
