import wujiFetch from '@utils/wujiFetch';
import { COMPONENT_HUB_API_PATH } from '@config/constant';
import { fetchComponentHubLibraryInfo, getComponentHubStoreKey } from '@utils/componentHub';
import { PROFILE_API_PREFIX } from '@pages/component-management/constant';
import { getGroupId } from '@pages/component-management/utils';

/**
 * 这里使用的信息仅供组件商店使用，
 * 加载组件不应该使用这里的信息，直接调用compLoaders中的方法即可
 */

export default {
  state: {
    componentHub: {}, // 组件商店接口返回的信息
    library: {},
  },
  actions: {
    async getComponents({ state, commit }, { registry, group }) {
      const key = getComponentHubStoreKey(registry, group);
      const cachedComponents = state.componentHub[key];
      if (cachedComponents) {
        return cachedComponents;
      }
      const hubListQuery = `${registry}@${group === 'default' || !group ? '' : group}`;
      const [res] = await wujiFetch(`${COMPONENT_HUB_API_PATH}?hubList=${hubListQuery}`);
      commit('setComponentHub', { key, components: res.components });
    },

    async getComponentGroup() {
      const groupsList = await wujiFetch(`${PROFILE_API_PREFIX}/groups`);
      // 默认商店是一定会加载的，不需要这里配置
      // 然后再清理掉前缀
      const data = [...groupsList.local || [], ...groupsList.public || []].filter(group => group.name !== '默认商店').map(group => ({
        ...group,
        id: getGroupId(group.id),
      }));
      return data;
    },

    async getLibrary({ commit }, { registry, group }) {
      const library = await fetchComponentHubLibraryInfo(registry, group);
      commit('setLibrary', { registry, group, library });
      return library;
    },
  },
  mutations: {
    setComponentHub(state, { key, components }) {
      state.componentHub[key] = components;
    },
    setLibrary(state, { registry, group, library }) {
      const key = getComponentHubStoreKey(registry, group);
      state.library[key] = library;
    },
  },
};
