import { memoize } from 'lodash';
import { parse } from 'querystring';
import { BASE_API_PATH } from '@/config/constant';
import wujiFetch from '@utils/wujiFetch';
import { hasDeptInfoService } from '@utils/userInfo';

const cacheFetch = memoize((url) => {
  setTimeout(() => {
    cacheFetch.cache.delete(url);
  }, 10000);

  const resp = wujiFetch(url);
  return resp;
});

/**
 * 获取组织节点信息
 * @param {string} id - 可以是 id1,id2 或者 id1;id2
 */
const fetchDeptInfo = async (idStr) => {
  if (!hasDeptInfoService()) throw new Error('这个部署版不支持获取部门信息');
  const query = parse(window.location.search.slice(1));
  const search = new URLSearchParams({
    id: idStr,
    projectid: window.w?.projectId || query.projectid || query.projectId,
  });
  const url = `${BASE_API_PATH}/dept-rtx/dept-info?${search}`;
  const resp = await cacheFetch(url);
  return resp;
};

export default {
  fetchDeptInfo,
};
