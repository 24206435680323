<template>
  <div
    v-if="list.length && mode === 'runtime'"
    id="xy-client-hmr-page-list"
  >
    <div class="debug-tips">
      当前为debug模式
    </div>
    <a-select
      v-model="id"
      :allow-clear="true"
      class="page-selection"
      placeholder="请选择"
    >
      <a-select-option
        v-for="page in list"
        :key="page.sourceCodeConfig.id"
        :value="page.sourceCodeConfig.id"
        @dropdownVisibleChange="$event && loadList()"
      >
        {{ page.name || page.sourceCodeConfig.id }}
        <a-tooltip
          v-if="!page.path"
          placement="rightTop"
        >
          <template slot="title">
            <span>路径未定义，无法进行预览</span>
          </template>
          <a-icon type="warning" />
        </a-tooltip>
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import pageLoader from '@loaders/page/loader';
import { getRouterPublicParams } from '@utils/routerPublicParams';

export default {
  props: {
    mode: {
      type: String,
      default: 'editor', // 'editor'(编辑时) | 'runtime'(运行时)
    },
    projectId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      list: [],
      id: undefined,
    };
  },
  async created() {
    await this.loadList();

    this.$watch(() => this.id, () => {
      if (this.id === undefined) return;
      this.onRoute(this.id);
    });

    this.$watch(() => this.$route, () => {
      const { path } = this.$route;
      let exist = false;
      this.list.forEach((item) => {
        if (item.path === path) {
          this.id = item.sourceCodeConfig.id;
          exist = true;
        }
      });

      if (!exist) {
        this.id = undefined;
      }
    }, { immediate: true });

    this.$watch(() => this.projectId, async () => {
      this.loadList();
    });
  },
  methods: {
    async loadList() {
      if (!this.projectId) return;

      try {
        this.list = await pageLoader.loadDebugList(this.projectId);
      } catch (err) {
        message.error('debug模式拉取页面列表失败，请确认是否开启本地开发服务');
      }
    },
    onRoute(id) {
      const pageInfo = this.list.find(page => page.sourceCodeConfig.id === id);
      if (pageInfo && pageInfo.path) {
        this.$router.push({
          path: pageInfo.path,
          query: {
            ...this.$route.query,
            ...getRouterPublicParams(this.$route.query),
          },
        });
      } else {
        message.info('该页面未配置路径，无法跳转');
      }
    },
  },
};
</script>

<style lang="scss">
#xy-client-hmr-page-list{
  .page-selection {
    width: 100px;
  }
  .debug-tips {
    margin-right: 5px;
    margin-left: 10px;
    display: inline;
    font-size: 14px;
    color: var(--wuji-primary-color);
  }
}
</style>
