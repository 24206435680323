<template>
  <div class="wrapper">
    <w-sys-spin
      v-if="loading"
      class="center"
    />
    <w-sys-page
      v-else-if="pageConf"
      :config="pageConf"
      :project-config="projectConfig"
    />
    <div v-else>
      invalid pageId
    </div>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import pageLoader from '@loaders/page/loader';
import { BASE_API_PATH } from '@config/constant';
import wujiFetch from '@utils/wujiFetch';

export default {
  props: {
    projectId: {
      type: String,
      default: '',
    },
    pageId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pageConf: null,
      projectConfig: null,
      loading: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      const [pageConf, projectConfig] = await Promise.all([
        this.getPageDetail(this.projectId, this.pageId),
        this.getProjectConfig(this.projectId),
      ]);
      this.pageConf = pageConf;
      this.projectConfig = projectConfig;
      this.loading = false;
    },
    async getPageDetail(projectId, pageId) {
      try {
        return await pageLoader.getPageDetail({ projectId, pageId });
      } catch (e) {
        message.error(`拉取页面数据失败：${e.message}`);
      }
    },
    async getProjectConfig(projectId) {
      try {
        return await wujiFetch(`${BASE_API_PATH}/xy/basic/project/${projectId}`);
      } catch (e) {
        message.error(`拉取应用配置失败：${e.message}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  height: 100%;

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
</style>
