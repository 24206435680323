import wujiFetch from '@utils/wujiFetch';
import { BASE_XY_API_PATH } from '@config/constant';
const GROUP_API = `${BASE_XY_API_PATH}/group`;
export default {
    state: {
        authList: [],
    },
    actions: {
        async fetchGroupAuth({}, { groupId }) {
            const result = await wujiFetch(`${GROUP_API}/${groupId}/auth`, {});
            return result;
        },
        async createGroupAuth({}, { groupId, data }) {
            const result = await wujiFetch(`${GROUP_API}/${groupId}/auth`, {
                method: 'POST',
                body: JSON.stringify(data),
            });
            return result;
        },
        async updateGroupAuth({}, { groupId, data, authId }) {
            const result = await wujiFetch(`${GROUP_API}/${groupId}/auth/${authId}`, {
                method: 'PATCH',
                body: JSON.stringify(data),
            });
            return result;
        },
        async deleteGroupAuth({}, { groupId, authId }) {
            const result = await wujiFetch(`${GROUP_API}/${groupId}/auth/${authId}`, {
                method: 'DELETE',
            });
            return result;
        },
    },
    mutations: {
        updateGroupAuth(state, payload) {
            Object.assign(state, payload);
        },
    },
};
