import { load, dump } from 'js-yaml';
export function jsonToYaml(json) {
    if (!json) {
        return '';
    }
    const yamlData = dump(json, {
        sortKeys: true,
    });
    return yamlData;
}
export function yamlToJson(yaml) {
    const data = load(yaml, {
        json: true,
    });
    return data;
}
