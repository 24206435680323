export const TAG_NAME = 'pageletComponent';
export const RENDERER_TAG_NAME = `${TAG_NAME}_cRenderer`;
export const isPageletComponent = (el) => {
    const tag = el.getAttribute('data-tag');
    if (tag && tag === TAG_NAME) {
        return true;
    }
    return false;
};
export const isPageletComponentRenderer = (el) => {
    const tag = el.getAttribute('data-tag');
    if (tag && tag === RENDERER_TAG_NAME) {
        return true;
    }
    return false;
};
export const SEPARATOR = '___';
export const getPropId = (field) => [field.id, field.tag, field.from].join(SEPARATOR);
export const getBindingTitle = (field) => `${field.id}(${field.from})`;
