import { cgiService, getCollection, collectionService } from '@tencent/wuji-source';
export const source = {
    use: cgiService.registerMiddleware.bind(cgiService),
    getCollection,
    collectionService,
};
export function initGlobalContext(router) {
    const ctx = {
        router,
        source,
    };
    // @ts-ignore
    if (!window.globalContext) {
        Object.defineProperty(window, 'globalContext', {
            get() {
                return ctx;
            },
            enumerable: true,
            configurable: false,
        });
    }
    const event = new Event('wuji:beforeReady', { bubbles: false, cancelable: false });
    document.dispatchEvent(event);
}
