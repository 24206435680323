import { message } from 'ant-design-vue';
import { isPlainObject } from 'lodash';
// 重写 ant-design-vue 的 message 部分方法
export function overwriteAntdMessage() {
    ['success', 'error', 'info', 'warning', 'warn'].forEach((method) => {
        const originMessageMethod = message[method];
        message[method] = async (...rest) => {
            const [content, second, third] = rest;
            const key = `${method}:$${content}`;
            let afterClose;
            if (isPlainObject(content)) {
                // 当参数为 Config 时, 直接调用
                if (content.key === undefined)
                    content.key = key;
                afterClose = await originMessageMethod(content);
            }
            else if (typeof second === 'function' || typeof third === 'function') {
                // 当定义了 onClose 回调函数时, 不使用 key 去重提示
                afterClose = await originMessageMethod(...rest);
            }
            else {
                let duration = 3;
                if (typeof second === 'number')
                    duration = second;
                afterClose = await originMessageMethod({ content, key, duration });
            }
            return afterClose;
        };
    });
    window.$message = message;
}
