import XyPageletContainer from './xy-pagelet-container';
import xyPageletSchema from './xy-pagelet-schema';
import PageletAttr from './pagelet-attr';
import { EVENTS } from '@composables/usePageletEvent';

export default {
  component: XyPageletContainer,
  componentForDesign: XyPageletContainer,
  category: '系统组件',
  id: 'xy-pagelet-container',
  events: EVENTS,
  slots: [
    { id: 'default', selector: '.xy-pagelet-container' },
  ],
  display: 'block',
  visible: false,
  name: '页面片',
  readme: '',
  propPanel: {
    PageletAttr: {
      component: PageletAttr,
      hideLabel: true,
    },
  },
  propsSchema: xyPageletSchema,
};

