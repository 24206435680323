import { get, isEmpty } from 'lodash';
import { defineComponent, ref, watch } from '@vue/composition-api';
// @ts-ignore
import infiniteScroll from 'vue-infinite-scroll';
import PermissionCheckbox from './PermissionCheckbox';
import { usePermission } from './utils';
import { injectPermState } from './PermState';
export default defineComponent({
    directives: { infiniteScroll },
    name: 'PageMenuPerm',
    model: {
        prop: 'rolePerms',
        event: 'change',
    },
    emits: ['checkChange'],
    props: {
        projectId: {
            type: String,
            default: '',
            required: true,
        },
        env: {
            type: String,
            default: 'dev',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        role: {
            type: String,
            default: '',
            required: true,
        },
        rolePerms: {
            type: Array,
            required: true,
        },
        pageList: {
            type: Array,
            required: true,
        },
        pagePermList: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const currentPageId = ref('');
        const builtinPermList = ref([]);
        const customPermList = ref([]);
        const { check, checkProject } = usePermission(props.projectId);
        const checkAllLoading = ref(false);
        const permState = injectPermState();
        const vSize = 15;
        const vLoadedAll = ref(false);
        const vPageList = ref(props.pageList.slice(0, vSize));
        const isCustomAndRelatedPagePerm = (name, pageId) => {
            const perm = props.pagePermList.find(x => x.name === name);
            if (!get(perm, '_id'))
                return false;
            return perm?.relatedPages
                ? perm.relatedPages.split(',').includes(pageId)
                : true;
        };
        const pageChange = (pageId) => {
            currentPageId.value = pageId;
            customPermList.value = props.pagePermList.filter(x => isCustomAndRelatedPagePerm(x.name, currentPageId.value));
        };
        const defaultPage = () => {
            const pageId = props.pageList?.[0]?.pageId;
            pageChange(pageId || '');
        };
        const initVList = () => {
            vPageList.value = props.pageList.slice(0, vSize);
            if (vPageList.value.length >= props.pageList.length)
                vLoadedAll.value = true;
            else
                vLoadedAll.value = false;
        };
        const init = async () => {
            builtinPermList.value = props.pagePermList.filter(x => !get(x, '_id'));
            defaultPage();
        };
        watch(() => props.pageList, () => {
            defaultPage();
            initVList();
        }, {
            immediate: true,
            deep: true,
        });
        watch(() => props.rolePerms, () => {
            checkAllLoading.value = false;
        }, {
            immediate: true,
            deep: true,
        });
        watch(() => props.pagePermList, () => {
            init();
        }, {
            immediate: true,
            deep: true,
        });
        return {
            checkAllLoading,
            init,
            builtinPermList,
            customPermList,
            currentPageId,
            pageChange,
            check,
            checkProject,
            permState,
            vPageList,
            vSize,
            vLoadedAll,
        };
    },
    created() {
        this.init();
    },
    render() {
        const renderCheckAll = () => {
            if (this.readonly)
                return null;
            const isBuiltinAllCheck = this.builtinPermList.every(x => this.check(this.rolePerms, this.role, x.name, this.currentPageId) || this.checkProject(this.rolePerms, this.role, x.name));
            const isCustomAllCheck = this.customPermList.every(x => this.check(this.rolePerms, this.role, x.name, this.currentPageId));
            const allCheck = isBuiltinAllCheck && isCustomAllCheck;
            return <a-checkbox checked={allCheck} disabled={this.checkAllLoading} onChange={() => {
                    if (!this.permState.panguMappingEnabled.value)
                        this.checkAllLoading = true;
                    const rolePerms = [...this.rolePerms].filter(x => x.pageId !== this.currentPageId);
                    const perms = [...this.builtinPermList, ...this.customPermList];
                    let data = [];
                    let type = 'unchecked';
                    if (allCheck) {
                        data = perms.map(x => ({ permission: x.name, pageId: this.currentPageId }));
                    }
                    else {
                        type = 'checked';
                        perms.forEach((x) => {
                            const isCheck = this.check(this.rolePerms, this.role, x.name, this.currentPageId);
                            const isProjectChecked = this.checkProject(this.rolePerms, this.role, x.name);
                            if (isCheck || isProjectChecked)
                                return;
                            data.push({ permission: x.name, pageId: this.currentPageId });
                        });
                        rolePerms.push(...this.builtinPermList.map(x => ({ permission: x.name, pageId: this.currentPageId })));
                        rolePerms.push(...this.customPermList.map(x => ({ permission: x.name, pageId: this.currentPageId })));
                    }
                    if (!data.length) {
                        this.checkAllLoading = false;
                        return;
                    }
                    ;
                    if (this.permState.panguMappingEnabled.value)
                        this.$emit('change', rolePerms);
                    else
                        this.$emit('checkChange', { type, data });
                }}>全部</a-checkbox>;
        };
        const renderCheck = (perm, pageId) => <PermissionCheckbox projectId={this.projectId} env={this.env} role={this.role} rolePerms={this.rolePerms} permission={perm.name} label={perm.cname} pageId={pageId} readonly={this.readonly} onCheckChange={(v) => {
                this.$emit('checkChange', v);
            }} onChange={(v) => {
                this.$emit('change', v);
            }}/>;
        const renderSection = (name, permList) => <section style="margin: 16px 0 0 36px">
      <h4>{name}</h4>
      <div>
        {permList.map(x => renderCheck(x, this.currentPageId))}
      </div>
    </section>;
        if (isEmpty(this.pageList))
            return <div style="transform: translateY(70%)"><a-empty /></div>;
        return <div style="display:grid;grid-template-columns:300px auto;height:100%">
      <div v-infinite-scroll={() => {
                if (this.vLoadedAll)
                    return;
                const len = this.vPageList.length;
                if (len >= this.pageList.length) {
                    this.vLoadedAll = true;
                    return;
                }
                const list = this.pageList.slice(len, len + this.vSize);
                this.vPageList.push(...list);
            }} infinite-scroll-distance={this.vSize} infinite-scroll-disabled={this.vLoadedAll} class="menu" style="overflow:auto;height:400px">
        <a-menu selectedKeys={[this.currentPageId]} onClick={({ key }) => this.pageChange(key)}>
          {this.vPageList.map(p => <a-menu-item key={p.pageId}>
              {p.pageName}
            </a-menu-item>)}
        </a-menu>
      </div>
      <div class="content">
        <div style="display:flex;align-items:baseline">
          <h3 style="margin: 0 12px">
            {this.pageList.find(x => x.pageId === this.currentPageId)?.pageName}
          </h3>
          {renderCheckAll()}
        </div>
        {renderSection('内置页面权限', this.builtinPermList)}
        {this.customPermList.length ? renderSection('自定义页面权限', this.customPermList) : null}
      </div>
    </div>;
    },
});
