<template>
  <div
    class="globalMask"
    @click.self="cancel"
  >
    <div class="box1">
      <div
        class="cancelBtn"
        @click="cancel"
      >
        ×
      </div>

      <a-spin :spinning="loading">
        <iframe
          :src="iframePath"
          class="switchIframe"
          border="0"
          @load="loading = false"
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchAccountMask',
  data() {
    return {
      iframePath: `/sso/choose_port?via=${document.domain}`,
      loading: true,
    };
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    window.__xy_sso_switch_account_callback = this.handleSSOCallback;
  },
  beforeDestroy() {
    // eslint-disable-next-line no-underscore-dangle
    if (window.__xy_sso_switch_account_callback === this.handleSSOCallback) {
      // eslint-disable-next-line no-underscore-dangle
      window.__xy_sso_switch_account_callback = null;
    }
  },
  methods: {
    cancel() {
      this.$emit('response', false);
    },
    handleSSOCallback() {
      this.loading = true;
      this.$emit('response', true);
    },
  },
};
</script>

<style lang="scss">
.box1 {
  background-color: #fff;
  padding: 20px 10px;
  box-shadow: 0 2px 8px #0002, 0 8px 32px #0006;
  border-radius: 16px;
  position: relative;
}

$cancelBtnSize: 32px;

.cancelBtn {
  position: absolute;
  right: (-$cancelBtnSize / 2);
  top: (-$cancelBtnSize / 2);
  cursor: pointer;
  width: $cancelBtnSize;
  height: $cancelBtnSize;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #eee;
  border-radius: 100%;
  font-size: 16px;
  line-height: 1;

  &:hover {
    box-shadow: 0 0 1px 2px;
  }
}

.switchIframe {
  width: 300px;
  height: 318px;
  border: 0;
}

.globalMask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: #0003;
  overflow: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
