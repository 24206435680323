import querystring from 'querystring';
import { getBeaconGlobalApi } from '@/utils/userInfo';
import { watch } from '@vue/composition-api';
import { BASE_PATH } from '@/config/constant';


const { GLOBAL_INFO } = window;
const APP_KEY = 'wuji';
// eslint-disable-next-line no-underscore-dangle
export const isInBeacon = () => GLOBAL_INFO.beaconDomain || window.__MICRO_APP_ENVIRONMENT__;

export const isInBeaconAndHasSpace = () => isInBeacon() && GLOBAL_INFO.hasBeaconSpace;

// 工具侧能脱离灯塔基座的页面列表
export const beaconWhitePageList = [
  '/project/editor/component',
  '/project/pageconfig',
];

export const getBeaconBasePath = () => {
  if (GLOBAL_INFO.beaconDomain) {
    return `//${GLOBAL_INFO.beaconDomain}/wuji`;
  }
  return '';
};

export const isInWhiteList = path => beaconWhitePageList.some(whitePath => path && path.indexOf(whitePath) !== -1);

export const getAbsolutePath = (path, isWujiApi = false) => {
  if (isInWhiteList(path) || isWujiApi) {
    // 白名单允许
    if (GLOBAL_INFO.host) {
      return `https://${GLOBAL_INFO.host}${path}`;
    }
    return path;
  }
  const beaconPath = getBeaconBasePath();
  return `${beaconPath}${path}`;
};

export const forceToPageInBeacon = (to = {}, from = {}) => {
  if (!to.path) {
    return false;
  }
  // 白名单内跳进，才需要打开
  // 即，本来不在灯塔基座里面的，用户点击跳转到基座的页面
  if (!isInWhiteList(to.path) && isInWhiteList(from.path)) {
    const beaconPath = getBeaconBasePath();
    if (beaconPath) {
      const qs = querystring.stringify(to.query);
      const url = `${beaconPath}${BASE_PATH.replace(/\/$/, '')}${to.path}?${qs}`;
      window.location.href = url;
      return true;
    }
  }

  return false;
};

export const getSpaceIdFromQuery = async () => {
  const { spaceId } = querystring.parse(location.search.substr(1));
  if (typeof spaceId === 'string') {
    return spaceId;
  }
  return spaceId?.[0];
};

export const getSpaceIdFromRecord = async () => {
  const globalAPI = getBeaconGlobalApi();
  try {
    const record = await globalAPI.user.fetchSubAppUrlByUserRecent(APP_KEY);
    const { recentOrgs, path } = record;
    // recentOrgs 里面有 空间ID 的信息
    if (recentOrgs && typeof recentOrgs.find === 'function') {
      const spaceIdTarget = recentOrgs.find(item => item.level === 'biz');
      if (spaceIdTarget?.id) {
        return spaceIdTarget?.id;
      }
    }
    // path 路径里面可能有 空间ID 的信息
    if (typeof path === 'string' && path.indexOf('?') > 0) {
      const queryFromPath = querystring.parse(path.substr(path.indexOf('?') + 1));
      if (queryFromPath.spaceId) {
        return queryFromPath.spaceId;
      }
    }
    return null;
  } catch (e) {
    console.error('获取灯塔最近访问记录失败', e);
    return null;
  }
};

// 灯塔空间上报
export const reportSpaceIdIfInBeacon = async () => {
  if (isInBeaconAndHasSpace()) {
    const globalAPI = getBeaconGlobalApi();
    const spaceId = await getSpaceIdFromQuery();
    if (spaceId) {
      globalAPI.navigation.updateUserCurrentOrgs({
        id: spaceId,
        level: 'biz',
      });
    }
  }
};

// 灯塔 空间ID 切换的处理
export const watchSpaceIdInBeacon = (vueRoot, handler) => {
  if (isInBeaconAndHasSpace()) {
    if (typeof handler === 'function') {
      watch(() => vueRoot.$route?.query?.spaceId, handler);
    }
  }
};

// 灯塔的权限空间地址
export const getBeaconAuthorityUrl = async () => {
  const spaceId = await getSpaceIdFromQuery();
  return `/dataauth/authority/${spaceId}/authRoleGroup/wuji`;
};
