import Vue from 'vue';
import { defineComponent, inject } from '@vue/composition-api';
import { usePageletEvent, EVENTS } from '@composables/usePageletEvent';
import { getAvailableComponent } from '@/utils/comps-loader';
import { getDocLink } from '@utils/links';
import { DEFAULT_LESSCODE } from '@components/CodeEditor/utils/codes';
import styles from './index.module.scss';
const XyFooterContainer = defineComponent({
    name: 'XyFooterContainer',
    setup(props, { emit }) {
        usePageletEvent({ emit });
        const w = inject('w', null);
        const getDefaultSlot = () => {
            if (w?.mode === 'runtime')
                return null;
            return (<div class={styles.placeholder}>
        <span>请放置组件</span>
      </div>);
        };
        return {
            getDefaultSlot,
        };
    },
    render() {
        return (<div class={['xy-footer-container', styles.container]}>
        {this.$slots.default || this.getDefaultSlot()}
      </div>);
    },
});
const componentId = 'xy-footer-container';
const linkHref = getDocLink().XY_WUJI_HOME ?? location.origin;
// 默认的页脚布局
const getDefaultFooterLayout = () => [
    {
        type: 'w-paragraph',
        props: {
            align: 'left',
        },
        children: [
            {
                type: getAvailableComponent('public', 'w-readonly'),
                style: {
                    color: '#999',
                    wordWrap: 'break-word',
                    flexGrow: '1',
                    textAlign: 'center',
                    lineHeight: '32px',
                },
                componentId: 'publicWReadonlyNew2',
                props: {
                    value: '腾讯公司 版权所有 | Copyright © 1998 - {{ new Date().getFullYear() }} Tencent. All Rights Reserved.',
                    align: 'center',
                },
            },
        ],
        componentId: 'wParagraph2',
    },
    {
        type: 'w-paragraph',
        props: {
            align: 'center',
        },
        children: [
            {
                type: getAvailableComponent('public', 'w-readonly'),
                style: {
                    height: '22px',
                    lineHeight: '22px',
                    wordWrap: 'break-word',
                    textAlign: 'center',
                    color: '#999',
                    'word-wrap': 'break-word',
                },
                componentId: 'publicWReadonlyNew3',
                props: {
                    align: 'center',
                    value: 'powered by',
                },
            },
            {
                type: getAvailableComponent('public', 'w-a-link'),
                componentId: 'publicWALink',
                props: {
                    href: linkHref,
                    text: window.GLOBAL_INFO.brandInfo?.xyAdminName || '无极',
                },
                style: {
                    color: '#999',
                },
                class: 'uc-private-style-68d329ef',
                styleSheetName: [
                    'uc-private-style-68d329ef',
                ],
            },
        ],
        componentId: 'wParagraph',
        style: {
            lineHeight: '32px',
        },
    },
];
// 默认的页脚样式
const getDefaultFooterStyle = () => ({
    width: '100%',
    padding: '24px 0px;',
});
export const getDefaultFooterConfig = (id) => ([
    {
        id,
        name: '页脚',
        layout: {
            type: componentId,
            props: {},
            children: getDefaultFooterLayout(),
            style: getDefaultFooterStyle(),
        },
        xyType: 'pagelet',
        lessCode: DEFAULT_LESSCODE,
    },
]);
export default {
    component: XyFooterContainer,
    componentForDesign: XyFooterContainer,
    category: '组合',
    id: componentId,
    slots: [
        { id: 'default', selector: '.xy-footer-container' },
    ],
    events: EVENTS,
    display: 'block',
    visible: false,
    name: '页脚',
    propsSchema: {
        type: 'object',
        fields: [],
    },
    readme: '',
    getActions({ modifyComp }) {
        return [
            {
                title: '预设页脚',
                async execute() {
                    await modifyComp((c) => {
                        Vue.set(c, 'children', getDefaultFooterLayout());
                        Vue.set(c, 'style', getDefaultFooterStyle());
                    });
                },
            },
        ];
    },
};
