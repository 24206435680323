/* eslint-disable no-new-func */
const newFunction = (args, functionBody) => {
    const getFunctionWithTemplate = (body) => new Function(...args, `return (${body})(${args.join(',')})`);
    try {
        return getFunctionWithTemplate(functionBody);
    }
    catch (error) {
        // 如果函数体是
        // b(){ ... } 这样的形式，上面的模版会编译失败
        // 我们尝试加一个function前缀，如果还失败，那就抛出这个错误
        if (functionBody.startsWith('async')) {
            const functionStr = functionBody.replace(/async/, 'async function ');
            return getFunctionWithTemplate(functionStr);
        }
        return getFunctionWithTemplate(`function ${functionBody}`);
    }
};
export default newFunction;
