import qs from 'query-string';
import wujiFetch from '@utils/wujiFetch';
import { BASE_API_PATH } from '@config/constant';
import getXyManagePathPrefix from '@/utils/getXyManagePathPrefix';
import {
  formatToUiProjectInfo,
  formatToSaveProjectInfo,
} from '@loaders/project/utils';

class ProjectLoader {
  /*
   * 编辑时获取应用列表
  */
  async getProjectList(query = {}) {
    const queryString = qs.stringify(query);
    let url = `${BASE_API_PATH}/xy/user/project`;
    if (queryString) {
      url += `?${queryString}`;
    }
    return wujiFetch(url);
  }

  async getRemoteProjectList(host, query = {}) {
    const queryString = qs.stringify(query);
    let url = `//${host}${BASE_API_PATH}/xy/user/project`;
    if (queryString) {
      url += `?${queryString}`;
    }
    return wujiFetch(url);
  }

  async exportProjectInfo({ projectId, remoteHost = '', envId = 'dev' }) {
    const url = remoteHost
      ? `//${remoteHost}${getXyManagePathPrefix({ envId })}/project/export/${projectId}`
      : `${getXyManagePathPrefix({ envId })}/project/export/${projectId}`;

    const res = await wujiFetch(url, { timeout: 60000 });
    return res;
  }

  /**
   * 运行时加载应用数据
   */
  async fetchBasicProjectInfo(projectId, envId = 'dev') {
    const projectInfo = await wujiFetch(`${getXyManagePathPrefix({ envId })}/basic/project/${projectId}`);
    if (projectInfo) {
      return formatToUiProjectInfo(projectInfo);
    }
    return projectInfo;
  }

  /**
   * 获取编辑时完整的应用配置
   */
  async fetchProjectInfo(projectId) {
    const projectInfo = await wujiFetch(`${BASE_API_PATH}/xy/project/${projectId}`);
    return formatToUiProjectInfo(projectInfo);
  }

  /**
   * 保存应用配置(创建)
   */
  async saveProjectInfo(projectInfo) {
    const uri = `${BASE_API_PATH}/xy/project`;
    return await wujiFetch(uri, {
      method: 'POST',
      body: JSON.stringify(formatToSaveProjectInfo(projectInfo, true)),
    });
  }

  async updateProjectInfo(projectId, data) {
    return await wujiFetch(`${BASE_API_PATH}/xy/project/${projectId}`, {
      method: 'PATCH',
      body: JSON.stringify(formatToSaveProjectInfo(data)),
    });
  }
  /**
   * 软删除
   */
  async deleteProject(projectId) {
    const uri = `${BASE_API_PATH}/xy/project/logicdelete/${projectId}`;
    return await wujiFetch(uri, {
      method: 'POST',
    });
  }
}

export default new ProjectLoader();
