import { defineComponent } from '@vue/composition-api';
import DetailDiff from '@components/version/detailDiff.vue';
import styles from './styles/mergeModal.module.scss';
import ConflictView from './ConflictView';
export default defineComponent({
    name: 'MergeView',
    components: {
        DetailDiff,
    },
    props: {
        change: {
            type: Object,
            default: () => { },
        },
        currentBranch: {
            type: String,
            default: '',
        },
        mergeBranch: {
            type: String,
            default: '',
        },
    },
    emits: ['change'],
    data() {
        return {};
    },
    watch: {},
    methods: {
        onChangeConflict(data) {
            this.$emit('change', {
                ...this.change,
                content: {
                    ...this.change.content,
                    after: data,
                },
            });
        },
    },
    render() {
        return (<div style={{
                overflow: 'hidden',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
            }}>
        {!this.change.conflict && <DetailDiff class={styles.editor_diff_detail} type="yaml" originalData={this.change.content.before} modifiedData={this.change.content.after}/>}
        {this.change.conflict && <ConflictView class={styles.editor_diff_detail} onChange={this.onChangeConflict} conflictItem={this.change.content} key={this.change.key} currentBranchLabel={this.currentBranch} mergeBranchLabel={this.mergeBranch}/>}
      </div>);
    },
});
