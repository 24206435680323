/**
 * 获取哪些字段发生了变化，返回字符串数组。示例：
 * ```
 * [
 *   "aaa",
 *   "bbb[1].x",
 *   "bbb[1]",
 *   "bbb[2].yy",
 *   "bbb[2]",
 *   "bbb"
 * ]
 * ```
 *
 * 这个示例说明:
 * - 字段 aaa、bbb 都发生了变化，
 * - 其中 bbb 是一个数组，它的第2、3条数据变了。
 * - 具体的，bbb 第二条的 x 和第三条的 yy 变了
 *
 * @returns {string[]}
 */
export default function getChanges(oldData, newData, defaultPrefix = '') {
  const changedPaths = [];

  function isChanged(p, o, n) {
    // 普通的检查
    if (!o || typeof o !== 'object' || !n || typeof n !== 'object') {
      return o !== n;
    }

    // 数组的检查
    if (Array.isArray(o)) {
      let arrChanged = false;

      for (let i = 0; i < o.length; i++) {
        const p2 = `${p}[${i}]`;
        if (isChanged(p2, o[i], n[i])) {
          changedPaths.push(p2);
          arrChanged = true;
        }
      }

      if (o.length < n.length) {
        for (let i = o.length; i < n.length; i++) {
          const p2 = `${p}[${i}]`;
          changedPaths.push(p2);
        }
      }

      return arrChanged;
    }

    // 对象的检查
    const keys = new Set([...Object.keys(o), ...Object.keys(n)]);
    let objChanged = false;
    keys.forEach((k) => {
      const p2 = `${p}${p ? '.' : ''}${k}`;
      if (isChanged(p2, o[k], n[k])) {
        changedPaths.push(p2);
        objChanged = true;
      }
    });
    return objChanged;
  }

  isChanged(defaultPrefix, oldData, newData);

  return changedPaths;
}
