import wujiFetch from '@utils/wujiFetch';
import { BASE_API_PATH } from '@config/constant';

const designBaseUrl = `${BASE_API_PATH}/xy/design/announcement`;
const runtimeBaseUrl = `${BASE_API_PATH}/xy/runtime/announcement`;

const fetchAnncmntList = async (projectId, filter = '') => await wujiFetch(
  `${designBaseUrl}?projectid=${projectId}&filter=${encodeURIComponent(filter)}`
  , {
    method: 'GET',
    timeout: 3000,
  },
);

const deleteAnncmnt = async ({ projectId, id, headers = {} }) => await wujiFetch(
  `${designBaseUrl}/${id}?projectid=${projectId}`,
  {
    method: 'DELETE',
    headers,
  },
);

const updateAnncmnt = async ({ projectId, id, data = {}, headers = {} }) => await wujiFetch(
  `${designBaseUrl}/${id}?projectid=${projectId}`,
  {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers,
  },
);

const createAnncmnt = async ({ projectId, data = {}, headers = {} }) => await wujiFetch(
  `${designBaseUrl}?projectid=${projectId}`,
  {
    method: 'POST',
    body: JSON.stringify({
      ...data,
      projectId,
    }),
    headers,
  },
);

const fetchAnncmntRuntimeList = async (projectId, filter = '') => await wujiFetch(
  `${runtimeBaseUrl}?projectid=${projectId}&filter=${encodeURIComponent(filter)}`,
  {
    method: 'GET',
    timeout: 3000,
  },
);

const fetchAnncmnLoopList = async projectId => await wujiFetch(
  `${runtimeBaseUrl}?projectid=${projectId}&filter=${encodeURIComponent('style=\'carousel\'')}`,
  {
    method: 'GET',
    timeout: 3000,
  },
);

const readAnncmntRuntime = async (projectId, id, endTime) => await wujiFetch(
  `${runtimeBaseUrl}/read/${id}?projectid=${projectId}`,
  {
    method: 'POST',
    body: JSON.stringify({
      endTime,
    }),
  },
);

export default {
  fetchAnncmntList,
  deleteAnncmnt,
  updateAnncmnt,
  createAnncmnt,
  fetchAnncmntRuntimeList,
  fetchAnncmnLoopList,
  readAnncmntRuntime,
};
