import { uniqBy } from 'lodash';
import { memoizedFetchGET } from '@utils/wujiFetch';
import { BASE_API_PATH } from '@config/constant';
import { loadScript, isBuiltinPageTemplate } from '@utils/common';
import wujiComp from '@tencent/xy-compsloader';
import { getHmrPort, isHmr } from '@/utils/comps-loader';
import { GLOBAL_PAGE_ID } from '@components/pluginList';
import logger from '@/utils/logger';
import getXyManagePathPrefix from '@/utils/getXyManagePathPrefix';
import { getLocalBaseApiUrl } from '@/config/cli-settings';
import { allBuiltinPagePluginInfoList } from '@/components/builtinPagePlugins';
import { getGeneratedPage } from '../pageGenerator';
import { isRuntime } from '@/utils/globalInfo';
import { message } from '@/utils/wuji-message';
import { addJsonPrefixIfOffline } from '@/utils/offline';

const locallyEnabledPluginConfigKey = (projectId, pageId, pluginId) => `debug_plugin_config_${projectId}_${pageId}_${pluginId}`;

export const getLocallyEnabledPluginConfig = (projectId, pageId, pluginId) => {
  const key = locallyEnabledPluginConfigKey(projectId, pageId, pluginId);
  return localStorage.getItem(key);
};

export const setLocallyEnabledPluginConfig = (projectId, pageId, pluginId, config) => {
  const key = locallyEnabledPluginConfigKey(projectId, pageId, pluginId);
  localStorage.setItem(key, config);
};


class PluginLoader {
  constructor() {
    this.debug = isHmr(null);
    this.port = getHmrPort(null);
    this.plugins = {};
    this.pluginConfigs = [];
  }
  /**
   * 获取应用和页面安装的扩展插件, 这里获取的是插件实例
   */
  async list(projectId, pageId, showMessage = true) {
    const key = `${projectId}:${pageId}`;
    // 前端存储已经加载了的插件，避免重复请求和加载同一个页面的插件
    if (this.plugins[key]) {
      return this.plugins[key];
    }

    let plugins = [];
    try {
      ({ plugins } = await this.getInstalledPlugins(projectId, pageId));
    } catch (error) {
      if (showMessage) {
        const res = await error.response.json();
        message.error(`获取插件列表失败:${res.message}`);
      }
      logger.error(error);
      return [];
    }

    let pluginInstances = [];
    // 没有安装插件的情况下不需要请求pluginhub接口
    const getInstance = async (plugin) => {
      try {
        const { src } = plugin.detail;
        const pluginJS = await this.getPluginJS({ pluginId: plugin.pluginId, src });
        const instance = this.getPluginInstance(plugin.pluginConfig, pluginJS, { projectId, pageId });
        return { pluginId: plugin.pluginId, plugin: instance };
      } catch (err) {
        console.error(err);
        message.error(`执行扩展插件${plugin.pluginId}失败:${err}`);
      }
    };
    try {
      pluginInstances = await Promise.all(plugins.map(plugin => getInstance(plugin)));
      pluginInstances = pluginInstances.filter(item => item && item.plugin !== undefined);
      this.plugins[key] = pluginInstances;

      // 插件实例里可以提供异步方法 waitForReady 阻塞页面的加载
      // 甚至阻塞页面数据源和UICore的
      await Promise.all(pluginInstances.map(item => item.plugin?.waitForReady?.()));
      return this.plugins[key];
    } catch (err) {
      return this.plugins[key];
    }
  }

  async requestPlugins(projectId, pageId, filter = '') {
    // 流程审批页面不用获取插件信息
    if ((!pageId && !filter) || isBuiltinPageTemplate(pageId)) return [];
    // 运行时优先从全局变量获取数据
    if (isRuntime()) {
      const cachePlugins = window?.xy_runtime_page_plugins_cache?.[pageId];
      if (cachePlugins) return cachePlugins;
    }
    const query = new URLSearchParams({
      filter,
    });
    const url = `${getXyManagePathPrefix()}/plugin/list/${projectId}/${encodeURIComponent(pageId)}${addJsonPrefixIfOffline()}?${query.toString()}`;
    const plugins = await memoizedFetchGET(url);
    return plugins;
  }

  async getInstalledPlugins(projectId, pageId, filter = '') {
    const enabledPlugins = [];

    // 对于生成的页面，需要走特殊API获取
    const fromGeneratedPage = await (await getGeneratedPage({ projectId, pageId }))?.getPluginList?.();
    if (Array.isArray(fromGeneratedPage)) enabledPlugins.push(...fromGeneratedPage);

    // 获取应用和页面安装的扩展插件
    const plugins = await this.requestPlugins(projectId, pageId, filter);

    /**
     * 为什么要解构一次？
     * 因为plugins这个对象被缓存了，我们后续直接修改plugins，会导致这个请求的结果被直接修改
     * 导致本地启动的逻辑判断会失败，为了解决这个问题，我们不要直接用这个对象，而是先clone一次。
     */
    enabledPlugins.push(...plugins);

    // 处理内置页面插件，它们的 src 不太一样
    enabledPlugins.forEach((item) => {
      if (item.detail?.src) return;

      const info = allBuiltinPagePluginInfoList.find(p => p.pluginId === item.pluginId);
      if (!info) return;

      // eslint-disable-next-line no-param-reassign
      item.detail = {
        id: info.detail.pluginId,  // FIXME: 应该是数字，不过大概没关系吧
        src: info.detail.src,
      };
    });

    // 本地启动的插件，这个启动信息不是写入数据库的，而是因为debug模式启用的
    const locallyEnabledPlugins = [];
    // debug的插件默认开启，不需要用户手动启用
    if (this.debug) {
      const debugPlugins = await this.loadDebugPlugins();
      debugPlugins.forEach((plugin) => {
        // 检查这个debug组件是否已经在远程启动了, 如果在远程已经启动过，我们会在接口中获取到这个组件
        const enabledRemotely = enabledPlugins.find(p => p.pluginId === plugin.pluginId);
        // 如果没有远程启动，将其加入到启动的列表中
        if (!enabledRemotely) {
          // 从localStorage中获取pluginConfig
          const pluginConfig = getLocallyEnabledPluginConfig(projectId, pageId, plugin.pluginId)
            || getLocallyEnabledPluginConfig(projectId, GLOBAL_PAGE_ID, plugin.pluginId);
          enabledPlugins.push({
            ...plugin,
            pluginId: plugin.pluginId,
            pluginConfig,
            enabledRemotely: false,
          });
          locallyEnabledPlugins.push(plugin.pluginId);
        } else {
          // 如果远程启动，将插件实例进行替换
          enabledRemotely.detail = plugin.detail;
        }
      });
    }
    return { plugins: enabledPlugins, locallyEnabledPlugins };
  }

  getPluginConfig(pluginId) {
    return (this.pluginConfigs.find(pluginConfig => pluginConfig.pluginId === pluginId) || {}).config;
  }

  // 获取插件实例，插件实例 = 插件js返回的comp + 插件配置 pluginConfig
  getPluginInstance(pluginConfig, pluginJS, context) {
    let config = '';
    try {
      config = JSON.parse(pluginConfig);
    } catch (error) {
      // do nothing
    }
    return pluginJS.comp(config, context);
  }

  /**
   * 获取所有扩展插件列表
   */
  async loadPlugins(groups = []) {
    try {
      const url = `${BASE_API_PATH}/xy/pluginhub?groups=${groups.join(',')}`;
      let pluginList = [...await memoizedFetchGET(url), ...allBuiltinPagePluginInfoList];
      // debug开启的时候需要拉取hmr的列表并且做合并
      if (this.debug) {
        const debugList = await this.loadDebugPlugins();
        pluginList = [...debugList, ...pluginList];
      }
      return uniqBy(pluginList, 'pluginId');
    } catch (err) {
      message.error('获取扩展插件失败');
      return [];
    }
  }

  async loadDebugPlugins() {
    try {
      const list = await memoizedFetchGET(`${getLocalBaseApiUrl(this.port)}/plugin`);
      return list;
    } catch (err) {
      // ignore
      logger.error(`暂未获取到可供调试的扩展插件:${err.message}`);
      return [];
    }
  }

  // 加载插件的js，获取js的输出
  async getPluginJS({ pluginId, src }) {
    if (typeof src === 'string') {
      // src is script URL
      await loadScript(src);
    } else if (typeof src === 'function') {
      // 支持 xy-client 内置的插件，见 src/components/builtinPagePlugins/
      // src is () => Promise<{ comp, config }>

      await Promise.resolve()
        .then(src)
        .then((result) => {
          const mod = { default: result, __esModule: true };
          wujiComp.registerPlugin(pluginId, [], [], () => mod);
        });
    } else {
      throw new Error('Invalid plugin src: ', src);
    }

    const plugin = await wujiComp.getPlugin(pluginId);
    return plugin;
  }
}

export default new PluginLoader();
