import Vue from 'vue';
import Antd from 'ant-design-vue';
import dayjs from 'dayjs';
import lodash from 'lodash';
import axios from 'axios';
import ajv from 'ajv';
import CryptoJS from 'crypto-js';
import queryString from 'query-string';
import * as buffer from 'buffer';
import * as VueCompositionAPI from '@vue/composition-api';
import * as nanoid from 'nanoid';
import * as jsYaml from 'js-yaml';

export const COMMON_THIRD_PARTY_LIBS = {
  vue: Vue,
  antd: Antd,
  axios,
  dayjs,
  lodash,
  ajv,
  buffer,
  'crypto-js': CryptoJS,
  'query-string': queryString,
  '@vue/vue-composition-api': VueCompositionAPI,
  nanoid,
  'js-yaml': jsYaml,
};

export function defineCommonThirdPartyLibs() {
  Object.entries(COMMON_THIRD_PARTY_LIBS).forEach(([key, value]) => {
    window.define(key, [], () => value);
  });
  // eslint-disable-next-line no-underscore-dangle
  window._xy_node_modules = COMMON_THIRD_PARTY_LIBS;
  window.CryptoJS = CryptoJS;
  window.jsyaml = jsYaml;
}
