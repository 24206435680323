import uiFlowAtoms from './uiAtoms';
import logicAtoms from './logicAtoms';
import draftAtoms from './draftAtoms';
import sourceAtoms from './sourceAtoms';
import exportAtoms from './exportAtoms';
import navigatorAtoms from './navigatorAtoms';
import notificationAtom from './notificationAtom';
import workflowAtoms from './workflowAtoms';
import oldAtoms from './oldAtoms';
import flowAtoms from './flowAtoms';
import aiAtoms from './aiAtoms';
import { ENABLE_FLOWENGINE } from '@config/constant';

/** @type {import('../../../../../ui-core/types/event').SimpleFlowAtom[]} */
const extraUICoreFlowAtoms = [];

extraUICoreFlowAtoms.push(
  ...uiFlowAtoms,
  ...logicAtoms,
  ...sourceAtoms,
  ...exportAtoms,
  ...navigatorAtoms,
  ...draftAtoms,
  ...notificationAtom,
  ...oldAtoms,
  ...(ENABLE_FLOWENGINE ? flowAtoms : []),
  ...aiAtoms,
);

export const getExtraUICoreFlowAtoms = (uc) => {
  // 如果有旧流程，展示旧引擎的行为原子
  if (uc.renderer?.wContext?.workflows?.length > 0 || window.RUNTIME_CONFIGS) {
    return [...extraUICoreFlowAtoms, ...workflowAtoms];
  }
  return extraUICoreFlowAtoms;
};

export default extraUICoreFlowAtoms;
