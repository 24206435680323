import wujiFetch from '@utils/wujiFetch';
import { BASE_API_PATH } from '@/config/constant';

const createTag = async (projectId, data = {}, headers = {}) => await wujiFetch(`${BASE_API_PATH}/xy/pagetag?projectid=${projectId}`, {
  method: 'POST',
  body: JSON.stringify(data),
  headers,
});

const deleteTag = async (projectId, id, headers = {}) => await wujiFetch(`${BASE_API_PATH}/xy/pagetag/delete/${id}?projectid=${projectId}`, {
  method: 'POST',
  headers,
});

const updateTag = async (projectId, id, data = {}, headers = {}) => await wujiFetch(`${BASE_API_PATH}/xy/pagetag/patch/${id}?projectid=${projectId}`, {
  method: 'POST',
  body: JSON.stringify(data),
  headers,
});

const getTagList = async projectId => await wujiFetch(`${BASE_API_PATH}/xy/pagetag?projectid=${projectId}`);

const getBasicTagList = async projectId => await wujiFetch(`${BASE_API_PATH}/xy/runtime/pagetag?projectid=${projectId}`);

export default {
  createTag,
  deleteTag,
  updateTag,
  getTagList,
  getBasicTagList,
};
