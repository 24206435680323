import { message } from 'ant-design-vue';
import BannerService from '@/services/banner';

export default {
  state: {
    bannerData: null,
    showBanner: false,
  },
  getters: {
  },
  actions: {
    async fetchBannerData({ commit }) {
      try {
        const { pathname } = window.location;
        const result = await BannerService.fetchBannerData(pathname);
        if (result) {
          commit('updateBannerData', result);
          return result;
        }
      } catch (err) {
        console.error(err);
        message.error('获取公告数据失败', err.message);
      }
    },
    async fetchBannerRecord({ commit }, { id } = {}) {
      try {
        const result = await BannerService.fetchBannerRecord(id);
        commit('updateBannerRecord', result);
        return result;
      } catch (err) {
        console.error(err);
        message.error('获取公告记录失败', err.message);
      }
    },
  },
  mutations: {
    updateBannerData(state, payload) {
      state.bannerData = payload;
    },
    updateBannerRecord(state, payload) {
      state.showBanner = payload;
    },
  },
};
