import GetMemoryLeakMixin from '@utils/vue/memoryLeakMixin';
import MemoryLeakDetectMixin from '@utils/vue/memoryLeakDetectMixin';
import { LOW_MEMORY_MODE } from '@config/constant';

export function mixinMemoryLeakEditor(Vue) {
  try {
    if (localStorage.getItem('lowmemory:editor:debug')) {
      Vue.mixin(GetMemoryLeakMixin(true));
    } else if (localStorage.getItem('lowmemory:editor')) {
      Vue.mixin(GetMemoryLeakMixin());
    }

    if (localStorage.getItem('leakDetect:editor')) {
      Vue.mixin(MemoryLeakDetectMixin);
    }
  } catch (e) {
    console.error('memory mixin error', e);
  }
}
export function mixinMemoryLeakRuntime(Vue) {
  try {
    if (localStorage.getItem('lowmemory:runtime:debug')) {
      Vue.mixin(GetMemoryLeakMixin(true));
    } else if (LOW_MEMORY_MODE || localStorage.getItem('lowmemory:runtime')) {
      Vue.mixin(GetMemoryLeakMixin());
    }
    if (localStorage.getItem('leakDetect:runtime')) {
      Vue.mixin(MemoryLeakDetectMixin);
    }
  } catch (e) {
    console.error('memory mixin error', e);
  }
}
