import Vue from 'vue';

const loadingPlugin = (store) => {
  const loadingModule = {
    state: () => ({}),
    mutations: {
      startLoading(state, payload) {
        Vue.set(state, payload, true);
      },
      stopLoading(state, payload) {
        Vue.set(state, payload, false);
      },
    },
  };
  store.registerModule('loading', loadingModule);
  store.subscribeAction({
    before: (action) => {
      store.commit('startLoading', action.type);
    },
    after: (action) => {
      store.commit('stopLoading', action.type);
    },
    // 抛错也要停止loading
    error: (action) => {
      store.commit('stopLoading', action.type);
    },
  });
};

export default loadingPlugin;
