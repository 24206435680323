import { defineComponent, inject } from '@vue/composition-api';
import { usePageletEvent, EVENTS } from '@composables/usePageletEvent';
import { DEFAULT_LESSCODE } from '@components/CodeEditor/utils/codes';
import styles from './index.module.scss';
const XyHeaderContainer = defineComponent({
    name: 'XyHeaderContainer',
    setup(props, { emit }) {
        usePageletEvent({ emit });
        const w = inject('w', null);
        const getDefaultSlot = () => {
            if (w?.mode === 'runtime')
                return null;
            return (<div class={styles.placeholder}>
        <span>请放置组件</span>
      </div>);
        };
        return {
            getDefaultSlot,
        };
    },
    render() {
        return (<div class={['xy-header-container', styles.container]}>
        {this.$slots.default || this.getDefaultSlot()}
      </div>);
    },
});
const componentId = 'xy-header-container';
export const getDefaultHeaderConfig = (id) => ([
    {
        id,
        name: '页头',
        layout: {
            type: componentId,
            props: {},
            children: [],
            style: {
                height: '50px',
                width: '100%',
                'background-color': 'rgba(255, 255, 255, 1)',
            },
        },
        xyType: 'pagelet',
        lessCode: DEFAULT_LESSCODE,
    },
]);
export default {
    component: XyHeaderContainer,
    componentForDesign: XyHeaderContainer,
    category: '组合',
    id: componentId,
    slots: [
        { id: 'default', selector: '.xy-header-container' },
    ],
    events: EVENTS,
    display: 'block',
    visible: false,
    name: '页头',
    propsSchema: {
        type: 'object',
        fields: [],
    },
    readme: '',
};
