<template>
  <div
    :class="className"
    class="xy-pagelet-container"
  >
    <slot>
      <div class="xy-pagelet-container-placeholder">
        <span>请放置组件</span>
      </div>
    </slot>
  </div>
</template>
<script>
import { usePageletEvent } from '@composables/usePageletEvent';

export default {
  name: 'XyPageletContainer',
  props: {
    className: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    usePageletEvent({ emit });
  },
};
</script>

<style lang="scss">
.xy-pagelet-container {
  position: relative;
  min-height: max(100%, 1em);
  .xy-pagelet-container-placeholder {
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    border: 1px dashed;
  }
}
</style>
