<template>
  <div
    class="xy-pagelet-renderer"
    :class="{ 'xy-pagelet-renderer-runtime': isDesignMode }"
  >
    <template>
      <template v-if="pageletId">
        <w-sys-page
          v-if="projectConfig && pageList && config"
          ref="w-sys-pagelet"
          mode="runtime"
          :project-config="projectConfig"
          :config="config"
          :page-list="pageList"
          :merge-params="mergeParams"
          :patch-params-object="pageDataBase.patchParamsObject"
          :params="pageDataBase.params"
          :pagelet-id="pageletId"
          :container="this"
          :main="false"
        />
      </template>
      <div
        v-else
        class="xy-pagelet-renderer-tips"
      >
        <span>
          请选择页面片
        </span>
      </div>
    </template>
  </div>
</template>
<script>
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import { stringInterop, vueDeepSet } from '@tencent/ui-core/lib/utils';
import { toPathParts } from '@tencent/data-schema-core/lib/utils/path';

export default {
  name: 'XyPageletRenderer',
  inject: {
    renderer: { from: 'uicore:renderer', default: null },
    parentWContext: {
      from: 'wContext',
      default: null,
    },
  },
  props: {
    pageletId: {
      type: String,
      default: '',
    },
    // [废弃] mergeParams 兼容旧配置
    mergeParams: {
      type: Boolean,
      default: false,
    },
    params: {
      type: [Array, Object],
      default: () => ([]),
    },
  },
  data() {
    return {

    };
  },
  computed: {
    projectConfig() {
      return this?.parentWContext?.projectConfig;
    },
    config() {
      return this?.parentWContext?.config;
    },
    pageList() {
      return this?.parentWContext?.pageList;
    },
    pageDataBase() {
      const { renderer } = this;
      const params = {};
      const patchParamsObjectBody = [];  // Functions

      forEach(this.params, (paramItem) => {
        const { key, value: valueRaw, bind } = paramItem;

        if (bind) {
          // 使用双向绑定的方式结合到当前页面的数据源
          const bindParts = toPathParts(bind);
          patchParamsObjectBody.push((x) => {
            Object.defineProperty(x, key, {
              configurable: false,
              enumerable: true,
              get: () => get(renderer, bindParts),
              set: (value) => {
                vueDeepSet(renderer, bindParts, value);
              },
            });
          });
        } else {
          // 使用表达式的方式过来，由于 wSysPage 会做深层拷贝，所以会失去绑定
          const value = stringInterop(valueRaw, renderer);
          params[key] = value;
        }
      });

      const patchParamsObject = patchParamsObjectBody.length
        ? (...args) => patchParamsObjectBody.forEach(fn => fn(...args))
        : null;

      return {
        patchParamsObject,
        params,
      };
    },
  },
};
</script>

<style lang="scss" scope>
.xy-pagelet-renderer {
  position: relative;
  .loading-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    z-index: 300;

    .loading-content {
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .xy-pagelet-renderer-tips {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
  }
}
.xy-pagelet-renderer-runtime {
  min-height: 100px;
}
</style>
