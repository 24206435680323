import { computed, reactive, watch, ref, getCurrentInstance } from '@vue/composition-api';
import { fetchRtxInfo, ONE_DAY_MS, usePermissionSettings } from './utils';
import { providePermState } from '@pages/modules/permission/PermState';
export function useExpire(users, { readonly = ref(false), options = ref([]), checkPangu = true, permissionSetting = ref(), onlyTag = false, } = {}) {
    let permissionSettings;
    if (permissionSetting.value) {
        // @ts-ignore
        permissionSettings = permissionSetting;
    }
    else {
        permissionSettings = usePermissionSettings().permissionSettings;
    }
    const { panguMappingEnabled, initAuthSetting } = providePermState();
    if (checkPangu) {
        const { props } = getCurrentInstance();
        initAuthSetting(props.projectId, props.env || 'dev');
    }
    const permissionOptions = computed(() => {
        // 用传进来的
        if (options.value.length > 0) {
            return options.value;
        }
        if (permissionSettings.value.expireOptions?.length) {
            return permissionSettings.value.expireOptions;
        }
        return [
            { label: '1天', value: 1 },
            { label: '3天', value: 3 },
            { label: '1月', value: 30 },
            { label: '3月', value: 90 },
            { label: '1年', value: 365 },
        ];
    });
    // 先简单过滤一下 后面从配置里捞
    const selectOptions = computed(() => {
        let maxDay = permissionSettings.value.maxDays;
        if (parseInt(`${maxDay}`, 10) === 0) {
            maxDay = 0;
        }
        return [
            ...permissionOptions.value,
            !maxDay && { label: '永久', value: 0 },
        ].filter(Boolean)
            .filter(config => config.value <= (maxDay || 365));
    });
    const expireOptions = reactive({
        expire: 0,
        expireAt: 0,
        rtxList: [],
    });
    watch(() => expireOptions.expire, (val) => {
        expireOptions.expireAt = val ? Date.now() + val * ONE_DAY_MS : val;
    }, { immediate: true });
    const expireRules = computed(() => permissionSettings.value?.expireRules || []);
    watch(() => users.value, async (val) => {
        if (!val.filter(Boolean).length)
            return;
        // 捞一下rtx身份匹配特殊规则
        const res = await fetchRtxInfo(val.filter((r) => typeof r === 'string'));
        expireOptions.rtxList = res;
    }, { immediate: true });
    const warningList = computed(() => expireOptions.rtxList
        .filter(item => expireRules.value.find(rule => rule.typeName.includes(item.typeName)))
        .map(item => ({
        warnText: `${item.id + (item.chnName ? `(${item.chnName})` : '')}是${item.typeName}员工
      ,有效期最长${expireRules.value.find(i => i.typeName.includes(item.typeName))?.maxDays}天`,
        info: item,
    })));
    const renderTypeWarning = function () {
        return warningList.value.map(item => (<a-alert showIcon={false} message={item.warnText} banner/>));
    };
    const renderCreateExpire = function () {
        // 开了盘古就不启用权限过期了
        if (panguMappingEnabled.value)
            return [];
        if (readonly.value) {
            return <a-form-model-item label="有效期" style="user-select: none;">
        {selectOptions.value.find(option => option.value === expireOptions.expire)?.label
                    || (`${expireOptions.expire}天`)}
      </a-form-model-item>;
        }
        const tags = selectOptions.value.map(({ label, value }) => {
            const change = (v) => v && (expireOptions.expire = value);
            const val = expireOptions.expire === value;
            return <a-checkable-tag checked={val} onChange={change}>
        {label}
      </a-checkable-tag>;
        });
        if (onlyTag) {
            return tags;
        }
        return [<a-form-model-item label="有效期" required style="user-select: none;">
      {tags}
      {renderTypeWarning()}
    </a-form-model-item>,
        ];
    };
    return {
        expireOptions,
        renderCreateExpire,
    };
}
