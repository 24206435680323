function removeListener(
  name,
  handler,
  capture,
  _target,
) {
  // eslint-disable-next-line no-underscore-dangle
  _target?.removeEventListener?.(name, handler._wrapper || handler, capture);
}

function normalizeEvent(name) {
  const passive = name.charAt(0) === '&';
  // eslint-disable-next-line no-param-reassign
  name = passive ? name.slice(1) : name;
  const once$$1 = name.charAt(0) === '~'; // Prefixed last, checked first
  // eslint-disable-next-line no-param-reassign
  name = once$$1 ? name.slice(1) : name;
  const capture = name.charAt(0) === '!';
  // eslint-disable-next-line no-param-reassign
  name = capture ? name.slice(1) : name;
  return {
    name,
    once: once$$1,
    capture,
    passive,
  };
}

function updateListeners(
  on,
  target,
) {
  // eslint-disable-next-line no-restricted-syntax
  for (const name in on) {
    const event = normalizeEvent(name);
    removeListener(event.name, on[name], event.capture, target);
  }
}

export function releaseVueDomListener(vm) {
  // eslint-disable-next-line no-underscore-dangle
  const vNode = vm._vnode;


  if (vNode?.data?.on) {
    updateListeners(vNode.data.on, vNode.elm);
  }
}
