import { FlowNodeSpecialBase } from './base';
export const flowNodeSpecialList = {
    start: () => import('./start').then(r => r.default),
    approval: () => import('./xyApproval').then(r => r.default),
    log: () => import('./log').then(r => r.default),
};
export async function getFlowNodeSpecial(id) {
    if (id in flowNodeSpecialList)
        return await flowNodeSpecialList[id]();
    console.warn(`[flowPagePlugin] failed to fetch node specials: type = ${id}`);
    return FallbackNodeSpecial;
}
export class FallbackNodeSpecial extends FlowNodeSpecialBase {
    async getDataSourceInstance() {
        const schema = {
            type: 'object',
            fields: [],
        };
        return {
            instance: {},
            schema,
        };
    }
    makeDefaultLayout() {
        return {
            type: 'public-w-icon-show-new',
            props: {
                icon: 'small-dash',
                sizeType: 'stretch',
                dropInVersion: 2,
                tooltipTitle: '无法加载这个节点',
            },
            style: { width: '64px', height: '64px', opacity: '0.2' },
        };
    }
    onDispose() {
    }
}
