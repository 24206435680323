import { flowNodePagelet } from './generators/flowNodePagelet';
import { flowDetailPage } from './generators/flowDetailPage';
import { generatedPageIdPrefix } from '@/config/constant';

/**
 * @returns {ReturnType<import('./types').PageGenerator>}
 */
export async function getGeneratedPage({ projectId, pageId }) {
  if (!pageId?.startsWith(generatedPageIdPrefix)) return null;
  const options = JSON.parse(pageId.slice(generatedPageIdPrefix.length));
  return generators[options.type](options, { projectId, pageId });
}

/**
 * @type {Record<string, import('../types').PageGenerator>}
 */
const generators = {
  flowNodePagelet,
  flowDetailPage,
};

