import { SKIN_API_PATH } from '@/config/constant';
import wujiFetch from '@/utils/wujiFetch';
import Vue from 'vue';
import { message } from '@utils/wuji-message';
export const FALLBACK_DEAFULT_SKIN = {
    id: 'wuji-base:1',
    skinId: 'wuji-base',
    version: 1,
    metadata: {
        author: '无极',
        name: '无极默认皮肤',
        description: '无极默认皮肤',
    },
    styles: {},
};
const state = {
    skins: undefined,
    skinStyles: {},
};
const actions = {
    async listSkins({ commit, state }) {
        const { skins } = state;
        if (skins)
            return skins;
        const data = await wujiFetch(SKIN_API_PATH);
        commit('setSkins', data);
        return data;
    },
    async getSkinStyles({ commit, state }, { skinId, version }) {
        if (version === undefined) {
            // 找到skinStyles中与skinId匹配的最新版本
            const prefix = `${skinId}:`;
            const versions = Object.keys(state.skinStyles)
                .filter(key => key.startsWith(prefix))
                .map(key => key.slice(prefix.length))
                .sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
            // eslint-disable-next-line no-param-reassign
            [version] = versions;
        }
        let id = `${skinId}:${version}`;
        if (id in state.skinStyles)
            return state.skinStyles[id];
        let data;
        try {
            data = await wujiFetch(`${SKIN_API_PATH}/${skinId}?version=${version ?? ''}`);
            if (!data) {
                throw new Error('返回数据为空');
            }
        }
        catch (e) {
            console.error('获取皮肤失败', e, { skinId, version });
            if (skinId === 'wuji-default') {
                data = { ...FALLBACK_DEAFULT_SKIN };
            }
            else {
                message.error(`获取皮肤数据失败 ${e.message}`);
                throw new Error(`无法获取皮肤 ${skinId}`);
            }
        }
        id = `${skinId}:${data.version}`; // 之前的version可能为undefined
        commit('setSkinStyles', { id, styles: data.styles });
        return data.styles;
    },
};
const mutations = {
    setSkins(state, skins) {
        state.skins = skins;
    },
    setSkinStyles(state, { id, styles }) {
        Vue.set(state.skinStyles, id, styles);
    },
};
const store = {
    state,
    actions,
    mutations,
};
export default store;
