/**
 * 对于需要类型安全性的地方，可以用这个来保护下
 *
 * @param x - can be string or anything else
 * @returns - always string
 */
export function toString(x) {
    if (typeof x === 'string')
        return x;
    if (!x || typeof x !== 'object')
        return String(x);
    try {
        return JSON.stringify(x);
    }
    catch (e) {
        return Object.prototype.toString.call(x);
    }
}
