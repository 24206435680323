import { Input, Modal, message } from 'ant-design-vue';

/**
 * @type {import('../../../../ui-core/types').UILib}
 */
const UICoreAntdUILib = {
  async alert(opt) {
    return new Promise((resolve) => {
      Modal.info({
        content: opt.content,
        maskClosable: true,
        onOk: () => void resolve(),
        onCancel: () => void resolve(),
      });
    });
  },

  async confirm(opt) {
    return new Promise((resolve) => {
      Modal.confirm({
        content: opt.content,
        onOk: () => void resolve(true),
        onCancel: () => void resolve(false),
      });
    });
  },

  message(opt) {
    const originalContent = opt.content;
    let content = originalContent;
    if (typeof originalContent === 'string') {
      content = h => h('span', {
        style: {
          display: 'inline-block',
          'white-space': 'pre-wrap',
          'vertical-align': 'top',
          'text-align': 'left',
        },
      }, [originalContent]);
    }

    message.open({
      type: opt.type,
      content,
      duration: opt.duration / 1000,
    });
  },

  async prompt(opt) {
    let answer = opt.defaultValue;

    // 输入框类型
    let InputBox = Input;
    const style = {};
    if (opt.password) {
      InputBox = Input.Password;
    } else if (opt.textarea) {
      InputBox = Input.TextArea;
      style.height = '7em';
    }

    const inputId = `tempBox${Date.now().toString(35)}`;

    return new Promise((resolve) => {
      const modal = Modal.confirm({
        content(h) {
          setTimeout(() => {
            const el = document.querySelector(`#${inputId} input, input#${inputId}, textarea#${inputId}`);
            if (el) {
              el.select();
              el.focus();
            }
          }, 200);

          return h('div', {}, [
            h('p', { domProps: { innerHTML: opt.message } }),
            h(InputBox, {
              props: { value: answer },
              domProps: {
                id: inputId,
              },
              style,
              on: {
                change: ev => void (answer = ev.target.value),
                pressEnter: (ev) => {
                  if (opt.textarea) return;

                  resolve(ev.target.value);
                  modal.destroy();
                },
              },
            }),
          ]);
        },
        onOk: () => void resolve(answer),
        onCancel: () => void resolve(null),
      });
    });
  },
};

export default UICoreAntdUILib;
