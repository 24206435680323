/**
 * 透传相关路由参数
 */
// eslint-disable-next-line import/prefer-default-export
export const getRouterPublicParams = (query) => {
  const { debug, debuglayout, debugport = '', debugfunchost = '', wujiskin = '' } = query || {};
  let ret = {};
  if (debug) {
    ret = {
      debug,
      debugport,
      debugfunchost,
    };
  } else if (debuglayout) {
    ret = {
      debuglayout,
    };
  }
  if (wujiskin) {
    ret = {
      ...ret,
      wujiskin,
    };
  }
  return ret;
};
