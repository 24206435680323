import { defineComponent, toRef, ref } from '@vue/composition-api';
import PermissionCheckbox from './PermissionCheckbox';
import { PermissionTypeMap, PermissionType } from './constants';
import { useFilterPermissions } from './utils';
export default defineComponent({
    name: 'CustomPermission',
    emits: ['change'],
    props: {
        projectId: {
            type: String,
            default: '',
            required: true,
        },
        env: {
            type: String,
            default: 'dev',
        },
        role: {
            type: String,
            default: '',
            required: true,
        },
        permissions: {
            type: Array,
            required: true,
        },
        rolePerms: {
            type: Array,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        maxHeight: {
            type: String,
            default: '',
        },
        isPermSet: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const permissionsRef = toRef(props, 'permissions');
        const { keyword, filterPermissions } = useFilterPermissions(permissionsRef, ref([]));
        return {
            keyword,
            filterPermissions,
        };
    },
    render() {
        const columns = [
            {
                title: '权限ID',
                dataIndex: 'name',
                key: 'name',
                width: 400,
            },
            {
                title: '权限名',
                dataIndex: 'cname',
                key: 'cname',
            },
            {
                title: '类型',
                dataIndex: 'type',
                key: 'type',
                customRender: (txt) => PermissionTypeMap[txt] || txt,
            },
            {
                title: '持有权限',
                customRender: (txt, record) => (<PermissionCheckbox projectId={this.projectId} env={this.env} role={this.role} rolePerms={this.rolePerms} readonly={this.readonly} permission={record.name} onCheckChange={(e) => this.$emit('checkChange', e)} onChange={(v) => this.$emit('change', v)}/>),
            },
        ];
        const types = this.isPermSet
            ? [PermissionType.MODULE, PermissionType.API]
            : [PermissionType.MODULE, PermissionType.API, PermissionType.SET];
        return (<div>
        <div style="margin-bottom:12px">
          <a-input-search v-model={this.keyword} placeholder="请输入权限名进行搜索" style="width:240px"></a-input-search>
        </div>
        <div>
          <a-table style={{ maxHeight: this.maxHeight, overflow: 'scroll' }} columns={columns} dataSource={this.filterPermissions.filter(x => types.includes(x.type))} rowKey="name" pagination={false} scroll={{ x: 700, y: 400 }}></a-table>
        </div>
      </div>);
    },
});
