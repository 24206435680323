import pageLoader from '@loaders/page/loader';
import { preloadCollections } from '@tencent/wuji-source';
import { isHmr } from '@utils/comps-loader';
import { message } from 'ant-design-vue';
// 反转义页面配置中的字符(fix: #924)
const decodePageConfig = (config) => {
    try {
        let str = JSON.stringify(config);
        if (typeof str === 'string') {
            const map = {
                '&lt;': '<',
                '&gt;': '>',
                '&amp;': '&',
            };
            str = str.replace(/&lt;|&gt;|&amp;/g, matches => map[matches]);
            return JSON.parse(str);
        }
        return null;
    }
    catch (e) {
        return null;
    }
};
// Node 端缓存的数据写入全局变量中
export const xyPageInfoCache = new Map();
Object.keys(window?.xy_runtime_page_config_cache ?? {}).forEach((pageId) => {
    const { projectId, env } = window?.RUNTIME_CONFIGS || {};
    if (projectId && env) {
        const pageKey = `${projectId}:${pageId}:${env}`;
        const cache = window?.xy_runtime_page_config_cache?.[pageId];
        if (cache) {
            xyPageInfoCache.set(pageKey, Promise.resolve(decodePageConfig(cache)));
        }
    }
});
window.xy_runtime_pageInfoCache = xyPageInfoCache;
export class RuntimePreloader {
    async load({ projectId, pageId, env }) {
        try {
            // @ts-ignore
            if (isHmr()) {
                return;
            }
            if (!projectId || !pageId || !env) {
                return;
            }
            const pageKey = `${projectId}:${pageId}:${env}`;
            let promise;
            if (xyPageInfoCache.has(pageKey)) {
                promise = xyPageInfoCache.get(pageKey);
            }
            else {
                promise = pageLoader.getPageDetail({ projectId, pageId });
            }
            if (promise) {
                promise.catch((e) => {
                    xyPageInfoCache.delete(pageKey);
                    console.error(e);
                });
                xyPageInfoCache.set(pageKey, promise);
                const pageInfo = await promise;
                this.preloadByPageInfo(pageInfo, env);
            }
        }
        catch (error) {
            // 避免万一出错中断执行，catch下
            console.error('[preLoader.load]Error:', error);
            message.error(`页面加载失败，${error.message}`);
        }
    }
    preloadByPageInfo(pageInfo, envId) {
        // @ts-ignore
        if (isHmr()) {
            return;
        }
        const { pageId, projectId, pageConfig } = pageInfo;
        if (!Array.isArray(pageConfig)) {
            return;
        }
        const ids = this.getFirstPageSourceIds(pageInfo);
        preloadCollections(ids, { projectId, pageId, envId });
        setTimeout(() => {
            const otherIds = this.getOtherPageSourceIds(pageInfo);
            preloadCollections(otherIds, { projectId, pageId, envId });
        }, 1000);
    }
    getFirstPageSourceIds(pageInfo) {
        const pageConfig = pageInfo?.pageConfig || [];
        const firstPage = pageConfig.find((item) => item.id === 'default') || pageConfig[0];
        const xySources = firstPage?.xySources || [];
        const ids = xySources.filter((item) => item.type === 'collection').map(item => item.id);
        return ids;
    }
    // 获取弹窗，页面片使用的collectionId
    getOtherPageSourceIds(pageInfo) {
        const pageConfig = pageInfo?.pageConfig || [];
        const otherPages = pageConfig.filter((item) => item.id !== 'default');
        const ids = {};
        otherPages.forEach((page) => {
            const xySources = page.xySources || [];
            const idsInPage = xySources.filter((item) => item.type === 'collection').map(item => item.id);
            idsInPage.forEach((id) => {
                ids[id] = id;
            });
        });
        return Object.keys(ids);
    }
}
export const preLoader = new RuntimePreloader();
