// 加载 Monaco Editor 实例
export default async function monacoProvider(callback) {
    let monaco = null;
    for (let i = 0; i < 10; i++) {
        try {
            monaco = await new Promise(rs => window.require(['vs/editor/editor.main'], rs));
            if (monaco) {
                break;
            }
        }
        catch (e) {
            console.log(`Monaco Editor 加载失败[${i}]`);
            await new Promise(rs => setTimeout(rs, 200));
        }
    }
    if (typeof callback === 'function')
        callback(monaco);
    return monaco;
}
export async function getMonacoAsync() {
    const monaco = await monacoProvider();
    return monaco;
}
