import { cloneDeep, remove } from 'lodash';
import {
  getDefaultSidebar,
  formatSideBarToUIState,
} from '@loaders/project/utils';
import {
  isRoleMatched,
  isBizMatched,
  getVisibleMatchedGroups,
  getMatchedGroupId,
} from '@components/sidebar/utils';
import { useRouter } from '@/router/useRouter';
import { PAGE_TYPE } from '@/loaders/page/consts';

export default {
  name: 'layout',
  namespaced: true,
  state: {
    mode: 'runtime',
    // 自定义菜单
    customSidebar: null,
    // 布局权限相关
    roles: [],
    biz: '',
    isAdmin: false,
    // 内容
    mainRenderer: null,
    // 导航
    nav: null,
    navRenderer: null,
    // 菜单
    sidebar: null,
    sidebarRenderer: null,
    // 页头
    pageHeaderRenderer: null,
    // 页脚
    pageFooterRenderer: null,
    // uicore实例
    ucRenderers: [],
    // 全局弹窗实例
    globalModals: {},
  },
  getters: {
    currentRouter() {
      const { router } = useRouter();
      return router.value;
    },
    currentRoute() {
      const { route } = useRouter();
      return route.value;
    },
    project(state, getters, rootState) {
      if (state.mode === 'runtime') {
        return rootState.runtime.project;
      }
      return rootState.project.projectInfo;
    },
    systemSidebar(state, getters) {
      return cloneDeep(getters.project?.uiInfo?.sidebar ?? getDefaultSidebar());
    },
    sidebar(state, getters) {
      if (state.customSidebar) return state.customSidebar;
      return getters.systemSidebar;
    },
    // 所有角色ID
    rolesIds(state) {
      return state.roles.map(r => r.role);
    },
    // 应用的所有菜单
    sidebarGroups(state, getters) {
      return formatSideBarToUIState(cloneDeep(getters.sidebar));
    },
    // 根据角色 + 业务筛选可见的菜单
    visibleSidebarGroups(state, getters) {
      const { roles, isAdmin, biz } = state;
      const { sidebarGroups } = getters;
      return getVisibleMatchedGroups(sidebarGroups, { roles, biz, isAdmin });
    },
    // 顶部导航菜单组配置可见的菜单组
    showSidebarGroups(state, getters) {
      const { isAdmin, biz } = state;
      const { project, rolesIds } = getters;
      return (project?.uiInfo?.navlink?.menuGroup || [])
        .filter(c => c.isShow
          && isRoleMatched(c?.roles, rolesIds, isAdmin)
          && isBizMatched(c?.bizes, biz));
    },
    // 顶部导航菜单组配置可见的菜单组Id
    showSidebarGroupIds(state, getters) {
      return getters.showSidebarGroups?.map(g => `${g.groupId}`) ?? [];
    },
    // 顶部导航实际显示的菜单组
    navShowSidebarGroups(state, getters) {
      const groups = [];
      getters.showSidebarGroupIds.forEach((id) => {
        const group = getters.visibleSidebarGroups.find(group => `${group.groupId}` === `${id}`);
        if (group) groups.push(group);
      });
      return groups;
    },
    // 顶部导航选中的菜单组
    navSidebarGroupSelectedKeys(state, getters) {
      // 优先取当前菜单所属菜单组
      const currentGroup = state.sidebar?.sidebar;
      if (currentGroup) return [currentGroup.groupId];
      // 兜底: 通过 URL 计算当前命中的菜单组
      const groupId = getMatchedGroupId(getters.navShowSidebarGroups, {
        $route: getters.currentRoute,
        stringInteropContext: {
          ...state.mainRenderer,
          $app: Vue.prototype.$app,
          w: window.w,
        },
      });
      return groupId ? [groupId] : [];
    },
    navTheme(state, getters) {
      return getters.project?.uiInfo?.navStyle?.nav === 1 ? 'dark' : 'light';
    },
    sidebarTheme(state, getters) {
      return getters.project?.uiInfo?.navStyle?.sidebar === 1 ? 'dark' : 'light';
    },
  },
  actions: {

  },
  mutations: {
    setLayoutMode(state, mode) {
      state.mode = mode;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setBiz(state, biz) {
      state.biz = biz;
    },
    setIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    setNav(state, nav) {
      state.nav = nav;
    },
    removeNav(state, nav) {
      if (state.nav === nav) {
        state.nav = null;
      }
    },
    setSidebar(state, sidebar) {
      state.sidebar = sidebar;
    },
    removeSidebar(state, sidebar) {
      if (state.sidebar === sidebar) {
        state.sidebar = null;
      }
    },
    // 设置渲染器
    setRenderer(state, { type, main, renderer }) {
      if (type === PAGE_TYPE.CONTENT && main) {
        state.mainRenderer = renderer;
      } else if (type === PAGE_TYPE.NAV) {
        state.navRenderer = renderer;
      } else if (type === PAGE_TYPE.SIDEBAR) {
        state.sidebarRenderer = renderer;
      } else if (type === PAGE_TYPE.HEADER) {
        state.pageHeaderRenderer = renderer;
      } else if (type === PAGE_TYPE.FOOTER) {
        state.pageFooterRenderer = renderer;
      }
    },
    // 移除渲染器
    removeRenderer(state, { type, main, renderer }) {
      if (type === PAGE_TYPE.CONTENT && main) {
        state.mainRenderer = renderer;
        if (state.mainRenderer === renderer) state.mainRenderer = null;
      } else if (type === PAGE_TYPE.NAV) {
        if (state.navRenderer === renderer) state.navRenderer = null;
      } else if (type === PAGE_TYPE.SIDEBAR) {
        if (state.sidebarRenderer === renderer) state.sidebarRenderer = null;
      } else if (type === PAGE_TYPE.HEADER) {
        if (state.pageHeaderRenderer === renderer) state.pageHeaderRenderer = null;
      } else if (type === PAGE_TYPE.FOOTER) {
        if (state.pageFooterRenderer === renderer) state.pageFooterRenderer = null;
      }
    },
    // 收集uc实例
    collectUcRenderer(state, { mainRoot = false,  renderer }) {
      if (state.ucRenderers.find(instance => instance === renderer)) return;
      if (mainRoot) {
        state.ucRenderers.unshift(renderer);
      } else {
        state.ucRenderers.push(renderer);
      }
    },
    // 替换或移除uc实例
    changeUcRenderer(state, { mainRoot = false,  renderer }) {
      if (mainRoot) {
        if (state.ucRenderers[0] !== renderer) {
          // 编辑时替换uc实例
          state.ucRenderers[0] = renderer;
        } else {
          state.ucRenderers.shift();
        }
      } else {
        remove(state.ucRenderers, instance => instance === renderer);
      }
    },
    // 初始化全局弹窗
    initGlobalModalById(state, { id, opt = { keepAlive: false } }) {
      state.globalModals[id] = { instance: null, ...opt };
    },
    // 设置全局弹窗实例
    setGlobalModalInstanceById(state, { id, instance }) {
      state.globalModals[id].instance = instance;
    },
    // 移除指定全局弹窗实例
    removeGlobalModalById(state, { id }) {
      destroyGlobalModalInstance(state, id);
    },
    // 清除全局弹窗实例
    clearGlobalModals(state) {
      Object.keys(state.globalModals)?.forEach(key => destroyGlobalModalInstance(state, key));
    },
    // 设置自定义菜单
    setCustomSidebar(state, sidebar) {
      state.customSidebar = sidebar;
    },
  },
};

function destroyGlobalModalInstance(state, id) {
  const { instance } =  state.globalModals[id];
  // 销毁全局弹窗父元素实例
  instance.$destroy();
  instance.$el.parentNode?.removeChild(instance.$el);
  delete state.globalModals[id];
}
