<template>
  <a-layout-sider
    v-model="collapsed"
    class="xy-sidebar runtime"
    :class="{
      'miniProgram': isMobileOrNarrowScreen,
    }"
    theme="light"
    :width="computedWidth"
    :trigger="null"
    collapsed-width="0"
  >
    <w-sys-pagelet
      v-if="project && pageList && page"
      id="w-sys-sidebar-page"
      ref="w-sys-sidebar-pagelet"
      :root="true"
      mode="runtime"
      type="sidebar"
      :project-config="project"
      :config="page"
      :page-list="pageList"
      :main="false"
      style="overflow-x: hidden;background-color: white;height: 100%"
    />
    <template v-if="!isMobileOrNarrowScreen">
      <div
        class="xy-sidebar-resizer"
        @mousedown="startResize"
        @touchstart="startResize"
      />
      <!-- Sidebar 开关 -->
      <CollapseTrigger
        :collapsed="collapsed"
        :is-show-collapse-trigger="isShowCollapseTrigger"
        @toggle-collapsed="toggleCollapsed"
      />
    </template>
    <div
      v-else
      class="drawer-mask"
      :class="{'drawer-mask-opened' : !collapsed}"
      :style="maskLeft"
      @click="setCollapsed(true)"
    />
  </a-layout-sider>
</template>

<script>
import { computed, toRefs } from '@vue/composition-api';
import { useRuntimePagelet } from '@composables/useRuntimePagelet';
import CollapseTrigger from '@components/sidebar/CollapseTrigger';
import useSidebar from '@composables/useSidebar';

export default {
  name: 'CustomSidebar',
  components: {  CollapseTrigger },
  props: {
    project: {
      type: Object,
      default: null,
    },
    pageId: {
      type: String,
      default: '',
    },
    isMobileOrNarrowScreen: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: '209px',
    },
  },
  setup(props) {
    const { project, pageId, isMobileOrNarrowScreen, width } = toRefs(props);

    const projectId = computed(() => project.value.id);

    const { page, pageList } = useRuntimePagelet({
      projectId: projectId.value,
      pageId: pageId.value,
    });
    const {
      collapsed,
      toggleCollapsed,
      setCollapsed,
      computedWidth,
      isShowCollapseTrigger,
      maskLeft,
      startResize,
    } = useSidebar({ isMobileOrNarrowScreen, width });

    return {
      page,
      pageList,
      collapsed,
      toggleCollapsed,
      setCollapsed,
      computedWidth,
      isShowCollapseTrigger,
      maskLeft,
      startResize,
    };
  },
  created() {
    this.$store.commit('layout/setSidebar', this);
  },
  beforeDestroy() {
    this.$store.commit('layout/removeSidebar', this);
  },
};
</script>

<style scoped lang="scss">
</style>
