/* eslint-disable no-param-reassign */
/**
 * 运行时开发者环境调试模式下，需要动态拉取静态资源并动态插入到指定位置
 */
import { getLocalBaseApiUrl, LOCAL_API_PROTOCOL } from '@/config/cli-settings';
import { getDataOrThrow, loadCss, loadScript } from '@/utils/common';
import fetch from '@utils/fetch';
import logger from '@utils/logger';
import { loadScriptError, getRefreshUrl } from '@utils/loadScriptError';

/**
 * 此处会导致globalJs在本地和线上加载的位置不一样，暂时无解
 */

class GlobalsLoader {
  constructor() {
    this.debug = false;
    this.port = 9998;
  }

  isDebug() {
    return !!this.debug;
  }

  initHmr(port) {
    this.debug = true;
    this.port = port;
  }

  async getGlobalInfo(projectId) {
    if (this.debug) {
      const globalInfo = await fetch(`${getLocalBaseApiUrl(this.port)}/globals?projectid=${projectId}`, {
        method: 'GET',
        credentials: 'omit',
      }, 5000, false).then(getDataOrThrow)
        .catch((err) => {
          // 不强制用户升级cli工具，所以这里暂不阻止下一步
          console.error(`拉取全局变量配置失败，请确认本地CLI服务是否启动，或升级CLI工具：${err.message}`);
        });

      return globalInfo;
    }

    return window.DEVTOOL_GLOBAL_OBJECT;
  }
  async loadResources(projectId) {
    const globalInfo = await this.getGlobalInfo(projectId);
    if (!globalInfo) return;

    const { resources, globalsJs } = globalInfo;
    const { css, js } = resources || {};

    const asyncTask = async (list, task) => {
      if (!list) return;

      const tasks = list.filter(Boolean)
        .map(async (src) => {
          try {
            await task(src);
          } catch (err) {
            // load error, cannot stop next step
            logger.error('load Script error', err);
            throw err;
          }
        });

      await Promise.all(tasks);
    };

    await asyncTask(css, loadCss);
    // 并行加载JS，按序执行
    await asyncTask([...(js || []), globalsJs], async (src) => {
      try {
        // 此处代码不仅用于加载调试的全局js,还会加载其他全局js，需要区分进行场景进行url的处理
        // 调试模式下js是//localhost:9998
        // 正式运行时js取自window.DEVTOOL_GLOBAL_OBJECT的js路径（是完整的绝对路径）
        let url;
        const ignoreCacheSetting = src !== globalsJs;
        if (this.debug) {
          url = `${LOCAL_API_PROTOCOL}${src.replace(/^(http|https):/, '')}`;
        } else {
          url = getRefreshUrl(src, {
            jsCacheDisabled: ignoreCacheSetting,
          });
        }
        await loadScript(url, false);
      } catch (err) {
        // 如果是debug条件下不需要弹层，否则可视化页面的运行时debug态下仅调试本地组件，就需要依赖cli是否启动global服务
        if (this.debug) return;

        loadScriptError(src);
        throw err;
      }
    });
  }
}

export default new GlobalsLoader();
