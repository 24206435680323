import Vue from 'vue';
import wujiComp from '@tencent/xy-compsloader';

import { error, loading } from './lazy-load-placeholder';
import { fetchCompDBInfo } from './requestor';
import { compsLoaderLogger } from './logger';

export const disabledComponents = new Set();

export const getUrlParamsDisabledComponent = () => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  if (params.has('disableComponent')) {
    params
      .get('disableComponent')
      .split(',')
      .forEach((c) => {
        compsLoaderLogger({
          functionName: 'getUrlParamsDisabledComponent',
          content: `组件${c}因为url参数被阻止实例化`,
        });
        if (c) disabledComponents.add(c);
      });
  }
};

/**
 * 注册 Vue 组件
 * @param {String}} 组件 key
 * @param {Component} 组件实例
 */
export function registerComp(key, comp) {
  try {
    if (disabledComponents.has(key)) return;
    Vue.component(key, comp);
    if (key.includes(':')) {
      Vue.component(key.replace(':', '-'), comp);
    }
  } catch (e) {
    console.error(`Vue.component[${key}] register error`, e);
  }
}

export const wrapComponent = (comp) => {
  if (isLazyLoadComponent(comp)) {
    return wrapLazyLoadComponent(comp);
  }
  return comp;
};

const wrapLazyLoadComponent = comp => () => ({
  component: comp(),
  loading,
  error,
  delay: 0,
  timeout: 3000,
});

/**
 * 一个比较hack的方式来判断是否是异步组件
 * 如果不是class component，那么这个组件实例会是一个对象
 * 如果是class component, 它会有一个cid属性
 * 从vue的源码里找到的，见vue源码的src/core/vdom/create-component:132
 * @param {*} comp
 * @returns
 */
const isLazyLoadComponent = comp => typeof comp === 'function' && !comp.cid;


/**
 * 异步注册 Vue 组件(需要保证 wujiComp 已经加载完组件列表数据)
 * @param {Array} filterKeys 不需要加载的组件
 */
export const registerAsyncComponents = (filterKeys) => {
  // 全量组件
  const totalComps = wujiComp?.moduleManage?.compDBList ?? [];
  if (!totalComps) return;
  // 组件加载器已安装的组件
  const installedCompKeys = Object.keys(wujiComp?.moduleManage?.installedModules ?? {});
  const asyncCompKeys = totalComps
    .map(c => c.id)
    .filter((id) => {
      const isFilter = !filterKeys.includes(id) && !installedCompKeys.includes(id);
      return isFilter;
    });
  asyncCompKeys.forEach((key) => {
    asyncComponentRegister(key);
  });
};

/**
 * 异步注册 Vue 组件
 * @param {Array} componentKeys 加载的组件
 */
export const registerAsyncComponentsByGivenComps = (componentKeys) => {
  // 初始化compsloader的请求
  fetchCompDBInfo();
  const installedCompKeys = Object.keys(wujiComp?.moduleManage?.installedModules ?? {});
  // 我们不需要注册已经安装好的组件
  const asyncCompKeys = componentKeys.filter(id => !installedCompKeys.includes(id));
  asyncCompKeys.forEach((key) => {
    asyncComponentRegister(key);
  });
};

const asyncComponentRegister = (key) => {
  const loadComp = () => new Promise((res, rej) => {
    wujiComp
      .getCompsByCompKeys([key])
      .then((map) => {
        const compInfo = map?.[key];
        if (compInfo?.comp) {
          res(compInfo.comp);
        } else {
          rej(`[register-async-components].Fail ${map}`);
        }
      })
      .catch(err => rej('[register-async-components].Error', err));
  });
  registerComp(key, wrapLazyLoadComponent(loadComp));
};
