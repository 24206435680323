import { isExternalDeploy } from './external-deploy';
import { addPathPrefix } from '@/utils/path';
export const getDocLink = () => window?.GLOBAL_INFO?.DOC_LINK ?? {};
const getLibraryFromComponentId = (componentId) => {
    if (componentId.endsWith('antd')) {
        return 'antd';
    }
    return 'tdesign';
};
/**
 * 获取组件商店的文档地址
 * @param componentId 组件的id
 * @param registry 部署版, local or public
 * @param group 分组
 * @returns
 */
export const getComponentHubDocLink = (componentId, registry, group) => {
    const publicCompHub = getDocLink().PUBLIC_COMPHUB;
    const pageId = componentId.replace(/^public-/, '').replace(':', '_');
    if (registry === 'public' && publicCompHub) {
        const library = getLibraryFromComponentId(componentId);
        const query = new URLSearchParams({ library });
        if (isExternalDeploy()) {
            return `${publicCompHub}/${pageId}?${query.toString()}`;
        }
        return `${publicCompHub}/page/${pageId}?${query.toString()}`;
    }
    return addPathPrefix(`/xy/componenthub/${registry}/${group || 'default'}/${componentId}`);
};
// 挂到 window 上给其他包使用
window.GLOBAL_INFO.getDocLink = getDocLink;
