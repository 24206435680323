<template>
  <div
    class="xy-modal-size-editor"
  >
    <a-input
      v-model="sizeValue"
      class="xy-modal-size-editor-input"
      type="number"
      min="0"
    >
      <a-select
        slot="addonAfter"
        v-model="sizeUnit"
        style="width: 65px"
        :options="unitOptions"
      />
    </a-input>
  </div>
</template>
<script>
import { vueComponentMixin } from '@tencent/data-schema/lib/vue-ui/definitions';

const component = {
  name: 'XyModalSizeEditor',
  mixins: [vueComponentMixin],
  props: {
    ui: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    };
  },
  computed: {
    unitOptions() {
      return this?.ui?.unitOptions ?? [];
    },
    sizeValue: {
      get() {
        const { value } = this.$props;
        const size = `${value}`.replace(/[^0-9]/ig, '');
        return +size;
      },
      set(val) {
        this.editValue = `${val}${this.sizeUnit}`;
      },
    },
    sizeUnit: {
      get() {
        const { value } = this.$props;
        let unit = `${value}`.replace(/[0-9]/ig, '');
        const units = this.unitOptions.map(unit => unit.value);
        if (!units.includes(unit)) {
          unit = 'px';
        }
        return unit;
      },
      set(val) {
        this.editValue = `${this.sizeValue}${val}`;
      },
    },
  },
};

export default component;

const settingsSchema = null;
export const meta = {
  settingsSchema,
  component,
  hideLabel: false,
};
</script>

<style lang="scss" scope>
.xy-modal-size-editor {
  width: 100%;
  &-input {
    // input[type="number"] 隐藏步进器
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
</style>
