import { ref } from '@vue/composition-api';
import { AcceptType } from '@/components/merge/interface/IConflictsOptions';
import { uuidv4 } from '@tencent/ui-core/lib/utils';
import monacoProvider from '@utils/monacoProvider';
const CONFLICT_MARKER = '(<<<<<<<)[\\s\\S\\n]*?(=======)[\\s\\S\\n]*?(>>>>>>>)';
const CONFLICT_SPLITER = '=======';
export default function useEditConflict(props) {
    const { editor, classname = {}, currentLabel, incomingLabel, onAccept } = props;
    const decorations = ref([]); // 高亮区块
    const currentPosition = ref({ lineNumber: 1, column: 1 }); // 当前选择的位置
    const widgetZones = ref([]);
    const widgetList = ref([]);
    const conflictsPosition = ref([]); // 所有的冲突位置
    // 根据冲突标志符搜索冲突点
    const collectConflicts = () => {
        const model = editor.value.getModel();
        if (!model) {
            return;
        }
        const conflictsMatch = model.findMatches(CONFLICT_MARKER, true, true);
        conflictsPosition.value = conflictsMatch.map((conflictPosition) => {
            const conflictSplitPostion = model
                .findNextMatch(CONFLICT_SPLITER, { lineNumber: conflictPosition.range.startLineNumber, column: 1 });
            return {
                startLineNumber: conflictPosition.range.startLineNumber,
                endLineNumber: conflictPosition.range.endLineNumber,
                splitLineNumber: conflictSplitPostion.range.startLineNumber,
            };
        });
        return conflictsPosition.value || [];
    };
    // 高亮冲突点
    const highlightConflicts = (conflicts, monaco) => {
        decorations.value = editor.value?.deltaDecorations(decorations.value, [
            // 冲突的当前变更高亮
            ...conflicts.map(conflict => ({
                range: new monaco.Range(conflict.startLineNumber, 1, conflict.splitLineNumber - 1, 1),
                options: {
                    isWholeLine: true,
                    className: classname.conflict_current_highlight,
                    marginClassName: classname.conflict_current_highlight,
                },
            })),
            // 冲突的最新变更高亮
            ...conflicts.map(conflict => ({
                range: new monaco.Range(conflict.splitLineNumber + 1, 1, conflict.endLineNumber - 1, 1),
                options: {
                    isWholeLine: true,
                    className: classname.conflict_incoming_highlight,
                    marginClassName: classname.conflict_incoming_highlight,
                },
            })),
        ]);
    };
    const getRemoveLines = (type, position) => {
        const { startLineNumber, endLineNumber, splitLineNumber } = position;
        if (type === AcceptType.current) {
            return [
                {
                    startLineNumber,
                    endLineNumber: startLineNumber + 1,
                },
                {
                    startLineNumber: splitLineNumber,
                    endLineNumber: endLineNumber + 1,
                },
            ];
        }
        if (type === AcceptType.incoming) {
            return [
                {
                    startLineNumber,
                    endLineNumber: splitLineNumber + 1,
                },
                {
                    startLineNumber: endLineNumber,
                    endLineNumber: endLineNumber + 1,
                },
            ];
        }
        if (type === AcceptType.both) {
            return [
                {
                    startLineNumber,
                    endLineNumber: startLineNumber + 1,
                },
                {
                    startLineNumber: splitLineNumber,
                    endLineNumber: splitLineNumber + 1,
                },
                {
                    startLineNumber: endLineNumber,
                    endLineNumber: endLineNumber + 1,
                },
            ];
        }
        return [];
    };
    // 点击accept变更
    const acceptChange = (type, position, monaco) => {
        const removeLines = getRemoveLines(type, position);
        editor.value.executeEdits('', removeLines.map(line => ({
            range: new monaco.Range(line.startLineNumber, 1, line.endLineNumber, 1),
            text: null,
        })));
        onAccept?.();
    };
    const addAcceptWidgets = (conflicts, monaco) => {
        clearAllViewZones();
        conflicts.forEach((conflictPosition) => {
            const domNode = document.createElement('div');
            domNode.style.color = 'gray';
            domNode.style.fontSize = '12px';
            domNode.style.whiteSpace = 'nowrap';
            const buttons = [
                {
                    label: currentLabel || '使用当前版本',
                    type: AcceptType.current,
                },
                {
                    label: incomingLabel || '使用远程版本',
                    type: AcceptType.incoming,
                },
                {
                    label: '保留两个版本',
                    type: AcceptType.both,
                },
            ];
            buttons.forEach((button) => {
                const element = document.createElement('span');
                element.style.cursor = 'pointer';
                element.style.marginRight = '4px';
                element.innerText = button.label;
                element.addEventListener('click', () => acceptChange(button.type, conflictPosition, monaco));
                domNode.appendChild(element);
            });
            editor.value.changeViewZones((changeAccessor) => {
                const viewZoneId = changeAccessor.addZone({
                    afterLineNumber: conflictPosition.startLineNumber - 1,
                    heightInLines: 1,
                    domNode: document.createElement('div'),
                });
                widgetZones.value.push(viewZoneId);
            });
            const id = uuidv4();
            const widget = {
                domNode: null,
                getDomNode: () => domNode,
                getId: () => id,
                getPosition() {
                    return {
                        position: {
                            lineNumber: conflictPosition.startLineNumber,
                            column: 1,
                        },
                        preference: [
                            monaco.editor.ContentWidgetPositionPreference.ABOVE,
                            monaco.editor.ContentWidgetPositionPreference.BELOW,
                        ],
                    };
                },
            };
            widgetList.value.push(widget);
            editor.value?.addContentWidget(widget);
        });
    };
    const clearAllViewZones = () => {
        editor.value?.changeViewZones((changeAccessor) => {
            widgetZones.value.forEach((zoneId) => {
                changeAccessor.removeZone(zoneId);
            });
        });
        widgetList.value.forEach((widgetId) => {
            editor.value?.removeContentWidget(widgetId);
        });
    };
    const updateConflicts = (autoFocus) => {
        monacoProvider((monaco) => {
            if (editor.value) {
                // 搜索冲突起始和结束标志
                const conflictsMatch = collectConflicts();
                // 高亮冲突
                highlightConflicts(conflictsMatch, monaco);
                addAcceptWidgets(conflictsMatch, monaco);
                if (autoFocus && conflictsMatch.length > 0) {
                    currentPosition.value = {
                        lineNumber: conflictsMatch[0].endLineNumber,
                        column: 1,
                    };
                    editor.value.revealPositionInCenter(currentPosition.value);
                }
            }
        });
    };
    const findMatch = (funcName) => {
        monacoProvider(() => {
            const conflictsMatch = editor.value
                .getModel()[`find${funcName}Match`](CONFLICT_MARKER, currentPosition.value, true, true);
            if (conflictsMatch) {
                editor.value?.revealPositionInCenter({
                    lineNumber: conflictsMatch.range.startLineNumber,
                    column: 1,
                });
                currentPosition.value = {
                    column: 1,
                    lineNumber: conflictsMatch.range.endLineNumber,
                };
            }
        });
    };
    return {
        updateConflicts,
        findMatch,
        conflictsPosition,
    };
}
