/* eslint-disable no-param-reassign */
import wujiComp from '@tencent/xy-compsloader';
import { fetchComponentHubLibraryInfo, getComponentHubStoreKey } from '@utils/componentHub';
import newFunction from '../new-function';
import { message } from 'ant-design-vue';
import { walkLayout } from '@tencent/ui-core/lib/utils';
import cloneDeep from 'lodash/cloneDeep';
import { FALLBACK_UI_LIBRARY } from '@/consts/component-hub-related';
const cacheMap = new Map();
const cacheSize = 128;
const buildSwitchHookFunction = (body) => {
    const cachedFunction = cacheMap.get(body);
    if (cachedFunction)
        return cachedFunction;
    try {
        const f = newFunction(['config'], body);
        if (cacheMap.size >= cacheSize) {
            cacheMap.delete(cacheMap.keys().next().value);
        }
        cacheMap.set(body, f);
        return f;
    }
    catch (err) {
        console.error('组件框架切换钩子编译失败', err);
        return null;
    }
};
// 将public-w-readonly-2v:antd
// 转换成下面这种对象
// {
//   registry: 'public',
//   keyword: 'w-readonly',    // 在同一个registry，keyword相同的组件会被视为可替换组件
//   version: '2v',
//   library: 'antd',
// }
export const getCompInfoFromCompKey = (compKey) => {
    const REGISTRY_REGEX = /^(public|local)-/;
    const PREFIX_REGEX = /(?:-(\d+v|new|\d))?(?::(\w+))?$/;
    const [, registry] = REGISTRY_REGEX.exec(compKey) || [];
    if (!registry)
        return null;
    const [, version, library] = PREFIX_REGEX.exec(compKey) || [];
    const keyword = compKey.replace(REGISTRY_REGEX, '').replace(PREFIX_REGEX, '');
    let formatVersion = version;
    // 兼容public-w-readonly-new
    if (version === 'new') {
        formatVersion = '1v';
    }
    // 兼容public-w-avatar-2
    if (/\d$/.test(version)) {
        formatVersion = `${version}v`;
    }
    return {
        registry,
        keyword,
        version: formatVersion,
        library,
    };
};
/**
 * 获取组件替换的配置
 *
 * 比如
 * public-w-input-2v
 * public-w-input:tdesign
 * public-w-input-2v:antd
 * 这种组件是可以互相替换的
 */
class ReplaceOptions {
    map;
    switchHookCache;
    initialized;
    defaultLibrary;
    constructor() {
        this.map = {
            local: {},
            public: {},
        };
        this.switchHookCache = {};
        this.initialized = false;
        this.defaultLibrary = FALLBACK_UI_LIBRARY;
    }
    buildMap(defaultLibrary) {
        this.defaultLibrary = defaultLibrary || FALLBACK_UI_LIBRARY;
        const { installedModules } = wujiComp.moduleManage;
        Object.keys(installedModules).forEach((key) => {
            const info = getCompInfoFromCompKey(key);
            if (!info)
                return;
            const { registry, keyword, version, library = '' } = info;
            const matchedResult = this.map[registry][keyword] || [];
            // 同一个ui框架的，只保留版本最新的
            const sameLibrary = matchedResult.find(item => item.library === library);
            if (sameLibrary) {
                if (this.versionCompare(sameLibrary.version, version) >= 0)
                    return;
                matchedResult.splice(matchedResult.indexOf(sameLibrary), 1);
            }
            matchedResult.push({
                id: key,
                version,
                library,
            });
            this.map[registry][keyword] = matchedResult;
        });
        // 排序
        Object.keys(this.map).forEach((registry) => {
            Object.keys(this.map[registry]).forEach((keyword) => {
                this.map[registry][keyword] = this.map[registry][keyword]
                    .sort(this.replaceOptionsSort.bind(this));
            });
        });
        this.initialized = true;
    }
    /**
     * 手动设置替换规则，用于希望将public-x替换成local-x的情况
     * @param target 目标组件，比如public-w-button
     * @param resolved 替换成的组件，比如local-w-button
     */
    setReplaceOption(target, resolved) {
        const info = getCompInfoFromCompKey(target);
        if (!info)
            return;
        const { registry, keyword, version, library = '' } = info;
        const matchedResult = this.map[registry][keyword] || [];
        // 插入到第一个
        matchedResult.unshift({
            id: resolved,
            version,
            library,
        });
    }
    versionCompare(v1, v2) {
        if (!v1 && v2)
            return -1;
        if (v1 && !v2)
            return 1;
        if (v1 === v2)
            return 0;
        const versionRegex = /(\d)+v/;
        // public-w-button-new 等价于 public-w-button-1v
        const v1Matched = versionRegex.exec(v1);
        const v2Matched = versionRegex.exec(v2);
        if (!v1Matched && v2Matched)
            return -1;
        if (v1Matched && !v2Matched)
            return 1;
        return v1Matched[1] - v2Matched[1];
    }
    getReplacementOptions(compKey) {
        const info = getCompInfoFromCompKey(compKey);
        if (!info)
            return null;
        const { registry, keyword } = info;
        return this.map[registry][keyword];
    }
    // availableGroups应该是[local@xxx, public@yyy]这样的形式
    async getSwitchHookByCompKey(compKey, availableGroups) {
        const info = getCompInfoFromCompKey(compKey);
        if (!info?.library)
            return null;
        const { registry, keyword, library } = info;
        const group = this.getGroupFromKeyword(keyword, availableGroups, registry);
        return await this.getSwitchHook(registry, group, library);
    }
    async getSwitchHook(registry, group, library) {
        const key = getComponentHubStoreKey(registry, group);
        if (this.switchHookCache[key]) {
            return this.switchHookCache[key]?.[library] || null;
        }
        const res = await fetchComponentHubLibraryInfo(registry, group);
        res.forEach((item) => {
            this.switchHookCache[key] = this.switchHookCache[key] || {};
            this.switchHookCache[key][item.name] = buildSwitchHookFunction(item.switchHook);
        });
        return this.switchHookCache[key]?.[library];
    }
    getGroupFromKeyword(keyword, availableGroups, registry = 'public') {
        const map = {};
        availableGroups.forEach((hub) => {
            const [registry, group] = hub.split('@');
            map[registry] = map[registry] || [];
            map[registry].push(group);
        });
        // 返回最长匹配的group
        const matchedGroups = map[registry]
            ?.filter(group => keyword.startsWith(group))
            .sort((a, b) => b.length - a.length);
        return matchedGroups?.[0] || '';
    }
    // 给定一个组件的keyword，获取当前可以使用的组件
    // 比如keyword： w-input
    // 会返回当前可用的最新版本的组件，比如public-w-input-2v, 或者public-w-input:antd
    getAvailableComponent(registry, keyword) {
        if (!this.initialized) {
            return `${registry}-${keyword}`;
        }
        const matchedResult = this.map[registry][keyword] || [];
        if (matchedResult.length === 0)
            return null;
        return matchedResult[0].id;
    }
    // 对替换选项进行排序
    // 1. 有library的排在前面
    // 2. 没有library的，按照版本号排序
    replaceOptionsSort(a, b) {
        if (a.library && b.library) {
            if (a.library === b.library) {
                return this.versionCompare(b.version, a.version);
            }
            // 默认库排在最前面
            if (a.library === this.defaultLibrary)
                return -1;
            if (b.library === this.defaultLibrary)
                return 1;
            return a.library.localeCompare(b.library);
        }
        if (a.library)
            return -1;
        if (b.library)
            return 1;
        return this.versionCompare(b.version, a.version);
    }
}
const replaceOptions = new ReplaceOptions();
export const buildReplaceOptions = (library) => {
    replaceOptions.buildMap(library);
};
export const setReplaceOption = (target, resolved) => replaceOptions
    .setReplaceOption(target, resolved);
export const getAvailableComponent = (registry, keyword) => replaceOptions
    .getAvailableComponent(registry, keyword);
export const getReplacementOptions = (compKey) => replaceOptions.getReplacementOptions(compKey);
export const getSwitchHookByCompKey = async (compKey, availableGroups) => replaceOptions
    .getSwitchHookByCompKey(compKey, availableGroups);
export const getSwitchHook = async (registry, group, library) => replaceOptions
    .getSwitchHook(registry, group, library);
// 暴露给switchHook的参数
export const getSwitchHookContext = (switchButtonInstance) => ({
    plugins: {
        enablePlugin: async (pluginId, pluginConfig, allPages) => {
            const isExist = switchButtonInstance.xyBus
                .ucPlugins?.pagePlugins.some((item) => item.pluginId === pluginId);
            if (isExist) {
                return;
            }
            const { projectid, pageid } = switchButtonInstance.$route.query;
            try {
                await switchButtonInstance.$store.dispatch('installPlugin', {
                    projectId: projectid,
                    pluginId,
                    pageId: allPages ? '*' : pageid,
                    pluginConfig,
                });
            }
            catch (e) {
                if (e.message.includes('ER_DUP_ENTRY: Duplicate entry')) {
                    return;
                }
                throw e;
            }
            // 安装插件会重启页面，所有要保存页面内容先
            if (pageid) {
                await switchButtonInstance.xyBus.editor.handleSavePageInfo(false);
            }
            return () => {
                message.info('由于安装了插件，页面片需要重新加载');
                setTimeout(() => {
                    switchButtonInstance.$router.go(0);
                }, 1000);
            };
        },
    },
});
// 将旧组件的 componentId 替换成新组件的 componentId
// 比如 publicWButton2v3 -> publicWButtonAntd3
const getNewComponentId = (oldType, newType, oldComponentId) => {
    const pascalToCamel = (str) => {
        const [head, ...res] = str.split(/[:-]/);
        return head + res.map(item => item[0].toUpperCase() + item.slice(1))
            .join('');
    };
    const oldTypeName = pascalToCamel(oldType);
    const newTypeName = pascalToCamel(newType);
    const regex = new RegExp(`^${oldTypeName}(.*)`);
    const matched = oldComponentId.match(regex);
    // 如果正则没有命中，说明这个 id 是用户自定义的，我们不更改
    if (!matched)
        return oldComponentId;
    const suffix = matched[1];
    return newTypeName + suffix;
};
/**
 * 将 layout 中的组件进行替换
 * @param layout
 * @param oldType 旧组件 id
 * @param newType 新组件 id
 * @returns 新的layout
 */
export const replaceTypeInPageletLayout = (layout, oldType, newType) => {
    const copiedLayout = cloneDeep(layout);
    walkLayout(copiedLayout, (step) => {
        if (step.layout.type === oldType) {
            step.layout.type = newType;
            const { componentId } = step.layout;
            if (componentId) {
                step.layout.componentId = getNewComponentId(oldType, newType, componentId);
            }
        }
    }, { includeScopedSlots: true });
    return copiedLayout;
};
/**
 * 更新layout 中所有的 type，将其替换成最新的组件
 * @param layout
 */
export const updateAllTypeInLayout = (layout) => {
    const updateLayout = (layout) => {
        const copiedLayout = cloneDeep(layout);
        walkLayout(copiedLayout, (step) => {
            const { type, componentId } = step.layout;
            const compInfo = getCompInfoFromCompKey(type);
            if (!compInfo)
                return;
            const { registry, keyword } = compInfo;
            if (registry !== 'public') {
                return;
            }
            const newType = getAvailableComponent('public', keyword);
            if (!newType)
                return;
            step.layout.type = newType;
            if (componentId)
                step.layout.componentId = getNewComponentId(type, newType, componentId);
            /**
             * w-image-2v在升级过程中，修改了bindings的id
             * 我们在这里做一个转换
             */
            if (keyword === 'w-image') {
                step.layout.bindings.value = step.layout.bindings['图片地址'];
                delete step.layout.bindings['图片地址'];
            }
        }, { includeScopedSlots: true });
        return copiedLayout;
    };
    if (Array.isArray(layout)) {
        return layout.map(updateLayout);
    }
    return updateLayout(layout);
};
export const triggerUpdateFunctionForAllInLayout = async (layout, designer, targetType) => {
    const targetCompPath = [];
    walkLayout(layout, (step) => {
        const { type } = step.layout;
        if (targetType === type) {
            targetCompPath.push({
                compPath: step.compPath,
                sourcePath: step.sourcePath,
            });
        }
    }, { includeScopedSlots: true });
    for (const { compPath, sourcePath } of targetCompPath) {
        const path = sourcePath.split('/');
        path[0] = 'layout';
        await designer.setSourcePath(path);
        designer.internalCompPath = compPath;
        const [compAction] = designer.getActiveCompActions().filter(i => i.title === '升级到最新版本');
        if (compAction) {
            compAction.execute();
        }
    }
};
