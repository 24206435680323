import wujiFetch from '@/utils/wujiFetch';
import getXyManagePathPrefix from '@utils/getXyManagePathPrefix';
import reporter from '@/reporter/instance/designerReport';

const fetchPage = async ({ projectId, pageId }) => await wujiFetch(`${getXyManagePathPrefix()}/page/${pageId}?projectid=${projectId}`);

const updatePage = async ({ data, pageId, projectId }, headers = {}) => {
  try {
    // 页面编辑行为的统一上报
    reporter.report('click', {
      mod: '页面信息',
      eid: '修改',
    });
  } catch (e) {}
  return await wujiFetch(`${getXyManagePathPrefix()}/page/${pageId}?projectid=${projectId}`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify(data),
  });
};

const createPage = async ({ data, projectId }, headers = {}) => await wujiFetch(`${getXyManagePathPrefix()}/page?projectid=${projectId}`, {
  method: 'POST',
  headers,
  body: JSON.stringify(data),
});

const deletePage = async ({ projectId, pageId }, headers = {}) => await wujiFetch(`${getXyManagePathPrefix()}/page/${pageId}?projectid=${projectId}`, {
  method: 'DELETE',
  headers,
});

export default {
  fetchPage,
  updatePage,
  createPage,
  deletePage,
};
