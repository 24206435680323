import { ref, computed, onMounted } from '@vue/composition-api';
import { message } from 'ant-design-vue';
import { useStore } from '@/store';
import { set } from 'lodash';
export function useEnvOption(projectId) {
    const store = useStore();
    const envOptions = computed(() => [
        ...store.state.env.envList.map((item) => ({ value: item.id, label: `${item.name} (${item.env})` })),
        { value: 'dev', label: '开发环境 (dev)' },
    ]);
    const envMap = computed(() => envOptions.value.reduce((m, i) => {
        if (!m[i.value])
            set(m, i.value, i.label);
        return m;
    }, {}));
    onMounted(() => {
        store.dispatch('fetchEnvList', { projectId: projectId.value });
    });
    return {
        envMap,
        envOptions,
    };
}
export function useHostConfig(projectId) {
    const store = useStore();
    const hostConfigs = computed(() => store.state.hostConfig.hostConfigs);
    const loading = ref(false);
    const getHostConfigs = async ({ tips = true, isRuntime = false } = {}) => {
        try {
            loading.value = true;
            await store.dispatch('getHostConfigList', { projectId: projectId.value, isRuntime, cache: false });
        }
        catch (err) {
            console.log(err);
            if (tips)
                message.error('获取应用的短路径配置失败');
        }
        finally {
            loading.value = false;
        }
    };
    const deleteHostConfig = async (config) => {
        try {
            loading.value = true;
            await store.dispatch('deleteHostConfig', { projectId: projectId.value, id: config.id });
        }
        catch (err) {
            message.error('删除短路径配置失败');
        }
        finally {
            loading.value = false;
        }
    };
    return {
        loading,
        hostConfigs,
        getHostConfigs,
        deleteHostConfig,
    };
}
export function useHostConfigModal() {
    const isShowEditForm = ref(false);
    const editFormMeta = ref(null);
    const handleFormConfirm = async () => {
        isShowEditForm.value = false;
    };
    return {
        isShowEditForm,
        editFormMeta,
        handleFormConfirm,
    };
}
