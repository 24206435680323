/** 如果child是parent的子vnode 返回true */
export function isVueParent(child, parent) {
    let p = child;
    do {
        if (p.$parent === parent) {
            return true;
        }
    } while (p = p.$parent);
    return false;
}
