import { AsyncSeriesHook } from 'tapable';
const createRuntimeHook = () => ({
    // 页面配置加载
    pageConfigLoaded: new AsyncSeriesHook(['pageConfig']),
});
export const runtimeHook = createRuntimeHook();
export function registerRuntimeHook(hookType, hookName, hookFunc) {
    const hook = runtimeHook[hookType];
    hook.tapPromise(hookName, hookFunc);
}
;
const initRuntimeHook = () => {
    window.xy_runtime_hook = {
        register: registerRuntimeHook,
    };
};
export default initRuntimeHook;
