// import FlowView from '../jobEdit/JobEdit';
// import RelatedJobList from '@pages/flow/jobList/RelatedJobList';
// http://localhost/xy/app/branch/master/dev/wuji/flow/view/xxx
export default [
    // {
    //   name: 'flow-view',
    //   path: 'flow/view/:jobId',
    //   component: FlowView, // 需要重新开发
    // },
    {
        name: 'related-flow',
        path: 'flow/related',
        component: import('@pages/flow/jobList/RelatedJobList'),
    },
];
