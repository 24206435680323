import logger from './logger';

export const COMPONENT_TYPE = {
  normal: 'normal',
};

export default function (key, compInfo) {
  try {
    const { comp, config, compDB } = compInfo;
    let author;
    let version;
    let demo;
    let componentId;
    let registry;
    let group;
    let { visible } = config;
    let icon;
    let dependencies;
    let componentType = COMPONENT_TYPE.normal;

    // HMR的组件没有compDB，针对这些组件需要特殊处理
    if (compDB) {
      ({ author, version, demo, componentId, registry, group, visible, icon, dependencies } = compDB);

      if (compDB.extraInfo) {
        dependencies = compDB.extraInfo.compDependencies;
        componentType = compDB.extraInfo.type;
      }
    }

    return Object.assign(config, {
      registry,
      group,
      author,
      version,
      demo,
      id: key,
      visible,
      component: comp,
      componentId,
      icon,
      dependencies,
      componentType,
    });
  } catch (e) {
    logger.error(`%c ${key} component failed to convert to UI-Core component!`, 'color: red;', e);
  }
}
