import { getDesignEnvPath, getRuntimeEnvPath } from '@/config/constant';
import wujiFetch, { memoizedFetchV2 } from '@/utils/wujiFetch';
import { omit } from 'lodash';
import useIds from '../ids/useIds';
export default function useFlowService(ids = useIds()) {
    const flowDesignUrl = `${getDesignEnvPath(ids.envId)}/manage/flow`;
    const flowRuntimeUrl = `${getRuntimeEnvPath(ids.envId)}/manage/flow`;
    const list = async (params) => {
        const data = await wujiFetch(flowDesignUrl, {
            method: 'GET',
            query: {
                projectId: ids.projectId,
                sort: 'ctime',
                order: 'desc',
                ...params,
            },
        });
        return data;
    };
    const all = async (params) => {
        const url = window.RUNTIME_CONFIGS ? flowRuntimeUrl : flowDesignUrl;
        const data = await wujiFetch(url, {
            method: 'GET',
            query: {
                projectId: ids.projectId,
                sort: 'ctime',
                order: 'desc',
                ...omit(params, 'page'),
                size: 'total',
            },
        });
        return data;
    };
    const count = async (params) => {
        const data = await wujiFetch(flowDesignUrl, {
            method: 'GET',
            query: {
                projectId: ids.projectId,
                count: 1,
                filter: params.filter,
            },
        });
        return data.total;
    };
    const create = async (flow, isBuildIn) => {
        const data = await wujiFetch(flowDesignUrl, {
            method: 'POST',
            query: {
                projectId: ids.projectId,
                isBuildIn,
            },
            body: JSON.stringify(flow),
        });
        return data;
    };
    const update = async (flow) => {
        const data = await wujiFetch(`${flowDesignUrl}/${flow.flowId}/`, {
            method: 'PATCH',
            query: {
                projectId: ids.projectId,
            },
            body: JSON.stringify(flow),
        });
        return data;
    };
    const get = async (flowId) => {
        const data = await wujiFetch(`${flowDesignUrl}/${flowId}`, {
            method: 'GET',
            query: {
                projectId: ids.projectId,
            },
        });
        return data;
    };
    const getForRuntime = async (flowId) => {
        const data = await memoizedFetchV2(`${flowRuntimeUrl}/flow/${encodeURIComponent(flowId)}`, {
            method: 'GET',
            query: {
                projectId: ids.projectId,
            },
        });
        return data;
    };
    const del = async (id) => {
        const data = await wujiFetch(flowDesignUrl, {
            method: 'DELETE',
            query: {
                id,
                projectId: ids.projectId,
            },
        });
        return data;
    };
    return {
        list,
        count,
        create,
        update,
        get,
        getForRuntime,
        del,
        all,
    };
}
