/* eslint-disable */

import { defineComponent, getCurrentInstance, onUnmounted } from '@vue/composition-api';
import { throttle } from 'lodash';
import { releaseVueDomListener } from '@utils/vue/releaseVueDomListener';

const clearObj = (obj) => {
  if (Array.isArray(obj)) {
    obj.length = 0;
  }

  if (typeof obj === 'object') {
    for (const key in obj) {
      delete obj[key];
    }
  }
};

const memoryLeakKeys = [
  '$refs',
  '_vnode',
  '_watchers',
  '_provided',
  '$vnode',
  '$options',
  'rootContext',
  'parentWContext',
  'wLayout',
  'ctx',
  '$el',
  '$parent',
  '$children',
  '_data',
  '$slots',
  '$scopedSlots',
  '$listeners',
  'configProvider',
];

const clearKeyByDesc = (vm, key, desc) => {
  if (!desc) return;
  if (desc.configurable) {
    Object.defineProperty(vm, key, {
      value: typeof desc.value === 'object' ? {} : null,
    });
    return;
  }
  if (desc.writable && desc.value) {
    desc.value = null;
  }
  if (desc.set) {
    vm[key] = null;
  }
};

const unLinkVue = (vm) => {
  if (vm._vnode) {
    vm._vnode.children = null;
    vm._vnode.context = null;
    releaseVueDomListener(vm);
  }

  clearObj(vm._computedWatchers);
};

const debugClearObject = (vm) => {
  Object.entries(Object.getOwnPropertyDescriptors(vm)).forEach(([key, desc]) => {
    try {
      if (key === '_$memoryDetectKey') return;
      clearKeyByDesc(vm, key, desc);
    } catch (e) {
      console.log('[debug]failed destroy comp', e);
    }
  });
};

const releaseVueInstance = (vm) => {
  (window.memoryLeakKeys || memoryLeakKeys).forEach((key) => {
    try {
      const desc = Object.getOwnPropertyDescriptor(vm, key);
      clearKeyByDesc(vm, key, desc);
    } catch (e) {}
  });
};

function noop() {}
const FAKE_MESSAGE = {
  success: noop,
  error: noop,
  info: noop,
  warning: noop,
  warn: noop,
  loading: noop,
  open: noop,
};
/** 组件销毁后就算报错也不用抛出来了 */
const fakeAntdMessage = (vm) => {
  if (vm.$message) {
    Object.defineProperty(vm, '$message', {
      value: FAKE_MESSAGE,
    });
  }
};

let destroyedVmArray = [];
const emitDestroy = throttle(
  async () => {
    const vmArray = destroyedVmArray;
    destroyedVmArray = [];
    // 等一个空闲时间再销毁
    await new Promise((r) => setTimeout(r, 0));
    vmArray.forEach((vm) => {
      try {
        unLinkVue(vm);
        releaseVueInstance(vm);
        fakeAntdMessage(vm);
      } catch (e) {}
    });
  },
  1e3,
  { leading: false },
);

const getMixin = (debug = false) => {
  const mixin = {
    setup() {
      const instance = getCurrentInstance();
      onUnmounted(() => {
        instance.parent = null;
        instance.type = null;

        if (debug) {
          setTimeout(() => debugClearObject(instance), 1000);
        }
      });
    },

    destroyed() {
      if (debug) {
        try {
          window?.xy_vue_destroyed?.(this);
        } catch (e) {
          console.error(e);
        }
        setTimeout(() => {
          unLinkVue(this);
          debugClearObject(this);
        }, 1000);
        return;
      }
      destroyedVmArray.push(this);
      emitDestroy();
    },
  };

  return defineComponent(mixin);
};

export default getMixin;
