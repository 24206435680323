export const DEFAULT_CATEGORY = [
    {
        id: 'wuji',
        name: '无极组件',
    },
    {
        id: 'layout',
        name: '栅格布局组件',
    },
    {
        id: 'list',
        name: '列表组件',
    },
    {
        id: 'form',
        name: '表单组件',
    },
    {
        id: 'custom',
        name: '自定义组件',
    },
];
export const DELETE_GROUP = 'pagelet-component-deleted-group';
export const EVENT_TYPE = 'pageletComponent:eventEmitter';
export const SLOT_COMPONENT_TYPE = 'public-w-slot';
export const UNRELEASE_VERSION = '0.0.0';
